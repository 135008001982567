import {ProgressExamsInterface} from 'pages/modules/progress/interfaces/exams.interface';
import {ProgressExamResultInterface} from 'pages/modules/progress/interfaces/exam-result.interface';

export class ProgressExamsModel implements ProgressExamsInterface {

    constructor(
        public dpsId: string,
        public title: string,
        public id: number,
        public bookId: number,
        public type: number,
        public system: number,
        public average: number,
        public results: ProgressExamResultInterface[],
    ) {
    }

    public static fromApiProgressStudentScoreExamStatus(
        status: ApiProgressStudentScoreExamStatusInterface
    ): ProgressExamsModel {
        return new this(
            status.dpsId,
            status.title,
            status.id,
            status.bookId,
            status.type,
            status.system,
            status.average,
            status.results
        );
    }
}
