import {Directive, TemplateRef} from '@angular/core';

@Directive({
    selector: '[app-grid-table-scroll-col-header]',
})
export class GridTableScrollColHeaderDirective {
    constructor(
        private template: TemplateRef<any>
    ) {}

    public getTemplate(): TemplateRef<any> {
        return this.template;
    }
}
