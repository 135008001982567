import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SvgDataInterface} from 'interfaces/svg-data.interface';
import {SvgListTypeEnum} from 'enums/svg/list-type.enum';
import {SvgCircleModel} from 'models/svg/circle.model';
import {SvgCheckModel} from 'models/svg/check.model';
import {SvgClassEnum} from 'enums/svg/class.enum';

@Injectable()
export class SvgFactory {

    protected renderer: Renderer2;

    protected cache: Map<string, SVGElement> = new Map();

    constructor(private httpClient: HttpClient, private rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    public createFromListType(type: SvgListTypeEnum): SVGElement {
        let model: SvgDataInterface;

        switch (type) {
            case SvgListTypeEnum.Check:
                model = new SvgCheckModel();
                break;
            default:
                model = new SvgCircleModel();
        }

        const svgElement: SVGElement = this.get(model);

        svgElement.classList.add(SvgClassEnum.ListStyle);

        return svgElement;
    }

    protected get(svgData: SvgDataInterface): SVGElement {
        if (this.cache.has(svgData.name)) {
            return this.cache.get(svgData.name);
        }

        const htmlElement: HTMLElement = this.renderer.createElement('div');
        htmlElement.innerHTML = svgData.data;

        const svgElement: SVGElement = <SVGElement>htmlElement.childNodes.item(0);

        this.cache.set(svgData.name, svgElement);

        return svgElement;
    }
}
