import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {TocViewBookItemInterface} from 'shared/modules/toc/interfaces/toc-view-book-item.interface';
import {TocViewBooksItemClassEnum} from 'shared/modules/toc/enums/item.class-enum';
import {StudyMaterialTypeEnum} from 'enums/study-material-type.enum';
import {IconEnum} from 'enums/icon.enum';
import {StudyMaterialDocTypeEnum} from 'enums/study-material-doc-type.enum';
import {TasksAddModalComponent} from 'pages/modules/tasks/components/modals/add-modal/add-modal.component';
import {ModalService} from 'core/services/modal/modal.service';
import {TocViewItemResultInterface} from 'shared/modules/toc/interfaces/toc-view-item-result.interface';
import {ResultStateEnum} from 'shared/components/result-cell/enums/result-state.enum';
import {ProgressStatusEnum} from 'enums/progress-status.enum';
import {RoleEnum} from 'enums/role.enum';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import * as moment from 'moment';
import {UserService} from 'security/services/user/user.service';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-toc-view-book-item',
    templateUrl: 'toc-view-book-item.component.html',
})
export class TocViewBookItemComponent implements OnInit, OnChanges {

    public readonly roleEnum = RoleEnum;

    public readonly routesEnum = RoutesEnum;

    public readonly studyMaterialDocTypeEnum = StudyMaterialDocTypeEnum;

    private readonly progressStatusMap: Map<ProgressStatusEnum, ResultStateEnum> = new Map([
        [ProgressStatusEnum.ProgressStatusTaskAssigned, ResultStateEnum.Created],
        [ProgressStatusEnum.ProgressStatusInProgress, ResultStateEnum.Doing],
        [ProgressStatusEnum.ProgressStatusHandedIn, ResultStateEnum.HandedIn],
        [ProgressStatusEnum.ProgressStatusChecked, ResultStateEnum.Graded],
        [ProgressStatusEnum.ProgressStatusPartiallyFinished, ResultStateEnum.Graded],
        [ProgressStatusEnum.ProgressStatusFullyFinished, ResultStateEnum.Graded],
    ]);

    @ViewChild(TasksAddModalComponent, {static: false})
    public tasksAddModalComponent: TasksAddModalComponent;

    @Output()
    public expandEvent: EventEmitter<TocViewBookItemInterface> = new EventEmitter();

    @Output()
    public activeEvent: EventEmitter<TocViewBookItemInterface> = new EventEmitter();

    @Input()
    public bookItem: TocViewBookItemInterface;

    @Input()
    public bookId: number;

    @Input()
    public chapterId: number;

    @Input()
    public active: boolean;

    public displayChevron: boolean = false;

    public archiveModeActive: boolean = environment.archiveModeActive;

    public classes: Array<string> = [];

    public prefix: string = null;

    public classHelper: ClassHelper = new ClassHelper();

    constructor(
        private modalService: ModalService,
        private userService: UserService,
    ) {
    }

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classes);
        this.displayChevron = this.bookItem.children && this.bookItem.children.length > 0;

        if (this.bookItem.active && this.bookItem.children.length > 0) {
            this.setExpanded(true);
        }

        if (this.bookItem.disabled) {
            this.setDisabledState();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.prefix = this.getPrefix();

        if (changes.active) {
            this.setActiveState();
        }
    }

    public handleExpandClick(event: MouseEvent, setActive: boolean = false): void {
        event.stopPropagation();

        if (setActive) {
            this.setActive(true);
        }

        this.setExpanded(!this.bookItem.expanded);
    }

    public isAssignment(bookItem: TocViewBookItemInterface): boolean {
        // TODO Properly fix this...
        return bookItem.title.startsWith('Opdracht ');
    }

    public isExam(bookItem: TocViewBookItemInterface): boolean {

        return (bookItem.type === StudyMaterialTypeEnum.Exam) ||
            (bookItem.doctype === StudyMaterialDocTypeEnum.Exam);
    }

    public isPracticeExam(bookItem: TocViewBookItemInterface): boolean {
        return bookItem.type === StudyMaterialTypeEnum.PracticeExam  ||
            (bookItem.doctype === StudyMaterialDocTypeEnum.PracticeExam);
    }

    public handleAssignTask(event: Event, bookItem: TocViewBookItemInterface): void {
        event.stopPropagation();
        event.preventDefault();

        this.modalService.taskAdd(this.bookId, this.chapterId || null, bookItem.viewBook.dpsid);
    }

    public handleAssignExam(event: Event, bookItem: TocViewBookItemInterface): void {
        event.stopPropagation();
        event.preventDefault();

        this.modalService.examPlanAdd(this.bookId, bookItem.id, bookItem.viewBook.dpsid);
    }

    public getResultState(result: TocViewItemResultInterface = null): Array<ResultStateEnum> {

        if (result === null) {
            return [ResultStateEnum.None];
        }

        if (result.result !== null && result.result !== 0) {
            if (moment(result.duedate).isBefore(moment(result.handedin))) {
                const arrayState = [];
                arrayState.push(this.progressStatusMap.get(<ProgressStatusEnum>result.progress_status));
                arrayState.push(ResultStateEnum.TooLate);

                return arrayState;
            } else {
                return [ResultStateEnum.Graded];
            }
        }

        let state;

        if (moment(result.duedate).isBefore(moment())) {
            const arrayState = [];
            arrayState.push(this.progressStatusMap.get(<ProgressStatusEnum>result.progress_status));
            arrayState.push(ResultStateEnum.TooLate);

            return arrayState;
        } else {
            state = this.progressStatusMap.get(<ProgressStatusEnum>result.progress_status);
        }

        if (state === null) {
            return [ResultStateEnum.None];
        }

        return [state];
    }

    private setActive(value: boolean): void {
        this.bookItem.active = value;
        this.activeEvent.emit(this.bookItem);
    }

    private setExpanded(value: boolean): void {
        this.bookItem.expanded = value;
        this.expandEvent.emit(this.bookItem);
    }

    private getPrefix(): string | null {
        switch (this.bookItem.doctype) {
            case StudyMaterialDocTypeEnum.Question:
                return IconEnum.Check;

            case StudyMaterialDocTypeEnum.Theory:
                return IconEnum.ParagraphLeft;

            case StudyMaterialDocTypeEnum.Map:
                return IconEnum.Map;

            case StudyMaterialDocTypeEnum.MapWithoutDocument:
                return IconEnum.Map;

            case StudyMaterialDocTypeEnum.Exam:
                return IconEnum.Trophy;

            case StudyMaterialDocTypeEnum.PracticeExam:
                return IconEnum.TrophyBasic;
        }

        switch (this.bookItem.type) {
            case StudyMaterialTypeEnum.ModuleBook:
                return IconEnum.Book;

            case StudyMaterialTypeEnum.Exam:
            case StudyMaterialTypeEnum.ExamAssessment:
                return IconEnum.Trophy;

            case StudyMaterialTypeEnum.PracticeExam:
                return IconEnum.TrophyBasic;
        }

        return IconEnum.ParagraphLeft;
    }

    private setActiveState(): void {
        this.classHelper.toggleClassByBoolean(TocViewBooksItemClassEnum.Active, this.bookItem.active);
    }

    private setDisabledState(): void {
        this.classHelper.addClasses([TocViewBooksItemClassEnum.Disabled]);
    }
}
