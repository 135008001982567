import {
    AfterContentInit,
    AfterViewInit,
    Component,
    DoCheck,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

@Component({
    selector: 'app-read-speaker-button',
    templateUrl: 'read-speaker-button.component.html',
})
export class ReadSpeakerButtonComponent implements OnInit, AfterContentInit, AfterViewInit, DoCheck, OnDestroy {

    @Output()
    public playerShownEvent: EventEmitter<boolean> = new EventEmitter();

    public readSpeakerUrl: string;

    private readSpeakerInitialized = false;

    private readSpeakerInstance: any;

    constructor() {
        this.readSpeakerInstance = window['rspkr'];
    }

    public ngOnInit(): void {
        this.readSpeakerUrl = encodeURI(window.location.href);

        this.addClickEvents();
    }

    public ngAfterContentInit(): void {
        this.addClickEvents();
    }

    public ngAfterViewInit(): void {
        this.addClickEvents();
    }

    public ngDoCheck(): void {
        this.addClickEvents();
    }

    public ngOnDestroy(): void {
        if (this.readSpeakerInitialized !== true) {
            return;
        }

        if (!this.readSpeakerInstance || !this.readSpeakerInstance.PlayerAPI) {
            return;
        }

        this.readSpeakerInstance.PlayerAPI.stop();
        this.playerShownEvent.emit(false);
    }

    private addClickEvents(): void {
        if (this.readSpeakerInitialized === true) {
            return;
        }

        if (!this.readSpeakerInstance || !this.readSpeakerInstance.ui) {
            return;
        }

        this.readSpeakerInstance.ui.addClickEvents();

        if (!this.readSpeakerInstance.Common) {
            return;
        }

        this.readSpeakerInstance.Common.addEvent('onUIShowPlayer', () => {
            this.playerShownEvent.emit(true);
        });
        this.readSpeakerInstance.Common.addEvent('onUIClosePlayer', () => {
            this.playerShownEvent.emit(false);
        });

        this.readSpeakerInitialized = true;
    }
}
