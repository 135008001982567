import {AfterViewInit, Component} from '@angular/core';
import {HelpPageModalComponent} from 'shared/modals/help-page/help-page-modal.component';
import {IconEnum} from 'enums/icon.enum';
import {HelpPageEnum} from 'services/help-page/enums/help-page.enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HelpPageContentService} from 'services/help-page/help-page-content.service';
import {UserService} from 'security/services/user/user.service';
import {NoHelpPageModel} from 'shared/modals/help-page/models/no-help-page.model';

@Component({
    selector: 'app-question-help-page-modal',
    templateUrl: '../help-page-modal.component.html',
})
export class QuestionHelpPageModalComponent implements AfterViewInit {

    public icon: IconEnum = IconEnum.QuestionMark;

    constructor(
    ) {
    }

    public ngAfterViewInit(): void {
    }
}
