import {Component, EventEmitter, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxClose} from 'models/iconbox/close.model';

@Component({
    selector: 'app-demo-request',
    templateUrl: 'demo-request-modal.component.html',
})
export class DemoRequestModalComponent {

    @Output()
    public setRequestEvent: EventEmitter<boolean> = new EventEmitter<false>();

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorOrange]);

    constructor(
        private activeModal: NgbActiveModal,
    ) {
    }

    public close(): void {
        this.activeModal.close();
    }

    public setRequest(): void {
        this.setRequestEvent.emit(true);
    }
}
