export enum ModalClassModifiersEnum {

    Simple = 'modal--simple',

    Drillster = 'modal--drillster',

    Legend = 'modal--legend',

    TocTaskAssign = 'modal--toc-task-assign',

    TaskDelete = 'modal--task-delete',

    TaskNotExist = 'modal--task-not-exist',

    ExamAssign = 'modal--exam-assign',

    PositionBottom = 'modal--position-bottom',

    NoMargin = 'modal--no-margin',

    TeachingMaterials = 'modal--teaching-materials',

    SaveFavoritesSort = 'modal--save-favorites-sort',

    DemoRequest = 'modal--demo-request',

    ActivateLicense = 'modal--activate-license',

    ContentVersions = 'modal--content-versions',

    InviteExternalReviewer = 'modal--invite-external-reviewer',

    ReviewDone = 'modal--review-done',

    ReviewComplete = 'modal--review-complete',

    ReviewRevoked = 'modal--review-revoked',

    Exam = 'modal--exam',

    WithSubTitle = 'modal--with-sub-title',

    Organization = 'modal--organization',

    IFrame = 'modal--i-frame',

    Reader = 'modal--reader',

    WelcomeHelpPage = 'modal--help-page',

    QuestionHelpPage = 'modal--help-page modal--help-page-question',

    BooksHelpPage = 'modal--help-page modal--help-page-books',

    TasksHelpPage = 'modal--help-page modal--help-page-tasks',

    ExamsHelpPage = 'modal--help-page modal--help-page-exams',

    ProgressHelpPage = 'modal--help-page modal--help-page-progress',

    GroupsHelpPage = 'modal--help-page modal--help-page-groups',

    AddAttachment = 'modal--add-attachment',

    AddUrl= 'modal--add-url',
}
