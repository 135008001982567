import {Component, OnDestroy, OnInit} from '@angular/core';
import {DocumentService} from 'document/services/document/document.service';
import {SummaryService} from 'services/summary/summary.service';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {ModalService} from 'core/services/modal/modal.service';

@Component({
    selector: 'app-document-summary',
    templateUrl: 'summary.component.html',
})
export class DocumentPageActionsSummaryComponent implements OnInit, OnDestroy {

    public summary: ApiSummaryInterface;

    private documentSubscription: Subscription;

    private summarySubscription: Subscription;

    constructor(
        private documentService: DocumentService,
        private summaryService: SummaryService,
        private toastService: ToastrService,
        private modalService: ModalService,
    ) {
    }

    public ngOnInit(): void {
        this.documentSubscription = this.documentService.documentChanged.subscribe(() => this.loadData());
        this.summarySubscription = this.summaryService.summaryChange.subscribe((summary: ApiSummaryInterface) => this.onSummaryChange(summary));

        this.loadData();
    }

    public ngOnDestroy(): void {
        if (this.documentSubscription instanceof Subscription) {
            this.documentSubscription.unsubscribe();
        }

        if (this.summarySubscription instanceof Subscription) {
            this.summarySubscription.unsubscribe();
        }
    }

    public handleCreateEditSummary(): void {
        if (this.summary) {
            this.editSummary();
        } else {
            this.newSummary();
        }
    }

    private loadData(): void {
        const document = this.getDocument();

        if (!document) {
            return;
        }

        this.summaryService.retrieve(this.documentService.getBookId(), document.parent_uuid)
            .subscribe((summary: ApiSummaryInterface) => {
                if (summary instanceof Array) {
                    return;
                }

                this.summary = summary;
            }, (err) => {
                console.log(err);

                this.toastService.error('Kon je samenvatting niet ophalen wegens een fout');
            });
    }

    private onSummaryChange(summary: ApiSummaryInterface): void {
        if (summary instanceof Array || summary === null) {
            this.summary = null;

            return;
        }

        this.summary = summary;
    }

    private getDocument(): ApiDocumentDataInterface {
        return this.documentService.getDocument();
    }

    private newSummary(): void {
        const document = this.getDocument();

        if (!document) {
            return;
        }

        this.modalService.summaryCreate(this.documentService.getBookId(), document.title, document.parent_uuid, '');
    }

    private editSummary(): void {
        const document = this.getDocument();

        if (!document || !this.summary) {
            return;
        }

        this.modalService.summaryEdit(this.summary, this.summary.book_id, document.title, this.summary.uuid, this.summary.text);
    }
}
