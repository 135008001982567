import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {SearchInterface} from 'shared/modules/search/interfaces/search.interface';
import {IconEnum} from 'enums/icon.enum';
import {TaskStatusFilterEnum} from 'shared/components/status-filter/enum/task-status-filter.enum';
import {TaskMineFilterEnum} from 'shared/components/mine-filter/enum/mine-filter.enum';
import {InfiniteScroll} from 'shared/classes/infinite-scroll.abstract';
import {Observable, Subscription} from 'rxjs';
import {GroupsService} from 'services/groups/groups.service';
import {ClassHelper} from 'helpers/dom/class.helper';
import {ContentSelectItemInterface} from 'shared/modules/content-select/interfaces/select-item.interface';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {UserService} from 'security/services/user/user.service';
import {RoleEnum} from 'enums/role.enum';
import {TaskService} from 'services/task/task.service';
import {ModalService} from 'core/services/modal/modal.service';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {ActionButtonClassEnum} from 'shared/enums/action-button/class.enum';
import {IconBoxBookModel} from 'shared/models/icon-box/book.model';
import {SearchPrefixedDefaultModel} from 'shared/models/search/prefixed.model';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {BookService} from 'services/book/book.service';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-tasks',
    templateUrl: 'tasks.component.html'
})
export class TasksComponent extends InfiniteScroll<ApiTaskWeeksInterface> implements OnInit, OnDestroy {

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly tasksClassModifiers: Array<ContentSelectClassModifiersEnum> = [
        ContentSelectClassModifiersEnum.Tasks,
        ContentSelectClassModifiersEnum.Grey,
    ];

    public archiveModeActive = environment.archiveModeActive;

    public action: ActionButtonInterface = {
        id: '1',
        value: 'Eigen opdracht opgeven',
        iconBox: {
            value: IconEnum.Add,
            type: IconBoxTypeEnum.Icon,
        },
        classModifiers: [
            ActionButtonClassEnum.Orange,
        ],
    };

    public actionMobile: ActionButtonInterface = {
        id: '1',
        value: 'Eigen opdracht opgeven',
        iconBox: {
            value: IconEnum.Add,
            type: IconBoxTypeEnum.Icon,
        },
        classModifiers: [
            ActionButtonClassEnum.OrangeInversed,
        ],
    };

    public classHelper: ClassHelper = new ClassHelper();

    public weeks: ApiTaskWeeksInterface = null;

    public search: string = '';

    public future: boolean = true;

    public showFilters: boolean = true;

    public showGroupsFilter: boolean = true;

    public contentSwitchData: Array<ContentSelectItemInterface> = [];

    public contentSwitchIconBox = new IconBoxBookModel();

    public searchConfig: SearchInterface = new SearchPrefixedDefaultModel('Zoek een taak', null);

    public statusFilterOptions: Map<string, string>;

    public statusFilterInitial: string;

    public chooseBookOrGroup: boolean;

    public demo: boolean;

    public bookId: number;

    public licenseRequested: boolean;

    protected limit: number = 8;

    private taskEventSubscription: Subscription;

    private userChangedSubscription: Subscription;

    private selectedGroup: ApiGroupInterface;

    private selectedStudent: ApiUserInterface;

    private selectedMine: string | TaskMineFilterEnum;

    private selectedStatus: string | TaskStatusFilterEnum;

    public constructor(
        private taskService: TaskService,
        private groupsService: GroupsService,
        private userService: UserService,
        private modalService: ModalService,
        private authorizationService: AuthorizationService,
        private bookService: BookService,
        protected change: ChangeDetectorRef,
    ) {
        super(change);
    }

    public ngOnInit(): void {
        this.setStudentFilters();
        this.setTeacherFilter();
        this.future = this.taskService.getSelectedFuture();
        this.userChangedSubscription = this.userService.onUserDataChange.subscribe(() => this.setStudentFilters());

        super.ngOnInit();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.clearTaskEventSubscription();

        if (this.userChangedSubscription) {
            this.userChangedSubscription.unsubscribe();
        }
    }

    private setStudentFilters(): void {
        const user = this.userService.getUserData();

        if (user.role !== RoleEnum.RoleStudent) {
            return;
        }

        this.statusFilterOptions = new Map<string, string>([
            [TaskStatusFilterEnum.All, 'Alle'],
            [TaskStatusFilterEnum.Specified, 'Te maken'],
            [TaskStatusFilterEnum.HandedIn, 'Ingeleverd'],
            [TaskStatusFilterEnum.Done, 'Nagekeken'],
        ]);
        this.statusFilterInitial = TaskStatusFilterEnum.Specified;

        this.showGroupsFilter = user.group_count > 1;
    }

    private setTeacherFilter(): void {
        const user = this.userService.getUserData();

        if (user.role !== RoleEnum.RoleTeacher) {
            return;
        }

        this.statusFilterOptions = new Map<string, string>([
            [TaskStatusFilterEnum.All, 'Alle'],
            [TaskStatusFilterEnum.Specified, 'Opgegeven'],
            [TaskStatusFilterEnum.HandedIn, 'Ingeleverd'],
            [TaskStatusFilterEnum.Done, 'Nagekeken'],
        ]);
        this.statusFilterInitial = TaskStatusFilterEnum.HandedIn;

        this.showGroupsFilter = true;
    }

    public onStatusFilterChange(filterChange: string | TaskStatusFilterEnum): void {
        this.selectedStatus = filterChange;

        this.loadData(true);
    }

    public onMineFilterChange(filterChange: string | TaskMineFilterEnum): void {
        this.selectedMine = filterChange;

        this.loadData(true);
    }

    public onGroupsFilterChange(group: ApiGroupInterface | null): void {
        if (this.selectedGroup !== group) {
            this.selectedGroup = group;

            this.loadData(true);
        }
    }

    public onStudentsFilterChange(student: ApiUserInterface | null): void {
        if (this.selectedStudent !== student) {
            this.selectedStudent = student;

            this.loadData(true);
        }
    }

    public handleAddTask(): void {
        const modalInstance = this.modalService.taskAddOwnTask(true);

        this.clearTaskEventSubscription();

        modalInstance.taskAddEvent.subscribe((task: any) => {
            this.loadData(true);
        });
    }

    public onSearchChange(search: string): void {
        this.search = search;
        this.loadData(true);
    }

    public toggleFuture(): void {
        this.future = !this.future;
        this.taskService.setSelectedFuture(this.future);
        this.loadData(true);
    }

    public toggleFilters(): void {
        this.showFilters = !this.showFilters;
    }

    public onContentItemClick(data: Array<ContentSelectItemInterface>): void {

        if (JSON.stringify(data) === JSON.stringify(this.contentSwitchData)) {
            return;
        }

        this.contentSwitchData = JSON.parse(JSON.stringify(data));
        this.loadData(true);
    }

    public onLoadTasks(weekId: string): void {
        const activeGroup = this.getActiveGroupOrStudent();
        const activeBook = this.getActiveBookOrChapter();

        this.taskService.retrieveTasks(
            weekId,
            this.search,
            this.selectedStatus,
            this.selectedMine === TaskMineFilterEnum.Mine,
            activeGroup.groupId,
            activeGroup.studentId,
            activeBook.bookId,
            activeBook.chapterId
        ).subscribe((tasks: ApiTasksInterface) => {
            for (const week of this.weeks.data) {
                if (week.id === weekId) {
                    week.tasks = tasks;
                    break;
                }
            }
        });
    }

    protected loadData(reset: boolean = false): void {
        if (!this.isFilterSelectionReady()) {
            return;
        }

        if (
            this.authorizationService.isGranted(RoleEnum.RoleTeacher) &&
            !this.onlyShowMine() &&
            this.getActiveGroupOrStudent().groupId === null &&
            this.getActiveBookOrChapter().bookId === null
        ) {
            this.chooseBookOrGroup = true;

            return;
        }

        this.chooseBookOrGroup = false;

        this.getDemoStates();

        super.loadData(reset);
    }

    protected isFilterSelectionReady(): boolean {
        const isGrantedTeacher: boolean = this.authorizationService.isGranted(RoleEnum.RoleTeacher);
        const teacherHasUndefinedSelection: boolean = isGrantedTeacher && [this.selectedStudent, this.selectedMine].includes(undefined);
        const hasGroupSelection: boolean = this.showGroupsFilter ? this.selectedGroup !== undefined : true;
        const hasContentSwitchData: boolean = this.contentSwitchData.length !== 0;
        const hasSelectedStatus: boolean = this.selectedStatus !== undefined;

        return (hasGroupSelection && hasContentSwitchData && hasSelectedStatus && !teacherHasUndefinedSelection);
    }

    protected getDataLength(): number {
        return this.weeks ? this.weeks.total : 0;
    }

    protected getLoadDataObservable(): Observable<ApiTaskWeeksInterface> {
        const activeGroup = this.getActiveGroupOrStudent();
        const activeBook = this.getActiveBookOrChapter();

        return this.taskService.retrieveWeeks(
            this.future,
            this.getRequestOffset(),
            this.limit.toString(),
            this.search,
            this.selectedStatus,
            this.onlyShowMine(),
            activeGroup.groupId,
            activeGroup.studentId,
            activeBook.bookId,
            activeBook.chapterId
        );
    }

    protected subscribe(response: ApiTaskWeeksInterface): void {
        if (this.weeks !== null) {
            this.weeks.data = [...this.weeks.data, ...response.data];
            this.weeks.total = response.total;
        } else {
            this.weeks = response;
        }
    }

    protected reset(): void {
        super.reset();

        this.weeks = null;
    }

    private clearTaskEventSubscription(): void {
        if (this.taskEventSubscription instanceof Subscription) {
            this.taskEventSubscription.unsubscribe();
        }
    }

    private getActiveGroupOrStudent(): any {
        const ret = {groupId: null, studentId: null};

        if (this.selectedGroup) {
            ret.groupId = this.selectedGroup.id;

            if (this.selectedStudent) {
                ret.studentId = this.selectedStudent.id;
            }
        }

        return ret;
    }

    private getActiveBookOrChapter(): any {
        const ret = {bookId: null, chapterId: null};

        booksLoop:
            for (const book of this.contentSwitchData) {
                if (book.active === true) {
                    ret.bookId = book.id;
                    break;
                }

                for (const chapter of book.children) {
                    if (chapter.active === true) {
                        ret.bookId = book.id;
                        ret.chapterId = chapter.id;
                        break booksLoop;
                    }
                }
            }

        return ret;
    }

    private onlyShowMine(): boolean {
        return this.selectedMine === TaskMineFilterEnum.Mine;
    }

    private getDemoStates(): void {
        this.isLoading = true;
        const activeBook = this.getActiveBookOrChapter();
        if (activeBook && activeBook.bookId) {
            this.bookService.getBook(String(activeBook.bookId)).subscribe((book: ApiBookInterface) => {
                this.demo = book.demo;
                this.bookId = book.id;
                this.licenseRequested = book.licenseRequested;
                this.isLoading = false;
            }, (error: any) => {
                this.demo = false;
                this.isLoading = false;
                console.error(error);
            });
        } else {
            this.demo = false;
        }
    }
}
