import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {StudyMaterialDocTypeEnum} from 'enums/study-material-doc-type.enum';
import {TocViewBookItemInterface} from 'shared/modules/toc/interfaces/toc-view-book-item.interface';
import {DocumentAssignmentStatusEnum} from 'pages/modules/document/enums/document-assignment-status.enum';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {AlgebraKIT} from 'assets/js/akit-widgets.js';
import {ToastrService} from 'ngx-toastr';
import {environment} from 'environments/environment';

declare var AlgebraKIT: AlgebraKIT;

@Component({
    selector: 'app-document-page-content-actions',
    templateUrl: 'content-actions.component.html',
})
export class DocumentPageContentActionsComponent implements OnInit, OnChanges {

    public readonly statusEnum = DocumentAssignmentStatusEnum;

    public readonly routesEnum = RoutesEnum;

    public readonly studyMaterialDocTypeEnum = StudyMaterialDocTypeEnum;

    @Input()
    public bookId: number;

    @Input()
    public document: ApiDocumentDataInterface;

    @Input()
    public toc: Array<TocViewBookItemInterface>;

    @Input()
    public assignment: ApiDocumentAssignmentInterface = null;

    @Output()
    public submitted: EventEmitter<null> = new EventEmitter();

    @Input()
    public status: DocumentAssignmentStatusEnum;

    public previous: TocViewBookItemInterface | null = null;

    public next: TocViewBookItemInterface | null = null;

    public algebraKit: AlgebrakitInterface | null = null;

    public archiveModeActive = environment.archiveModeActive;

    public isAutoCheck: boolean;

    constructor(
        private toastrService: ToastrService,
    ) {
        AlgebraKIT.addExerciseListener((data) => {
            if (data) {
                this.algebraKit = data;
            }
        });
    }

    public ngOnInit(): void {
        this.setNextPrevious();
        this.isAutoCheck = this.assignment && this.assignment.auto_check;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.setNextPrevious();
    }

    private setNextPrevious(): void {
        if (!this.document && !this.toc) {
            return;
        }

        const flatToc = this.getFlattenedToc(this.toc, 0);
        const index = this.getCurrentIndex(flatToc);

        if (index !== null) {
            this.previous = this.getPrevious(flatToc, index);
            this.next = this.getNext(flatToc, index);
        }
    }

    private getFlattenedToc(toc: Array<TocViewBookItemInterface>, depth: number): Array<TocViewBookItemInterface> {
        let flattenedToc = [];

        for (const item of toc) {
            flattenedToc.push(item);

            if (item.children) {
                flattenedToc = flattenedToc.concat(this.getFlattenedToc(item.children, depth + 1));
            }
        }

        return flattenedToc;
    }

    private getCurrentIndex(toc: Array<TocViewBookItemInterface>): number | null {
        for (let i = 0; i < toc.length; i++) {
            if (toc[i].viewBook && toc[i].viewBook.dpsid && toc[i].viewBook.dpsid === this.document.dpsid) {
                return i;
            }
        }

        return null;
    }

    private getPrevious(toc: Array<TocViewBookItemInterface>, index: number): TocViewBookItemInterface | null {
        for (let i = index - 1; i >= 0; i--) {
            if (this.isProperBookChapter(toc[i])) {
                return toc[i];
            }
        }

        return null;
    }

    private getNext(toc: Array<TocViewBookItemInterface>, index: number): TocViewBookItemInterface | null {
        for (let i = index + 1; i < toc.length; i++) {
            if (this.isProperBookChapter(toc[i])) {
                return toc[i];
            }
        }

        return null;
    }

    private isProperBookChapter(item: TocViewBookItemInterface): boolean {
        return item.viewBook && item.viewBook.dpsid && item.viewBook.doctype !== StudyMaterialDocTypeEnum.MapWithoutDocument;
    }

    public scrollToTop(): void {
        window.scroll(0, 0);
    }

    public onSubmit(): void {
        if (this.status !== DocumentAssignmentStatusEnum.Open && this.status !== DocumentAssignmentStatusEnum.SelfScoringDone) {
            return;
        }

        if (this.algebraKit && this.algebraKit.event !== 'exercise-finished' && this.algebraKit.event !== 'interaction-evaluate') {
            this.toastrService.error('De opdracht is nog niet volledig afgerond, rond deze eerst af voordat je inlevert.');

            return;
        }

        this.submitted.emit();
    }
}
