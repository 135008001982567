import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {ExamService} from 'services/exam/exam.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {RoleEnum} from 'enums/role.enum';

@Component({
    selector: 'app-exams-result',
    templateUrl: 'exams-result.component.html',
})
export class ExamsResultComponent implements OnInit, OnDestroy {

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly routesEnum = RoutesEnum;

    public readonly roleEnum = RoleEnum;

    public isLoading: boolean = false;

    public examPlanId: number;

    public studentId: number;

    public exam: ApiExamDetailsInterface;

    public examUrl: SafeResourceUrl;

    private routeParamSubscription: Subscription;

    public constructor(
        private examService: ExamService,
        private toastService: ToastrService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
    ) {}

    public ngOnInit(): void {
        this.routeParamSubscription = this.route.params.subscribe(() => {
            this.loadData();
        });
    }

    public ngOnDestroy(): void {
        if (this.routeParamSubscription instanceof Subscription) {
            this.routeParamSubscription.unsubscribe();
        }
    }

    private loadData(): void {
        const planId = this.route.snapshot.params.planId;
        const resultId = this.route.snapshot.params.resultId;
        this.studentId = this.route.snapshot.params.studentId;

        if (planId !== 0) {
            this.examPlanId = planId;
        }

        if (!resultId || this.isLoading === true) {
            return;
        }

        this.isLoading = true;

        this.examService.retrieveResult(resultId).toPromise()
            .then((result: string) => {
                this.setResult(result);

                return Promise.resolve([]);
            })
            .catch((error: any) => {
                console.error(error);

                this.toastService.error('Kon examen resultaat niet ophalen.');
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    private setResult(result: string): void {
        if (result) {
            this.examUrl = this.sanitizer.bypassSecurityTrustResourceUrl(<string>result);
        }
    }
}
