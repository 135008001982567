import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DatePickerFormatter} from 'shared/date-picker/date-picker-formatter';
import * as moment from 'moment';

@Component({
    selector: 'app-date-picker',
    templateUrl: 'date-picker.component.html',
})
export class DatePickerComponent {
    @Input()
    public placeholder: string = DatePickerFormatter.DATE_FORMAT;

    @Input()
    public model: NgbDateStruct | string | null;

    @Output()
    public modelChange: EventEmitter<NgbDateStruct> = new EventEmitter();

    @Input()
    public minDate: NgbDateStruct | null;

    public onModelChange(): void {
        const model: NgbDateStruct | string | null = this.model;
        if (!this.isDateStruct(model)) {
            return;
        }

        this.modelChange.emit(model);
    }

    /**
     * BD-2302: We'll allow the following formats:
     * 1-2-22, 1-2-2022, 01-02-22, 01-02-2022, 1/2/22, 1/2/2022, 01/02/22
     *
     * This function is triggered on blur, it only acts when the model is not a NgbDateStruct
     */
    public onBlur(): void {
        let model: NgbDateStruct | string | null = this.model;
        if (this.isDateStruct(model) || null === model) {
            return;
        }

        const matches: RegExpMatchArray | null = new RegExp(/^(\d{1,2})[-/\\](\d{1,2})[-/\\](\d{2,4})$/g).exec(model);
        if (null === matches) {
            return;
        }

        const momentDate = moment(
            `${matches[1].padStart(2, '0')}/${matches[2].padStart(2, '0')}/${matches[3].padStart(4, String((new Date()).getFullYear()).substring(0, 2))}`,
            DatePickerFormatter.DATE_FORMAT,
            true
        );

        model = this.model = NgbDate.from({
            day: Number(momentDate.format('DD')),
            month: Number(momentDate.format('MM')),
            year: Number(momentDate.format('YYYY')),
        });

        this.modelChange.emit(model);
    }

    private isDateStruct(model: NgbDateStruct | string | null): model is NgbDateStruct {
        return null !== model && model.hasOwnProperty('day') && model.hasOwnProperty('month') && model.hasOwnProperty('year');
    }
}
