import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {DocumentNotesComponent} from 'document/components/notes/notes.component';

@Directive({
    selector: '[appDocumentNotesTrigger]',
})
export class DocumentNotesTriggerDirective {

    @Input('appDocumentNotesTrigger')
    public notesComponent: DocumentNotesComponent;

    public enabled: boolean = true;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
    ) {}

    @HostListener('mousedown', ['$event'])
    public onMouseDown(event: MouseEvent): void {
        if (!this.enabled) {
            return;
        }
        this.notesComponent.showButton(event);
    }

    @HostListener('document:mousedown', ['$event'])
    public onDocumentClick(event: MouseEvent): void {
        if (!this.enabled) {
            return;
        }

        const target: HTMLElement = <HTMLElement>event.target;

        if (this.elementRef.nativeElement.contains(target)) {
            return;
        }

        if (target.classList && target.classList.contains('document-notes-button__button')) {
            return;
        } else if (target.className && target.className.indexOf('document-notes-button__button') >= 0) {
            return;
        }

        this.notesComponent.hideButton();
    }
}
