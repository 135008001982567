import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {Observable, Observer} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DocumentService {

    protected currentDocument: CurrentDocumentInterface;

    protected currentToc: ApiDocumentDataTocInterface;

    protected activeDocument: Array<ApiDocumentDataInterface>;

    public constructor(
        private api: ApiService,
    ) {
    }

    public retrieveDocumentVersions(dpsid: string, bookId: string, taskId?: string): Observable<Array<ApiDocumentDataInterface>> {
        const params = [
            ['dpsid', dpsid],
            ['book', bookId],
        ];

        if (taskId) { params.push(['task', taskId]); }

        return this.api.get<Array<ApiDocumentDataInterface>>(ApiEndpointEnum.Document, new URLSearchParams(params));
    }

    public retrieveDocumentToc(dpsid: string, bookId: string): Observable<ApiDocumentDataTocInterface> {
        return new Observable<ApiDocumentDataTocInterface>((observer: Observer<ApiDocumentDataTocInterface>) => {
            if (this.currentToc === undefined || this.currentToc.book.id !== Number(bookId)) {
                const apiEndpoint: string = ApiEndpointEnum.BookToc.replace(':bookId', bookId);

                this.api.get<ApiDocumentDataTocInterface>(apiEndpoint).subscribe(data => {
                    this.currentToc = data;

                    this.resolveCurrentDocument(data.book, dpsid, data.body);
                    observer.next(data);
                });
            } else {
                this.resolveCurrentDocument(this.currentToc.book, dpsid, this.currentToc.body);
                observer.next(this.currentToc);
            }
        });
    }

    public getCurrentDocument(): CurrentDocumentInterface | undefined {
        return this.currentDocument;
    }

    protected setCurrentDocument(book: ApiBookInterface, chapter: ApiBookChapterInterface): void {
        this.currentDocument = {book, chapter};
    }

    public getActiveDocument(): Array<ApiDocumentDataInterface> {
        return this.activeDocument;
    }

    public setActiveDocument(document: Array<ApiDocumentDataInterface>): void {
        this.activeDocument = document;
    }

    protected resolveCurrentDocument(book: ApiBookInterface, dpsid: string, chapters: Array<ApiTocViewBooksInterface>): void {
        chapters.some((chapter: ApiTocViewBooksInterface) => {
            const child: ApiTocViewBooksInterface = this.resolveChildByDpsid(dpsid, chapter.children);

            if (child !== undefined) {
                this.setCurrentDocument(book, book.chapters.find(bookChapter => bookChapter.id === chapter.id));

                return true;
            }
        });
    }

    protected resolveChildByDpsid(dpsid: string, documents: Array<ApiTocViewBooksInterface>): ApiTocViewBooksInterface {
        let document: ApiTocViewBooksInterface = documents.find(child => child.dpsid === dpsid);

        if (document === undefined) {
            documents.some((documentChild: ApiTocViewBooksInterface) => {
                document = this.resolveChildByDpsid(dpsid, documentChild.children || []);

                return document !== undefined;
            });
        }

        return document;
    }
}
