import {Component, Input} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {RoutesEnum} from 'routing/enums/routes.enum';

@Component({
    selector: 'app-popover-handed-in',
    templateUrl: 'popover-handed-in.component.html',
})
export class ProgressPopoverHandedInComponent {

    public readonly iconEnum = IconEnum;

    public readonly tableClasses = GridTableClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly routesEnum = RoutesEnum;

    @Input()
    public task: ApiTaskDetailsInterface = null;

    @Input()
    public bookId: number = null;

    @Input()
    public dpsid: string = null;

    @Input()
    public studentsHandedIn: Array<ApiUserInterface> = null;

    @Input()
    public studentsNotHandedIn: Array<ApiUserInterface> = null;

    public studentsHandedInCount = 0;

    public studentsNotHandedInCount = 0;

    public taskGetStudentsHandedIn(): Array<ApiUserInterface> {
        if (this.studentsHandedIn !== null) {
            this.studentsHandedInCount = this.studentsHandedIn.length;

            return this.studentsHandedIn;
        }

        if (this.task && !this.task.children) {
            return;
        }

        const students = this.task.children.filter((childTask: ApiTaskDetailsInterface) => {
            return childTask.handedin !== null;
        }).map((childTask: ApiTaskDetailsInterface) => {
            return childTask.assigned_to;
        });

        this.studentsHandedInCount = students.length;

        return students;
    }

    public taskGetStudentsNotHandedIn(): Array<ApiUserInterface> {
        if (this.studentsNotHandedIn !== null) {
            this.studentsNotHandedInCount = this.studentsNotHandedIn.length;

            return this.studentsNotHandedIn;
        }

        if (this.task && !this.task.children) {
            return;
        }

        const students = this.task.children.filter((childTask: ApiTaskDetailsInterface) => {
            return childTask.handedin === null;
        }).map((childTask: ApiTaskDetailsInterface) => {
            return childTask.assigned_to;
        });

        this.studentsNotHandedInCount = students.length;

        return students;
    }
}
