import {DocumentPageActionsButtonsEnum} from 'pages/modules/document/enums/actions-buttons.enum';
import {DocumentActionButtonInterface} from 'pages/modules/document/interfaces/action-button.interface';
import {IconEnum} from 'enums/icon.enum';
import {RoleEnum} from 'enums/role.enum';

export class DocumentActionsButtonTocModel implements DocumentActionButtonInterface {

    public id: DocumentPageActionsButtonsEnum = DocumentPageActionsButtonsEnum.TableOfContents;

    public value: string = 'Inhoudsopgave';

    public icon: IconEnum = IconEnum.Unit;

    public roles: Array<RoleEnum> = [
        RoleEnum.RoleStudentOrTeacher,
    ];

    constructor(public classModifiers: Array<string>, public visible: boolean = true) {
    }
}
