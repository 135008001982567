export enum IconBoxClassEnum {

    Icon = 'icon-box--icon',

    Prefixed = 'icon-box--prefixed',

    Affixed = 'icon-box--affixed',

    ColorBlue = 'icon-box--color-primary',

    ColorOrange = 'icon-box--color-orange',

    ColorTask = 'icon-box--color-orange',

    ColorGreen = 'icon-box--color-green',

    ColorPurple = 'icon-box--color-purple',

    ColorExam = 'icon-box--color-red',

    ColorTransparent = 'icon-box--color-transparent',

    ButtonCloseWhite = 'icon-box--button-24boost-white',

    ButtonCloseBlue = 'icon-box--button-24boost-blue',

    ButtonCloseGreen = 'icon-box--button-24boost-green',

    TocBook = 'icon-box--toc-book',

    Header = 'icon-box--header',

    Navbar = 'icon-box--navbar',

    Padding = 'icon-box--padding',

    ContentMark = 'icon-box--content-mark',

    BorderNone = 'icon-box--border-none',

    BorderNoRadius = 'icon-box--border-no-radius',

    NoBorder = 'icon-box--no-border',

    Material = 'icon-box--material',

    MaterialUploadedSource = 'icon-box--material-uploaded-source',

    MaterialUploadedSourceDisabled = 'icon-box--material-uploaded-source-disabled',

    Groups = 'icon-box--groups',

    AddGroupsModal = 'icon-box--add-groups-modal',

    DocumentReference = 'icon-box--document-reference',

    Drillster = 'icon-box--drillster',

    Legend = 'icon-box--legend',

    LegendClose = 'icon-box--legend-close',

    PopoverClose = 'icon-box--popover-close',

    Clickable = 'icon-box--clickable',

    ModalClose = 'icon-box--modal-close',

    MessageCenter = 'icon-box--message-center',

    Questions = 'icon-box--questions',

    Info = 'icon-box--info',

    InfoSolid = 'icon-box--info-solid',

    NeutralFeedback = 'icon-box--neutral-feedback',

}
