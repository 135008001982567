import {RoleEnum} from 'enums/role.enum';

export const RolesMapping = new Map<RoleEnum, Array<RoleEnum>>([
    [
        RoleEnum.RoleStudent,
        [
            RoleEnum.RoleStudentOrTeacher,
        ],
    ],
    [
        RoleEnum.RoleTeacher,
        [
            RoleEnum.RoleStudentOrTeacher,
        ],
    ],
    [
        RoleEnum.RoleReviewer,
        [
            // Add roles
        ],
    ],
    [
        RoleEnum.RoleNavbarAll,
        [
            RoleEnum.RoleNavbarTests,
            RoleEnum.RoleNavbarTasks,
            RoleEnum.RoleNavbarProgress,
            RoleEnum.RoleNavbarMaterial,
            RoleEnum.RoleNavbarGroups,
        ],
    ],
    [
        RoleEnum.RoleTocAll,
        [
            RoleEnum.RoleTocAssignTask,
            RoleEnum.RoleTocAssignExam,
        ],
    ],
    [
        RoleEnum.RoleDocumentAll,
        [
            RoleEnum.RoleDocumentAssignTask,
        ],
    ]
]);
