import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {CollapsibleClassEnum} from 'shared/components/collapsible/enums/class.enum';
import {GroupsModuleGroupNamesEnum} from 'pages/modules/groups/enums/group-names.enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import {SortEnum} from 'enums/sort.enum';
import {GroupsModuleGroupInterface} from 'pages/modules/groups/interfaces/group.interface';
import {GroupsModulePersonInterface} from 'pages/modules/groups/interfaces/person.interface';
import {HttpErrorResponse} from '@angular/common/http';
import {GroupsService} from 'services/groups/groups.service';
import {ModalService} from 'core/services/modal/modal.service';
import {GroupsModuleGroupModeEnum} from 'pages/modules/groups/enums/group-mode.enum';
import {GroupsGroupHeaderComponent} from 'pages/modules/groups/components/group-header/group-header.component';
import {GroupsModuleActionsEnum} from 'pages/modules/groups/enums/group-actions.enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {GroupsModuleGroupHeaderClassesEnum} from 'pages/modules/groups/enums/group-header-classes.enum';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {IconEnum} from 'enums/icon.enum';
import {finalize} from 'rxjs/operators';
import {RoleEnum} from 'enums/role.enum';
import * as underscore from 'underscore';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {UserService} from 'security/services/user/user.service';
import {ActionButtonClassEnum} from 'shared/enums/action-button/class.enum';
import {ActionButtonFactory} from 'shared/factories/action-button.factory';

@Component({
    selector: 'app-groups-group-component',
    templateUrl: 'group.component.html',
})
export class GroupsGroupComponent implements OnInit, OnDestroy {

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly groupNames = GroupsModuleGroupNamesEnum;

    public readonly groupActions = GroupsModuleActionsEnum;

    @ViewChild(GroupsGroupHeaderComponent, { static: true })
    public header: GroupsGroupHeaderComponent;

    @Input()
    public mode: GroupsModuleGroupModeEnum = GroupsModuleGroupModeEnum.Default;

    @Input()
    public group: GroupsModuleGroupInterface;

    @Input()
    public expanded: boolean;

    @Output()
    public checkedEvent: EventEmitter<GroupsModuleGroupInterface> = new EventEmitter();

    @Output()
    public groupRemovedEvent: EventEmitter<GroupsModuleGroupInterface> = new EventEmitter();

    public collapsibleClasses = CollapsibleClassEnum;

    public iconBox: IconBoxInterface;

    public personRemoval: boolean = true;

    public personSorting: boolean = true;

    public displayCollapse: boolean = true;

    public classHelper: ClassHelper = new ClassHelper();

    private groupEditSubscription: Subscription;

    @Input()
    set classModifiers(modifiers: Array<string>) {
        this.classHelper.addClasses(modifiers);
    }

    get classModifiers(): Array<string> {
        return this.classHelper.getClasses();
    }

    constructor(
        private groupsService: GroupsService,
        private toastService: ToastrService,
        private modalService: ModalService,
        private userService: UserService,
        private actionButtonFactory: ActionButtonFactory,
    ) {
    }

    public ngOnInit(): void {
        this.setupComponent();
    }

    public ngOnDestroy(): void {
        if (this.groupEditSubscription instanceof Subscription) {
            this.groupEditSubscription.unsubscribe();
        }
    }

    public handleGroupAction(action: string): void {
        if (this.group.loading) { // Take no action whilst loading
            return;
        }

        switch (action) {
            case GroupsModuleActionsEnum.Edit:
                this.handleEditGroup();
                break;

            case GroupsModuleActionsEnum.Active:
                this.handleToggleGroup();
                break;

            case GroupsModuleActionsEnum.RenewCode:
                this.handleRenewCode();
                break;

            case GroupsModuleActionsEnum.Actions:
                this.handleGroupActions();
                break;

            case GroupsModuleActionsEnum.Expand:
                this.groupsService.getGroup(this.group.id).subscribe((response: ApiGroupInterface) => {
                    Object.assign(this.group, response);
                });
                this.expanded = !this.expanded;
                break;

            case GroupsModuleActionsEnum.Checked:
                this.group.checked = !this.group.checked;
                this.checkedEvent.emit(this.group);
                break;
        }
    }

    public handleSorting(direction: SortEnum, group: GroupsModuleGroupNamesEnum): void {
        switch (group) {
            case GroupsModuleGroupNamesEnum.Teachers:
                this.group.teachers = this.getSortedGroup(direction, this.group.teachers);
                break;
            case GroupsModuleGroupNamesEnum.Students:
                this.group.students = this.getSortedGroup(direction, this.group.students);
                break;
        }
    }

    public handleRemove(person: GroupsModulePersonInterface): void {
        this.group.loading = true;
        switch (person.role) {
            case RoleEnum.RoleStudent:
                this.removeStudent(person);
                break;

            case RoleEnum.RoleTeacher:
                this.removeTeacher(person);
                break;
        }
    }

    private handleRenewCode(): void {
        this.group.loading = true;
        this.groupsService.renewCode(this.group.id)
            .pipe(finalize(() => this.group.loading = false))
            .subscribe((group: ApiGroupInterface) => {
                this.group.code = group.code;
            });
    }

    private handleToggleGroup(): void {
        this.group.loading = true;
        this.groupsService.toggleActive(this.group.id, !this.group.active)
            .pipe(finalize(() => this.group.loading = false))
            .subscribe((group: ApiGroupInterface) => {
                this.group.active = group.active;
            }, (errorResponse: HttpErrorResponse) => {
                this.toastService.error('Kon groep niet aanpassen');
            });
    }

    private handleEditGroup(): void {
        const modalInstance = this.modalService.groupEdit(this.group);

        this.groupEditSubscription = modalInstance.groupEvent.subscribe((group: ApiGroupInterface) => {
            this.group.name = group.name;
            this.group.year = group.year;
        });
    }

    private handleGroupActions(): void {
        const modalInstance = this.modalService.groupActions(
            this.group,
            this.header.actions.filter((action) => action.id !== GroupsModuleActionsEnum.Actions),
        );

        modalInstance.groupActionEvent.subscribe((action: string) => this.handleGroupAction(action));
    }

    private removeTeacher(person: GroupsModulePersonInterface): void {
        this.groupsService.removeTeacher(person.id, this.group.id)
            .pipe(finalize(() => this.group.loading = false))
            .subscribe(() => {
                this.removePersonFromCollection(this.group.teachers, person);
            }, (errorResponse: HttpErrorResponse) => this.handleRemoveError(errorResponse));
    }

    private removeStudent(person: GroupsModulePersonInterface): void {
        this.groupsService.removeStudent(person.id, this.group.id)
            .pipe(finalize(() => this.group.loading = false))
            .subscribe(() => {
                this.removePersonFromCollection(this.group.students, person);
            }, (errorResponse: HttpErrorResponse) => this.handleRemoveError(errorResponse));
    }

    private removePersonFromCollection(collection: Array<GroupsModulePersonInterface>, person: GroupsModulePersonInterface): void {
        if (this.userService.getCurrentUserId() === person.id) {
            this.groupRemovedEvent.emit(this.group);

            return;
        }

        const targetPerson: GroupsModulePersonInterface = collection.find((member) => member.id === person.id);
        const personIndex = collection.indexOf(targetPerson);

        if (personIndex === -1) {
            throw new Error(`Unable to find person with ID#${person.id} in collection`);
        }

        collection.splice(personIndex, 1);
    }

    private handleRemoveError(errorResponse: HttpErrorResponse): void {
        this.toastService.error('Kon gebruiker niet van groep verwijderen');
    }

    private getSortedGroup(direction: SortEnum, group: Array<GroupsModulePersonInterface>): Array<GroupsModulePersonInterface> {
        const sortedGroup = underscore.chain(group)
            .sortBy((member: GroupsModulePersonInterface) => {
                return [member.firstName, member.lastName];
            }).value();

        return direction === SortEnum.Asc ? sortedGroup : sortedGroup.reverse();
    }

    /**
     * Group should loads it's settings based on mode, I'm not really happy about this part yet.
     * We should replace this with a somewhat better way for loading/applying settings
     */
    private setupComponent(): void {
        switch (this.mode) {
            case GroupsModuleGroupModeEnum.Compact:
                this.loadCompact();
                break;

            default:
                this.loadDefault();
                break;
        }
    }

    private loadCompact(): void {
        this.header.iconBox.classModifiers = [IconBoxClassEnum.AddGroupsModal];
        this.header.classHelper.addClass(GroupsModuleGroupHeaderClassesEnum.AddGroup);
        this.personRemoval = false;
        this.personSorting = false;
        this.displayCollapse = false;
    }

    private loadDefault(): void {
        this.header.displayCheckbox = false;
        this.header.actions = [
            this.actionButtonFactory.createToggleButton(GroupsModuleActionsEnum.Active, this.group.active),
            this.actionButtonFactory.createGreenIconButton(GroupsModuleActionsEnum.RenewCode, IconEnum.Refresh),
            this.actionButtonFactory.createGreenIconButton(GroupsModuleActionsEnum.Edit, IconEnum.Edit),
            this.actionButtonFactory.createGreenIconButton(GroupsModuleActionsEnum.Actions, IconEnum.Options, [
                ActionButtonClassEnum.MediaSmallAndDown,
            ]),
        ];
    }
}
