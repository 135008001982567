import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ExamStateInterface, ExamStateService} from 'pages/modules/content-exam/service/exam-state.service';
import {AutoCheckService} from 'document/services/autocheck/auto-check.service';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-content-exam-page-document',
    templateUrl: 'page-document.component.html',
})
export class ContentExamPageDocumentComponent implements OnInit, OnDestroy {
    private examStateSubscription: Subscription;

    @Input()
    public bookId: number;

    @Input()
    public document: ApiDocumentDataInterface;

    public constructor(
        private autoCheckService: AutoCheckService,
        private examStateService: ExamStateService,
        private documentAnswersStateService: DocumentAnswersStateService
    ) {
    }

    public ngOnInit(): void {
        this.documentAnswersStateService.reset();
        this.examStateSubscription = this.examStateService.subscribe(examState => this.onExamState(examState));
    }

    private onExamState(examState: ExamStateInterface): void {
        const assignment: ApiExamAssignmentInterface | undefined = examState.assignment;

        if (undefined === this.document || !examState.hasResults || undefined === assignment) {
            return;
        }

        const result = this.document.assignments.some(documentAssignment => {
            if (documentAssignment.dpsid === assignment.dpsId) {
                this.autoCheckService.setAutoCheckAnswers(documentAssignment);

                return true;
            }

            return false;
        });

        this.documentAnswersStateService.toggleAnswers(result);
    }

    public ngOnDestroy(): void {
        if (undefined !== this.examStateSubscription) {
            this.examStateSubscription.unsubscribe();
        }
    }
}
