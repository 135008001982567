export enum AlertMessageClassEnum {

    Progress = 'alert-message--progress',

    TaskDelete = 'alert-message--task-delete',

    TaskNotAvailable = 'alert-message--task-not-available',

    ExamDelete = 'alert-message--exam-delete',

}
