import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RedactorService} from 'services/redactor/redactor.service';
import {SummaryService} from 'services/summary/summary.service';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {ToastrService} from 'ngx-toastr';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {LinkTargetEnum} from 'enums/link-target.enum';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {IconEnum} from 'enums/icon.enum';
import {ActionButtonClassEnum} from 'shared/enums/action-button/class.enum';
import {IconBoxClose} from 'models/iconbox/close.model';

@Component({
    selector: 'app-summary-modal',
    templateUrl: 'summary-modal.component.html',
})
export class SummaryModalComponent implements OnInit, AfterViewInit {

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorBlue]);

    @ViewChild('summaryTextArea', { static: true })
    protected summaryTextArea: ElementRef;

    @Input()
    public summary: ApiSummaryInterface;

    @Input()
    public bookId: number;

    @Input()
    public bookTitle: string;

    @Input()
    public documentUuid: string;

    @Input()
    public contentHtml: string;

    public downloadAction: ActionButtonInterface;

    private redactor: RedactorAppInstance;

    constructor(
        private activeModal: NgbActiveModal,
        private redactorService: RedactorService,
        private summaryService: SummaryService,
        private toastService: ToastrService,
    ) {
    }

    public ngOnInit(): void {
        if (this.summary) {
            this.downloadAction = {
                id: 'download',
                value: 'Printen',
                target: LinkTargetEnum.Blank,
                href: this.summary.download,
                iconBox: {
                    type: IconBoxTypeEnum.Icon,
                    value: IconEnum.Print,
                },
                classModifiers: [
                    ActionButtonClassEnum.ModalHeader,
                ],
            };
        }
    }

    public ngAfterViewInit(): void {
        this.redactorService.create(this.summaryTextArea.nativeElement)
            .subscribe((redactor: RedactorAppInstance) => {
                this.redactor = redactor;

                if (this.contentHtml) {
                    this.redactor.source.setCode(this.contentHtml);
                }
            });
    }

    public close(): void {
        this.activeModal.dismiss();
    }

    public handleSave(): void {
        if (this.summary) {
            this.saveEdit();
        } else {
            this.saveNew();
        }
    }

    private saveNew(): void {
        this.summaryService.saveNew(this.bookId, this.documentUuid, this.redactor.source.getCode())
            .subscribe((summary: ApiSummaryInterface) => {
                this.toastService.success('Samenvatting is toegevoegd.');

                this.activeModal.close(summary);
            }, () => {
                this.toastService.error('Kon samenvatting niet toevoegen.');
            });
    }

    private saveEdit(): void {
        this.summaryService.edit(this.summary.id, this.bookId, this.documentUuid, this.redactor.source.getCode())
            .subscribe((summary: ApiSummaryInterface) => {
                this.toastService.success('Samenvatting is gewijzigd.');

                this.activeModal.close(summary);
            }, () => {
                this.toastService.error('Kon samenvatting niet wijzigen.');
            });
    }

    protected handleRemove(): void {
        if (!this.summary) {
            return;
        }

        this.summaryService.remove(this.summary.id)
            .subscribe(() => {
                this.toastService.success('Samenvatting is verwijderd.');

                this.activeModal.close();
            }, () => {
                this.toastService.error('Kon samenvatting niet verwijderen.');
            });
    }
}
