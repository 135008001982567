import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {StringService} from 'services/string/string.service';
import {ApiService} from 'core/services/api/api.service';
import {first} from 'rxjs/operators';
import {ExamTypeEnum} from 'enums/exam-type.enum';

export interface ExamMetadataInterface {
    apiExamMetadata?: ApiExamMetadataInterface;
    hasReachedMaxAttempts: boolean;
    inProgress: boolean;
    maxAttempts: number;
    attempts: number;
    results: Array<ApiContentExamResultInterface>;
}

@Injectable()
export class ExamMetadataService {
    private examMetadata: ExamMetadataInterface = {
        hasReachedMaxAttempts: false,
        inProgress: false,
        maxAttempts: 3,
        attempts: 0,
        results: [],
    };

    private examMetadataSubject: BehaviorSubject<ExamMetadataInterface>;

    constructor(private stringService: StringService, private apiService: ApiService) {
        this.examMetadataSubject = new BehaviorSubject(this.examMetadata);
    }

    public subscribe(fn: (examMetadata: ExamMetadataInterface) => void): Subscription {
        return this.examMetadataSubject.subscribe(fn);
    }

    public getAssignmentAt(index: number): ApiExamAssignmentInterface | undefined {
        return this.examMetadata.apiExamMetadata.assignments[index];
    }

    public getResultByAttempt(attempt: number): ApiContentExamResultInterface | undefined {
        return this.examMetadata.results[attempt - 1];
    }

    public getStudentDataForTeacher(): ApiExamUserInterface | undefined {
        const apiMetaData: ApiExamMetadataInterface | undefined = this.examMetadata.apiExamMetadata;
        if (undefined === apiMetaData) {
            return;
        }

        return apiMetaData.user;
    }

    public hasResult(attempt: number): boolean {
        return undefined !== this.examMetadata.results[attempt - 1];
    }

    public isPractiseExam(): boolean {
        return this.examMetadata.apiExamMetadata.type === ExamTypeEnum.PracticeExam;
    }

    public isLastAssignment(assignment: ApiExamAssignmentInterface): boolean {
        const apiExamMetadata: ApiExamMetadataInterface | undefined = this.examMetadata.apiExamMetadata;

        return apiExamMetadata !== undefined && apiExamMetadata.assignments.indexOf(assignment) === apiExamMetadata.assignments.length - 1;
    }

    public addResult(contentExamResult: ApiContentExamResultInterface): void {
        this.examMetadata.results.push(contentExamResult);
        this.examMetadata.inProgress = false;
        this.examMetadata.inProgress = this.examMetadata.apiExamMetadata.inProgress = false;
        this.examMetadata.hasReachedMaxAttempts = this.examMetadata.results.length >= this.examMetadata.maxAttempts;
        this.examMetadata.attempts = this.examMetadata.results.length;

        this.examMetadataSubject.next(this.examMetadata);
    }

    public retrieve(bookId: string, examDpsId: string, planId?: string, userId?: string): Observable<ApiExamMetadataInterface> {
        const routeProperties: Map<string, string> = new Map([['bookId', bookId], ['examDpsId', examDpsId]]);
        const urlSearchParams: URLSearchParams | undefined = new URLSearchParams([['planId', planId], ['userId', userId]]);

        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.ExamContentMetadata, routeProperties);
        const examMetadataObservable = this.apiService.get<ApiExamMetadataInterface>(apiRoute, urlSearchParams);

        examMetadataObservable.pipe(first()).subscribe(apiExamMetadata => this.update(apiExamMetadata));

        return examMetadataObservable;
    }

    private update(apiExamMetadata: ApiExamMetadataInterface): void {
        const results: Array<ApiContentExamResultInterface> = undefined !== apiExamMetadata.results ? apiExamMetadata.results : [];
        const resultCount: number = results.length;
        const maxAttempts: number = undefined === apiExamMetadata.maxAttempts ? this.examMetadata.maxAttempts : apiExamMetadata.maxAttempts;

        this.examMetadata.results = results;
        this.examMetadata.attempts = resultCount;
        this.examMetadata.apiExamMetadata = apiExamMetadata;
        this.examMetadata.inProgress = apiExamMetadata.inProgress;
        this.examMetadata.maxAttempts = maxAttempts;
        this.examMetadata.hasReachedMaxAttempts = maxAttempts === resultCount;

        this.examMetadataSubject.next(this.examMetadata);
    }
}
