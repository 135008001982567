export enum GridTableRowClassEnum {

    Base = 'grid-table__row',

    Clickable = 'grid-table__row--clickable',

    NoMinHeight = 'grid-table__row--no-min-height',

    ProgressResultRow = 'grid-table__row--progress-result-row',

    BackgroundGrey = 'grid-table__col--background-24boost-grey',

    Floatable = 'grid-table__row--floatable',

    Floating = 'grid-table__row--floating',

}
