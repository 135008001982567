import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {ActionButtonClassEnum} from 'shared/enums/action-button/class.enum';
import {DropdownComponent} from 'shared/modules/dropdown/components/dropdown.component';
import {LinkTargetEnum} from 'enums/link-target.enum';

@Component({
    selector: 'app-action-button',
    templateUrl: 'action-button.component.html',
})
export class ActionButtonComponent implements OnInit, OnChanges {

    public readonly actionButtonModifiers = ActionButtonClassEnum;

    public readonly linkTargetEnum = LinkTargetEnum;

    @ViewChild(DropdownComponent, {static: false})
    public dropdownComponent: DropdownComponent;

    @Output()
    public actionEmitter: EventEmitter<ActionButtonInterface> = new EventEmitter();

    @Input()
    public action: ActionButtonInterface;

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public disabled: boolean = false;

    public title: string;

    public classHelper: ClassHelper = new ClassHelper();

    public ngOnInit(): void {
        if (this.action.href && this.action.routerLink) {
            throw new Error('You can only set href or routerLink, not both.');
        }

        this.title = this.action.value;

        this.classHelper.addClasses(this.classModifiers);

        if (this.action.classModifiers) {
            this.classHelper.addClasses(this.action.classModifiers);
        }

        if (!this.action.value && !this.action.toggleBox) {
            this.classHelper.addClass(ActionButtonClassEnum.IconOnly);
            this.classHelper.addClass('icon-box');
        }

        if (this.action.value && this.action.iconBox !== undefined) {
            this.classHelper.addClass(IconBoxClassEnum.Affixed, 'icon-box');
            this.classHelper.addClass(IconBoxClassEnum.BorderNone, 'icon-box');
            this.classHelper.addClass(IconBoxClassEnum.ColorTransparent, 'icon-box');
        }

        if (this.action.disabled) {
            this.disabled = this.action.disabled;
            this.classHelper.addClass(ActionButtonClassEnum.Disabled);
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            this.classHelper.toggleClassByBoolean(ActionButtonClassEnum.Disabled, changes.disabled.currentValue);
        }

        if (changes.action) {
            this.action = this.action;
        }
    }

    public handleToggle(): void {
        this.actionEmitter.emit(this.action);
    }

    public handleClick(action: ActionButtonInterface, event?: MouseEvent): void {
        if (event !== undefined) {
            event.stopPropagation();
        }

        if (this.disabled) {
            return;
        }

        this.actionEmitter.emit(action);
    }

    public toggleDropdown(toggle: boolean): void {
        if (this.disabled || !this.action.children || this.action.children.length < 1) {
            return;
        }

        this.classHelper.toggleClassByBoolean(ActionButtonClassEnum.DropDownVisible, toggle);
        if (this.dropdownComponent instanceof DropdownComponent) {
            this.dropdownComponent.toggle(toggle);
        }
    }
}
