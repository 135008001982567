import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {Observable} from 'rxjs';
import {StringService} from 'services/string/string.service';

@Injectable()
export class TeacherMaterialService {
    constructor(
        private apiService: ApiService,
        private stringService: StringService
    ) {
    }

    public retrieveForSchoolYear(schoolYearId: string, params?: URLSearchParams): Observable<ApiResourcesInterface> {
        return this.apiService.get<ApiResourcesInterface>(ApiEndpointEnum.TeacherSourcesPerSchoolYear.replace(':schoolYearId', schoolYearId), params);
    }

    public retrieveForBook(bookId: number, folderDpsid?: string, params?: URLSearchParams): Observable<ApiTeacherMaterialsInterface> {
        let url = ApiEndpointEnum.TeacherSources;

        if (folderDpsid) {
            url = ApiEndpointEnum.TeacherSourcesChapter;
        }

        return this.apiService.get<ApiTeacherMaterialsInterface>(this.stringService.getMappedString(url, new Map<string, string>([
            ['bookId', String(bookId)],
            ['folderDpsid', folderDpsid],
        ])), params);
    }

    public getElementPath(pathArray?: Array<ApiPathComponentInterface>): string {
        let path = '';

        if (pathArray === null) {
            return path;
        }

        pathArray.forEach(
            pathElem => {
                if (path === '') {
                    path = pathElem.title;
                } else {
                    path = path + ' > ' + pathElem.title;
                }
            }
        );

        return path;
    }
}
