import {IconEnum} from 'enums/icon.enum';
import {AlertMessageInterface} from 'shared/components/alert-message/interfaces/alertMessageInterface';
import {AlertMessageClassEnum} from 'shared/components/alert-message/enums/alert-message-class-enum';

export class TaskDeleteAlertMessage implements AlertMessageInterface {

    public title = 'Let op deze taak is door een andere docent opgegeven!';

    public icon = IconEnum.WarningFull;

    public classModifiers: Array<AlertMessageClassEnum> = [
        AlertMessageClassEnum.TaskDelete,
    ];

    constructor(classModifiers: Array<AlertMessageClassEnum> = []) {
        this.classModifiers = this.classModifiers.concat([
            AlertMessageClassEnum.TaskDelete
        ], classModifiers);
    }

}
