export enum DocumentContentMarkTypeEnum {

    Teacher = 'document-docent',

    Quotation = 'document-quotation',

    Case = 'document-casus',

    Citation = 'document-citation',

    Remark = 'document-remark',

    Tip = 'document-tip',

    Mark = 'document-content-mark',

    Law = 'document-law'

}
