import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconBoxComponent} from 'shared/modules/icon-box/components/icon-box.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        NgbTooltipModule,
    ],
    declarations: [
        IconBoxComponent,
    ],
    exports: [
        IconBoxComponent,
    ],
})
export class IconBoxModule {
}
