import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from 'core/services/modal/modal.service';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {RouteDataOptionsEnum} from 'routing/enums/route-data-options.enum';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {PublisherService} from 'services/publisher/publisher.service';
import {PublisherDataInterface} from 'services/publisher/interfaces/data.interface';

@Component({
    selector: 'app-footer-component',
    templateUrl: 'footer.component.html',
})
export class FooterComponent implements OnInit, OnDestroy {

    public publisher: PublisherDataInterface;

    public readonly iconBoxClassEnum = IconBoxClassEnum;

    public showLegendIcon: boolean = false;

    public legendIconBox: IconBoxInterface = {
        type: IconBoxTypeEnum.Icon,
        value: IconEnum.Legend
    };

    private activatedRouteSubscription: Subscription;

    public constructor(
        private modalService: ModalService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private publisherService: PublisherService
    ) {
    }

    public ngOnInit(): void {
        this.checkShowLegend();
        this.setPublisher();

        this.activatedRouteSubscription = this.router.events
            .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
            .subscribe(() => this.checkShowLegend());
    }

    public ngOnDestroy(): void {
        if (this.activatedRouteSubscription instanceof Subscription) {
            this.activatedRouteSubscription.unsubscribe();
        }
    }

    private setPublisher(): void {
        this.publisher = this.publisherService.getActivePublisher();
    }

    private checkShowLegend(): void {
        this.showLegendIcon = false;

        const activatedChildFirst: ActivatedRoute = this.activatedRoute.firstChild;

        if (!(activatedChildFirst instanceof ActivatedRoute)) {
            return;
        }

        const activatedChildSecond: ActivatedRoute = activatedChildFirst.firstChild;

        if (!(activatedChildSecond instanceof ActivatedRoute)) {
            return;
        }

        const activatedChildThird: ActivatedRoute = activatedChildSecond.firstChild;

        if (!(activatedChildThird instanceof ActivatedRoute)) {
            return;
        }

        const config = activatedChildThird.routeConfig;

        this.showLegendIcon = config.data && config.data[RouteDataOptionsEnum.ShowLegend] === true;
    }

    public handleOpenLegend(): void {
        this.modalService.legend();
    }
}
