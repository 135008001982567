export enum NotificationTypeEnum {

    TypeHomeworkNew = 10,

    TypeHomeworkEdit = 11,

    TypeHomeworkDelete = 12,

    TypeHomeworkHandin = 13,

    TypeHomeworkChecked = 14,

    TypeHomeworkRechecked = 15,

    TypeTaskNew = 20,

    TypeTaskEdit = 21,

    TypeTaskDelete = 22,

    TypeTaskDeadlineApproaching = 23,

    TypeTaskDeadlineExpired = 24,

    TypeTaskResit = 25,

    TypeNoteShared = 30,

    TypeFeedbackNew = 40,

    TypeExamNew = 50,

    TypeExamNeedsReview = 51,

    TypeExamReviewed = 52,

    TypeExamStart = 53,

    TypeExamDelete = 54,

    TypeAdaptiveAssessmentCompleted = 60,

    TypeAdaptivePlanReady = 61,

    TypeSourceUploaded = 70,

    TypeInternshipReview = 80,

    TypeMessage = 90,

    TypeGroupJoined = 100,

    TypeGroupLeft = 101,

    TypeLicenceExtend = 110,

}
