import {RoleEnum} from 'enums/role.enum';
import {RolesLogicInterface} from 'security/interfaces/roles-logic.interface';
import {Injectable} from '@angular/core';
import {UserService} from 'security/services/user/user.service';
import {GroupsService} from 'services/groups/groups.service';
import {ApiUserDataInterface} from 'interfaces/api/user-data.interface';

@Injectable()
export class DocumentRolesLogic implements RolesLogicInterface {

    protected userData: ApiUserDataInterface;

    constructor(
        private userService: UserService,
        private groupService: GroupsService,
    ) {
    }

    public getRoles(): Array<RoleEnum> {
        const roles: Array<RoleEnum> = [];
        const hasGroups: boolean = this.groupService.getGroupCount() > 0;

        this.userData = this.userService.getUserData();

        if (this.userData.role === RoleEnum.RoleTeacher && hasGroups) {
            roles.push(RoleEnum.RoleDocumentAssignTask);
        }

        return roles;
    }

    public getName(): string {
        return 'DocumentRolesLogic';
    }
}
