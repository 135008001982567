import {ProgressGroupInterface} from 'pages/modules/progress/interfaces/group.interface';
import {ProgressStudentInterface} from 'pages/modules/progress/interfaces/student.interface';
import {ProgressAverageInterface} from 'pages/modules/progress/interfaces/average.interface';

export class ProgressGroupModel implements ProgressGroupInterface {

    constructor(
        public id: number,
        public name: string,
        public students: Array<ProgressStudentInterface>,
        public average?: ProgressAverageInterface,
    ) {
    }

}
