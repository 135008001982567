import {Directive, HostBinding, OnInit} from '@angular/core';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';

@Directive({
    selector: 'section',
})

export class DocumentSectionDirective implements OnInit {

    public static DEPTH = 0;

    @HostBinding('attr.data-level')
    public currentLevel: number;

    @HostBinding('class')
    public elementClass: string = DocumentHostClassesEnum.Section;

    public ngOnInit(): void {
        ++DocumentSectionDirective.DEPTH;
        this.currentLevel = DocumentSectionDirective.DEPTH;
    }
}
