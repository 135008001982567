import {Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';

@Component({
    selector: 'app-preloader-component',
    templateUrl: 'preloader.component.html',
})
export class PreloaderComponent implements OnInit, OnChanges {

    public static readonly CLASS = 'preloader';

    @HostBinding('class')
    protected classes: string;

    @Input()
    public show: boolean = true;

    @Input()
    public background: boolean = false;

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public inline: boolean = false;

    @Input()
    public small: boolean = false;

    private classHelper: ClassHelper = new ClassHelper(PreloaderComponent.CLASS);

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classModifiers);

        if (this.show) {
            this.classHelper.addClass(PreloaderClassModifiersEnum.Visible);
        }

        if (this.background) {
            this.classHelper.addClass(PreloaderClassModifiersEnum.Background);
        }

        if (this.inline) {
            this.classHelper.addClass(PreloaderClassModifiersEnum.Inline);
        }

        if (this.small) {
            this.classHelper.addClass(PreloaderClassModifiersEnum.Small);
        }

        this.classes = this.classHelper.toString();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.show && !changes.show.firstChange && changes.show.currentValue !== changes.show.previousValue) {
            this.show = changes.show.currentValue;
            this.classHelper.toggleClass(PreloaderClassModifiersEnum.Visible);
            this.classes = this.classHelper.toString();
        }
    }
}
