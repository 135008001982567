import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {Observable} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {TaskStatusFilterEnum} from 'shared/components/status-filter/enum/task-status-filter.enum';
import {AddTaskFormInterface} from 'pages/modules/tasks/components/modals/add-modal/interfaces/add-task-form.interface';
import {StringService} from 'services/string/string.service';
import {NavigationExtras, Router} from '@angular/router';
import {ResultStateEnum} from 'shared/components/result-cell/enums/result-state.enum';
import {HomeworkStatusEnum} from 'enums/homework-status.enum';
import {ProgressStatusEnum} from 'enums/progress-status.enum';
import * as moment from 'moment';
import {ProgressAssignmentStatusInterface} from 'pages/modules/progress/interfaces/assignment-status.interface';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {RouteService} from 'routing/services/route/route.service';
import {UserService} from 'security/services/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class TaskService {

    private future: boolean = true;

    private selectedWeek: string;

    private isOriginalTask: boolean = true;

    constructor(
        private apiRequestService: ApiService,
        private routeService: RouteService,
        private stringService: StringService,
        private userService: UserService,
        private router: Router,
    ) {
    }

    public retrieveWeeks(
        future: boolean,
        offset: number | null,
        limit: string | null,
        search: string = null,
        status: string | TaskStatusFilterEnum = null,
        mine: boolean = null,
        groupId: string = null,
        userId: string = null,
        bookId: number = null,
        chapterId: number = null
    ): Observable<ApiTaskWeeksInterface> {
        const params = new URLSearchParams([
            ['offset', offset.toString()],
            ['limit', limit],
            ['future', future ? '1' : '0'],
        ]);

        if (Number(offset) === 0) {
            params.append('include_tasks', '1');
        }

        if (search !== null && search.length > 0) {
            params.append('search', search);
        }

        if (status !== null) {
            params.append('status', status);
        }

        if (mine !== null) {
            params.append('mine', mine ? '1' : '0');
        }

        if (groupId !== null) {
            params.append('group', groupId);

            if (userId !== null) {
                params.append('user', userId);
            }
        }

        if (bookId !== null) {
            params.append('book', String(bookId));

            if (chapterId !== null) {
                params.append('chapter', String(chapterId));
            }
        }

        return this.apiRequestService.get<ApiTaskWeeksInterface>(ApiEndpointEnum.TaskWeeks, params);
    }

    public retrieveTasks(
        weekId: string,
        search: string = null,
        status: string | TaskStatusFilterEnum = null,
        mine: boolean = null,
        groupId: string = null,
        userId: string = null,
        bookId: number = null,
        chapterId: number = null
    ): Observable<ApiTasksInterface> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.Tasks, new Map([['weekId', weekId]]));
        const params = new URLSearchParams([
            ['offset', '0'],
            ['limit', '1000'],
        ]);

        if (search !== null && search.length > 0) {
            params.append('search', search);
        }

        if (status !== null) {
            params.append('status', status);
        }

        if (mine !== null) {
            params.append('mine', mine ? '1' : '0');
        }

        if (groupId !== null) {
            params.append('group', groupId);

            if (userId !== null) {
                params.append('user', userId);
            }
        }

        if (bookId !== null) {
            params.append('book', String(bookId));

            if (chapterId !== null) {
                params.append('chapter', String(chapterId));
            }
        }

        return this.apiRequestService.get<ApiTasksInterface>(apiRoute, params);
    }

    public setSelectedFuture(future: boolean): void {
        this.future = future;
    }

    public getSelectedFuture(): boolean {
        return this.future;
    }

    public retrieveTasksForProgress(
        bookId: number,
        chapterId: number = null,
        groupId: string = null,
        userId: string = null,
        search: string = null,
    ): Observable<Array<ApiTaskDetailsInterface>> {
        const params = new URLSearchParams();

        params.append('book', String(bookId));

        if (chapterId !== null) {
            params.append('chapter', String(chapterId));
        }

        if (groupId !== null) {
            params.append('group', groupId);

            if (userId !== null) {
                params.append('user', userId);
            }
        }

        if (search !== null && search.length > 0) {
            params.append('search', search);
        }

        return this.apiRequestService.get<Array<ApiTaskDetailsInterface>>(ApiEndpointEnum.TasksProgress, params);
    }

    public retrieveTaskById(taskId: number, studentId?: number): Observable<ApiTaskDetailsInterface> {
        const params = new URLSearchParams();

        if (studentId !== undefined && studentId !== null) {
            params.append('student', String(studentId));
        }

        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.Task, new Map([
            ['taskId', taskId.toString()],
        ]));

        return this.apiRequestService.get<ApiTaskDetailsInterface>(apiRoute, params);
    }

    public saveNewTask(form: AddTaskFormInterface, isFinished?: boolean): Observable<ApiTaskAddInterface> {
        const obs = this.apiRequestService.post<ApiTaskAddInterface>(ApiEndpointEnum.TaskAdd, form);

        if (isFinished) {
            obs.subscribe(() => {
                this.userService.reload();
            });
        }

        return obs;
    }

    public resitTask(taskId: number, form: AddTaskFormInterface, isFinished?: boolean): Observable<ApiTaskAddInterface> {
        const obs = this.apiRequestService.post<ApiTaskAddInterface>(
            this.stringService.getMappedString(ApiEndpointEnum.TaskResit, new Map([['taskId', String(taskId)]])),
            form
        );

        if (isFinished) {
            obs.subscribe(() => {
                this.userService.reload();
            });
        }

        return obs;
    }

    public updateTask(taskId: number, form: AddTaskFormInterface): Observable<ApiTaskDetailsInterface> {
        return this.apiRequestService.post<ApiTaskDetailsInterface>(
            this.stringService.getMappedString(ApiEndpointEnum.TaskUpdate, new Map([['taskId', String(taskId)]])),
            form
        );
    }

    public navigateToDetails(taskId: number, studentId: number = null, taskgroup: boolean = false): void {
        const queryParams: NavigationExtras = {
            queryParams: {}
        };

        if (studentId !== undefined && studentId !== null) {
            queryParams.queryParams.student = studentId;
        }

        if (taskgroup) {
            queryParams.queryParams.taskgroup = 1;
        }

        const route = this.routeService.getRouteWithPublisher(RoutesEnum.TaskDetails, new Map([
            ['taskId', taskId.toString()],
        ]));

        this.router.navigate([route], queryParams);
    }

    public deleteTask(taskId: number): Observable<null> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.TaskDelete, new Map([
            ['taskId', taskId.toString()],
        ]));

        const obs = this.apiRequestService.post<null>(apiRoute);

        obs.subscribe(() => {
            this.userService.reload();
        });

        return obs;
    }

    public getTaskStates(task: ApiTaskDetailsInterface): Array<ResultStateEnum> {
        const statuses: Array<ResultStateEnum> = [];

        const handedIn = task.homework && task.homework[0];

        if (!task.homework || !task.homework[0]) {
            statuses.push(ResultStateEnum.Created);
        } else if (task.homework[0].examination_result) {
            statuses.push(ResultStateEnum.Graded);
        } else if (task.handedin || task.homework[0].status === HomeworkStatusEnum.StatusWaiting) {
            statuses.push(ResultStateEnum.HandedIn);
        } else if (task.closed && task.resit === true) {
            statuses.push(ResultStateEnum.Resit);
        } else {
            statuses.push(ResultStateEnum.None);
        }

        if (
            (!handedIn && moment.unix(task.duedate).isBefore(moment())) ||
            (handedIn && moment.unix(task.duedate).isBefore(moment.unix(task.handedin)))
        ) {
            statuses.push(ResultStateEnum.TooLate);
        }

        return statuses;
    }

    public getHomeworkStates(homework: ApiTaskDetailsHomeworkInterface = null): Array<ResultStateEnum> {
        if (homework.examination_result && homework.examination_result > 0) {
            return [ResultStateEnum.Graded];
        }

        if (homework.status === HomeworkStatusEnum.StatusWaiting) {
            return [ResultStateEnum.HandedIn];
        }

        return [ResultStateEnum.Created];
    }

    public getAssignmentStatusStates(assignmentStatus: ProgressAssignmentStatusInterface = null): Array<ResultStateEnum> {
        let handedIn = false;
        const statuses: Array<ResultStateEnum> = [];

        switch (assignmentStatus.progressStatus) {
            case ProgressStatusEnum.ProgressStatusTaskAssigned:
                statuses.push(ResultStateEnum.Resit);
                break;

            case ProgressStatusEnum.ProgressStatusInProgress:
                statuses.push(ResultStateEnum.Doing);
                break;

            case ProgressStatusEnum.ProgressStatusHandedIn:
                statuses.push(ResultStateEnum.HandedIn);
                handedIn = true;
                break;

            case ProgressStatusEnum.ProgressStatusChecked:
            case ProgressStatusEnum.ProgressStatusPartiallyFinished:
            case ProgressStatusEnum.ProgressStatusFullyFinished:
                statuses.push(ResultStateEnum.Graded);
                handedIn = true;
                break;

            default:
                statuses.push(ResultStateEnum.None);
                break;
        }

        if ((!handedIn && moment(assignmentStatus.dueDate).isBefore(moment()))
            || (handedIn && moment(assignmentStatus.dueDate).isBefore(moment(assignmentStatus.handedin)))
        ) {
            statuses.push(ResultStateEnum.TooLate);
        }

        return statuses;
    }

    public setSelectedWeek(week: string): void {
        this.selectedWeek = week;
    }

    public retrieveSelectedWeek(): string {
        return this.selectedWeek;
    }

    public clearSelectedWeek(): void {
        this.selectedWeek = '';
    }

    public setIsOriginalTask(isOriginal: boolean): void {
        this.isOriginalTask = isOriginal;
    }

    public getIsOriginalTask(): boolean {
        return this.isOriginalTask;
    }
}
