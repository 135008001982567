import {Component, Input} from '@angular/core';
import {CollapsibleClassEnum} from 'shared/components/collapsible/enums/class.enum';
import {RouteService} from 'routing/services/route/route.service';
import {Router} from '@angular/router';
import {RoutesEnum} from 'routing/enums/routes.enum';
import naturalCompare from 'string-natural-compare';

@Component({
    selector: 'app-exams-exam-plans-table',
    templateUrl: 'exam-plans-table.component.html',
})
export class ExamsExamPlansTableComponent {

    public readonly collapsibleClasses = CollapsibleClassEnum;

    public readonly messages = {
        emptyMessage: 'Geen examens gevonden',
        totalMessage: 'totaal',
        selectedMessage: 'geselecteerd'
    };

    @Input()
    public examPlans: ApiExamPlansInterface;

    constructor(
        private routeService: RouteService,
        private router: Router,
    ) {}

    public onDataTableSelect(selection: NgxDataTableSelected<ApiTaskDetailsInterface>): void {
        this.navigateToDetails(selection.selected[0].id);
    }

    public navigateToDetails(examPlanId: number): void {
        this.router.navigateByUrl(this.routeService.getRouteWithPublisher(RoutesEnum.ExamDetails, new Map([
            ['planId', examPlanId.toString()]
        ])));
    }

    public getAssignLabel(plan: ApiExamPlanInterface): string {
        const userNames = [];

        for (const user of plan.users) {
            userNames.push(user.user.name);
        }

        return userNames.join(', ');
    }

    public getNumberDone(plan: ApiExamPlanInterface): number {
        let count = 0;

        for (const user of plan.users) {
            if (user.result) {
                count++;
            }
        }

        return count;
    }

    public naturalSort(titleA: string, titleB: string): number {
        return naturalCompare(titleA, titleB);
    }
}
