import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IconBoxClose} from 'models/iconbox/close.model';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AddUrlPreferencesEnum} from 'shared/modals/add-url/enum/add-url-preferences.enum';

@Component({
    selector: 'app-add-url',
    templateUrl: 'add-url.component.html',
})
export class AddUrlModalComponent implements OnInit {
    @Output()
    public preferencesEvent: EventEmitter<string> = new EventEmitter<string>();

    public readonly modalTitle: string = 'URL toevoegen';

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorBlue]);

    public addUrlOpenPreference: AddUrlPreferencesEnum;

    constructor(private activeModal: NgbActiveModal) {}

    public ngOnInit(): void {
        this.addUrlOpenPreference = AddUrlPreferencesEnum.OwnInput;
    }

    public close(): void {
        this.activeModal.dismiss();
    }

    public handlePreferences(): void {
        this.preferencesEvent.emit(this.addUrlOpenPreference);
    }
}
