export enum StudyMaterialTypeEnum {

    ModuleHtml5 = 10,

    ModuleOther = 11,

    ModuleOtherAdaptive = 13,

    ModuleHtml5Bpv = 14,

    ModuleExternal = 15,

    ModuleBook = 17,

    Exam = 20,

    PracticeExam = 21,

    ExamAssessment = 22,

}
