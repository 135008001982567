import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from 'security/services/user/user.service';
import {UserIdentityModelFactory} from 'core/factories/user-identity-model.factory';
import {UserIdentityModel} from 'core/models/user-identity.model';
import {Subscription} from 'rxjs';
import {ApiUserDataInterface} from 'interfaces/api/user-data.interface';

@Component({
    selector: 'app-user-profile-page',
    templateUrl: 'profile-page.component.html',
})
export class UserProfilePageComponent implements OnInit, OnDestroy {

    public userIdentity: UserIdentityModel;

    private userData: ApiUserDataInterface;

    private userChangedSubscription: Subscription;

    constructor(
        private userService: UserService,
        private userIdentityModelFactory: UserIdentityModelFactory,
    ) {
    }

    public ngOnInit(): void {
        this.setUser();

        this.userChangedSubscription = this.userService.onUserDataChange.subscribe(() => {
            this.setUser();
        });
    }

    public ngOnDestroy(): void {
        if (this.userChangedSubscription instanceof Subscription) {
            this.userChangedSubscription.unsubscribe();
        }
    }

    private setUser(): void {
        this.userData = this.userService.getUserData();

        this.userIdentity = this.userIdentityModelFactory.fromUserData(this.userData);
        if (!this.userIdentity.organizationName) {
            this.setLastKnownOrganisationName();
        }
    }

    private setLastKnownOrganisationName(): void {
        for (const schoolYear of this.userData.school_years.reverse()) {
            if (schoolYear.organization && schoolYear.organization.name) {
                this.userIdentity.organizationName = schoolYear.organization.name;

                return;
            }
        }
    }
}
