import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HelpPageModalComponent} from 'shared/modals/help-page/help-page-modal.component';
import {WelcomeHelpPageModalComponent} from 'shared/modals/help-page/welcome/welcome.component';
import {QuestionHelpPageModalComponent} from 'shared/modals/help-page/question/question.component';
import {IconBoxModule} from 'shared/modules/icon-box/icon-box.module';
import {PipesModule} from 'shared/modules/pipes/pipes.module';
import {NgbCarouselConfig, NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import {PreloaderComponent} from 'shared/components/preloader/preloader.component';
import {BooksHelpPageModalComponent} from 'shared/modals/help-page/books/books.component';
import {TasksHelpPageModalComponent} from 'shared/modals/help-page/tasks/tasks.component';
import {ExamsHelpPageModalComponent} from 'shared/modals/help-page/exams/exams.component';
import {ProgressHelpPageModalComponent} from 'shared/modals/help-page/progress/progress.component';
import {GroupsHelpPageModalComponent} from 'shared/modals/help-page/groups/groups.component';
import {InfoHelpPageModalComponent} from 'shared/modals/help-page/info/info.component';


@NgModule({
    imports: [
        CommonModule,
        IconBoxModule,
        PipesModule,
        NgbCarouselModule,
    ],
    declarations: [
        PreloaderComponent,
        HelpPageModalComponent,
        WelcomeHelpPageModalComponent,
        QuestionHelpPageModalComponent,
        InfoHelpPageModalComponent,
        BooksHelpPageModalComponent,
        TasksHelpPageModalComponent,
        ExamsHelpPageModalComponent,
        ProgressHelpPageModalComponent,
        GroupsHelpPageModalComponent,
    ],
    exports: [
        PreloaderComponent,
        HelpPageModalComponent,
        WelcomeHelpPageModalComponent,
        QuestionHelpPageModalComponent,
        InfoHelpPageModalComponent,
        BooksHelpPageModalComponent,
        TasksHelpPageModalComponent,
        ExamsHelpPageModalComponent,
        ProgressHelpPageModalComponent,
        GroupsHelpPageModalComponent,
    ],
    providers: [
        NgbCarouselConfig,
    ],
    entryComponents: [
        HelpPageModalComponent,
        WelcomeHelpPageModalComponent,
        QuestionHelpPageModalComponent,
        BooksHelpPageModalComponent,
        TasksHelpPageModalComponent,
        ExamsHelpPageModalComponent,
        ProgressHelpPageModalComponent,
        GroupsHelpPageModalComponent,
    ],
})
export class HelpPageModule {
}
