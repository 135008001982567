import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {ClassHelper} from 'helpers/dom/class.helper';
import {GroupsModuleGroupHeaderClassesEnum} from 'pages/modules/groups/enums/group-header-classes.enum';
import {GroupsModuleActionsEnum} from 'pages/modules/groups/enums/group-actions.enum';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {GroupsModuleGroupInterface} from 'pages/modules/groups/interfaces/group.interface';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-groups-group-header-component',
    templateUrl: 'group-header.component.html',
})
export class GroupsGroupHeaderComponent implements OnChanges {

    @Output()
    public groupActionEvent: EventEmitter<string> = new EventEmitter();

    @Input()
    public group: GroupsModuleGroupInterface;

    @Input()
    public year: string = '';

    @Input()
    public expanded: boolean;

    @Input()
    public active: boolean;

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public checked: boolean = false;

    @Input()
    public displayCheckbox: boolean = true;

    @Input()
    public displayExpand: boolean = true;

    @Input()
    public actions: Array<ActionButtonInterface> = [];

    public archiveModeActive = environment.archiveModeActive;

    public iconBox: IconBoxInterface = {
        type: IconBoxTypeEnum.Icon,
        value: IconEnum.Users,
        classModifiers: [
            IconBoxClassEnum.Groups,
        ],
    };

    public classHelper: ClassHelper = new ClassHelper();

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.active) {
            this.classHelper.toggleClassByBoolean(
                GroupsModuleGroupHeaderClassesEnum.Inactive,
                !changes.active.currentValue,
            );

            this.active ? this.iconBox.value = IconEnum.Users : this.iconBox.value = IconEnum.UsersInactive;
        }
    }

    public handleExpand(event: Event): void {
        event.stopPropagation();

        this.groupActionEvent.emit(GroupsModuleActionsEnum.Expand);
    }

    public handleAction(action: ActionButtonInterface): void {
        this.groupActionEvent.emit(action.id);
    }

    public handleCheckedToggle(event: Event): void {
        if (!this.displayCheckbox) {
            return;
        }

        event.stopPropagation();
        event.preventDefault();

        this.groupActionEvent.emit(GroupsModuleActionsEnum.Checked);
    }
}
