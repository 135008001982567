import {UserIdentityModel} from 'core/models/user-identity.model';
import {Injectable} from '@angular/core';
import {ApiUserDataInterface} from 'interfaces/api/user-data.interface';

@Injectable()
export class UserIdentityModelFactory {

    public fromUserData(userData: ApiUserDataInterface): UserIdentityModel {
        let organization: ApiOrganisationInterface = null;

        if (userData.organization) {
            organization = userData.organization;
        } else if (userData.school_years && userData.school_years.length > 0) {
            organization = userData.school_years[userData.school_years.length - 1].organization;
        }

        return new UserIdentityModel(
            userData.firstname,
            userData.middlename,
            userData.lastname,
            userData.email,
            userData.avatar,
            organization ? organization.name : null,
            userData.use_one_drive,
            userData.one_drive_open_preference,
            userData.one_drive_root,
            userData.one_drive_username
        );
    }

}
