import {Component, HostBinding, Input} from '@angular/core';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import {IconEnum} from 'enums/icon.enum';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {CollapsibleClassEnum} from 'shared/components/collapsible/enums/class.enum';
import {ProgressGroupInterface} from 'pages/modules/progress/interfaces/group.interface';
import {ProgressStudentInterface} from 'pages/modules/progress/interfaces/student.interface';
import {ProgressStudyMaterialInterface} from 'pages/modules/progress/interfaces/study-material.interface';
import {RoleEnum} from 'enums/role.enum';
import {ProgressStatusFilterEnum} from 'shared/components/status-filter/enum/progress-status-filter.enum';
import {ExamTypeEnum} from 'enums/exam-type.enum';

@Component({
    selector: 'app-progress-group-student-details',
    templateUrl: 'group-student-details.component.html',
})
export class ProgressGroupStudentDetailsComponent {

    public static readonly CLASS = 'progress-student-details';

    public readonly columnClasses = GridTableColClassEnum;

    public readonly rowClasses = GridTableRowClassEnum;

    public readonly collapsibleClasses = CollapsibleClassEnum;

    public readonly iconEnum = IconEnum;

    public readonly roleEnum = RoleEnum;

    @HostBinding('class')
    protected classes: string = ProgressGroupStudentDetailsComponent.CLASS;

    @Input()
    public classHelper: ClassHelper;

    @Input()
    public group: ProgressGroupInterface;

    @Input()
    public assignmentsStatus: Array<ApiProgressAverageAssignmentStatusInterface>;

    @Input()
    public user: ProgressStudentInterface | null = null;

    @Input()
    public studyMaterial: ProgressStudyMaterialInterface;

    @Input()
    public bookId: number;

    @Input()
    public isBpv: boolean = false;

    @Input()
    public isNumeric: boolean = false;

    @Input()
    public filter: ProgressStatusFilterEnum;

    public typePracticeExam: number = ExamTypeEnum.PracticeExam;

    public typeExam: number = ExamTypeEnum.Exam;

}
