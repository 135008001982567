export enum DocumentPageActionsButtonsEnum {

    TableOfContents = 1,

    ShowAnswer = 2,

    SubmitAssignment = 3,

    TeacherMaterial = 4,

    EBook = 5,

}
