export enum ButtonClassEnum {

    Navbar = 'button-container--navbar',

    FlexSm = 'btn--flex-sm',

    Primary = 'btn--primary',

    PrimaryInversed = 'btn--primary-inversed',

    ColorBlue = 'btn--blue',

    ColorBlueDark = 'btn--blue-dark',

    ColorBlueDarkInversed = 'btn--blue-dark-inversed',

}
