import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ContentSwitchGroupModel} from 'pages/modules/tasks/components/modals/add-modal/models/group-model';
import {finalize} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {GroupsService} from 'services/groups/groups.service';
import {ToastrService} from 'ngx-toastr';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {ExamService} from 'services/exam/exam.service';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxClose} from 'models/iconbox/close.model';

@Component({
    selector: 'app-add-exam-modal',
    templateUrl: 'add-modal.component.html'
})
export class ExamsAddModalComponent implements OnInit {

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorExam]);

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    @Input()
    public bookId: number;

    @Input()
    public dpsId: string;

    @Input()
    public examId: number;

    @Input()
    public examType: number;

    public isLoading: boolean = true;

    public startDateTime: number;

    public dueDateTime: number;

    public groups: Array<ContentSwitchGroupModel> = [];

    constructor(
        private activeModal: NgbActiveModal,
        private groupService: GroupsService,
        private toastService: ToastrService,
        private examService: ExamService,
    ) {}

    public ngOnInit(): void {
        this.loadGroupsAndStudents();
    }

    public close(): void {
        this.activeModal.close();
    }

    public handleSave(): void {
        if (!this.startDateTime) {
            this.toastService.warning('Toets kon niet worden opgegeven. Kies een start datum/tijd.');

            return;
        }

        if (this.startDateTime <= (Date.now() / 1000)) {
            this.toastService.warning('Toets kon niet worden opgegeven. De start datum/tijd mag niet in het verleden liggen.');

            return;
        }

        if (!this.dueDateTime) {
            this.toastService.warning('Toets kon niet worden opgegeven. Kies een eind datum/tijd.');

            return;
        }

        if (this.dueDateTime <= this.startDateTime) {
            this.toastService.warning('Toets kon niet worden opgegeven. De start datum/tijd mag niet voor de eind datum/tijd liggen.');

            return;
        }

        const params = {
            exam: this.examId,
            type: this.examType, // @TODO Can't the backend decide?
            max_attempts: 1, // @TODO Default to backend
            start: this.startDateTime,
            end: this.dueDateTime,
            book: this.bookId,
            groups: [],
            user_groups: [],
            dpsId: this.dpsId
        };

        this.addGroups(this.groups, params);

        if (params.groups.length === 0 && params.user_groups.length === 0) {
            this.toastService.warning('Toets kon niet worden opgegeven. Selecteer studenten.');

            return;
        }

        this.examService.saveExam(params)
        .subscribe((exams) => {
            this.toastService.success(exams.length + ' toets(en) opgegeven.');
            this.close();
        }, () => {
            this.toastService.error('Kon toets niet opgeven.');
        });
    }

    private loadGroupsAndStudents(): void {
        this.groupService.retrieveAllGroups()
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((apiGroups: ApiGroupsInterface) => {
                const groups = [];

                for (const group of apiGroups.data) {
                    if (group.students.length > 0) {
                        groups.push(ContentSwitchGroupModel.fromApiGroup(group));
                    }
                }

                this.groups = groups;
            }, (errorResponse: HttpErrorResponse) => {
                this.toastService.error('Kon groepen en studenten niet ophalen.');
            });
    }

    private addGroups(groups: Array<ContentSwitchGroupModel>, params: any): boolean {
        for (const group of groups) {
            if (group.active === true) {
                params.groups.push(Number(group.id));
                continue;
            }

            for (const student of group.children) {
                if (student.active) {
                    params.user_groups.push({
                        group_id: Number(group.id),
                        user_id: Number(student.id)
                    });
                }
            }
        }

        return true;
    }
}
