import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {ActionButtonFactory} from 'shared/factories/action-button.factory';
import {IconEnum} from 'enums/icon.enum';
import {ProgressService} from 'services/progress/progress.service';
import {RoleEnum} from 'enums/role.enum';
import {BookService} from 'services/book/book.service';
import {CollapsibleClassEnum} from 'shared/components/collapsible/enums/class.enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import {ActionButtonClassEnum} from 'shared/enums/action-button/class.enum';
import {ContentSelectItemInterface} from 'shared/modules/content-select/interfaces/select-item.interface';
import {ProgressConstantsEnum} from 'pages/modules/progress/enums/constants.enum';
import {ProgressStatusFilterEnum} from 'shared/components/status-filter/enum/progress-status-filter.enum';
import {PageWrapperClassEnum} from 'shared/modules/page/enums/page-wrapper/class.enum';
import {DocumentTypeEnum} from 'enums/document-type.enum';
import {UserService} from 'security/services/user/user.service';
import {ProgressActionButtonPdfModel} from 'pages/modules/progress/models/action-button-pdf.model';
import {ProgressActionButtonExcelModel} from 'pages/modules/progress/models/action-button-excel.model';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {ProgressChapterFactory} from 'pages/modules/progress/factories/chapter.factory';
import {ProgressChapterInterface} from 'pages/modules/progress/interfaces/chapter.interface';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {AlertMessageInterface} from 'shared/components/alert-message/interfaces/alertMessageInterface';
import {ProgressAlertMessage} from 'shared/components/alert-message/models/progress.model';
import { AutoCheckService } from 'document/services/autocheck/auto-check.service';

@Component({
    selector: 'app-progress',
    templateUrl: 'progress.component.html',
})
export class ProgressComponent implements OnInit, OnDestroy {

    public readonly constantsEnum = ProgressConstantsEnum;

    public readonly iconEnum = IconEnum;

    public readonly pageWrapperClassEnum = PageWrapperClassEnum;

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly progressClassModifiers: Array<ContentSelectClassModifiersEnum> = [
        ContentSelectClassModifiersEnum.Progress,
        ContentSelectClassModifiersEnum.Grey,
    ];

    public readonly statusFilterOptions = new Map([
        [ProgressStatusFilterEnum.All, 'Alle'],
        [ProgressStatusFilterEnum.HandedIn, 'Opgegeven'],
    ]);

    public readonly statusFilterInitial = ProgressStatusFilterEnum.All;

    @HostBinding('class')
    protected classes = 'container-fluid container-fluid--width';

    public isLoading: boolean = false;

    public alertMessage: AlertMessageInterface = new ProgressAlertMessage();

    public action: ActionButtonInterface;

    public classHelper: ClassHelper = new ClassHelper();

    public canDownloadDocument: boolean = false;

    public book: ApiBookInterface = null;

    public progressOverview: ApiProgressOverviewInterface;

    public chapters: Array<ProgressChapterInterface> = [];

    public search: string = '';

    public selectedGroup: ApiGroupInterface;

    public selectedStudent: ApiUserInterface;

    public hasRequiredDataset: boolean = false;

    public showGroupsFilter: boolean = true;

    public licenseRequested: boolean = false;

    public demo: boolean = false;

    public bookId: number;

    private lastSearchParams: string;

    private contentSwitchData: Array<ContentSelectItemInterface> = [];

    private userChangedSubscription: Subscription;

    private selectedStatus: string | ProgressStatusFilterEnum;

    constructor(
        private toastService: ToastrService,
        private actionButtonFactory: ActionButtonFactory,
        private chapterFactory: ProgressChapterFactory,
        private progressService: ProgressService,
        private authorizationService: AuthorizationService,
        private bookService: BookService,
        private userService: UserService,
        private autocheckService: AutoCheckService,
    ) {
        this.action = this.actionButtonFactory.createPurpleButton('heading-action-root', 'Downloaden als', IconEnum.Download);

        this.classHelper.addClasses(
            [
                CollapsibleClassEnum.Bordered,
                CollapsibleClassEnum.MarginBottom
            ],
            ProgressConstantsEnum.ClassCollectionCollapsible
        );

        this.classHelper.addClasses(
            [
                ActionButtonClassEnum.Heading,
                ActionButtonClassEnum.MediaLargeOnly,
            ],
            this.constantsEnum.ClassCollectionHeaderButton
        );
    }

    public ngOnInit(): void {
        this.setStudentFilters();
        this.loadProgressOverview();

        this.userChangedSubscription = this.userService.onUserDataChange.subscribe(() => this.setStudentFilters());
    }

    public ngOnDestroy(): void {
        if (this.userChangedSubscription) {
            this.userChangedSubscription.unsubscribe();
        }
    }

    public onContentItemClick(event: Array<ContentSelectItemInterface>): void {
        if (event.length === 0) {
            this.contentSwitchData = [];
            this.book = null;

            return;
        }

        this.contentSwitchData = event;

        const selectedBookChapter = this.getActiveBookOrChapter();

        if (!selectedBookChapter.bookId) {
            this.contentSwitchData = [];
            this.book = null;

            return;
        }

        let selectedBook = null;

        for (const book of this.bookService.getBooks()) {
            if (book.id === selectedBookChapter.bookId) {
                selectedBook = book;
            }
        }

        this.book = selectedBook;

        if (this.book === null) {
            this.contentSwitchData = [];

            return;
        } else {
            this.bookId = this.book.id;
            this.demo = this.book.demo ? this.book.demo : false;
            this.licenseRequested = this.book.licenseRequested ? this.book.licenseRequested : false;
        }

        this.loadProgressOverview();
    }

    public onGroupsFilterChange(group: ApiGroupInterface | null): void {
        this.selectedGroup = group;

        this.loadProgressOverview();
    }

    public onStudentsFilterChange(student: ApiUserInterface | null): void {
        this.selectedStudent = student;

        this.loadProgressOverview();
    }

    public onFilterButtonChange(event: ProgressStatusFilterEnum | string): void {
        this.selectedStatus = event;

        this.loadProgressOverview();
    }

    public setHasRequiredDataSet(): void {
        const selectedBookChapter = this.getActiveBookOrChapter();

        // Skip one change detection cycle to prevent ExpressionHasBeenChanged error (BS-3120).
        setTimeout(() => {
            this.hasRequiredDataset = !!selectedBookChapter.bookId && !!this.selectedStatus
                && (
                    (
                        this.authorizationService.isGranted(RoleEnum.RoleStudent)
                        && (this.userService.getUserData().group_count <= 1 || !!this.selectedGroup)
                    ) || (
                        !!this.selectedGroup && this.selectedStudent !== undefined
                    )
                );
        }, 0);

    }

    private setStudentFilters(): void {
        const user = this.userService.getUserData();

        if (user.role !== RoleEnum.RoleStudent) {
            return;
        }

        this.showGroupsFilter = user.group_count >= 1;
    }

    private loadProgressOverview(): Promise<void> {
        this.loadDocumentLinks();
        this.setHasRequiredDataSet();

        return new Promise<void>((resolve, reject) => {


            /*
            /////// Below test is probably the reason for BO-2165 and seems to be a recipe for a race-condition.
            /////// Just in case this logic is actually needed (and not just an optimization - which is what it looks like)
            /////// I've kept this commented out for now, instead of removing it.
            */

            // if (!this.hasRequiredDataset && this.userService.isTabAlreadyVisited('progress')) {
            //     resolve();
            //     return;
            // }

            let groupId = null;
            let studentId = null;

            if (this.selectedGroup) {
                groupId = this.selectedGroup.id;

                if (this.authorizationService.isGranted(RoleEnum.RoleTeacher) && this.selectedStudent) {
                    studentId = this.selectedStudent.id;
                }
            }

            const selectedBookChapter = this.getActiveBookOrChapter();

            const searchParams = JSON.stringify([selectedBookChapter.bookId, selectedBookChapter.chapterId, groupId, studentId, this.search]);
            if (searchParams === this.lastSearchParams) {
                resolve();

                return;
            }

            this.lastSearchParams = searchParams;

            this.isLoading = true;

            this.progressService.retrieveOverview(selectedBookChapter.bookId, selectedBookChapter.chapterId, groupId, studentId, this.search)
                .toPromise()
                .then((progress: ApiProgressOverviewInterface) => {

                    // set the scoremodel
                    if (progress && progress.book && progress.book.scoremodel) {
                        this.autocheckService.setScoreModel(progress.book.scoremodel);
                    }

                    if (progress.book) {
                        this.loadProgressData(progress);
                    }

                    this.isLoading = false;

                    resolve();
                })
                .catch((error: any) => {
                    this.isLoading = false;
                    this.handleApiErrorResponse(error);

                    reject(error);
                });
        });
    }

    private loadProgressData(progress: ApiProgressOverviewInterface): void {
        this.chapters = this.chapterFactory.fromApiProgressOverview(progress);
        this.progressOverview = progress;
        this.loadDocumentLinks();
    }

    private loadDocumentLinks(): void {
        let isTeacher: boolean = this.authorizationService.isGranted(RoleEnum.RoleTeacher);

        const selectedBookChapter = this.getActiveBookOrChapter();

        // Can't download as teacher when there's no group selected
        if (selectedBookChapter.bookId === null || (isTeacher && !this.selectedGroup)) {
            this.canDownloadDocument = false;

            return;
        }

        let studentId = this.userService.getCurrentUserId();
        const groupId = this.selectedGroup ? this.selectedGroup.id : null;

        if (isTeacher && this.selectedStudent && this.selectedStudent.id) {
            studentId = this.selectedStudent.id;
            isTeacher = false;
        }

        this.canDownloadDocument = true;
        this.action.children = [
            new ProgressActionButtonPdfModel(this.progressService.getProgressDownloadLink(DocumentTypeEnum.Pdf, isTeacher, groupId, studentId, selectedBookChapter.bookId, this.selectedStatus)),
            new ProgressActionButtonExcelModel(this.progressService.getProgressDownloadLink(DocumentTypeEnum.Excel, isTeacher, groupId, studentId, selectedBookChapter.bookId, this.selectedStatus)),
        ];
    }

    private handleApiErrorResponse(response: HttpErrorResponse): void {
        console.error(response);

        if (response.error.msg !== undefined) {
            this.toastService.error('Kon voortgang niet ophalen.');
        }
    }

    private getActiveBookOrChapter(): { bookId?: number, chapterId?: number } {
        const ret = {bookId: null, chapterId: null};

        booksLoop:
            for (const book of this.contentSwitchData) {
                if (book.active === true) {
                    ret.bookId = Number(book.id);
                    break;
                }

                for (const chapter of book.children) {
                    if (chapter.active === true) {
                        ret.bookId = Number(book.id);
                        ret.chapterId = Number(chapter.id);
                        break booksLoop;
                    }
                }
            }

        return ret;
    }
}
