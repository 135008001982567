import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LicenseService} from 'services/license/license.service';
import {ToastrService} from 'ngx-toastr';
import {finalize} from 'rxjs/operators';
import {ActivationStatusEnum} from 'pages/modules/bookshelf/components/modals/activate-license/enums/activation-status.enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxClose} from 'models/iconbox/close.model';

@Component({
    selector: 'app-activate-license',
    templateUrl: 'activate-license-modal.component.html',
})
export class ActivateLicenseModalComponent {

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorBlue]);

    public licenses: Array<ActivateLicenseInterface> = [];

    public loading: boolean = false;

    constructor(
        private activeModal: NgbActiveModal,
        private licenseService: LicenseService,
        private toastrService: ToastrService,
    ) {
        this.addCode();
    }

    public close(): void {
        this.activeModal.dismiss();
    }

    public addCode(): void {
        this.licenses.push({code: ''});
    }

    public removeCode(index: number): void {
        this.licenses.splice(index, 1);

        if (this.licenses.length === 0) {
            this.addCode();
        }
    }

    public handleActivation(): void {
        this.licenses = this.licenses.filter((license: ActivateLicenseInterface) => license.code.trim().length > 0);
        const codes = this.licenses.map((license: ActivateLicenseInterface) => license.code.trim());

        if (codes.length === 0) {
            this.addCode();

            return;
        }

        this.loading = true;

        this.licenseService.activateMultiple(codes)
            .pipe(finalize(() => this.loading = false))
            .subscribe((responses: Array<ApiLicenseActivationInterface>) => {
                let closePopup = true;

                const newCodesList = [];

                for (let i = 0; i < responses.length; i++) {
                    const response = responses[i];

                    switch (response.status) {
                        case ActivationStatusEnum.Success:
                            this.toastrService.success(`Licentie '${response.code}' is succesvol geactiveerd`);
                            break;

                        case ActivationStatusEnum.ErrorUserAlreadyHasLicense:
                        case ActivationStatusEnum.ErrorCodeAlreadyActivated:
                            this.toastrService.success(`U heeft licentie '${response.code}' reeds geactiveerd.`);
                            break;

                        case ActivationStatusEnum.ErrorCodeUnknown:
                        case ActivationStatusEnum.ErrorCodeInvalid:
                            this.toastrService.error(`Licentie '${response.code}' is invalide of onbekend.`);
                            closePopup = false;

                            this.licenses[i].failed = true;
                            newCodesList.push(this.licenses[i]);
                            break;

                        case ActivationStatusEnum.ErrorCodeExpired:
                            this.toastrService.error(`Licentie '${response.code}' is verlopen.`);
                            closePopup = false;

                            this.licenses[i].failed = true;
                            newCodesList.push(this.licenses[i]);
                            break;

                        case ActivationStatusEnum.ErrorSoap:
                        case ActivationStatusEnum.ErrorSoapActivation:
                            this.toastrService.error(`Licentie '${response.code}' is niet verwerkt wegens een systeemfout.`);
                            closePopup = false;

                            this.licenses[i].failed = true;
                            newCodesList.push(this.licenses[i]);
                            break;

                        default:
                            this.toastrService.error(`Licentie '${response.code}' is niet verwerkt wegens een onbekende fout.`);
                            closePopup = false;

                            this.licenses[i].failed = true;
                            newCodesList.push(this.licenses[i]);
                            break;
                    }
                }

                this.licenses = newCodesList;

                if (closePopup === true) {
                    this.close();
                }
            }, () => {
                this.toastrService.error('Kon de licenties niet activeren.');
            });
    }
}
