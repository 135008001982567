export enum ProgressConstantsEnum {

    FilterPlaceholder = 'placeholder',

    ClassCollectionFilter = 'filter',

    ClassCollectionFilterButton = 'filter-button',

    ClassCollectionCollapsible = 'collapsible',

    ClassCollectionHeaderButton = 'heading-action-button',

}
