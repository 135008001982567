import {Component, OnDestroy, OnInit} from '@angular/core';
import {RoleEnum} from 'enums/role.enum';
import {DocumentService} from 'document/services/document/document.service';
import {Subscription} from 'rxjs';
import {ModalService} from 'core/services/modal/modal.service';
import {InviteStateEnum} from 'enums/invite-state.enum';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {UserService} from 'security/services/user/user.service';

@Component({
    selector: 'app-document-external-reviewer',
    templateUrl: 'external-reviewer.component.html',
})
export class DocumentExternalReviewerComponent implements OnInit, OnDestroy {

    public readonly roleEnum = RoleEnum;

    public readonly inviteStateEnum = InviteStateEnum;

    public internship: ApiInternshipInterface = null;

    public task: ApiTaskDetailsInterface = null;

    public invite: ApiInternshipInviteInterface = null;

    public showForTeacher: boolean = false;

    private userChangeSubscription: Subscription;

    private answerStateChangeSubscription: Subscription;

    private modalCloseSubscription: Subscription;

    public constructor(
        private documentService: DocumentService,
        private userService: UserService,
        private modalService: ModalService,
        private answerStateService: DocumentAnswersStateService
    ) {}

    public ngOnInit(): void {
        this.setData();

        this.userChangeSubscription = this.userService.onUserDataChange.subscribe(() => this.setData());
        this.answerStateChangeSubscription = this.answerStateService.registerChangeListener(() => this.setData());
    }

    public ngOnDestroy(): void {
        if (this.userChangeSubscription instanceof Subscription) {
            this.userChangeSubscription.unsubscribe();
        }

        if (this.answerStateChangeSubscription instanceof Subscription) {
            this.answerStateChangeSubscription.unsubscribe();
        }

        if (this.modalCloseSubscription instanceof Subscription) {
            this.modalCloseSubscription.unsubscribe();
        }
    }

    private setData(): void {
        this.task = null;
        this.internship = null;
        this.invite = null;
        this.showForTeacher = false;

        const task = this.documentService.getActiveTask();

        if (task === null || !this.documentService.isBpvAssignment()) {
            return;
        }

        const user = this.userService.getUserData();

        for (const internship of user.internships) {
            if (internship.book && internship.book.id === this.documentService.getBookId()) {
                this.internship = internship;
            }
        }

        this.task = task;

        for (const invite of this.task.internshipinvites) {
            if (invite.status !== InviteStateEnum.Revoked) {
                this.invite = invite;
            }
        }

        this.showForTeacher = this.answerStateService.answersShown();
    }

    public inviteExternalReviewer(): void {
        if (this.invite && this.invite.status === InviteStateEnum.Completed) {
            return;
        }

        const modalInstance = this.modalService.externalReviewerInvite(this.task, this.invite);

        if (this.modalCloseSubscription instanceof Subscription) {
            this.modalCloseSubscription.unsubscribe();
        }

        this.modalCloseSubscription = modalInstance.closeEvent.subscribe((invite: ApiInternshipInviteInterface|null) => {
            this.invite = invite;
        });
    }
}
