import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TasksAssignedByComponent} from 'pages/modules/tasks/components/tasks-detail/assigned-by/assigned-by.component';
import {TasksAssignedToGroupComponent} from 'pages/modules/tasks/components/tasks-detail/assigned-to-group/assigned-to-group.component';
import {TasksAssignedToStudentComponent} from 'pages/modules/tasks/components/tasks-detail/assigned-to-student/assigned-to-student.component';
import {TasksAttachmentsComponent} from 'pages/modules/tasks/components/tasks-detail/attachments/attachments.component';
import {TasksCurrentTaskComponent} from 'pages/modules/tasks/components/tasks-detail/current-task/current-task.component';
import {TasksDocumentComponent} from 'pages/modules/tasks/components/tasks-detail/document/document.component';
import {TasksHandinOwnTaskComponent} from 'pages/modules/tasks/components/tasks-detail/handin-own-task/handin-own-task.component';
import {TasksScoreComponent} from 'pages/modules/tasks/components/tasks-detail/score/score.component';
import {SharedModule} from 'shared/shared.module';
import {TasksComponent} from 'pages/modules/tasks/components/tasks/tasks.component';
import {WeeksComponent} from 'pages/modules/tasks/components/tasks/weeks/weeks.component';
import {WeekComponent} from 'pages/modules/tasks/components/tasks/week/week.component';
import {TasksTableComponent} from 'pages/modules/tasks/components/tasks/tasks-table/tasks-table.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {TasksAddModalComponent} from 'pages/modules/tasks/components/modals/add-modal/add-modal.component';
import {FormsModule} from '@angular/forms';
import {TasksDetailComponent} from './components/tasks-detail/tasks-detail.component';
import {RouterModule} from '@angular/router';
import {TasksAttachmentFactory} from 'pages/modules/tasks/factories/attachment.factory';
import {DocumentModule} from 'document/document.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {TasksDeleteModalComponent} from 'pages/modules/tasks/components/modals/delete-modal/delete-modal.component';
import {FeedbackComponent} from 'pages/modules/tasks/components/tasks-detail/feedback/feedback.component';
import {DocumentPageModule} from 'pages/modules/document/document.module';
import {TasksAddTaskModalWorkTypeComponent} from 'pages/modules/tasks/components/modals/add-modal/components/work-type/work-type.component';
import {TasksAddTaskModalExamineComponent} from 'pages/modules/tasks/components/modals/add-modal/components/examine/examine.component';
import {TasksAddTaskModalUserSelectionGroupsComponent} from 'pages/modules/tasks/components/modals/add-modal/components/user-selection-groups/groups.component';
import {TasksAddTaskModalAssignmentsComponent} from 'pages/modules/tasks/components/modals/add-modal/components/assignments/assignments.component';
import {TasksAddTaskModalTitleComponent} from 'pages/modules/tasks/components/modals/add-modal/components/title/title.component';
import {TasksAddTaskModalBookComponent} from 'pages/modules/tasks/components/modals/add-modal/components/book/book.component';
import {TasksApiTaskBuilderFactory} from 'pages/modules/tasks/components/modals/add-modal/factories/api-task-builder.factory';
import {NgbPopoverModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {TasksHomeworkComponent} from 'pages/modules/tasks/components/homework/tasks-homework.component';
import {SecurityModule} from 'security/security.module';
import {OnedriveModule} from 'onedrive/onedrive.module';
import {TaskSelectorComponent} from 'pages/modules/tasks/components/tasks-detail/task-selector/task-selector.component';
import {ProgressModule} from 'pages/modules/progress/progress.module';
import {TaskStateService} from 'pages/modules/tasks/service/task-state.service';
import {TaskNotExistModalComponent} from 'pages/modules/tasks/components/modals/task-not-exist-modal/task-not-exist-modal.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        NgxDatatableModule,
        NgbTooltipModule,
        RouterModule,
        DocumentModule,
        InfiniteScrollModule,
        DocumentPageModule,
        SecurityModule,
        NgbPopoverModule,
        OnedriveModule,
        ProgressModule,
    ],
    declarations: [
        TasksComponent,
        WeeksComponent,
        WeekComponent,
        TasksTableComponent,
        TasksAddModalComponent,
        TasksDetailComponent,
        TasksDeleteModalComponent,
        FeedbackComponent,
        TasksAddTaskModalWorkTypeComponent,
        TasksAddTaskModalExamineComponent,
        TasksAddTaskModalUserSelectionGroupsComponent,
        TasksAddTaskModalAssignmentsComponent,
        TasksAddTaskModalTitleComponent,
        TasksAddTaskModalBookComponent,
        TasksHomeworkComponent,
        TasksCurrentTaskComponent,
        TaskSelectorComponent,
        TasksAssignedByComponent,
        TasksAssignedToStudentComponent,
        TasksAssignedToGroupComponent,
        TasksAttachmentsComponent,
        TasksDocumentComponent,
        TasksHandinOwnTaskComponent,
        TasksScoreComponent,
        TaskNotExistModalComponent,
    ],
    exports: [
        TasksComponent,
    ],
    entryComponents: [
        TasksAddModalComponent,
        TasksDeleteModalComponent,
        TaskNotExistModalComponent,
    ],
    providers: [
        TasksAttachmentFactory,
        TasksApiTaskBuilderFactory,
        TaskStateService,
    ],
})
export class TasksModule {
}
