import {SvgDataInterface} from 'interfaces/svg-data.interface';

export class SvgCheckModel implements SvgDataInterface {

    public name: string = 'check';

    get data(): string {
        return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <title class="rs_skip">checkmark</title>
            <path d="M27 4l-15 15-7-7-5 5 12 12 20-20z"></path>
        </svg>`;
    }
}
