import {AfterViewInit, Component, HostBinding} from '@angular/core';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';
import {DocumentTableResponsiveClassEnum} from 'document/modules/components/table-responsive/enums/class.enum';
import {TableComponent} from 'document/modules/abstract/table.component';

@Component({
    selector: '.document-table-responsive',
    templateUrl: 'table-responsive.component.html',
})
export class DocumentTableResponsiveComponent extends TableComponent implements AfterViewInit {

    @HostBinding('class')
    public elementClass: string = DocumentHostClassesEnum.TableResponsive;

    public classModifiers: Array<string> = [
        DocumentTableResponsiveClassEnum.Responsive
    ];

    public showEnlarge: boolean;

    protected table: HTMLTableElement;

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.showEnlarge = true;
    }
}
