import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {finalize} from 'rxjs/operators';
import {ExamService} from 'services/exam/exam.service';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxClose} from 'models/iconbox/close.model';

@Component({
    selector: 'app-exams-delete-modal-component',
    templateUrl: 'delete-modal.component.html',
})
export class ExamsDeleteModalComponent {

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorExam]);

    @Input()
    public examPlanId: number;

    @Output()
    public examPlanDeletedEvent: EventEmitter<null> = new EventEmitter();

    public isLoading: boolean = false;

    constructor(
        private activeModal: NgbActiveModal,
        private examService: ExamService,
        private toastService: ToastrService,
    ) {}

    public close(): void {
        this.activeModal.dismiss();
    }

    public deleteTask(): void {
        this.isLoading = true;

        this.examService.deleteExam(this.examPlanId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(() => {
                this.toastService.success('Toets is verwijderd.');

                this.close();
                this.examPlanDeletedEvent.emit();
            }, (err: any) => {
                console.error(err);

                this.toastService.error('Kon toets niet verwijderen.');
            });
    }
}
