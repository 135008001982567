import {AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, Output, Renderer2} from '@angular/core';
import {ModalService} from 'core/services/modal/modal.service';
import {DocumentService} from 'document/services/document/document.service';
import {SummaryService} from 'services/summary/summary.service';

@Component({
    selector: 'app-document-summary-button',
    templateUrl: 'button.component.html',
})
export class DocumentSummaryButtonComponent implements AfterViewInit {

    private readonly blackList: Array<string> = [
        'document__reference',
        'document__textarea',
        'document__assignment',
    ];

    @HostBinding('class')
    private className: string = 'document-summary-button';

    @Input()
    public selection: Selection;

    @Output()
    public clickEvent: EventEmitter<any> = new EventEmitter();

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private modalService: ModalService,
        private documentService: DocumentService,
        private summaryService: SummaryService,
    ) {}

    public ngAfterViewInit(): void {
        const buttonElement: HTMLElement = this.elementRef.nativeElement;
        const anchorElement: HTMLElement = this.selection.anchorNode.parentElement;

        if (!anchorElement) {
            return;
        }

        const offset = this.getOffset(anchorElement);

        this.renderer.setStyle(this.elementRef.nativeElement, 'top', `${offset.top - buttonElement.scrollHeight}px`);
        this.renderer.setStyle(this.elementRef.nativeElement, 'left', `${offset.left + (anchorElement.offsetWidth / 2) - (buttonElement.scrollWidth / 2)}px`);
    }

    private getOffset(element: HTMLElement): {top: number, left: number} {
        const rect = element.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        return {
            top: rect.top + scrollTop,
            left: rect.left + scrollLeft
        };
    }

    public onAddToSummary(): void {
        let content = this.getSelectionContent();

        const bookId = this.documentService.getBookId();
        const document = this.documentService.getDocument();

        const existingSummary = this.summaryService.getCurrentSummary();

        if (existingSummary !== null) {
            content = `${existingSummary.text}<br>${content}`;

            this.modalService.summaryEdit(existingSummary, bookId, document.title, document.parent_uuid, content);
        } else {
            this.modalService.summaryCreate(bookId, document.title, document.parent_uuid, content);
        }

        this.selection.removeAllRanges();
        this.clickEvent.emit();
    }

    private getSelectionContent(): string {
        if (this.selection.rangeCount === 0) {
            return;
        }

        const contentNode = this.selection.getRangeAt(0).cloneContents();

        const divElement = document.createElement('div');
        divElement.appendChild(contentNode);

        for (const blackListItem of this.blackList) {
            const blacklistedItems = divElement.getElementsByClassName(blackListItem);

            for (let i = 0; i < blacklistedItems.length; i++) {
                blacklistedItems.item(i).remove();
            }
        }

        const innerHtml = divElement.innerHTML;
        divElement.remove();

        return innerHtml;
    }
}
