import {DocumentPageActionsButtonsEnum} from 'pages/modules/document/enums/actions-buttons.enum';
import {DocumentActionButtonInterface} from 'pages/modules/document/interfaces/action-button.interface';
import {IconEnum} from 'enums/icon.enum';
import {RoleEnum} from 'enums/role.enum';

export class DocumentActionsButtonEBookModel implements DocumentActionButtonInterface {

    public id: DocumentPageActionsButtonsEnum = DocumentPageActionsButtonsEnum.EBook;

    public value: string = 'E-book';

    public icon: IconEnum = IconEnum.Ebook2;

    public roles: Array<RoleEnum> = [
        RoleEnum.RoleStudentOrTeacher,
    ];

    constructor(public visible: boolean = true) {
    }
}
