import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {RoleEnum} from 'enums/role.enum';

@Directive({
    selector: '[appIsNotReviewer]'
})
export class IsNotReviewerDirective {
    constructor(
        private authorizationService: AuthorizationService,
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {
    }

    @Input()
    public set appIsNotReviewer(value: any) {
        this.updateView();
    }

    private updateView(): void {
        if (!this.authorizationService.isGranted(RoleEnum.RoleReviewer)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
