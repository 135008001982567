import {Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, Renderer2} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';
import {IconEnum} from 'enums/icon.enum';

@Component({
    selector: 'app-card',
    templateUrl: 'card.component.html',
})
export class CardComponent implements OnInit {

    protected static readonly CLASS = 'card';

    @Output()
    protected clickEvent: EventEmitter<void> = new EventEmitter();

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public prefixIcon: IconEnum;

    @Input()
    public affix: string;

    @Input()
    public title: string;

    @Input()
    public description: string;

    @Input()
    public route?: string;

    @Input()
    public htmlDescription: boolean = false;

    @HostBinding('class')
    protected classes: string;

    public classHelper: ClassHelper = new ClassHelper(CardComponent.CLASS);

    constructor(
        private renderer: Renderer2,
    ) {
    }

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classModifiers);
        this.classes = this.classHelper.toString();

        this.updateDescription();
    }

    @HostListener('click')
    public onClick(): void {
        this.clickEvent.emit();
    }

    private updateDescription(): void {
        if (this.htmlDescription) {
            return;
        }

        const spanElement: HTMLSpanElement = this.renderer.createElement('span');

        spanElement.innerHTML = this.description;
        this.description = spanElement.outerHTML;
    }
}
