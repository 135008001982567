import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {NgbCalendar, NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
    selector: 'app-date-time-picker',
    templateUrl: 'date-time.component.html',
})
export class DateTimePickerComponent implements OnChanges {

    @Input()
    public dateTime: number;

    @Output()
    public dateTimeChange: EventEmitter<number> = new EventEmitter();

    @Input()
    public disabled: boolean = false;

    @Input()
    public min?: number = null;

    public date: NgbDateStruct = {day: 0, month: 0, year: 0};

    public time: NgbTimeStruct = {hour: 0, minute: 0, second: 0};

    public minDate?: NgbDateStruct = null;

    constructor(calendar: NgbCalendar) {
        this.minDate = calendar.getToday();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.dateTime && changes.dateTime.currentValue) {
            const dateTime = moment.unix(changes.dateTime.currentValue);

            this.date = {
                day: dateTime.date(),
                month: dateTime.month() + 1,
                year: dateTime.year()
            };

            this.time = {
                hour: dateTime.hour(),
                minute: dateTime.minute(),
                second: 0
            };
        }

        if (changes.minDate) {
            if (changes.minDate.currentValue) {
                const dateTime = moment.unix(changes.dateTime.currentValue);

                this.minDate = {
                    day: dateTime.date(),
                    month: dateTime.month() + 1,
                    year: dateTime.year()
                };
            } else {
                this.minDate = null;
            }
        }
    }

    public onDateTimeChange(): void {
        const timeStamp: number | null = this.createTimeStamp();

        if (!timeStamp) {
            return;
        }

        this.dateTimeChange.emit(this.createTimeStamp());
    }

    private createTimeStamp(): number | null {
        if (!this.date) {
            return null;
        }

        return moment(`${this.date.day}-${this.date.month}-${this.date.year} ${this.time.hour}:${this.time.minute}:${this.time.second}`, 'D-M-YYYY H:m:s').unix();
    }
}
