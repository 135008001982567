import {AfterViewInit, ContentChildren, Directive, HostBinding, QueryList} from '@angular/core';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';
import {DocumentReviewComponent} from 'document/modules/components/review/review.component';

@Directive({
    selector: '.document-opdracht'
})
export class DocumentAssignmentDirective implements AfterViewInit {

    @HostBinding('class')
    public elementClass: string = DocumentHostClassesEnum.Assignment;

    @ContentChildren(DocumentReviewComponent, {descendants: true})
    public reviewComponents: QueryList<DocumentReviewComponent>;

    public ngAfterViewInit(): void {
        this.handleReviewComponents();
    }

    private handleReviewComponents(): void {
        if (this.reviewComponents.length === 0) {
            return;
        }

        this.reviewComponents.last.showExternalReviewer = true;
    }
}
