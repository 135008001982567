import {ContentSelectItemInterface} from 'shared/modules/content-select/interfaces/select-item.interface';

export class ContentSelectOrElseModel implements ContentSelectItemInterface {

    public id: string = '-1';

    public title: string = 'Anders, namelijk...';

    public parentId: string;

    public classModifiers: Array<string> = [];

    public children: Array<ContentSelectOrElseModel> = [];

    constructor(
        public active: boolean = false
    ) {
    }

    public setChildren(children: Array<ContentSelectOrElseModel>): void {
        this.children = children;
    }
}
