import {AfterViewInit, Component, ElementRef, HostBinding} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {DocumentAnswerableComponent} from 'document/modules/abstract/answerable.component';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {AlertMessageInterface} from 'shared/components/alert-message/interfaces/alertMessageInterface';
import {TaskNotAvailableAlertMessage} from 'shared/components/alert-message/models/task-not-available.model';
import {HttpErrorResponse} from '@angular/common/http';
import {AlgebrakitService} from 'services/algebrakit/algebrakit.service';
import {ToastrService} from 'ngx-toastr';
import {DocumentService} from 'services/document/document.service';
import {AssignmentService} from 'services/assignment/assignment.service';
import {DocumentAssignmentStatusEnum} from 'pages/modules/document/enums/document-assignment-status.enum';

@Component({
    selector: '.document-algebrakit',
    templateUrl: 'algebrakit.component.html',
})
export class DocumentAlgebrakitComponent extends DocumentAnswerableComponent implements AfterViewInit {

    @HostBinding('class')
    public elementClass: string = 'document__algebrakit';

    public id: string;

    private answer: string;

    public value: SafeHtml = '';

    public correctValue: SafeHtml = '';

    public alertMessage: AlertMessageInterface = new TaskNotAvailableAlertMessage();

    public documents: Array<ApiDocumentDataInterface> = null;

    public hasContent = true;

    public countGetElement: number = 0;

    public repeatable: boolean = false;

    constructor(
        protected elementRef: ElementRef,
        protected answersService: DocumentAnswersService,
        protected showAnswersService: DocumentAnswersStateService,
        protected sanitizer: DomSanitizer,
        protected algebrakitService: AlgebrakitService,
        protected toastService: ToastrService,
        private documentService: DocumentService,
    ) {
        super(elementRef, answersService, showAnswersService);

        this.id = `algebrakit-${this.dataSet.assignmentid}-${this.dataSet.fieldid}`;
    }

    public ngAfterViewInit(): void {
        this.getElementRef();
    }

    public getElementRef(): void {
        if (this.hasContentBeenLoaded()) {
            this.hasContent = true;
        } else {
            setTimeout(() => {
                this.hasContent = false;
                this.countGetElement = this.countGetElement + 500;

                if (this.countGetElement <= 20000) {
                    this.getElementRef();
                }
            }, 500);
        }
    }

    protected setAnswer(answer: string): void {
        this.answer = answer;
        this.setValue();
    }

    protected disableAnswers(): void {
        this.setValue();
    }

    protected enableAnswers(): void {
        this.setValue();
    }

    protected showCorrectAnswer(correctAnswer: string): void {
        this.correctValue = this.sanitizer.bypassSecurityTrustHtml(`<akit-exercise solution-mode="true" session-id="${this.answer}"></akit-exercise>`);
    }

    protected showInitialAnswer(initialAnswer: string): void {
        //
    }

    protected setValue(): void {
        this.countGetElement = 0;
        this.getElementRef();

        if (!this.answer) {
            return;
        }

        if (this.answersDisabled) {
            this.value = this.sanitizer.bypassSecurityTrustHtml(`<akit-exercise review-mode="true" session-id="${this.answer}"></akit-exercise>`);
        } else {
            this.value = this.sanitizer.bypassSecurityTrustHtml(` <akit-exercise session-id="${this.answer}"></akit-exercise>`);
        }

        const documentTaskStatus: undefined | number = this.getDocumentTaskStatus();

        if (undefined === documentTaskStatus) {
            console.error('Content error: task status is not found for algebrakit');

            return;
        }

        if (this.field && this.field.options) {
            this.repeatable = this.field.options.repeatable === 'true' ? documentTaskStatus !== DocumentAssignmentStatusEnum.Open : false;
        }
    }

    protected answerValid(): boolean {
        return true;
    }

    public cleanAnswer(): void {
        if (!this.field) {
            return;
        }

        this.algebrakitService.cleanAnswer(this.field.id.toString())
            .subscribe((data: any) => {
                if (data && data.value) {
                    this.setAnswer(data.value);
                }
            }, (errorResponse: HttpErrorResponse) => {
                this.toastService.error('Kon opdracht(en) niet opschonen.');
            });
    }

    private hasContentBeenLoaded(): boolean {
        if (!(this.elementRef.nativeElement.children && this.elementRef.nativeElement.children[0] && this.elementRef.nativeElement.children[0].children.length > 0 &&
            this.elementRef.nativeElement.children[0].children[1])) {
            return false;
        }

        return this.elementRef.nativeElement.children[0].children[1].children[0].children.length > 0 || this.elementRef.nativeElement.children[0].children[1].children[0].shadowRoot.children.length > 0;
    }

    private getDocumentTaskStatus(): number {
        if (!this.documentService.getActiveDocument() || this.documentService.getActiveDocument() && this.documentService.getActiveDocument()[0].assignments.length < 1) {
            return 0;
        }

        if (!this.documentService.getActiveDocument()[0].assignments[0].tasks && this.documentService.getActiveDocument()[0].assignments[0].tasks.length < 1) {
            return 0;
        }

        const currentDocumentTask = this.documentService.getActiveDocument()[0].assignments[0].tasks[0];

        if (!currentDocumentTask) {
            return 0;
        }

        return currentDocumentTask.status;
    }
}
