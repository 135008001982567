import {Component, Input, OnDestroy} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {ExamService} from 'services/exam/exam.service';
import {ExamUserResultService} from 'pages/modules/exams/service/exam-user-result.service';
import {ExamSystemEnum} from 'enums/exam-system.enum';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-exams-exam-examination-result',
    templateUrl: 'exam-examination-result.component.html',
})
export class ExamsExamExaminationResultComponent implements OnDestroy {

    private examUserResultSubscription: Subscription;

    public readonly rowClasses = GridTableRowClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly examSystem = ExamSystemEnum;

    public readonly routesEnum = RoutesEnum;

    public readonly iconEnum = IconEnum;

    @Input()
    public examPlan: ApiExamPlanDetailsInterface;

    public examResult: ApiExamResultInterface;

    constructor(
        private examService: ExamService,
        examUserResultService: ExamUserResultService,
    ) {
        this.examUserResultSubscription = examUserResultService.subscribe(
            result => {
                    this.setExamResult(result);
                });
    }

    public ngOnDestroy(): void {
        this.examUserResultSubscription.unsubscribe();
    }

    public mayStartExam(): boolean {
        return this.examService.mayStartExam(this.examPlan);
    }

    private setExamResult(result?: ApiExamUserResultInterface): void {
        if (null === result) {
            return;
        }

        this.examResult = result.examResult;
    }
}
