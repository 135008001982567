import {Injectable} from '@angular/core';
import {DocumentSourceInterface} from 'document/modules/interfaces/source.interface';
import {DocumentReferenceComponent} from 'document/modules/components/reference/reference.component';
import {IconEnum} from 'enums/icon.enum';
import {MimetypeEnum} from 'enums/mimetype.enum';
import {LinkTargetEnum} from 'enums/link-target.enum';
import {ApiService} from 'core/services/api/api.service';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {SourceAccessibleForEnum} from 'pages/modules/document/enums/source-accessible-for.enum';
import {SourceDisplayOnEnum} from 'pages/modules/document/enums/source-display-on.enum';
import {DocumentSourceTypeEnum} from 'document/modules/enums/source-type.enum';

@Injectable()
export class DocumentSourceFactory {

    constructor(
        private apiService: ApiService,
    ) {
    }

    public fromApiSource(sourceResponse: ApiSourceInterface): DocumentSourceInterface {
        const displayOn: any = sourceResponse.page_uuid
            ? SourceDisplayOnEnum.ThisPage
            : SourceDisplayOnEnum.AllPages;
        const accessibleFor: SourceAccessibleForEnum = sourceResponse.teacher_only === 1
            ? SourceAccessibleForEnum.Teachers
            : SourceAccessibleForEnum.TeachersAndStudents;

        return {
            id: sourceResponse.id.toString(),
            icon: sourceResponse.mimetype === MimetypeEnum.Text ? IconEnum.Link : IconEnum.Document,
            value: sourceResponse.title,
            link: this.apiService.buildUrl(ApiEndpointEnum.SourceGet, new Map([['sourceId', sourceResponse.id.toString()]])),
            target: LinkTargetEnum.Blank,
            editable: true,
            isOwner: sourceResponse.isOwner,
            uuid: displayOn ? sourceResponse.page_uuid : null,
            accessibleFor,
            displayOn,
            type: sourceResponse.type === 'file' ? DocumentSourceTypeEnum.File : DocumentSourceTypeEnum.Link,
            mimeType: sourceResponse.mimetype
        };
    }

    public fromApiSources(references: Array<ApiSourceInterface>): Array<DocumentSourceInterface> {
        const documentReferences: Array<DocumentSourceInterface> = [];

        references.forEach((apiSource: ApiSourceInterface) => {
            documentReferences.push(this.fromApiSource(apiSource));
        });

        return documentReferences;
    }

    public fromDocumentReference(reference: DocumentReferenceComponent): DocumentSourceInterface {
        const linkElement: HTMLLinkElement = reference.documentReferenceLink.nativeElement;

        return {
            id: reference.id,
            icon: reference.iconBox.value,
            value: reference.value,
            link: linkElement.href,
            target: linkElement.target,
            editable: false,
            isOwner: false,
            uuid: null,
            mimeType: null
        };
    }

    public fromBody(title: string, href: string, target: string): DocumentSourceInterface {
        return {
            id: null,
            icon: IconEnum.Link,
            value: title,
            link: href,
            target: target,
            editable: false,
            isOwner: false,
            uuid: null,
            mimeType: null
        };
    }
}
