export class UserIdentityModel {

    constructor(
        public firstName: string,
        public middleName: string,
        public lastName: string,
        public email: string,
        public avatar: string,
        public organizationName: string,
        public use_one_drive: boolean,
        public one_drive_open_preference?: string,
        public one_drive_root?: string,
        public one_drive_username?: string
    ) {
    }

    public static fromUser(user: UserIdentityModel): UserIdentityModel {
        return new UserIdentityModel(
            user.firstName,
            user.middleName,
            user.lastName,
            user.email,
            user.avatar,
            user.organizationName,
            user.use_one_drive,
            user.one_drive_open_preference,
            user.one_drive_root,
            user.one_drive_username
        );
    }

    public toApiBody(): ApiUserIdentityInterface {
        return {
            firstname: this.firstName,
            middlename: this.middleName,
            lastname: this.lastName,
            email: this.email,
            use_one_drive: this.use_one_drive,
            one_drive_open_preference: this.one_drive_open_preference,
            one_drive_root: this.one_drive_root,
            one_drive_username: this.one_drive_username
        };
    }
}
