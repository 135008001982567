import {AfterContentInit, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridTableColComponent} from 'shared/modules/grid-table/components/col/grid-table-col.component';
import {WindowBreakPointsEnum} from 'enums/window-break-points.enum';

@Component({
    selector: 'app-grid-table-col-floatable',
    templateUrl: 'grid-table-col-floatable.component.html',
})
export class GridTableColFloatableComponent extends GridTableColComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {

    public readonly floatingCollection = 'floating';

    @Input()
    public float: boolean = true;

    public constructor(protected elementRef: ElementRef) {
        super();
        this.classHelper.addClass(GridTableColClassEnum.Floatable);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.handleFixedState();

        super.ngOnChanges(changes);
    }

    public ngOnInit(): void {
        this.handleFixedState();

        super.ngOnInit();
    }

    protected setHostClasses(): void {
        super.setHostClasses();

        this.hostClasses = `${this.hostClasses} ${this.classHelper.toString(this.floatingCollection)}`;
    }

    @HostListener('window:scroll')
    @HostListener('window:resize')
    private handleScrollEvent(): void {
        this.handleFixedState();

        this.setHostClasses();
    }

    private handleFixedState(): void {
        if (this.float !== true) {
            this.classHelper.removeClass(GridTableColClassEnum.Floating, this.floatingCollection);

            return;
        }

        if (window.innerWidth < WindowBreakPointsEnum.Small) {
            this.classHelper.removeClass(GridTableColClassEnum.Floating, this.floatingCollection);

            return;
        }

        const nativeElement: HTMLElement = this.elementRef.nativeElement;
        const bounding = nativeElement.getBoundingClientRect();

        if (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        ) {
            this.classHelper.removeClass(GridTableColClassEnum.Floating, this.floatingCollection);
        } else if (!this.classHelper.hasClass(GridTableColClassEnum.Floating, this.floatingCollection)) {
            this.classHelper.addClass(GridTableColClassEnum.Floating, this.floatingCollection);
        }
    }
}
