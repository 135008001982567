import {NgModule} from '@angular/core';
import {OneDriveService} from 'onedrive/services/onedrive.service';
import {SharedModule} from 'shared/shared.module';
import {MsAlLoginService} from 'onedrive/services/msallogin.service';

@NgModule({
    providers: [
        OneDriveService,
        MsAlLoginService

    ],
    imports: [
        SharedModule
    ],
    declarations: [],
    exports: [],
})
export class OnedriveModule {
}
