import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';
import {GroupsModuleGroupAddClassesEnum} from 'pages/modules/groups/enums/group-add-classes.enum';
import {UserService} from 'security/services/user/user.service';
import {RoleEnum} from 'enums/role.enum';
import {GroupsModuleGroupAddActionsEnum} from 'pages/modules/groups/enums/group-add-actions.enum';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {ActionButtonFactory} from 'shared/factories/action-button.factory';
import {IconEnum} from 'enums/icon.enum';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-groups-add-group-component',
    templateUrl: 'add-group.component.html',
})
export class GroupsAddGroupComponent implements OnInit, OnChanges {

    @Output()
    public addGroupClick: EventEmitter<void> = new EventEmitter();

    @Output()
    public joinGroupClick: EventEmitter<void> = new EventEmitter();

    @Input()
    public isDisabled: boolean = false;

    @Input()
    public classModifiers: Array<string> = [];

    public action: ActionButtonInterface;

    public classHelper: ClassHelper = new ClassHelper();

    public archiveModeActive: boolean = environment.archiveModeActive;

    constructor(private userService: UserService, private actionButtonFactory: ActionButtonFactory) {
        const isTeacher: boolean = this.userService.getUserData().role === RoleEnum.RoleTeacher;
        const createGroupButtonId: string = GroupsModuleGroupAddActionsEnum.AddGroup.toString();
        const joinGroupButtonId: string = GroupsModuleGroupAddActionsEnum.JoinGroup.toString();

        this.action = isTeacher
            ? this.actionButtonFactory.createGreenButton(createGroupButtonId, 'Groep toevoegen', IconEnum.Add)
            : this.actionButtonFactory.createGreenButton(joinGroupButtonId, 'Groepscode invoeren', IconEnum.Add);
    }

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classModifiers);

        if (this.isDisabled) {
            this.classHelper.addClass(GroupsModuleGroupAddClassesEnum.Disabled);
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.isDisabled && !changes.isDisabled.currentValue) {
            this.classHelper.removeClass(GroupsModuleGroupAddClassesEnum.Disabled);
        }
    }

    public handleClick(): void {
        if (this.isDisabled) {
            return;
        }

        switch (Number(this.action.id)) {
            case GroupsModuleGroupAddActionsEnum.JoinGroup:
                this.joinGroupClick.emit();
                break;
            case GroupsModuleGroupAddActionsEnum.AddGroup:
                this.addGroupClick.emit();
                break;
        }
    }
}
