import {Injectable} from '@angular/core';
import {MessageCenterMessageInterface} from 'pages/modules/message-center/interfaces/message.interface';
import {MessageCenterMessageTypeFactory} from 'pages/modules/message-center/factories/message-type.factory';

@Injectable()
export class MessageCenterMessageFactory {

    constructor(
        private messageCenterMessageTypeFactory: MessageCenterMessageTypeFactory,
    ) {
    }

    public fromApiNotification(
        apiNotification: ApiNotificationInterface,
    ): MessageCenterMessageInterface {
        return {
            id: apiNotification.id,
            created: apiNotification.created,
            title: apiNotification.title,
            description: apiNotification.description,
            read: apiNotification.viewed !== null,
            messageType: this.messageCenterMessageTypeFactory.fromApiNotification(apiNotification),
        };
    }

    public fromApiMessage(
        apiMessage: ApiMessageInterface,
    ): MessageCenterMessageInterface {
        return {
            id: apiMessage.message.id,
            created: apiMessage.created_at,
            title: apiMessage.message.title,
            description: apiMessage.message.body,
            read: apiMessage.viewed !== null,
            messageType: this.messageCenterMessageTypeFactory.fromApiMessage(apiMessage),
            push: apiMessage.message.push,
        };
    }

    public fromApiMessagesAndApiNotifications(
        data: ApiNotificationsInterface,
    ): Array<MessageCenterMessageInterface> {
        const mergedData: Array<MessageCenterMessageInterface> = [];

        for (const notification of data.notifications) {
            if (notification['message'] !== undefined) {
                mergedData.push(this.fromApiMessage(<ApiMessageInterface>notification));
            } else {
                mergedData.push(this.fromApiNotification(<ApiNotificationInterface>notification));
            }
        }

        return mergedData;
    }
}
