import {Pipe, PipeTransform} from '@angular/core';
import {DurationInputArg2} from 'moment';
import * as moment from 'moment';

@Pipe({
    name: 'momentDuration'
})
export class MomentDurationPipe implements PipeTransform {

    public transform(value: number, measurement: DurationInputArg2 = 'seconds'): any {
        const momentDuration: moment.Duration = moment.duration(value, measurement);

        if (!value || !momentDuration.isValid()) {
            return '-';
        }

        return `${this.withPrefixZero(momentDuration.minutes())}:${this.withPrefixZero(momentDuration.seconds())}`;
    }

    private withPrefixZero(value: number): string {
        let ret = value.toString();

        if (value < 10) {
            ret = `0${ret}`;
        }

        return ret;
    }

}
