import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {ContentSelectItemInterface} from 'shared/modules/content-select/interfaces/select-item.interface';
import {ContentSelectModelFactory} from 'shared/modules/content-select/factories/model.factory';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';

@Component({
    selector: 'app-tasks-add-modal-task-book',
    templateUrl: 'book.component.html',
})
export class TasksAddTaskModalBookComponent implements OnChanges {

    public readonly iconBoxEnum = IconBoxClassEnum;

    @Input()
    public book: number = null;

    @Output()
    public bookChange: EventEmitter<number> = new EventEmitter();

    @Input()
    public books: Array<ApiBookInterface> = [];

    @Input()
    public disabled: boolean = false;

    public booksClassModifiers: Array<ContentSelectClassModifiersEnum> = [
        ContentSelectClassModifiersEnum.Books,
        ContentSelectClassModifiersEnum.Grey,
    ];

    public activeBook: ContentSelectItemInterface;

    public booksContentItems: Array<ContentSelectItemInterface> = [];

    constructor(
        private contentSwitchModelFactory: ContentSelectModelFactory
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.books && changes.books.currentValue) {
            const contentItems = [];

            for (const book of this.books) {
                const bookModel = this.contentSwitchModelFactory.fromApiBook(book);

                if (this.book !== null && book.id === this.book) {
                    bookModel.active = true;
                    this.activeBook = bookModel;
                }

                contentItems.push(bookModel);
            }

            this.booksContentItems = contentItems;
        }
    }

    public onBookChange(selection: Array<ContentSelectItemInterface>): void {
        if (selection.length === 0 && this.book) {
            this.activeBook.active = false;
            this.book = this.activeBook = null;
        }

        if (selection.length > 0) {
            this.activeBook = selection[0];
            this.book = Number(this.activeBook.id);
        }

        this.bookChange.emit(this.book);
    }
}
