import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ShelfFolderComponent} from 'pages/modules/teacher-material/components/shelf/folder/folder.component';
import {ReturnToTeacherMaterialsModel} from 'pages/modules/teacher-material/models/return-to-teacher-materials.model';
import {BookshelfItemClassEnum} from 'pages/modules/bookshelf/components/item/enums/item-class.enum';

@Component({
    selector: 'app-shelf-return',
    templateUrl: '../folder/folder.component.html',
})
export class ShelfReturnComponent extends ShelfFolderComponent implements OnInit, OnChanges {

    @Input()
    public pathComponent: ApiPathComponentInterface;

    public ngOnChanges(): void {
        this.folder = new ReturnToTeacherMaterialsModel(this.pathComponent);
    }

    public handleClick(event: Event): void {
        event.preventDefault();
        this.folderChange.emit({
            bookId: this.bookId.toString(),
            folderDpsid: this.pathComponent ? this.pathComponent.dpsid : null,
        });
    }

    public ngOnInit(): void {
        this.class = BookshelfItemClassEnum.FolderReturn;
        this.documentType = this.resourceTypeEnum.ReturnFolder;
    }
}
