import {AfterViewInit, Component, HostBinding} from '@angular/core';
import {TableComponent} from 'document/modules/abstract/table.component';

@Component({
    selector: '.document-table[border="0"]',
    templateUrl: 'table.component.html',
})
export class DocumentTableComponent extends TableComponent implements AfterViewInit {

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
    }
}
