import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class FullscreenService {

    public fullscreenObservable = new Subject<boolean>();

    private _fullscreen: boolean = false;

    public setFullscreen(value: boolean): void {
        this._fullscreen = value;
        this.fullscreenObservable.next(value);
    }
}
