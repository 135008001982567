import {NgModule} from '@angular/core';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'shared/shared.module';
import {SimpleModalComponent} from 'core/components/modals/components/simple-modal/simple.component';

@NgModule({
    imports: [
        CommonModule,
        NgbModalModule,
        SharedModule,
    ],
    declarations: [
        SimpleModalComponent,
    ],
    exports: [
        SimpleModalComponent,
    ],
    entryComponents: [
        SimpleModalComponent,
    ],
})
export class ModalsModule {
}
