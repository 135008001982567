import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ProgressStatusFilterEnum} from 'shared/components/status-filter/enum/progress-status-filter.enum';
import {StudentProgressInterface} from 'pages/modules/progress/components/student-details/student-results/student-results.component';

export interface ActiveTaskEventInterface {
    task: ApiTaskDetailsInterface;
    loadMasterTask: boolean;
    navigateToTask: boolean;
}

@Injectable()
export class TaskStateService {

    private activeTask: ApiTaskDetailsInterface | undefined;

    private activeTaskEventSubject: BehaviorSubject<ActiveTaskEventInterface> = new BehaviorSubject(undefined);

    private activeProgressFilter: ProgressStatusFilterEnum = ProgressStatusFilterEnum.All;

    private activeProgressStatusFilterSubject: BehaviorSubject<ProgressStatusFilterEnum> = new BehaviorSubject(ProgressStatusFilterEnum.All);

    private onActiveTaskScoreChange: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private activeTaskScoreChange: boolean = false;

    private tasksSubject: BehaviorSubject<StudentProgressInterface> = new BehaviorSubject(undefined);

    private tasks: StudentProgressInterface;

    public subscribeToActiveTask(fn: (activeTask: ActiveTaskEventInterface | undefined) => void): Subscription {
        return this.activeTaskEventSubject.subscribe(fn);
    }

    public setActiveTask(task: ApiTaskDetailsInterface, loadMasterTask: boolean = true, navigateToTask: boolean = true): void {
        this.activeTask = task;
        this.activeTaskEventSubject.next({ task, loadMasterTask, navigateToTask });
    }

    public unsetActiveTask(): void {
        if (undefined === this.activeTask) {
            return;
        }

        this.activeTaskEventSubject.next(this.activeTask = undefined);
    }

    public subscribeToProgressStatusFilter(fn: (progressStatusFilter: ProgressStatusFilterEnum) => void): Subscription {
        return this.activeProgressStatusFilterSubject.subscribe(fn);
    }

    public setProgressStatusFilter(progressFilter: ProgressStatusFilterEnum): void {
        this.activeProgressFilter = progressFilter;
        this.activeProgressStatusFilterSubject.next(progressFilter);
    }

    public getProgressStatusFilter(): ProgressStatusFilterEnum {
        return this.activeProgressFilter;
    }

    public subscribeToActiveTaskScoreChange(fn: (activeTaskScoreChange: boolean) => void): Subscription {
        return this.onActiveTaskScoreChange.subscribe(fn);
    }

    public setActiveTaskScoreChange(activeTaskScoreChange: boolean): void {
        this.activeTaskScoreChange = activeTaskScoreChange;
        this.onActiveTaskScoreChange.next(activeTaskScoreChange);
    }

    public getActiveTaskScoreChange(): boolean {
        return this.activeTaskScoreChange;
    }

    public subscribeToTasks(fn: (tasks: StudentProgressInterface) => void): Subscription {
        return this.tasksSubject.subscribe(fn);
    }

    public setTasks(tasks: StudentProgressInterface): void {
        this.tasks = tasks;
        this.tasksSubject.next(this.tasks);
    }

    public getTasks(): StudentProgressInterface {
        return this.tasks;
    }
}
