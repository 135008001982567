import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {environment} from 'environments/environment';
import {finalize} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {UserService} from 'security/services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {CheckBoxInterface} from 'shared/components/check-box/interfaces/check-box.interface';
import {CheckBoxModelInterface} from 'shared/components/check-box/interfaces/check-box-model.interface';
import {ApiUserDataInterface} from 'interfaces/api/user-data.interface';

@Component({
    selector: 'app-terms-conditions',
    templateUrl: 'terms-conditions.component.html',
    providers: [NgbCarouselConfig]
})
export class TermsConditionsModalComponent implements OnInit {

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly termsConditionsLink = environment.termsAndConditionsUrl;

    public readonly termsCheckOptions: Array<CheckBoxInterface> = [{
        id: 0,
        value: 'Ik ga akkoord met de algemene voorwaarden',
    }];

    public accepted: CheckBoxModelInterface = {0: false};

    public isLoading: boolean = false;

    public user: ApiUserDataInterface;

    constructor(
        private activeModal: NgbActiveModal,
        private userService: UserService,
        private toastService: ToastrService,
    ) {
    }

    public ngOnInit(): void {
        this.user = this.userService.getUserData();
    }

    public handleDeny(): void {
        this.activeModal.dismiss(false);
    }

    public handleSave(): void {
        if (this.accepted[0] !== true) {
            return;
        }

        this.isLoading = true;

        this.userService.acceptTerms()
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(() => {
                this.toastService.success('U heeft de algemene voorwaarden geaccepteerd');

                this.activeModal.close(true);
            }, (error: HttpErrorResponse) => {
                this.toastService.error('Kon de aanvraag niet verwerken wegens een systeem fout.');
            });
    }

}
