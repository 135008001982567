import {Injectable} from '@angular/core';
import {ProgressExamsInterface} from 'pages/modules/progress/interfaces/exams.interface';
import {ProgressExamsModel} from 'pages/modules/progress/models/exams.model';

@Injectable()
export class ProgressExamsFactory {

    public fromApiProgressStudent(progressStudent: ApiProgressStudentInterface): Array<ProgressExamsInterface> {
        const statuses: Array<ProgressExamsModel> = [];

        (progressStudent.score.exams || []).forEach((examStatus: ApiProgressStudentScoreExamStatusInterface) => {
            statuses.push(ProgressExamsModel.fromApiProgressStudentScoreExamStatus(examStatus));
        });

        return statuses;
    }

    public fromApiProgressForStudent(progressForStudent: ApiProgressForStudentInterface): Array<ProgressExamsInterface> {
        const statuses: Array<ProgressExamsModel> = [];
        const studentScore: ApiProgressStudentScoreInterface = progressForStudent.studymaterials[0].scores[0];

        (studentScore.exams || []).forEach((examStatus: ApiProgressStudentScoreExamStatusInterface) => {
            statuses.push(ProgressExamsModel.fromApiProgressStudentScoreExamStatus(examStatus));
        });

        return statuses;
    }
}
