export enum GroupsModuleActionsEnum {

    RenewCode = 'renew',

    Active = 'active',

    Edit = 'edit',

    Actions = 'actions',

    Expand = 'expand',

    Checked = 'checked',

}
