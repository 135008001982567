import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-content-versions',
    templateUrl: 'content-versions.component.html',
})
export class DocumentPageVersionsComponent {

    @Input()
    public versions: Array<ContentVersionsInterface>;

    @Output()
    public versionChange: EventEmitter<number> = new EventEmitter();

    public handleVersion(input: ContentVersionsInterface): void {
        this.versions.forEach((version: ContentVersionsInterface) => {
            if (version.value === input.value) {
                version.state = !version.state;
                this.versionChange.emit(version.value);
            } else {
                version.state = false;
            }
        });
    }
}
