import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from 'security/services/user/user.service';
import {UserIdentityModelFactory} from 'core/factories/user-identity-model.factory';
import {UserIdentityModel} from 'core/models/user-identity.model';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs';
import {ApiUserDataInterface} from 'interfaces/api/user-data.interface';

@Component({
    selector: 'app-user-user-page',
    templateUrl: 'user-page.component.html',
})
export class UserUserPageComponent implements OnInit, OnDestroy {

    public isLoading: boolean = false;

    public userIdentity: UserIdentityModel;

    private routeSubscription: Subscription;

    constructor(
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private userIdentityModelFactory: UserIdentityModelFactory,
    ) {
    }

    public ngOnInit(): void {
        this.routeSubscription = this.activatedRoute.params.subscribe((params: Params) => {
            if (params.userId) {
                this.retrieveUser(Number(params.userId));
            }
        });
    }

    public ngOnDestroy(): void {
        if (this.routeSubscription instanceof Subscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    private retrieveUser(userId: number): void {
        this.userService.getUser(userId).subscribe((user: ApiUserDataInterface) => {
            this.userIdentity = this.userIdentityModelFactory.fromUserData(user);
        });
    }
}
