import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {AnalyticsService} from 'services/analytics/analytics.service';
import {AnalyticsDimensionEnum} from 'services/analytics/enums/dimension.enum';
import {AnalyticsEventTaskHandInModel} from 'services/analytics/models/task-hand-in.model';
import {HomeworkService} from 'services/homework/homework.service';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';

@Component({
    selector: 'app-tasks-handin-own-task',
    templateUrl: 'handin-own-task.component.html',
})
export class TasksHandinOwnTaskComponent {

    public readonly columnClasses = GridTableColClassEnum;

    @Input()
    public activeTask: ApiTaskDetailsInterface;

    @Output()
    public handedIn: EventEmitter<null> = new EventEmitter<null>();

    public attachments: AttachmentDataInterface = {
        description: '',
        files: [],
        urls: [],
    };

    public constructor(
        private toastService: ToastrService,
        private analyticsService: AnalyticsService,
        private homeworkService: HomeworkService,
    ) {}

    public handIn(): void {
        if (this.attachments.files.length === 0 && this.attachments.urls.length === 0) {
            this.toastService.warning('Voeg ten minste één bestand of één url toe.');

            return;
        }

        const params: ApiHomeworkOwnTaskHandInInterface = {
            title: this.activeTask.title,
            description: this.attachments.description,
            files: [],
            urls: this.attachments.urls,
            selfstudy_examination: false,
            book: this.activeTask.book,
        };

        for (const file of this.attachments.files) {
            params.files.push(file.id);
        }

        this.analyticsService.dimension(AnalyticsDimensionEnum.AssignmentTitle, this.activeTask.title);
        this.analyticsService.event(new AnalyticsEventTaskHandInModel());

        this.homeworkService.handInOwnTask(this.activeTask.id, params)
            .subscribe(() => {
                this.toastService.success('Taak is ingeleverd.');

                this.handedIn.emit();
            }, () => {
                this.toastService.error('Kon taak niet inleveren.');
            });
    }
}
