import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';
import {PageWrapperClassEnum} from 'shared/modules/page/enums/page-wrapper/class.enum';

@Component({
    selector: 'app-page-wrapper',
    templateUrl: 'wrapper.component.html',
})
export class PageWrapperComponent implements OnInit {

    public static CLASS = 'page-component__wrapper';

    @HostBinding('class')
    public hostClasses: string;

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public set paddingLeftRight(value: boolean | string) {
        this.classHelper.toggleClassByBoolean(PageWrapperClassEnum.PaddingLeftRight, value.toString() === 'true');
    }

    @Input()
    public set paddingTop(value: boolean | string) {
        this.classHelper.toggleClassByBoolean(PageWrapperClassEnum.PaddingTopNone, value.toString() === 'false');
    }

    private classHelper = new ClassHelper(PageWrapperComponent.CLASS);

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classModifiers);
        this.hostClasses = this.classHelper.toString();
    }
}
