import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';

export class ActionButtonDefaultModel implements ActionButtonInterface {

    public iconBox: IconBoxInterface;

    constructor(
        public id: string,
        public value: string,
        public icon: IconEnum,
        public classModifiers: Array<string> = [],
    ) {
        this.iconBox = {
            value: icon,
            type: IconBoxTypeEnum.Icon,
        };
    }
}
