export enum MessageCenterMessageTypeEnum {

    All = 1,

    Message = 2,

    Material = 3,

    Tasks = 4,

    Tests = 5,

}
