import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RadioBoxInterface} from 'shared/components/radio-box/interfaces/radio-box.interface';
import {RadioBoxEnum} from 'shared/components/radio-box/enum/radio-box.enum';
import {AddUrlPreferencesEnum} from 'shared/modals/add-url/enum/add-url-preferences.enum';

@Component({
    selector: 'app-add-url-preferences',
    templateUrl: 'add-url-preferences.component.html',
})
export class AddUrlPreferencesComponent {
    public readonly preferencesOptions: Array<RadioBoxInterface> =
        [
            {
                id: AddUrlPreferencesEnum.OwnInput,
                value: 'Eigen invoer',
            },
            {
                id: AddUrlPreferencesEnum.OneDrive,
                value: 'Vanuit OneDrive',
            }
        ];

    public classModifier = RadioBoxEnum;

    @Input()
    public addUrlPreferences: AddUrlPreferencesEnum;

    @Output()
    public addUrlPreferencesChange: EventEmitter<AddUrlPreferencesEnum> = new EventEmitter();

    @Input()
    public disabled: boolean = false;

    public addUrlPreferencesUpdated(): void {
        this.addUrlPreferencesChange.emit(this.addUrlPreferences);
    }
}
