import {ContentSelectModel} from 'shared/modules/content-select/models/content-select.model';
import {ContentSwitchStudentModel} from 'pages/modules/tasks/components/modals/add-modal/models/student-model';
import {GroupsService} from 'services/groups/groups.service';

export class ContentSwitchGroupModel extends ContentSelectModel {

    constructor(id: string, title: string) {
        super(id, title, false, []);
    }

    public static fromApiGroup(apiGroup: ApiGroupInterface): ContentSwitchGroupModel {
        const group = new ContentSwitchGroupModel(apiGroup.id.toString(), GroupsService.buildGroupName(apiGroup.name, apiGroup.year));

        for (const apiStudent of apiGroup.students) {
            group.addStudent(ContentSwitchStudentModel.fromApiStudent(apiStudent));
        }

        return group;
    }

    public addStudent(student: ContentSwitchStudentModel): void {
        student.parentId = this.id; // also set parentId
        this.children.push(student);
    }
}
