import {NgModule} from '@angular/core';
import {SharedModule} from 'shared/shared.module';
import {CommonModule} from '@angular/common';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {BookshelfComponent} from 'pages/modules/bookshelf/components/bookshelf.component';
import {BookshelfItemComponent} from 'pages/modules/bookshelf/components/item/item.component';
import {BookshelfItemModelFactory} from 'pages/modules/bookshelf/factories/item-model.factory';
import {RouterModule} from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        InfiniteScrollModule,
        RouterModule,
    ],
    declarations: [
        BookshelfComponent,
        BookshelfItemComponent,
    ],
    exports: [
        BookshelfComponent,
    ],
    providers: [
        BookshelfItemModelFactory,
    ]
})
export class BookshelfModule {
}
