import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxClose} from 'models/iconbox/close.model';
import {Location} from '@angular/common';

@Component({
    selector: 'app-content-exam-modal-start',
    templateUrl: 'modal-start.component.html'
})
export class ContentExamModalStartComponent {

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorBlue]);

    constructor(private activeModal: NgbActiveModal, private location: Location) {
    }

    public close(): void {
        this.activeModal.close();
        this.location.back();
    }

    public start(): void {
        this.activeModal.close();
    }
}
