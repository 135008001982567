import {Injectable, OnDestroy} from '@angular/core';
import {UserService} from 'security/services/user/user.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from 'environments/environment';
import {Router} from '@angular/router';
import {ModalService} from 'core/services/modal/modal.service';
import {RoleEnum} from 'enums/role.enum';
import {LocalStorageService} from 'angular-2-local-storage';
import {LocalStorageKeysEnum} from 'enums/local-storage-keys.enum';
import * as Sentry from '@sentry/browser';
import {MsAlLoginService} from 'onedrive/services/msallogin.service';
import Timer = NodeJS.Timer;

@Injectable()
export class AuthenticationService implements OnDestroy {

    public authenticated: boolean = false;

    private isAuthenticating: boolean = false;

    private isAuthenticatedSubject: Subject<boolean> = new Subject<boolean>();

    private timeOuts: Array<Timer> = [];

    constructor(
        private userService: UserService,
        private router: Router,
        private modalService: ModalService,
        private localStorageService: LocalStorageService,
        private msAlLoginService: MsAlLoginService
    ) {
    }

    public get isAuthenticated(): Observable<boolean> {
        return this.isAuthenticatedSubject.asObservable();
    }

    public logout(): void {
        location.href = this.getLogoutUrl();
    }

    public getLogoutUrl(): string {
        const user = this.userService.getUserData();

        let url = `${environment.logoutUrl}`;

        if (user && user.publisher) {
            url += `?publisher=${user.publisher.slug}`;
        }

        return url;
    }

    public authenticate(publisher?: string): void {
        if (this.isAuthenticating || this.authenticated) {
            return;
        }

        // BD-1258
        if (publisher === 'eduactief') {
            publisher = 'bbo';
        }

        this.isAuthenticating = true;
        this.userService.retrieve(publisher)
            .subscribe(() => {
                this.checkLocalStorage();
            }, () => {
                this.isAuthenticatedSubject.error('Not authenticated');
                this.isAuthenticatedSubject.complete();
            });
    }

    private checkLocalStorage(): void {
        const existingUser = this.localStorageService.get<number>(LocalStorageKeysEnum.UserData);

        if (existingUser !== this.userService.getCurrentUserId()) {
            this.localStorageService.clearAll();
        }

        this.msalLogin(true);

        this.localStorageService.set(LocalStorageKeysEnum.UserData, this.userService.getCurrentUserId());

        this.acceptTerms();
    }

    public msalLogin(interactive: boolean): void {
        const userData = this.userService.getUserData();

        if (userData.use_one_drive) {
            this.msAlLoginService.authenticate(interactive, false);
        }

        if (this.userService.getUserData() && this.msAlLoginService.getLoggedInUser() === this.userService.getUserData().one_drive_username) {
            this.timeOuts.push(
                setTimeout(() => {
                    this.msalLogin(false);
                }, 1800000)
            );
        }
    }

    private acceptTerms(): void {
        const user = this.userService.getUserData();

        if (user.terms_condition_accepted > 0) {
            this.selectOrganization();

            return;
        }

        this.modalService.acceptTermsAndConditions().subscribe(() => {
            this.selectOrganization();
        }, () => {
            this.logout();
        });
    }

    private selectOrganization(): void {
        const user = this.userService.getUserData();

        if (user.role !== RoleEnum.RoleTeacher) {
            this.authenticateFinish();

            return;
        }

        if (user.school_years.length === 0) {
            this.authenticateFinish();

            return;
        }

        if (user.school_years[user.school_years.length - 1].organization) {
            this.authenticateFinish();

            return;
        }

        this.modalService.organization().subscribe(() => {
            this.authenticateFinish();
        }, () => {
            this.logout();
        });
    }

    private authenticateFinish(): void {
        this.authenticated = true;

        const user = this.userService.getUserData();

        if (user.firsttime && environment.features.helpPageEnabled) {
            this.modalService.openWelcomeHelpPage(user);
        }

        this.isAuthenticatedSubject.next(this.authenticated);
        this.isAuthenticatedSubject.complete();

        this.isAuthenticatedSubject = new BehaviorSubject(this.authenticated);

        Sentry.addBreadcrumb({
            category: 'auth',
            message: 'Authenticated user ' + this.userService.getCurrentUserId(),
            level: Sentry.Severity.Info
        });

        this.isAuthenticating = false;
    }

    public ngOnDestroy(): void {
        this.timeOuts.forEach(timer => clearTimeout(timer));
    }
}
