export enum TaskStatusFilterEnum {

    All = 'all',

    Specified = 'specified',

    HandedIn = 'handed_in',

    Done = 'done',

}
