import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {ClassHelper} from 'helpers/dom/class.helper';

@Component({
    selector: 'app-horizontal-bar-component',
    templateUrl: 'horizontal-bar.component.html',
})
export class HorizontalBarComponent implements OnInit {

    @HostBinding('class')
    public elementClass: string;

    @Input()
    public classModifiers: Array<string> = [];

    private classHelper: ClassHelper = new ClassHelper();

    public ngOnInit(): void {
        this.classHelper.addClasses([HorizontalBarClassesEnum.Base, ...this.classModifiers]);
        this.elementClass = this.classHelper.toString();
    }
}
