import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    OnDestroy,
    QueryList,
    ViewChildren
} from '@angular/core';
import {DocumentMatchingOptionsInterface} from 'document/modules/components/matching/interface/options.interface';
import {DocumentValueInterface} from 'document/modules/components/matching/interface/value.interface';
import {DocumentMatchingClassEnum} from 'document/modules/components/matching/enums/class.enum';
import {DocumentAnswerableComponent} from 'document/modules/abstract/answerable.component';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {DocumentMatchingChoiceInterface} from 'document/modules/components/matching/interface/choice.interface';
import {ClassHelper} from 'helpers/dom/class.helper';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AutoCheckService } from 'document/services/autocheck/auto-check.service';
import {RoleEnum} from 'enums/role.enum';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';
import {DocumentService} from 'document/services/document/document.service';

// KOPPELVRAGEN (PAREN)

@Component({
    selector: '.document-matching',
    templateUrl: 'matching.component.html',
})
export class DocumentMatchingComponent extends DocumentAnswerableComponent implements AfterViewInit, AfterViewChecked, OnDestroy {

    @HostBinding('class')
    public elementClass: string = 'document__matching';

    @ViewChildren('child')
    public childElements: QueryList<ElementRef>;

    @ViewChildren('childInitial')
    public childInitialElements: QueryList<ElementRef>;

    @ViewChildren('childCorrect')
    public childCorrectElements: QueryList<ElementRef>;

    public readonly roleEnum = RoleEnum;

    public readonly examinationNeededEnum = ExamineOptionsEnum;

    public examinationByStudent: boolean = false;

    public classes: ClassHelper = new ClassHelper(DocumentMatchingClassEnum.Matching);

    public id: string;

    public options: DocumentMatchingOptionsInterface;

    public hasTitles: boolean = false;

    public childHeight: string = 'auto';

    public childInitialHeight: string = 'auto';

    public childCorrectHeight: string = 'auto';

    public isMatchImageMatching: boolean = false;

    public isChoiceImageMatching: boolean = false;

    public correctAnswers: Array<DocumentMatchingChoiceInterface> = [];

    public initialAnswers: Array<DocumentMatchingChoiceInterface>;

    protected matches: Array<DocumentValueInterface>;

    protected dataSet: DocumentDatasetOptionsInterface;

    private currentViewHeight: number = 0;

    public answerHidden: boolean;

    public autoScoreEnabled: boolean;

    public autoScoreAnswer: Array<string>;

    private subscriptions: Array<Subscription> = [];

    constructor(
        protected elementRef: ElementRef,
        protected answersService: DocumentAnswersService,
        protected showAnswersService: DocumentAnswersStateService,
        private autocheckService: AutoCheckService,
        private documentService: DocumentService,

    ) {
        super(elementRef, answersService, showAnswersService);

        this.id = `matching-${this.dataSet.assignmentid}-${this.dataSet.fieldid}`;

        try {
            this.options = JSON.parse(this.dataSet.options);
            this.setHasTitles();
            this.constructMatches();
        } catch (e) {
            this.initializationFailed = true;

            console.error('Failed to JSON parse the options:', e);
        }
    }

    public ngAfterViewInit(): void {
        this.calculateChildHeight();
        super.ngAfterViewInit();
        this.setupAutoScoring();
        this.handleExaminationNeeded();
    }

    public ngAfterViewChecked(): void {
        const element: HTMLElement = this.elementRef.nativeElement;
        const height = element.offsetHeight;

        if (this.childHeight === 'auto' || this.currentViewHeight === height) {
            return;
        }

        this.currentViewHeight = height;
        this.calculateChildHeight();
    }

    @HostListener('window:resize')
    private onWindowResize(): void {
        this.calculateChildHeight();
    }

    private calculateChildHeight(): void {
        // First reset child height, so we can re-calculate the heights
        this.childHeight = 'auto';
        this.childInitialHeight = 'auto';
        this.childCorrectHeight = 'auto';

        // Regular children
        setTimeout(() => {
            this.childHeight = `${this.calculateChildHeightForElements(this.childElements)}px`;
            this.childInitialHeight = `${this.calculateChildHeightForElements(this.childInitialElements)}px`;
            this.childCorrectHeight = `${this.calculateChildHeightForElements(this.childCorrectElements)}px`;
        }, 1);
    }

    private handleExaminationNeeded(): void {
        const task = this.documentService.getActiveTask();

        // examination by Student
        if (task && task.examination_needed === this.examinationNeededEnum.Student) {
            this.examinationByStudent = this.documentService.subscribeSelfStudyCompatible();
        }
    }


    private setupAutoScoring(): void {

        const assignmentId = this.dataSet.assignmentid;
        const fieldId = parseInt(this.dataSet.fieldid, 10);
        const assignment = this.answersService.getAssignment(assignmentId);
        this.autoScoreEnabled = assignment && assignment.auto_check;

        if (! this.autoScoreEnabled) { return; }

        this.subscriptions.push( this.autocheckService.getAutoCheckAnswerForFieldId(assignmentId, fieldId).pipe(take(1)).subscribe( autoCheckAnswer => {

            if (autoCheckAnswer) {
                this.autoScoreAnswer = autoCheckAnswer.split(',');
            }
        }));
    }

    private calculateChildHeightForElements(elements: QueryList<ElementRef> = null): number {
        let childMaxHeight = 0;

        if (elements) {
            for (const child of elements.toArray()) {
                if (child.nativeElement.offsetHeight > childMaxHeight) {
                    childMaxHeight = child.nativeElement.offsetHeight;
                }
            }
        }

        return childMaxHeight;
    }

    private constructMatches(): void {
        this.matches = [];

        for (let i = 0; i < this.options.matches.length; i++) {
            if (this.options.matches[i].image) {
                this.isMatchImageMatching = true;
            }

            if (this.options.choices[i].image) {
                this.isChoiceImageMatching = true;
            }

            this.matches.push(<DocumentValueInterface>{
                row: i,
                match: this.options.matches[i],
                choice: this.options.choices[i]
            });
        }

        if (this.isMatchImageMatching && this.isChoiceImageMatching) {
            this.classes.addClass(DocumentMatchingClassEnum.MatchingImage);
        } else {
            this.classes.addClass(DocumentMatchingClassEnum.MatchingText);
        }
    }

    private setHasTitles(): void {
        this.hasTitles =
            (this.options.choiceslabel && this.options.choiceslabel.length > 0)
            || (this.options.matcheslabel && this.options.matcheslabel.length > 0);

        if (this.hasTitles) {
            this.classes.addClass(DocumentMatchingClassEnum.MatchingHasTitles);
        }
    }

    public imageLoaded(): void {
        this.calculateChildHeight();
    }

    public handleDrop(event: CdkDragDrop<Array<string>>): void {
        if (this.answersDisabled) {
            return;
        }

        moveItemInArray(this.options.choices, event.previousIndex, event.currentIndex);

        this.saveAnswer(this.options.choices.map(choice => choice.value).join(','));
    }

    protected setAnswer(answer: string): void {

        if (answer === undefined) {
            return;
        }

        if (!answer) {
            answer = '';
        }

        const splittedAnswer = answer.split(',');

        const choicesBackup = this.options.choices;
        this.options.choices = [];

        for (const answerValue of splittedAnswer) {
            for (const choice of choicesBackup) {
                if (choice.value === answerValue) {
                    this.options.choices.push(choice);
                    break;
                }
            }
        }
    }

    protected hideCorrectAnswer(): void {
        this.classes.removeClass(DocumentMatchingClassEnum.MatchingShowCorrectAnswer);

        this.calculateChildHeight();
        this.answerHidden = true;
    }

    protected showCorrectAnswer(correctAnswer: string): void {
        this.correctAnswers = this.setupAnswers(correctAnswer);

        this.classes.addClass(DocumentMatchingClassEnum.MatchingShowCorrectAnswer);

        this.calculateChildHeight();

        this.answerHidden = false;
    }

    protected showInitialAnswer(initialAnswer: string): void {

        this.initialAnswers = this.setupAnswers(initialAnswer);

        this.classes.addClass(DocumentMatchingClassEnum.MatchingShowInitialAnswer);
        this.classes.removeClass(DocumentMatchingClassEnum.MatchingShowCorrectAnswer);

        this.calculateChildHeight();
    }

    protected answerValid(): boolean {
        return true;
    }

    protected hideInitialAnswer(): void {
        this.classes.removeClass(DocumentMatchingClassEnum.MatchingShowInitialAnswer);
        this.classes.addClass(DocumentMatchingClassEnum.MatchingShowInitialAnswer);

        this.calculateChildHeight();
    }

    protected disableAnswers(): void {
        this.classes.addClass(DocumentMatchingClassEnum.MatchingDisabled);
    }

    protected enableAnswers(): void {
        this.classes.removeClass(DocumentMatchingClassEnum.MatchingDisabled);
    }

    private setupAnswers(answer: string): Array<DocumentMatchingChoiceInterface> {
        const answers = [];

        if (!answer) {
            return answers;
        }

        const splittedAnswer = answer.split(',');

        for (const answerValue of splittedAnswer) {
            for (const choice of this.options.choices) {
                if (choice.value === answerValue) {
                    answers.push(choice);
                    break;
                }
            }
        }

        return answers;
    }

    public ngOnDestroy(): void {
        this.autocheckService.resetAutoCheckAnswer();
        this.subscriptions.map(sub => sub.unsubscribe());
    }
}
