export enum LinkTargetEnum {

    Blank = '_blank',

    Self = '_self',

    Parent = '_parent',

    Top = '_top',

}
