import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {Observable} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {AnalyticsService} from 'services/analytics/analytics.service';
import {AnalyticsEventDeeplinkBookIdModel} from 'services/analytics/models/deeplink-book-id.model';
import {AnalyticsEventDeeplinkEanModel} from 'services/analytics/models/deeplink-ean.model';
import {AnalyticsDimensionEnum} from 'services/analytics/enums/dimension.enum';
import {AnalyticsEventDeeplinkDpsidModel} from 'services/analytics/models/deeplink-dpsid.model';

@Injectable({
    providedIn: 'root'
})
export class DeeplinkService {

    constructor(
        private apiRequestService: ApiService,
        private analyticsService: AnalyticsService,
    ) {
    }

    public ean(ean: string): Observable<ApiBookInterface> {
        this.analyticsService.dimension(AnalyticsDimensionEnum.DeeplinkEan, ean);
        this.analyticsService.event(new AnalyticsEventDeeplinkEanModel());

        return this.apiRequestService.get(ApiEndpointEnum.DeeplinkEan, null, new Map([
            ['ean', ean],
        ]));
    }

    public dpsid(dpsid: string): Observable<ApiBookInterface> {
        this.analyticsService.dimension(AnalyticsDimensionEnum.DeeplinkDpsid, dpsid);
        this.analyticsService.event(new AnalyticsEventDeeplinkDpsidModel());

        return this.apiRequestService.get(ApiEndpointEnum.DeeplinkDpsid, null, new Map([
            ['dpsid', encodeURIComponent(dpsid)],
        ]));
    }

    public bookId(bookId: number): Observable<ApiBookInterface> {
        this.analyticsService.dimension(AnalyticsDimensionEnum.DeeplinkBookId, String(bookId));
        this.analyticsService.event(new AnalyticsEventDeeplinkBookIdModel());

        return this.apiRequestService.get(ApiEndpointEnum.DeeplinkBookId, null, new Map([
            ['bookId', String(bookId)],
        ]));
    }
}
