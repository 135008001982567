export enum ExternPageActionStyleEnum {

    DefaultMarginLeft = -22.3,

    DefaultExternPageActionWidth = 21,

    StepsWidth = 0.0416, // 1250(=12.5rem) - 1430(=20rem) = 180 : 7.5/180

    StepsMarginLeft = 0.0416, // 1250(=13.5rem) - 1430(=21.5rem) = 180 : 8/180

    StartWidth = 12.5, // rem

    StartMarginLeft = 13.5, // rem

}
