import {NavbarItemsEnum} from 'core/components/navbar/enums/navbar-items.enum';
import {ColorEnum} from 'enums/color.enum';
import {IconEnum} from 'enums/icon.enum';
import {RoleEnum} from 'enums/role.enum';
import {NavbarButton} from 'core/components/navbar/classes/navbar.button';
import {ButtonRolesInterface} from 'shared/components/button/interfaces/button-roles.interface';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {HelpPageEnum} from 'services/help-page/enums/help-page.enum';

export class NavbarMaterialButtonModel extends NavbarButton implements ButtonRolesInterface {

    public static readonly id: string = 'materials';

    constructor(public title: string = 'lesmateriaal') {
        super(
            NavbarItemsEnum.Material,
            RoutesEnum.Books,
            title,
            ColorEnum.Blue,
            [
                RoleEnum.RoleNavbarMaterial,
            ],
            IconEnum.Book
        );
    }
}
