import {Component, Input} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {StudyMaterialTypeEnum} from 'enums/study-material-type.enum';
import {HorizontalBarIconClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-icon-classes.enum';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';

@Component({
    selector: 'app-exams-exam-information',
    templateUrl: 'exam-information.component.html',
})
export class ExamsExamInformationComponent {

    public readonly rowClasses = GridTableRowClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly horizontalBarIconClasses = HorizontalBarIconClassesEnum;

    public readonly iconEnum = IconEnum;

    public readonly studyMaterialTypeEnum = StudyMaterialTypeEnum;

    @Input()
    public examPlan: ApiExamPlanDetailsInterface;
}
