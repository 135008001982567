import {Component, OnInit} from '@angular/core';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from 'security/services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {UserIdentityModelFactory} from 'core/factories/user-identity-model.factory';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {IconBoxSchoolModel} from 'shared/models/icon-box/school.model';
import {ContentSelectModelFactory} from 'shared/modules/content-select/factories/model.factory';
import {ContentSelectModel} from 'shared/modules/content-select/models/content-select.model';
import {ContentSelectItemInterface} from 'shared/modules/content-select/interfaces/select-item.interface';
import {ContentSelectOrElseModel} from 'shared/modules/content-select/models/or-else.model';

@Component({
    selector: 'app-organisation-modal',
    templateUrl: 'organization-modal.component.html',
})
export class OrganizationModalComponent implements OnInit {

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly organizationClassModifiers: Array<ContentSelectClassModifiersEnum> = [
        ContentSelectClassModifiersEnum.Organization,
        ContentSelectClassModifiersEnum.Grey,
    ];

    public readonly contentSwitchIconBox = new IconBoxSchoolModel();

    public contentSwitchData: Array<ContentSelectItemInterface> = [];

    public isLoading: boolean = false;

    public showOrganizationNameInput: boolean = false;

    public organizationName: string = '';

    private organizations: Array<ApiOrganisationInterface>;

    private selectedOrganization: ContentSelectItemInterface;

    constructor(
        private activeModal: NgbActiveModal,
        private userService: UserService,
        private toastService: ToastrService,
        private userIdentityModelFactory: UserIdentityModelFactory,
        private contentSwitchModelFactory: ContentSelectModelFactory,
    ) {
    }

    public ngOnInit(): void {
        this.isLoading = true;

        this.loadOrganizations();
    }

    public onContentItemClick(items: Array<ContentSelectItemInterface>): void {
        this.selectedOrganization = items[0];

        if (!this.selectedOrganization) {
            this.showOrganizationNameInput = false;

            return;
        }

        this.showOrganizationNameInput = Number(this.selectedOrganization.id) < 0;
    }

    public ignore(): void {
        this.activeModal.dismiss();
    }

    public handleSave(): void {
        if (this.showOrganizationNameInput === true && this.organizationName.length === 0) {
            this.toastService.warning('Geeft u alstublieft de naam van uw organisatie op of kies een bestaande.');

            return;
        }

        if (!this.selectedOrganization) {
            this.toastService.warning('Selecteer een organisatie uit de lijst.');

            return;
        }

        const params: any = {};

        if (this.showOrganizationNameInput === true) {
            params.custom = this.organizationName;
        } else {
            params.organization_id = Number(this.selectedOrganization.id);
        }

        this.isLoading = true;

        this.userService.updateOrganization(params)
            .subscribe(() => {
                this.activeModal.close(true);
                this.toastService.success('Organisatie opgeslagen.');
            }, () => {
                this.toastService.error('Kon de organisatie niet opslaan. Probeer het later opnieuw.');

                this.isLoading = false;
            });
    }

    private loadOrganizations(): void {
        this.userService.getOrganizations()
        .subscribe((organizations: Array<ApiOrganisationInterface>) => {
            this.organizations = organizations;
            this.setContentSwitchData();

            this.isLoading = false;
        }, () => {
            this.toastService.error('Kon de lijst met organisaties niet ophalen. Probeer het later opnieuw.');

            this.isLoading = false;
        });
    }

    private setContentSwitchData(): void {
        const contentSwitchData = this.contentSwitchModelFactory.fromApiOrganizationsArray(this.organizations);

        contentSwitchData.push(new ContentSelectOrElseModel());

        this.contentSwitchData = contentSwitchData;
    }
}
