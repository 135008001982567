import {Component, HostBinding} from '@angular/core';
import {PageWrapperClassEnum} from 'shared/modules/page/enums/page-wrapper/class.enum';
import {PageColClassEnum} from 'shared/modules/page/enums/page-col/class.enum';

@Component({
    selector: 'app-not-found',
    templateUrl: 'not-found.component.html',
})
export class NotFoundComponent {

    public readonly pageWrapperClassEnum = PageWrapperClassEnum;

    public readonly pageColClassEnum = PageColClassEnum;

    @HostBinding('class')
    protected elementClass: string = 'container-fluid container-fluid--width';

}
