import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';
import {IconBoxInterface} from 'app/modules/shared/modules/icon-box/interfaces/icon-box.interface';
import {IconBoxClassEnum} from 'app/modules/shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxTypeEnum} from 'app/modules/shared/modules/icon-box/enums/icon-box.type-enum';

@Component({
    selector: 'app-icon-box',
    templateUrl: 'icon-box.component.html',
})
export class IconBoxComponent implements OnInit {

    @Input()
    public iconBox: IconBoxInterface;

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public tooltip: string;

    @Input()
    public isClickable: boolean = true;

    @Output()
    public iconBoxClick: EventEmitter<IconBoxInterface> = new EventEmitter();

    public classHelper: ClassHelper = new ClassHelper();

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classModifiers);
        this.classHelper.addClasses(this.iconBox.classModifiers || []);

        if (this.requirePadding()) {
            this.classHelper.addClass(IconBoxClassEnum.Padding);
        }

        if (this.iconBox.type === IconBoxTypeEnum.Icon) {
            this.classHelper.addClass(IconBoxClassEnum.Icon);
        }

        if (this.isClickable && this.iconBoxClick.observers.length > 0) {
            this.classHelper.addClass(IconBoxClassEnum.Clickable);
        }
    }

    public isImageType(): boolean {
        return this.iconBox.type === IconBoxTypeEnum.Image;
    }

    public isIconType(): boolean {
        return this.iconBox.type === IconBoxTypeEnum.Icon;
    }

    public handleClick(event: MouseEvent): void {
        if (this.iconBoxClick.observers.length === 0) {
            return;
        }

        event.stopPropagation();

        this.iconBoxClick.emit(this.iconBox);
    }

    private requirePadding(): boolean {
        return this.iconBox.notificationCount && this.iconBox.notificationCount >= 10;
    }

}
