import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';
import {HorizontalBarTextClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-text-classes.enum';

@Component({
    selector: 'app-horizontal-bar-text-component',
    templateUrl: 'text.component.html',
})
export class HorizontalBarTextComponent implements OnInit {

    @HostBinding('class')
    public elementClass: string;

    @Input()
    public classModifiers: Array<string> = [];

    private classHelper: ClassHelper = new ClassHelper();

    public ngOnInit(): void {
        this.classHelper.addClasses([HorizontalBarTextClassesEnum.Base, ...this.classModifiers]);
        this.elementClass = this.classHelper.toString();
    }
}
