import {Injectable} from '@angular/core';
import {SystemMessageInterface} from 'services/system/interfaces/message.interface';
import {SystemTypeEnum} from 'services/system/enums/type.enum';
import {UserService} from 'security/services/user/user.service';
import {MercureMessageType, MercureService} from 'services/mercure/mercure.service';

@Injectable({
    providedIn: 'root'
})
export class SystemService {

    constructor(
        private mercureService: MercureService,
        private userService: UserService,
    ) {
    }

    public initialize(): void {
        this.mercureService.subscribe<SystemMessageInterface>(MercureMessageType.System, event => this.handleSystemMessages(event));
    }

    private handleSystemMessages(payload: SystemMessageInterface): void {
        if (!payload.status) {
            return;
        }

        switch (payload.status) {
            case SystemTypeEnum.MaintenanceOn:
                this.handleMaintenanceOn();
                break;

            case SystemTypeEnum.MaintenanceOff:
                this.handleMaintenanceOff();
                break;

            case SystemTypeEnum.PageRefresh:
                this.handlePageRefresh();
                break;

            case SystemTypeEnum.ReloadBooks:
                this.handleReloadBooks();
                break;

            case SystemTypeEnum.ReloadGroups:
                this.handleReloadGroups();
                break;

            case SystemTypeEnum.ReloadLogin:
                this.handleReloadLogin();
                break;
        }
    }

    private handleMaintenanceOn(): void {
        // TODO
    }

    private handleMaintenanceOff(): void {
        // TODO
    }

    private handlePageRefresh(): void {
        location.reload();
    }

    private handleReloadBooks(): void {
        // TODO
    }

    private handleReloadGroups(): void {
        // TODO
    }

    private handleReloadLogin(): void {
        this.userService.reload();
    }
}
