import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import {Subscription} from 'rxjs';
import {ClassHelper} from 'helpers/dom/class.helper';
import {DropdownClassEnum} from 'app/modules/shared/modules/dropdown/enums/dropdown-class.enum';

@Component({
    selector: 'app-dropdown-component',
    templateUrl: 'dropdown.component.html',
})
export class DropdownComponent implements OnInit, OnDestroy {

    public static readonly CLASS: string = 'dropdown-component';

    public static readonly EVENT_FIELD: string = '_dropdownComponentDoNotHandle';

    @HostBinding('class')
    protected elementClass: string;

    @Output()
    public toggleEvent: EventEmitter<boolean> = new EventEmitter();

    @Input()
    public id: string;

    @Input()
    public clickOutSide: boolean = false;

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public disabled: boolean = false;

    @Input()
    public caret: boolean = false;

    public classHelper: ClassHelper = new ClassHelper(DropdownComponent.CLASS);

    private bodyClickSubscription: Subscription;

    constructor(private elementRef: ElementRef) {
    }

    public ngOnDestroy(): void {
        if (this.bodyClickSubscription instanceof Subscription) {
            this.bodyClickSubscription.unsubscribe();
        }
    }

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classModifiers);

        if (this.caret) {
            this.classHelper.addClass(DropdownClassEnum.Caret);
        }

        this.elementClass = this.classHelper.toString();
    }

    public isVisible(): boolean {
        return this.classHelper.hasClass(DropdownClassEnum.Visible);
    }

    public toggle(toggleOverride?: boolean): void {
        if (this.disabled) {
            return;
        }

        const toggleValue = toggleOverride !== undefined ? toggleOverride : !this.isVisible();

        this.classHelper.toggleClassByBoolean(DropdownClassEnum.Visible, toggleValue);
        this.elementClass = this.classHelper.toString();
        this.toggleEvent.emit(toggleValue);
    }

    @HostListener('document:click', ['$event'])
    protected handleBodyClick(event: Event): void {

        if (this.clickOutSide && this.elementRef.nativeElement.classList.value.indexOf(DropdownClassEnum.Visible) >= 0) {
            this.toggle(false);
        }

        if (!this.isVisible() || event[DropdownComponent.EVENT_FIELD] === this.id) {
            return;
        }

        if (event['path'] && event['path'].find(e => e === this.elementRef.nativeElement) !== undefined) {
            return;
        }

        if (event.composedPath() && event.composedPath().find(e => e === this.elementRef.nativeElement) !== undefined) {
            return;
        }

        this.toggle(false);
    }
}
