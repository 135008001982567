import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {StringService} from 'services/string/string.service';
import {PublisherService} from 'services/publisher/publisher.service';
import {PublisherDataInterface} from 'services/publisher/interfaces/data.interface';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {environment} from 'environments/environment';

@Injectable()
export class RouteService {

    constructor(
        private router: Router,
        private publisherService: PublisherService,
        private stringService: StringService,
    ) {
    }

    public isActive(route: RoutesEnum, params: Map<string, string>): boolean {
        const routeWithParams: string = this.getRouteWithPublisher(route, params);

        return this.router.isActive(routeWithParams, true);
    }

    public getRouteWithPublisher(
        route: RoutesEnum|string,
        params: Map<string, string> = new Map(),
    ): string {
        const paramsMap: Map<string, string> = params instanceof Map ? params : new Map();
        const publisher: PublisherDataInterface = this.publisherService.getActivePublisher();

        if (publisher === undefined) {
            throw new Error('Unable to generate route, no publisher set');
        }

        paramsMap.set('publisher', publisher.slug); // Add publisher to params

        const routeWithPublisher: string = this.stringService.getMappedString(`:publisher/${route}`, paramsMap);

        return `/${routeWithPublisher}`;
    }

    public getApiRoute(
        route: ApiEndpointEnum,
        params: Map<string, string> = new Map(),
    ): string {
        const paramsMap: Map<string, string> = params instanceof Map ? params : new Map();

        return `${environment.apiUrl}${this.stringService.getMappedString(route, paramsMap)}`;
    }
}
