import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TasksAddModalComponent} from 'pages/modules/tasks/components/modals/add-modal/add-modal.component';
import {TasksDeleteModalComponent} from 'pages/modules/tasks/components/modals/delete-modal/delete-modal.component';
import {LegendModalComponent} from 'core/components/footer/modals/legend-modal/legend-modal.component';
import {ModalClassModifiersEnum} from 'core/components/modals/enums/class-modifiers.enum';
import {InviteExternalReviewerModalComponent} from 'shared/modals/invite-external-reviewer/invite-external-reviewer-modal.component';
import {DrillsterModalComponent} from 'shared/components/drillster/drillster-modal/drillster-modal.component';
import {ManageMaterialsModalComponent} from 'pages/modules/bookshelf/components/modals/manage-materials/manage-materials-modal.component';
import {ActivateLicenseModalComponent} from 'pages/modules/bookshelf/components/modals/activate-license/activate-license-modal.component';
import {DocumentPageVersionsModalComponent} from 'pages/modules/document/components/content-versions/content-versions-modal/content-versions-modal.component';
import {
    DocumentPageAddPracticeSupervisorModalComponent
} from 'pages/modules/document/components/practice-supervisor/modals/add-practice-supervisor/add-practice-supervisor-modal.component';
import {DocumentPageReviewDoneModalComponent} from 'pages/modules/document/components/send-review/modals/review-done/review-done-modal.component';
import {ExamsDeleteModalComponent} from 'pages/modules/exams/components/modals/delete-modal/delete-modal.component';
import {GroupsJoinGroupModalComponent} from 'pages/modules/groups/components/modals/join-group/join-group.component';
import {GroupsAddGroupModalComponent} from 'pages/modules/groups/components/modals/add-group/add-group.component';
import {GroupsModuleGroupInterface} from 'pages/modules/groups/interfaces/group.interface';
import {GroupsEditGroupModalComponent} from 'pages/modules/groups/components/modals/edit-group/edit-group.component';
import {GroupsActionsModalComponent} from 'pages/modules/groups/components/modals/actions/action.component';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {TermsConditionsModalComponent} from 'shared/modals/terms-conditions/terms-conditions.component';
import {ExamsAddModalComponent} from 'pages/modules/exams/components/modals/add-modal/add-modal.component';
import {SimpleModalComponent} from 'core/components/modals/components/simple-modal/simple.component';
import {Observable, Subject} from 'rxjs';
import {MessageCenterMessageDetailsModalComponent} from 'pages/modules/message-center/modals/message-details/message-details.component';
import {MessageCenterMessageInterface} from 'pages/modules/message-center/interfaces/message.interface';
import {OrganizationModalComponent} from 'shared/modals/organizaton/organization-modal.component';
import {SummaryModalComponent} from 'shared/modals/summary/summary-modal.component';
import {NoteModalComponent} from 'shared/modals/note/note-modal.component';
import {IFrameModalComponent} from 'shared/modals/i-frame/i-frame-modal.component';
import {SafeResourceUrl} from '@angular/platform-browser';
import {SourceModalComponent} from 'shared/modals/source/source-modal.component';
import {IconEnum} from 'enums/icon.enum';
import {DocumentSourceInterface} from 'document/modules/interfaces/source.interface';
import {DocumentPageReviewCompletedModalComponent} from 'pages/modules/document/components/send-review/modals/review-completed/review-completed-modal.component';
import {DocumentPageReviewRevokedModalComponent} from 'pages/modules/document/components/send-review/modals/review-revoked/review-revoked-modal.component';
import {QuestionHelpPageModalComponent} from 'shared/modals/help-page/question/question.component';
import {WelcomeHelpPageModalComponent} from 'shared/modals/help-page/welcome/welcome.component';
import {BooksHelpPageModalComponent} from 'shared/modals/help-page/books/books.component';
import {TasksHelpPageModalComponent} from 'shared/modals/help-page/tasks/tasks.component';
import {ExamsHelpPageModalComponent} from 'shared/modals/help-page/exams/exams.component';
import {ProgressHelpPageModalComponent} from 'shared/modals/help-page/progress/progress.component';
import {GroupsHelpPageModalComponent} from 'shared/modals/help-page/groups/groups.component';
import {UserService} from 'security/services/user/user.service';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {DemoRequestModalComponent} from 'shared/components/demo-bar/modal/demo-request/demo-request-modal.component';
import {AddAttachmentsModalComponent} from 'shared/modals/add-attachments/add-attachments.component';
import {AddUrlModalComponent} from 'shared/modals/add-url/add-url.component';
import {ContentExamModalStartComponent} from 'pages/modules/content-exam/components/modal-start/modal-start.component';
import {ContentExamModalResultsComponent} from 'pages/modules/content-exam/components/modal-results/modal-results.component';
import {NeutralFeedbackModalComponent} from 'shared/modals/neutral-feedback-modal/neutral-feedback-modal.component';
import {TaskNotExistModalComponent} from 'pages/modules/tasks/components/modals/task-not-exist-modal/task-not-exist-modal.component';
import {ApiUserDataInterface} from 'interfaces/api/user-data.interface';

@Injectable()
export class ModalService {

    constructor(
        private modalService: NgbModal,
        private userService: UserService,
    ) {
    }

    private open(content: any, classes: Array<string> = [], options: NgbModalOptions = {}): NgbModalRef {
        options.windowClass = `${options.windowClass || ''} ${classes.join(' ')}`;

        if (options.backdrop === undefined || options.backdrop === null) {
            options.backdrop = 'static';
        }

        if (options.keyboard === undefined || options.keyboard === null) {
            options.keyboard = false;
        }

        const modalRef: NgbModalRef = this.modalService.open(content, options);

        if (options.backdrop === false) {
            // Not optional in the modal service yet!!
            document.body.classList.remove('modal-open');
        }

        return modalRef;
    }

    //
    // Tasks
    //

    public taskAdd(bookId: number = null, chapterId: number = null, assignmentDpsid: string = null): TasksAddModalComponent {
        const comp = this.open(TasksAddModalComponent, [ModalClassModifiersEnum.TocTaskAssign]).componentInstance;

        comp.bookId = bookId;
        comp.chapterId = chapterId || null;

        if (assignmentDpsid !== null) {
            comp.preSelectedAssignments.push(assignmentDpsid);
        }

        return comp;
    }

    public taskAddOwnTask(ownTask: boolean = false): TasksAddModalComponent {
        const modalInstance = this.open(TasksAddModalComponent, [], {size: 'lg'}).componentInstance;
        modalInstance.ownTaskMode = ownTask;

        return modalInstance;
    }

    public taskEdit(task: ApiTaskDetailsInterface): TasksAddModalComponent {
        const modalInstance = this.open(TasksAddModalComponent, [], {size: 'lg'}).componentInstance;
        modalInstance.task = task;

        return modalInstance;
    }

    public taskResit(task: ApiTaskDetailsInterface): TasksAddModalComponent {
        const modalInstance = this.open(TasksAddModalComponent, [], {size: 'lg'}).componentInstance;

        modalInstance.task = task;
        modalInstance.resit = true;

        return modalInstance;
    }

    public taskRemove(task: ApiTaskDetailsInterface): TasksDeleteModalComponent {
        const currentUserId = this.userService.getCurrentUserId();
        const modalInstance = this.open(TasksDeleteModalComponent, [ModalClassModifiersEnum.TaskDelete]).componentInstance;
        modalInstance.taskId = task.id;
        modalInstance.assignedByCurrentTeacher = task.assigned_by_id === currentUserId;

        return modalInstance;
    }

    public taskNotExist(): Promise<void> {
        return this.open(TaskNotExistModalComponent, [ModalClassModifiersEnum.TaskNotExist]).result;
    }

    //
    // Legend
    //

    public legend(): LegendModalComponent {
        return this.open(LegendModalComponent, [
            ModalClassModifiersEnum.PositionBottom,
            ModalClassModifiersEnum.NoMargin,
            ModalClassModifiersEnum.Legend,
        ], {backdrop: false}).componentInstance;
    }

    //
    // BPV
    //

    public externalReviewerInvite(task: ApiTaskDetailsInterface, invite: ApiInternshipInviteInterface = null): InviteExternalReviewerModalComponent {
        const modalInstance = this.open(InviteExternalReviewerModalComponent, [ModalClassModifiersEnum.InviteExternalReviewer]).componentInstance;
        modalInstance.task = task;

        if (invite) {
            modalInstance.name = invite.name;
            modalInstance.email = invite.email;
        }

        return modalInstance;
    }

    public practiceSupervisorAdd(bookId: number, bookTitle: string): DocumentPageAddPracticeSupervisorModalComponent {
        const modalInstance = this.open(DocumentPageAddPracticeSupervisorModalComponent).componentInstance;
        modalInstance.bookId = bookId;
        modalInstance.bookTitle = bookTitle;

        return modalInstance;
    }

    public practiceSupervisorEdit(bookId: number, bookTitle: string, internship: ApiInternshipInterface): DocumentPageAddPracticeSupervisorModalComponent {
        const modalInstance = this.open(DocumentPageAddPracticeSupervisorModalComponent).componentInstance;
        modalInstance.bookId = bookId;
        modalInstance.bookTitle = bookTitle;
        modalInstance.internship = internship;

        return modalInstance;
    }

    public reviewHandedIn(): DocumentPageReviewDoneModalComponent {
        return this.open(DocumentPageReviewDoneModalComponent, [ModalClassModifiersEnum.ReviewDone], {backdrop: 'static'}).componentInstance;
    }

    public reviewCompleted(): DocumentPageReviewCompletedModalComponent {
        return this.open(DocumentPageReviewCompletedModalComponent, [ModalClassModifiersEnum.ReviewComplete], {backdrop: 'static'}).componentInstance;
    }

    public reviewRevoked(): DocumentPageReviewRevokedModalComponent {
        return this.open(DocumentPageReviewRevokedModalComponent, [ModalClassModifiersEnum.ReviewRevoked], {backdrop: 'static'}).componentInstance;
    }

    //
    // Licenses
    //

    public teachingMaterials(): ManageMaterialsModalComponent {
        return this.open(ManageMaterialsModalComponent, [
            ModalClassModifiersEnum.TeachingMaterials,
        ]).componentInstance;
    }

    public demoRequest(): DemoRequestModalComponent {
        return this.open(DemoRequestModalComponent, [
            ModalClassModifiersEnum.DemoRequest,
        ]).componentInstance;
    }

    public activateLicense(): ActivateLicenseModalComponent {
        return this.open(ActivateLicenseModalComponent, [
            ModalClassModifiersEnum.ActivateLicense,
        ]).componentInstance;
    }

    //
    // Document
    //

    public documentVersions(): DocumentPageVersionsModalComponent {
        return this.open(DocumentPageVersionsModalComponent, [
            ModalClassModifiersEnum.ContentVersions,
        ]).componentInstance;
    }

    public drillster(token: string, code: string, type: string): DrillsterModalComponent {
        const modalRef = this.open(DrillsterModalComponent, [ModalClassModifiersEnum.Drillster]).componentInstance;

        modalRef.drillster = {
            code: code,
            type: type,
            token: token,
        };

        return modalRef;
    }

    public iFrame(
        url: SafeResourceUrl,
        title: string,
        icon?: IconEnum,
        classModifiers: Array<string> = [],
    ): IFrameModalComponent {
        const modalRef: IFrameModalComponent = this.open(IFrameModalComponent, [...classModifiers, ModalClassModifiersEnum.IFrame]).componentInstance;
        modalRef.title = title;
        modalRef.icon = icon;
        modalRef.url = url;

        return modalRef;
    }

    public enlarge(title: string, element: HTMLElement): Observable<HTMLElement | string> {
        const subject: Subject<HTMLElement | string> = new Subject();
        const modalRef: NgbModalRef = this.open(SimpleModalComponent, [ModalClassModifiersEnum.Simple]);
        const modalInstance: SimpleModalComponent = modalRef.componentInstance;

        modalInstance.title = title;
        modalInstance.htmlElement = element;

        modalRef.result
            .then((result: HTMLElement | string) => subject.next(result))
            .catch((error: any) => subject.error(error))
            .finally(() => subject.complete())
        ;

        return subject.asObservable();
    }

    //
    // Exams
    //

    public examPlanAdd(bookId: number, examId: number, dpsId: string): ExamsAddModalComponent {
        const comp = this.open(ExamsAddModalComponent, [ModalClassModifiersEnum.ExamAssign]).componentInstance;

        comp.bookId = bookId;
        comp.examId = examId;
        comp.dpsId = dpsId;

        return comp;
    }

    public examPlanRemove(examPlan: ApiExamPlanDetailsInterface): ExamsDeleteModalComponent {
        const modalInstance = this.open(ExamsDeleteModalComponent, [
            ModalClassModifiersEnum.Exam,
            ModalClassModifiersEnum.WithSubTitle,
        ]).componentInstance;

        modalInstance.examPlanId = examPlan.id;

        return modalInstance;
    }

    public practiceExamStart(): Promise<void> {
        return this.open(ContentExamModalStartComponent, [ModalClassModifiersEnum.ExamAssign, ModalClassModifiersEnum.Exam]).result;
    }

    public practiceExamResults(): Promise<void> {
        return this.open(ContentExamModalResultsComponent, [ModalClassModifiersEnum.ExamAssign, ModalClassModifiersEnum.Exam]).result;
    }

    //
    // Groups
    //

    public groupJoin(): GroupsJoinGroupModalComponent {
        return this.open(GroupsJoinGroupModalComponent).componentInstance;
    }

    public groupAdd(): GroupsAddGroupModalComponent {
        return this.open(GroupsAddGroupModalComponent).componentInstance;
    }

    public groupEdit(group: GroupsModuleGroupInterface): GroupsEditGroupModalComponent {
        const modalInstance = this.open(GroupsEditGroupModalComponent).componentInstance;

        modalInstance.group = group;

        return modalInstance;
    }

    public groupActions(group: GroupsModuleGroupInterface, actions: Array<ActionButtonInterface>): GroupsActionsModalComponent {
        const modalInstance = this.open(GroupsActionsModalComponent, [
            ModalClassModifiersEnum.PositionBottom,
            ModalClassModifiersEnum.NoMargin,
        ]).componentInstance;

        modalInstance.group = group;
        modalInstance.actions = actions;

        return modalInstance;
    }

    //
    // Terms and conditions
    //

    public acceptTermsAndConditions(): Observable<boolean> {
        const obs = new Subject<boolean>();

        this.open(TermsConditionsModalComponent).result
            .then((accepted: boolean) => {
                if (accepted === true) {

                    obs.next(accepted);
                } else {
                    obs.error(false);
                }
                obs.complete();
            })
            .catch(() => {
                obs.error(false);
                obs.complete();
            });

        return obs.asObservable();
    }

    //
    // Help page
    //

    public openWelcomeHelpPage(user: ApiUserDataInterface): Observable<boolean> {
        const obs = new Subject<boolean>();

        this.open(WelcomeHelpPageModalComponent, [ModalClassModifiersEnum.WelcomeHelpPage]).result
            .finally(() => {
                this.openBooksHelpPage(user.firsttime);
                obs.complete();
            });

        return obs.asObservable();
    }

    public openQuestionHelpPage(): Observable<boolean> {
        const obs = new Subject<boolean>();

        this.open(QuestionHelpPageModalComponent, [ModalClassModifiersEnum.QuestionHelpPage]).result
            .finally(() => {
                obs.complete();
            });

        return obs.asObservable();
    }

    public openBooksHelpPage(firstTime?: boolean, setClassPosition?: string): BooksHelpPageModalComponent {
        const modalInstance = this.open(BooksHelpPageModalComponent, [ModalClassModifiersEnum.BooksHelpPage, setClassPosition]).componentInstance;
        modalInstance.firstTime = firstTime;

        if (firstTime) {
            modalInstance.skipEvent.subscribe(() => {
                this.openQuestionHelpPage();
            });
        }
        this.userService.visitTab(RoutesEnum.Books);

        return modalInstance;
    }

    public openTasksHelpPage(firstTime?: boolean, setClassPosition?: string): TasksHelpPageModalComponent {
        const modalInstance = this.open(TasksHelpPageModalComponent, [ModalClassModifiersEnum.TasksHelpPage, setClassPosition]).componentInstance;
        modalInstance.firstTime = firstTime;

        modalInstance.skipEvent.subscribe(() => {
            this.openQuestionHelpPage();
        });

        this.userService.visitTab(RoutesEnum.Tasks);

        return modalInstance;
    }

    public openExamsHelpPage(firstTime?: boolean, setClassPosition?: string): ExamsHelpPageModalComponent {
        const modalInstance = this.open(ExamsHelpPageModalComponent, [ModalClassModifiersEnum.ExamsHelpPage, setClassPosition]).componentInstance;
        modalInstance.firstTime = firstTime;

        modalInstance.skipEvent.subscribe(() => {
            this.openQuestionHelpPage();
        });

        this.userService.visitTab(RoutesEnum.Exams);

        return modalInstance;
    }

    public openProgressHelpPage(firstTime?: boolean, setClassPosition?: string): ProgressHelpPageModalComponent {
        const modalInstance = this.open(ProgressHelpPageModalComponent, [ModalClassModifiersEnum.ProgressHelpPage, setClassPosition]).componentInstance;
        modalInstance.firstTime = firstTime;

        modalInstance.skipEvent.subscribe(() => {
            this.openQuestionHelpPage();
        });

        this.userService.visitTab(RoutesEnum.Progress);

        return modalInstance;
    }

    public openGroupsHelpPage(firstTime?: boolean, setClassPosition?: string): GroupsHelpPageModalComponent {
        const modalInstance = this.open(GroupsHelpPageModalComponent, [ModalClassModifiersEnum.GroupsHelpPage, setClassPosition]).componentInstance;
        modalInstance.firstTime = firstTime;

        modalInstance.skipEvent.subscribe(() => {
            this.openQuestionHelpPage();
        });

        this.userService.visitTab(RoutesEnum.Groups);

        return modalInstance;
    }

    //
    // Message center
    //

    public messageCenterMessageDetails(
        message: MessageCenterMessageInterface,
    ): Observable<MessageCenterMessageInterface> {
        const subject = new Subject<MessageCenterMessageInterface>();
        const modalRef: NgbModalRef = this.open(MessageCenterMessageDetailsModalComponent);
        const modalInstance: MessageCenterMessageDetailsModalComponent = modalRef.componentInstance;

        modalInstance.message = message;
        modalRef.result.then((messageResult: MessageCenterMessageInterface) => {
            subject.next(messageResult);
        }).catch((error: any) => {
            subject.error(error);
        }).finally(() => subject.complete());

        return subject.asObservable();
    }

    //
    // Organization
    //

    public organization(): Observable<boolean> {
        const obs = new Subject<boolean>();

        this.open(OrganizationModalComponent).result
            .then((accepted: boolean) => {
                if (accepted === true) {
                    obs.next(accepted);
                } else {
                    obs.error(false);
                }

                obs.complete();
            })
            .catch(() => {
                obs.error(false);
                obs.complete();
            });

        return obs.asObservable();
    }

    //
    // Summary
    //

    public summaryCreate(bookId: number, bookTitle: string, documentUuid: string, text: string): void {
        const ref = this.open(SummaryModalComponent);
        const instance = <SummaryModalComponent>ref.componentInstance;

        instance.bookId = bookId;
        instance.bookTitle = bookTitle;
        instance.documentUuid = documentUuid;
        instance.contentHtml = text;
    }

    public summaryEdit(summary: ApiSummaryInterface, bookId: number, bookTitle: string, documentUuid: string, text: string): void {
        const ref = this.open(SummaryModalComponent);
        const instance = <SummaryModalComponent>ref.componentInstance;

        instance.summary = summary;
        instance.bookId = bookId;
        instance.bookTitle = bookTitle;
        instance.documentUuid = documentUuid;
        instance.contentHtml = text;
    }

    //
    // Notes
    //

    public noteCreate(studyMaterialTitle: string, documentDpsid: string, elementId: string): void {
        const ref = this.open(NoteModalComponent);
        const instance = <NoteModalComponent>ref.componentInstance;

        instance.studyMaterialTitle = studyMaterialTitle;
        instance.documentDpsid = documentDpsid;
        instance.elementId = elementId;
    }

    public noteEdit(note: ApiNoteInterface, studyMaterialTitle: string, documentDpsid: string, elementId: string, correspondingNotes: Array<ApiNoteInterface>): Observable<Array<ApiNoteInterface>> {
        const obs = new Subject<Array<ApiNoteInterface>>();

        const ref = this.open(NoteModalComponent);
        const instance = <NoteModalComponent>ref.componentInstance;

        instance.note = note;
        instance.studyMaterialTitle = studyMaterialTitle;
        instance.documentDpsid = documentDpsid;
        instance.elementId = elementId;
        instance.noteContent = note.message;
        instance.correspondingNotes = correspondingNotes;

        ref.result
            .then((notes: Array<ApiNoteInterface>) => {
                if (notes instanceof Array && notes.length > 0) {
                    obs.next(notes);
                } else {
                    obs.next([]);
                }

                obs.complete();
            })
            .catch(() => {
                obs.error(false);
                obs.complete();
            });

        return obs.asObservable();
    }

    //
    // Sources
    //

    public source(resource?: DocumentSourceInterface): Observable<ApiSourceInterface> {
        const obs = new Subject<ApiSourceInterface>();
        const modalRef: NgbModalRef = this.open(SourceModalComponent);
        const modalInstance: SourceModalComponent = <SourceModalComponent>modalRef.componentInstance;

        modalInstance.resource = resource;
        modalRef.result.then((response: ApiSourceInterface) => {
            obs.next(response);
        }).catch((reason?: any) => {
            obs.error(reason);
        }).finally(() => {
            obs.complete();
        });

        return obs.asObservable();
    }

    public addAttachment(): Observable<string> {
        const obs = new Subject<string>();

        const modalRef = this.open(AddAttachmentsModalComponent, [ModalClassModifiersEnum.AddAttachment]);
        const instance = <AddAttachmentsModalComponent>modalRef.componentInstance;

        instance.preferencesEvent.subscribe((preference: string) => {
            instance.close();
            obs.next(preference);
            obs.complete();
        });

        return obs.asObservable();
    }

    public addUrl(): Observable<string> {
        const obs = new Subject<string>();
        const modalRef = this.open(AddUrlModalComponent, [ModalClassModifiersEnum.AddUrl]);
        const instance = <AddUrlModalComponent>modalRef.componentInstance;

        instance.preferencesEvent.subscribe((preference: string) => {
            instance.close();
            obs.next(preference);
            obs.complete();
        });

        return obs.asObservable();
    }

    public showNeutralFeedback(content: string): Observable<string> {
        const subject = new Subject<string>();
        const ref = this.open(NeutralFeedbackModalComponent);
        const instance = <NeutralFeedbackModalComponent>ref.componentInstance;

        instance.neutralFeedbackContent = content;

        return subject.asObservable();
    }
}
