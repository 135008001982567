import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {IconEnum} from 'enums/icon.enum';
import {LinkTargetEnum} from 'enums/link-target.enum';
import {ActionButtonClassEnum} from 'shared/enums/action-button/class.enum';
import {ActionButtonDefaultModel} from 'shared/models/action-button/default.model';

export class ProgressActionButtonPdfModel extends ActionButtonDefaultModel implements ActionButtonInterface {

    public target: LinkTargetEnum = LinkTargetEnum.Blank;

    public classModifiers: Array<string> = [
        ActionButtonClassEnum.Purple,
    ];

    constructor(public href: string) {
        super('action-pdf', 'Pdf bestand', IconEnum.Pdf);

        this.href = href;
    }
}
