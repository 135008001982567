import {AfterViewInit, Component, ElementRef, HostBinding, HostListener, ViewChild} from '@angular/core';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';
import {DocumentAnswerableComponent} from 'document/modules/abstract/answerable.component';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {CanvasInstance} from 'document/modules/components/canvas/model/canvas';
import {RoleEnum} from 'enums/role.enum';
import {DocumentService} from 'document/services/document/document.service';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';

@Component({
    selector: '.document-canvas',
    templateUrl: 'canvas.component.html',
})
export class DocumentCanvasComponent extends DocumentAnswerableComponent implements AfterViewInit {

    @ViewChild('canvas', {static: true})
    public canvas: ElementRef;

    @ViewChild('canvasInitial', {static: true})
    public canvasInitialAnswer: ElementRef;

    @ViewChild('canvasHandedIn', {static: true})
    public canvasHandedIn: ElementRef;

    @HostBinding('class')
    public elementClass: string = DocumentHostClassesEnum.Canvas;

    public readonly roleEnum = RoleEnum;

    public readonly examinationNeededEnum = ExamineOptionsEnum;

    public examinationByStudent: boolean = false;

    public id: string;

    public correctAnswer: string;

    protected dataSet: DocumentDatasetOptionsInterface;

    protected dataSetOptions: DocumentCanvasDatasetOptionsInterface;

    private answerCanvas: CanvasInstance;

    private handedInCanvas: CanvasInstance;

    private initialAnswerCanvas: CanvasInstance;

    /**
     * @see https://medium.com/@tarik.nzl/creating-a-canvas-component-with-free-hand-drawing-with-rxjs-and-angular-61279f577415
     */
    constructor(
        protected elementRef: ElementRef,
        protected answersService: DocumentAnswersService,
        protected showAnswersService: DocumentAnswersStateService,
        private documentService: DocumentService,
    ) {
        super(elementRef, answersService, showAnswersService);

        this.id = `canvas-${this.dataSet.assignmentid}-${this.dataSet.fieldid}`;
        this.setDatasetOptions();
    }

    public ngAfterViewInit(): void {
        this.answerCanvas = new CanvasInstance(this.canvas.nativeElement, this.dataSetOptions, this.elementRef.nativeElement.clientWidth, (values) => {
            this.save(values);
        });

        this.handedInCanvas = new CanvasInstance(this.canvasHandedIn.nativeElement, this.dataSetOptions, this.elementRef.nativeElement.clientWidth);
        this.handedInCanvas.setDisabled(true);

        // set the width and height
        this.onResize();
        this.handleExaminationNeeded();

        super.ngAfterViewInit();
    }

    @HostListener('window:resize')
    public onResize(): void {
        if (this.answerCanvas) {
            this.answerCanvas.resize(this.elementRef.nativeElement.clientWidth);
        }

        if (this.initialAnswerCanvas) {
            this.initialAnswerCanvas.resize(this.elementRef.nativeElement.clientWidth);
        }

        if (this.handedInCanvas) {
            this.handedInCanvas.resize(this.elementRef.nativeElement.clientWidth);
        }
    }

    private handleExaminationNeeded(): void {
        const task = this.documentService.getActiveTask();

        // examination by Student
        if (task && task.examination_needed === this.examinationNeededEnum.Student) {
            this.examinationByStudent = this.documentService.subscribeSelfStudyCompatible();
        }
    }


    protected setDatasetOptions(): void {
        try {
            if (this.dataSet && this.dataSet.options.length !== 0) {
                this.dataSetOptions = JSON.parse(this.dataSet.options);
            }
        } catch (exception) {
            throw new Error(exception);
        }
    }

    protected setAnswer(answer: string): void {
        if (!this.answerCanvas) {
            return;
        }

        const decodedAnswer = this.getDecodedAnswer(answer);
        this.answerCanvas.setAnswer(decodedAnswer);

        if (this.handedInCanvas) {
            this.handedInCanvas.setAnswer(decodedAnswer);
        }
    }

    protected save(value: any): void {
        const encodedAnswer: string = JSON.stringify(value);

        this.saveAnswer(encodedAnswer);

        if (this.handedInCanvas) {
            this.handedInCanvas.setAnswer(value);
        }
    }

    protected showCorrectAnswer(correctAnswer: string): void {
        const answerImage = new Image();

        answerImage.src = `${this.dataSetOptions.imagepath}${correctAnswer}`;
        answerImage.onerror = () => {
            // observer.error('An error occurred while loading the image');
        };
        answerImage.onload = () => {
            this.correctAnswer = answerImage.src;
        };
    }

    protected answerValid(): boolean {
        return true;
    }

    protected showInitialAnswer(initialAnswer: string): void {
        if (!initialAnswer || !this.canvasInitialAnswer) {
            return;
        }

        if (initialAnswer === JSON.stringify(this.answerCanvas.getAnswer())) {
            return;
        }

        this.initialAnswerCanvas = new CanvasInstance(this.canvasInitialAnswer.nativeElement, this.dataSetOptions, this.elementRef.nativeElement.clientWidth);
        this.initialAnswerCanvas.setAnswer(initialAnswer);
        this.initialAnswerCanvas.setDisabled(true);
    }

    protected enableAnswers(): void {
        if (this.answerCanvas) {
            this.answerCanvas.setDisabled(false);
        }
    }

    protected disableAnswers(): void {
        if (this.answerCanvas) {
            this.answerCanvas.setDisabled(true);
        }
    }
}
