export enum DocumentHostClassesEnum {

    Document = 'document',

    ContentMark = 'document__content-mark',

    NeutralFeedback = 'document__neutral-feedback',

    Assignment = 'document__assignment',

    Section = 'document__section',

    SectionReview = 'document__section--review',

    SectionReviewContainer = 'document__section--review-container',

    SubSection = 'document__sub-section',

    ResourceLink = 'document__resource-link',

    Question = 'document__question',

    QuestionAnswer = 'document__question-answer',

    QuestionHandedIn = 'document__question-handed-in',

    QuestionInitialAnswer = 'document__question-initial-answer',

    Media = 'document__content-media',

    MediaError = 'document__content-media--error',

    TableResponsive = 'document__content-table--responsive',

    Table = 'document__content-table',

    Canvas = 'document__canvas',

    SortableBlock = 'document__sortable-block',

    StepList = 'document__step-list',

    StepListItem = 'document__step-list-item',

}
