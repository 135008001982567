import * as Sentry from '@sentry/browser';
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {ErrorHandler as BaseErrorHandler, NgModule} from '@angular/core';
import {DrillsterService} from 'services/drillster/drillster.service';
import {AppComponent} from './app.component';
import {CoreModule} from 'core/core.module';
import {HttpClientModule} from '@angular/common/http';
import {LocalStorageModule} from 'angular-2-local-storage';
import {SchoolService} from 'services/school/school.service';
import {BookService} from 'services/book/book.service';
import {CommonModule} from '@angular/common';
import {RedactorService} from 'services/redactor/redactor.service';
import {TeacherMaterialService} from 'services/teacher-material/teacher-material.service';
import {HelpersModule} from 'helpers/helpers.module';
import {MathJaxModule} from 'ngx-mathjax';
import {WindowService} from 'services/window/window.service';
import {StringService} from 'services/string/string.service';
import {FullscreenService} from 'services/fullscreen/fullscreen.service';
import {environment} from 'environments/environment';
import {GroupsService} from 'services/groups/groups.service';
import {TaskService} from 'services/task/task.service';
import {AssignmentService} from 'services/assignment/assignment.service';
import {LicenseService} from 'services/license/license.service';
import {CollectionBrowseFactory} from 'core/factories/collection-browse.factory';
import {FeedbackService} from 'services/feedback/feedback.service';
import {HomeworkService} from 'services/homework/homework.service';
import {HttpInterceptorsModule} from 'http-interceptors/http-interceptors.module';
import {SharedModule} from 'shared/shared.module';
import {ProgressService} from 'services/progress/progress.service';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {SvgFactory} from 'factories/svg.factory';
import {RoutingModule} from 'routing/routing.module';
import {PublisherService} from 'services/publisher/publisher.service';
import {InternshipService} from 'services/internship/internship.service';
import {ExamService} from 'services/exam/exam.service';
import {ExamMetadataService} from 'services/exam/exam-metadata.service';
import {MessageService} from 'services/message/message.service';
import {RouterModule} from '@angular/router';
import {AnalyticsService} from 'services/analytics/analytics.service';
import {PublisherFactory} from 'services/publisher/factories/publisher.factory';
import {SummaryService} from 'services/summary/summary.service';
import {NoteService} from 'services/note/note.service';
import {SourceService} from 'services/source/source.service';
import {SearchService} from 'services/search/search.service';
import {ErrorHandler} from 'services/error-handler/error-handler.service';
import {NotificationService} from 'services/notification/notification.service';
import {SystemService} from 'services/system/system.service';
import {FaviconService} from 'services/favicon/favicon.service';
import {HelpPageContentService} from 'services/help-page/help-page-content.service';
import {HelpPageService} from 'services/help-page/help-page.service';
import {HammerJSConfig} from 'app/config/hammerjs.config';
import {DemoBarService} from 'services/demo-bar/demo-bar.service';
import {AlgebrakitService} from 'services/algebrakit/algebrakit.service';
import {OnedriveModule} from 'onedrive/onedrive.module';
import {MsalModule} from '@azure/msal-angular';
import * as moment from 'moment';
import {FileService} from 'services/file/file.service';
import {MercureService} from 'services/mercure/mercure.service';

Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.environment,
    enabled: environment.sentryEnabled
});

moment.locale('nl');

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        DeviceDetectorModule.forRoot(),
        MathJaxModule.config(true, {
            version: '2.7.7',
            config: 'TeX-AMS-MML_HTMLorMML',
            hostname: 'cdnjs.cloudflare.com',
        }),
        LocalStorageModule.forRoot({
            prefix: 'app-24-boost',
            storageType: 'localStorage',
        }),
        MsalModule.forRoot({
            auth: {
                clientId: environment.microsoftAppId,
                redirectUri: environment.microsoftRedirectUrl,
                navigateToLoginRequestUrl: true
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false, // set to true for IE 11
            },
        },
        {
            popUp: false,
            consentScopes: [
                'user.read', 'Files.ReadWrite.AppFolder', 'Files.Read.All', 'Files.ReadWrite.All'
            ],
            protectedResourceMap: [
                ['https://graph.microsoft.com/', ['user.read', 'Files.ReadWrite.AppFolder', 'Files.Read.All', 'Files.ReadWrite.All']],
                [environment.microsoftRedirectUrl, null],
            ],
            extraQueryParameters: {}
        }),
        HelpersModule,
        BrowserModule,
        RoutingModule,
        CoreModule,
        SharedModule,
        CommonModule,
        HttpClientModule,
        HttpInterceptorsModule,
        RouterModule,
        OnedriveModule
    ],
    providers: [
        {provide: BaseErrorHandler, useClass: ErrorHandler},
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerJSConfig // Bovenstaande config file
        },
        CollectionBrowseFactory,
        SvgFactory,
        PublisherService,
        WindowService,
        SchoolService,
        BookService,
        RedactorService,
        TeacherMaterialService,
        StringService,
        FullscreenService,
        GroupsService,
        TaskService,
        AssignmentService,
        LicenseService,
        FeedbackService,
        HomeworkService,
        ProgressService,
        InternshipService,
        ExamService,
        ExamMetadataService,
        MessageService,
        AnalyticsService,
        PublisherFactory,
        SummaryService,
        NoteService,
        SourceService,
        SearchService,
        NotificationService,
        SystemService,
        FaviconService,
        DrillsterService,
        HelpPageContentService,
        HelpPageService,
        DemoBarService,
        AlgebrakitService,
        FileService,
        MercureService,
    ],
    bootstrap: [
        AppComponent,
    ],
})
export class AppModule {
}
