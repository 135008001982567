import {Component, HostBinding} from '@angular/core';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';

@Component({
    selector: `.document-steplistitem`,
    templateUrl: 'step-list-item.component.html',
})
export class DocumentStepListItemComponent {

    @HostBinding('class')
    public elementClass: string = DocumentHostClassesEnum.StepListItem;

}
