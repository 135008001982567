import {ContentChild, Directive, TemplateRef} from '@angular/core';
import {GridTableScrollColCellDirective} from 'shared/modules/grid-table/components/scroll-col/column/cell/cell.directive';
import {GridTableScrollColHeaderDirective} from 'shared/modules/grid-table/components/scroll-col/column/header/header.directive';

@Directive({
    selector: 'app-grid-table-scroll-col-column',
})
export class GridTableScrollColColumnDirective {
    @ContentChild(GridTableScrollColHeaderDirective, { read: TemplateRef, static: true })
    private headerTemplateDirective: TemplateRef<any>;

    @ContentChild(GridTableScrollColCellDirective, { read: TemplateRef, static: true })
    private cellTemplateDirective: TemplateRef<any>;

}
