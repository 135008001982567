import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {environment} from 'environments/environment';
import {finalize, map} from 'rxjs/operators';
import {StringService} from 'services/string/string.service';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {Observable, Subject} from 'rxjs';

@Injectable()
export class ApiService {

    public constructor(
        private httpClient: HttpClient,
        private stringService: StringService,
    ) {
    }

    public head<T>(
        url: string,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        return this.mapObservable<T>(
            this.httpClient.head<ApiResponseInterface<T>>(this.buildUrl(url, urlProperties), this.getOptions(queryParams, options))
        );
    }

    public options<T>(
        url: string,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        return this.mapObservable<T>(
            this.httpClient.get<ApiResponseInterface<T>>(this.buildUrl(url, urlProperties), this.getOptions(queryParams, options))
        );
    }

    public get<T>(
        url: string,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        return this.mapObservable<T>(
            this.httpClient.get<ApiResponseInterface<T>>(this.buildUrl(url, urlProperties), this.getOptions(queryParams, options))
        );
    }

    public download<T>(
        url: string,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        return this.mapObservable<T>(
            this.httpClient.get<T>(this.buildUrl(url, urlProperties), this.getOptions(queryParams, options))
        );
    }

    public post<T>(
        url: string,
        body?: any | null,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        return this.mapObservable<T>(
            this.httpClient.post<ApiResponseInterface<T>>(this.buildUrl(url, urlProperties), body, this.getOptions(queryParams, options))
        );
    }

    public put<T>(
        url: string,
        body?: any | null,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        return this.mapObservable<T>(
            this.httpClient.put<ApiResponseInterface<T>>(this.buildUrl(url, urlProperties), body, this.getOptions(queryParams, options))
        );
    }

    public delete<T>(
        url: string,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        return this.mapObservable<T>(
            this.httpClient.delete<ApiResponseInterface<T>>(this.buildUrl(url, urlProperties), this.getOptions(queryParams, options))
        );
    }

    public uploadFile<T>(
        file: File,
        url?: string,
        queryParams?: URLSearchParams,
        urlProperties?: Map<string, string>,
        options?: any,
    ): Observable<T> {
        if (url === undefined || url === null) {
            url = ApiEndpointEnum.FileAdd;
        }

        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.mapObservable<T>(
            this.httpClient.post<ApiResponseInterface<T>>(this.buildUrl(url, urlProperties), formData, this.getOptions(queryParams, options))
        );
    }

    public buildUrl(url: string, urlProperties?: Map<string, string>): string {
        const mappedUrl: string = this.stringService.getMappedString(url, urlProperties);

        return environment.apiUrl + mappedUrl;
    }

    /**
     * @Deprecated()
     */
    public buildUrlWithParams(url: string, urlProperties?: Map<string, string>, queryParams?: URLSearchParams): string {
        const mappedUrl: string = this.stringService.getMappedString(url, urlProperties);

        let apiUrl = environment.apiUrl + mappedUrl;

        if (queryParams !== undefined && queryParams !== null && queryParams.toString() !== '') {
            apiUrl += '?' + queryParams.toString();
        }

        return apiUrl;
    }

    /**
     * Maps request observable and ensures complete is always triggered.
     * Note: Errors are handled by the error handler
     */
    protected mapObservable<T>(observable: any, field: string = 'data'): Observable<T> {
        const subject: Subject<T> = new Subject();

        observable.pipe(
            map(response => (response && response[field]) ? response[field] : response),
            finalize(() => subject.complete()), // Triggers as long as no errors occur
        ).subscribe(
            (response: T) => subject.next(response),
            (error: HttpErrorResponse) => subject.error(error),
        );

        return subject.asObservable();
    }

    protected getOptions(queryParams?: URLSearchParams, options?: any): any {
        let defaultOptions: any = {withCredentials: true};

        if (options !== undefined) {
            defaultOptions = Object.assign(defaultOptions, options);
        }

        if (queryParams instanceof URLSearchParams) {
            let httpParams = new HttpParams();

            // @ts-ignore
            for (const key of queryParams.keys()) {
                const paramValue: any | undefined = queryParams.get(key);

                if ('undefined' === paramValue) {
                    continue;
                }

                httpParams = httpParams.append(key, paramValue);
            }

            defaultOptions.params = httpParams;
        }

        return defaultOptions;
    }
}
