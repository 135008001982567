import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {Observable} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {StringService} from 'services/string/string.service';

@Injectable()
export class DrillsterService {

    constructor(
        private apiRequestService: ApiService,
        private stringService: StringService,
    ) {
    }

    public updateScore(bookId: number, drillsterId: string, score: number): Observable<ApiTaskAddInterface> {
        const encodedDrillsterId = this.getEncodedDrillsterId(drillsterId);
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.DrillsterUpdateScore, new Map([
            ['bookId', bookId.toString()],
            ['drillsterId', encodedDrillsterId],
        ]));

        return this.apiRequestService.post<ApiTaskAddInterface>(apiRoute, {
            proficiency: score
        });
    }

    private getEncodedDrillsterId(drillsterId: string): string {
        return encodeURIComponent(btoa(drillsterId));
    }
}
