import {Component, EventEmitter, Input, Output} from '@angular/core';
import {HorizontalBarIconClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-icon-classes.enum';
import {IconEnum} from 'enums/icon.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';

@Component({
    selector: 'app-tasks-current-task',
    templateUrl: 'current-task.component.html',
})
export class TasksCurrentTaskComponent {

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly horizontalBarIconClasses = HorizontalBarIconClassesEnum;

    public readonly iconEnum = IconEnum;

    @Input()
    public masterTask: ApiTaskDetailsInterface;

    @Input()
    public activeTask: ApiTaskDetailsInterface;

    @Output()
    public toTask: EventEmitter<number> = new EventEmitter<number>();

    public browseTasks(taskId: number | null): void {
        if (!taskId) {
            return;
        }

        this.toTask.emit(taskId);
    }
}
