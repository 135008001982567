import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';

@Component({
    selector: 'app-page',
    templateUrl: 'page.component.html',
})
export class PageComponent implements OnInit {

    public static CLASS = 'page-component';

    @HostBinding('class')
    public hostClasses: string;

    @Input()
    public classModifiers: Array<string> = [];

    private classHelper = new ClassHelper(PageComponent.CLASS);

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classModifiers);
        this.hostClasses = this.classHelper.toString();
    }
}
