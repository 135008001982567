import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {RouteService} from 'routing/services/route/route.service';
import {ToastrService} from 'ngx-toastr';
import {HomeworkService} from 'services/homework/homework.service';

@Component({
    selector: 'app-tasks-homework',
    templateUrl: 'task-homework.component.html',
})
export class TasksHomeworkComponent implements OnInit, OnDestroy {

    public isLoading: boolean = true;

    private routeParamSubscription: Subscription;

    private homeworkSubscription: Subscription;

    constructor(
        private homeworkService: HomeworkService,
        private routeService: RouteService,
        private toastService: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
    }

    public ngOnInit(): void {
        this.routeParamSubscription = this.route.params.subscribe((params: Params) => {
            this.homeworkSubscription = this.homeworkService.retrieveHomework(params.homeworkId)
                .subscribe((response: ApiHomeworkCurrentInterface) => {
                    this.isLoading = false;
                    this.router.navigate([
                        this.routeService.getRouteWithPublisher(RoutesEnum.TaskDetails, new Map([
                            ['taskId', response.current.task.id.toString()],
                        ])),
                    ]);
                }, () => {
                    this.toastService.error('Kon huiswerk niet ophalen.');
                    this.router.navigate([
                        this.routeService.getRouteWithPublisher(RoutesEnum.Tasks),
                    ]);
                });
        });
    }

    public ngOnDestroy(): void {
        if (this.routeParamSubscription instanceof Subscription) {
            this.routeParamSubscription.unsubscribe();
        }

        if (this.homeworkSubscription instanceof Subscription) {
            this.homeworkSubscription.unsubscribe();
        }
    }
}
