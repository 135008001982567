import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subscription, timer} from 'rxjs';
import {ClassHelper} from 'helpers/dom/class.helper';
import {SearchModeEnum} from 'app/modules/shared/modules/search/enums/search-mode.enum';
import {SearchInterface} from 'app/modules/shared/modules/search/interfaces/search.interface';
import {SearchClassEnum} from 'shared/modules/search/enums/search-class.enum';

@Component({
    selector: 'app-search-component',
    templateUrl: 'search.component.html'
})
export class SearchComponent implements OnInit, OnChanges {

    protected static SEARCH_FIELD_COUNT = 0;

    public static readonly CLASS: string = 'search-component';

    public readonly formModifiers = 'form';

    @HostBinding('class')
    protected elementClass: string;

    @Input()
    public mode: number;

    @Input()
    public search: SearchInterface = {};

    @Input()
    public timeOut: number = 500;

    @Input()
    public disabled: boolean = false;

    @Output()
    public valueEvent: EventEmitter<string> = new EventEmitter();

    @Input()
    public elementId: string;

    public searchValue: string;

    public classHelper: ClassHelper = new ClassHelper(SearchComponent.CLASS);

    private timer: Subscription;

    public ngOnInit(): void {
        this.mode = this.search.mode !== undefined ? this.search.mode : SearchModeEnum.User;
        this.classHelper.addClasses(this.search.classModifiers || [], this.formModifiers);
        this.elementClass = this.classHelper.toString();
        this.updateElementId();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            this.classHelper.toggleClassByBoolean(SearchClassEnum.Disabled, changes.disabled.currentValue);
            this.elementClass = this.classHelper.toString();
        }
    }

    public onKeyUp(event: KeyboardEvent): void {
        if (this.disabled) {
            return;
        }

        // for backwards compatibility
        if (event.code === 'Enter' || event.key === 'Enter' || this.mode === SearchModeEnum.Instant) {
            this.doSearch();

            return;
        }

        if (this.mode !== SearchModeEnum.Delay) {
            return;
        }

        this.reset();
        this.timer = timer(this.timeOut).subscribe(() => {
            this.valueEvent.emit(this.searchValue);
        });
    }

    public doSearch(): void {
        this.valueEvent.emit(this.searchValue);
        this.reset();
    }

    private reset(): void {
        if (this.timer instanceof Subscription) {
            this.timer.unsubscribe();
            this.timer = null;
        }
    }

    private updateElementId(): void {
        if (this.elementId !== undefined) {
            return;
        }

        this.elementId = `input-text-search-${++SearchComponent.SEARCH_FIELD_COUNT}`;
    }
}
