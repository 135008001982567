import {SearchInterface} from 'shared/modules/search/interfaces/search.interface';
import {IconEnum} from 'enums/icon.enum';

export class SearchPrefixedDefaultModel implements SearchInterface {

    public prefixIcon: IconEnum = IconEnum.Search;

    constructor(
        public placeHolder: string,
        public classModifiers: Array<string>,
    ) {
    }
}
