import {Injectable} from '@angular/core';
import {RoleEnum} from 'enums/role.enum';
import {UserRolesModel} from 'security/models/user-roles.model';
import {RolesLogicInterface} from 'security/interfaces/roles-logic.interface';
import {UserService} from 'security/services/user/user.service';
import {ApiUserDataInterface} from 'interfaces/api/user-data.interface';

@Injectable()
export class RoleService {

    protected userRolesInstance: UserRolesModel;

    protected roleLogic: Map<string, RolesLogicInterface> = new Map();

    /**
     * Localstorage is injected to prevent possible circular dependency injection
     */
    constructor(
        private userService: UserService,
    ) {
        this.userService.onUserDataChange.subscribe(() => {
            this.reloadRoles();
        });
    }

    protected get userRoles(): UserRolesModel {
        if (this.userRolesInstance === undefined) {
            const userData: ApiUserDataInterface = this.userService.getUserData();
            const userRole: RoleEnum = userData.role;

            this.userRolesInstance = new UserRolesModel(userRole);
        }

        return this.userRolesInstance;
    }

    /**
     * Roles determine whether users see parts of the app or not, like navbar buttons.
     */
    public getUserRoles(): UserRolesModel {
        return this.userRoles;
    }

    public getRolesSnapshot(): string {
        return this.userRoles.getSnapshot();
    }

    public registerRoleLogic(logic: RolesLogicInterface): void {
        const className: string = logic.getName();

        if (this.roleLogic.has(className)) {
            throw new Error(`Role logic "${className}" has already been registered`);
        }

        this.roleLogic.set(className, logic);

        this.loadRoleLogicByClass(className);
    }

    public unregisterLogic(logic: RolesLogicInterface): void {
        const className: string = logic.getName();

        if (!this.roleLogic.has(className)) {
            throw new Error(`Role logic "${className}" has not been registered`);
        }

        this.roleLogic.delete(className);
    }

    public reloadRoles(): void {
        this.userRoles.reset();
        this.loadRoleLogic();
    }

    protected addRoles(roles: Array<RoleEnum>): void {
        roles.forEach((role: RoleEnum) => {
            this.addRole(role);
        });
    }

    protected addRole(role: RoleEnum): void {
        if (!this.userRoles.includes(role)) {
            this.userRoles.push(role);
        }
    }

    protected loadRoleLogicByClass(className: string): void {
        this.addRoles(this.roleLogic.get(className).getRoles());
    }

    protected loadRoleLogic(): void {
        this.roleLogic.forEach((logic: RolesLogicInterface, className: string) => {
            this.loadRoleLogicByClass(className);
        });
    }
}
