import {Component, Input, OnInit} from '@angular/core';
import {RadioBoxInterface} from 'shared/components/radio-box/interfaces/radio-box.interface';
import {SourceDisplayOnEnum} from 'pages/modules/document/enums/source-display-on.enum';
import {SourceAccessibleForEnum} from 'pages/modules/document/enums/source-accessible-for.enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SourceService} from 'services/source/source.service';
import {AttachmentSourceInterface} from 'shared/modals/source/interfaces/source.interface';
import {ToastrService} from 'ngx-toastr';
import {DocumentService} from 'document/services/document/document.service';
import {AttachmentSourceFileInterface} from 'shared/modals/source/interfaces/source-file.interface';
import {AttachmentSourceLinkInterface} from 'shared/modals/source/interfaces/source-link.interface';
import {DocumentSourceInterface} from 'document/modules/interfaces/source.interface';
import {AttachmentsComponentClassEnum} from 'shared/components/attachments/enums/class.enum';
import {ButtonClassEnum} from 'shared/components/button/enums/button-class.enum';
import {Observable} from 'rxjs';
import {DocumentSourceTypeEnum} from 'document/modules/enums/source-type.enum';

@Component({
    selector: 'app-source-modal',
    templateUrl: 'source-modal.component.html',
})
export class SourceModalComponent implements OnInit {

    public readonly attachmentsClassEnum = AttachmentsComponentClassEnum;

    public readonly buttonClassEnum = ButtonClassEnum;

    public readonly accessibleForOptions: Array<RadioBoxInterface> = [
        {
            id: SourceAccessibleForEnum.TeachersAndStudents,
            value: 'Docenten en studenten',
        },
        {
            id: SourceAccessibleForEnum.Teachers,
            value: 'Alleen docenten',
        }
    ];

    public readonly displayOnOptions: Array<RadioBoxInterface> = [
        {
            id: SourceDisplayOnEnum.ThisPage,
            value: 'Deze pagina',
        },
        {
            id: SourceDisplayOnEnum.AllPages,
            value: 'Alle pagina\'s van dit hoofdstuk',
        }
    ];

    @Input()
    public resource?: DocumentSourceInterface;

    public title: string = '';

    public sourceAccessibleFor: SourceAccessibleForEnum = SourceAccessibleForEnum.TeachersAndStudents;

    public sourceDisplayOn: SourceDisplayOnEnum = SourceDisplayOnEnum.ThisPage;

    public attachments: AttachmentDataInterface = {
        urls: [],
        files: [],
    };

    public isLoading: boolean = false;

    constructor(
        private activeModal: NgbActiveModal,
        private sourceService: SourceService,
        private documentService: DocumentService,
        private toastService: ToastrService,
    ) {
    }

    public ngOnInit(): void {
        if (this.resource !== undefined) {
            this.title = this.resource.value;
            this.sourceAccessibleFor = this.resource.accessibleFor;
            this.sourceDisplayOn = this.resource.displayOn;

            if (this.resource.type === DocumentSourceTypeEnum.File) {
                this.attachments.files.push({
                    id: null,
                    name: this.resource.value,
                    mimetype: null,
                    extension: null,
                    created: null,
                    updated: null,
                    size: null,
                });
            } else {
                this.attachments.urls.push({
                    id: Number(this.resource.id),
                    url: this.resource.value,
                });
            }
        }
    }

    public close(reason?: any): void {
        if (this.isLoading) {
            return;
        }

        this.activeModal.dismiss(reason);
    }

    public save(): void {
        if (this.isLoading) {
            return;
        }

        const currentDocument: ApiDocumentDataInterface = this.documentService.getDocument();

        if (this.title.length === 0) {
            this.toastService.warning('Kan bron niet toevoegen zonder een titel');

            return;
        }

        if (currentDocument === undefined) {
            this.toastService.warning('Kan bron niet toevoegen zonder een document');

            return;
        }

        this.isLoading = true;

        const data: AttachmentSourceInterface = {
            title: this.title,
            book: this.documentService.getBookId(),
            uuid: currentDocument.parent_uuid,
            teacher_only: this.sourceAccessibleFor === SourceAccessibleForEnum.Teachers,
        };

        if (this.sourceDisplayOn === SourceDisplayOnEnum.ThisPage) {
            data['page_uuid'] = currentDocument.dpsid;
        }

        if (this.attachments.files.length > 0) {
            this.createFile(data);
        } else {
            this.createLink(data);
        }
    }

    public remove(): void {
        if (this.isLoading) {
            return;
        }

        this.isLoading = true;
        this.sourceService.remove(this.resource.id).subscribe(
            () => {
                this.toastService.success('Bron is verwijderd.');
                this.activeModal.close(null);
            },
            (error: any) => {
                this.handleError(error, 'Kon bron niet verwijderen.');
            });
    }

    private createFile(data: AttachmentSourceInterface): void {
        const hasFiles: boolean = this.attachments.files.length > 0;
        const fileData: AttachmentSourceFileInterface = hasFiles
            ? Object.assign(data, {file_id: this.attachments.files[0].id})
            : <AttachmentSourceFileInterface>data;

        if (this.resource && fileData.file_id === null) {
            delete fileData.file_id;
        }

        this.createSource(fileData);
    }

    private createLink(data: AttachmentSourceInterface): void {
        const hasUrls: boolean = this.attachments.urls.length > 0;
        const linkData: AttachmentSourceLinkInterface = hasUrls
            ? Object.assign(data, {link: this.attachments.urls[0].url})
            : <AttachmentSourceLinkInterface>data;

        if (this.resource && linkData.link === this.resource.value) {
            delete linkData.link;
        }

        this.createSource(linkData);
    }

    private createSource(data: AttachmentSourceInterface): void {
        const observable: Observable<ApiSourceInterface> = this.resource
            ? this.sourceService.edit(this.resource.id, data)
            : this.sourceService.create(data);

        observable.subscribe((response: ApiSourceInterface) => {
            this.toastService.success('Bron is toegevoegd.');
            this.activeModal.close(response);
        }, (error: any) => this.handleError(error, 'Kon bron niet toevoegen.'));
    }

    private handleError(error: any, message: string): void {
        console.error(error);
        this.isLoading = false;
        this.toastService.error(message);
    }
}
