import {Injectable} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {NotificationTypeEnum} from 'enums/notification-type.enum';
import {MessageCenterMessageTypeInterface} from 'pages/modules/message-center/interfaces/message-type.interface';
import {RouteService} from 'routing/services/route/route.service';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {MessageCenterMessageTypeEnum} from 'pages/modules/message-center/enums/type.enum';

@Injectable()
export class MessageCenterMessageTypeFactory {

    constructor(
        private routeService: RouteService,
    ) {
    }

    protected getTaskDetailsRouterLink(taskId: string): string {
        return this.routeService.getRouteWithPublisher(RoutesEnum.TaskDetails, new Map([
            ['taskId', taskId],
        ]));
    }

    protected getDocumentRouterLink(apiNotificationInfo: ApiNotificationInfoInterface): string | undefined {
        if ([apiNotificationInfo, apiNotificationInfo.uuid].includes(undefined)) {
            return;
        }

        if (apiNotificationInfo.bookId === undefined) {
            return this.routeService.getRouteWithPublisher(`${RoutesEnum.Deeplink}/${RoutesEnum.DeeplinkDpsid}`, new Map([
                ['dpsid', apiNotificationInfo.uuid],
            ]));
        }

        if (apiNotificationInfo.uuid === undefined || apiNotificationInfo.uuid === null) {
            return this.routeService.getRouteWithPublisher(RoutesEnum.Book, new Map([
                ['bookId', apiNotificationInfo.bookId.toString()]
            ]));
        }

        return this.routeService.getRouteWithPublisher(RoutesEnum.Document, new Map([
            ['bookId', apiNotificationInfo.bookId.toString()],
            ['documentDpsid', apiNotificationInfo.uuid],
        ]));
    }

    protected getTestRouterLink(apiNotificationInfo: ApiNotificationInfoInterface): string | undefined {
        if (!(apiNotificationInfo && apiNotificationInfo.planId)) {
            return;
        }

        return this.getTestPlanRouterLink(apiNotificationInfo.planId);
    }

    protected getTestPlanRouterLink(planId: number): string | undefined {
        if ([planId].includes(undefined)) {
            return;
        }

        return this.routeService.getRouteWithPublisher(RoutesEnum.ExamDetails, new Map([
            ['planId', planId.toString()],
        ]));
    }

    protected getExamStudentRouterLink(apiNotification: ApiNotificationInterface): string | undefined {
        if (!apiNotification.info || !apiNotification.info.user || !apiNotification.object_id) {
            return;
        }

        return this.routeService.getRouteWithPublisher(RoutesEnum.ExamStudentDetails, new Map([
            ['planId', apiNotification.object_id],
            ['studentId', apiNotification.info.user.toString()],
        ]));
    }

    protected getHomeworkRouterLink(homeworkId: string): string {
        return this.routeService.getRouteWithPublisher(RoutesEnum.TaskHomework, new Map([
            ['homeworkId', homeworkId],
        ]));
    }

    protected getLicenceBoomRouterLink(): string {
        return this.routeService.getRouteWithPublisher(RoutesEnum.TaskHomework);
    }

    public fromApiNotification(apiNotification: ApiNotificationInterface): MessageCenterMessageTypeInterface {
        switch (apiNotification.type) {

            // TODO: BO-1445 Missing bookId to complete the route
            case NotificationTypeEnum.TypeHomeworkNew:
            case NotificationTypeEnum.TypeHomeworkEdit:
            case NotificationTypeEnum.TypeHomeworkChecked:
            case NotificationTypeEnum.TypeHomeworkRechecked:
            case NotificationTypeEnum.TypeTaskResit:
                return {
                    icon: IconEnum.Check,
                    type: MessageCenterMessageTypeEnum.Tasks,
                    value: apiNotification.type,
                    routerLink: apiNotification.info
                        ? this.getTaskDetailsRouterLink(apiNotification.object_id)
                        : this.getHomeworkRouterLink(apiNotification.object_id),
                };

            case NotificationTypeEnum.TypeTaskDeadlineApproaching:
            case NotificationTypeEnum.TypeTaskDeadlineExpired:
            case NotificationTypeEnum.TypeTaskNew:
            case NotificationTypeEnum.TypeTaskEdit:
            case NotificationTypeEnum.TypeTaskDelete:
            case NotificationTypeEnum.TypeInternshipReview:
                return {
                    icon: IconEnum.Check,
                    type: MessageCenterMessageTypeEnum.Tasks,
                    value: apiNotification.type,
                    routerLink: this.getTaskDetailsRouterLink(apiNotification.object_id),
                };

            case NotificationTypeEnum.TypeFeedbackNew:
                return {
                    icon: IconEnum.Check,
                    type: MessageCenterMessageTypeEnum.Tasks,
                    value: apiNotification.type,
                    routerLink: this.getHomeworkRouterLink(String(apiNotification.info.homework)),
                };

            case NotificationTypeEnum.TypeHomeworkDelete:
                return {
                    icon: IconEnum.Check,
                    type: MessageCenterMessageTypeEnum.Tasks,
                    value: apiNotification.type,
                    routerLink: this.getTaskDetailsRouterLink(String(apiNotification.object_id)),
                };

            case NotificationTypeEnum.TypeHomeworkHandin:
                return {
                    icon: IconEnum.Check,
                    type: MessageCenterMessageTypeEnum.Tasks,
                    value: apiNotification.type,
                    routerLink: this.getHomeworkRouterLink(apiNotification.object_id),
                };

            // TODO: Missing dpsid to generate router link
            case NotificationTypeEnum.TypeNoteShared:
                return {
                    icon: IconEnum.Book,
                    type: MessageCenterMessageTypeEnum.Material,
                    value: apiNotification.type,
                    routerLink: this.getDocumentRouterLink(apiNotification.info),
                };

            case NotificationTypeEnum.TypeExamNew:
            case NotificationTypeEnum.TypeExamStart:
            case NotificationTypeEnum.TypeExamDelete:
                return {
                    icon: IconEnum.Trophy,
                    type: MessageCenterMessageTypeEnum.Tests,
                    value: apiNotification.type,
                    routerLink: this.getTestRouterLink(apiNotification.info),
                };

            case NotificationTypeEnum.TypeExamReviewed:
                return {
                    icon: apiNotification.info.examType === 20 ? IconEnum.Trophy : IconEnum.TrophyBasic,
                    type: MessageCenterMessageTypeEnum.Tests,
                    value: apiNotification.type,
                    routerLink: this.getTestPlanRouterLink(Number(apiNotification.object_id)),
                };

            case NotificationTypeEnum.TypeExamNeedsReview:
                return {
                    icon: IconEnum.Trophy,
                    type: MessageCenterMessageTypeEnum.Tests,
                    value: apiNotification.type,
                    routerLink: this.getExamStudentRouterLink(apiNotification),
                };

            case NotificationTypeEnum.TypeSourceUploaded:
                let icon = IconEnum.Document;

                if (apiNotification.info.uploadedSourceType === 'link') {
                    icon = IconEnum.Link;
                }

                return {
                    icon: icon,
                    type: MessageCenterMessageTypeEnum.Material,
                    value: apiNotification.type,
                    routerLink: this.getDocumentRouterLink(apiNotification.info),
                };

            case NotificationTypeEnum.TypeLicenceExtend:
                return {
                    icon: IconEnum.Message,
                    type: MessageCenterMessageTypeEnum.All,
                    value: apiNotification.type,
                    routerLink: this.getHomeworkRouterLink(apiNotification.object_id),
                };
        }
    }

    public fromApiMessage(apiMessage: ApiMessageInterface): MessageCenterMessageTypeInterface {
        return {
            icon: apiMessage.viewed ? IconEnum.MailOpen : IconEnum.Mail,
            type: MessageCenterMessageTypeEnum.Message,
            value: NotificationTypeEnum.TypeMessage,
        };
    }
}
