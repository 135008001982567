import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CollectionBrowseFactory} from 'core/factories/collection-browse.factory';
import {CollectionBrowseInterface} from 'interfaces/collection-browse.interface';
import {AvatarClassesEnum} from 'shared/components/avatar/enums/avatar-classes.enum';
import {IconEnum} from 'enums/icon.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {HorizontalBarIconClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-icon-classes.enum';
import {TaskStateService} from 'pages/modules/tasks/service/task-state.service';
import {TaskService} from 'services/task/task.service';
import {RoleEnum} from 'enums/role.enum';
import {AuthorizationService} from 'security/services/authorization/authorization.service';

@Component({
    selector: 'app-tasks-assigned-to-group',
    templateUrl: 'assigned-to-group.component.html',
})
export class TasksAssignedToGroupComponent implements OnChanges {

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly horizontalBarIconClasses = HorizontalBarIconClassesEnum;

    public readonly iconEnum = IconEnum;

    public readonly avatarClasses = AvatarClassesEnum;

    @Input()
    public activeTask: ApiTaskDetailsInterface;

    @Input()
    public taskGroups: Array<ApiTaskGroupInterface>;

    @Input()
    public childTasks: Array<ApiTaskDetailsInterface>;

    @Input()
    public homework: ApiHomeworkCurrentInterface;

    public taskGroup: ApiTaskGroupInterface;

    public groups: CollectionBrowseInterface;

    public initialTaskIsMasterTask: boolean;

    public constructor(
        private taskService: TaskService,
        private tasksService: TaskStateService,
        private authorizationService: AuthorizationService,
        private collectionBrowseFactory: CollectionBrowseFactory
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.activeTask || !this.activeTask.is_taskgrouptask) {
            return;
        }

        this.taskGroup = this.activeTask.taskgroup;
        this.initialTaskIsMasterTask = this.taskService.getIsOriginalTask() && this.authorizationService.isGranted(RoleEnum.RoleTeacher);
        this.setupDataForTeacher();
    }

    public browseStudentTasks(direction: number): void {
        const nextIndex = this.groups.index + direction;
        const groupAtIndex = this.taskGroups[nextIndex];

        if (groupAtIndex === undefined) {
            return;
        }

        this.groups.updateIndex(nextIndex);

        this.childTasks.some(childTask => {

            if (childTask.taskgroup.id === groupAtIndex.id) {
                this.taskService.navigateToDetails(childTask.id, null, true);

                return true;
            }

            return false;
        });
    }

    private setupDataForTeacher(): void {
        if (!this.taskGroups) {
            return;
        }

        this.groups = this.collectionBrowseFactory.create(0, this.taskGroups.length);

        for (let i = 0; i < this.taskGroups.length; i++) {
            if (this.activeTask && this.activeTask.taskgroup.id === this.taskGroups[i].id) {
                this.groups.updateIndex(i);
            }
        }
    }
}
