import {Injectable} from '@angular/core';
import {ProgressAssignmentStatusInterface} from 'pages/modules/progress/interfaces/assignment-status.interface';
import {ProgressAssignmentStatusModel} from 'pages/modules/progress/models/assignmen-status.model';

@Injectable()
export class ProgressAssignmentStatusFactory {

    public fromApiProgressStudent(progressStudent: ApiProgressStudentInterface): Array<ProgressAssignmentStatusInterface> {
        const statuses: Array<ProgressAssignmentStatusModel> = [];

        progressStudent.score.assignments_status.forEach((assignmentStatus: ApiProgressStudentScoreAssignmentStatusInterface) => {
            statuses.push(ProgressAssignmentStatusModel.fromApiProgressStudentScoreAssignmentStatus(assignmentStatus));
        });

        return statuses;
    }

    public fromApiProgressForStudent(progressForStudent: ApiProgressForStudentInterface): Array<ProgressAssignmentStatusInterface> {
        const studentScore: ApiProgressStudentScoreInterface = progressForStudent.studymaterials[0].scores[0];

        return this.fromApiProgressStudentScore(studentScore);
    }

    public fromApiProgressStudentScore(score: ApiProgressStudentScoreInterface): Array<ProgressAssignmentStatusInterface> {
        const statuses: Array<ProgressAssignmentStatusModel> = [];

        if (score.assignments_status !== undefined) {

            score.assignments_status.forEach((assignmentStatus: ApiProgressStudentScoreAssignmentStatusInterface) => {
                statuses.push(ProgressAssignmentStatusModel.fromApiProgressStudentScoreAssignmentStatus(assignmentStatus));
            });
        }

        return statuses;
    }

    public fromApiProgressAverage(average: ApiProgressAverageInterface): Array<ProgressAssignmentStatusInterface> {
        const statuses: Array<ProgressAssignmentStatusModel> = [];

        average.assignments_status.forEach((assignmentStatus: ApiProgressAverageAssignmentStatusInterface) => {
            statuses.push(ProgressAssignmentStatusModel.fromApiProgressAverageAssignmentStatus(assignmentStatus));
        });

        return statuses;
    }

}
