import {Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import {ProgressStudentDetailsColBodyInterface} from 'pages/modules/progress/components/student-details/interfaces/col-body.interface';
import {ProgressStudentDetailsColColumnInterface} from 'pages/modules/progress/components/student-details/interfaces/col-column.interface';
import {ProgressStudentDetailsColHeaderInterface} from 'pages/modules/progress/components/student-details/interfaces/col-header.interface';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {ResultClassEnum} from 'shared/components/result-cell/enums/result-class.enum';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {ProgressGroupInterface} from 'pages/modules/progress/interfaces/group.interface';
import {ProgressStudentInterface} from 'pages/modules/progress/interfaces/student.interface';
import {ProgressStudyMaterialInterface} from 'pages/modules/progress/interfaces/study-material.interface';
import {CollapsibleClassEnum} from 'shared/components/collapsible/enums/class.enum';
import {AbstractStudentGroupRowsComponent} from 'pages/modules/progress/components/student-details/grid-table/abstract-student-group-rows.component';
import {GridTableScrollColComponent} from 'shared/modules/grid-table/components/scroll-col/scroll-col.component';
import {GridTableScrollControlComponent} from 'shared/modules/grid-table/components/scroll-control/scroll-control.component';
import {ProgressExamsInterface} from 'pages/modules/progress/interfaces/exams.interface';
import {ExamTypeEnum} from 'enums/exam-type.enum';

@Component({
    selector: 'app-progress-student-details-exams',
    templateUrl: 'student-details-exams.component.html',
})
export class ProgressStudentDetailsExamsComponent extends AbstractStudentGroupRowsComponent implements OnInit, OnChanges {
    public readonly iconEnum = IconEnum;

    public readonly tableClasses = GridTableClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly rowClasses = GridTableRowClassEnum;

    public readonly resultCellClasses = ResultClassEnum;

    public readonly collapsibleClasses = CollapsibleClassEnum;

    @Input()
    public classHelper: ClassHelper;

    @Input()
    public group: ProgressGroupInterface;

    @Input()
    public user: ProgressStudentInterface = null;

    @Input()
    public studyMaterial: ProgressStudyMaterialInterface;

    @Input()
    public bookId: number;

    @Input()
    public examType: number;

    @Input()
    public title: string = null;

    public headers: ProgressStudentDetailsColHeaderInterface[] = [];

    public columns: ProgressStudentDetailsColColumnInterface[] = [];

    public rows: ProgressStudentDetailsColBodyInterface[][] = [];

    public containsExam: boolean = false;

    @ViewChild('headerExamTemplate', {read: TemplateRef, static: true})
    private headerExamTemplate: TemplateRef<any>;

    @ViewChild('contentExamTemplate', {read: TemplateRef, static: true})
    private contentExamTemplate: TemplateRef<any>;

    @ViewChild(GridTableScrollColComponent, {static: true})
    private gridScrollComponent: GridTableScrollColComponent;

    @ViewChild(GridTableScrollControlComponent, {static: true})
    private tableScrollControlComponent: GridTableScrollControlComponent;

    public ngOnInit(): void {
        this.gridScrollComponent.addScrollControl(this.tableScrollControlComponent);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.group.firstChange || changes.group.currentValue.id !== changes.group.previousValue.id) {
            this.hasExams();
            this.setupColumns();
            this.setupRows();
        }
    }

    protected getStudentGroup(): ProgressGroupInterface {
        return this.group;
    }

    protected getStudyMaterial(): ProgressStudyMaterialInterface {
        return this.studyMaterial;
    }

    protected setRows(rows: ProgressStudentDetailsColBodyInterface[][]): void {
        this.rows = rows;
    }

    protected buildStudentRow(student: ProgressStudyMaterialInterface | ProgressStudentInterface): ProgressStudentDetailsColBodyInterface[] {
        const studentColumns: ProgressStudentDetailsColBodyInterface[] = [];
        const filteredExams = student.exams.filter(exam => exam.type === this.examType);

        if (filteredExams.length === 0) {
            studentColumns.push({
                exam: null,
                student: student,
                group: this.group,
                template: this.contentExamTemplate,
            });
        }

        for (const exam of filteredExams) {
            studentColumns.push({
                exam: exam,
                student: student,
                group: this.group,
                template: this.contentExamTemplate,
            });
        }

        return studentColumns;
    }

    private hasExams(): void {
        let filteredExams;

        if (this.group && this.group.students.length > 0) {
            this.group.students.forEach(obj => {
                filteredExams = obj.exams.filter(exam => exam.type === this.examType);
            });
        } else {
            filteredExams = this.studyMaterial.exams.filter(exam => exam.type === this.examType);
        }

        if (filteredExams.length > 0) {
            this.containsExam = true;
        }
    }

    private setupColumns(): void {
        const headers: Array<ProgressStudentDetailsColHeaderInterface> = [];
        const columns: Array<ProgressStudentDetailsColColumnInterface> = [];
        const progressExams: ProgressExamsInterface[][] = (this.group && this.group.students.length > 0)
            ? this.group.students.map(student => student.exams)
            : [this.studyMaterial.exams.filter(exam => exam.type === this.examType)];

        const studentExams: Map<number, ProgressExamsInterface[]> = this.getMappedStudentExams(progressExams, this.examType);

        let index = 0;

        studentExams.forEach((studentProgressExams: ProgressExamsInterface[], key: number) => {
            const progressExam: ProgressExamsInterface = studentProgressExams[0];

            if (!headers.some(header => key === header.exam.id)) {
                headers.push({
                    isExam: true,
                    exam: progressExam,
                    width: 72,
                    template: this.headerExamTemplate,
                });
            }

            columns.push({
                isExam: true,
                examNumber: ++index,
                width: 72,
                student: this.group && this.group.students.length > 0 ? this.group.students[index] : this.studyMaterial,
                group: this.group,
            });
        });

        this.headers = headers;
        this.columns = columns;
    }

    protected getMappedStudentExams(progressExams: ProgressExamsInterface[][], examType: ExamTypeEnum): Map<number, ProgressExamsInterface[]> {
        const studentExamMap: Map<number, ProgressExamsInterface[]> = new Map();

        progressExams.forEach(exams => exams.filter(exam => examType === exam.type).forEach(exam => {
            const examId: number = exam.id;

            if (!studentExamMap.has(examId)) {
                studentExamMap.set(examId, []);
            }

            studentExamMap.get(examId).push(exam);
        }));

        return studentExamMap;
    }
}
