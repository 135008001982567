import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {SearchResultInterface} from 'pages/modules/search/interfaces/search-result.interface';
import {IconEnum} from 'enums/icon.enum';
import {SearchEntityTypesEnum} from 'pages/modules/search/enums/entity-types.enum';
import {HorizontalBarIconClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-icon-classes.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {SearchResultTaskInterface} from 'pages/modules/search/interfaces/result-task.interface';
import {RouteService} from 'routing/services/route/route.service';
import {Router} from '@angular/router';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {SearchResultUserInterface} from 'pages/modules/search/interfaces/result-user.interface';
import {UserService} from 'security/services/user/user.service';
import {SearchResultDocumentInterface} from 'pages/modules/search/interfaces/result-document.interface';
import {SearchResultStudyMaterialInterface} from 'pages/modules/search/interfaces/result-study-material.interface';
import {SearchResultNoteInterface} from 'pages/modules/search/interfaces/result-note.interface';
import {ResourceTypeEnum} from 'enums/resource-type.enum';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {DocumentService} from 'document/services/document/document.service';

@Component({
    selector: 'app-search-item',
    templateUrl: 'search-item.component.html',
})
export class SearchItemComponent implements OnChanges {

    protected readonly horizontalBarClasses = HorizontalBarClassesEnum;

    @Input()
    public item: SearchResultInterface;

    public routerLink: string = null;

    public hrefLink: string = null;

    public resultIcon: IconEnum;

    public resultColorClass: string;

    constructor(
        private routeService: RouteService,
        private router: Router,
        private userService: UserService,
        private documentService: DocumentService
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.item && changes.item.currentValue !== changes.item.previousValue) {
            this.setRouterLink();
            this.setResultIcon();
            this.setResultColorClass();
        }
    }

    private setRouterLink(): void {
        switch (this.item.entity) {
            case SearchEntityTypesEnum.Task:
                this.routeToTask(<SearchResultTaskInterface>this.item);
                break;

            case SearchEntityTypesEnum.User:
                this.routeToUser(<SearchResultUserInterface>this.item);
                break;

            case SearchEntityTypesEnum.StudyMaterial:
                this.routeToStudyMaterial(<SearchResultStudyMaterialInterface>this.item);
                break;

            case SearchEntityTypesEnum.Note:
                this.routeToNote(<SearchResultNoteInterface>this.item);
                break;

            case SearchEntityTypesEnum.Document:
                this.routeToDocument(<SearchResultDocumentInterface>this.item);
                break;
        }
    }

    private setResultIcon(): void {
        switch (this.item.entity) {
            case SearchEntityTypesEnum.Note:
                this.resultIcon = IconEnum.Note;

                return;

            case SearchEntityTypesEnum.StudyMaterial:
                this.resultIcon = IconEnum.Book;

                return;

            case SearchEntityTypesEnum.Task:
                this.resultIcon = IconEnum.Check;

                return;

            case SearchEntityTypesEnum.User:
                this.resultIcon = IconEnum.User;

                return;
        }

        if (this.item.entity === SearchEntityTypesEnum.Document
            && [
                ResourceTypeEnum.Ebook,
                ResourceTypeEnum.TeacherSource,
                ResourceTypeEnum.SupportingSource,
            ].includes(<ResourceTypeEnum>(this.item as SearchResultDocumentInterface).type)) {
            this.resultIcon = IconEnum.Attachment;

            return;
        }

        this.resultIcon = IconEnum.Document;
    }

    private setResultColorClass(): void {
        switch (this.item.entity) {
            case SearchEntityTypesEnum.Task:
                this.resultColorClass = HorizontalBarIconClassesEnum.ColorTask;
                break;

            default:
                this.resultColorClass = HorizontalBarIconClassesEnum.ColorDocument;
                break;
        }
    }

    private routeToTask(task: SearchResultTaskInterface): void {
        this.hrefLink = null;

        this.routerLink = this.routeService.getRouteWithPublisher(RoutesEnum.TaskDetails, new Map([
            ['taskId', task.id.toString()],
        ]));
    }

    private routeToUser(user: SearchResultUserInterface): void {
        this.hrefLink = null;

        if (this.userService.getCurrentUserId() === user.id) {
            this.routerLink = this.routeService.getRouteWithPublisher(RoutesEnum.Profile);
        } else {
            this.routerLink = this.routeService.getRouteWithPublisher(RoutesEnum.User, new Map([
                ['userId', user.id.toString()],
            ]));
        }
    }

    private routeToStudyMaterial(studyMaterial: SearchResultStudyMaterialInterface): void {
        this.hrefLink = null;

        if (!studyMaterial.bookId || !studyMaterial.dpsid) {
            this.routerLink = this.routeService.getRouteWithPublisher(`${RoutesEnum.Deeplink}/${RoutesEnum.DeeplinkEan}`, new Map([
                ['ean', studyMaterial.ean],
            ]));
        } else {
            this.routerLink = this.routeService.getRouteWithPublisher(RoutesEnum.Document, new Map([
                ['bookId', studyMaterial.bookId.toString()],
                ['documentDpsid', studyMaterial.dpsid],
            ]));
        }
    }

    private routeToNote(note: SearchResultNoteInterface): void {
        this.hrefLink = null;

        this.routerLink = this.routeService.getRouteWithPublisher(`${RoutesEnum.Deeplink}/${RoutesEnum.DeeplinkDpsid}`, new Map([
            ['dpsid', note.dpsid],
        ]));
    }

    private routeToDocument(document: SearchResultDocumentInterface): void {
        if (document.type === ResourceTypeEnum.Ebook) {
            this.hrefLink = null;
            this.routerLink = this.routeService.getRouteWithPublisher(RoutesEnum.PdfViewer, new Map([
                ['dpsid', document.dpsid],
            ]));

            return;
        }

        if (document.type === ResourceTypeEnum.TeacherSource || document.type === ResourceTypeEnum.SupportingSource) {
            this.routerLink = null;
            this.hrefLink = this.routeService.getApiRoute(ApiEndpointEnum.ResourceExternal, new Map([
                ['dpsid', document.dpsid],
                ['book', this.documentService.getBookId().toString()],
            ]));

            return;
        }

        this.hrefLink = null;
        this.routerLink = this.routeService.getRouteWithPublisher(`${RoutesEnum.Deeplink}/${RoutesEnum.DeeplinkDpsid}`, new Map([
            ['dpsid', document.dpsid],
        ]));
    }
}
