export enum ResourceTypeEnum {

    Image = 'afbeelding',

    Sheet = 'rekenblad',

    Document = 'document',

    TeacherSource = 'teacher_source',

    Audio = 'audio',

    Video = 'video',

    Html = 'html',

    Pdf = 'pdf',

    Ebook = 'ebook',

    Link = 'link',

    SupportingSource = 'supporting_source',

    ReturnFolder = 'return',

    UploadedSourceFolder = 'uploaded_folder',

    DpsidUploadSource = 'uploadedsources'

}
