import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RadioBoxInterface} from 'shared/components/radio-box/interfaces/radio-box.interface';
import {TaskTypeEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/task-type.enum';

@Component({
    selector: 'app-tasks-add-modal-work-type',
    templateUrl: 'work-type.component.html',
})
export class TasksAddTaskModalWorkTypeComponent {

    public readonly typeOptions: Array<RadioBoxInterface> = [
        {
            id: TaskTypeEnum.Personal,
            value: 'Individuele opdracht',
        },
        {
            id: TaskTypeEnum.Group,
            value: 'Groepsopdracht',
        }
    ];

    @Input()
    public workType: TaskTypeEnum;

    @Output()
    public workTypeChange: EventEmitter<TaskTypeEnum> = new EventEmitter();

    @Input()
    public disabled: boolean = false;

    public onWorkTypeUpdated(): void {
        this.workTypeChange.emit(this.workType);
    }
}
