export enum DocumentAssignmentStatusEnum {

    None = 0,

    BpvPreparation = 1,

    Open = 2,

    SelfScoreOpen = 3,

    SelfScoring = 4,

    SelfScoringDone = 5,

    Done = 6,

    AutoScoring = 7

}
