import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'shared/shared.module';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ExamsExamCheckQuestionComponent} from 'pages/modules/exams/components/exam-check/question/question.component';
import {DocumentComponentsModule} from 'document/modules/components/components.module';
import {DocumentDirectivesModule} from 'document/modules/directives/directives.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgbTooltipModule,
        DocumentComponentsModule,
        DocumentDirectivesModule,
    ],
    declarations: [
        ExamsExamCheckQuestionComponent,
    ],
    exports: [
        ExamsExamCheckQuestionComponent,
    ],
})
export class ExamsExamCheckQuestionModule {
}
