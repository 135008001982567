import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DeeplinkBookIdComponent} from 'pages/modules/deeplink/bookId/bookId.component';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {BookshelfComponent} from 'pages/modules/bookshelf/components/bookshelf.component';
import {PdfViewerComponent} from 'pages/components/pdf-viewer/pdf-viewer.component';
import {GroupsComponent} from 'pages/modules/groups/components/groups.component';
import {TasksComponent} from 'pages/modules/tasks/components/tasks/tasks.component';
import {DocumentPageComponent} from 'pages/modules/document/components/document.component';
import {TeacherMaterialComponent} from 'pages/modules/teacher-material/components/teacher-material.component';
import {TasksDetailComponent} from 'pages/modules/tasks/components/tasks-detail/tasks-detail.component';
import {ProgressComponent} from 'pages/modules/progress/components/progress.component';
import {RouterPublisherResolveService} from 'routing/resolvers/publisher-resolve.service';
import {RouteService} from 'routing/services/route/route.service';
import {RouterAuthenticationGuard} from 'routing/guards/authentication/authentication.guard';
import {IsStudentGuard} from 'routing/guards/is-student/is-student.guard';
import {IsTeacherGuard} from 'routing/guards/is-teacher/is-teacher.guard';
import {IsStudentOrTeacherGuard} from 'routing/guards/is-student-or-teacher/is-student-or-teacher.guard';
import {SharedModule} from 'shared/shared.module';
import {NotFoundComponent} from 'shared/components/not-found/not-found.component';
import {ExamsComponent} from 'pages/modules/exams/components/exams/exams.component';
import {ExamsDetailComponent} from 'pages/modules/exams/components/exams-detail/exams-detail.component';
import {ExamsMakeComponent} from 'pages/modules/exams/components/exams-make/exams-make.component';
import {ExamsResultComponent} from 'pages/modules/exams/components/exams-result/exams-result.component';
import {RouterPublisherGuard} from 'routing/guards/publisher/publisher.guard';
import {MessageCenterComponent} from 'pages/modules/message-center/components/message-center.component';
import {TasksHomeworkComponent} from 'pages/modules/tasks/components/homework/tasks-homework.component';
import {DeeplinkEanComponent} from 'pages/modules/deeplink/ean/ean.component';
import {SearchPageComponent} from 'pages/modules/search/search/search.component';
import {UserProfilePageComponent} from 'pages/modules/user/components/profile-page/profile-page.component';
import {UserUserPageComponent} from 'pages/modules/user/components/user-page/user-page.component';
import {DeeplinkDpsidComponent} from 'pages/modules/deeplink/dpsid/dpsid.component';
import {HasGroupsGuard} from 'routing/guards/has-groups/has-groups.guard';
import {SettingsComponent} from 'pages/modules/user/components/settings/settings.component';
import {ContentExamPageComponent} from 'pages/modules/content-exam/components/page/page.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [
            RouterAuthenticationGuard,
            RouterPublisherGuard,
        ],
        children: [
            {
                path: '',
                component: NotFoundComponent,
                pathMatch: 'full',
            },
            {
                path: ':publisher',
                resolve: [
                    RouterPublisherResolveService
                ],
                pathMatch: 'prefix',
                children: [
                    {
                        path: RoutesEnum.Books,
                        component: BookshelfComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.Book,
                        component: DeeplinkBookIdComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.Groups,
                        component: GroupsComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.TeacherMaterialBook,
                        component: TeacherMaterialComponent,
                        canActivate: [
                            IsTeacherGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.TeacherMaterialBookChapter,
                        component: TeacherMaterialComponent,
                        canActivate: [
                            IsTeacherGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.TeacherMaterial,
                        component: TeacherMaterialComponent,
                        canActivate: [
                            IsTeacherGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.Profile,
                        component: UserProfilePageComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.Settings,
                        component: SettingsComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.User,
                        component: UserUserPageComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.PdfViewer,
                        component: PdfViewerComponent,
                    },
                    {
                        path: RoutesEnum.Progress,
                        component: ProgressComponent,
                        data: {
                            showLegend: true,
                        },
                        canActivate: [
                            IsStudentOrTeacherGuard,
                            HasGroupsGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.Tasks,
                        component: TasksComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                            HasGroupsGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.TaskHomework,
                        component: TasksHomeworkComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                            HasGroupsGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.TaskDetails,
                        component: TasksDetailComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                            HasGroupsGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.Exams,
                        component: ExamsComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                            HasGroupsGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.ExamDetails,
                        component: ExamsDetailComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                            HasGroupsGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.ExamStudentDetails,
                        component: ExamsDetailComponent,
                        canActivate: [
                            IsTeacherGuard,
                            HasGroupsGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.ExamMake,
                        component: ExamsMakeComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.ContentExamPlanPage,
                        component: ContentExamPageComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.ContentExamPage,
                        component: ContentExamPageComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.ContentExamPageTeacher,
                        component: ContentExamPageComponent,
                        canActivate: [
                            IsTeacherGuard,
                            HasGroupsGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.ExamPlanMake,
                        component: ExamsMakeComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                            HasGroupsGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.ExamPlanResult,
                        component: ExamsResultComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                            HasGroupsGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.ExamPlanResultTeacher,
                        component: ExamsResultComponent,
                        canActivate: [
                            IsTeacherGuard,
                            HasGroupsGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.MessageCenter,
                        component: MessageCenterComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.Document,
                        component: DocumentPageComponent,
                    },
                    {
                        path: RoutesEnum.Search,
                        component: SearchPageComponent,
                        canActivate: [
                            IsStudentOrTeacherGuard,
                        ],
                    },
                    {
                        path: RoutesEnum.Deeplink,
                        pathMatch: 'prefix',
                        children: [
                            {
                                path: RoutesEnum.DeeplinkEan,
                                component: DeeplinkEanComponent,
                            },
                            {
                                path: RoutesEnum.DeeplinkDpsid,
                                component: DeeplinkDpsidComponent,
                            },
                            {
                                path: RoutesEnum.DeeplinkBookId,
                                component: DeeplinkBookIdComponent,
                            },
                        ],
                    },
                    {
                        path: '',
                        redirectTo: RoutesEnum.Books,
                        pathMatch: 'full',
                    },
                    {
                        path: '**',
                        component: NotFoundComponent
                    },
                ],
            },
        ],
    },
    {
        path: '**',
        component: NotFoundComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
        SharedModule,
    ],
    exports: [
        RouterModule,
    ],
    providers: [
        RouteService,
        IsTeacherGuard,
        IsStudentGuard,
        IsStudentOrTeacherGuard,
        RouterAuthenticationGuard,
        HasGroupsGuard,
        RouterPublisherGuard,
        RouterPublisherResolveService,
    ],
})
export class RoutingModule {
}
