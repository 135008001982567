import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RedactorService} from 'services/redactor/redactor.service';
import {InternshipService} from 'services/internship/internship.service';
import {ToastrService} from 'ngx-toastr';
import {IconBoxClose} from 'models/iconbox/close.model';

@Component({
    selector: 'app-document-invite-external-reviewer-modal',
    templateUrl: 'invite-external-reviewer-modal.component.html',
})
export class InviteExternalReviewerModalComponent implements AfterViewInit {

    public readonly iconEnum = IconEnum;

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorBlue]);

    @Output()
    public closeEvent: EventEmitter<ApiInternshipInviteInterface|null> = new EventEmitter();

    @Input()
    public task: ApiTaskDetailsInterface;

    @Input()
    public name: string = '';

    @Input()
    public email: string = '';

    @Input()
    public remark: string = '';

    @ViewChild('attachmentTextArea', { static: true })
    protected attachmentTextArea: ElementRef;

    private redactor: RedactorAppInstance;

    constructor(
        private activeModal: NgbActiveModal,
        private redactorService: RedactorService,
        private internshipService: InternshipService,
        private toastService: ToastrService,
    ) {
    }

    public ngAfterViewInit(): void {
        const options = {
            callbacks: {
                blur: this.onRedactorChange.bind(this),
            },
        };

        this.redactorService.create(this.attachmentTextArea.nativeElement, options)
            .subscribe((redactor: RedactorAppInstance) => {
                this.redactor = redactor;

                if (this.remark) {
                    this.redactor.source.setCode(this.remark);
                }
            });
    }

    public close(): void {
        this.activeModal.dismiss();
    }

    public inviteReviewer(): void {
        if (this.name.length === 0 || this.email.length === 0) {
            this.toastService.warning('Geef een naam en een email adres op.');

            return;
        }

        this.internshipService.inviteExternalReviewer(this.task.id, this.name, this.email, this.remark)
            .subscribe((invite: ApiInternshipInviteInterface) => {
                this.toastService.success('Extern beoordelaar is uitgenodigd.');
                this.closeEvent.emit(invite);
                this.close();
            }, () => {
                this.toastService.error('Kon externe beoordelaar niet uitnodigen.');
            });
    }

    private onRedactorChange(): void {
        this.remark = this.redactor.source.getCode();
    }
}
