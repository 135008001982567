import {AfterViewInit, Component, ElementRef, HostBinding, ViewChild} from '@angular/core';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {IconEnum} from 'enums/icon.enum';
import {DomParentsHelper} from 'helpers/dom/parents.helper';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';
import {ContentMarkClassEnum} from 'document/modules/components/content-mark/enums/class.enum';
import {DocumentContentMarkTypeEnum} from 'document/modules/components/content-mark/enums/type.enum';

@Component({
    selector: '.document-docent, .document-citation, .document-quotation, .document-remark, .document-tip, .document-casus, .document-law',
    templateUrl: 'content-mark.component.html',
})
export class DocumentContentMarkComponent implements AfterViewInit {

    public readonly iconBox: IconBoxInterface = {
        type: IconBoxTypeEnum.Icon,
        value: null,
        classModifiers: [
            IconBoxClassEnum.ContentMark,
        ],
    };

    @HostBinding('class')
    public elementClass: string = DocumentHostClassesEnum.ContentMark;

    @ViewChild('originalContent', { static: true })
    public originalContent: ElementRef;

    public quote: string;

    public classModifiers: Array<string> = [];

    public nativeElement: HTMLElement;

    public contentType: DocumentContentMarkTypeEnum;

    public isInline: boolean = false;

    constructor(private elementRef: ElementRef, private domHelper: DomParentsHelper) {
        this.nativeElement = this.elementRef.nativeElement;
        this.loadContentType();
    }

    public ngAfterViewInit(): void {
        this.isInline = this.getParentSectionCount() >= 3;

        if (!this.isInline) {
            this.iconBox.classModifiers.push(IconBoxClassEnum.BorderNoRadius);
            this.classModifiers.push(ContentMarkClassEnum.SectionToInline);
        } else {
            this.classModifiers.push(ContentMarkClassEnum.Inline);
        }

        const nextSibling: HTMLElement = this.originalContent.nativeElement.nextSibling;

        // Backwards compatibility to support the older H4 titles
        if (nextSibling.tagName === 'H4') {
            const siblingAsH3 = document.createElement('h3');
            siblingAsH3.id = nextSibling.id;
            siblingAsH3.className = nextSibling.className;
            siblingAsH3.innerHTML = nextSibling.innerHTML;

            nextSibling.replaceWith(siblingAsH3);
        }
    }

    protected getParentSectionCount(): number {
        const sections = this.domHelper.findParentsWithClass(this.nativeElement, DocumentHostClassesEnum.Section, DocumentHostClassesEnum.Document);

        return sections.length;
    }

    protected loadContentType(): void {
        this.contentType = <DocumentContentMarkTypeEnum>this.nativeElement.className;
        this.nativeElement.dataset['type'] = this.contentType;

        switch (this.contentType) {
            case DocumentContentMarkTypeEnum.Teacher:
                this.iconBox.value = IconEnum.TeacherInfo;
                break;

            case DocumentContentMarkTypeEnum.Remark:
                this.iconBox.value = IconEnum.Warning;
                break;

            case DocumentContentMarkTypeEnum.Mark:
                this.iconBox.value = IconEnum.Info;
                break;

            case DocumentContentMarkTypeEnum.Tip:
                this.iconBox.value = IconEnum.Flag;
                break;

            case DocumentContentMarkTypeEnum.Quotation:
                this.iconBox.value = IconEnum.Article;
                break;

            case DocumentContentMarkTypeEnum.Citation:
                this.iconBox.value = IconEnum.QuotesLeft;
                break;

            case DocumentContentMarkTypeEnum.Case:
                this.iconBox.value = IconEnum.Case;
                break;

            case DocumentContentMarkTypeEnum.Law:
                this.iconBox.value = IconEnum.Law;
                break;
        }
    }
}
