import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiService} from 'core/services/api/api.service';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {AttachmentSourceInterface} from 'shared/modals/source/interfaces/source.interface';

@Injectable()
export class SourceService {

    constructor(
        private apiService: ApiService,
    ) {
    }

    public create(data: AttachmentSourceInterface): Observable<ApiSourceInterface> {
        return this.apiService.post<ApiSourceInterface>(ApiEndpointEnum.SourceAdd, data);
    }

    public remove(sourceId: string): Observable<any> {
        return this.apiService.post<ApiSourceInterface>(ApiEndpointEnum.SourceRemove, null, null, new Map([
            ['sourceId', sourceId],
        ]));
    }

    public edit(sourceId: string, data: AttachmentSourceInterface): Observable<ApiSourceInterface> {
        return this.apiService.post<ApiSourceInterface>(ApiEndpointEnum.SourceEdit, data, null, new Map([
            ['sourceId', sourceId],
        ]));
    }
}
