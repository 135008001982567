import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {AvatarClassesEnum} from 'shared/components/avatar/enums/avatar-classes.enum';
import {HorizontalBarIconClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-icon-classes.enum';

@Component({
    selector: 'app-exams-exam-switch-student',
    templateUrl: 'exam-switch-student.component.html',
})
export class ExamsExamSwitchStudentComponent implements OnChanges {

    public readonly rowClasses = GridTableRowClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly horizontalBarIconClasses = HorizontalBarIconClassesEnum;

    public readonly avatarClasses = AvatarClassesEnum;

    public readonly iconEnum = IconEnum;

    @Input()
    public examPlan: ApiExamPlanDetailsInterface;

    @Input()
    public studentResult: ApiExamUserResultInterface;

    @Output()
    public studentResultChange: EventEmitter<ApiExamUserResultInterface> = new EventEmitter();

    public userIndex: number;

    public totalUsers: number;

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.examPlan || !this.studentResult) {
            return;
        }

        this.totalUsers = this.examPlan.results.length;

        for (let i = 0; i < this.examPlan.results.length; i++) {
            if (this.examPlan.results[i].planUser.user.id === this.studentResult.planUser.user.id) {
                this.userIndex = i;
            }
        }
    }

    public navigatePrevious(): void {
        if (this.userIndex === 0) {
            return;
        }

        this.studentResult = this.examPlan.results[this.userIndex - 1];
        this.studentResultChange.emit(this.studentResult);
    }

    public navigateNext(): void {
        if (this.userIndex === (this.totalUsers - 1)) {
            return;
        }

        this.studentResult = this.examPlan.results[this.userIndex + 1];
        this.studentResultChange.emit(this.studentResult);
    }
}
