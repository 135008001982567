import {Injectable} from '@angular/core';
import {RoleEnum} from 'enums/role.enum';
import {RoleService} from 'security/services/role/role.service';
import {RolesMapping} from 'security/mapping/roles.mapping';

@Injectable()
export class AuthorizationService {

    private rolesMapping = RolesMapping;

    constructor(
        private roleService: RoleService
    ) {
    }

    /**
     * Checks if user has role or inherits role, returns true on first match
     */
    public isGranted(role: RoleEnum): boolean {
        return this.roleService.getUserRoles().some((userRole: RoleEnum) => {
            return this.isRoleGranted(role, userRole);
        });
    }

    /**
     * Check if role is inherited by user's role or if user's inherited roles
     */
    protected isRoleGranted(role: RoleEnum, userRole: RoleEnum): boolean {
        const roleMapping: Array<RoleEnum> = this.rolesMapping.get(userRole);
        const hasRole = this.roleService.getUserRoles().includes(role);

        return hasRole || roleMapping !== undefined && roleMapping.some((mappedRole: RoleEnum): boolean => {
            return mappedRole === role || this.isRoleGranted(role, mappedRole);
        });
    }
}
