export enum HeadingClassesEnum {

    H1 = 'heading--h1',

    H2 = 'heading--h2',

    H3 = 'heading--h3',

    H4 = 'heading--h4',

    H5 = 'heading--h5',

    H6 = 'heading--h6',

    MarginBottom = 'heading--margin-bottom',

    MarginTopNone = 'heading--margin-top-none',

    Absolute = 'heading--absolute',

    Fixed = 'heading--fixed',

    Document = 'heading--document',

    DocumentHasBookTitle = 'heading--document-has-book-title',

    ReadSpeakerActive = 'heading--read-speaker-active',

    ReadSpeakerPreserve = 'rs_preserve', // All rs_ elements should remain intact

    BootstrapGutter = 'heading--bootstrap-gutter',

}
