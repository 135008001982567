import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthenticationService} from 'security/services/authentication/authentication.service';
import {PublisherService} from 'services/publisher/publisher.service';

@Injectable({
    providedIn: 'root',
})
export class RouterAuthenticationGuard implements CanActivate {

    constructor(
        private authenticationService: AuthenticationService,
        private publisherService: PublisherService,
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (!this.authenticationService.authenticated) {
            const publisherSlug = this.publisherService.getFromState(state);

            this.authenticationService.authenticate(publisherSlug);
        }

        return this.authenticationService.isAuthenticated;
    }
}
