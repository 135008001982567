import {TabItemInterface} from 'shared/modules/tab/interfaces/tab-item.interface';
import {IconEnum} from 'enums/icon.enum';

export class TeacherMaterialTabModel implements TabItemInterface {

    public classModifiers: Array<string> = [];

    constructor(public title: string, public active: boolean = false, public icon: IconEnum = null) {
    }
}
