import {Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import {RoleEnum} from 'enums/role.enum';
import {ProgressService} from 'services/progress/progress.service';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {IconEnum} from 'enums/icon.enum';
import {ProgressConstantsEnum} from 'pages/modules/progress/enums/constants.enum';
import {UserService} from 'security/services/user/user.service';
import {ProgressChapterInterface} from 'pages/modules/progress/interfaces/chapter.interface';
import {ProgressStatusFilterEnum} from 'shared/components/status-filter/enum/progress-status-filter.enum';
import {ProgressGroupFactory} from 'pages/modules/progress/factories/group.factory';
import {ProgressGroupInterface} from 'pages/modules/progress/interfaces/group.interface';
import {ProgressStudentFactory} from 'pages/modules/progress/factories/student.factory';
import {ProgressStudentInterface} from 'pages/modules/progress/interfaces/student.interface';
import {ProgressStudyMaterialInterface} from 'pages/modules/progress/interfaces/study-material.interface';
import {ProgressStudyMaterialFactory} from 'pages/modules/progress/factories/study-material.factory';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';

@Component({
    selector: 'app-progress-chapter-result',
    templateUrl: 'chapter-result.component.html',
})
export class ProgressChapterResultComponent implements OnChanges {

    public static readonly CLASS = 'progress-student-details';

    public readonly columnClasses = GridTableColClassEnum;

    public readonly tableClasses = GridTableClassEnum;

    public readonly constantsEnum = ProgressConstantsEnum;

    public readonly iconEnum = IconEnum;

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    @HostBinding('class')
    protected classes: string = ProgressChapterResultComponent.CLASS;

    @Input()
    public classHelper: ClassHelper;

    @Input()
    public search: string;

    @Input()
    public chapter: ProgressChapterInterface;

    @Input()
    public group: ApiGroupInterface;

    @Input()
    public user: ProgressStudentInterface;

    @Input()
    public filter: ProgressStatusFilterEnum;

    public student: ProgressStudentInterface;

    public progress: ApiProgressInterface;

    public progressGroups: Array<ProgressGroupInterface> = [];

    public studentMaterial: ProgressStudyMaterialInterface;

    public expanded: boolean = false;

    public isLoading: boolean = false;

    public hasResults: boolean = false;

    public isNumeric: boolean = false;

    public assignmentsStatus: Array<ApiProgressAverageAssignmentStatusInterface>;

    private progressForStudent: ApiProgressForStudentInterface;

    constructor(
        private progressService: ProgressService,
        private authorizationService: AuthorizationService,
        private groupFactory: ProgressGroupFactory,
        private studentFactory: ProgressStudentFactory,
        private studyMaterialFactory: ProgressStudyMaterialFactory,
        private userService: UserService,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.expanded && changes.expanded.currentValue) {
            this.loadData();
        }
    }

    public handleOnExpand(): void {
        this.progress = null;
        this.expanded = true;
        this.loadData();
    }

    public handleOnCollapse(): void {
        this.expanded = false;
    }

    private loadData(): void {
        this.isLoading = true;
        let prom = null;

        let groupId = null;
        if (this.group) {
            groupId = Number(this.group.id);
        }

        if (this.authorizationService.isGranted(RoleEnum.RoleTeacher)) {
            let studentId = null;
            if (this.user) {
                studentId = Number(this.user.id);
            }

            prom = this.progressService.retrieveForTeacher(this.chapter.book.id, this.chapter.id, groupId, studentId, this.search).toPromise();
        } else {
            this.user = this.studentFactory.fromApiUserData(this.userService.getUserData());

            prom = this.progressService.retrieveForStudent(this.chapter.book.id, groupId, this.chapter.id, this.search).toPromise();
        }

        prom.then((progress: Array<ApiProgressInterface | ApiProgressForStudentInterface>) => {
            return this.loadProgress(progress);
        }).then(() => {
            this.hasResults = 0 !== this.progressGroups.length || undefined !== this.studentMaterial;
        }).finally(() => {
            this.isLoading = false;
        }).catch(error => {
            throw new Error(error);
        });
    }

    private loadProgressForStudent(data: ApiProgressForStudentInterface): void {
        this.progressForStudent = data;
        this.studentMaterial = this.studyMaterialFactory.fromApiProgressForStudent(this.progressForStudent);
    }

    private loadProgressForTeacher(data: ApiProgressInterface): void {
        this.progress = data;
        this.progressGroups = this.groupFactory.fromApiProgress(this.progress);

        for (const group of this.progressGroups) {
            if (group.id === this.group.id) {
                for (const student of group.students) {
                    if (this.user && this.user.id === student.id) {
                        this.student = student;
                    }
                }
            }
        }
    }

    public getProgressGroup(): ProgressGroupInterface {
        if (this.group) {
            return {
                id: this.group.id,
                name: this.group.name,
                students: []
            };
        }

        return null;
    }

    private loadProgress(progress: Array<ApiProgressInterface | ApiProgressForStudentInterface>): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                const data: ApiProgressInterface | ApiProgressForStudentInterface = progress[0];
                const assignmentStatus = this.assignmentsStatus = this.getAssignmentStatus(progress);

                if (assignmentStatus.length > 0) {
                    this.assignmentsStatusIsNumeric();
                }

                if (this.isApiProgressTeacher(data)) {
                    this.loadProgressForTeacher(data);
                }

                if (this.isApiProgressForStudent(data)) {
                    this.loadProgressForStudent(data);
                }

                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    private getAssignmentStatus(progress: Array<ApiProgressInterface> | ApiProgressForStudentInterface): Array<ApiProgressAverageAssignmentStatusInterface> {
        if (undefined !== progress[0].groups) {
            return progress[0].groups[0].avg[0].assignments_status;
        }

        if (undefined !== progress[0].studymaterials[0].scores[0].assignments_status) {
            return progress[0].studymaterials[0].scores[0].assignments_status;
        }

        return [];
    }

    private assignmentsStatusIsNumeric(): boolean {
        for (const assignment of this.assignmentsStatus) {
            if (assignment.is_numeric === false) {
                continue;
            }

            return this.isNumeric = true;
        }
    }

    private isApiProgressTeacher(data: ApiProgressInterface | ApiProgressForStudentInterface): data is ApiProgressInterface {
        return (<ApiProgressInterface>data).groups !== undefined;
    }

    private isApiProgressForStudent(data: ApiProgressInterface | ApiProgressForStudentInterface): data is ApiProgressForStudentInterface {
        return (<ApiProgressForStudentInterface>data).studymaterials !== undefined;
    }
}
