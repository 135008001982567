import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';

export class IconBoxBookModel implements IconBoxInterface {

    public type: IconBoxTypeEnum = IconBoxTypeEnum.Icon;

    public value: IconEnum = IconEnum.BookClose;

    public classModifiers: Array<IconBoxClassEnum> = [
        IconBoxClassEnum.TocBook,
    ];

}
