import {Component, Input} from '@angular/core';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import {IconBoxClose} from 'models/iconbox/close.model';

@Component({
    selector: 'app-popover-title',
    templateUrl: 'popover-title.component.html',
})
export class PopoverTitleComponent {

    public readonly iconBoxClasses = IconBoxClassEnum;

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorBlue]);

    @Input()
    public title: string;

    @Input()
    public popoverTemplate: NgbPopover;

}
