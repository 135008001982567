import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {Observable, Observer} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';

@Injectable()
export class BookService {

    private books: Array<ApiBookInterface> = [];

    private apiBooks: ApiBooksInterface;

    constructor(private apiRequestService: ApiService) {
    }

    public getBooks(): Array<ApiBookInterface> {
        return this.books;
    }

    public retrieveBooks(
        offset: string | null,
        limit: string | null,
        search: string | null,
        includeChapters: boolean = false,
        useCache: boolean = false,
    ): Observable<ApiBooksInterface> {
        return new Observable((observer: Observer<ApiBooksInterface>) => {
            if (useCache && this.apiBooks) {
                observer.next(this.apiBooks);
                observer.complete();
            } else {
                this.apiRequestService.get<ApiBooksInterface>(ApiEndpointEnum.EducationBooks, new URLSearchParams([
                    ['offset', offset],
                    ['limit', limit],
                    ['search', search],
                    ['include_chapters', (includeChapters ? 1 : 0).toString()],
                ])).subscribe((response: ApiBooksInterface) => {
                    this.apiBooks = useCache ? response : null; // Update cache
                    observer.next(response);
                }, (error: any) => observer.error(error), () => observer.complete());
            }
        });
    }

    public retrieveAllBooks(search: string = '', includedemo: boolean = true): Observable<ApiBooksInterface> {
        return new Observable((observer: Observer<ApiBooksInterface>) => {
            if (this.apiBooks) {
                observer.next(this.apiBooks);
                observer.complete();
            } else {
                this.apiRequestService.get<ApiBooksInterface>(ApiEndpointEnum.EducationBooks, new URLSearchParams([
                    ['offset', '0'],
                    ['limit', '-1'],
                    ['search', search],
                    ['include_chapters', '0'],
                    ['include_demo', includedemo ? '1' : '0']
                ])).subscribe((response: ApiBooksInterface) => {
                        this.apiBooks = response;
                        observer.next(response);
                    }, (error: any) => observer.error(error),
                    () => observer.complete()
                );
            }
        });
    }

    public retrieveActiveBooks(): Observable<Array<ApiBookInterface>> {
        const obs = this.apiRequestService
            .get<Array<ApiBookInterface>>(
                ApiEndpointEnum.EducationMyBooks,
                null,
                new Map([
                    ['schoolYearId', '0'],
                ]),
            );

        obs.subscribe((books: Array<ApiBookInterface>) => {
            this.books = books;
        });

        return obs;
    }

    public toggleFavorite(bookId: string): Observable<ApiBookInterface> {
        return this.apiRequestService
            .post<ApiBookInterface>(
                ApiEndpointEnum.EducationBooksFavouriteToggle,
                null,
                null,
                new Map([
                    ['book', bookId],
                ]),
            );
    }

    public getBook(bookId: string): Observable<ApiBookInterface> {
        const apiEndpoint: string = ApiEndpointEnum.Book.replace(':bookId', bookId);

        return this.apiRequestService.get<ApiBookInterface>(apiEndpoint);
    }
}
