import {Injectable} from '@angular/core';
import {CollectionBrowseModel} from 'core/models/collection-browse.model';
import {CollectionBrowseInterface} from 'interfaces/collection-browse.interface';

@Injectable()
export class CollectionBrowseFactory {

    public create(currentIndex: number, collectionSize: number): CollectionBrowseInterface {
        return new CollectionBrowseModel(currentIndex, collectionSize);
    }
}
