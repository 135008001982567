import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {WorkTypeEnum} from 'shared/components/work-type-label/enums/work-type.enum';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';
import {WorkTypeClassesEnum} from 'shared/components/work-type-label/enums/work-type-classes.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {ProgressAssignmentStatusInterface} from 'pages/modules/progress/interfaces/assignment-status.interface';
import {ProgressDrillsterStatusInterface} from 'pages/modules/progress/interfaces/drillster-status.interface';
import {ProgressStudentInterface} from 'pages/modules/progress/interfaces/student.interface';
import {ClassHelper} from 'helpers/dom/class.helper';
import {ProgressStatusEnum} from 'enums/progress-status.enum';

@Component({
    selector: 'app-progress-assignment-number',
    templateUrl: 'assignment-number.component.html',
})
export class ProgressAssignmentNumberComponent implements OnChanges {

    public readonly columnClasses = GridTableColClassEnum;

    public readonly workTypeEnum = WorkTypeEnum;

    public readonly workTypeClasses = WorkTypeClassesEnum;

    public readonly examinationNeededEnum = ExamineOptionsEnum;

    @Input()
    public classHelper: ClassHelper;

    @Input()
    public bookId: number;

    @Input()
    public status: ProgressAssignmentStatusInterface = null;

    @Input()
    public drillster: ProgressDrillsterStatusInterface = null;

    @Input()
    public students: Array<ProgressStudentInterface> = null;

    @Input()
    public popoverEnabled: boolean = false;

    @Input()
    public hidePopover: boolean = false;

    public ngOnChanges(changes: SimpleChanges): void {
        this.popoverEnabled = !(this.status === null || (this.status.taskAssignedCount > 0 && this.students === null) || this.status.progressStatus > 0);
    }

    public getStudentsHandedIn(): Array<ApiUserInterface>|null {
        if (this.students === null) {
            return null;
        }

        const students = [];

        for (const student of this.students) {
            for (const assignmentStatus of student.assignmentStatuses) {
                if (assignmentStatus.assignmentId === this.status.assignmentId &&
                    [ProgressStatusEnum.ProgressStatusChecked, ProgressStatusEnum.ProgressStatusHandedIn].includes(assignmentStatus.progressStatus)
                ) {
                    students.push(student);
                }
            }
        }

        return students;
    }

    public getStudentsNotHandedIn(): Array<ApiUserInterface>|null {
        if (this.students === null) {
            return null;
        }

        const students = [];

        for (const student of this.students) {
            for (const assignmentStatus of student.assignmentStatuses) {
                if (assignmentStatus.assignmentId === this.status.assignmentId &&
                    [ProgressStatusEnum.ProgressStatusTaskAssigned].includes(assignmentStatus.progressStatus)
                ) {
                    students.push(student);
                }
            }
        }

        return students;
    }
}
