export enum AnalyticsDimensionEnum {

    UserRole = 1,

    UserOrganisation = 3,

    AssignmentResourceTitle = 4,

    AssignmentTitle = 5,

    MethodTitle = 6,

    BookTitle = 7,

    PublisherSlug = 8,

    DeeplinkEan = 9,

    DeeplinkDpsid = 10,

    DeeplinkBookId = 11,

}
