import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TabItemInterface} from 'shared/modules/tab/interfaces/tab-item.interface';

@Component({
    selector: 'app-tab',
    templateUrl: 'tab.component.html'
})
export class TabComponent implements OnInit {

    @Input()
    public tabItems: Array<TabItemInterface> = [];

    @Output()
    public tabChange: EventEmitter<TabItemInterface> = new EventEmitter();

    private activeTab: TabItemInterface;

    public setActiveTab(tabItem: TabItemInterface): void {
        this.activeTab.active = false; // Set previous tab active state

        tabItem.active = true;
        this.activeTab = tabItem; // Switch active tab
        this.tabChange.emit(tabItem);
    }

    public ngOnInit(): void {
        this.activeTab = this.tabItems.find(tabItem => tabItem.active === true);
    }
}
