import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ContentSwitchGroupModel} from 'pages/modules/tasks/components/modals/add-modal/models/group-model';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {ContentSwitchStudentModel} from 'pages/modules/tasks/components/modals/add-modal/models/student-model';
import {IconEnum} from 'enums/icon.enum';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';

@Component({
    selector: 'app-user-selection-personal',
    templateUrl: 'personal.component.html',
})
export class UserSelectionPersonalComponent implements OnChanges {

    public readonly iconBoxEnum = IconBoxClassEnum;

    @Input()
    public groups: Array<ContentSwitchGroupModel> = [];

    @Output()
    public groupsChange: EventEmitter<Array<ContentSwitchGroupModel>> = new EventEmitter();

    @Input()
    public disabled: boolean = false;

    @Input()
    public clickOutSide: boolean = true;

    public groupsClassModifiers: Array<ContentSelectClassModifiersEnum> = [
        ContentSelectClassModifiersEnum.GroupStudents,
        ContentSelectClassModifiersEnum.Grey,
    ];

    public selectGroupsAndStudents: Array<ContentSwitchGroupModel | ContentSwitchStudentModel> = [];

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.groups && changes.groups.currentValue) {
            this.onGroupStudentChange();
        }
    }

    public onGroupStudentChange(): void {
        this.selectGroupsAndStudents = [];

        for (const group of this.groups) {
            if (group.active === true) {
                this.selectGroupsAndStudents.push(group);
                continue;
            }

            for (const student of group.children) {
                if (student.active) {
                    this.selectGroupsAndStudents.push(student);
                }
            }
        }

        this.groupsChange.emit(this.groups);
    }

    public getGroupStudentIcon(item: ContentSwitchGroupModel | ContentSwitchStudentModel): string {
        return item instanceof ContentSwitchGroupModel ? IconEnum.Users : IconEnum.User;
    }
}
