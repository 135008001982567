import {Injectable} from '@angular/core';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {FeedRssInterface} from 'document/modules/interfaces/feed-rss.interface';
import {ApiService} from 'core/services/api/api.service';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable()
export class FeedRssService {

    protected rssFeed: FeedRssInterface;

    protected rssFeedStudyMaterialMethodId: string;

    protected readonly rssFeedSubject: BehaviorSubject<FeedRssInterface> = new BehaviorSubject(undefined);

    constructor(private api: ApiService) {
    }

    public subscribeToRssFeed(fn: (rssFeed: FeedRssInterface) => void): Subscription {
        return this.rssFeedSubject.subscribe(fn);
    }

    public loadRssFeedByStudyMaterialMethodId(rssFeedStudyMaterialMethodId: string): Promise<void> {
        if (rssFeedStudyMaterialMethodId === this.rssFeedStudyMaterialMethodId && undefined !== this.rssFeed) {
            return Promise.resolve();
        }

        this.rssFeedStudyMaterialMethodId = rssFeedStudyMaterialMethodId;
        const apiEndpoint: string = ApiEndpointEnum.FeedRssGet.replace(':studyMaterialMethodId', rssFeedStudyMaterialMethodId);

        return this.api.get<FeedRssInterface>(apiEndpoint).toPromise().then(rssFeed => this.setRssFeed(rssFeed));
    }

    public setRssFeed(rssFeed: FeedRssInterface): void {
        this.rssFeed = rssFeed;
        this.rssFeedSubject.next(rssFeed);
    }
}
