import {Component, ElementRef, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IconEnum} from 'enums/icon.enum';
import {FilterDropdownClassEnum} from 'shared/enums/filter-dropdown/class.enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {GroupsModuleGroupAddModeEnum} from 'pages/modules/groups/enums/group-add-mode.enum';
import {GroupsService} from 'services/groups/groups.service';
import {SchoolService} from 'services/school/school.service';
import {Subscription} from 'rxjs';
import {GroupsModuleGroupModelFactory} from 'pages/modules/groups/factories/group-model.factory';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {finalize} from 'rxjs/operators';
import {SearchInterface} from 'shared/modules/search/interfaces/search.interface';
import {SearchModeEnum} from 'shared/modules/search/enums/search-mode.enum';
import {UserService} from 'security/services/user/user.service';
import {GroupsModuleGroupInterface} from 'pages/modules/groups/interfaces/group.interface';
import {GroupsModuleGroupModeEnum} from 'pages/modules/groups/enums/group-mode.enum';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {GroupYearFilterEnum} from 'pages/modules/groups/enums/group-year-filter.enum';
import {GroupsModuleGroupsYearModel} from 'pages/modules/groups/models/groups-year.model';
import {IconBoxClose} from 'models/iconbox/close.model';
import {ProgressBarClassModifiersEnum} from 'shared/components/progress-bar/enums/progress-bar-class-modifiers.enum';

@Component({
    selector: 'app-groups-add-group-modal-component',
    templateUrl: 'add-group.component.html',
})
export class GroupsAddGroupModalComponent implements OnInit, OnDestroy {

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly filterDropClasses = FilterDropdownClassEnum;

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorGreen]);

    public readonly progressBarClassEnum = ProgressBarClassModifiersEnum;

    public groupEvent: EventEmitter<GroupsModuleGroupInterface> = new EventEmitter();

    public title: string = 'Groep toevoegen';

    public groupMode: GroupsModuleGroupModeEnum = GroupsModuleGroupModeEnum.Compact;

    public filters: GroupsModuleGroupsYearModel = new GroupsModuleGroupsYearModel();

    public groups: Array<GroupsModuleGroupInterface> = [];

    public groupsVisible: Array<GroupsModuleGroupInterface> = [];

    public groupName?: string = null;

    public groupYear?: string = null;

    public selectedGroup?: GroupsModuleGroupInterface = null;

    public selectedGroups: Array<number> = [];

    public isLoading: boolean = false;

    public countAddTeacher: number = 0;

    public setProgressBar: boolean = false;

    public groupProgress: number = 0;

    public progressTitle: string = 'Groep(en) worden toegevoegd..';

    public addTaskFinished: boolean = false;

    public search: SearchInterface = {
        prefixIcon: IconEnum.Users,
        placeHolder: 'Geef een groepsnaam op',
        mode: SearchModeEnum.Instant,
    };

    private mode: GroupsModuleGroupAddModeEnum;

    private requestSubscription: Subscription;

    public countAddGroup: number = 0;

    constructor(
        private elementRef: ElementRef,
        private activeModal: NgbActiveModal,
        private schoolService: SchoolService,
        private groupService: GroupsService,
        private userService: UserService,
        private groupsModelFactory: GroupsModuleGroupModelFactory,
        private toastService: ToastrService,
    ) {
    }

    public ngOnInit(): void {
        this.fetchGroups();
    }

    public ngOnDestroy(): void {
        if (this.requestSubscription instanceof Subscription) {
            this.requestSubscription.unsubscribe();
        }
    }

    public close(): void {
        this.activeModal.dismiss();
    }

    public handleCheckedEvent(checkedGroup: GroupsModuleGroupInterface): void {
        this.selectedGroup = checkedGroup;
        this.groupName = null;
        this.mode = GroupsModuleGroupAddModeEnum.Existing;
        this.selectedGroups = [];

        for (const group of this.groups) {
            if (group.checked === true) {
                this.selectedGroups.push(group.id);
            }
        }
    }

    public handleInput(value: string): void {
        this.mode = GroupsModuleGroupAddModeEnum.Input;
        this.selectedGroup = null;
        this.groupName = value;

        // Filter groups
        this.filterGroups();
    }

    public handleAdd(): void {
        if (!this.isValid()) {
            return;
        }

        switch (this.mode) {
            case GroupsModuleGroupAddModeEnum.Existing:
                this.joinExistingGroup();
                break;

            case GroupsModuleGroupAddModeEnum.Input:
                this.createGroup();
                break;
        }
    }

    public handleFilterChange(groupYear: any): void {
        this.groupYear = groupYear.selectedValue;
    }

    private isValid(): boolean {
        return (this.groupName !== undefined && this.groupName !== null && this.groupName.length !== 0 && this.groupYear !== GroupYearFilterEnum.All) || this.selectedGroup !== null;
    }

    private joinExistingGroup(): void {

        if (this.selectedGroups.length < 1) {
            return;
        }

        this.isLoading = true;
        this.setProgressBar = true;

        for (const groupId of this.selectedGroups) {
            this.addTeacherToGroup(groupId);
        }
    }

    private addTeacherToGroup(groupId: number): void {
        this.countAddGroup++;
        const isFinished = this.selectedGroups.length === this.countAddGroup;

        this.groupService.addTeacher(this.userService.getCurrentUserId(), groupId, isFinished)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((response: ApiGroupInterface) => {
                this.groupEvent.emit(this.groupsModelFactory.fromApiGroup(response));
                this.toastService.success('Je bent toegevoegd aan de groep.');

                this.increaseProgress(100 / this.selectedGroups.length);

            }, (errorResponse: HttpErrorResponse) => {
                this.toastService.error('Groep toevoegen mislukt');
                this.increaseProgress(100 / this.selectedGroups.length);
            });
    }

    private increaseProgress(taskPercentage: number): void {
        this.countAddTeacher++;

        if (this.groupProgress !== 100) {
            this.groupProgress += taskPercentage;
        }

        if (this.selectedGroups.length === this.countAddTeacher) {
            this.addTaskFinished = true;
            setTimeout(() => {
                this.close();
            }, 1000);
        }
    }

    private filterGroups(): void {
        if (this.isValid()) {
            this.groupsVisible = this.groups.filter((group) => {
                return new RegExp(`${this.groupName}`, 'i').exec(group.name);
            });
        } else {
            this.groupsVisible = [];
        }
    }

    private createGroup(): void {
        if (!this.groupName || !this.groupYear || this.groupYear === GroupYearFilterEnum.All) {
            this.toastService.warning('Controleer groepsnaam en het geselecteerde jaar.');

            return;
        }

        this.isLoading = true;
        this.requestSubscription = this.groupService.createGroup(this.groupName, this.schoolService.getSelectedSchoolYear().id, this.groupYear)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((apiGroup: ApiGroupInterface) => {
                this.groupEvent.emit(this.groupsModelFactory.fromApiGroup(apiGroup));
                this.toastService.success('Een nieuwe groep is toegevoegd.');
                this.close();
            }, (errorResponse: HttpErrorResponse) => {
                this.toastService.error('Groep toevoegen mislukt.');
            });
    }

    private fetchGroups(): void {
        this.isLoading = true;
        this.requestSubscription = this.groupService.retrieveOrganisationGroups(0, this.groupName)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((groups: Array<ApiGroupInterface>) => {
                const userId: number = this.userService.getCurrentUserId();
                const filteredGroups = groups.filter((group) => group.students.filter((member) => member.id === userId).length === 0);

                this.groups = this.groupsModelFactory.fromApiGroupArray(filteredGroups);
            }, (errorResponse: HttpErrorResponse) => {
                this.toastService.error('Fout bij het zoeken naar groepen.');
            });
    }
}
