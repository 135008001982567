import {Component, OnDestroy, OnInit} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {SearchInterface} from 'shared/modules/search/interfaces/search.interface';
import {FormClassModifiersEnum} from 'enums/form-class-modifiers.enum';
import {AvatarClassesEnum} from 'shared/components/avatar/enums/avatar-classes.enum';
import {UserService} from 'security/services/user/user.service';
import {PublisherService} from 'services/publisher/publisher.service';
import {PublisherDataInterface} from 'services/publisher/interfaces/data.interface';
import {RoleEnum} from 'enums/role.enum';
import {IconBoxBell} from 'models/iconbox/bell.model';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {Subscription} from 'rxjs';
import {SearchService} from 'services/search/search.service';
import {MessageService} from 'services/message/message.service';
import {IconBoxQuestion} from 'models/iconbox/question.model';
import {ModalService} from 'core/services/modal/modal.service';
import {HelpPageService} from 'services/help-page/help-page.service';
import {Router} from '@angular/router';
import {environment} from 'environments/environment';
import {IconBoxInfo} from 'models/iconbox/info.model';

@Component({
    selector: 'app-header-component',
    templateUrl: 'header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

    public readonly roleEnum = RoleEnum;

    public readonly avatarClassesEnum = AvatarClassesEnum;

    public readonly routesEnum = RoutesEnum;

    public archiveModeActive = environment.archiveModeActive;

    public readonly messageCenter = new IconBoxBell([
        IconBoxClassEnum.Header,
        IconBoxClassEnum.MessageCenter,
    ]);

    public readonly question = new IconBoxQuestion([
        IconBoxClassEnum.Header,
        IconBoxClassEnum.Questions,
    ]);

    public readonly info = new IconBoxInfo([
        IconBoxClassEnum.Header,
        IconBoxClassEnum.InfoSolid,
    ]);

    public readonly search: SearchInterface = {
        affixIcon: IconEnum.Search,
        placeHolder: 'Zoeken',
        classModifiers: [
            FormClassModifiersEnum.InlineHeader,
        ],
    };

    public publisher: PublisherDataInterface;

    private publisherSubscription: Subscription;

    private notificationChangeSubscription: Subscription;

    public helpPageEnabled: boolean = environment.features.helpPageEnabled;

    constructor(
        private userService: UserService,
        private publisherService: PublisherService,
        private searchService: SearchService,
        private messageService: MessageService,
        private modalService: ModalService,
        private firstTimeService: HelpPageService,
        private router: Router,
    ) {
    }

    public ngOnInit(): void {
        this.publisherSubscription = this.publisherService.onPublisherChange.subscribe(() => this.setPublisher());
        this.notificationChangeSubscription = this.messageService.unreadCountChange.subscribe(() => this.setNotificationCount());

        this.setPublisher();
        this.setNotificationCount();
    }

    public ngOnDestroy(): void {
        if (this.publisherSubscription instanceof Subscription) {
            this.publisherSubscription.unsubscribe();
        }

        if (this.notificationChangeSubscription instanceof Subscription) {
            this.notificationChangeSubscription.unsubscribe();
        }
    }

    public handleSearch(search: string): void {
        this.searchService.globalSearch(search);
    }

    private setPublisher(): void {
        this.publisher = this.publisherService.getActivePublisher();
    }

    private setNotificationCount(): void {
        this.messageCenter.notificationCount = this.messageService.getUnreadCount();
    }

    public searchEnabled(): boolean {
        return environment.features.searchEnabled;
    }

    public openCurrentHelpPage(): void {
        if (!environment.features.helpPageEnabled) {
            return;
        }

        switch (true) {
            case this.router.url.includes(RoutesEnum.Books):
                this.firstTimeService.openHelpPage(RoutesEnum.Books, true);
                break;
            case this.router.url.includes(RoutesEnum.Tasks):
                this.firstTimeService.openHelpPage(RoutesEnum.Tasks, true);
                break;
            case this.router.url.includes(RoutesEnum.Exams):
                this.firstTimeService.openHelpPage(RoutesEnum.Exams, true);
                break;
            case this.router.url.includes(RoutesEnum.Progress):
                this.firstTimeService.openHelpPage(RoutesEnum.Progress, true);
                break;
            case this.router.url.includes(RoutesEnum.Groups):
                this.firstTimeService.openHelpPage(RoutesEnum.Groups, true);
                break;
        }
    }
}
