export enum SearchEntityTypesEnum {

    Document = 'resource',

    StudyMaterial = 'studymaterial',

    User = 'person',

    Note = 'note',

    Task = 'task',

}
