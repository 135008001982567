import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {Observable} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';


@Injectable()
export class DemoBarService {

    constructor(
        private api: ApiService,
    ) {
    }

    public requestTeacherLicense(bookId: string): Observable<any> {
        return this.api.post(
            ApiEndpointEnum.LicenseRequest,
            {
                book_id: bookId,
            },
            null,
            null,
        );
    }
}
