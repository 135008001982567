import {AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, Output, Renderer2, SimpleChanges} from '@angular/core';
import {ModalService} from 'core/services/modal/modal.service';

@Component({
    selector: 'app-document-notes-add-button',
    templateUrl: 'add-button.component.html',
})
export class DocumentNotesAddButtonComponent implements AfterViewInit, OnChanges {

    @HostBinding('class')
    private className: string = 'document-notes-button document-notes-button--add';

    @Input()
    public topOffset: number = null;

    @Input()
    public studyMaterialTitle: string = null;

    @Input()
    public documentDpsid: string = null;

    @Input()
    public elementId: string = null;

    @Output()
    public clickEvent: EventEmitter<any> = new EventEmitter();

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private modalService: ModalService,
    ) {}

    public ngAfterViewInit(): void {
        this.adjustTopOffset();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.adjustTopOffset();
    }

    private adjustTopOffset(): void {
        if (this.topOffset === null) {
            return;
        }

        this.renderer.setStyle(this.elementRef.nativeElement, 'top', `${this.topOffset}px`);
    }

    public onAddToSummary(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this.modalService.noteCreate(this.studyMaterialTitle, this.documentDpsid, this.elementId);
        this.clickEvent.emit();
    }
}
