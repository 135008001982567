import {EventEmitter, Injectable} from '@angular/core';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {ApiService} from 'core/services/api/api.service';
import {RoleEnum} from 'enums/role.enum';
import {Observable, Observer} from 'rxjs';
import {AnalyticsService} from 'services/analytics/analytics.service';
import * as Sentry from '@sentry/browser';
import {ToastrService} from 'ngx-toastr';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {ApiUserDataInterface} from 'interfaces/api/user-data.interface';

@Injectable()
export class UserService {

    protected userData: ApiUserDataInterface = null;

    public onUserDataChange: EventEmitter<ApiUserDataInterface> = new EventEmitter<ApiUserDataInterface>();

    constructor(
        private apiService: ApiService,
        private analyticsService: AnalyticsService,
        private toastService: ToastrService,
    ) {
    }

    public retrieve(publisher?: string): Observable<ApiUserDataInterface> {
        return new Observable<ApiUserDataInterface>((observer: Observer<ApiUserDataInterface>) => {
            const params = new URLSearchParams();
            params.append('newLogin', 'true');

            let url = ApiEndpointEnum.UserLogin;

            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('hash')) {
                params.append('hash', urlParams.get('hash'));

                url = ApiEndpointEnum.UserLoginHash;
            }

            if (publisher !== undefined && publisher !== null) {
                params.append('publisher', publisher);
            }

            this.apiService.get<ApiUserDataInterface>(url, params)
                .subscribe((userData: ApiUserDataInterface) => {
                    this.handleSetUser(userData);
                    observer.next(userData);
                });
        });
    }

    public reload(): void {
        this.retrieve().subscribe();
    }

    private handleSetUser(userData: ApiUserDataInterface): void {
        this.userData = userData;
        this.onUserDataChange.emit(this.userData);

        this.analyticsService.setUser(this.userData);

        Sentry.configureScope((scope: Sentry.Scope) => {
            scope.setUser({
                'id': String(userData.id),
                'role': this.getUserRole(userData),
            });
        });
    }

    private getUserRole(user: ApiUserDataInterface): string {
        switch (user.role) {
            case RoleEnum.RoleStudent:
                return 'student';

            case RoleEnum.RoleTeacher:
                return 'teacher';

            case RoleEnum.RoleReviewer:
                return 'reviewer';
        }

        return 'unknown';
    }

    public getUserData(): ApiUserDataInterface | null {
        return this.userData;
    }

    public getCurrentUserId(): number {
        return this.getUserData().id;
    }

    public isCurrentUserId(userId: number): boolean {
        return this.getCurrentUserId() === userId;
    }

    public getUser(userId: number): Observable<ApiUserDataInterface> {
        const params = new URLSearchParams();
        params.append('id', userId.toString());

        return this.apiService.get(ApiEndpointEnum.User, params);
    }

    public editProfile(userIdentity: ApiUserIdentityInterface): Observable<ApiUserDataInterface> {
        return new Observable((observer: Observer<ApiUserDataInterface>) => {
            this.apiService.post<ApiUserDataInterface>(ApiEndpointEnum.UserEdit, userIdentity)
                .subscribe(() => {
                    this.retrieve().subscribe(() => {
                        observer.next(this.getUserData());
                        observer.complete();
                    }, (error: any) => {
                        observer.error(error);
                        observer.complete();
                    });
                }, (error: any) => {
                    observer.error(error);
                    observer.complete();
                });
        });
    }

    public updateAvatar(file: File): Observable<ApiUserDataInterface> {
        return new Observable((observer: Observer<ApiUserDataInterface>) => {
            return this.apiService.uploadFile<ApiUserDataInterface>(file, ApiEndpointEnum.UserEdit)
                .subscribe((apiUser: ApiUserDataInterface) => {
                    this.userData.avatar = apiUser.avatar;

                    this.handleSetUser(this.userData);

                    observer.next(apiUser);
                }, (error: any) => observer.error(error), () => observer.complete());
        });
    }

    public visitTab(tabName: string): void {
        if (!this.isTabAlreadyVisited(tabName)) {
            const visitedTab = {
                id: null,
                type: 'visited_tab',
                name: tabName,
            };

            if (this.userData && !this.userData.visited_tabs) {
                this.userData.visited_tabs = [];
            }

            this.userData.visited_tabs.push(visitedTab);
        }
    }

    public setSkipHelpers(): void {
        this.visitTab(RoutesEnum.Books);
        this.visitTab(RoutesEnum.Tasks);
        this.visitTab(RoutesEnum.Exams);
        this.visitTab(RoutesEnum.Progress);
        this.visitTab(RoutesEnum.Groups);
    }

    public acceptTerms(): Observable<ApiUserDataInterface> {
        return new Observable((observer: Observer<ApiUserDataInterface>) => {
            this.apiService.post(ApiEndpointEnum.UserAcceptTerms, {accept: true})
                .subscribe(() => {
                    this.retrieve().subscribe(() => {
                        observer.next(null);
                        observer.complete();
                    }, (error: any) => {
                        observer.error(error);
                        observer.complete();
                    });
                }, (error: any) => {
                    observer.error(error);
                    observer.complete();
                });
        });
    }

    public getOrganizations(): Observable<Array<ApiOrganisationInterface>> {
        return this.apiService.get<Array<ApiOrganisationInterface>>(ApiEndpointEnum.OrganizationsAll);
    }

    public updateOrganization(params: any): Observable<ApiUserDataInterface> {
        return new Observable((observer: Observer<ApiUserDataInterface>) => {
            this.apiService.post<ApiUserDataInterface>(ApiEndpointEnum.UserUpdateOrganization, params)
                .subscribe(() => {
                    this.retrieve().subscribe(() => {
                        observer.next(this.getUserData());
                        observer.complete();
                    }, (error: any) => {
                        observer.error(error);
                        observer.complete();
                    });
                }, (error: any) => {
                    observer.error(error);
                    observer.complete();
                });
        });
    }

    public isTabAlreadyVisited(tabName: string): boolean {
        if (this.userData.visited_tabs === null) {
            return true;
        }

        for (let i = 0; i < this.userData.visited_tabs.length; i++) {
            if (this.userData.visited_tabs[i].name === tabName) {
                return true;
            }
        }

        return false;
    }
}
