import {
    Component, EventEmitter, OnInit, Output,
    ViewChild
} from '@angular/core';
import {
    NgbActiveModal,
    NgbCarousel,
    NgbSlideEventSource
} from '@ng-bootstrap/ng-bootstrap';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxClose} from 'models/iconbox/close.model';
import {IconEnum} from 'enums/icon.enum';
import {HelpPageContentService} from 'services/help-page/help-page-content.service';
import {UserService} from 'security/services/user/user.service';

@Component({
    selector: 'app-help-page-modal',
    templateUrl: 'help-page-modal.component.html',
})

export class HelpPageModalComponent implements OnInit {

    @ViewChild('carousel', {static: true})
    public carousel: NgbCarousel;

    @Output()
    public skipEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ButtonCloseWhite]);

    public icon: IconEnum = IconEnum.HomeRegular;

    public setCarousel: boolean = true;

    public buttonTitle: string;

    public triangle: boolean = false;

    public activeId: number = 0;

    public isLoading: boolean = true;

    public preloaderColor: string;

    public setSkipBtn: boolean = true;

    public firstTime: boolean;

    public setPrevious: boolean = true;

    public NgbSlideEventSource = NgbSlideEventSource;

    public showNavigationArrows = false;

    public showNavigationIndicators = false;

    public pages: Array<HelpPageInterface> = [];

    constructor(
        public activeModal: NgbActiveModal,
        public popupPageService: HelpPageContentService,
        public userService: UserService,
    ) {
    }

    public ngOnInit(): void {

        if (this.firstTime) {
            this.popupPageService.setFirstTime(true);
            this.setPrevious = false;
        }

        this.setPreviousOrSkipBtn();
    }

    public close(): void {
        this.activeModal.dismiss();
    }

    /**
     * Navigates to a slide with the specified identifier.
     */
    public select(slideId: number, source?: NgbSlideEventSource): any {
        this.carousel.select('ngb-slide-' + slideId.toString(), source);
        this.activeId = slideId;

        this.setPreviousOrSkipBtn();
    }

    public prev(): void {
        this.carousel.prev();
        this.activeId = this.activeId <= 0 ? this.activeId = (this.pages.length - 1) : this.activeId - 1;

        this.setPreviousOrSkipBtn();
    }

    public next(): void {
        this.carousel.next();
        this.activeId = this.activeId >= (this.pages.length - 1) ? this.activeId = 0 : this.activeId + 1;

        this.setPreviousOrSkipBtn();
    }

    public onSwipe(e: any): void {
        if (e === 'swiperight') {
            this.prev();
        } else {
            this.next();
        }
    }

    public skipHelpPage(): void {
        this.isLoading = true;
        this.popupPageService.skipHelpers()
            .subscribe(() => {
                this.isLoading = false;
                this.close();
                this.userService.setSkipHelpers();
                this.popupPageService.setFirstTime(false);
                this.skipEvent.emit(true);
            });
    }

    private setPreviousOrSkipBtn(): void {
        this.firstTime && this.activeId === 0 ? this.setPrevious = false : this.setPrevious = true;
    }
}
