import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {Subscription} from 'rxjs';
import {ContentSelectModelFactory} from 'shared/modules/content-select/factories/model.factory';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';
import {Router} from '@angular/router';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {ContentSelectItemInterface} from 'shared/modules/content-select/interfaces/select-item.interface';
import {RouteService} from 'routing/services/route/route.service';

@Component({
    selector: 'app-toc-header-component',
    templateUrl: 'toc-header.component.html',
})
export class TocHeaderComponent implements OnInit, OnDestroy, OnChanges {

    @Output()
    public hideToc: EventEmitter<void> = new EventEmitter();

    @Input()
    public bookId: number;

    @Input()
    public currentBook: ContentSelectItemInterface;

    @Input()
    private books: ApiBooksInterface;

    public contentSwitchItems: Array<ContentSelectItemInterface> = [];

    public contentSwitchActive: ContentSelectItemInterface;

    public contentSwitchIconBox: IconBoxInterface = {
        type: IconBoxTypeEnum.Icon,
        value: IconEnum.BookClose,
        classModifiers: [
            IconBoxClassEnum.TocBook,
        ],
    };

    public contentSwitchCssClassModifiers: Array<string> = [
        ContentSelectClassModifiersEnum.Toc,
    ];

    public icoBoxClose: IconBoxInterface = {
        type: IconBoxTypeEnum.Icon,
        value: IconEnum.Cross,
        classModifiers: [
            IconBoxClassEnum.Icon,
            IconBoxClassEnum.ButtonCloseBlue,
        ],
    };

    private loadSubscription: Subscription;

    constructor(
        private router: Router,
        private routeService: RouteService,
        private contentSwitchModelFactory: ContentSelectModelFactory
    ) {
    }

    public ngOnInit(): void {
        if (this.currentBook) {
            const item = {
                id: this.currentBook.id,
                title: this.currentBook.title,
                active: false
            };

            this.contentSwitchActive = item;
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentBook.firstChange) {
            setTimeout(() => {
                    this.loadBookData();
                },
                1000);
        }
    }

    public ngOnDestroy(): void {
        if (this.loadSubscription instanceof Subscription) {
            this.loadSubscription.unsubscribe();
        }
    }

    public doHideToc(event?: MouseEvent): void {
        if (event !== undefined) {
            event.stopPropagation();
        }

        this.hideToc.emit();
    }

    public handleContentSwitchItemClick(items: Array<ContentSelectItemInterface>): void {
        if (items.length === 0) {
            return;
        }

        const item = items[0];
        const book = this.books.data.find(apiBook => apiBook && apiBook.id === Number(item.id));

        if (book !== undefined) {
            const url: string = this.routeService.getRouteWithPublisher(RoutesEnum.Document, new Map([
                ['bookId', book.id.toString()],
                ['documentDpsid', book.dpsid],
            ]));

            this.router.navigateByUrl(url);
        }
    }

    private loadBookData(): void {
        this.contentSwitchItems = this.contentSwitchModelFactory.fromApiBooks(this.books);
        this.updateActiveItem();
    }

    private updateActiveItem(): void {
        const item: ContentSelectItemInterface = this.contentSwitchItems.find(child => child.id === this.bookId.toString());

        if (item !== undefined) {
            item.active = true;
            this.contentSwitchActive = item;
        }
    }
}
