import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {FilterDropdownClassEnum} from 'shared/enums/filter-dropdown/class.enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GroupsService} from 'services/groups/groups.service';
import {Subscription} from 'rxjs';
import {GroupsModuleGroupInterface} from 'pages/modules/groups/interfaces/group.interface';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {GroupsModuleGroupsYearModel} from 'pages/modules/groups/models/groups-year.model';
import {GroupYearFilterEnum} from 'pages/modules/groups/enums/group-year-filter.enum';
import {IconBoxClose} from 'models/iconbox/close.model';

@Component({
    selector: 'app-groups-join-group-modal-component',
    templateUrl: 'edit-group.component.html',
})
export class GroupsEditGroupModalComponent implements OnInit, OnDestroy {

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly filterDropClasses = FilterDropdownClassEnum;

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorGreen]);

    @Output()
    public groupEvent: EventEmitter<ApiGroupInterface> = new EventEmitter();

    public filters: GroupsModuleGroupsYearModel = new GroupsModuleGroupsYearModel(true);

    public isLoading: boolean = false;

    public groupName: string;

    public groupYear: string;

    public group: GroupsModuleGroupInterface;

    private subscription: Subscription;

    constructor(
        private activeModal: NgbActiveModal,
        private groupService: GroupsService,
        private toastService: ToastrService,
    ) {
    }

    public ngOnInit(): void {
        this.groupName = this.group.name;
        this.groupYear = this.group.year;

        if (this.groupYear) {
            this.filters.selectedValue = this.groupYear;
        }
    }

    public ngOnDestroy(): void {
        if (this.subscription instanceof Subscription) {
            this.subscription.unsubscribe();
        }
    }

    public close(): void {
        this.activeModal.dismiss();
    }

    public handleFilterChange(groupYear: any): void {
        this.groupYear = groupYear.selectedValue;
    }

    public save(): void {
        if (!this.isValid()) {
            this.toastService.warning('Controleer groepsnaam en het geselecteerde jaar.');

            return;
        }

        this.isLoading = true;
        this.subscription = this.groupService.updateGroupName(this.group.id, this.groupName, this.groupYear)
            .subscribe((response: ApiGroupInterface) => {
                this.groupEvent.emit(response);
                this.toastService.success('De groepsnaam is gewijzigd.');
                this.close();
            }, (errorResponse: HttpErrorResponse) => {
                this.toastService.error('Groep bewerken mislukt.');
            });
    }

    private isValid(): boolean {
        return (this.groupName !== undefined && this.groupName !== null && this.groupName.length !== 0 && this.groupYear !== GroupYearFilterEnum.All) && this.groupYear !== null;
    }
}
