import {AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RedactorService} from 'services/redactor/redactor.service';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {DocumentService} from 'document/services/document/document.service';
import {DocumentAnswerableComponent} from 'document/modules/abstract/answerable.component';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {ClassHelper} from 'helpers/dom/class.helper';
import {RoleEnum} from 'enums/role.enum';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {IconEnum} from 'enums/icon.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {InviteStateEnum} from 'enums/invite-state.enum';
import Timer = NodeJS.Timer;
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';

@Component({
    selector: '.document-userinputarea',
    templateUrl: 'textarea.component.html',
})
export class DocumentTextareaComponent extends DocumentAnswerableComponent implements AfterViewInit, OnInit, OnDestroy {

    public static readonly CLASS = 'document__textarea';

    public readonly examinationNeededEnum = ExamineOptionsEnum;

    public readonly roleEnum = RoleEnum;

    public readonly iconEnum = IconEnum;

    public readonly gridTableClasses = GridTableClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public invite: ApiInternshipInviteInterface = null;

    @ViewChild('textArea', { static: false })
    private textArea: ElementRef;

    @HostBinding('class')
    public elementClass: string;

    public id: string;

    public classHelper: ClassHelper = new ClassHelper(DocumentTextareaComponent.CLASS);

    public value: string = '';

    public initialAnswer: string;

    public reviewMode: boolean = false;

    public reviewError: boolean = false;

    public examinationByStudent: boolean = false;

    protected redactor: RedactorAppInstance;

    private keyUpTimeout: Timer;

    constructor(
        protected elementRef: ElementRef,
        protected answersService: DocumentAnswersService,
        protected showAnswersService: DocumentAnswersStateService,
        private documentService: DocumentService,
        private redactorService: RedactorService,
        private authorizationService: AuthorizationService,
    ) {
        super(elementRef, answersService, showAnswersService);

        this.id = `textarea-${this.dataSet.assignmentid}-${this.dataSet.fieldid}`;
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.handleExaminationNeeded();

        const task = this.documentService.getActiveTask();

        if (task === null || !this.documentService.isBpvAssignment()) {
            return;
        }

        for (const invite of task.internshipinvites) {
            if (invite.status !== InviteStateEnum.Revoked) {
                this.invite = invite;
                break;
            }
        }

    }

    public ngAfterViewInit(): void {
        const options = {
            callbacks: {
                blur: () => this.onRedactorChange(),
                keyup: () => this.onRedactorKeyUp(),
            },
        };

        if (this.textArea) {
            this.redactorService.create(this.textArea.nativeElement, options)
                .subscribe((redactor: RedactorAppInstance) => {
                    this.redactor = redactor;
                    super.ngAfterViewInit();
                });
        } else {
            super.ngAfterViewInit();
        }

        this.elementClass = this.classHelper.toString();
    }

    public ngOnDestroy(): void {
        this.removeRedactor();
    }

    public updateClasses(): void {
        this.elementClass = this.classHelper.toString();
    }

    public inReviewMode(): void {
        this.reviewMode = true;

        if (!this.authorizationService.isGranted(RoleEnum.RoleReviewer) && this.textArea) {
            this.redactorService.delete(this.textArea.nativeElement);
        }
    }

    public reviewAnswerValid(): boolean {
        const valid = this.getValueStringLength() > 0;

        this.reviewError = valid === false;

        return valid;
    }

    protected setAnswer(answer: string): void {
        if (!answer) {
            return;
        }

        if (this.field.options && this.field.options.roles_enabled && this.field.options.roles_enabled.indexOf(RoleEnum.RoleReviewer) > -1) {
            if (!this.authorizationService.isGranted(RoleEnum.RoleReviewer) && !(this.invite && this.invite.status === InviteStateEnum.Completed)) {
                return;
            }
        }
        this.value = answer;

        if (this.redactor) {
            this.redactor.source.setCode(this.value);
        }
    }

    protected showCorrectAnswer(correctAnswer: string): void {
        // Implemented in a different way
    }

    protected showInitialAnswer(initialAnswer: string): void {
        if (initialAnswer === this.value) {
            return;
        }

        this.initialAnswer = initialAnswer;
    }

    protected disableAnswers(): void {
        this.removeRedactor();
    }

    protected answerValid(): boolean {
        return true;
    }

    private onRedactorChange(): void {
        this.value = this.redactor.source.getCode();

        if (this.reviewError && this.getValueStringLength() > 0) {
            this.reviewError = false;
        }

        this.saveAnswer(this.value);
    }

    private onRedactorKeyUp(): void {
        if (this.keyUpTimeout) {
            clearTimeout(this.keyUpTimeout);
        }

        this.keyUpTimeout = setTimeout(() => {
            this.onRedactorChange();

            this.keyUpTimeout = undefined;
        }, 1000);
    }

    private getValueStringLength(): number {
        const el = document.createElement('div');
        el.innerHTML = this.value;

        const text = el.textContent || el.innerText || '';
        el.remove();

        return text.length;
    }

    private removeRedactor(): void {
        if (this.redactor && this.textArea) {
            this.redactorService.delete(this.textArea.nativeElement);
            this.redactor = undefined;
        }
    }

    private handleExaminationNeeded(): void {
        const task = this.documentService.getActiveTask();

        // examination by Student
        if (task && task.examination_needed === this.examinationNeededEnum.Student) {
            this.examinationByStudent = this.documentService.subscribeSelfStudyCompatible();
        }
    }
}
