import {
    Component,
    HostBinding,
    Input,
    OnInit,
} from '@angular/core';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';
import {ClassHelper} from 'helpers/dom/class.helper';

@Component({
    selector: '.document-handed-in',
    templateUrl: 'document-handed-in.component.html',
})
export class DocumentHandedInComponent implements OnInit {

    @HostBinding('class')
    public elementClass: string = '';

    @Input()
    public classModifiers: Array<string> = [];

    public ngOnInit(): void {
        const classHelper = new ClassHelper();

        classHelper.addClasses(this.classModifiers);
        classHelper.addClass(DocumentHostClassesEnum.QuestionHandedIn);

        this.elementClass = classHelper.toString();
    }
}
