import {AnalyticsEventCategoryEnum} from 'services/analytics/enums/event-category.enum';
import {AnalyticsEventActionEnum} from 'services/analytics/enums/event-action.enum';
import {AnalyticsEventModel} from 'services/analytics/models/event.model';

export class AnalyticsEventAuthenticatedAccessDeniedModel extends AnalyticsEventModel {

    public constructor() {
        super(AnalyticsEventCategoryEnum.Authenticated, AnalyticsEventActionEnum.AuthenticatedAccessDenied);
    }

}
