import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterButtonGroupInterface} from 'shared/components/filter-button-group/interfaces/filter.interface';
import {StatusPersistedInterface} from 'shared/components/status-filter/interface/persisted.interface';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';
import {LocalStorageService} from 'angular-2-local-storage';
import {LocalStorageKeysEnum} from 'enums/local-storage-keys.enum';
import {FilterDropdownFilterInterface} from 'shared/components/filter-dropdown/interfaces/filter.interface';

@Component({
    selector: 'app-status-filter',
    templateUrl: 'status-filter.component.html',
})
export class StatusFilterComponent implements OnInit {

    @Input()
    public classModifiers: Array<ContentSelectClassModifiersEnum> = [];

    @Input()
    public initial: string;

    @Input()
    public statuses: Map<string, string>;

    @Input()
    public disabled: boolean = false;

    @Input()
    public context: string = 'status';

    @Output()
    public statusChange: EventEmitter<string> = new EventEmitter();

    public statusFilter: FilterButtonGroupInterface<string> = {
        id: 'status-filter',
        options: new Map<string, string>([])
    };

    public statusFilterMobile: FilterDropdownFilterInterface<string> = {
        id: 'status-filter',
        options: new Map([
            ['', new Map<string, string>([])]
        ]),
    };

    private status: string;

    constructor(
        private readonly localStorageService: LocalStorageService,
    ) {}

    public ngOnInit(): void {
        this.statusFilter.options = this.statuses;
        this.statusFilterMobile.options.set('', this.statuses);

        this.setFilterStatus(this.initial);

        this.setInitialActiveState();

        this.updateSelectedGroup();
    }

    public onStatusFilterButtonsChange(filterChange: FilterButtonGroupInterface<string>): void {
        this.setFilterStatus(filterChange.selected);

        this.filterUpdated();
    }

    public onStatusFilterDropdownChange(filterChange: FilterDropdownFilterInterface<string>): void {
        this.setFilterStatus(filterChange.selectedValue);

        this.filterUpdated();
    }

    private setFilterStatus(status: string): void {
        this.status = status;

        this.statusFilter.selected = status;
        this.statusFilterMobile.selectedValue = status;
    }

    private updateSelectedGroup(): void {
        this.statusChange.emit(this.status);
    }

    private filterUpdated(): void {
        this.updateSelectedGroup();

        const val: StatusPersistedInterface = {
            status: this.status
        };

        this.localStorageService.set(this.getLocalStorageKey(), val);
    }

    private setInitialActiveState(): void {
        const persisted = this.localStorageService.get<StatusPersistedInterface>(this.getLocalStorageKey()) || null;

        if (!persisted || !persisted.status) {
            return;
        }

        this.setFilterStatus(persisted.status);
    }

    private getLocalStorageKey(): string {
        return `${this.context}-${LocalStorageKeysEnum.StatusFilterData}`;
    }
}
