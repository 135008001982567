export enum DocumentHotspotClassEnum {

    HotSpotBlock = 'document-hotspot-block',

    HotSpotContainer = 'hotspot-marker-container',

    HotSpotMarker = 'hotspot-marker',

    HotspotShowCorrectAnswer = 'hotspot-show-correct-answer'

}
