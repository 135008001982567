import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { RadioBoxInterface } from 'shared/components/radio-box/interfaces/radio-box.interface';
import { ExamineOptionsEnum } from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';

@Component({
    selector: 'app-tasks-add-modal-examine',
    templateUrl: 'examine.component.html',
})
export class TasksAddTaskModalExamineComponent implements OnChanges {

    public examineOptions: Array<RadioBoxInterface> = [
        {
            id: ExamineOptionsEnum.Teacher,
            value: 'Docent',
        },
        {
            id: ExamineOptionsEnum.Student,
            value: 'Student',
        },
        {
            id: ExamineOptionsEnum.None,
            value: 'Niemand',
        },
        {
            id: ExamineOptionsEnum.Auto,
            value: 'Deze opdracht wordt automatisch nagekeken',
            hidden: true
        },
        {
            id: ExamineOptionsEnum.Differs,
            value: 'Afwijkend',
            hidden: true
        }
    ];

    @Input()
    public examine: ExamineOptionsEnum;

    @Output()
    public examineChange: EventEmitter<ExamineOptionsEnum> = new EventEmitter();

    @Input()
    public differs: boolean = true;

    @Input()
    public disabled: boolean = false;

    @Input()
    public isAutoCheck: boolean = false;

    public ngOnChanges(): void {

        this.examineOptions = this.examineOptions.map( option => {

            if (this.isAutoCheck) {
                if (option.id === ExamineOptionsEnum.Auto) {
                    option.hidden = false;
                } else {
                    option.hidden = true;
                }
            } else {
                if (option.id === ExamineOptionsEnum.Auto) {
                    option.hidden = true;
                } else {
                    option.hidden = false;
                }
            }

            return option;
        });


        if (this.differs) {
            this.examineOptions = this.examineOptions.map( option => {
                if (option.id === ExamineOptionsEnum.Differs) {
                    if (this.isAutoCheck) {
                        option.hidden = true;
                    } else {
                        option.hidden = false;
                    }
                }

                return option;
            });
        } else {
            this.examineOptions = this.examineOptions.map( option => {
                if (option.id === ExamineOptionsEnum.Differs) {
                    option.hidden = true;
                }

                return option;
            });
        }


    }


    public onExamineUpdated(): void {
        this.examineChange.emit(this.examine);
    }
}
