import {Injectable} from '@angular/core';

@Injectable()
export class DocumentKeyEventService {

    public ctrlClick: boolean = false;

    constructor() {
        window.addEventListener('keydown', (event: KeyboardEvent) => this.handleKeyDownEvent(event));
        window.addEventListener('keyup', () => this.handleKeyUpEvent());
    }

    /**
     * Register ctrl or meta (mac command key), expand with possible future key events.
     */
    public handleKeyDownEvent(event: KeyboardEvent): void {
        this.ctrlClick = (event.ctrlKey || event.metaKey);
    }

    public handleKeyUpEvent(): void {
        this.ctrlClick = false;
    }
}
