import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {AvatarClassesEnum} from 'shared/components/avatar/enums/avatar-classes.enum';
import {ExamService} from 'services/exam/exam.service';
import {ResultClassEnum} from 'shared/components/result-cell/enums/result-class.enum';

@Component({
    selector: 'app-exams-exam-score',
    templateUrl: 'exam-score.component.html',
})
export class ExamsExamScoreComponent {

    public readonly rowClasses = GridTableRowClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly avatarClasses = AvatarClassesEnum;

    public readonly iconEnum = IconEnum;

    @Input()
    public examPlan: ApiExamPlanDetailsInterface;

    @Input()
    public examResult: ApiExamUserResultInterface;

    @Input()
    public examCheck: ApiExamCheckInterface;

    @Input()
    public currentAnswer: ApiExamOpenAnswerInterface;

    @Output()
    public currentAnswerChange: EventEmitter<ApiExamOpenAnswerInterface> = new EventEmitter();

    public gradePoints: number = null;

    constructor(
        private examService: ExamService,
    ) {}

    public grade(points: number): void {
        const backupScore = this.currentAnswer.score;
        const backupReviewed = this.currentAnswer.reviewed;

        this.currentAnswer.score = points;
        this.currentAnswer.reviewed = true;

        this.examService.gradeOpenQuestion(this.examResult.examResult.id, this.currentAnswer.id, points)
            .subscribe(() => {
                this.currentAnswerChange.emit(this.currentAnswer);
            }, () => {
                this.currentAnswer.score = backupScore;
                this.currentAnswer.reviewed = backupReviewed;
            });
    }

    public getScoreStateClass(): string {
        if (this.currentAnswer.score === 0) {
            return ResultClassEnum.ResultWrong;
        }

        if (this.currentAnswer.score === this.currentAnswer.max_score) {
            return ResultClassEnum.ResultRight;
        }

        return ResultClassEnum.ResultPass;
    }
}
