import {AfterViewInit, ContentChildren, Directive, ElementRef, OnInit, Renderer2} from '@angular/core';
import {BootstrapSpacingClassesEnum} from 'enums/bootstrap-spacing-classes.enum';
import {DocumentUserInputInlineComponent} from 'document/modules/components/user-input-inline/user-input-inline.component';
import {DocumentUserInputInlineClassEnum} from 'document/modules/components/user-input-inline/enums/class.enum';
import {DocumentTextareaComponent} from 'document/modules/components/textarea/textarea.component';
import {DocumentTextareaClassEnum} from 'document/modules/enums/textarea/class.enum';
import {SvgListTypeEnum} from 'enums/svg/list-type.enum';
import {SvgFactory} from 'factories/svg.factory';
import {TypographyListClassesEnum} from 'enums/typography-list-classes.enum';
import {DomParentsHelper} from 'helpers/dom/parents.helper';

@Directive({
    selector: 'ol, ul',
})
export class DocumentListDirective implements OnInit, AfterViewInit {

    public static readonly CLASS = 'document__list';

    public static readonly CLASS_LIST = 'list__element';

    public static readonly CLASS_LIST_WRAPPER = 'element__wrapper';

    @ContentChildren(DocumentUserInputInlineComponent, {descendants: true})
    public inlineInputComponents: Array<DocumentUserInputInlineComponent> = [];

    @ContentChildren(DocumentTextareaComponent, {descendants: true})
    public textareaComponents: Array<DocumentTextareaComponent> = [];

    protected listType: SvgListTypeEnum;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private svgFactory: SvgFactory,
        private parentHelper: DomParentsHelper,
    ) {
        const previousSibling: HTMLElement = this.elementRef.nativeElement.previousSibling;

        if (previousSibling instanceof HTMLParagraphElement) {
            previousSibling.classList.add(BootstrapSpacingClassesEnum.NoMarginBottom);
        }
    }

    public ngOnInit(): void {
        this.elementRef.nativeElement.classList.add(DocumentListDirective.CLASS);
        this.elementRef.nativeElement.classList.add('clearfix');
    }

    public ngAfterViewInit(): void {
        this.setListType();
        this.updateInputInlineComponents();
        this.updateTextareaComponents();
        this.updateListElements();
    }

    protected setListType(): void {
        if (!this.isUnorderedList()) {
            return;
        }

        let listType: SvgListTypeEnum;

        const result: boolean = Array.from(this.getNativeElement().classList).some((className: string) => {
            switch (className) {
                case TypographyListClassesEnum.StyleCheck:
                    listType = SvgListTypeEnum.Check;
                    break;
            }

            return listType !== undefined;
        });

        if (!result) {
            listType = SvgListTypeEnum.Circle;
        }

        if (this.parentHelper.findFirstParentWithClass(this.elementRef.nativeElement, 'document__review') !== null) {
            listType = SvgListTypeEnum.Check;
            this.elementRef.nativeElement.classList.add('document__list--style-24boost-blue');
        }

        this.listType = listType;
    }

    protected updateListElements(): void {
        this.getNativeElement().childNodes.forEach((childNode: ChildNode) => {
            if (childNode instanceof HTMLLIElement) {
                childNode.classList.add(DocumentListDirective.CLASS_LIST);

                const childNodesContent: Array<Node> = Array.from(childNode.childNodes);
                const wrapperElement: HTMLDivElement = this.createWRapperElement();

                if (this.isUnorderedList()) {
                    wrapperElement.appendChild(this.getListStyleSvg());
                }

                for (const childNodeContent of childNodesContent) {
                    wrapperElement.appendChild(childNodeContent);
                }

                childNode.appendChild(wrapperElement);
            }
        });
    }

    protected isUnorderedList(): boolean {
        return this.getNativeElement().tagName.toLowerCase() === 'ul';
    }

    protected getListStyleSvg(): SVGElement {
        const svgElement: SVGElement = this.svgFactory.createFromListType(this.listType);

        return <SVGElement>svgElement.cloneNode(true);
    }

    protected createWRapperElement(): HTMLDivElement {
        const wrapperElement: HTMLDivElement = this.renderer.createElement('div');

        wrapperElement.className = DocumentListDirective.CLASS_LIST_WRAPPER;

        return wrapperElement;
    }

    protected updateInputInlineComponents(): void {
        this.inlineInputComponents.forEach((input: DocumentUserInputInlineComponent) => {
            input.classHelper.toggleClassByBoolean(DocumentUserInputInlineClassEnum.ListStyling, true);
            input.updateClasses();
        });
    }

    protected updateTextareaComponents(): void {
        this.textareaComponents.forEach((textarea: DocumentTextareaComponent) => {
            textarea.classHelper.toggleClassByBoolean(DocumentTextareaClassEnum.ListStyling, true);
            textarea.updateClasses();
        });
    }

    protected getNativeElement(): HTMLUListElement | HTMLOListElement {
        return this.elementRef.nativeElement;
    }
}
