import {Component, ElementRef, HostBinding, OnInit} from '@angular/core';
import {DocumentAnswerableComponent} from 'document/modules/abstract/answerable.component';
import {DocumentTextareaComponent} from 'document/modules/components/textarea/textarea.component';
import {RoleEnum} from 'enums/role.enum';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {RadioBoxInterface} from 'shared/components/radio-box/interfaces/radio-box.interface';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {DocumentService} from 'document/services/document/document.service';
import {InviteStateEnum} from 'enums/invite-state.enum';
import {AuthorizationService} from 'security/services/authorization/authorization.service';

@Component({
    selector: '.document-rating-score',
    templateUrl: 'rating-score.component.html',
})
export class DocumentRatingScoreComponent extends DocumentAnswerableComponent implements OnInit {

    public readonly roleEnum = RoleEnum;

    public readonly gridTableClasses = GridTableClassEnum;

    public readonly gridTableColClasses = GridTableColClassEnum;

    @HostBinding('class')
    public elementClass: string = 'document__rating-score';

    public rating = null;

    public invite: ApiInternshipInviteInterface = null;

    public ratingOptions: Array<RadioBoxInterface> = [
        {id: 1, value: '1 slecht'},
        {id: 2, value: '2'},
        {id: 3, value: '3'},
        {id: 4, value: '4'},
        {id: 5, value: '5 goed'},
    ];

    private textAreaComponent: DocumentTextareaComponent;

    public constructor(
        protected elementRef: ElementRef,
        protected answersService: DocumentAnswersService,
        protected showAnswersService: DocumentAnswersStateService,
        private documentService: DocumentService,
        private authorizationService: AuthorizationService
    ) {
        super(elementRef, answersService, showAnswersService);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        const task = this.documentService.getActiveTask();

        if (task === null || !this.documentService.isBpvAssignment()) {
            return;
        }

        for (const invite of task.internshipinvites) {
            if (invite.status !== InviteStateEnum.Revoked) {
                this.invite = invite;
            }
        }
    }

    public connectWithTextArea(textArea: DocumentTextareaComponent): void {
        this.textAreaComponent = textArea;
    }

    protected showCorrectAnswer(correctAnswer: string): void {
        // Not supported
    }

    protected showInitialAnswer(initialAnswer: string): void {
        // Not supported
    }

    protected setAnswer(answer: string): void {
        if (this.authorizationService.isGranted(RoleEnum.RoleReviewer) || (this.invite && this.invite.status === InviteStateEnum.Completed)) {
            this.rating = Number(answer);

            this.answerValid();
        }
    }

    protected answerValid(): boolean {
        if (this.rating > 1 && this.rating < 5) {
            return true;
        }

        return !this.textAreaComponent || this.textAreaComponent.reviewAnswerValid();
    }

    public getOptionValue(id: number): string {

        for (const option of this.ratingOptions) {
            if (option.id === id) {
                return option.value;
            }
        }

        return this.rating;
    }
}
