export enum ContentSelectClassModifiersEnum {

    Toc = 'content-select--toc',

    Disabled = 'content-select--disabled',

    TeacherMaterials = 'content-select--teacher-materials',

    TasksAddModal = 'content-select--tasks-add-modal',

    Expanded = 'content-select--expanded',

    HasFooter = 'content-select--has-footer',

    Tasks = 'content-select--tasks',

    Exams = 'content-select--exams',

    Progress = 'content-select--progress',

    PracticeSupervisor = 'content-select--practice-supervisor',

    GroupStudents = 'content-select--groups-students',

    Books = 'content-select--books',

    Organization = 'content-select--organization',

    // Background color dropdown

    Grey = 'content-select--grey',

    White = 'content-select--white',

}
