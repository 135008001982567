import {IconEnum} from 'enums/icon.enum';
import {ActionButtonClassEnum} from 'shared/enums/action-button/class.enum';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';

export class TasksEditTaskModel implements ActionButtonInterface {
    public id = 'button-edit-task';

    public iconBox = {
        value: IconEnum.Edit,
        type: IconBoxTypeEnum.Icon,
    };

    public classModifiers = [
        ActionButtonClassEnum.Orange,
        ActionButtonClassEnum.IconOnlyInverted,
    ];
}
