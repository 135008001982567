import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RadioBoxInterface} from 'shared/components/radio-box/interfaces/radio-box.interface';

@Component({
    selector: 'app-radio-box',
    templateUrl: 'radio-box.component.html',
})
export class RadioBoxComponent implements ClassModifierInterface {

    @Input()
    public id: string;

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public options: Array<RadioBoxInterface>;

    @Input()
    public model: number;

    @Output()
    public modelChange: EventEmitter<number> = new EventEmitter();

    @Input()
    public disabled: boolean = false;

    constructor() {
        this.id = this.id !== undefined ? this.id : Math.random().toString(36).substring(9);
    }

    public onModelChange(id: number): void {
        if (this.disabled) {
            return;
        }

        this.model = id;
        this.modelChange.emit(this.model);
    }
}
