import {Component, Input, OnInit} from '@angular/core';
import {MessageCenterMessageInterface} from 'pages/modules/message-center/interfaces/message.interface';
import {IconBoxClose} from 'models/iconbox/close.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconEnum} from 'enums/icon.enum';
import {AnalyticsService} from 'services/analytics/analytics.service';
import {AnalyticsEventMessageShowModel} from 'services/analytics/models/message-show.model';

@Component({
    selector: 'app-message-center-message-details',
    templateUrl: 'message-details.component.html',
})
export class MessageCenterMessageDetailsModalComponent implements OnInit {

    protected static readonly TIME_SPEC_AFFIX = 'geleden ontvangen';

    public readonly relativeTimeSpec: moment.RelativeTimeSpec = {
        s: `%d seconden ${MessageCenterMessageDetailsModalComponent.TIME_SPEC_AFFIX}`,
        ss: `%d seconden ${MessageCenterMessageDetailsModalComponent.TIME_SPEC_AFFIX}`,
        m: `%d minuten ${MessageCenterMessageDetailsModalComponent.TIME_SPEC_AFFIX}`,
        mm: `%d minuten ${MessageCenterMessageDetailsModalComponent.TIME_SPEC_AFFIX}`,
        h: `%d uur ${MessageCenterMessageDetailsModalComponent.TIME_SPEC_AFFIX}`,
        hh: `%d uur ${MessageCenterMessageDetailsModalComponent.TIME_SPEC_AFFIX}`,
        d: `%d dagen ${MessageCenterMessageDetailsModalComponent.TIME_SPEC_AFFIX}`,
        dd: `%d dagen ${MessageCenterMessageDetailsModalComponent.TIME_SPEC_AFFIX}`,
        y: `%d jaar ${MessageCenterMessageDetailsModalComponent.TIME_SPEC_AFFIX}`,
        yy: `%d jaar ${MessageCenterMessageDetailsModalComponent.TIME_SPEC_AFFIX}`,
    };

    public readonly iconBox = new IconBoxClose([IconBoxClassEnum.ButtonCloseWhite]);

    @Input()
    public message: MessageCenterMessageInterface;

    public title: string;

    public description: string;

    public created: number;

    constructor(
        private activeModal: NgbActiveModal,
        private analyticsService: AnalyticsService,
    ) {
    }

    public ngOnInit(): void {
        this.title = this.message.title;
        this.description = this.message.description;
        this.created = this.message.created;

        this.analyticsService.event(new AnalyticsEventMessageShowModel(this.message.id, this.message.title));
    }

    public close(): void {
        if (!this.message.read) {
            this.message.read = true;
            this.message.messageType.icon = IconEnum.MailOpen;
            this.activeModal.close(this.message);
        } else {
            this.activeModal.dismiss();
        }
    }
}
