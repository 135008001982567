import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'urlHttps'
})

export class UrlHttpsPipe implements PipeTransform {
    public transform(href: string): any {
        return href.startsWith('http://') || href.startsWith('https://') ? href : 'https://' + href;
    }
}
