import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CollectionBrowseFactory} from 'core/factories/collection-browse.factory';
import {ClassHelper} from 'helpers/dom/class.helper';
import {CollectionBrowseInterface} from 'interfaces/collection-browse.interface';
import {AvatarClassesEnum} from 'shared/components/avatar/enums/avatar-classes.enum';
import {IconEnum} from 'enums/icon.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {HorizontalBarIconClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-icon-classes.enum';
import {TaskStateService} from 'pages/modules/tasks/service/task-state.service';
import {TaskService} from 'services/task/task.service';

@Component({
    selector: 'app-tasks-assigned-to-student',
    templateUrl: 'assigned-to-student.component.html',
})
export class TasksAssignedToStudentComponent implements OnChanges {

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly horizontalBarIconClasses = HorizontalBarIconClassesEnum;

    public readonly iconEnum = IconEnum;

    public readonly avatarClasses = AvatarClassesEnum;

    @Input()
    public activeTask: ApiTaskDetailsInterface;

    @Input()
    public childTasks: Array<ApiTaskDetailsInterface>;

    @Input()
    public classHelper: ClassHelper = new ClassHelper();

    public students: CollectionBrowseInterface;

    public constructor(private tasksService: TaskStateService, private taskService: TaskService, private collectionBrowseFactory: CollectionBrowseFactory) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.students = this.collectionBrowseFactory.create(this.childTasks.indexOf(this.activeTask), this.childTasks.length);
    }

    public browseStudentTasks(direction: number): void {
        const nextIndex: number = this.childTasks.indexOf(this.activeTask) + direction;
        const apiTaskDetails: ApiTaskDetailsInterface = this.childTasks[nextIndex];

        if (undefined === apiTaskDetails) {
            return;
        }

        this.students.updateIndex(nextIndex);
        this.taskService.navigateToDetails(apiTaskDetails.id);
    }
}
