import {ChangeDetectorRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {InfiniteScrollDirective} from 'ngx-infinite-scroll';

export abstract class InfiniteScroll<T> implements OnInit, OnDestroy {

    @ViewChild(InfiniteScrollDirective, { static: true })
    public infiniteScrollContainer: InfiniteScrollDirective;

    protected limit: number = 10;

    protected position: number = 0;

    protected observable: Observable<T>;

    protected subscription: Subscription;

    private lengthSubscription: Subscription;

    private hasScrolled: boolean;

    public isLoading: boolean = false;

    protected abstract getLoadDataObservable(): Observable<T>;

    protected abstract getDataLength(): number;

    protected abstract subscribe(response: T): void;

    protected constructor(protected change: ChangeDetectorRef) {
    }

    public ngOnInit(): void {
        if (!(this.infiniteScrollContainer instanceof InfiniteScrollDirective)) {
            throw new Error('Missing element containing infinite scroll directive');
        }

        if (this.infiniteScrollContainer.scrolled.observers.length === 0) {
            throw new Error('No scrolled subscribers, have you bound (scrolled)="handleScrolled()" on the infinite scroll container?');
        }

        this.loadData(true);
    }

    public ngOnDestroy(): void {
        this.unsubscribe();
    }

    public getRequestOffset(): number {
        return this.position * this.limit;
    }

    public handleScrolled(): void {
        this.hasScrolled = true;

        if (!this.isAllowedToScroll()) {
            return;
        }

        this.doLoadData();
    }

    protected loadData(reset: boolean = false): void {
        this.unsubscribe();

        if (reset) {
            this.reset();
        }

        if (!reset && !this.isAllowedToScroll()) {
            return;
        }

        this.doLoadData();
    }

    private doLoadData(): void {
        this.isLoading = true;
        this.hasScrolled = false;
        this.change.detectChanges();

        this.observable = this.getLoadDataObservable(); // Get observable
        this.subscription = this.observable.subscribe((response: T) => this.subscribe(response));
        this.lengthSubscription = this.subscription.add(() => {
            this.position++; // Increase offset
            this.isLoading = false;

            if (this.hasScrolled && this.isAllowedToScroll()) {
                this.doLoadData(); // Load data
            }
        });
    }

    /**
     * Allow scrolling when:
     * 1. Not loading
     * 2. Current position is less than total available positions
     */
    protected isAllowedToScroll(): boolean {
        const totalPositions: number = this.getDataLength() / this.limit;

        return !this.isLoading && (this.position < totalPositions);
    }

    protected reset(): void {
        this.position = 0;
    }

    protected unsubscribe(): void {
        if (this.subscription instanceof Subscription) {
            this.subscription.unsubscribe();
        }
    }
}
