import {Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {throttleTime} from 'rxjs/operators';

@Directive({
    selector: '[PreventDoubleClick]'
})
export class PreventDoubleClickDirective implements OnInit, OnDestroy {
    @Input()
    public throttleTime = 500;

    @Output()
    public throttledClick = new EventEmitter();

    private clicks = new Subject();
    private subscription: Subscription;

    constructor() {
    }

    public ngOnInit(): void {
        this.subscription = this.clicks.pipe(
            throttleTime(this.throttleTime)
        ).subscribe(e => this.emitThrottledClick(e));
    }

    public emitThrottledClick(e: any): void {
        this.throttledClick.emit(e);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    @HostListener('click', ['$event'])
    public clickEvent(event: any): void {
        event.preventDefault();
        event.stopPropagation();
        this.clicks.next(event);
    }
}
