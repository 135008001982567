import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {NavbarButton} from 'core/components/navbar/classes/navbar.button';
import {DropdownComponent} from 'shared/modules/dropdown/components/dropdown.component';
import {UserService} from 'security/services/user/user.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {NavbarMaterialButtonModel} from 'core/components/navbar/models/material-button.model';
import {NavbarTasksButtonModel} from 'core/components/navbar/models/tasks-button.model';
import {NavbarTestsButtonModel} from 'core/components/navbar/models/tests-button.model';
import {NavbarProgressButtonModel} from 'core/components/navbar/models/progress-button.model';
import {NavbarGroupsButtonModel} from 'core/components/navbar/models/groups-button.model';
import {Subscription} from 'rxjs';
import {NavbarRolesLogic} from 'core/components/navbar/authorization/roles.logic';
import {RoleService} from 'security/services/role/role.service';
import {RoleEnum} from 'enums/role.enum';
import {NavbarItemsEnum} from 'core/components/navbar/enums/navbar-items.enum';
import {HelpPageService} from 'services/help-page/help-page.service';
import {HelpPageClassEnum} from 'services/help-page/enums/help-page-class.enum';
import {Router} from '@angular/router';
import {HelpPageEnum} from 'services/help-page/enums/help-page.enum';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-navbar-component',
    templateUrl: 'navbar.component.html',
})
export class NavBarComponent implements OnInit, OnDestroy {

    public readonly roleEnum = RoleEnum;

    @ViewChildren(DropdownComponent)
    protected dropdownComponents: QueryList<DropdownComponent>;

    public buttons: Array<NavbarButton> = [
        new NavbarMaterialButtonModel(),
        new NavbarTasksButtonModel(),
        new NavbarTestsButtonModel(),
        new NavbarProgressButtonModel(),
        new NavbarGroupsButtonModel(),
    ];

    private hasGroups: boolean = false;

    private userChangeSubscription: Subscription;

    constructor(
        private userService: UserService,
        private roleService: RoleService,
        private deviceService: DeviceDetectorService,
        private navbarRolesLogic: NavbarRolesLogic,
        private changeDetectorRef: ChangeDetectorRef,
        private helpPageService: HelpPageService,
        private elementRef: ElementRef,
        private route: Router,
    ) {
        this.roleService.registerRoleLogic(navbarRolesLogic);
    }

    public ngOnInit(): void {
        this.onUserChange();
        this.userChangeSubscription = this.userService.onUserDataChange.subscribe(() => this.onUserChange());

        this.getElementOriginalLeft();
    }

    public ngOnDestroy(): void {
        if (this.userChangeSubscription) {
            this.userChangeSubscription.unsubscribe();
        }
    }

    private setButtonNotificationCount(button: NavbarItemsEnum, count: number): void {
        this.findButtonById(button).setNotificationCount(count);
    }

    private onUserChange(): void {
        const user = this.userService.getUserData();

        this.hasGroups = user.group_count > 0;
        this.setButtonNotificationCount(NavbarItemsEnum.Tests, user.test_open_count);

        this.changeDetectorRef.detectChanges();
    }

    private findButtonById(buttonId: string): NavbarButton {
        const navBarButton: NavbarButton = this.buttons.find(button => button.id === buttonId);

        if (navBarButton === undefined) {
            throw new Error(`No button found that matches ID#${buttonId}.`);
        }

        return navBarButton;
    }

    private getElementOriginalLeft(buttonTitle?: string): void {
        let title = buttonTitle;
        const nativeElements: any = this.getNativeElement();

        if (!buttonTitle) {
            title = this.getBtnTranslation();
        }

        for (let i = 0; i < nativeElements.length; i++) {
            if (nativeElements[i].innerText.toLowerCase() === title) {
                switch (i) {
                    case 0:
                        this.helpPageService.setModalPosition(HelpPageClassEnum.One);
                        break;
                    case 1:
                        this.helpPageService.setModalPosition(HelpPageClassEnum.Two);
                        break;
                    case 2:
                        this.helpPageService.setModalPosition(HelpPageClassEnum.Three);
                        break;
                    case 3:
                        this.helpPageService.setModalPosition(HelpPageClassEnum.Four);
                        break;
                    case 4:
                        this.helpPageService.setModalPosition(HelpPageClassEnum.Five);
                        break;
                }
            }
        }

        return;
    }

    private getNativeElement(): Array<HTMLElement> {
        if (this.elementRef.nativeElement.children.length === 0) {
            // no tabs
            return [];
        }

        return this.elementRef.nativeElement.children[0].children[0].children;
    }

    private getBtnTranslation(): string {
        switch (true) {
            case this.route.url.indexOf(HelpPageEnum.Books) >= 0:
                return 'lesmateriaal';
                break;
            case this.route.url.indexOf(HelpPageEnum.Tasks) >= 0:
                return 'taken';
                break;
            case this.route.url.indexOf(HelpPageEnum.Exams) >= 0:
                return 'toetsen';
                break;
            case this.route.url.indexOf(HelpPageEnum.Progress) >= 0:
                return 'voortgang';
                break;
            case this.route.url.indexOf(HelpPageEnum.Groups) >= 0:
                return 'groepen';
                break;
        }
    }

    public openHelpPage(tabName: string, buttonTitle: string): void {
        if (!environment.features.helpPageEnabled) {
            return;
        }

        this.getElementOriginalLeft(buttonTitle);

        this.helpPageService.openHelpPage(tabName);
    }
}
