import {Injectable} from '@angular/core';
import {GroupsModuleGroupModel} from 'pages/modules/groups/models/group.model';
import {GroupsModuleGroupModelPersonFactory} from 'pages/modules/groups/factories/group-model-person.factory';
import {RoleEnum} from 'enums/role.enum';

@Injectable()
export class GroupsModuleGroupModelFactory {

    constructor(private groupModelPersonFactory: GroupsModuleGroupModelPersonFactory) {
    }

    public fromApiGroup(apiGroup: ApiGroupInterface): GroupsModuleGroupModel {
        return new GroupsModuleGroupModel(
            apiGroup.id,
            apiGroup.name,
            apiGroup.code,
            apiGroup.active,
            this.groupModelPersonFactory.fromApiGroupMembers(apiGroup.teachers),
            this.groupModelPersonFactory.fromApiGroupMembers(apiGroup.students),
            apiGroup.year,
        );
    }

    public fromApiGroupArray(apiGroups: Array<ApiGroupInterface>): Array<GroupsModuleGroupModel> {
        const groupModels: Array<GroupsModuleGroupModel> = [];

        apiGroups.forEach((apiGroup: ApiGroupInterface) => {
            groupModels.push(this.fromApiGroup(apiGroup));
        });

        return groupModels;
    }

    public fromApiGroups(apiGroups: ApiGroupsInterface): Array<GroupsModuleGroupModel> {
        const groupModels: Array<GroupsModuleGroupModel> = [];

        apiGroups.data.forEach((apiGroup: ApiGroupInterface) => {
            groupModels.push(this.fromApiGroup(apiGroup));
        });

        return groupModels;
    }

}
