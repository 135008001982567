export enum AnalyticsEventCategoryEnum {

    Authenticated = 'authenticated',

    StudyMaterial = 'studymaterial',

    Assignment = 'assignment',

    Task = 'task',

    Book = 'book',

    Publisher = 'publisher',

    Summary = 'summary',

    Message = 'message',

    Notification = 'notification',

    Note = 'note',

    Deeplink = 'deeplink',

}
