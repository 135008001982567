export enum BookshelfItemTypeEnum {

    Book = 1,

    TeachingMaterials = 2,

    TeacherMaterials = 3,

    ActivateLicense = 5,

}
