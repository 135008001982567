import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';
import {UserService} from 'security/services/user/user.service';
import {GroupsModulePersonClassModifiersEnum} from 'pages/modules/groups/enums/person-class-modifiers.enum';
import {GroupsModulePersonInterface} from 'pages/modules/groups/interfaces/person.interface';
import {RoleEnum} from 'enums/role.enum';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-groups-person-component',
    templateUrl: 'person.component.html',
})
export class GroupsPersonComponent implements OnInit {

    @Output()
    public personRemoveEvent: EventEmitter<GroupsModulePersonInterface> = new EventEmitter();

    @Input()
    public person: GroupsModulePersonInterface;

    @Input()
    public allowRemove: boolean = true;

    public showRemove: boolean = true;

    public cancelRemove: boolean = false;

    public classHelper: ClassHelper = new ClassHelper();

    public archiveModeActive = environment.archiveModeActive;

    constructor(private userService: UserService) {
    }

    public ngOnInit(): void {
        const isStudent: boolean = this.person.role === RoleEnum.RoleStudent;

        this.showRemove = this.allowRemove && (this.person.id === this.userService.getCurrentUserId() || isStudent);
    }

    public handleRemove(): void {
        if (!this.allowRemove) {
            return;
        }

        this.personRemoveEvent.emit(this.person);
    }

    public handleCancel(toggleRemove: boolean): void {
        this.cancelRemove = toggleRemove;
        this.classHelper.toggleClass(GroupsModulePersonClassModifiersEnum.Selected, 'remove');
    }
}
