import {CollectionBrowseInterface} from 'interfaces/collection-browse.interface';

export class CollectionBrowseModel implements CollectionBrowseInterface {

    public canBrowsePrevious: boolean = false;

    public canBrowseNext: boolean = false;

    constructor(
        public index: number,
        public total: number,
    ) {
        this.updateIndex(index);
    }

    public updateIndex(index: number): void {
        if (index === -1) {
            return;
        }

        const adjustedIndex: number = index + 1;

        this.index = index;
        this.canBrowsePrevious = (adjustedIndex > 1);
        this.canBrowseNext = adjustedIndex < this.total;
    }
}
