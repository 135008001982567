import {NgbDate, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class DatePickerFormatter extends NgbDateParserFormatter {

    public static DATE_FORMAT: string = 'DD/MM/YYYY';

    public format(date: NgbDateStruct): string | null {
        if (!date) {
            return null;
        }

        const dateTimeString = `${date.day}/${date.month}/${date.year}`;

        return moment(dateTimeString, DatePickerFormatter.DATE_FORMAT).format(DatePickerFormatter.DATE_FORMAT);
    }

    public parse(value: string): NgbDateStruct {
        const momentDate = moment(value, DatePickerFormatter.DATE_FORMAT, true);

        return NgbDate.from({
            day: Number(momentDate.format('DD')),
            month: Number(momentDate.format('MM')),
            year: Number(momentDate.format('YYYY')),
        });
    }
}
