import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SharedModule} from 'shared/shared.module';
import {DocumentPageComponent} from 'pages/modules/document/components/document.component';
import {TocModule} from 'shared/modules/toc/toc.module';
import {DocumentPageContentComponent} from 'pages/modules/document/components/content/content.component';
import {DocumentPageContentActionsComponent} from 'pages/modules/document/components/content-actions/content-actions.component';
import {DocumentExternPageActionsComponent} from 'pages/modules/document/components/extern-page-actions/extern-page-actions.component';
import {DocumentPageActionsComponent} from 'pages/modules/document/components/page-actions/page-actions.component';
import {DocumentPageActionsButtonComponent} from 'pages/modules/document/components/actions-button/button.component';
import {DocumentPageActionsSourcesComponent} from 'pages/modules/document/components/sources/sources.component';
import {DocumentPageTocContainerDirective} from 'pages/modules/document/directives/toc-container.directive';
import {DocumentModule} from 'document/document.module';
import {RouterModule} from '@angular/router';
import {DocumentPageSelfScoreComponent} from 'pages/modules/document/components/self-score/self-score.component';
import {DocumentPageVersionsComponent} from 'pages/modules/document/components/content-versions/content-versions.component';
import {DocumentPageVersionsModalComponent} from 'pages/modules/document/components/content-versions/content-versions-modal/content-versions-modal.component';
import {DocumentRolesLogic} from 'pages/modules/document/authorization/roles.logic';
import {SecurityModule} from 'security/security.module';
import {DocumentPagePracticeSupervisorComponent} from 'pages/modules/document/components/practice-supervisor/practice-supervisor.component';
import {DocumentPageAddPracticeSupervisorModalComponent} from 'pages/modules/document/components/practice-supervisor/modals/add-practice-supervisor/add-practice-supervisor-modal.component';
import {DocumentPageSendReviewComponent} from 'pages/modules/document/components/send-review/send-review.component';
import {DocumentPageReviewDoneModalComponent} from 'pages/modules/document/components/send-review/modals/review-done/review-done-modal.component';
import {RoutingModule} from 'routing/routing.module';
import {DocumentPageActionsSummaryComponent} from 'pages/modules/document/components/summary/summary.component';
import {DocumentPageReviewCompletedModalComponent} from 'pages/modules/document/components/send-review/modals/review-completed/review-completed-modal.component';
import {DocumentPageReviewRevokedModalComponent} from 'pages/modules/document/components/send-review/modals/review-revoked/review-revoked-modal.component';
import {DocumentPageAutoScoreComponent} from 'pages/modules/document/components/auto-score/auto-score.component';
import {FeedRssComponent} from 'pages/modules/document/components/feed-rss/feed-rss.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        DocumentModule,
        TocModule,
        RouterModule,
        SecurityModule,
        RoutingModule,
        FormsModule,
    ],
    declarations: [
        DocumentPageComponent,
        DocumentPageContentComponent,
        DocumentPageContentActionsComponent,
        DocumentExternPageActionsComponent,
        DocumentPageActionsComponent,
        DocumentPageActionsButtonComponent,
        DocumentPageActionsSourcesComponent,
        DocumentPageActionsSummaryComponent,
        DocumentPageTocContainerDirective,
        DocumentPageSelfScoreComponent,
        DocumentPageVersionsComponent,
        DocumentPageVersionsModalComponent,
        DocumentPagePracticeSupervisorComponent,
        DocumentPageAddPracticeSupervisorModalComponent,
        DocumentPageSendReviewComponent,
        DocumentPageReviewDoneModalComponent,
        DocumentPageReviewCompletedModalComponent,
        DocumentPageReviewRevokedModalComponent,
        DocumentPageAutoScoreComponent,
        FeedRssComponent,
    ],
    entryComponents: [
        DocumentPageVersionsModalComponent,
        DocumentPageAddPracticeSupervisorModalComponent,
        DocumentPageReviewDoneModalComponent,
        DocumentPageReviewCompletedModalComponent,
        DocumentPageReviewRevokedModalComponent,
    ],
    exports: [
        DocumentPageComponent,
        DocumentPageSelfScoreComponent,
        DocumentPageAddPracticeSupervisorModalComponent,
        DocumentPageReviewDoneModalComponent,
        DocumentPageContentComponent,
    ],
    providers: [
        DocumentRolesLogic,
    ],
})
export class DocumentPageModule {
}
