import {AfterViewInit, Component, Input, OnDestroy} from '@angular/core';
import {DocumentService} from 'document/services/document/document.service';
import {DrillsterService} from 'services/drillster/drillster.service';

declare var drillster: Drillster;

@Component({
    selector: 'app-drillster-component',
    templateUrl: 'drillster.component.html'
})
export class DrillsterComponent implements AfterViewInit, OnDestroy {

    @Input()
    public type: string;

    @Input()
    public code: string;

    @Input()
    public token: string;

    private initialized: boolean = false;

    private destroyed: boolean = false;

    private drillsters: DrillsterWidgets;

    public constructor(
        private drillsterService: DrillsterService,
        private documentService: DocumentService
    ) {
    }

    public ngAfterViewInit(): void {
        this.initializeDrillsters();
    }

    public ngOnDestroy(): void {
        if (this.drillsters) {
            this.drillsters.unload();
            this.destroyed = true;
        }
    }

    private initializeDrillsters(): void {
        if (this.initialized) {
            return;
        }

        const drillsters = drillster.widgets('drillster*');

        if (!drillsters) {
            return;
        }

        this.drillsters = drillsters;

        this.loadDrillsters();
        this.subscribeEvents();
    }

    private loadDrillsters(): void {
        this.drillsters.load();
        this.initialized = true;
    }

    private subscribeEvents(): void {
        this.drillsters.on('QUESTION_ANSWERED', (event: any) => this.onQuestionAnswered(event));
        this.drillsters.on('TEST_COMPLETED', (event: any) => this.onTestCompleted(event));
    }

    private onTestCompleted(event: DrillsterEvent): void {
        if (this.destroyed) {
            return;
        }

        if (event.type !== 'TEST_COMPLETED') {
            return;
        }

        if (!event.data || !event.data.result || !event.data.result.score) {
            return;
        }

        this.drillsterService.updateScore(this.documentService.getBookId(), this.code, event.data.result.score).subscribe();
    }

    private onQuestionAnswered(event: DrillsterEvent): void {
        if (this.destroyed) {
            return;
        }

        if (event.type !== 'QUESTION_ANSWERED') {
            return;
        }

        if (!event.data || !event.data.type || event.data.type !== 'DRILL' || !event.data.proficiency || !event.data.proficiency.current) {
            return;
        }

        this.drillsterService.updateScore(this.documentService.getBookId(), this.code, event.data.proficiency.current).subscribe();
    }
}
