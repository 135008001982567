export enum ResultSentenceEnum {

    ResultRightSentence = 'Deze opdracht is als goed beoordeeld',

    ResultPassSentence = 'Deze opdracht is als voldoende beoordeeld',

    ResultWrongSentence = 'Deze opdracht is als onvoldoende beoordeeld',

    ResultWrongWrongSentence = 'Deze opdracht is als fout beoordeeld',

    ResultRetrySentence = 'Deze opdracht is aangemerkt voor een herkansing',

}
