import {EventEmitter, Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {Observable} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {StringService} from 'services/string/string.service';
import {AnalyticsService} from 'services/analytics/analytics.service';
import {AnalyticsEventSummaryNewModel} from 'services/analytics/models/summary-new.model';
import {AnalyticsEventSummaryEditModel} from 'services/analytics/models/summary-edit.model';

@Injectable({
    providedIn: 'root'
})
export class SummaryService {

    public summaryChange: EventEmitter<ApiSummaryInterface> = new EventEmitter();

    private currentSummary: ApiSummaryInterface = null;

    constructor(
        private apiRequestService: ApiService,
        private stringService: StringService,
        private analyticsService: AnalyticsService,
    ) {
    }

    public getCurrentSummary(): ApiSummaryInterface | null {
        return this.currentSummary;
    }

    public retrieve(bookId: number, documentUuid: string): Observable<ApiSummaryInterface> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.SummaryGet, new Map([
            ['uuid', documentUuid],
        ]));

        const searchParams = new URLSearchParams();

        // todo right fix for searchParams?
        if (!bookId) {
            return;
        }

        searchParams.set('book_id', bookId.toString());

        const obs = this.apiRequestService.get<ApiSummaryInterface>(apiRoute, searchParams);

        obs.subscribe((summary: ApiSummaryInterface) => {
            if (summary instanceof Array) {
                this.currentSummary = null;

                return;
            }

            this.currentSummary = summary;
        });

        return obs;
    }

    public saveNew(bookId: number, documentUuid: string, text: string): Observable<ApiSummaryInterface> {
        const obs = this.apiRequestService.post<ApiSummaryInterface>(ApiEndpointEnum.SummaryAdd, {
            uuid: documentUuid,
            book_id: bookId,
            text: text
        });

        obs.subscribe((summary: ApiSummaryInterface) => {
            this.currentSummary = summary;
            this.summaryChange.emit(this.currentSummary);

            this.analyticsService.event(new AnalyticsEventSummaryNewModel());
        });

        return obs;
    }

    public edit(summaryId: number, bookId: number, documentUuid: string, text: string): Observable<ApiSummaryInterface> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.SummaryEdit, new Map([
            ['summaryId', summaryId.toString()],
        ]));

        const obs = this.apiRequestService.post<ApiSummaryInterface>(apiRoute, {
            uuid: documentUuid,
            book_id: bookId,
            text: text
        });

        obs.subscribe((summary: ApiSummaryInterface) => {
            this.currentSummary = summary;
            this.summaryChange.emit(this.currentSummary);

            this.analyticsService.event(new AnalyticsEventSummaryEditModel());
        });

        return obs;
    }

    public remove(summaryId: number): Observable<null> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.SummaryRemove, new Map([
            ['summaryId', summaryId.toString()],
        ]));

        const obs = this.apiRequestService.delete<null>(apiRoute);

        obs.subscribe(() => {
            this.currentSummary = null;
            this.summaryChange.emit(this.currentSummary);
        });

        return obs;
    }
}
