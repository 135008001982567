import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {StringService} from 'services/string/string.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {ExamsStatusFilterEnum} from 'pages/modules/exams/enums/exams-status-filter.enum';
import {UserService} from 'security/services/user/user.service';
import * as moment from 'moment';

export interface ApiExamStepInterface {
    bookUuid: string;
    document: ApiDocumentInterface;
}

export interface ApiDocumentInterface {
    legacyDocument: ApiDocumentDataInterface;
}

@Injectable({
    providedIn: 'root'
})
export class ExamService {
    public readonly disableBookTitleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private apiService: ApiService,
        private stringService: StringService,
        private userService: UserService,
    ) {
    }

    public mayStartExam(examPlan: ApiExamPlanInterface): boolean {
        return moment.unix(examPlan.start).isSameOrBefore(moment()) && moment.unix(examPlan.end).isAfter(moment());
    }

    public saveExam(params: any): Observable<Array<ApiExamPlanInterface>> {
        const obs: Observable<Array<ApiExamPlanInterface>> = this.apiService.post(ApiEndpointEnum.ExamCreate, params);

        obs.subscribe(() => {
            this.userService.reload();
        });

        return obs;
    }

    public retrieveMonths(
        future: boolean,
        offset: number | null,
        limit: string | null,
        search: string = null,
        status: string | ExamsStatusFilterEnum = null,
        groupId: string = null,
        userId: string = null,
        bookId: number = null,
        chapterId: number = null
    ): Observable<ApiExamMonthsInterface> {
        const params = new URLSearchParams([
            ['offset', offset.toString()],
            ['limit', limit],
            ['future', future ? '1' : '0'],
        ]);

        if (Number(offset) === 0) {
            params.append('include_exams', '1');
        }

        if (search !== null && search.length > 0) {
            params.append('search', search);
        }

        if (status !== null) {
            params.append('status', status);
        }

        if (groupId !== null) {
            params.append('group', groupId);

            if (userId !== null) {
                params.append('user', userId);
            }
        }

        if (bookId !== null) {
            params.append('book', String(bookId));

            if (chapterId !== null) {
                params.append('chapter', String(chapterId));
            }
        }

        return this.apiService.get(ApiEndpointEnum.ExamMonths, params);
    }

    public retrieveExamPlans(
        monthId: string,
        search: string = null,
        status: string | ExamsStatusFilterEnum = null,
        groupId: string = null,
        userId: string = null,
        bookId: number = null,
        chapterId: number = null
    ): Observable<ApiExamPlansInterface> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.ExamMonth, new Map([['monthId', monthId]]));

        const params = new URLSearchParams([
            ['offset', '0'],
            ['limit', '1000'],
        ]);

        if (search !== null && search.length > 0) {
            params.append('search', search);
        }

        if (status !== null) {
            params.append('status', status);
        }

        if (groupId !== null) {
            params.append('group', groupId);

            if (userId !== null) {
                params.append('user', userId);
            }
        }

        if (bookId !== null) {
            params.append('book', String(bookId));

            if (chapterId !== null) {
                params.append('chapter', String(chapterId));
            }
        }

        return this.apiService.get(apiRoute, params);
    }

    public retrieveExamPlan(planId: number): Observable<ApiExamPlanDetailsInterface> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.ExamPlan, new Map([['planId', planId.toString()]]));

        return this.apiService.get(apiRoute);
    }

    public retrieveExam(planId: number, examId: number, bookId: number): Observable<ApiExamDetailsInterface> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.Exam, new Map([
            ['examId', examId.toString()],
            ['planId', planId.toString()],
            ['bookId', bookId.toString()],
        ]));

        const searchParams = new URLSearchParams();
        searchParams.set('new-client', '1');

        return this.apiService.get(apiRoute, searchParams);
    }

    public retrieveResult(resultId: number): Observable<string> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.ExamResultUrl, new Map([
            ['resultId', resultId.toString()],
        ]));

        return this.apiService.get(apiRoute);
    }

    public deleteExam(planId: number): Observable<null> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.ExamRemove, new Map([
            ['planId', planId.toString()],
        ]));

        return this.apiService.post(apiRoute);
    }

    public retrieveResultForReview(resultId: number): Observable<ApiExamCheckInterface> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.ExamResult, new Map([
            ['resultId', resultId.toString()],
        ]));

        const searchParams = new URLSearchParams();
        searchParams.set('forReview', '1');

        return this.apiService.get(apiRoute, searchParams);
    }

    public gradeOpenQuestion(resultId: number, questionId: number, grade: number): Observable<ApiExamResultInterface> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.ExamGradeQuestion, new Map([
            ['resultId', resultId.toString()],
        ]));

        return this.apiService.post(apiRoute, {
            item: questionId,
            score: grade,
        });
    }

    public startExam(bookId: string, examDpsId: string, planId?: string): Observable<void> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.ExamContentStart, new Map([
            ['bookId', bookId],
            ['examDpsId', examDpsId],
            ['planId', String(planId)],
        ]));

        return this.apiService.get(apiRoute);
    }

    public retrieveExamAssignment(bookId: string, examDpsId: string, assignmentDpsId: string, attempt: number, planId: string, userId?: string): Observable<ApiExamStepInterface> {
        const urlSearchParams: URLSearchParams | undefined = new URLSearchParams([['userId', userId]]);
        const routeProperties = new Map([
            ['bookId', bookId],
            ['examDpsId', examDpsId],
            ['planId', planId],
            ['examAssignmentDpsId', assignmentDpsId],
            ['attempt', attempt.toString()]
        ]);

        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.ExamContentAssignment, routeProperties);

        return this.apiService.get(apiRoute, urlSearchParams);
    }

    public retrieveExamResults(bookId: string, examDpsId: string, planId: string): Observable<ApiExamMetadataInterface> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.ExamContentResult, new Map([
            ['bookId', bookId],
            ['examDpsId', examDpsId],
            ['planId', planId],
        ]));

        return this.apiService.get(apiRoute);
    }

    public setToDisableBookTitle(value: boolean): void {
        this.disableBookTitleSubject.next(value);
    }
}
