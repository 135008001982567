import {EventEmitter, Injectable} from '@angular/core';
import {DocumentSourceInterface} from 'document/modules/interfaces/source.interface';
import {DocumentSourceFactory} from 'document/modules/factories/source.factory';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class DocumentService {

    protected bookId: number;

    protected bookTitle: string;

    protected bookCover: string;

    protected document: ApiDocumentDataInterface;

    protected resources: Array<DocumentSourceInterface> = [];

    private selfStudyCompatibleDocument: BehaviorSubject<boolean> = new BehaviorSubject(true);

    public documentChanged: EventEmitter<ApiDocumentDataInterface> = new EventEmitter();

    public bookChanged: EventEmitter<ApiBookInterface> = new EventEmitter();

    constructor(
        private referenceFactory: DocumentSourceFactory,
    ) {
    }

    public setBook(book: ApiBookInterface | null = null): void {
        this.bookId = book ? book.id : null;
        this.bookTitle = book ? book.title : null;
        this.bookCover = book ? book.cover : null;

        this.bookChanged.emit(book);
    }

    public setBookId(bookId: number): void {
        this.bookId = bookId;
    }

    public getBookId(): number {
        return this.bookId;
    }

    public getBookTitle(): string {
        return this.bookTitle;
    }

    public getBookCover(): string {
        return this.bookCover;
    }

    public getDocument(): ApiDocumentDataInterface {
        return this.document;
    }

    public setDocument(document: ApiDocumentDataInterface): void {
        const documentSources: Array<ApiSourceInterface> | null = document.sources;

        if (null !== documentSources && documentSources.length > 0) {
            this.resources = this.referenceFactory.fromApiSources(documentSources);
        } else {
            this.resources = [];
        }

        this.documentChanged.emit(this.document = document);
    }

    public addResource(resource: DocumentSourceInterface): void {
        this.resources.push(resource);
    }

    public addOrReplaceResource(resource: DocumentSourceInterface): void {
        const existingResource: DocumentSourceInterface = this.resources.find(res => res.id === resource.id);

        if (existingResource !== undefined) {
            this.resources[this.resources.indexOf(existingResource)] = resource; // Replace at index
        } else {
            this.addResource(resource);
        }
    }

    public removeResourceById(id: string): void {
        const existingResource: DocumentSourceInterface = this.resources.find((resource) => resource.id === id);

        if (existingResource === undefined) {
            return;
        }

        this.resources.splice(this.resources.indexOf(existingResource), 1);
    }

    public getResources(): Array<DocumentSourceInterface> {
        return this.resources;
    }

    public hasAssignments(): boolean {
        return this.getDocument() && this.getDocument().assignments.length > 0;
    }

    public isBpvAssignment(): boolean {
        return this.hasAssignments() && this.getDocument().assignments[0].is_bpv;
    }

    public getActiveTask(): ApiTaskDetailsInterface | null {
        if (!this.hasAssignments()) {
            return null;
        }

        const tasks = this.getDocument().assignments[0].tasks;

        if (tasks.length === 0) {
            return null;
        }

        return tasks[tasks.length - 1];
    }

    public subscribeSelfStudyCompatible(): boolean {
        return !!this.selfStudyCompatibleDocument.subscribe();
    }
}
