import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ExamStateInterface, ExamStateService} from 'pages/modules/content-exam/service/exam-state.service';
import {ExamMetadataInterface, ExamMetadataService} from 'services/exam/exam-metadata.service';
import {IconEnum} from 'enums/icon.enum';
import {Subscription} from 'rxjs';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {RoleEnum} from 'enums/role.enum';
import {UserService} from 'security/services/user/user.service';

enum ContentActionItemState {
    Right = 50,
    Wrong = 60,
}

export interface ContentExamNavigationActionItemInterface {
    assignment?: ApiExamAssignmentInterface;
    state?: ContentActionItemState;
    icon?: string;
    title: string;
    active: boolean;
    enabled: boolean;
    answered: boolean;
    index?: number;
}

interface ContentExamNavigationButtonInterface {
    action: () => void;
    disabled?: boolean;
    title: string;
}

@Component({
    selector: 'app-content-exam-navigation',
    templateUrl: 'navigation.component.html',
})
export class ContentExamNavigationComponent implements OnInit, OnDestroy {

    private subscriptions: Subscription[] = [];

    private paginationFinishButton: ContentExamNavigationActionItemInterface = {
        title: 'Resultaten',
        icon: IconEnum.Flag,
        active: false,
        enabled: false,
        answered: false,
    };

    public readonly itemStateEnum = ContentActionItemState;

    @Input()
    public disabled: boolean = false;

    public readonly roleEnum = RoleEnum;

    public previousButton: ContentExamNavigationButtonInterface | undefined | null;

    public nextButton: ContentExamNavigationButtonInterface | undefined | null;

    public items: ContentExamNavigationActionItemInterface[] = [];

    public itemActive: ContentExamNavigationActionItemInterface | undefined;

    public readonly defaultPreviousButton: ContentExamNavigationButtonInterface = {
        title: 'Vorige',
        action: () => this.setPreviousItem(),
        disabled: true,
    };

    public readonly defaultNextButton: ContentExamNavigationButtonInterface = {
        title: 'Volgende',
        action: () => this.setNextItem(),
        disabled: false,
    };

    public paginationStart: number = 0;

    public paginationEnd: number = 0;

    private paginationWindow: number = 5;

    constructor(
        private examStateService: ExamStateService,
        private examMetadataService: ExamMetadataService,
        private userService: UserService,
        documentAnswersService: DocumentAnswersService,
    ) {
        this.subscriptions.push(examStateService.subscribe(examState => this.onExamState(examState)));
        this.subscriptions.push(examMetadataService.subscribe(examMetadata => this.onExamMetadata(examMetadata)));
        this.subscriptions.push(documentAnswersService.subscribe(answerState => this.onAnswerData(answerState)));
    }

    public ngOnInit(): void {
        if (0 !== this.items.length && undefined === this.itemActive) {
            this.setActive(this.items[0]);
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private onExamState(examState: ExamStateInterface): void {
        this.paginationFinishButton.enabled = examState.hasResults;
        if (examState.showResults) {
            this.setActive(this.paginationFinishButton);
        } else if (undefined !== examState.assignment) {
            this.setItemActiveByAssignment(examState.assignment);
        }
        // new attempt started
        if (!examState.assignment && !examState.showResults) {
            this.items.forEach((item) => {
                item.answered = false;
                item.state = undefined;
            });
        }

        this.updatePaginationNextButton(examState);
        this.updatePagination();

        this.subscriptions.push(this.examStateService.attemptChangeListener().subscribe(() => {
            this.updatePaginationOnAttemptChange(examState.attempt);
            this.updatePaginationNextButtonOnAttemptChange(examState);
        }));
    }

    private updatePaginationAttemptNextButton(examState: ExamStateInterface): void {
        if (undefined === examState) {
            return;
        }

        this.examStateService.setAttempt(examState.attempt + 1);
        this.updatePaginationOnAttemptChange(examState.attempt);
        this.updatePaginationNextButtonOnAttemptChange(examState);
        this.updatePagination();
        setTimeout(() => {
            this.setItemActiveByAssignment(this.items[0].assignment);
            this.examStateService.setAssignment(this.items[0].assignment);
        });
    }

    private updatePaginationOnAttemptChange(attempt: number): void {
        if (undefined === attempt) {
            return;
        }

        const results = this.examMetadataService.getResultByAttempt(attempt);

        this.items.filter(item => item.hasOwnProperty('assignment')).forEach(item => {
            if (results) {
                item.state = results.assignmentResults.find(result => result.dpsId === item.assignment.dpsId).score;
            }
        });

        this.updatePagination();
    }

    private updatePaginationNextButtonOnAttemptChange(examState: ExamStateInterface): void {
        let nextButton: ContentExamNavigationButtonInterface;

        if (examState.attemptHasResult && examState.attemptEnabled) {
            if (examState.showResults && !examState.inProgress) {
                nextButton = {
                    title: `Bekijk poging ${examState.attempt + 1}`,
                    action: () => this.updatePaginationAttemptNextButton(examState),
                };
            }

            this.nextButton = examState.isAtFinalAttempt ? null : nextButton;
        }
    }


    private onExamMetadata(examMetadata: ExamMetadataInterface): void {
        if (undefined === examMetadata.apiExamMetadata) {
            return;
        }

        const results = this.examMetadataService.getResultByAttempt(this.examStateService.getAttempt());

        this.items = examMetadata.apiExamMetadata.assignments.map((assignment: ApiExamAssignmentInterface, index: number) => {
            const active: boolean = examMetadata.apiExamMetadata.inProgress && !examMetadata.hasReachedMaxAttempts && 0 === index;
            const item: ContentExamNavigationActionItemInterface = {
                title: assignment.title,
                assignment: assignment,
                enabled: true,
                answered: assignment.hasAnswer,
                active,
                index
            };


            if (results) {
                const assignmentResult = results.assignmentResults.find(result => result.dpsId === item.assignment.dpsId);
                item.state = undefined !== assignmentResult ? assignmentResult.score : 0;
            }

            if (active) {
                this.itemActive = item;
            }

            return item;
        });

        if (this.userService.getUserData().role !== 20) {
            this.items.push(this.paginationFinishButton);
        }

        this.updatePagination();
    }

    private onAnswerData(answerState: ApiDocumentAssignmentInterface): void {
        this.items.forEach((item) => {
            if (item.assignment && item.assignment.dpsId === answerState.dpsid) {
                item.answered = true;
            }
        });

        if (!this.items.some(item => item.hasOwnProperty('assignment') && !item.answered) && undefined !== this.nextButton) {
            this.nextButton.disabled = false;
        }
    }

    public setItem(item?: ContentExamNavigationActionItemInterface | undefined): void {
        if (null === item || undefined === item || this.disabled || item === this.itemActive) {
            return;
        }

        this.examStateService.setAssignment(item.assignment);
    }

    private setPreviousItem(): void {
        const itemIndex: number = this.getActiveItemIndex();

        this.setItem(this.items[itemIndex - 1]);
    }

    private setNextItem(): void {
        const itemIndex: number = this.getActiveItemIndex();

        this.setItem(this.items[itemIndex + 1]);
    }

    private setItemActiveByAssignment(assignment: ApiExamAssignmentInterface): void {
        this.items.some(item => {
            if (assignment === item.assignment) {
                this.setActive(item);

                return true;
            }
        });
    }

    private setActive(item: ContentExamNavigationActionItemInterface): void {
        if (undefined !== this.itemActive) {
            this.itemActive.active = false;
        }

        this.itemActive = item;
        this.itemActive.active = true;
        this.updatePagination();
    }

    private updatePagination(): void {
        if (!this.items.length) {
            return;
        }
        const activeItemIndex: number = this.getActiveItemIndex();

        this.defaultPreviousButton.disabled = activeItemIndex <= 0;
        this.defaultNextButton.disabled = activeItemIndex >= (this.items.length - 1);

        this.paginationWindow = Math.min(this.paginationWindow, this.items.length);
        if (activeItemIndex < this.paginationWindow / 2) {
            this.paginationStart = 0;
            this.paginationEnd = this.paginationWindow;
        } else if (activeItemIndex > this.items.length - this.paginationWindow / 2) {
            this.paginationEnd = this.items.length;
            this.paginationStart = this.items.length - this.paginationWindow;
        } else {
            this.paginationStart = activeItemIndex - Math.floor(this.paginationWindow / 2);
            this.paginationEnd = activeItemIndex + Math.ceil(this.paginationWindow / 2);
        }
    }


    private getActiveItemIndex(): number {
        return this.items.indexOf(this.itemActive);
    }

    private updatePaginationNextButton(examState: ExamStateInterface): void {
        const user = this.userService.getUserData();

        let nextButton: ContentExamNavigationButtonInterface;

        if (examState.isAtFinalAssignment && !examState.hasResults) {
            nextButton = {
                title: 'Afronden',
                action: () => this.setNextItem(),
                disabled: false
            };
        }

        if (examState.showResults && !examState.inProgress && !examState.hasReachedMaxAttempts) {
            if (user.role === RoleEnum.RoleStudent) {
                nextButton = {
                    title: `Start poging ${examState.attempt + 1}`,
                    action: () => this.examStateService.startNextAttempt(),
                };
            }

            nextButton = {
                title: `Start volgende poging`,
                action: () => this.examStateService.startNextAttempt(),
            };

            this.userService.reload();
        }

        let defaultNextButton = false;
        if (!examState.showResults && examState.hasResults && examState.hasReachedMaxAttempts) {
            defaultNextButton = true;
            nextButton = {
                title: `Volgende`,
                action: () => this.setNextItem(),
            };
        }

        this.nextButton = examState.hasReachedMaxAttempts ? !defaultNextButton ? null : nextButton : nextButton;

        if (examState.attemptHasResult && examState.attemptEnabled) {
            if (examState.showResults && !examState.inProgress) {
                nextButton = {
                    title: `Bekijk poging ${examState.attempt + 1}`,
                    action: () => this.updatePaginationAttemptNextButton(examState),
                };
            }

            this.nextButton = examState.isAtFinalAttempt ? null : nextButton;
        }
    }
}
