import {Injectable} from '@angular/core';
import {ProgressChapterInterface} from 'pages/modules/progress/interfaces/chapter.interface';
import {ProgressChapterModel} from 'pages/modules/progress/models/chapter.model';
import {ProgressBookFactory} from 'pages/modules/progress/factories/book.factory';

@Injectable()
export class ProgressChapterFactory {

    constructor(
        private bookFactory: ProgressBookFactory,
    ) {
    }

    public fromApiProgressOverview(progress: ApiProgressOverviewInterface): Array<ProgressChapterInterface> {
        const chapters: Array<ProgressChapterInterface> = [];

        progress.book.chapters.forEach((chapter: ApiBookChapterInterface) => {
            chapters.push(new ProgressChapterModel(
                chapter.id,
                chapter.title,
                this.bookFactory.fromApiBook(progress.book),
                chapter.dpsid,
            ));
        });

        return chapters;
    }

}
