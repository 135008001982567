import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-exams-months',
  templateUrl: 'months.component.html',
})
export class ExamsMonthsComponent {

    @Input()
    public months: ApiExamMonthsInterface;

    @Output()
    public loadExamPlans: EventEmitter<string> = new EventEmitter();

    public onLoadExamPlans(weekId: string): void {
        this.loadExamPlans.emit(weekId);
    }
}
