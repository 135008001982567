export enum DocumentSubSectionSelectorsEnum {

    Goals = 'document-goals',

    Example = 'document-voorbeeld',

    Reading = 'document-reading',

    Level = 'document-verdieping',

    Summary = 'document-summary',

    TaskPlan = 'document-taskplan',

}
