import {NgModule} from '@angular/core';
import {DocumentResourceLinkDirective} from 'document/modules/directives/resource-link/resource-link.directive';
import {DocumentSectionDirective} from 'document/modules/directives/section/section.directive';
import {DocumentAssignmentDirective} from 'document/modules/directives/assignment/assignment.directive';
import {DocumentQuestionDirective} from 'document/modules/directives/question/question.directive';
import {DocumentOriginalContentDirective} from 'document/modules/directives/original-content/original-content.directive';
import {DocumentMathDirective} from 'document/modules/directives/math/math.directive';
import {DocumentSubHeadingDirective} from 'document/modules/directives/sub-heading/sub-heading.directive';
import {DocumentListDirective} from 'document/modules/directives/list/list.directive';

@NgModule({
    declarations: [
        DocumentOriginalContentDirective,
        DocumentResourceLinkDirective,
        DocumentSectionDirective,
        DocumentAssignmentDirective,
        DocumentQuestionDirective,
        DocumentMathDirective,
        DocumentSubHeadingDirective,
        DocumentListDirective,
    ],
    exports: [
        DocumentOriginalContentDirective,
        DocumentResourceLinkDirective,
        DocumentSectionDirective,
        DocumentAssignmentDirective,
        DocumentQuestionDirective,
        DocumentMathDirective,
        DocumentSubHeadingDirective,
        DocumentListDirective,
    ],
})
export class DocumentDirectivesModule {
}
