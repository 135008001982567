import {AfterViewInit, Component} from '@angular/core';
import {HelpPageModalComponent} from 'shared/modals/help-page/help-page-modal.component';
import {IconEnum} from 'enums/icon.enum';
import {HelpPageEnum} from 'services/help-page/enums/help-page.enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HelpPageContentService} from 'services/help-page/help-page-content.service';
import {UserService} from 'security/services/user/user.service';
import {NoHelpPageModel} from 'shared/modals/help-page/models/no-help-page.model';

@Component({
    selector: 'app-welcome-help-page-modal',
    templateUrl: '../help-page-modal.component.html',
})

export class WelcomeHelpPageModalComponent extends HelpPageModalComponent implements AfterViewInit {

    public icon: IconEnum = IconEnum.HomeRegular;

    public setCarousel: boolean = true;

    public pages: Array<HelpPageInterface> = [];

    private noHelPage = new NoHelpPageModel();

    constructor(
        public activeModal: NgbActiveModal,
        public popupPageService: HelpPageContentService,
        public userService: UserService
    ) {
        super(activeModal, popupPageService, userService);
    }

    public ngAfterViewInit(): void {
        this.loadData();
        this.carousel.pause();
        this.carousel.select('ngb-slide-0', this.NgbSlideEventSource.INDICATOR);
    }

    public loadData(): void {
        this.isLoading = true;
        this.popupPageService.retrieveContentByPage(HelpPageEnum.Welcome).toPromise()
            .then((pages: Array<HelpPageInterface>) => {
                this.pages = pages;

                if (this.pages.length < 1) {
                    this.pages.push(this.noHelPage);
                }

                this.isLoading = false;

                return Promise.resolve([]);
            }, () => {
                this.isLoading = false;
            });
    }

}
