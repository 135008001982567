import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'shared/shared.module';
import {NavBarComponent} from 'core/components/navbar/components/navbar.component';
import {NavbarRolesLogic} from 'core/components/navbar/authorization/roles.logic';
import {SecurityModule} from 'security/security.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        SecurityModule,
    ],
    declarations: [
        NavBarComponent,
    ],
    exports: [
        NavBarComponent,
    ],
    providers: [
        NavbarRolesLogic,
    ],
})
export class NavbarModule {
}
