import {Injectable} from '@angular/core';
import {GroupsModulePersonModel} from 'pages/modules/groups/models/person.model';

@Injectable()
export class GroupsModuleGroupModelPersonFactory {

    public fromApiGroupMember(apiGroupMember: ApiUserInterface): GroupsModulePersonModel {
        return new GroupsModulePersonModel(
            apiGroupMember.id,
            apiGroupMember.name,
            apiGroupMember.firstname,
            apiGroupMember.lastname,
            apiGroupMember.middlename,
            apiGroupMember.role
        );
    }

    public fromApiGroupMembers(apiGroupMembers: Array<ApiUserInterface>): Array<GroupsModulePersonModel> {
        const groupPersons: Array<GroupsModulePersonModel> = [];

        apiGroupMembers
            .forEach((apiGroupMember: ApiUserInterface) => {
                groupPersons.push(this.fromApiGroupMember(apiGroupMember));
            });

        return groupPersons;
    }

}
