import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ActionButtonFactory} from 'shared/factories/action-button.factory';
import {IconEnum} from 'enums/icon.enum';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {ApiService} from 'core/services/api/api.service';
import {ProgressGroupInterface} from 'pages/modules/progress/interfaces/group.interface';
import {ToastrService} from 'ngx-toastr';
import {InternshipService} from 'services/internship/internship.service';
import {ProgressStudentInterface} from 'pages/modules/progress/interfaces/student.interface';
import {ProgressModuleStatusInterface} from 'pages/modules/progress/interfaces/module-status.interface';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-bpv-attachment-detail',
    templateUrl: 'bpv-attachment-detail.component.html',
})
export class BpvAttachmentDetailComponent implements OnInit {

    public readonly tableClasses = GridTableClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly iconEnum = IconEnum;

    public readonly downloadUrl = environment.fileDownloadUrl;

    @ViewChild('file', { static: true })
    protected file: ElementRef;

    @Input()
    public group: ProgressGroupInterface;

    @Input()
    public user: ProgressStudentInterface | null = null;

    public action: ActionButtonInterface;

    public files: Array<ApiFileInterface> = [];

    constructor(
        private actionButtonFactory: ActionButtonFactory,
        private apiRequestService: ApiService,
        private internshipService: InternshipService,
        private toastService: ToastrService,
    ) {
        this.action = this.actionButtonFactory.createPurpleButton('progress-detail-bpv', 'Bestand toevoegen', IconEnum.Attachment, ['action-button--bpv']);
    }

    public ngOnInit(): void {
        if (!this.user.moduleStatus) {
            return;
        }

        this.files = this.user.moduleStatus.files;
    }

    public handleBpvAttachment(): void {
        const files: FileList = this.file.nativeElement.files;

        for (let i = 0; i < files.length; i++) {
            const file = files.item(i);

            this.uploadFile(file);
        }
    }

    public deleteFile(file: any): void {
        this.internshipService.deleteFile(this.user.moduleStatus.id, file.id).subscribe(() => {
            this.toastService.success('Bestand verwijderd');

            this.files.splice(this.files.indexOf(file), 1);
        }, () => {
            this.toastService.error('Kon het bestand niet uploaden.');
        });
    }

    private uploadFile(file: File): void {
        this.internshipService.attachFile(this.user.moduleStatus.id, file).subscribe((newModuleStatus: ProgressModuleStatusInterface) => {
            this.toastService.success('Bestand toegevoegd voor student.');

            this.files = newModuleStatus.files;
        }, () => {
            this.toastService.error('Kon het bestand niet uploaden.');
        });
    }
}
