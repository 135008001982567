import {AfterViewInit, Directive, ElementRef, OnDestroy, Renderer2} from '@angular/core';
import {WindowService} from 'services/window/window.service';
import {DomParentsHelper} from 'helpers/dom/parents.helper';
import {Subscription} from 'rxjs';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';
import {HeadingClassesEnum} from 'enums/heading-classes.enum';

@Directive({
    selector: 'h2',
})
export class DocumentSubHeadingDirective implements AfterViewInit, OnDestroy {

    protected static readonly CLASS: string = 'heading';

    private static documentElement: HTMLElement;

    private static offsetHeading: HTMLElement;

    private static subHeadings: number = 0;

    private offsetThreshold: number = 600;

    private onScrollSubscription: Subscription;

    private onResizeSubscription: Subscription;

    constructor(
        private elementRef: ElementRef,
        private windowService: WindowService,
        private parentsHelper: DomParentsHelper,
        private renderer: Renderer2,
    ) {
        DocumentSubHeadingDirective.subHeadings = 0;

        this.renderer.addClass(this.getNativeElement(), DocumentSubHeadingDirective.CLASS);
        this.renderer.addClass(this.getNativeElement(), HeadingClassesEnum.H2);
        this.renderer.addClass(this.getNativeElement(), HeadingClassesEnum.MarginBottom);
        this.renderer.addClass(this.getNativeElement(), HeadingClassesEnum.BootstrapGutter);

        // Todo BO-1373
        // this.setSubscriptions();
    }

    public ngAfterViewInit(): void {
        this.updateStaticProperties();
        this.setSubHeadingIndex();
        // this.handleElement();
    }

    public ngOnDestroy(): void {
        if (this.onScrollSubscription instanceof Subscription) {
            this.onScrollSubscription.unsubscribe();
        }
    }

    protected setSubscriptions(): void {
        this.onResizeSubscription = this.windowService.onResize.subscribe(() => this.updateElementWidth());
        this.onScrollSubscription = this.windowService.onScroll.subscribe(() => this.handleElement());
    }

    protected setSubHeadingIndex(): void {
        const computedZIndex: number = this.getComputedZIndex();

        this.getNativeElement().style.zIndex = String(computedZIndex + Number(this.getNativeElement().style.zIndex) + DocumentSubHeadingDirective.subHeadings);
        DocumentSubHeadingDirective.subHeadings++;
    }

    protected updateStaticProperties(): void {
        if (!(DocumentSubHeadingDirective.documentElement instanceof HTMLElement)) {
            DocumentSubHeadingDirective.documentElement = this.parentsHelper.findFirstParentWithClass(this.getNativeElement(), DocumentHostClassesEnum.Document);
        }
    }

    protected handleElement(): void {
        const offset: number = this.getElementOffset();
        const threshold: number = this.offsetThreshold;
        const documentOffset: number = DocumentSubHeadingDirective.documentElement.getBoundingClientRect().top + DocumentSubHeadingDirective.documentElement.offsetHeight;

        if (documentOffset < threshold) {
            this.handleAbsolute(offset);
        } else {
            this.handleSticky(offset);
        }
    }

    protected handleAbsolute(offset: number): void {
        if (this.isAbsolute()) {
            return;
        }

        const documentOffsetTop: number = DocumentSubHeadingDirective.documentElement.offsetHeight - this.offsetThreshold;

        this.switchClass(HeadingClassesEnum.Fixed, HeadingClassesEnum.Absolute);
        this.getNativeElement().style.top = `${documentOffsetTop + offset}px`;
    }

    protected handleSticky(offset: number): void {
        this.toggleClass(false, HeadingClassesEnum.Absolute); // Remove absolute class

        if (!this.isSticky() && this.getParentTop() < offset) {
            this.offsetScroll(true);
        } else if (this.isSticky() && this.getParentTop() >= offset) {
            this.offsetScroll(false);
        }
    }

    protected offsetScroll(toggle: boolean): void {
        this.toggleClass(toggle, HeadingClassesEnum.Fixed); // Add or remove sticky class

        if (toggle) {
            this.setElementProperties();
        } else {
            this.removeElementProperties();
        }

        this.updateElementWidth();
    }

    protected switchClass(targetClassName: HeadingClassesEnum, className: HeadingClassesEnum): void {
        this.toggleClass(false, targetClassName);
        this.toggleClass(true, className);
    }

    protected setElementProperties(): void {
        const size: number = this.getElementHeight();
        const offset: number = this.getElementOffset();

        this.getNativeElement().style.top = `${offset}px`;
        this.getParentElement().style.paddingTop = `${size}px`;
    }

    protected getElementHeight(): number {
        return this.getNativeElement().getBoundingClientRect().height + this.getParentPadding();
    }

    protected getElementOffset(): number {
        return DocumentSubHeadingDirective.offsetHeading ? DocumentSubHeadingDirective.offsetHeading.offsetHeight : 0;
    }

    protected removeElementProperties(): void {
        this.getNativeElement().style.top = null;
        this.getParentElement().style.paddingTop = null;
    }

    protected toggleClass(toggle: boolean, className: HeadingClassesEnum): void {
        const classList: DOMTokenList = this.getNativeElement().classList;

        if (toggle) {
            classList.add(className);
        } else {
            classList.remove(className);
        }
    }

    protected getParentElement(): HTMLElement {
        return this.elementRef.nativeElement.parentElement;
    }

    protected getParentTop(): number {
        return this.getParentElement().getBoundingClientRect().top;
    }

    protected getParentPadding(): number {
        const parentElement: HTMLElement = this.getParentElement();

        return parentElement.style.paddingTop ? Number(parentElement.style.paddingTop) : null;
    }

    protected getComputedZIndex(): number {
        const computedStyle: CSSStyleDeclaration = window.getComputedStyle(this.getNativeElement());

        return Number(computedStyle.zIndex);
    }

    protected isSticky(): boolean {
        return this.getNativeElement().classList.contains(HeadingClassesEnum.Fixed);
    }

    protected isAbsolute(): boolean {
        return this.getNativeElement().classList.contains(HeadingClassesEnum.Absolute);
    }

    protected updateElementWidth(): void {
        const parentWidth: number = DocumentSubHeadingDirective.documentElement.offsetWidth;

        this.getNativeElement().style.width = this.isSticky() ? `${parentWidth}px` : null;
    }

    protected getNativeElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }
}
