import {EventEmitter, Injectable} from '@angular/core';
import {PublisherDataInterface} from 'services/publisher/interfaces/data.interface';

@Injectable()
export class PublisherFactory {

    public fromApiPublisherInterface(api: ApiPublisherInterface): PublisherDataInterface {
        return {
            name: api.name,
            slug: api.slug,
            logo: api.logo,
            default: api.default,
            teacher_materials_url: api.teacher_materials_url,
            favicon: api.favicon || null,
            support_url: api.support_url
        };
    }

}
