import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {AvatarClassesEnum} from 'shared/components/avatar/enums/avatar-classes.enum';
import {RouteService} from 'routing/services/route/route.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HorizontalBarTextClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-text-classes.enum';
import {ExamUserResultService} from 'pages/modules/exams/service/exam-user-result.service';
import {Subscription} from 'rxjs';
import {RoutesEnum} from 'routing/enums/routes.enum';

@Component({
    selector: 'app-exams-exam-students',
    templateUrl: 'exam-students.component.html',
})
export class ExamsExamStudentsComponent implements OnDestroy, OnInit {

    private resultSubscription: Subscription;

    public readonly rowClasses = GridTableRowClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly horizontalBarTextClasses = HorizontalBarTextClassesEnum;

    public readonly avatarClasses = AvatarClassesEnum;

    public readonly iconEnum = IconEnum;

    @Input()
    public examPlan: ApiExamPlanDetailsInterface;

    public result: ApiExamUserResultInterface;

    private routeParamSubscription: Subscription;

    constructor(
        private routeService: RouteService,
        private router: Router,
        private userExamResultService: ExamUserResultService,
        private route: ActivatedRoute,
    ) {
        this.resultSubscription = this.userExamResultService.subscribe(result => {
            this.setResult(result);
        });
    }

    public ngOnInit(): void {
        this.routeParamSubscription = this.route.params.subscribe(() => {
            const studentId = Number(this.route.snapshot.params.studentId);

            for (const result of this.examPlan.results) {
                if (result.planUser.user.id === studentId) {
                    this.userExamResultService.setResult(result);
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.resultSubscription.unsubscribe();
        this.routeParamSubscription.unsubscribe();
    }

    public browseStudent(direction: number): void {
        const totalResults: number = this.examPlan.results.length;
        const currentIndex: number = this.examPlan.results.indexOf(this.result);

        if ((-1 === direction && currentIndex === 0) || (1 === direction && currentIndex === totalResults - 1)) {
            return;
        }

        const result = this.examPlan.results[currentIndex + direction];

        this.router.navigateByUrl(this.routeService.getRouteWithPublisher(RoutesEnum.ExamStudentDetails, new Map([
            ['planId', this.examPlan.id.toString()],
            ['studentId', result.planUser.user.id.toString()],
        ])));

    }

    private setResult(studentResult?: ApiExamUserResultInterface): void {
        this.result = studentResult;
    }
}
