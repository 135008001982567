import {Injectable} from '@angular/core';
import {ProgressAverageInterface} from 'pages/modules/progress/interfaces/average.interface';
import {ProgressAverageModel} from 'pages/modules/progress/models/average.model';
import {ProgressAssignmentStatusFactory} from 'pages/modules/progress/factories/assignment-status.factory';
import {ProgressExamScoreFactory} from 'pages/modules/progress/factories/exam-score.factory';

@Injectable()
export class ProgressAverageFactory {

    constructor(
        private assignmentStatusFactory: ProgressAssignmentStatusFactory,
        private examScoreFactory: ProgressExamScoreFactory,
    ) {
    }

    public fromApiProgressForStudentStudyMaterial(studyMaterial: ApiProgressForStudentStudyMaterialsInterface): ProgressAverageInterface {
        return new ProgressAverageModel(
            this.assignmentStatusFactory.fromApiProgressStudentScore(studyMaterial.scores[0]),
            this.examScoreFactory.fromApiProgressStudentScore(studyMaterial.scores[0]),
        );
    }

    public fromApiProgressGroup(progressGroup: ApiProgressGroupInterface): ProgressAverageInterface {
        return new ProgressAverageModel(
            this.assignmentStatusFactory.fromApiProgressAverage(progressGroup.avg[0]),
            this.examScoreFactory.fromApiProgressAverage(progressGroup.avg[0]),
        );
    }
}
