export class ClassHelper {

    protected static RootCollection: string = 'root';

    protected classCollectionContainer: Map<string, Array<string>> = new Map([
        [ClassHelper.RootCollection, []],
    ]);

    public constructor(className: string = null, collection: string = ClassHelper.RootCollection) {
        if (className !== null) {
            this.addClass(className, collection);
        }
    }

    public getClasses(collection: string = ClassHelper.RootCollection): Array<string> {
        if (!this.classCollectionContainer.has(collection)) {
            this.classCollectionContainer.set(collection, []);
        }

        return this.classCollectionContainer.get(collection);
    }

    public getAllClasses(): Array<string> {
        const classes = [];

        this.classCollectionContainer.forEach((values: Array<string>) => {
            classes.push(...values);
        });

        return classes;
    }

    public addClass(className: string, collection: string = ClassHelper.RootCollection): void {
        if (!this.classCollectionContainer.has(collection)) {
            this.classCollectionContainer.set(collection, []);
        }

        if (!this.classCollectionContainer.get(collection).includes(className)) {
            this.classCollectionContainer.get(collection).push(className);
        }
    }

    public addClasses(classes: Array<string>, collection: string = ClassHelper.RootCollection): void {
        classes.forEach((modifier: string) => {
            this.addClass(modifier, collection);
        });
    }

    public removeClass(className: string, collection: string = ClassHelper.RootCollection): void {
        const classes = this.getClasses(collection);
        const index: number = classes.indexOf(className);

        if (index !== -1) {
            classes.splice(index, 1);
        }
    }

    public toggleClass(className: string, collection: string = ClassHelper.RootCollection): void {
        const classNameIndex = this.getClasses(collection).indexOf(className);

        if (classNameIndex !== -1) {
            this.removeClass(className, collection);
        } else {
            this.addClass(className, collection);
        }
    }

    public toggleClassByBoolean(className: string, toggle: boolean, collection: string = ClassHelper.RootCollection): void {
        if (toggle && this.hasClass(className, collection) || !toggle && !this.hasClass(className, collection)) {
            return;
        }

        if (!toggle) {
            this.removeClass(className, collection);
        } else {
            this.addClass(className, collection);
        }
    }

    public hasClass(className: string, collection: string = ClassHelper.RootCollection): boolean {
        return this.getClasses(collection).includes(className);
    }

    public toString(collection: string = ClassHelper.RootCollection): string {
        return this.getClasses(collection).join(' ');
    }

    public clear(collection: string = ClassHelper.RootCollection): void {
        this.classCollectionContainer.set(collection, []);
    }
}
