import {Injectable} from '@angular/core';
import {ProgressStudyMaterialInterface} from 'pages/modules/progress/interfaces/study-material.interface';
import {ProgressStudyMaterialModel} from 'pages/modules/progress/models/study-material.model';
import {ProgressAverageFactory} from 'pages/modules/progress/factories/average.factory';
import {ProgressAssignmentStatusFactory} from 'pages/modules/progress/factories/assignment-status.factory';
import {ProgressDrillsterStatusFactory} from 'pages/modules/progress/factories/drillster-status.factory';
import {ProgressExamsFactory} from 'pages/modules/progress/factories/exams.factory';

@Injectable()
export class ProgressStudyMaterialFactory {

    constructor(
        private averageFactory: ProgressAverageFactory,
        private assignmentStatusFactory: ProgressAssignmentStatusFactory,
        private drillsterFactory: ProgressDrillsterStatusFactory,
        private examsFactory: ProgressExamsFactory,
    ) {
    }

    public fromApiProgressForStudent(progressForStudent: ApiProgressForStudentInterface): ProgressStudyMaterialInterface {
        return new ProgressStudyMaterialModel(
            this.assignmentStatusFactory.fromApiProgressForStudent(progressForStudent),
            this.drillsterFactory.fromApiProgressForStudent(progressForStudent),
            this.averageFactory.fromApiProgressForStudentStudyMaterial(progressForStudent.studymaterials[0]),
            this.examsFactory.fromApiProgressForStudent(progressForStudent),
        );
    }
}
