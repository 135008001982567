import {Component, OnDestroy} from '@angular/core';
import {ExamStateInterface, ExamStateService} from 'pages/modules/content-exam/service/exam-state.service';
import {ExamMetadataInterface, ExamMetadataService} from 'services/exam/exam-metadata.service';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';

interface ContentExamAttemptEntryInterface {
    attempt: number;
    active: boolean;
    enabled: boolean;
    hasResult: boolean;
}

@Component({
    selector: 'app-content-exam-navigation-attempt',
    templateUrl: 'navigation-attempt.component.html',
})
export class ContentExamNavigationAttemptComponent implements OnDestroy {

    private subscriptions: Subscription[] = [];

    private totalResults: number;

    public readonly attemptEntries: ContentExamAttemptEntryInterface[] = [];

    public constructor(private examMetadataService: ExamMetadataService, private examStateService: ExamStateService) {
        this.subscriptions.push(examMetadataService.subscribe(examState => this.onExamMetadata(examState)));
        this.subscriptions.push(examStateService.subscribe(examState => this.onExamState(examState)));
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public handleAttemptClick(attemptEntry: ContentExamAttemptEntryInterface): void {
        if (!attemptEntry.enabled || attemptEntry.active) {
            return;
        }

        let nextAttempt = this.attemptEntries.find(attemptEntryNext => attemptEntryNext.attempt === (attemptEntry.attempt + 1));
        let isLastAttempt: boolean = false;

        if (undefined === nextAttempt) {
            nextAttempt = this.attemptEntries.find(attemptEntryNext => attemptEntryNext.attempt === attemptEntry.attempt);
            isLastAttempt = true;
        }

        this.examStateService.setAttempt(attemptEntry.attempt, isLastAttempt, nextAttempt.hasResult, nextAttempt.enabled);
        this.examStateService.emitAttemptChange(attemptEntry.attempt);
    }

    private onExamMetadata(examMetadata: ExamMetadataInterface): void {
        if (undefined === examMetadata.apiExamMetadata) {
            return;
        }

        this.totalResults = examMetadata.results.length;
        if (this.attemptEntries.length === 0) {
            _.times(examMetadata.maxAttempts).forEach((index: number) => {
                const attempt: number = index + 1;
                const result: ApiContentExamResultInterface | undefined = this.examMetadataService.getResultByAttempt(attempt);
                const hasResult: boolean = undefined !== result;

                this.attemptEntries.push({active: false, enabled: false, attempt, hasResult});
            });
        }
    }

    private onExamState(examState: ExamStateInterface): void {
        this.attemptEntries.forEach(attemptEntry => {
            attemptEntry.active = attemptEntry.attempt === examState.attempt;
            attemptEntry.enabled = examState.inProgress && attemptEntry.attempt === examState.attempt
                || !examState.inProgress && attemptEntry.attempt <= this.totalResults;
        });
    }
}
