import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'shared/shared.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {BrowserModule} from '@angular/platform-browser';
import {TeacherMaterialComponent} from 'pages/modules/teacher-material/components/teacher-material.component';
import {TeacherMaterialItemComponent} from 'pages/modules/teacher-material/components/item/item.component';
import {RouterModule} from '@angular/router';
import { ShelfComponent } from 'app/modules/pages/modules/teacher-material/components/shelf/shelf.component';
import { ShelfSourceComponent } from 'pages/modules/teacher-material/components/shelf/source/source.component';
import { ShelfFolderComponent } from 'app/modules/pages/modules/teacher-material/components/shelf/folder/folder.component';
import {ShelfReturnComponent} from 'pages/modules/teacher-material/components/shelf/return/return.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        BrowserModule,
        InfiniteScrollModule,
        RouterModule,
    ],
    declarations: [
        TeacherMaterialComponent,
        TeacherMaterialItemComponent,
        ShelfComponent,
        ShelfSourceComponent,
        ShelfFolderComponent,
        ShelfReturnComponent,
    ],
    exports: [],
})
export class TeacherMaterialModule {
}
