import {NavbarItemsEnum} from 'core/components/navbar/enums/navbar-items.enum';
import {ColorEnum} from 'enums/color.enum';
import {IconEnum} from 'enums/icon.enum';
import {RoleEnum} from 'enums/role.enum';
import {ButtonRolesInterface} from 'shared/components/button/interfaces/button-roles.interface';
import {NavbarButton} from 'core/components/navbar/classes/navbar.button';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {HelpPageEnum} from 'services/help-page/enums/help-page.enum';

export class NavbarGroupsButtonModel extends NavbarButton implements ButtonRolesInterface {

    public static readonly id: string = 'groups';

    constructor(public title: string = 'groepen') {
        super(
            NavbarItemsEnum.Groups,
            RoutesEnum.Groups,
            title,
            ColorEnum.Green,
            [
                RoleEnum.RoleNavbarGroups,
            ],
            IconEnum.Users
        );
    }
}
