import {AnalyticsEventCategoryEnum} from 'services/analytics/enums/event-category.enum';
import {AnalyticsEventActionEnum} from 'services/analytics/enums/event-action.enum';
import {AnalyticsEventModel} from 'services/analytics/models/event.model';

export class AnalyticsEventMessageShowModel extends AnalyticsEventModel {

    public constructor(messageId: number, messageTitle: string) {
        super(AnalyticsEventCategoryEnum.Message, AnalyticsEventActionEnum.MessageShow, messageId, messageTitle);
    }

}
