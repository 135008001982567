import {AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, } from '@angular/core';
import {DocumentMultiSelectBlockOptionsInterface} from 'document/modules/components/multi-select-block/interface/options.interface';
import {DocumentAnswerableComponent} from 'document/modules/abstract/answerable.component';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {DocumentMultiSelectBlockOptionInterface} from 'document/modules/components/multi-select-block/interface/option.interface';
import {take} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {AutoCheckService} from 'document/services/autocheck/auto-check.service';
import {RoleEnum} from 'enums/role.enum';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';
import {DocumentService} from 'document/services/document/document.service';

// MEERMEERKEUZEVRAGEN

@Component({
    selector: '.document-multipleselectblock',
    templateUrl: 'multi-select-block.component.html',
})
export class DocumentMultiSelectBlockComponent extends DocumentAnswerableComponent implements AfterViewInit, OnDestroy {

    private baseElementClass: string = 'document__multi-select-block';

    @HostBinding('class')
    public elementClass: string = this.baseElementClass;

    public readonly roleEnum = RoleEnum;

    public readonly examinationNeededEnum = ExamineOptionsEnum;

    public examinationByStudent: boolean = false;

    public id: string;

    public value: any = {};

    public correctAnswer: any = {};

    public initialAnswer: any;

    public options: DocumentMultiSelectBlockOptionsInterface;

    protected dataSet: DocumentDatasetOptionsInterface;

    public answerHidden: boolean;

    public autoScoreEnabled: boolean;

    public autoScoreAnswer: any;

    private subscriptions: Array<Subscription> = [];

    constructor(
        protected elementRef: ElementRef,
        protected answersService: DocumentAnswersService,
        protected showAnswersService: DocumentAnswersStateService,
        private autocheckService: AutoCheckService,
        private documentService: DocumentService,
    ) {
        super(elementRef, answersService, showAnswersService);

        this.id = `multi-select-block-${this.dataSet.assignmentid}-${this.dataSet.fieldid}`;

        try {
            this.options = JSON.parse(this.dataSet.options);

            for (const choice of this.options.choices) {
                this.value[choice.value] = false;
            }
        } catch (e) {
            this.initializationFailed = true;

            console.error('Failed to JSON parse the options:', e);
        }
    }

    public onChange(): void {
        this.saveAnswer(JSON.stringify(this.value));
    }

    public ngAfterViewInit(): void {
        this.setupAutoScoring();
        this.handleExaminationNeeded();
    }

    private setupAutoScoring(): void {
        const assignmentId = this.dataSet.assignmentid;
        const fieldId = parseInt(this.dataSet.fieldid, 10);
        const assignment = this.answersService.getAssignment(assignmentId);
        this.autoScoreEnabled = assignment && assignment.auto_check;

        if (! this.autoScoreEnabled) { return; }

        this.subscriptions.push( this.autocheckService.getAutoCheckAnswerForFieldId(assignmentId, fieldId).pipe(take(1)).subscribe( autoCheckAnswer => {

            if (autoCheckAnswer) {
                this.autoScoreAnswer = {};
                const answersArray = autoCheckAnswer.replace(/\\n/g, '\n').replace(/\\/g, '').split('\n');
                for (const choice of this.options.choices) {
                    this.autoScoreAnswer[choice.value] = answersArray.indexOf(choice.label) >= 0 || answersArray.indexOf(choice.value) >= 0;
                }
            }
        }));
    }

    private handleExaminationNeeded(): void {
        const task = this.documentService.getActiveTask();

        // examination by Student
        if (task && task.examination_needed === this.examinationNeededEnum.Student) {
            this.examinationByStudent = this.documentService.subscribeSelfStudyCompatible();
        }
    }

    protected setAnswer(answer: string): void {
        if (!answer) {
            return;
        }

        this.value = this.getDecodedAnswer(answer);
    }

    protected showCorrectAnswer(correctAnswer: string): void {

        const answerOptions = correctAnswer.replace(/\\n/g, '\n').replace(/\\/g, '').split('\n');

        for (const choice of this.options.choices) {

            this.correctAnswer[choice.value] = answerOptions.indexOf(choice.label) >= 0 || answerOptions.indexOf(choice.value) >= 0;
        }

        this.answerHidden = false;
    }

    protected hideCorrectAnswer(): void {
        this.answerHidden = true;
    }

    protected showInitialAnswer(initialAnswer: string): void {
        if (!initialAnswer || initialAnswer === JSON.stringify(this.value)) {
            return;
        }

        this.initialAnswer = JSON.parse(initialAnswer);
    }

    protected answerValid(): boolean {
        return true;
    }

    public selectOption(choice: DocumentMultiSelectBlockOptionInterface): void {
        this.value[choice.value] = !this.value[choice.value];
        this.saveAnswer(JSON.stringify(this.value));
    }

    public ngOnDestroy(): void {
        this.autocheckService.resetAutoCheckAnswer();
        this.subscriptions.map(sub => sub.unsubscribe());
    }
}
