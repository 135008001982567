import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxClose} from 'models/iconbox/close.model';

@Component({
    selector: 'app-neutral-feedback-modal',
    templateUrl: 'neutral-feedback-modal.component.html',
})
export class NeutralFeedbackModalComponent {

    @Input()
    public neutralFeedbackContent: string;

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorBlue]);

    public isLoading: boolean = false;

    constructor(
        private activeModal: NgbActiveModal,
    ) {
    }

    public close(): void {
        this.activeModal.dismiss();
    }
}
