export class AngularEnumSelectorHelper {

    public static getAsSelector(enumObject: Object): string {
        return Object.keys(enumObject).map(key => `.${enumObject[key]}`).join(', ');
    }

    public static findValueByDOMTokenList(enumObject: Object, list: DOMTokenList): string {
        const documentSelectors: Array<string> = Object.keys(enumObject).map(key => enumObject[key]);

        return documentSelectors.find(selector => list.contains(selector));
    }

}
