import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GridTableComponent} from 'shared/modules/grid-table/components/grid-table.component';
import {GridTableRowComponent} from 'shared/modules/grid-table/components/row/grid-table-row.component';
import {GridTableColComponent} from 'shared/modules/grid-table/components/col/grid-table-col.component';
import {GridTableScrollColCellDirective} from 'shared/modules/grid-table/components/scroll-col/column/cell/cell.directive';
import {GridTableScrollColColumnDirective} from 'shared/modules/grid-table/components/scroll-col/column/column.directive';
import {GridTableScrollColHeaderDirective} from 'shared/modules/grid-table/components/scroll-col/column/header/header.directive';
import {GridTableScrollControlComponent} from 'shared/modules/grid-table/components/scroll-control/scroll-control.component';
import {PipesModule} from 'shared/modules/pipes/pipes.module';
import {GridTableScrollColComponent} from 'shared/modules/grid-table/components/scroll-col/scroll-col.component';
import {GridTableRowFloatableComponent} from 'shared/modules/grid-table/components/row-floatable/grid-table-row-floatable.component';
import {GridTableColFloatableComponent} from 'shared/modules/grid-table/components/col-floatable/grid-table-col-floatable.component';

@NgModule({
    imports: [
        CommonModule,
        PipesModule,
    ],
    declarations: [
        GridTableComponent,
        GridTableRowComponent,
        GridTableRowFloatableComponent,
        GridTableColComponent,
        GridTableColFloatableComponent,
        GridTableScrollControlComponent,
        GridTableScrollColComponent,
        GridTableScrollColColumnDirective,
        GridTableScrollColCellDirective,
        GridTableScrollColHeaderDirective,
    ],
    exports: [
        GridTableComponent,
        GridTableRowComponent,
        GridTableRowFloatableComponent,
        GridTableColComponent,
        GridTableColFloatableComponent,
        GridTableScrollControlComponent,
        GridTableScrollColComponent,
        GridTableScrollColColumnDirective,
        GridTableScrollColCellDirective,
        GridTableScrollColHeaderDirective,
    ],
})
export class GridTableModule {
}
