import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ActionButtonFactory} from 'shared/factories/action-button.factory';
import {FilterDropdownOptionsFactory} from 'shared/factories/filter-dropdown-options.factory';
import {GroupsService} from 'services/groups/groups.service';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {ProgressService} from 'services/progress/progress.service';
import {RoleEnum} from 'enums/role.enum';
import {BookService} from 'services/book/book.service';
import {TaskService} from 'services/task/task.service';
import {IconEnum} from 'enums/icon.enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import {ProgressConstantsEnum} from 'pages/modules/progress/enums/constants.enum';
import {ResultClassEnum} from 'shared/components/result-cell/enums/result-class.enum';
import {WorkTypeEnum} from 'shared/components/work-type-label/enums/work-type.enum';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';
import {WorkTypeClassesEnum} from 'shared/components/work-type-label/enums/work-type-classes.enum';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {TaskTitleClassesEnum} from 'shared/components/task-title/enums/classes.enum';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {Router} from '@angular/router';
import {RouteService} from 'routing/services/route/route.service';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';

@Component({
    selector: 'app-progress-own-tasks',
    templateUrl: 'own-tasks.component.html',
})
export class ProgressOwnTasksComponent implements OnChanges {

    public readonly constantsEnum = ProgressConstantsEnum;

    public readonly iconEnum = IconEnum;

    public readonly resultClassEnum = ResultClassEnum;

    public readonly tableClasses = GridTableClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly workTypeEnum = WorkTypeEnum;

    public readonly workTypeClasses = WorkTypeClassesEnum;

    public readonly examinationNeededEnum = ExamineOptionsEnum;

    public readonly taskTitleClasses = TaskTitleClassesEnum;

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    @Input()
    public classHelper: ClassHelper;

    @Input()
    public search: string;

    @Input()
    public book: ApiBookInterface;

    @Input()
    public chapter: any;

    @Input()
    public group: ApiGroupInterface;

    @Input()
    public user: ApiUserInterface;

    public tasks: Array<ApiTaskDetailsInterface> = [];

    public expanded: boolean = false;

    public isLoading: boolean = false;

    constructor(
        private actionButtonFactory: ActionButtonFactory,
        private filterDropdownOptionsFactory: FilterDropdownOptionsFactory,
        private groupService: GroupsService,
        private progressService: ProgressService,
        private authorizationService: AuthorizationService,
        private bookService: BookService,
        private taskService: TaskService,
        private router: Router,
        private routeService: RouteService,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.expanded) {
            this.loadData();
        }
    }

    public handleOnExpand(): void {
        this.expanded = true;
        this.loadData();
    }

    public handleOnCollapse(): void {
        this.expanded = false;
    }

    /**
     * Convert numbers to letters (A - ZZZZZ)
     */
    public toLetter(num: number): string {
        const mod = num % 26;
        // tslint:disable-next-line:no-bitwise
        let pow = num / 26 | 0;
        // noinspection CommaExpressionJS
        const out = mod ? String.fromCharCode(64 + mod) : (pow--, 'Z');

        return (pow ? this.toLetter(pow) + out : out).toLowerCase();
    }

    public taskIsTooLate(task: ApiTaskDetailsInterface): boolean {
        return task.duedate < (Date.now() / 1000) && task.handedin_count < task.children_count;
    }

    public getCorrectChildTask(task: ApiTaskDetailsInterface): ApiTaskDetailsInterface {
        if (!task.owner) {
            return task;
        }

        return task.children.find((childTask) => childTask.assigned_to_id === this.user.id);
    }

    public navigateToTask(task: ApiTaskDetailsInterface): void {
        this.router.navigateByUrl(this.routeService.getRouteWithPublisher(
            RoutesEnum.TaskDetails, new Map([
                ['taskId', task.id.toString()],
            ])
        ));
    }

    private loadData(): void {
        this.isLoading = true;

        let prom = null;
        if (this.authorizationService.isGranted(RoleEnum.RoleTeacher)) {
            let groupId = null;
            if (this.group) {
                groupId = Number(this.group.id);
            }

            let studentId = null;
            if (this.user) {
                studentId = Number(this.user.id);
            }

            prom = this.taskService.retrieveTasksForProgress(this.book.id, this.chapter, groupId, studentId, this.search).toPromise();
        } else {
            prom = this.taskService.retrieveTasksForProgress(this.book.id, this.chapter, null, null, this.search).toPromise();
        }

        prom.then((tasks: Array<ApiTaskDetailsInterface>) => {
            this.handleData(tasks);
        }).finally(() => {
            this.isLoading = false;
        });
    }

    private handleData(tasks: Array<ApiTaskDetailsInterface>): void {
        this.tasks = tasks;
    }
}
