import {ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import {GroupsModuleClassesEnum} from 'pages/modules/groups/enums/classes.enum';
import {GroupsModuleGroupFilterEnum} from 'pages/modules/groups/enums/group-filter.enum';
import {GroupsService} from 'services/groups/groups.service';
import {InfiniteScroll} from 'shared/classes/infinite-scroll.abstract';
import {SchoolService} from 'services/school/school.service';
import {GroupsModuleGroupModelFactory} from 'pages/modules/groups/factories/group-model.factory';
import {Observable, Subscription} from 'rxjs';
import {IconEnum} from 'enums/icon.enum';
import {SearchInterface} from 'shared/modules/search/interfaces/search.interface';
import {SearchModeEnum} from 'shared/modules/search/enums/search-mode.enum';
import {GroupsModuleGroupInterface} from 'pages/modules/groups/interfaces/group.interface';
import {ModalService} from 'core/services/modal/modal.service';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {RoleService} from 'security/services/role/role.service';
import {RoleEnum} from 'enums/role.enum';
import {FilterButtonGroupInterface} from 'shared/components/filter-button-group/interfaces/filter.interface';
import {LocalStorageService} from 'angular-2-local-storage';
import {LocalStorageKeysEnum} from 'enums/local-storage-keys.enum';
import {FilterDropdownFilterInterface} from 'shared/components/filter-dropdown/interfaces/filter.interface';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
    selector: 'app-groups-component',
    templateUrl: 'groups.component.html',
})
export class GroupsComponent extends InfiniteScroll<ApiGroupsInterface> implements OnDestroy {

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly classesEnum = GroupsModuleClassesEnum;

    public readonly roleEnum = RoleEnum;

    public groupFilter: FilterButtonGroupInterface<string> = {
        id: LocalStorageKeysEnum.Group,
        options: new Map<string, string>([
            [GroupsModuleGroupFilterEnum.All, 'Alle'],
            [GroupsModuleGroupFilterEnum.Active, 'Actief'],
            [GroupsModuleGroupFilterEnum.Inactive, 'Inactief'],
        ])
    };

    public filters: FilterDropdownFilterInterface<GroupsModuleGroupFilterEnum> = {
        id: LocalStorageKeysEnum.Group,
        selectedValue: GroupsModuleGroupFilterEnum.All,
        options: new Map<string, Map<GroupsModuleGroupFilterEnum, string>>([
            ['', new Map<GroupsModuleGroupFilterEnum, string>([
                [GroupsModuleGroupFilterEnum.All, 'Alle'],
                [GroupsModuleGroupFilterEnum.Active, 'Actief'],
                [GroupsModuleGroupFilterEnum.Inactive, 'Inactief'],
            ])],
        ]),
    };

    public groups: Array<GroupsModuleGroupInterface> = [];

    public searchValue: string;

    public searchConfig: SearchInterface = {
        prefixIcon: IconEnum.Search,
        placeHolder: 'Zoek een groep',
        mode: SearchModeEnum.Delay,
    };

    private itemCount: number = 0;

    private groupEventSubscription: Subscription;

    constructor(
        public deviceService: DeviceDetectorService,
        private groupsService: GroupsService,
        private schoolService: SchoolService,
        private roleService: RoleService,
        private authorizationService: AuthorizationService,
        private groupModelFactory: GroupsModuleGroupModelFactory,
        private modalService: ModalService,
        private readonly localStorageService: LocalStorageService,
        protected change: ChangeDetectorRef,
    ) {
        super(change);
        this.setInitialActiveState();
    }

    public ngOnDestroy(): void {
        this.clearGroupEventSubscription();
    }

    public handleJoinGroup(): void {
        const modalInstance = this.modalService.groupJoin();

        this.clearGroupEventSubscription();

        this.groupEventSubscription = modalInstance.groupEvent.subscribe(() => {
            this.loadData(true);
            this.updateRoles();
        });
    }

    public handleAddGroup(): void {
        const modalInstance = this.modalService.groupAdd();

        this.clearGroupEventSubscription();
        this.groupEventSubscription = modalInstance.groupEvent.subscribe((group: GroupsModuleGroupInterface) => {
            this.groups.unshift(group);
            this.updateRoles();
        });
    }

    public handleGroupRemoved(group: GroupsModuleGroupInterface): void {
        this.groups.splice(this.groups.indexOf(group), 1);
        this.updateRoles();
    }

    public onSearchChange(search: string): void {
        this.searchValue = search;
        this.loadData(true);
    }

    public onGroupFilterChange(event: FilterButtonGroupInterface<string> | FilterDropdownFilterInterface<string>): void {
        this.localStorageService.set(this.getLocalStorageKey(), this.isFilterButtonGroupInterface<string>(event) ? event.selected : event.selectedValue);
        this.loadData(true);
    }

    protected reset(): void {
        this.groups = [];
        super.reset();
    }

    protected getDataLength(): number {
        return this.itemCount;
    }

    protected getLoadDataObservable(): Observable<ApiGroupsInterface> {
        const state: GroupsModuleGroupFilterEnum = this.localStorageService.get<GroupsModuleGroupFilterEnum>(this.getLocalStorageKey());

        let active;
        if (state === GroupsModuleGroupFilterEnum.Active) {
            active = true;
        } else if (state === GroupsModuleGroupFilterEnum.Inactive) {
            active = false;
        }

        return this.groupsService.retrieveGroups(this.getRequestOffset(), this.limit, this.searchValue, active);
    }

    protected subscribe(response: ApiGroupsInterface): void {
        this.groups = [...this.groups, ...this.groupModelFactory.fromApiGroups(response)];
        this.itemCount = response.total;
    }

    /**
     * BO-1570 Add navbar role when teacher has groups
     */
    private updateRoles(): void {
        this.roleService.reloadRoles();
    }

    private clearGroupEventSubscription(): void {
        if (this.groupEventSubscription instanceof Subscription) {
            this.groupEventSubscription.unsubscribe();
        }
    }

    private getLocalStorageKey(): string {
        return `group-${LocalStorageKeysEnum.Group}`;
    }

    private setInitialActiveState(): void {
        const persisted: GroupsModuleGroupFilterEnum = this.localStorageService.get<GroupsModuleGroupFilterEnum>(this.getLocalStorageKey()) || GroupsModuleGroupFilterEnum.All;
        this.localStorageService.set(this.getLocalStorageKey(), persisted);
        this.filters.selectedValue = this.groupFilter.selected = persisted;
    }

    private isFilterButtonGroupInterface<T>(event: FilterButtonGroupInterface<T> | FilterDropdownFilterInterface<string>): event is FilterButtonGroupInterface<T> {
        return event.hasOwnProperty('selected');
    }
}
