import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TabComponent} from 'shared/modules/tab/components/tab.component';
import {TabItemComponent} from 'shared/modules/tab/components/tab-item/tab-item.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        TabComponent,
        TabItemComponent,
    ],
    exports: [
        TabComponent,
    ],
})
export class TabModule {
}
