import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserIdentityModel} from 'core/models/user-identity.model';
import {finalize} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {UserService} from 'security/services/user/user.service';
import {ToastrService} from 'ngx-toastr';
import {UserIdentityModelFactory} from 'core/factories/user-identity-model.factory';
import {ActivatedRoute} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {OneDriveService} from 'onedrive/services/onedrive.service';
import {MsAlLoginService} from 'onedrive/services/msallogin.service';
import {AuthenticationService} from 'security/services/authentication/authentication.service';
import {ApiUserDataInterface} from 'interfaces/api/user-data.interface';
import Timer = NodeJS.Timer;
import {environment} from 'environments/environment';

@Component({
    selector: 'app-one-drive',
    templateUrl: 'one-drive.component.html',
})
export class OneDriveComponent implements OnInit, OnDestroy {

    public userIdentity: UserIdentityModel;

    public isLoading: boolean = false;

    public isLoggedIn: boolean = false;

    public tooltip: string = 'Kies je voor ‘Ja’ dan wordt ieder bestand dat je vanuit een boek opent automatisch gedownload naar je OneDrive in een aparte folder met de naam ‘Boom Digitaal’.';

    public tooltipSettings = 'Heb je een office pakket geïnstalleerd op je device en wil je deze standaard gebruiken, kies dan voor ‘Office lokaal’. Kies anders voor ‘Office online’.';

    public isTablet: boolean = false;

    public enabled: boolean = false;

    private timeOuts: Array<Timer> = [];

    public archiveModeActive: boolean = environment.archiveModeActive;

    constructor(
        private userIdentityModelFactory: UserIdentityModelFactory,
        private activatedRoute: ActivatedRoute,
        private userService: UserService,
        private toastService: ToastrService,
        private deviceService: DeviceDetectorService,
        private oneDriveService: OneDriveService,
        private msAlLoginService: MsAlLoginService,
        private http: HttpClient,
        private authenticationService: AuthenticationService
    ) {
    }

    public ngOnInit(): void {
        this.isTablet = this.deviceService.isTablet();
        const userData = this.userService.getUserData();
        if (userData) {
            this.retrieveUser(userData);
        }

        this.enabled = this.msAlLoginService.getLoggedInUser() === this.userIdentity.one_drive_username;

        if (this.userIdentity.one_drive_username === null || this.userIdentity.one_drive_username === '') {
            this.msAlLoginService.loginCompleted.subscribe(
                (result: boolean) => {
                    if (result) {
                        this.signUp();
                    } else {
                        this.toastService.warning('Kon onedrive niet configureren.');
                    }
                },
            );
        }
    }

    private retrieveUser(userData: ApiUserDataInterface): void {
        this.userIdentity = this.userIdentityModelFactory.fromUserData(userData);
    }

    public signUp(): void {
        this.msAlLoginService.authenticate(true, true).then(
            (token) => {
                const graphUrl = `https://graph.microsoft.com/v1.0/me/drive`;
                this.http.get(graphUrl).subscribe(
                    (response: any) => {
                        this.userIdentity.use_one_drive = true;
                        if (response.driveType === 'personal') {
                            this.userIdentity.one_drive_root = 'https://d.docs.live.net/' + response.id;
                        } else {
                            this.userIdentity.one_drive_root = response.webUrl;
                        }
                        this.userIdentity.one_drive_username = token.account.userName;
                        this.save(true);

                        this.timeOuts.push(
                            setTimeout(() => {
                                this.authenticationService.msalLogin(false);
                            }, 1800000)
                        );
                    }
                );
            },
            () => {
                this.toastService.warning('Kon Onedrive niet configureren.');
            });
    }

    public SignOut(): void {
        this.userIdentity.use_one_drive = false;
        this.userIdentity.one_drive_root = '';
        this.userIdentity.one_drive_username = '';
        this.msAlLoginService.logout();
        // this.msAlLoginService.clearLocalStorage();
        this.save(true);
    }

    public save(hasClearance: boolean = false): void {
        if (!this.userIdentity.use_one_drive && !hasClearance) {
            return;
        }

        this.isLoading = true;

        this.userService.editProfile(this.userIdentity.toApiBody())
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((userData) => {
                if (hasClearance) {
                    this.retrieveUser(userData);
                    this.enabled = this.msAlLoginService.getLoggedInUser() === this.userIdentity.one_drive_username;
                    this.toastService.success(this.userIdentity.use_one_drive ? 'Succesvol aangemeld' : 'Succesvol afgemeld');

                    return;
                }

                this.toastService.success('Instellingen zijn bijgewerkt.');
            }, (error: HttpErrorResponse) => {
                this.toastService.error('Kon instellingen niet bijwerken.');
            });
    }

    public getLoggedInUser(): string {
        return this.msAlLoginService.getLoggedInUser();
    }

    public ngOnDestroy(): void {
        this.timeOuts.forEach(timer => clearTimeout(timer));
    }
}
