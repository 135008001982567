export enum GridTableClassEnum {

    BorderTopNone = 'grid-table--border-top-none',

    BorderTopOnly = 'grid-table--border-top-only',

    BorderRadius = 'grid-table--border-radius',

    MarginBottom = 'grid-table--margin-bottom',

    MarginTop = 'grid-table--margin-top',

    BackgroundGreyDark = 'grid-table--border-24boost-grey-dark',

    BackgroundWhite = 'grid-table--background-24boost-white',

    Feedback = 'grid-table--feedback',

}
