import {Component, Input} from '@angular/core';
import {StringService} from 'services/string/string.service';
import {TaskService} from 'services/task/task.service';
import {WorkTypeEnum} from 'shared/components/work-type-label/enums/work-type.enum';
import {WorkTypeClassesEnum} from 'shared/components/work-type-label/enums/work-type-classes.enum';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';
import {CollapsibleClassEnum} from 'shared/components/collapsible/enums/class.enum';
import {RoleEnum} from 'enums/role.enum';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';
import naturalCompare from 'string-natural-compare';

@Component({
    selector: 'app-tasks-table',
    templateUrl: 'tasks-table.component.html',
})
export class TasksTableComponent {

    public readonly workTypeEnum = WorkTypeEnum;

    public readonly workTypeClasses = WorkTypeClassesEnum;

    public readonly examinationNeededEnum = ExamineOptionsEnum;

    public readonly collapsibleClasses = CollapsibleClassEnum;

    public readonly roleEnum = RoleEnum;

    public readonly messages = {
        emptyMessage: 'Geen taken gevonden',
        totalMessage: 'totaal',
        selectedMessage: 'geselecteerd'
    };

    @Input()
    public tasks: ApiTasksInterface;

    @Input()
    public weekNumber: string;

    @Input()
    public studentSelected: ApiUserInterface;

    constructor(
        private stringService: StringService,
        private taskService: TaskService
    ) {
    }

    public onDataTableActivate(event: any): void {
        if (event.type !== 'click') {
            return;
        }

        if (event.cellElement && event.cellElement.classList && event.cellElement.classList.contains('datatable-body-cell--handed-in-count')) {
            return;
        }

        this.navigateToDetails(event.row.id);
    }

    public navigateToDetails(taskId: number): void {
        let studentId = null;

        if (this.studentSelected) {
            studentId = this.studentSelected.id;
        }

        this.taskService.setSelectedWeek(this.weekNumber);
        this.taskService.navigateToDetails(taskId, studentId);
    }

    public getResult(row: ApiTaskDetailsInterface): number | null {
        if (this.studentSelected && row.children && row.children.length > 0) {
            row = row.children[0];
        }

        if (!row.homework || !row.homework[0]) {
            return null;
        }

        return row.homework[0].examination_result;
    }

    public getRawScore(row: ApiTaskDetailsInterface): number | null {
        if (this.studentSelected && row.children && row.children.length > 0) {
            row = row.children[0];
        }

        if (!row.homework || !row.homework[0]) {
            return null;
        }

        return row.homework[0].raw_score;
    }

    public getModifiedByTeacher(row: ApiTaskDetailsInterface): number | null {
        if (this.studentSelected && row.children && row.children.length > 0) {
            row = row.children[0];
        }

        if (!row.homework || !row.homework[0]) {
            return null;
        }

        return row.homework[0].modifiedByTeacher;
    }

    public loadTaskForPopover(event: Event, row: ApiTaskDetailsInterface, popover: NgbPopover): void {
        this.taskService.retrieveTaskById(row.id).subscribe((task) => {
            row.children = task.children;
            row.children_count = task.children_count;

            popover.open();
        });
    }

    public naturalSort(titleA: string, titleB: string): number {
        return naturalCompare(titleA, titleB);
    }
}
