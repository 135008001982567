import {Injectable} from '@angular/core';
import {ProgressBookInterface} from 'pages/modules/progress/interfaces/book.interface';
import {ProgressBookModel} from 'pages/modules/progress/models/book.model';

@Injectable()
export class ProgressBookFactory {

    public fromApiBook(book: ApiBookInterface): ProgressBookInterface {
        return new ProgressBookModel(book.id, book.title);
    }

}
