import {Component, EventEmitter, HostBinding, Input, Output, QueryList, TemplateRef, ViewChild} from '@angular/core';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {ProgressGroupInterface} from 'pages/modules/progress/interfaces/group.interface';
import {ProgressStudentDetailsColHeaderInterface} from 'pages/modules/progress/components/student-details/interfaces/col-header.interface';
import {ProgressStudentDetailsColColumnInterface} from 'pages/modules/progress/components/student-details/interfaces/col-column.interface';
import {ProgressStudentDetailsColBodyInterface} from 'pages/modules/progress/components/student-details/interfaces/col-body.interface';
import {ProgressAssignmentStatusInterface} from 'pages/modules/progress/interfaces/assignment-status.interface';
import {ProgressStudentInterface} from 'pages/modules/progress/interfaces/student.interface';
import {ResultClassEnum} from 'shared/components/result-cell/enums/result-class.enum';
import {ProgressStatusFilterEnum} from 'shared/components/status-filter/enum/progress-status-filter.enum';
import {ProgressAverageInterface} from 'pages/modules/progress/interfaces/average.interface';
import {ProgressStudyMaterialInterface} from 'pages/modules/progress/interfaces/study-material.interface';
import {AbstractStudentGroupRowsComponent} from 'pages/modules/progress/components/student-details/grid-table/abstract-student-group-rows.component';
import {GridTableScrollColComponent} from 'shared/modules/grid-table/components/scroll-col/scroll-col.component';
import {GridTableScrollControlComponent} from 'shared/modules/grid-table/components/scroll-control/scroll-control.component';
import {ActiveTaskEventInterface, TaskStateService} from 'pages/modules/tasks/service/task-state.service';

export interface NavigateToTaskEvent {
    assignmentStatus: ProgressAssignmentStatusInterface;
    student: ProgressStudentInterface | null;
}

export interface StudentProgressInterface {
    bookId: number;
    average: ProgressAverageInterface;
    studyMaterial?: ProgressStudyMaterialInterface;
    filter?: ProgressStatusFilterEnum;
    group?: ProgressGroupInterface;
    isBpv?: boolean;
}

@Component({
    selector: 'app-progress-student-results',
    templateUrl: 'student-results.component.html',
})
export class StudentResultsComponent extends AbstractStudentGroupRowsComponent {
    @HostBinding('class')
    private classes: string[] = ['student-progress-results'];

    @Input()
    @HostBinding('style.minHeight.rem')
    private minHeight: number = 0;

    @ViewChild('headedBpvTemplate', {read: TemplateRef, static: true})
    private headedBpvTemplate: TemplateRef<any>;

    @ViewChild('headerAssignmentNumberTemplate', {read: TemplateRef, static: true})
    private headerAssignmentNumberTemplate: TemplateRef<any>;

    @ViewChild('contentResultTemplate', {read: TemplateRef, static: true})
    private contentResultTemplate: TemplateRef<any>;

    @ViewChild('contentDrillsterTemplate', {read: TemplateRef, static: true})
    private contentDrillsterTemplate: TemplateRef<any>;

    @ViewChild('contentAverageTemplate', {read: TemplateRef, static: true})
    private contentAverageTemplate: TemplateRef<any>;

    @ViewChild(GridTableScrollColComponent, {static: true})
    private gridScrollComponent: GridTableScrollColComponent;

    @Output()
    public readonly navigateToTaskEvent: EventEmitter<NavigateToTaskEvent> = new EventEmitter();

    public readonly columnClasses = GridTableColClassEnum;

    public readonly resultCellClasses = ResultClassEnum;

    public headers: Array<ProgressStudentDetailsColHeaderInterface> = [];

    public columns: Array<ProgressStudentDetailsColColumnInterface> = [];

    public rows: Array<Array<ProgressStudentDetailsColBodyInterface>> = [];

    public studentProgress?: StudentProgressInterface;

    @Input()
    public hidePopover: boolean = false;

    constructor(private taskStateService: TaskStateService) {
        super();
    }

    public addScrollControls(gridTableScrollControlComponents: QueryList<GridTableScrollControlComponent>): void {
        gridTableScrollControlComponents.forEach(scrollControl => this.gridScrollComponent.addScrollControl(scrollControl));
    }

    public filter(progressStatusFilter: ProgressStatusFilterEnum): void {
        if (undefined === this.studentProgress) {
            return;
        }

        const studentProgress = this.studentProgress;
        studentProgress.filter = progressStatusFilter;

        this.update(studentProgress);
    }

    public update(studentProgress: StudentProgressInterface, activeTask?: ActiveTaskEventInterface): void {
        this.studentProgress = studentProgress;
        this.setupColumns();
        this.setupRows();
        setTimeout(() => {
            this.taskStateService.setTasks(studentProgress);
            this.gridScrollComponent.updateControls();
            this.gridScrollComponent.scrollToActiveColumn(activeTask, this.taskStateService.getProgressStatusFilter());
        });

    }

    public navigateToTask(assignmentStatus: ProgressAssignmentStatusInterface, student: ProgressStudentInterface = null): void {
        this.navigateToTaskEvent.emit({assignmentStatus, student});
    }

    protected getStudentGroup(): ProgressGroupInterface | undefined {
        return this.studentProgress.group;
    }

    protected getStudyMaterial(): ProgressStudyMaterialInterface | undefined {
        return this.studentProgress.studyMaterial;
    }

    protected setRows(rows: ProgressStudentDetailsColBodyInterface[][]): void {
        this.rows = rows;
    }

    protected buildStudentRow(student: ProgressStudyMaterialInterface | ProgressStudentInterface): ProgressStudentDetailsColBodyInterface[] {
        const studentColumns: Array<ProgressStudentDetailsColBodyInterface> = [];

        if (this.studentProgress.isBpv) {
            const filesCount = student['moduleStatus'] && student['moduleStatus'].files ? student['moduleStatus'].files.length : 0;

            studentColumns.push({
                result: filesCount,
                template: this.contentResultTemplate,
            });
        }

        for (const drill of student.drillsterStatusses) {
            studentColumns.push({
                drillster: drill,
                template: this.contentDrillsterTemplate,
            });
        }

        for (let i = 0; i < student.assignmentStatuses.length; i++) {
            const average = student.assignmentStatuses[i];
            const column = this.studentProgress.average.statuses[i];

            if (this.studentProgress.filter !== ProgressStatusFilterEnum.All && !column.taskAssignedCount && !column.progressStatus) {
                continue;
            }

            studentColumns.push({
                average: average,
                student: student,
                template: this.contentAverageTemplate,
            });
        }

        return studentColumns;
    }

    private setupColumns(): void {
        const group: ProgressGroupInterface | undefined = this.studentProgress.group;
        const headers: Array<ProgressStudentDetailsColHeaderInterface> = [];
        const columns: Array<ProgressStudentDetailsColColumnInterface> = [];

        if (this.studentProgress.isBpv) {
            const val = {
                isBpv: true,
                width: 72,
                template: this.headedBpvTemplate,
            };

            headers.push(val);
            columns.push(val);
        }

        for (const drill of (group && group.students.length > 0 ? group.students[0] : this.studentProgress.studyMaterial).drillsterStatusses) {
            headers.push({
                isDrillster: true,
                drillster: drill,
                width: 72,
                template: this.headerAssignmentNumberTemplate,
            });

            columns.push({
                isDrillster: true,
                width: 72,
            });
        }

        for (const status of this.studentProgress.average.statuses) {
            if (this.studentProgress.filter !== ProgressStatusFilterEnum.All && !status.taskAssignedCount && !status.progressStatus) {
                continue;
            }

            headers.push({
                isAverage: true,
                average: status,
                width: 72,
                template: this.headerAssignmentNumberTemplate,
            });

            columns.push({
                isAverage: true,
                width: 72,
            });
        }

        this.headers = headers;
        this.columns = columns;
    }
}
