import {Directive, HostBinding} from '@angular/core';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';

@Directive({
    selector: '.document-vraag',
})
export class DocumentQuestionDirective {

    @HostBinding('class')
    public elementClass: string = DocumentHostClassesEnum.Question;

}
