import {Component, OnDestroy, OnInit} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {TasksEditTaskModel} from 'pages/modules/tasks/models/edit-task.model';
import {TasksGoToAssignmentsModel} from 'pages/modules/tasks/models/go-to-assignments.model';
import {TasksRemoveTaskModel} from 'pages/modules/tasks/models/remove-task.model';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {ToastrService} from 'ngx-toastr';
import {TaskService} from 'services/task/task.service';
import {ClassHelper} from 'helpers/dom/class.helper';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {ModalService} from 'core/services/modal/modal.service';
import {TasksGridTableClassesEnum} from 'pages/modules/tasks/enums/tasks-grid-table-classes.enum';
import {DocumentService} from 'services/document/document.service';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {RoleEnum} from 'enums/role.enum';
import {Subscription} from 'rxjs';
import {HomeworkService} from 'services/homework/homework.service';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {RouteService} from 'routing/services/route/route.service';
import {AnalyticsService} from 'services/analytics/analytics.service';
import {AnalyticsDimensionEnum} from 'services/analytics/enums/dimension.enum';
import {AnalyticsEventTaskViewModel} from 'services/analytics/models/task-view.model';
import {AlertMessageInterface} from 'shared/components/alert-message/interfaces/alertMessageInterface';
import {TaskDeletedAlertMessage} from 'shared/components/alert-message/models/task-deleted.model';
import {AutoCheckService} from 'document/services/autocheck/auto-check.service';
import {ProgressService} from 'services/progress/progress.service';
import {BookService} from 'services/book/book.service';
import {ActiveTaskEventInterface, TaskStateService} from 'pages/modules/tasks/service/task-state.service';
import * as moment from 'moment';
import {TaskStatusFilterEnum} from 'shared/components/status-filter/enum/task-status-filter.enum';
import {ProgressStatusFilterEnum} from 'shared/components/status-filter/enum/progress-status-filter.enum';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-tasks-detail',
    templateUrl: 'tasks-detail.component.html',
})
export class TasksDetailComponent implements OnInit, OnDestroy {

    public static BUTTON_REMOVE_TASK: string = 'button-remove-task';
    public static BUTTON_EDIT_TASK: string = 'button-edit-task';

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly iconEnum = IconEnum;

    public readonly taskStatusFilterEnum = TaskStatusFilterEnum;

    public readonly statusFilterOptions = new Map<string, string>([
        [TaskStatusFilterEnum.All, 'Alle'],
        [TaskStatusFilterEnum.Specified, 'Opgegeven'],
    ]);

    public columnModifiers: string = 'grid-table-col';

    public taskRoute: string;

    public isLoading: boolean = false;

    public actionGoToAssignment: ActionButtonInterface = new TasksGoToAssignmentsModel();

    public actionButtons: Array<ActionButtonInterface> = [];

    public alertMessage: AlertMessageInterface = new TaskDeletedAlertMessage();

    public taskDeleted: boolean = false;

    public masterTask: ApiTaskDetailsInterface;

    public activeTask: ApiTaskDetailsInterface;

    public childTasks: Array<ApiTaskDetailsInterface>;

    public taskHomework: ApiTaskDetailsHomeworkInterface;

    public resource: ApiAssignmentResourceInterface;

    public demo: boolean = false;

    public isAlgebrakit: boolean = false;

    public document: ApiDocumentDataInterface;

    public classHelper: ClassHelper = new ClassHelper();

    public homeworkCurrent: ApiHomeworkCurrentInterface;

    private subscriptions: Subscription[] = [];

    public bookId: number;

    public licenseRequested: boolean = false;

    public isGFAnswerModel: boolean;

    public isAutoCheck: boolean;

    public progress: ApiProgressInterface[] = [];

    public bookCover: string;

    public bookTitle: string;

    public chapterTitle: string;

    public isTaskGroupMasterTask: boolean = false;

    public activeTaskScoreChange?: ActiveTaskEventInterface;

    public archiveModeActive = environment.archiveModeActive;

    public constructor(
        private taskStateService: TaskStateService,
        private authorizationService: AuthorizationService,
        private taskService: TaskService,
        private toastService: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private documentService: DocumentService,
        private modalService: ModalService,
        private routeService: RouteService,
        private answerStateService: DocumentAnswersStateService,
        private autoCheckService: AutoCheckService,
        private homeworkService: HomeworkService,
        private analyticsService: AnalyticsService,
        private progressService: ProgressService,
        private bookService: BookService
    ) {
        this.taskRoute = this.routeService.getRouteWithPublisher(RoutesEnum.Tasks, null);
        this.subscriptions.push(this.taskStateService.subscribeToActiveTask(task => this.onActiveTaskEvent(task)));
    }

    public ngOnInit(): void {
        this.subscriptions.push(this.route.params.subscribe(() => this.loadData()));
        this.subscriptions.push(this.route.queryParams.subscribe(() => this.loadData()));
        this.subscriptions.push(this.taskStateService.subscribeToActiveTaskScoreChange(scoreChanged => this.onActiveTaskScoreChangeEvent(scoreChanged)));
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.taskStateService.unsetActiveTask();
    }

    public onStatusFilterChange(filterChange: string): void {
        this.taskStateService.setProgressStatusFilter(filterChange === TaskStatusFilterEnum.All ? ProgressStatusFilterEnum.All : ProgressStatusFilterEnum.HandedIn);
    }

    public handleActionButton(id: string): void {
        switch (id) {
            case TasksDetailComponent.BUTTON_REMOVE_TASK:
                this.handleRemoveTaskAction();
                break;

            case TasksDetailComponent.BUTTON_EDIT_TASK:
                this.handleEditTaskAction();
                break;
        }
    }

    public loadData(taskId?: number): void {
        if (!this.route.snapshot.params.taskId || this.isLoading === true) {
            return;
        }

        this.isLoading = true;

        if (!taskId) {
            taskId = this.route.snapshot.params.taskId;
        }

        this.taskService.retrieveTaskById(taskId, this.getStudentId()).toPromise()
            .then((taskDetails: ApiTaskDetailsInterface) => {
                this.loadTaskDetails(taskDetails);

                this.demo = taskDetails.book_demo;
                this.isAlgebrakit = taskDetails.assignment ? taskDetails.assignment.is_algebrakit : false;
                this.bookId = taskDetails.book;
                this.licenseRequested = taskDetails.book_licenseRequested;
                this.taskDeleted = false;
                this.isGFAnswerModel = taskDetails.scoremodel === 'GF';
                this.isAutoCheck = taskDetails.auto_check && taskDetails.assignment.auto_check;
                this.chapterTitle = taskDetails.chapter_title;

                this.retrieveDocumentBook();

                if (undefined === taskDetails.assignment || undefined === taskDetails.assignment.dpsid) {
                    return Promise.resolve(null);
                }

                return this.documentService.retrieveDocumentVersions(taskDetails.assignment.dpsid, taskDetails.book.toString(), this.activeTask.id.toString()).toPromise();
            })
            .then((documents: Array<ApiDocumentDataInterface>) => {
                if (null !== documents) {
                    this.loadDocument(documents);
                }

                if (this.taskHomework) {
                    return this.homeworkService.retrieveHomework(this.taskHomework.id).toPromise();
                }

                return Promise.resolve(null);
            })
            .then((currentHomework?: ApiHomeworkCurrentInterface) => {
                if (currentHomework) {
                    this.loadHomework(currentHomework);
                }

                return Promise.resolve(null);
            })
            .catch((error: any) => {
                console.error(error);

                if (error.error.msg === 'TASK_DELETED') {
                    const name = error.error.data.deleted_by.name;
                    this.alertMessage.title = `Deze taak is niet langer beschikbaar. ${name} heeft deze taak verwijdert. Eventueel ingeleverd en beoordeeld werk is niet meer beschikbaar.`;
                    this.taskDeleted = true;
                }

            })
            .finally(() => {
                this.actionGoToAssignment.disabled = false;
                this.isLoading = false;
            });
    }

    private onActiveTaskEvent(activeTaskEvent: ActiveTaskEventInterface): void {
        if (undefined === activeTaskEvent) {
            return;
        }

        this.isLoading = true;
        this.activeTask = activeTaskEvent.task; // Track locally to keep everything intact

        if (this.activeTask.is_taskgrouptask) {
            this.isTaskGroupMasterTask = this.taskService.getIsOriginalTask() && this.authorizationService.isGranted(RoleEnum.RoleTeacher);
        }

        this.loadProgress(activeTaskEvent.task).then(progress => {
            this.progress = progress;

            const taskId = activeTaskEvent.loadMasterTask ? this.masterTask.id : activeTaskEvent.task.id;
            this.updateClassModifiers();

            if (false === activeTaskEvent.navigateToTask) {
                return;
            }

            // Some weird master task switch?
            this.navigateToTask(taskId, activeTaskEvent.loadMasterTask);
        }).finally(() => this.isLoading = false);
    }

    private onActiveTaskScoreChangeEvent(scoreChange: boolean): void {
        if (undefined === scoreChange) {
            return;
        }

        if (scoreChange) {
            this.subscriptions.push(this.taskStateService.subscribeToActiveTask(task => this.activeTaskScoreChange = task));

            this.loadProgress(this.activeTaskScoreChange.task).then(progress => {
                this.progress = progress;

                this.updateClassModifiers();


                if (false === this.activeTaskScoreChange.navigateToTask) {
                    return;
                }

            }).finally(() => {this.isLoading = false; this.taskStateService.setActiveTaskScoreChange(false); });
        }
    }

    private handleEditTaskAction(): void {
        const modalInstance = this.modalService.taskEdit(this.masterTask);

        modalInstance.taskUpdateEvent.subscribe(() => {
            this.loadData(this.masterTask.id);
        });
    }

    private getStudentId(): number | null {
        if (this.activeTask) {
            return null; // let backend decide
        }

        const student = this.route.snapshot.queryParams.student;

        return student ? Number(student) : null;
    }

    private retrieveDocumentBook(): void {
        if (!this.bookId) {
            return;
        }

        this.bookService.getBook(String(this.bookId)).subscribe((book: ApiBookInterface) => {
            this.bookCover = book.cover;
            this.bookTitle = book.title;
        }, (error: any) => {
            console.error(error);
        });
    }

    private loadTaskDetails(taskDetails: ApiTaskDetailsInterface): void {
        this.masterTask = taskDetails;

        // this a hack to check if we initially asked for this task, or that we switched to this task
        // when we move between taskgroups, we set a flag
        this.taskService.setIsOriginalTask(
            String(taskDetails.id) === this.route.snapshot.params.taskId ||
            this.route.snapshot.queryParams.taskgroup === '1'
        );
        this.childTasks = this.authorizationService.isGranted(RoleEnum.RoleTeacher) && taskDetails.children ? taskDetails.children : [];

        const activeTask = this.getChildTask(); // Get active task based on role
        this.taskStateService.setActiveTask(activeTask, false, false); // Pass task to service, but prevent acting on it

        this.actionGoToAssignment.routerLink = activeTask.assignment
            ? this.routeService.getRouteWithPublisher(RoutesEnum.Document, new Map([
                ['bookId', String(activeTask.book)],
                ['documentDpsid', activeTask.assignment.dpsid],
            ]))
            : null;

        this.resource = activeTask.assignment ? activeTask.assignment.resource : null;
        this.setHomeWork(activeTask.homework[0]);

        this.analyticsService.dimension(AnalyticsDimensionEnum.AssignmentResourceTitle, this.resource ? this.resource.title : null);
        this.analyticsService.dimension(AnalyticsDimensionEnum.AssignmentTitle, activeTask.assignment ? activeTask.assignment.title : null);
        this.analyticsService.event(new AnalyticsEventTaskViewModel());
    }

    private getChildTask(): ApiTaskDetailsInterface {
        if (!this.authorizationService.isGranted(RoleEnum.RoleTeacher)) {
            return this.masterTask;
        }

        this.actionButtons = [new TasksRemoveTaskModel(), new TasksEditTaskModel()];

        const studentId = this.getStudentId() || this.masterTask.student;

        if (studentId !== null) {
            const child = this.childTasks.find(childTask => childTask.assigned_to_id === studentId);
            if (child !== undefined && child !== null) {
                return child;
            }
        }

        if (this.childTasks.length > 0) {
            return this.childTasks[0];
        }

        return this.masterTask;
    }

    private loadDocument(documents: Array<ApiDocumentDataInterface>): void {
        this.document = documents.pop();
        this.answerStateService.disableAnswers();

        if (this.authorizationService.isGranted(RoleEnum.RoleTeacher)) {
            this.answerStateService.showAnswers();
        }
    }

    private loadProgress(activeTask: ApiTaskDetailsInterface): Promise<ApiProgressInterface[]> {
        if (undefined === activeTask.assignment) {
            return Promise.resolve([]);
        }

        const bookId: number = activeTask.book;
        const groupId: number = null !== activeTask.group ? activeTask.group : activeTask.group_id;
        const chapterId: number = activeTask.assignment.resource.id;
        const includeTasks: number = 1;

        return (this.authorizationService.isGranted(RoleEnum.RoleTeacher)
                ? this.progressService.retrieveForTeacher(bookId, chapterId, groupId, activeTask.assigned_to_id, undefined, includeTasks)
                : this.progressService.retrieveForStudent(bookId, groupId, chapterId, undefined, includeTasks)
        ).toPromise();
    }

    private loadHomework(homework: ApiHomeworkCurrentInterface): void {
        this.homeworkService.emit(false);
        this.homeworkCurrent = homework;

        // Reset all assignment answers in the document
        if (this.document) {
            for (const assignment of this.document.assignments) {
                for (const field of assignment.fields) {
                    field.answer = null;
                }
            }
        }

        // Set the current answers of the student
        for (const answer of homework.current.answers) {
            for (const assignment of this.document.assignments) {
                if (assignment.assignment_id !== answer.assignment_id) {
                    continue;
                }

                for (const field of assignment.fields) {
                    if (field.field_id === Number(answer.field_id)) {
                        field.answer = answer.value;
                    }
                }
            }
        }

        // Set the initial answers of the student
        if (this.activeTask && this.activeTask.selfstudy_answers && this.activeTask.selfstudy_answers.length > 0) {
            for (const answer of this.activeTask.selfstudy_answers) {
                for (const assignment of this.document.assignments) {
                    if (assignment.assignment_id !== answer.assignment_id) {
                        continue;
                    }

                    for (const field of assignment.fields) {
                        if (field.field_id === Number(answer.field_id)) {
                            field.initial_answer = answer.value;
                        }
                    }
                }
            }
        }

        if (this.document && this.document.assignments.length > 0 && this.document.assignments[0].tasks !== undefined) {
            this.document.assignments[0].tasks = [this.activeTask];
        }

        // Emit the change for auto_check
        if (homework && homework.current && homework.current.assignment && homework.current.assignment.auto_check) {
            // Setting (possible) correct answers from autoCheck handedIn response
            this.autoCheckService.setAutoCheckAnswers(homework.current.assignment as any as ApiDocumentAssignmentInterface);
        }

        this.answerStateService.emit();
        this.homeworkService.emit(true);
    }

    private setHomeWork(homeWork?: ApiTaskDetailsHomeworkInterface): void {
        if (homeWork !== undefined && homeWork !== null) {
            this.taskHomework = homeWork;
        }
    }

    private updateClassModifiers(): void {
        if (this.isPastDueDate()) {
            this.classHelper.addClass(TasksGridTableClassesEnum.ColBorderRed, this.columnModifiers);
        }
    }

    private isPastDueDate(): boolean {
        const dueTime: number = this.activeTask.duedate * 1000;
        const handedInTime: number = this.activeTask.handedin * 1000;
        const isDue: boolean = !this.activeTask.handedin && moment(dueTime).isBefore(moment().toDate().getTime());
        const handedInDue: boolean = this.activeTask.handedin && moment(dueTime).isBefore(handedInTime);

        return isDue || handedInDue;
    }

    private handleRemoveTaskAction(): void {
        const modalInstance = this.modalService.taskRemove(this.masterTask);

        modalInstance.taskDeletedEvent.subscribe(() => {
            this.router.navigate([this.taskRoute]);
        });
    }

    private navigateToTask(taskId: number, includeExtras: boolean): void {
        if (taskId === this.activeTask.id || taskId === this.masterTask.id) {
            this.isLoading = false;
            this.loadData(this.activeTask.id);

            return;
        }

        const extras: NavigationExtras = {};

        if (includeExtras) {
            this.addQueryParamsForTask(extras);
        }

        this.router.navigate(
            [
                this.routeService.getRouteWithPublisher(RoutesEnum.TaskDetails, new Map<string, string>([
                    ['taskId', String(taskId)],
                ])),
            ],
            extras,
        );
    }

    private addQueryParamsForTask(extras: NavigationExtras): void {
        if (!this.authorizationService.isGranted(RoleEnum.RoleTeacher)) {
            return;
        }

        extras.queryParams = {
            student: this.activeTask.assigned_to_id,
        };
    }
}
