import {EventEmitter, Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {Observable, Observer} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {StringService} from 'services/string/string.service';
import {AnalyticsService} from 'services/analytics/analytics.service';
import {AnalyticsEventNoteNewModel} from 'services/analytics/models/note-new.model';

@Injectable({
    providedIn: 'root'
})
export class NoteService {

    public notesChange: EventEmitter<Array<ApiNoteInterface>> = new EventEmitter();

    constructor(
        private apiRequestService: ApiService,
        private stringService: StringService,
        private analyticsService: AnalyticsService,
    ) {
    }

    public retrieveAll(documentDpsid: string): Observable<Array<ApiNoteInterface>> {
        const params = new URLSearchParams();
        params.set('dpsid', documentDpsid);

        return this.apiRequestService.get<Array<ApiNoteInterface>>(ApiEndpointEnum.NoteAll, params);
    }

    public saveNew(documentDpsid: string, htmlId: string, message: string, users: Array<number> = null): Observable<ApiNoteInterface> {
        const params: NoteFormInterface = {
            message: message,
            dpsid: documentDpsid,
            html_id: htmlId,
        };

        if (users !== null) {
            params.users = users;
        }

        return new Observable((observer: Observer<ApiNoteInterface>) => {
            this.apiRequestService.post<Array<ApiNoteInterface>>(ApiEndpointEnum.NoteAdd, params)
                .subscribe((createdNotes: Array<ApiNoteInterface>) => {
                    this.analyticsService.event(new AnalyticsEventNoteNewModel());

                    this.retrieveAll(documentDpsid)
                        .subscribe((notes: Array<ApiNoteInterface>) => {
                            this.notesChange.emit(notes);

                            observer.next(createdNotes[0]);
                            observer.complete();
                        }, (err) => {
                            observer.error(err);
                            observer.complete();
                        });
                }, (err) => {
                    observer.error(err);
                    observer.complete();
                });
        });
    }

    public edit(noteId: number, documentDpsid: string, htmlId: string, message: string, users: Array<number> = null): Observable<Array<ApiNoteInterface>> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.NoteEdit, new Map([
            ['noteId', noteId.toString()],
        ]));

        const params: NoteFormInterface = {
            message: message,
            dpsid: documentDpsid,
            html_id: htmlId,
        };

        if (users !== null) {
            params.users = users;
        }

        return this.apiRequestService.post<Array<ApiNoteInterface>>(apiRoute, params);
    }

    public remove(noteId: number): Observable<null> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.NoteRemove, new Map([
            ['noteId', noteId.toString()],
        ]));

        return this.apiRequestService.post<null>(apiRoute);
    }
}
