import {HostBinding, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';

export abstract class GridColumnClass implements ClassModifierInterface, OnChanges, OnInit {

    public readonly modifiersCollection = 'modifiers';

    private classHelperInstance: ClassHelper;

    @HostBinding('class')
    protected hostClasses: string;

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public set size(value: number | string) {
        this.addColumnSizeClass('size', value);
    }

    @Input()
    public set sm(value: number | string) {
        this.addColumnSizeClass('sm', value);
    }

    @Input()
    public set smOrder(value: number | string) {
        this.addColumnOrderClass('sm', value);
    }

    @Input()
    public set md(value: number | string) {
        this.addColumnSizeClass('md', value);
    }

    @Input()
    public set mdOrder(value: number | string) {
        this.addColumnOrderClass('md', value);
    }

    @Input()
    public set lg(value: number | string) {
        this.addColumnSizeClass('lg', value);
    }

    @Input()
    public set lgOrder(value: number | string) {
        this.addColumnOrderClass('lg', value);
    }

    public get classHelper(): ClassHelper {
        if (!(this.classHelperInstance instanceof ClassHelper)) {
            this.classHelperInstance = new ClassHelper(this.getBaseClass());
        }

        return this.classHelperInstance;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.classModifiers) {
            this.classHelper.clear(this.modifiersCollection);
            this.classHelper.addClasses(this.classModifiers, this.modifiersCollection);
        }

        this.setHostClasses();
    }

    public ngOnInit(): void {
        this.setHostClasses();
    }

    protected setHostClasses(): void {
        this.hostClasses = this.classHelper.getAllClasses().join(' ');
    }

    protected abstract getBaseClass(): string;

    /**
     * Bootstrap grid columns require no base class when column size is specified
     */
    protected isBootstrapMode(): boolean {
        return true;
    }

    protected getBreakpointValues(): Array<number> {
        return Array.from(new Array(12), (val, index) => index + 1);
    }

    protected addColumnSizeClass(breakpoint: string, value: number | string, infix: string = '--'): void {
        if (!this.getBreakpointValues().includes(Number(value))) {
            throw new Error(`Breakpoint "${breakpoint}" has no column support for value "${value}"`);
        }

        if (this.isBootstrapMode()) {
            this.classHelper.removeClass(this.getBaseClass());
        }

        this.classHelper.addClass(this.getColumnClass(breakpoint, value, infix));
        this.setHostClasses();
    }

    protected addColumnOrderClass(breakpoint: string, value: number | string, infix: string = '--order-'): void {
        this.classHelper.addClass(this.getColumnClass(breakpoint, value, infix));
        this.setHostClasses();
    }

    protected getColumnClass(breakpoint: string, value: number | string, infix: string): string {
        return `${this.getBaseClass()}${infix}${breakpoint}-${value}`;
    }
}
