import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input, NgZone,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {RegexValidationEnum} from 'enums/regex-validation.enum';
import {RoleEnum} from 'enums/role.enum';
import {RedactorService} from 'services/redactor/redactor.service';
import {UploadService} from 'core/services/upload/upload.service';
import {NgForm} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {forkJoin} from 'rxjs';
import {ModalService} from 'core/services/modal/modal.service';
import {AddAttachmentPreferencesEnum} from 'shared/modals/add-attachments/add-attachment-preferences/enum/add-attachment-preferences.enum';
import {OneDriveService} from 'onedrive/services/onedrive.service';
import {AddUrlPreferencesEnum} from 'shared/modals/add-url/enum/add-url-preferences.enum';
import {UserService} from 'security/services/user/user.service';
import {ApiService} from 'core/services/api/api.service';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {environment} from 'environments/environment';
import {FileService} from 'services/file/file.service';

@Component({
    selector: 'app-attachments',
    templateUrl: 'attachments.component.html',
})
export class AttachmentsComponent implements AfterViewInit, OnChanges {

    public readonly regexValidation = RegexValidationEnum;

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public model: AttachmentDataInterface;

    @Input()
    public max: number;

    @Output()
    public modelChange: EventEmitter<AttachmentDataInterface> = new EventEmitter();

    @ViewChild('file', {static: false})
    protected file: ElementRef;

    @ViewChild('attachmentDescriptionTextArea', {static: true})
    protected attachmentDescriptionTextArea: ElementRef;

    public showDescription: boolean = false;

    public showAddUrl: boolean = false;

    public disabled: boolean = false;

    public isLoading: boolean = false;

    public archiveModeActive = environment.archiveModeActive;

    private redactor: RedactorAppInstance;

    private mode: string = 'files';

    public readonly downloadFileUrl = environment.fileDownloadUrl;

    constructor(
        private redactorService: RedactorService,
        private uploadService: UploadService,
        private apiService: ApiService,
        private toastService: ToastrService,
        private modalService: ModalService,
        private oneDriveService: OneDriveService,
        private userService: UserService,
        private fileService: FileService
    ) {

    }

    public ngAfterViewInit(): void {
        const options = {
            callbacks: {
                blur: this.onRedactorChange.bind(this),
            },
        };

        this.redactorService.create(this.attachmentDescriptionTextArea.nativeElement, options)
            .subscribe((redactor: RedactorAppInstance) => {
                this.redactor = redactor;

                if (this.model && this.model.description) {
                    this.redactor.source.setCode(this.model.description);
                }
            });

        this.oneDriveService.filesSelected.subscribe(
            (files: any) => {
                if (this.mode === 'files') {
                    this.filesSelected(files);
                } else {
                    this.urlsSelected(files);
                }
            }
        );

        this.updateDisabled();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.updateDisabled();
    }

    public toggleDescription(): void {
        this.onRedactorChange();
        this.showDescription = !this.showDescription;
    }

    public saveUrl(form: NgForm): void {
        if (form.invalid) {
            this.toastService.warning('Opgegeven URL is ongeldig.');

            return;
        }

        this.uploadUrls([form.value.urlField]);

        form.reset();
    }

    private uploadUrls(urls: Array<string>): void {
        this.isLoading = true;

        const uploadActions = [];

        for (const url of urls) {
            const obs = this.apiService.post<ApiUrlInterface>(ApiEndpointEnum.UrlAdd, { 'url': url } );
            uploadActions.push(obs);

            obs.subscribe((apiUrl: ApiUrlInterface) => {
                this.model.urls.push(apiUrl);
                this.modelChange.emit(this.model);
            }, () => {
                // Catch
            });
        }

        forkJoin(uploadActions).subscribe({
            error: () => {
                this.updateDisabled();
                this.isLoading = false;
            },
            next: () => {
                this.updateDisabled();
                this.isLoading = false;
            },
            complete: () => {
                this.updateDisabled();
                this.isLoading = false;
            }
        });

        this.showAddUrl = false;
    }

    public deleteUrl(url: AttachmentUrlInterface): void {
        this.model.urls.splice(this.model.urls.indexOf(url), 1);
        this.modelChange.emit(this.model);

        this.updateDisabled();
    }

    public onFilesUploadAdded(): void {
        const files: FileList = this.file.nativeElement.files;

        const fileObjects: Array<File> = [];

        let maxFiles = this.getMaxAttachments();

        if (maxFiles === null || maxFiles > files.length) {
            maxFiles = files.length;
        }

        for (let i = 0; i < maxFiles; i++) {
            const file = files.item(i);
            fileObjects.push(file);
        }

        this.uploadFiles(fileObjects);
    }

    private uploadFiles(files: Array<File>): void {
        this.isLoading = true;

        const uploadActions = [];

        for (const file of files) {
            const obs = this.uploadService.upload(file);
            uploadActions.push(obs);

            obs.subscribe((apiFile: ApiFileInterface) => {
                this.model.files.push(apiFile);
                this.modelChange.emit(this.model);
            }, () => {
                // Catch
            });
        }

        forkJoin(uploadActions).subscribe({
            error: () => {
                this.updateDisabled();
                this.isLoading = false;
            },
            next: () => {
                this.updateDisabled();
                this.isLoading = false;
            },
            complete: () => {
                this.updateDisabled();
                this.isLoading = false;
            }
        });
    }

    public deleteFile(file: AttachmentFileInterface): void {
        this.model.files.splice(this.model.files.indexOf(file), 1);
        this.modelChange.emit(this.model);
        this.updateDisabled();
    }

    public getMaxAttachments(): number {
        if (!this.max) {
            return null;
        }

        return this.max - (this.model.urls.length || 0);
    }

    private updateDisabled(): void {
        const urls: number = this.model.urls !== undefined ? this.model.urls.length : 0;
        const files: number = this.model.files !== undefined ? this.model.files.length : 0;

        this.disabled = this.max && this.max > 0 && urls + files >= this.max;
    }

    private onRedactorChange(): void {
        this.model.description = this.redactor.source.getCode();
        this.modelChange.emit(this.model);

        this.updateDisabled();
    }

    public addAttachment(): void {
        if (this.userService.getUserData().use_one_drive) {

            this.modalService.addAttachment()
                .subscribe((preferences: string) => {
                    if (preferences === AddAttachmentPreferencesEnum.Local) {
                        document.getElementById('attachment-file').click();
                    }

                    if (preferences === AddAttachmentPreferencesEnum.OneDrive) {
                        this.oneDriveService.launchOneDrivePicker(this.mode);
                    }
                });
        } else {
            document.getElementById('attachment-file').click();
        }
    }

    public addUrls(): void {
        if (this.userService.getUserData().use_one_drive) {

            this.modalService.addUrl().subscribe((preferences: string) => {
                if (preferences === AddUrlPreferencesEnum.OwnInput) {
                    this.showAddUrl = !this.showAddUrl;
                    this.updateDisabled();
                }

                if (preferences === AddUrlPreferencesEnum.OneDrive) {
                    this.mode = 'urls';
                    this.oneDriveService.launchOneDrivePicker(this.mode);
                }
            });
        } else {
            this.showAddUrl = !this.showAddUrl;
            this.updateDisabled();
        }
    }

    public async filesSelected(files: any): Promise<void> {
        this.isLoading = true;

        const fileObjects: Array<File> = [];

        for (const file of files.value) {
            // get the url from the OneDrive object @microsoft.graph.downloadUrl field
            const fileUrl = file['@microsoft.graph.downloadUrl'];
            // get the filename from the OneDrive object name field
            const fileName = file.name;
            // create a blob by the given file URL
            const blob = await fetch(fileUrl).then(r => r.blob());
            // create a new file Object
            const oneDriveFile = new File([blob], fileName);

            fileObjects.push(oneDriveFile);
        }

        this.uploadFiles(fileObjects);

    }

    public urlsSelected(files: any): void {

        const share = this.userService.getUserData().role === RoleEnum.RoleStudent  ? 'edit' : 'view';

        const sharePromises: Array<Promise<string>> = [];

        for (const file of files.value) {
            //  create share link
            sharePromises.push(this.oneDriveService.shareFile(file.id, share));

            Promise.all(sharePromises).then(
                (values) => {
                    this.uploadUrls(values);
                },
                (error) => {
                    this.toastService.warning('U heeft geen rechten om bestanden te delen buiten uw organisatie. Neem contact op met uw Office 365 beheerder.');
                }
            );
        }
    }

    public openFile(url: string, file: ApiFileInterface, target: string): void {
        this.fileService.openFile(url, file, target);
    }
}
