import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'momentTimeAgo'
})
export class MomentTimeAgoPipe implements PipeTransform {

    protected relativeTimeSpec: moment.RelativeTimeSpec = {
        s: '%d s',
        ss: '%d s',
        m: '%d min',
        mm: '%d min',
        M: '%d mnd',
        MM: '%d mnd',
        h: '%d u',
        hh: '%d u',
        d: '%d d',
        dd: '%d d',
        y: '%d jr',
        yy: '%d jr',
    };

    protected updateLocale(relativeTimeSpec: moment.RelativeTimeSpec = null): void {
        moment.updateLocale('nl', {
            relativeTime: relativeTimeSpec ? relativeTimeSpec : this.relativeTimeSpec
        });
    }

    public transform(
        value: string | number | moment.Moment,
        relativeTimeSpec: moment.RelativeTimeSpec = null,
    ): any {
        this.updateLocale(relativeTimeSpec);
        const momentDate: moment.Moment = (typeof value === 'number') ? moment.unix(value) : moment(value);

        if (!value || !momentDate.isValid()) {
            return value;
        }

        return momentDate.fromNow(true);
    }
}
