import {TaskAttachmentInterface} from 'pages/modules/tasks/interfaces/attachment.interface';
import {TasksAttachmentTypesEnum} from 'pages/modules/tasks/enums/attachment-types.enum';
import {IconEnum} from 'enums/icon.enum';

export class TasksAttachmentUrlModel implements TaskAttachmentInterface {

    public type: TasksAttachmentTypesEnum.Url;

    public icon: IconEnum = IconEnum.Global;

    constructor(
        public value: string,
        public target?: string,
    ) {
    }

    public get url(): string {
        return this.value;
    }

}
