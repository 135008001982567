import {SearchInterface} from 'shared/modules/search/interfaces/search.interface';
import {IconEnum} from 'enums/icon.enum';

export class TeacherMaterialSearchModel implements SearchInterface {

    public affixIcon: IconEnum = IconEnum.Search;

    constructor(public placeHolder: string) {
    }
}
