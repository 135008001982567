export enum WindowBreakPointsEnum {

    ExtraSmall = 320,

    Small = 568,

    Medium = 667,

    Large = 768

}
