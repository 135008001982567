import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {InputElementComponent} from 'shared/modules/form-elements/components/input/input.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
    ],
    declarations: [
        InputElementComponent,
    ],
    exports: [
        InputElementComponent,
    ],
})
export class FormElementsModule {
}
