import {ResultStateEnum} from 'shared/components/result-cell/enums/result-state.enum';
import {ResultCodeEnum} from 'shared/components/result-cell/enums/result-code.enum';
import {ProgressResultStateInterface} from 'pages/modules/progress/interfaces/result-state.interface';

export class ProgressResultIsNumericStateMapping extends Array<ProgressResultStateInterface> {

    constructor() {
        super(
            {
                state: ResultStateEnum.IsNumeric,
                text: 'Gemiddelde score',
            },
            {
                state: ResultStateEnum.Graded,
                result: ResultCodeEnum.ResultRetry,
            },
            {
                state: ResultStateEnum.HandedIn,
                text: 'Ingeleverd',
            },
            {
                state: ResultStateEnum.TooLate,
                text: 'Te laat',
            },
        );
    }
}
