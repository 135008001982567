import {Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import {HorizontalBarIconClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-icon-classes.enum';

@Component({
    selector: 'app-horizontal-bar-icon-component',
    templateUrl: 'icon.component.html',
})
export class HorizontalBarIconComponent implements OnInit, OnChanges {

    @HostBinding('class')
    public elementClass: string;

    @ViewChild('ngIconContent', { static: false })
    public ngIconContent: ElementRef;

    @Output()
    public click: EventEmitter<void> = new EventEmitter();

    @Input()
    public icon: IconEnum | string;

    @Input()
    public disabled: boolean = false;

    @Input()
    public classModifiers: Array<string> = [];

    private classHelper: ClassHelper = new ClassHelper();

    public ngOnInit(): void {
        this.classHelper.addClasses([HorizontalBarIconClassesEnum.Base, ...this.classModifiers]);

        if (this.click.observers.length > 0) {
            this.classHelper.addClass(HorizontalBarIconClassesEnum.CursorPointer);
        }

        this.updateElementClasses();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            this.classHelper.toggleClassByBoolean(HorizontalBarIconClassesEnum.Disabled, this.disabled);
            this.updateElementClasses();
        }
    }

    private updateElementClasses(): void {
        this.elementClass = this.classHelper.toString();
    }
}
