import {
    AfterContentInit,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input, OnChanges, OnDestroy, SimpleChanges,
    ViewChild
} from '@angular/core';
import { Marker } from 'document/modules/components/hotspot/models/Marker.model';
import {Subscription } from 'rxjs';

interface Clip {
    shape: string;
    coordinates: string;
    top: string;
    left: string;
    width: string;
    height: string;
    rounding: string;
    x: string;
    y: string;
    radius: string;
}

@Component({
  selector: 'app-hotspot-answer',
  templateUrl: './hotspot-answer.component.html',
  styleUrls: ['./hotspot-answer.component.scss']
})
export class DocumentHotspotAnswerComponent implements AfterContentInit, OnChanges, OnDestroy {

    @HostBinding('class')
    @ViewChild('answerimage', {static: true}) public image: ElementRef;
    public elementClass: string = 'document__hotspot-answer';
    @Input() public imageSrc: string;
    @Input() public answer: any;
    @Input() public markers: Array<Marker>;
    @Input() public orgImageId: string;
    @Input() public isAutoScore: boolean;
    public clips: Array<Clip> = [];
    private imageWidth: number;
    private imageHeight: number;
    private loadSub: Subscription;

    constructor() { }

    public afterLoadImage(evt: any): void {
        if (evt && evt.target) {
            this.imageWidth = evt.target.naturalWidth;
            this.imageHeight = evt.target.naturalHeight;
            this.calculateClipsAndOutlines();
        }
    }

    public ngAfterContentInit(): void {
       this.calculateClipsAndOutlines();
   }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.answer && !changes.answer.firstChange) {
            this.calculateClipsAndOutlines();
        }
    }

    @HostListener('window:resize')
    private recalc(): void {
        this.calculateClipsAndOutlines();
    }

    private calculateClipsAndOutlines(): void {
        // Set image width in hard pixels to fix issue in iOS Safari.
        const defaultWidth = 420;
        const imagePixelWidth = this.imageWidth ? this.imageWidth : defaultWidth;

        if (!this.answer) {
            return;
        }

        this.clips = this.answer.map( (answer: Clip) => {
            if (answer.shape === 'rect' ) {
                const coords = answer.coordinates.split(',');
                const [left, top, right, bottom] = coords;

                return {
                    shape: 'inset',
                    top,
                    left,
                    coordinates: [
                        top,
                        100 - parseInt(right, 10) + '%',
                        100 - parseInt(bottom, 10) + '%',
                        left
                    ].join(' '),
                    width: parseInt(right, 10) - parseInt(left, 10) + '%',
                    height: parseInt(bottom, 10) - parseInt(top, 10) + '%',
                    rounding: '0'
                };
            }

            if (answer.shape === 'circle' ) {
                const coords = answer.coordinates.split(',');
                const [x, y, radius] = coords;
                const diameterPerc = parseInt(radius, 10) * 2;
                const diameterPixels = imagePixelWidth * (diameterPerc / 100);

                return {
                    shape: 'circle',
                    coordinates: `${radius} at ${x} ${y}`,
                    top: `calc(${y} - ${diameterPixels / 2}px)`,
                    left: `calc(${x} - ${diameterPixels / 2}px)`,
                    width: diameterPerc + '%',
                    height: diameterPixels + 'px',
                    rounding: '50%',
                    radius,
                    x,
                    y
                };
            }

        });

        if (this.isAutoScore) {

            // Wait one tick

            setTimeout( () => {

                this.markers = [...this.markers.map( marker => {

                    let markerInRange = false;
                    this.clips.forEach( clip => {

                        let topRangeStart = 0;
                        let topRangeEnd = 0;
                        let leftRangeStart = 0;
                        let leftRangeEnd = 0;

                        if (clip.shape === 'inset') {
                            topRangeStart = parseInt(clip.top, 10);
                            topRangeEnd = parseInt(clip.top, 10) + parseInt(clip.height, 10);
                            leftRangeStart = parseInt(clip.left, 10);
                            leftRangeEnd = parseInt(clip.left, 10) + parseInt(clip.width, 10);
                        }

                        if (clip.shape === 'circle') {
                            topRangeStart = parseInt(clip.y, 10) - parseInt(clip.radius, 10);
                            topRangeEnd = parseInt(clip.y, 10) + parseInt(clip.radius, 10);
                            leftRangeStart = parseInt(clip.x, 10) - parseInt(clip.radius, 10);
                            leftRangeEnd = parseInt(clip.x, 10) + parseInt(clip.radius, 10);
                        }

                        const matchFound = (marker.top > topRangeStart && marker.top < topRangeEnd && marker.left > leftRangeStart && marker.left < leftRangeEnd);

                        if (matchFound) {
                            markerInRange = true;
                        }
                    });

                    if (markerInRange) {
                        marker.valid = true;
                    }

                    return marker;

                })];

            }, 0);
        }
    }

    public ngOnDestroy(): void {
        // tslint:disable-next-line:no-unused-expression
        this.loadSub && this.loadSub.unsubscribe();
    }

}
