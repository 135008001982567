import {NgModule} from '@angular/core';
import {SharedModule} from 'shared/shared.module';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SearchPageComponent} from 'pages/modules/search/search/search.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {SearchResultsFactory} from 'pages/modules/search/factories/results.factory';
import {SearchItemComponent} from 'pages/modules/search/search/search-item/search-item.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        InfiniteScrollModule,
    ],
    declarations: [
        SearchPageComponent,
        SearchItemComponent,
    ],
    exports: [
        SearchPageComponent,
    ],
    providers: [
        SearchResultsFactory,
    ]
})
export class SearchModule {
}
