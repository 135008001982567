import {ProgressStudentDetailsColBodyInterface} from 'pages/modules/progress/components/student-details/interfaces/col-body.interface';
import {ProgressGroupInterface} from 'pages/modules/progress/interfaces/group.interface';
import {ProgressStudyMaterialInterface} from 'pages/modules/progress/interfaces/study-material.interface';
import {ProgressStudentInterface} from 'pages/modules/progress/interfaces/student.interface';

export abstract class AbstractStudentGroupRowsComponent {

    protected abstract getStudentGroup(): ProgressGroupInterface | undefined;

    protected abstract getStudyMaterial(): ProgressStudyMaterialInterface | undefined;

    protected abstract buildStudentRow(student: ProgressStudyMaterialInterface | ProgressStudentInterface): ProgressStudentDetailsColBodyInterface[];

    protected abstract setRows(rows: ProgressStudentDetailsColBodyInterface[][]): void;

    protected setupRows(): void {
        const rows: ProgressStudentDetailsColBodyInterface[][] = [];
        const group: ProgressGroupInterface | undefined = this.getStudentGroup();
        const studyMaterial: ProgressStudyMaterialInterface | undefined = this.getStudyMaterial();

        if (undefined !== group && null !== group && group.students.length > 0) {
            for (const student of group.students) {
                rows.push(this.buildStudentRow(student));
            }
        } else {
            rows.push(this.buildStudentRow(studyMaterial));
        }

        this.setRows(rows);
    }
}
