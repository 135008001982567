import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RadioBoxInterface} from 'shared/components/radio-box/interfaces/radio-box.interface';
import {OneDrivePreferencesEnum} from 'pages/modules/user/components/one-drive-preferences/enum/one-drive-preferences.enum';
import {RadioBoxEnum} from 'shared/components/radio-box/enum/radio-box.enum';

@Component({
    selector: 'app-one-drive-preferences',
    templateUrl: 'one-drive-preferences.component.html',
})
export class OneDrivePreferencesComponent {

    public readonly preferencesOptions: Array<RadioBoxInterface> = [
        {
            id: OneDrivePreferencesEnum.OfficeLocal,
            value: 'Office lokaal',
        },
        {
            id: OneDrivePreferencesEnum.OfficeOnline,
            value: 'Office online',
        }
    ];

    public classModifier = RadioBoxEnum ;

    @Input()
    public oneDrivePreferences: OneDrivePreferencesEnum;

    @Output()
    public oneDrivePreferencesChange: EventEmitter<OneDrivePreferencesEnum> = new EventEmitter();

    @Input()
    public disabled: boolean = false;

    public oneDrivePreferencesUpdated(): void {
        this.oneDrivePreferencesChange.emit(this.oneDrivePreferences);
    }
}
