/**
 * This file reflects the colors map in
 * 24boost/applications/frontend-twenty-four-boost/src/assets/scss/abstracts/_variables.scss
 */
export enum ColorEnum {

    White = '24boost-white',

    Purple = '24boost-purple',

    Red = '24boost-red',

    Grey = '24boost-grey',

    Orange = '24boost-orange',

    Yellow = '24boost-yellow',

    Green = '24boost-green',

    GreyDark = '24boost-grey-dark',

    GreyDarker = '24boost-grey-darker',

    Blue = '24boost-blue',

    BlueDark = '24boost-blue-dark',

}
