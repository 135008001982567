import {Component, OnDestroy} from '@angular/core';
import {RoleEnum} from 'enums/role.enum';
import {FeedRssService} from 'document/services/feed-rss/feed-rss.service';
import {Subscription} from 'rxjs';
import {FeedRssInterface} from 'document/modules/interfaces/feed-rss.interface';

@Component({
    selector: 'app-feed-rss',
    templateUrl: 'feed-rss.component.html',
})
export class FeedRssComponent implements OnDestroy {
    protected rssFeedSubscription: Subscription;

    public readonly roleEnum = RoleEnum;

    public rssFeed: FeedRssInterface;

    constructor(feedRssService: FeedRssService) {
        this.rssFeedSubscription = feedRssService.subscribeToRssFeed(rssFeed => this.onRssFeed(rssFeed));
    }

    public ngOnDestroy(): void {
        this.rssFeedSubscription.unsubscribe();
    }

    public onRssFeed(rssFeed: FeedRssInterface): void {
        this.rssFeed = rssFeed;
    }
}
