import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'shared/shared.module';
import {ProgressComponent} from 'pages/modules/progress/components/progress.component';
import {ProgressOwnTasksComponent} from 'pages/modules/progress/components/own-tasks/own-tasks.component';
import {ProgressGroupStudentDetailsComponent} from 'pages/modules/progress/components/group-student-details/group-student-details.component';
import {ProgressChapterResultComponent} from 'pages/modules/progress/components/chapter-result/chapter-result.component';
import {ProgressStudentDetailsComponent} from 'pages/modules/progress/components/student-details/student-details.component';
import {ProgressAverageDetailsComponent} from 'pages/modules/progress/components/average-details/average-details.component';
import {ProgressAssignmentNumberComponent} from 'pages/modules/progress/components/assignment-number/assignment-number.component';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {ProgressBookFactory} from 'pages/modules/progress/factories/book.factory';
import {ProgressChapterFactory} from 'pages/modules/progress/factories/chapter.factory';
import {ProgressGroupFactory} from 'pages/modules/progress/factories/group.factory';
import {ProgressStudentFactory} from 'pages/modules/progress/factories/student.factory';
import {ProgressAverageFactory} from 'pages/modules/progress/factories/average.factory';
import {ProgressStudyMaterialFactory} from 'pages/modules/progress/factories/study-material.factory';
import {ProgressAssignmentStatusFactory} from 'pages/modules/progress/factories/assignment-status.factory';
import {BpvAttachmentDetailComponent} from './components/bpv-attachment-detail/bpv-attachment-detail.component';
import {SecurityModule} from 'security/security.module';
import {ProgressModuleStatusFactory} from 'pages/modules/progress/factories/module-status.factory';
import {ProgressDrillsterStatusFactory} from 'pages/modules/progress/factories/drillster-status.factory';
import {RoutingModule} from 'routing/routing.module';
import {ProgressPopoverNotAssignedComponent} from 'pages/modules/progress/components/popover-not-assigned/popover-not-assigned.component';
import {ProgressExamScoreFactory} from 'pages/modules/progress/factories/exam-score.factory';
import {ProgressStudentDetailsExamsComponent} from 'pages/modules/progress/components/student-details/student-details-exams/student-details-exams.component';
import {ProgressExamsFactory} from 'pages/modules/progress/factories/exams.factory';
import {ProgressExamResultDetailComponent} from 'pages/modules/progress/components/exam-result-detail/exam-result-detail.component';
import {StudentResultsComponent} from 'pages/modules/progress/components/student-details/student-results/student-results.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgbPopoverModule,
        RoutingModule,
        SecurityModule,
    ],
    declarations: [
        ProgressComponent,
        ProgressOwnTasksComponent,
        ProgressGroupStudentDetailsComponent,
        ProgressChapterResultComponent,
        ProgressStudentDetailsComponent,
        ProgressAverageDetailsComponent,
        ProgressAssignmentNumberComponent,
        BpvAttachmentDetailComponent,
        ProgressPopoverNotAssignedComponent,
        ProgressStudentDetailsExamsComponent,
        ProgressExamResultDetailComponent,
        StudentResultsComponent
    ],
    providers: [
        ProgressBookFactory,
        ProgressChapterFactory,
        ProgressStudentFactory,
        ProgressAverageFactory,
        ProgressAssignmentStatusFactory,
        ProgressDrillsterStatusFactory,
        ProgressStudyMaterialFactory,
        ProgressGroupFactory,
        ProgressModuleStatusFactory,
        ProgressExamScoreFactory,
        ProgressExamsFactory,
    ],
    exports: [
        ProgressComponent,
        ProgressStudentDetailsComponent,
        StudentResultsComponent,
    ],
})
export class ProgressModule {
}
