export enum DocumentContentMediaClassEnum {

    Loading = 'document-content-media--loading',

    Error = 'document-content-media--error',

    Table = 'document__content-media--table',

    Iframe = 'document-content-media--iframe',

}
