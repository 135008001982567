import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RoleEnum} from 'enums/role.enum';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';
import {LocalStorageService} from 'angular-2-local-storage';
import {LocalStorageKeysEnum} from 'enums/local-storage-keys.enum';
import {FilterDropdownFilterInterface} from 'shared/components/filter-dropdown/interfaces/filter.interface';
import {IconEnum} from 'enums/icon.enum';
import {TaskGroupsFilterEnum} from 'pages/modules/tasks/enums/groups-filter.enum';
import {GroupsService} from 'services/groups/groups.service';

@Component({
    selector: 'app-group-filter',
    templateUrl: 'group-filter.component.html',
})
export class GroupFilterComponent implements OnInit {

    @Input()
    public classModifiers: Array<ContentSelectClassModifiersEnum> = [];

    @Input()
    public group: ApiGroupInterface;

    @Output()
    public groupChange: EventEmitter<ApiGroupInterface> = new EventEmitter();

    @Input()
    public disabled: boolean = false;

    @Input()
    public all: boolean = true;

    public groupsFilter: FilterDropdownFilterInterface<string | number> = {
        id: 'groups-filter',
        icon: IconEnum.Users,
        selectedValue: null,
        placeHolder: 'Selecteer groep',
        options: new Map<string, Map<string | number, string>>(),
    };

    public initialVisible: Map<string, boolean> = new Map();

    private groups: ApiGroupsInterface = null;

    constructor(
        private groupsService: GroupsService,
        private localStorageService: LocalStorageService,
        private authorizationService: AuthorizationService,
    ) {}

    public ngOnInit(): void {
        if (this.all === true) {
            this.groupsFilter.options.set('', new Map([
                [TaskGroupsFilterEnum.All, 'Alle groepen'],
            ]));

            this.groupsFilter.selectedValue = TaskGroupsFilterEnum.All;
        }

        this.groupsService.retrieveAllForFilter().subscribe((groups: ApiGroupsInterface) => this.processGroupData(groups));
    }

    public onGroupsFilterChange(filterChange: FilterDropdownFilterInterface<string | number>): void {
        this.groupsFilter = filterChange;

        this.updateSelectedGroup(filterChange.selectedValue);

        this.getActiveToPersisted();
    }

    private processGroupData(groups: ApiGroupsInterface): void {
        this.groups = groups;

        if (this.authorizationService.isGranted(RoleEnum.RoleTeacher)) {
            this.processGroupDataForTeachers();
        } else {
            this.processGroupDataForStudents();
        }

        this.setInitialActiveState();
        this.updateSelectedGroup(this.groupsFilter.selectedValue);
    }

    private processGroupDataForStudents(): void {
        const activeGroups = new Map<string | number, string>();
        const inActiveGroups = new Map<string | number, string>();
        const oldGroups = new Map<string | number, string>();

        for (const group of this.groups.data) {
            const groupName = GroupsService.buildGroupName(group.name, group.year);

            if (group.old === true) {
                oldGroups.set(String(group.id), groupName);
            } else if (group.active !== true) {
                inActiveGroups.set(String(group.id), groupName);
            } else {
                activeGroups.set(String(group.id), groupName);
            }
        }

        this.groupsFilter.options.set('Actieve groepen', activeGroups);
        this.groupsFilter.options.set('Inactieve groepen', inActiveGroups);
        this.groupsFilter.options.set('Verlaten groepen', oldGroups);
    }

    private processGroupDataForTeachers(): void {
        for (const group of this.groups.data) {
            if (group.year) {
                this.addGroupToYear(group.year, group);
            } else {
                this.addGroupToYear('Overige jaren', group);
            }
        }
    }

    private addGroupToYear(year: string, group: ApiGroupInterface): void {
        if (!this.groupsFilter.options.has(year)) {
            this.groupsFilter.options.set(year, new Map<string | number, string>());
        }

        this.groupsFilter.options.set(
            year,
            this.groupsFilter.options.get(year).set(String(group.id), GroupsService.buildGroupName(group.name, group.year))
        );
    }

    private updateSelectedGroup(groupId: number|string): void {
        let selectedGroup = null;

        if (groupId !== TaskGroupsFilterEnum.All) {
            for (const group of this.groups.data) {
                if (group.id === Number(groupId)) {
                    selectedGroup = group;

                    break;
                }
            }
        }

        if (this.groups && this.groups.data.length === 1) {
            this.groupsFilter.selectedValue = String(this.groups.data[0].id);
            selectedGroup = this.groups.data[0];
        }

        this.group = selectedGroup;
        this.groupChange.emit(this.group);
    }

    private setInitialActiveState(): void {
        const persisted = this.localStorageService.get<GroupPersistedInterface>(LocalStorageKeysEnum.GroupUserSingleData) || null;

        if (!(persisted instanceof Object) || Object.keys(persisted).length === 0) {
            this.setInitialVisibleForRole();

            return;
        }

        if (!persisted.groupId) {
            this.setInitialVisibleForRole();

            return;
        }

        for (const group of this.groups.data) {
            if (group.id === persisted.groupId) {
                this.groupsFilter.selectedValue = String(group.id);
            }
        }

        for (const optionKey of this.groupsFilter.options.keys()) {
            for (const groupKey of this.groupsFilter.options.get(optionKey).keys()) {
                if (groupKey === this.groupsFilter.selectedValue) {
                    this.initialVisible.set(optionKey, true);
                }
            }
        }
    }

    private setInitialVisibleForRole(): void {
        if (this.authorizationService.isGranted(RoleEnum.RoleTeacher)) {
            const keys = this.groupsFilter.options.keys();

            this.initialVisible.set(keys.next().value, true);
        } else {
            this.initialVisible.set('Actieve groepen', true);
        }
    }

    private getActiveToPersisted(): void {
        if (this.group === null) {
            this.localStorageService.set(LocalStorageKeysEnum.GroupUserSingleData, null);

            return;
        }

        let persisted = this.localStorageService.get<GroupPersistedInterface>(LocalStorageKeysEnum.GroupUserSingleData) || null;

        if (!(persisted instanceof Object) || Object.keys(persisted).length === 0) {
            persisted = {};
        }

        persisted.groupId = this.group.id;

        if (persisted.userId) {
            let userFound = false;

            for (const user of this.group.students) {
                if (user.id === persisted.userId) {
                    userFound = true;
                }
            }

            if (!userFound) {
                persisted.userId = null;
            }
        }

        this.localStorageService.set(LocalStorageKeysEnum.GroupUserSingleData, persisted);
    }
}
