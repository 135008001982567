import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
    selector: '[appRepeatElement]'
})
export class RepeatElementDirective {

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef) {
    }

    @Input()
    public set appRepeatElement(times: number) {
        for (let i = 0; i < times; i++) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
