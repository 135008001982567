import {TaskAttachmentInterface} from 'pages/modules/tasks/interfaces/attachment.interface';
import {TasksAttachmentTypesEnum} from 'pages/modules/tasks/enums/attachment-types.enum';
import {IconEnum} from 'enums/icon.enum';
import {environment} from 'environments/environment';

export class TasksAttachmentFileModel implements TaskAttachmentInterface {

    public type: TasksAttachmentTypesEnum.File;

    public icon: IconEnum = IconEnum.Attachment;

    constructor(
        public value: string,
        public id: number,
    ) {
    }

    public get url(): string {
        return `${environment.fileDownloadUrl}${this.id}`;
    }

}
