import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TeacherMaterialFolderModel} from 'pages/modules/teacher-material/models/folder-item.model';
import {BookshelfItemClassEnum} from 'pages/modules/bookshelf/components/item/enums/item-class.enum';
import {ResourceTypeEnum} from 'enums/resource-type.enum';
import {FolderChangeEventInterface} from 'pages/modules/teacher-material/interfaces/folder-change-event.interface';
import {ClassHelper} from 'helpers/dom/class.helper';
import {ReturnToTeacherMaterialsModel} from 'pages/modules/teacher-material/models/return-to-teacher-materials.model';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-shelf-folder',
    templateUrl: './folder.component.html',
})
export class ShelfFolderComponent implements OnInit {
    public resourceTypeEnum = ResourceTypeEnum;

    @Input()
    public bookId: number;

    @Input()
    public folder: TeacherMaterialFolderModel | ReturnToTeacherMaterialsModel;

    @Output()
    public folderChange: EventEmitter<FolderChangeEventInterface> = new EventEmitter<FolderChangeEventInterface>();

    public class: string;

    public classHelper: ClassHelper = new ClassHelper();

    public documentType: string;

    constructor(
        private toastService: ToastrService,
    ) {
    }

    public ngOnInit(): void {
        if (this.folder.dpsid && this.folder.dpsid.indexOf(ResourceTypeEnum.DpsidUploadSource) >= 0) {
            this.classHelper.addClass(BookshelfItemClassEnum.FolderUploadedSources);
        }

        if (this.folder.disabled) {
            this.classHelper.addClass(BookshelfItemClassEnum.FolderDisabled);
        }

        this.documentType = this.folder.documentType;
    }

    public handleClick(event: Event): void {
        event.preventDefault();

        if (!this.bookId) {
            this.toastService.warning('Geen boek id gevonden.');

            return;
        }

        this.folderChange.emit({
            bookId: this.bookId.toString(),
            folderDpsid: this.folder.dpsid,
        });
    }
}
