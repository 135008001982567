export enum HorizontalBarIconClassesEnum {

    Base = 'horizontal-bar__icon',

    CursorPointer = 'horizontal-bar__icon--cursor-pointer',

    Disabled = 'horizontal-bar__icon--disabled',

    ColorExams = 'horizontal-bar__icon--color-exams',

    ColorTask = 'horizontal-bar__icon--color-tasks',

    ColorDocument = 'horizontal-bar__icon--color-documents',

}
