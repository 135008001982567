import {SvgDataInterface} from 'interfaces/svg-data.interface';

export class SvgCircleModel implements SvgDataInterface {

    public name: string = 'circle';

    get data(): string {
        return `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <title class="rs_skip">circle</title>
            <circle r="6" cx="16" cy="16" fill="black" />
        </svg>`;
    }
}
