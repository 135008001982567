import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {ColorEnum} from 'enums/color.enum';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {ColorBarClassEnum} from 'shared/components/color-bar/enums/color-bar-class.enum';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {ClassHelper} from 'helpers/dom/class.helper';

@Component({
    selector: 'app-color-bar-component',
    templateUrl: 'color-bar.component.html',
})
export class ColorBarComponent implements OnInit, OnDestroy {

    protected static readonly CLASS = 'color-bar';

    public readonly routeColorMapping: Map<string, ColorEnum> = new Map([
        [RoutesEnum.Books, ColorEnum.Blue],
        [RoutesEnum.Tasks, ColorEnum.Orange],
        [RoutesEnum.Exams, ColorEnum.Red],
        [RoutesEnum.Progress, ColorEnum.Purple],
        [RoutesEnum.Groups, ColorEnum.Green],
        [RoutesEnum.MessageCenter, ColorEnum.BlueDark],
        [RoutesEnum.Search, ColorEnum.BlueDark],
        [RoutesEnum.Profile, ColorEnum.BlueDark],
        [RoutesEnum.Settings, ColorEnum.Blue],
    ]);

    @HostBinding('class')
    public elementClasses: string;

    public currentColorClass: string;

    private activatedRouteSubscription: Subscription;

    private classHelper: ClassHelper = new ClassHelper(ColorBarComponent.CLASS);

    constructor(
        private router: Router,
    ) {
    }

    public ngOnInit(): void {
        this.handleRouterChange();

        this.activatedRouteSubscription = this.router.events
            .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
            .subscribe(() => this.handleRouterChange());
    }

    public ngOnDestroy(): void {
        if (this.activatedRouteSubscription instanceof Subscription) {
            this.activatedRouteSubscription.unsubscribe();
        }
    }

    private handleRouterChange(): void {
        const url: string = this.router.url.split('?')[0];
        let colorSegment = null;

        for (const segment of url.split('/')) {
            if (this.routeColorMapping.has(segment)) {
                colorSegment = segment;
                break;
            }
        }

        this.setColorClass(colorSegment !== null ? this.routeColorMapping.get(colorSegment) : ColorEnum.GreyDarker);
    }

    private setColorClass(color: ColorEnum): void {
        this.classHelper.removeClass(this.currentColorClass);

        this.currentColorClass = `${ColorBarClassEnum.Class}--${color}`;
        this.classHelper.addClass(this.currentColorClass);

        this.elementClasses = this.classHelper.toString();
    }
}
