import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {RedactorService} from 'services/redactor/redactor.service';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {ToastrService} from 'ngx-toastr';
import {ContentSwitchGroupModel} from 'pages/modules/tasks/components/modals/add-modal/models/group-model';
import {GroupsService} from 'services/groups/groups.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {RoleEnum} from 'enums/role.enum';
import {NoteService} from 'services/note/note.service';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {AvatarClassesEnum} from 'shared/components/avatar/enums/avatar-classes.enum';
import {IconBoxClose} from 'models/iconbox/close.model';
import {finalize} from 'rxjs/operators';

@Component({
    selector: 'app-note-modal',
    templateUrl: 'note-modal.component.html',
})
export class NoteModalComponent implements OnInit, AfterViewInit {

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorBlue]);

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly avatarClasses = AvatarClassesEnum;

    @ViewChild('noteTextArea', { static: true })
    protected noteTextArea: ElementRef;

    @Input()
    public note: ApiNoteInterface;

    @Input()
    public studyMaterialTitle: string;

    @Input()
    public documentDpsid: string;

    @Input()
    public elementId: string;

    @Input()
    public noteContent: string;

    @Input()
    public correspondingNotes: Array<ApiNoteInterface>;

    public groups: Array<ContentSwitchGroupModel> = [];

    public editable: boolean = false;

    private redactor: RedactorAppInstance;

    public isLoading: boolean = false;

    constructor(
        private activeModal: NgbActiveModal,
        private redactorService: RedactorService,
        private noteService: NoteService,
        private toastService: ToastrService,
        private groupService: GroupsService,
        private authorizationService: AuthorizationService,
    ) {
    }

    public ngOnInit(): void {
        this.editable = !this.note || this.note.created_by.id === this.note.created_for.id;

        this.loadGroupsAndStudents();
    }

    public ngAfterViewInit(): void {
        this.redactorService.create(this.noteTextArea.nativeElement)
            .subscribe((redactor: RedactorAppInstance) => {
                this.redactor = redactor;

                if (this.noteContent) {
                    this.redactor.source.setCode(this.noteContent);
                }

                if (!this.editable) {
                    this.redactor.enableReadOnly();
                }
            });
    }

    public close(): void {
        this.activeModal.dismiss();
    }

    public handleSave(): void {
        if (!this.editable) {
            return;
        }

        if (this.note) {
            this.saveEdit();
        } else {
            this.saveNew();
        }
    }

    public handleRemove(): void {
        if (!this.note || !this.editable) {
            return;
        }

        this.noteService.remove(this.note.id)
        .subscribe(() => {
            this.toastService.success('Notitie is verwijderd.');

            this.activeModal.close([]);
        }, () => {
            this.toastService.error('Kon notitie niet verwijderen.');
        });
    }

    private loadGroupsAndStudents(): void {
        if (!this.authorizationService.isGranted(RoleEnum.RoleTeacher)) {
            return;
        }

        this.groupService.retrieveAllGroups()
            .subscribe((apiGroups: ApiGroupsInterface) => {
                const groups: Array<ContentSwitchGroupModel> = [];

                for (const group of apiGroups.data) {
                    groups.push(ContentSwitchGroupModel.fromApiGroup(group));
                }

                for (const correspondingNote of (this.correspondingNotes || [])) {
                    GROUPS_LOOP:
                        for (const group of groups) {
                            for (const student of group.children) {
                                if (Number(student.id) === correspondingNote.created_for.id) {
                                    student.active = true;

                                    break GROUPS_LOOP;
                                }
                            }
                        }
                }

                this.groups = groups;
            }, (errorResponse: HttpErrorResponse) => {
                this.toastService.error('Kon groepen en studenten niet ophalen.');
            });
    }

    private saveNew(): void {
        let users = null;
        this.isLoading = true;

        if (this.authorizationService.isGranted(RoleEnum.RoleTeacher)) {
            users = this.addUsers();
        }

        this.noteService.saveNew(this.documentDpsid, this.elementId, this.redactor.source.getCode(), users)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((notes: ApiNoteInterface) => {
                this.toastService.success('Notitie is toegevoegd.');

                this.activeModal.close([notes]);
            }, () => {
                this.toastService.error('Kon notitie niet toevoegen.');
            });
    }

    private saveEdit(): void {
        let users = null;
        this.isLoading = true;

        if (this.authorizationService.isGranted(RoleEnum.RoleTeacher)) {
            users = this.addUsers();
        }

        this.noteService.edit(this.note.id, this.documentDpsid, this.elementId, this.redactor.source.getCode(), users)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((notes: Array<ApiNoteInterface>) => {
                this.toastService.success('Notitie is gewijzigd.');

                this.activeModal.close([notes]);
            }, () => {
                this.toastService.error('Kon notitie niet wijzigen.');
            });
    }

    private addUsers(): Array<number> {
        const users = [];

        for (const group of this.groups) {
            if (group.active === true) {
                for (const student of group.children) {
                    if (!users.includes(student.id)) {
                        users.push(student.id);
                    }
                }

                continue;
            }

            for (const student of group.children) {
                if (student.active && !users.includes(student.id)) {
                    users.push(student.id);
                }
            }
        }

        return users;
    }
}
