import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {AuthenticationService} from 'security/services/authentication/authentication.service';
import {UserService} from 'security/services/user/user.service';
import {PublisherService} from 'services/publisher/publisher.service';
import {environment} from 'environments/environment';

@Injectable({
    providedIn: 'root',
})
export class RouterPublisherGuard implements CanActivate {

    private static navigatingToSlug: boolean = false;

    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private publisherService: PublisherService,
        private router: Router,
    ) {
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return new Observable((observer: Observer<boolean>) => {
            this.authenticationService.isAuthenticated.subscribe(() => {
                const user = this.userService.getUserData();
                const publisherSlug = this.publisherService.getFromState(state);

                // BD-1954: Redirect boomvo users to new frontend
                if ('boomvo' === publisherSlug) {
                    window.location.href = environment.boomvoUrl;

                    observer.next(false);
                } else if (this.publisherSlugInCorrect(publisherSlug) && user.publisher && user.publisher.slug) {
                    RouterPublisherGuard.navigatingToSlug = true;

                    const navProm = this.router.navigateByUrl(`/${user.publisher.slug}`);
                    navProm.finally(() => {
                        RouterPublisherGuard.navigatingToSlug = false;
                    });

                    observer.next(true);
                } else {
                    observer.next(true);
                }

                observer.complete();
            });
        });
    }

    private publisherSlugInCorrect(slug: string|null): boolean {
        const user = this.userService.getUserData();

        return !RouterPublisherGuard.navigatingToSlug && user.publisher !== null && (!slug || slug !== user.publisher.slug);
    }
}
