export enum DocumentMatchingClassEnum {

    Matching = 'document-matching-block',

    MatchingImage = 'document-matching-block--image',

    MatchingText = 'document-matching-block--text',

    MatchingHasTitles = 'document-matching-block--has-titles',

    MatchingShowCorrectAnswer = 'document-matching-block--has-correct-answer',

    MatchingShowInitialAnswer = 'document-matching-block--has-initial-answer',

    MatchingDisabled = 'document-matching-block--disabled',

}
