import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';
import {GridTableScrollControlButtonClassEnum} from 'shared/modules/grid-table/enums/scroll-control/button-class.enum';
import {TableGridScrollControlDirectionEnum} from 'shared/modules/grid-table/enums/scroll-control/direction.enum';
import {GridTableScrollEventInterface} from 'shared/modules/grid-table/interfaces/scroll-event.interface';
import {IconEnum} from 'enums/icon.enum';

@Component({
    selector: 'app-grid-table-scroll-control',
    templateUrl: 'scroll-control.component.html',
})
export class GridTableScrollControlComponent implements OnInit {

    public static readonly CLASS = 'grid-table__scroll-control';

    public readonly direction = TableGridScrollControlDirectionEnum;

    @HostBinding('class')
    protected classes: string;

    @Output()
    public scrollClickEvent: EventEmitter<GridTableScrollEventInterface> = new EventEmitter();

    @Input()
    public displayPrevious: boolean = true;

    @Input()
    public previousText: string;

    @Input()
    public iconPrevious: IconEnum = IconEnum.ArrowLeft;

    @Input()
    public displayNext: boolean = true;

    @Input()
    public nextText: string;

    @Input()
    public iconNext: IconEnum = IconEnum.ArrowRight;

    @Input()
    public scrollSize: number;

    @Input()
    public set canScrollPrev(value: boolean) {
        this.toggleDisabledModifier(TableGridScrollControlDirectionEnum.Prev, value);
    }

    public get canScrollPrev(): boolean {
        return !this.hasDisabledModifier(TableGridScrollControlDirectionEnum.Prev);
    }

    @Input()
    public set canScrollNext(value: boolean) {
        this.toggleDisabledModifier(TableGridScrollControlDirectionEnum.Next, value);
    }

    public get canScrollNext(): boolean {
        return !this.hasDisabledModifier(TableGridScrollControlDirectionEnum.Next);
    }

    public classHelper: ClassHelper = new ClassHelper(GridTableScrollControlComponent.CLASS);

    public ngOnInit(): void {
        this.classes = this.classHelper.toString();
    }

    public onClick(direction: TableGridScrollControlDirectionEnum): void {
        if (this.hasDisabledModifier(direction)) {
            return;
        }

        this.scrollClickEvent.emit({direction, scrollSize: this.scrollSize});
    }

    private hasDisabledModifier(direction: TableGridScrollControlDirectionEnum): boolean {
        return this.classHelper.hasClass(GridTableScrollControlButtonClassEnum.Disabled, direction.toString());
    }

    private toggleDisabledModifier(direction: TableGridScrollControlDirectionEnum, value: boolean): void {
        this.classHelper.toggleClassByBoolean(GridTableScrollControlButtonClassEnum.Disabled, !value, direction.toString());
    }
}
