import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {DocumentJitAnchorDirective} from 'document/modules/components/jit-content/jit-anchor.directive';
import {DocumentJitComponent} from 'document/modules/components/jit-content/jit.component';
import {DocumentStepListItemComponent} from 'document/modules/components/step-list-item/step-list-item.component';
import {DocumentStepListComponent} from 'document/modules/components/step-list/step-list.component';
import {SharedModule} from 'shared/shared.module';
import {DocumentTextareaComponent} from 'document/modules/components/textarea/textarea.component';
import {DocumentContentMarkComponent} from 'document/modules/components/content-mark/content-mark.component';
import {DocumentContentMediaComponent} from 'document/modules/components/content-media/content-media.component';
import {DocumentDirectivesModule} from 'document/modules/directives/directives.module';
import {DocumentAnswerComponent} from 'document/modules/components/document-answer/document-answer.component';
import {DocumentUserInputInlineComponent} from 'document/modules/components/user-input-inline/user-input-inline.component';
import {DocumentSingleSelectInlineComponent} from 'document/modules/components/single-select-inline/single-select-inline.component';
import {DocumentSingleSelectBlockComponent} from 'document/modules/components/single-select-block/single-select-block.component';
import {DocumentMultiSelectBlockComponent} from 'document/modules/components/multi-select-block/multi-select-block.component';
import {DocumentCanvasComponent} from 'document/modules/components/canvas/canvas.component';
import {DocumentReferenceComponent} from 'document/modules/components/reference/reference.component';
import {DocumentEnlargeComponent} from 'document/modules/components/enlarge/enlarge.component';
import {DocumentTableComponent} from 'document/modules/components/table/table.component';
import {DocumentTableResponsiveComponent} from 'document/modules/components/table-responsive/table-responsive.component';
import {DocumentSortableBlockComponent} from 'document/modules/components/sortable-block/sortable-block.component';
import {DocumentMatchingComponent} from 'document/modules/components/matching/matching.component';
import {DocumentSubSectionComponent} from 'document/modules/components/sub-section/sub-section.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DocumentHandedInComponent} from 'document/modules/components/document-handed-in/document-handed-in.component';
import {DocumentAlgebrakitComponent} from 'document/modules/components/algebrakit/algebrakit.component';
import {DocumentReviewComponent} from 'document/modules/components/review/review.component';
import {DocumentRatingScoreComponent} from 'document/modules/components/rating-score/rating-score.component';
import {SecurityModule} from 'security/security.module';
import {DocumentExternalReviewerComponent} from 'document/modules/components/external-reviewer/external-reviewer.component';
import {DocumentHeadingComponent} from 'document/modules/components/heading/heading.component';
import {DocumentInitialAnswerComponent} from 'document/modules/components/document-initial-answer/document-initial-answer.component';
import {DocumentMultiMatchingComponent} from './multi-matching/multi-matching.component';
import {DocumentHotspotComponent} from './hotspot/hotspot.component';
import {DocumentHotspotAnswerComponent} from './hotspot/hotspot-answer/hotspot-answer.component';
import {DocumentGraphicGapMatchComponent} from './graphic-gap-match/graphic-gap-match.component';
import {DocumentNeutralFeedbackComponent} from 'document/modules/components/neutral-feedback/neutral-feedback.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        DocumentDirectivesModule,
        BrowserAnimationsModule,
        DragDropModule,
        SecurityModule,
    ],
    declarations: [
        DocumentEnlargeComponent,
        DocumentTableResponsiveComponent,
        DocumentTableComponent,
        DocumentTextareaComponent,
        DocumentContentMarkComponent,
        DocumentContentMediaComponent,
        DocumentAnswerComponent,
        DocumentUserInputInlineComponent,
        DocumentSingleSelectInlineComponent,
        DocumentSingleSelectBlockComponent,
        DocumentMultiSelectBlockComponent,
        DocumentCanvasComponent,
        DocumentReferenceComponent,
        DocumentSortableBlockComponent,
        DocumentMatchingComponent,
        DocumentAlgebrakitComponent,
        DocumentSubSectionComponent,
        DocumentHandedInComponent,
        DocumentReviewComponent,
        DocumentRatingScoreComponent,
        DocumentExternalReviewerComponent,
        DocumentHeadingComponent,
        DocumentInitialAnswerComponent,
        DocumentStepListComponent,
        DocumentStepListItemComponent,
        DocumentJitComponent,
        DocumentJitAnchorDirective,
        DocumentMultiMatchingComponent,
        DocumentHotspotComponent,
        DocumentHotspotAnswerComponent,
        DocumentGraphicGapMatchComponent,
        DocumentNeutralFeedbackComponent
    ],
    exports: [
        DocumentEnlargeComponent,
        DocumentTableComponent,
        DocumentTableResponsiveComponent,
        DocumentTextareaComponent,
        DocumentContentMarkComponent,
        DocumentContentMediaComponent,
        DocumentAnswerComponent,
        DocumentUserInputInlineComponent,
        DocumentSingleSelectInlineComponent,
        DocumentSingleSelectBlockComponent,
        DocumentMultiSelectBlockComponent,
        DocumentCanvasComponent,
        DocumentReferenceComponent,
        DocumentAlgebrakitComponent,
        DocumentSortableBlockComponent,
        DocumentMatchingComponent,
        DocumentSubSectionComponent,
        DocumentHandedInComponent,
        DocumentReviewComponent,
        DocumentRatingScoreComponent,
        DocumentExternalReviewerComponent,
        DocumentHeadingComponent,
        DocumentInitialAnswerComponent,
        DocumentStepListComponent,
        DocumentStepListItemComponent,
        DocumentJitComponent,
        DocumentJitAnchorDirective,
        DocumentHotspotComponent,
        DocumentHotspotAnswerComponent,
        DocumentMultiMatchingComponent,
        DocumentGraphicGapMatchComponent,
        DocumentNeutralFeedbackComponent
    ],
})
export class DocumentComponentsModule {
}
