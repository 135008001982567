import {AfterViewInit, Component, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {ProgressGroupInterface} from 'pages/modules/progress/interfaces/group.interface';
import {ProgressStudentInterface} from 'pages/modules/progress/interfaces/student.interface';
import {ProgressAverageInterface} from 'pages/modules/progress/interfaces/average.interface';
import {ProgressStudyMaterialInterface} from 'pages/modules/progress/interfaces/study-material.interface';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {RoleEnum} from 'enums/role.enum';
import {ProgressAssignmentStatusInterface} from 'pages/modules/progress/interfaces/assignment-status.interface';
import {AssignmentService} from 'services/assignment/assignment.service';
import {NavigationExtras, Router} from '@angular/router';
import {RouteService} from 'routing/services/route/route.service';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {ProgressStatusFilterEnum} from 'shared/components/status-filter/enum/progress-status-filter.enum';
import {CollapsibleClassEnum} from 'shared/components/collapsible/enums/class.enum';
import {NavigateToTaskEvent, StudentResultsComponent} from 'pages/modules/progress/components/student-details/student-results/student-results.component';
import {GridTableScrollControlComponent} from 'shared/modules/grid-table/components/scroll-control/scroll-control.component';

@Component({
    selector: 'app-progress-student-details',
    templateUrl: 'student-details.component.html',
})
export class ProgressStudentDetailsComponent implements AfterViewInit, OnChanges {
    @ViewChild(StudentResultsComponent, {static: true})
    private studentResultsComponent: StudentResultsComponent;

    @ViewChildren(GridTableScrollControlComponent)
    public readonly gridTableScrollControlComponents: QueryList<GridTableScrollControlComponent>;

    public readonly iconEnum = IconEnum;

    public readonly collapsibleClasses = CollapsibleClassEnum;

    public readonly tableClasses = GridTableClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly rowClasses = GridTableRowClassEnum;

    @Input()
    public classHelper: ClassHelper;

    @Input()
    public group: ProgressGroupInterface;

    @Input()
    public user: ProgressStudentInterface = null;

    @Input()
    public studyMaterial: ProgressStudyMaterialInterface;

    @Input()
    public bookId: number;

    @Input()
    public isBpv: boolean = false;

    @Input()
    public filter: ProgressStatusFilterEnum;

    @Input()
    public hidePopover: boolean = false;

    public average: ProgressAverageInterface;

    public containsAssignments: boolean = false;

    constructor(
        private authorizationService: AuthorizationService,
        private assignmentService: AssignmentService,
        private routeService: RouteService,
        private router: Router,
    ) {
    }

    public ngAfterViewInit(): void {
        this.studentResultsComponent.addScrollControls(this.gridTableScrollControlComponents);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.filter) {
            const average = this.average = this.authorizationService.isGranted(RoleEnum.RoleTeacher) ? this.group.average : this.studyMaterial.average;

            this.hasAssignments();

            // Trigger setup on student results
            this.studentResultsComponent.update({
                studyMaterial: this.studyMaterial,
                group: this.group,
                filter: this.filter,
                bookId: this.bookId,
                isBpv: this.isBpv,
                average
            });
        }
    }

    public navigateToTask(event: NavigateToTaskEvent): void {
        const assignmentStatus: ProgressAssignmentStatusInterface = event.assignmentStatus;
        const student: ProgressStudentInterface | null = event.student;

        if (assignmentStatus.progressStatus === 0) {
            this.navigateToDocument(assignmentStatus);

            return;
        }

        let studentId = null;

        const queryParams: NavigationExtras = {
            queryParams: {}
        };

        if (student !== null && this.authorizationService.isGranted(RoleEnum.RoleTeacher)) {
            studentId = student.id;
            queryParams.queryParams.student = student.id;
        }

        this.assignmentService.retrieveTasks(this.bookId, assignmentStatus.actualAssignmentId, this.group ? this.group.id : null, studentId)
            .subscribe((tasks: Array<ApiTaskInterface>) => {
                if (tasks.length === 0) {
                    this.navigateToDocument(assignmentStatus);
                } else {
                    this.router.navigate([this.routeService.getRouteWithPublisher(
                        RoutesEnum.TaskDetails, new Map([
                            ['taskId', tasks[0].id.toString()],
                        ])
                    )], queryParams);
                }
            });
    }

    private navigateToDocument(assignmentStatus: ProgressAssignmentStatusInterface): void {
        if (!assignmentStatus.dpsid) {
            return;
        }

        this.router.navigateByUrl(this.routeService.getRouteWithPublisher(
            RoutesEnum.Document, new Map([
                ['bookId', this.bookId.toString()],
                ['documentDpsid', assignmentStatus.dpsid],
            ])
        ));
    }

    private hasAssignments(): void {
        let filteredAssignments;
        let filteredDrillster;

        if (this.group && this.group.students.length > 0) {
            this.group.students.forEach(obj => {
                if (undefined !== obj.assignmentStatuses[0]) {
                    filteredAssignments = obj.assignmentStatuses;
                }
                if (undefined !== obj.drillsterStatusses[0]) {
                    filteredDrillster = obj.drillsterStatusses;
                }
            });
        } else {
            filteredAssignments = this.studyMaterial.assignmentStatuses;
            filteredDrillster = this.studyMaterial.drillsterStatusses;
        }

        if (
            (undefined !== filteredAssignments && filteredAssignments.length > 0) ||
            (undefined !== filteredDrillster && filteredDrillster.length > 0)) {
            this.containsAssignments = true;
        }
    }
}
