import {fromEvent, Observable, Observer} from 'rxjs';
import {pairwise, switchMap, takeUntil} from 'rxjs/operators';
import {EventEmitter} from '@angular/core';

export class CanvasInstance {

    private readonly canvasElement: HTMLCanvasElement;

    private readonly dataSetOptions: DocumentCanvasDatasetOptionsInterface;

    private readonly cx: CanvasRenderingContext2D;

    private readonly onChangeEvent: EventEmitter<any> = new EventEmitter();

    private lineValues: any;

    private backgroundImage: HTMLImageElement;

    private clientWidth: number;

    private active: boolean = false;

    private disabled: boolean = false;

    public constructor(
        canvasElement: HTMLCanvasElement,
        dataSetOptions: DocumentCanvasDatasetOptionsInterface,
        clientWidth: number,
        changeSubscription?: any
    ) {
        this.canvasElement = canvasElement;
        this.dataSetOptions = dataSetOptions;

        this.onChangeEvent.subscribe(changeSubscription);

        this.cx = this.canvasElement.getContext('2d');

        this.resize(clientWidth);

        this.setCxSettings();
        this.captureTouchEvents(this.canvasElement);
        this.captureEvents(this.canvasElement);
    }

    public reset(): void {
        this.lineValues = [];

        // start our drawing path
        this.cx.beginPath();

        // Use the identity matrix while clearing the canvas
        this.cx.setTransform(1, 0, 0, 1, 0, 0);
        this.cx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);

        // Restore the transform
        this.cx.restore();

        // set some default properties about the line
        this.drawBackgroundImage();
        this.setCxSettings();
    }

    public setAnswer(answer: any): void {
        this.lineValues = answer;

        this.loadCanvasImage().subscribe(() => {
            if (!this.cx) {
                return;
            }

            this.drawBackgroundImage();
            this.setCxSettings();

            for (let i = 0, l = this.lineValues.length; i < l; i++) {
                for (let j = 0, l2 = this.lineValues[i].length; j < l2; j++) {
                    const coords = this.lineValues[i][j];

                    if (j !== 0) {
                        this.cx.lineTo(coords.x, coords.y);
                    } else {
                        this.cx.moveTo(coords.x, coords.y);
                    }
                }

                this.cx.stroke();
            }

            this.active = true;
        });
    }

    public resize(clientWidth: number): void {
        this.clientWidth = clientWidth;

        this.canvasElement.width = this.getCanvasWidth();
        this.canvasElement.height = this.getCanvasHeight();

        this.reset();
    }

    public isActive(): boolean {
        return this.active;
    }

    public toggle(): void {
        this.loadCanvasImage()
            .subscribe(() => {
                this.reset();
                this.active = !this.active;
            });
    }

    public setDisabled(disabled: boolean): void {
        this.disabled = disabled;
    }

    public getAnswer(): any {
        return this.lineValues;
    }

    private setCxSettings(): void {
        this.cx.lineWidth = 2;
        this.cx.lineCap = 'round';
        this.cx.strokeStyle = '#189ACB';
    }

    private drawBackgroundImage(): void {
        if (!this.backgroundImage) {
            return;
        }

        const ratio = this.getCanvasRatio();
        const imageOriginalWidth: number = this.getCanvasDataWidth();
        const imageOriginalHeight: number = this.getCanvasDataHeight();
        const imageWidth: number = imageOriginalWidth * ratio;
        const imageHeight = imageOriginalHeight * ratio;

        this.canvasElement.height = imageHeight; // Set canvas height

        this.cx.drawImage(this.backgroundImage, 0, 0, imageOriginalWidth, imageOriginalHeight, 0, 0, imageWidth, imageHeight);
    }

    /**
     * Capture touch events and trigger mouse events
     */
    private captureTouchEvents(canvas: HTMLCanvasElement): void {
        canvas.addEventListener('touchstart', (event: TouchEvent) => {
            event.preventDefault();
            event.stopPropagation();

            if (this.disabled) {
                return;
            }

            canvas.dispatchEvent(new MouseEvent('mousedown'));
        }, false);

        canvas.addEventListener('touchend', () => {
            if (this.disabled) {
                return;
            }

            canvas.dispatchEvent(new MouseEvent('mouseup'));
        }, false);

        canvas.addEventListener('touchmove', (event: TouchEvent) => {
            if (this.disabled) {
                return;
            }

            const touch = event.touches[0];
            const mouseEvent = new MouseEvent('mousemove', {clientX: touch.clientX, clientY: touch.clientY});

            canvas.dispatchEvent(mouseEvent);
        }, false);
    }

    private captureEvents(canvas: HTMLCanvasElement): void {
        // Handle save!
        fromEvent(canvas, 'mousedown')
            .subscribe(() => {
                if (this.disabled) {
                    return;
                }

                this.lineValues.push([]);
            });

        // this will capture all mousedown events from the canvas element
        fromEvent(canvas, 'mousedown')
            .pipe(
                switchMap(() => {

                    // after a mouse down, we'll record all mouse moves
                    return fromEvent(canvas, 'mousemove')
                        .pipe(
                            // we'll stop (and unsubscribe) once the user releases the mouse
                            // this will trigger a 'mouseup' event
                            takeUntil(fromEvent(canvas, 'mouseup')),
                            // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
                            takeUntil(fromEvent(canvas, 'mouseleave')),
                            // pairwise lets us get the previous value to draw a line from
                            // the previous point to the current point
                            pairwise()
                        );
                })
            )
            .subscribe((res: [MouseEvent, MouseEvent]) => {
                const rect = canvas.getBoundingClientRect();

                // previous and current position with the offset
                const prevPos = {
                    x: res[0].clientX - rect.left,
                    y: res[0].clientY - rect.top
                };

                const currentPos = {
                    x: res[1].clientX - rect.left,
                    y: res[1].clientY - rect.top
                };

                // this method we'll implement soon to do the actual drawing
                this.drawOnCanvas(prevPos, currentPos);
            });

        // Handle save!
        fromEvent(canvas, 'mouseup')
            .subscribe(() => {
                if (this.disabled) {
                    return;
                }

                this.onChangeEvent.emit(this.lineValues);
            });
    }

    private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }): void {
        // in case the context is not set
        if (!this.cx || this.disabled) {
            return;
        }

        // start our drawing path
        this.cx.beginPath();

        // we're drawing lines so we need a previous position
        if (prevPos) {
            // sets the start point
            this.cx.moveTo(prevPos.x, prevPos.y); // from

            // draws a line from the start pos until the current position
            this.cx.lineTo(currentPos.x, currentPos.y);

            // strokes the current path with the styles we set earlier
            this.cx.stroke();

            // Add to values
            this.lineValues[this.lineValues.length - 1].push({x: currentPos.x, y: currentPos.y});
        }
    }

    private getCanvasDataWidth(): number {
        return parseInt(this.dataSetOptions.width, 10);
    }

    private getCanvasWidth(): number {
        return this.getCanvasDataWidth() * this.getCanvasRatio();
    }

    private getCanvasDataHeight(): number {
        return parseInt(this.dataSetOptions.height, 10);
    }

    private getCanvasHeight(): number {
        return this.getCanvasDataHeight() * this.getCanvasRatio();
    }

    private getCanvasRatio(): number {
        return this.clientWidth / this.getCanvasDataWidth();
    }

    private loadCanvasImage(): Observable<boolean> {
        return new Observable((observer: Observer<any>) => {
            if (this.backgroundImage || !this.dataSetOptions || !this.dataSetOptions.imagepath || !this.dataSetOptions.src) {
                observer.next(true);
            } else {
                const backgroundImage = new Image();

                backgroundImage.src = this.dataSetOptions.imagepath + this.dataSetOptions.src;

                backgroundImage.onerror = () => {
                    observer.error('An error occurred while loading the image');
                };

                backgroundImage.onload = () => {
                    this.backgroundImage = backgroundImage;
                    observer.next(true);
                };
            }
        });
    }

}
