import {BookshelfItemModel} from 'app/modules/pages/modules/bookshelf/models/item.model';
import {Injectable} from '@angular/core';

@Injectable()
export class BookshelfItemModelFactory {

    public fromApiBook(apiBookItem: ApiBookInterface): BookshelfItemModel {
        return new BookshelfItemModel(
            apiBookItem.id,
            apiBookItem.title,
            apiBookItem.dummy,
            apiBookItem.demo,
            apiBookItem.isFavorite,
            apiBookItem.dpsid,
            apiBookItem.cover,
            apiBookItem.studymaterialmethod,
        );
    }

    public fromApiBooks(bookshelfApiItems: Array<ApiBookInterface>): Array<BookshelfItemModel> {
        const bookShelfItems: Array<BookshelfItemModel> = [];
        bookshelfApiItems.forEach((value: ApiBookInterface) => {
            bookShelfItems.push(this.fromApiBook(value));
        });

        return bookShelfItems;
    }
}
