import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges
} from '@angular/core';
import {ModalService} from 'core/services/modal/modal.service';
import {Subscription} from 'rxjs';
import {UserService} from 'security/services/user/user.service';

@Component({
    selector: 'app-document-notes-edit-button',
    templateUrl: 'edit-button.component.html',
})
export class DocumentNotesEditButtonComponent implements OnInit, AfterViewInit, OnChanges, AfterViewChecked, OnDestroy {

    @HostBinding('class')
    private className: string = 'document-notes-button';

    @Input()
    public note: ApiNoteInterface;

    @Output()
    public noteChange: EventEmitter<ApiNoteInterface> = new EventEmitter();

    @Input()
    public document: ApiDocumentDataInterface;

    @Output()
    public removedEvent: EventEmitter<ApiNoteInterface> = new EventEmitter();

    public currentUserId: number = null;

    public correspondingNotes: Array<ApiNoteInterface> = [];

    private element: HTMLElement;

    private currentTopOffset: number;

    private userChangedSubscription: Subscription;


    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private modalService: ModalService,
        private userService: UserService
    ) {}

    public ngOnInit(): void {
        this.currentUserId = this.userService.getCurrentUserId();

        this.userChangedSubscription = this.userService.onUserDataChange.subscribe(() => {
            this.currentUserId = this.userService.getCurrentUserId();
        });
    }

    public ngAfterViewInit(): void {
        this.adjustTopOffset();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.note && changes.note.currentValue !== changes.note.previousValue) {
            this.element = null;
        }

        this.setElement();
        this.adjustTopOffset();
    }

    public ngAfterViewChecked(): void {
        this.setElement();
        this.adjustTopOffset();
    }

    public ngOnDestroy(): void {
        if (this.userChangedSubscription instanceof Subscription) {
            this.userChangedSubscription.unsubscribe();
        }
    }

    private adjustTopOffset(): void {
        if (!this.element || this.currentTopOffset === this.element.offsetTop) {
            return;
        }

        if (this.element.isConnected === false) {
            this.element = null;
            this.setElement();
        }

        this.currentTopOffset = this.element.offsetTop;
        this.renderer.setStyle(this.elementRef.nativeElement, 'top', `${this.currentTopOffset}px`);
    }

    private setElement(): void {
        if (!this.note || this.element) {
            return;
        }

        this.correspondingNotes = this.getAllCorrespondingNotes();

        this.element = document.getElementById(this.note.html_id);

        if (!this.element) {
            return;
        }

        this.element.onresize = ($event: Event) => this.onAddToSummary($event);
    }

    private getAllCorrespondingNotes(): Array<ApiNoteInterface> {
        const notes = [];

        for (const note of this.document.notes) {
            if (note.id !== this.note.id && note.created_by.id === this.note.created_by.id && note.html_id === this.note.html_id) {
                notes.push(note);
            }
        }

        return notes;
    }

    public onAddToSummary(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        this.modalService.noteEdit(this.note, this.document.title, this.document.dpsid, this.note.html_id, this.correspondingNotes)
            .subscribe((notes: Array<ApiNoteInterface>) => {
                if (notes.length > 0) {
                    this.note = notes[0];
                    this.noteChange.emit(this.note);
                } else {
                    this.removedEvent.emit(this.note);
                }
            });
    }
}
