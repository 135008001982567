export enum RoleEnum {
    /**
     * Backend roles
     */

    RoleStudent = 10,

    RoleTeacher = 20,

    RoleReviewer = 30,

    /**
     * Application general roles 30000 ~ 39999
     */

    RoleHasPublisher = 30000,

    RoleStudentOrTeacher = 30001,

    /**
     * Application navbar roles: 40000 ~ 49999
     */

    RoleNavbarAll = 40000,

    RoleNavbarGroups = 41000,

    RoleNavbarTasks = 42000,

    RoleNavbarTests = 43000,

    RoleNavbarProgress = 44000,

    RoleNavbarMaterial = 45000,

    /**
     * Application toc roles: 50000 ~ 59999
     */

    RoleTocAll = 50000,

    RoleTocAssignTask = 51000,

    RoleTocAssignExam = 52000,

    /**
     * Application document roles: 60000 ~ 69999
     */

    RoleDocumentAll = 60000,

    RoleDocumentAssignTask = 60001,

}
