export enum StudyMaterialDocTypeEnum {

    Question = 'question',

    Theory = 'theory',

    Map = 'map',

    MapWithoutDocument = 'map-without-document',

    Exam = 'exam',

    PracticeExam = 'practice-exam',

}
