import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {DocumentService} from 'document/services/document/document.service';
import {Subject, Subscription} from 'rxjs';

@Injectable()
export class DocumentAnswersService {

    // listens to saved assignement
    private savedAssignment: Subject<ApiDocumentAssignmentInterface> = new Subject();

    // public readonly savedAssignmentSuccess: BehaviorSubject<ApiDocumentAssignmentInterface> = new BehaviorSubject<number>(0);
    public subscribe(fn: (answerData: ApiDocumentAssignmentInterface) => void): Subscription {
        return this.savedAssignment.subscribe(fn);
    }

    constructor(private apiService: ApiService, private documentService: DocumentService) {
    }

    public currentAnswer(assignmentId: string, fieldId: number): ApiDocumentAssignmentFieldInterface | null {
        const assignment = this.getAssignment(assignmentId);

        if (assignment === null) {
            return null;
        }

        for (const field of assignment.fields) {
            if (field.field_id === fieldId) {
                return field;
            }
        }

        return null;
    }

    public getAssignment(assignmentId: string): ApiDocumentAssignmentInterface | null {
        const document = this.documentService.getDocument();

        for (const assignment of document.assignments) {
            if (assignment.assignment_id === assignmentId) {
                return assignment;
            }
        }

        return null;
    }

    public saveAnswer(assignmentId: string, fieldId: number, value: any): Subscription | null {
        const assignment = this.getAssignment(assignmentId);
        const field = this.currentAnswer(assignmentId, fieldId);


        if (assignment === null || field === null) {
            return null;
        }

        const body = {
            assignmentfield: field.id || null,
            assignment_id: assignmentId,
            field_id: fieldId,
            dpsid: assignment.dpsid,
            value: value,
            book: this.documentService.getBookId()
        };


        return this.apiService.post<null>(ApiEndpointEnum.SaveAnswer, body).subscribe(() => this.savedAssignment.next(assignment));
    }

}
