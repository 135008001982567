import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {ApiService} from 'core/services/api/api.service';

@Injectable()
export class AlgebrakitService {

    public constructor(
        private api: ApiService,
    ) {
    }

    public cleanAnswer(assignmentId: string): Observable<string> {
        const params = {
            assignmentfield: assignmentId,
        };

        return this.api.post<string>(ApiEndpointEnum.AnswerClean, params);
    }
}
