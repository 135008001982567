import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {CollapsibleComponent} from 'shared/components/collapsible/collapsible.component';
import * as moment from 'moment';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {CollapsibleClassEnum} from 'shared/components/collapsible/enums/class.enum';

@Component({
    selector: 'app-exams-month',
    templateUrl: 'month.component.html',
})
export class ExamsMonthComponent {

    public readonly collapsibleClassEnum = CollapsibleClassEnum;

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly iconEnum = IconEnum;

    @ViewChild(CollapsibleComponent, { static: true })
    public collapsible: CollapsibleComponent;

    @Input()
    public month: ApiExamMonthInterface;

    @Input()
    public initiallyExpanded: boolean = false;

    @Output()
    public loadExamPlans: EventEmitter<string> = new EventEmitter();

    private initialExpandOver: boolean = false;

    public getEndDate(): moment.Moment {
        const month = moment(this.month.date);

        return month.clone().endOf('month');
    }

    public onExpand(): void {
        if (this.initiallyExpanded && this.initialExpandOver === false) {
            this.initialExpandOver = true;

            return;
        }

        this.loadExamPlans.emit(this.month.id);
    }

    public handleCollapse(event: MouseEvent): void {
        event.stopPropagation();

        this.collapsible.handleExpand();
    }
}
