import {RoleEnum} from 'enums/role.enum';
import {RolesLogicInterface} from 'security/interfaces/roles-logic.interface';
import {Injectable} from '@angular/core';
import {UserService} from 'security/services/user/user.service';
import {ApiUserDataInterface} from 'interfaces/api/user-data.interface';

@Injectable()
export class NavbarRolesLogic implements RolesLogicInterface {

    protected userData: ApiUserDataInterface;

    constructor(
        private userService: UserService,
    ) {
    }

    public getRoles(): Array<RoleEnum> {
        const roles: Array<RoleEnum> = [
            RoleEnum.RoleNavbarMaterial,
            RoleEnum.RoleNavbarGroups,
        ];

        this.userData = this.userService.getUserData();

        if (!this.hasGroups()) {
            return roles;
        }

        switch (this.userData.role) {
            case RoleEnum.RoleStudent:
                roles.push(...this.getStudentRoles());
                break;

            case RoleEnum.RoleTeacher:
                roles.push(...this.getTeacherRoles());
                break;
        }

        if (this.hasProgress()) {
            roles.push(RoleEnum.RoleNavbarProgress);
        }

        if (this.hasTests()) {
            roles.push(RoleEnum.RoleNavbarTests);
        }

        return roles;
    }

    public getName(): string {
        return 'NavbarRolesLogic';
    }

    private getTeacherRoles(): Array<RoleEnum> {
        const roles: Array<RoleEnum> = [];

        if (this.hasGroups() || this.hasTasks()) {
            roles.push(RoleEnum.RoleNavbarTasks);
        }

        return roles;
    }

    private getStudentRoles(): Array<RoleEnum> {
        const roles: Array<RoleEnum> = [];

        if (this.hasTasks()) {
            roles.push(RoleEnum.RoleNavbarTasks);
        }

        return roles;
    }

    private hasGroups(): boolean {
        return this.userData.group_count > 0;
    }

    private hasTests(): boolean {
        return this.userData.test_count > 0;
    }

    private hasTasks(): boolean {
        return this.userData.task_count > 0;
    }

    private hasProgress(): boolean {
        return this.userData.progress_count > 0 || this.userData.task_count > 0;
    }
}
