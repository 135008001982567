import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SharedModule} from 'shared/shared.module';
import {PagesModule} from 'pages/pages.module';
import {NavbarModule} from 'core/components/navbar/navbar.module';
import {ModalsModule} from 'core/components/modals/modals.module';
import {HeaderComponent} from 'core/components/header/header.component';
import {FooterComponent} from 'core/components/footer/footer.component';
import {ApiService} from 'core/services/api/api.service';
import {ModalService} from 'core/services/modal/modal.service';
import {UploadService} from 'core/services/upload/upload.service';
import {UserIdentityModelFactory} from 'core/factories/user-identity-model.factory';
import {LegendModalComponent} from 'core/components/footer/modals/legend-modal/legend-modal.component';
import {SecurityModule} from 'security/security.module';
import {RoutingModule} from 'routing/routing.module';

@NgModule({
    imports: [
        CommonModule,
        ModalsModule,
        NavbarModule,
        SharedModule,
        PagesModule,
        SecurityModule,
        RoutingModule,
    ],
    declarations: [
        HeaderComponent,
        FooterComponent,
        LegendModalComponent,
    ],
    exports: [
        RouterModule,
        NavbarModule,
        HeaderComponent,
        FooterComponent,
    ],
    providers: [
        ModalService,
        ApiService,
        UploadService,
        UserIdentityModelFactory,
    ],
    entryComponents: [
        LegendModalComponent,
    ],
})
export class CoreModule {
}
