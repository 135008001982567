import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DefaultPipe} from 'shared/modules/pipes/components/default.pipe';
import {MomentFormatPipe} from 'shared/modules/pipes/components/moment-format.pipe';
import {MomentDurationPipe} from 'shared/modules/pipes/components/moment-duration.pipe';
import {MomentTimeAgoPipe} from 'shared/modules/pipes/components/moment-time-ago.pipe';
import {SafePipe} from 'shared/modules/pipes/components/save-url.pipe';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        DefaultPipe,
        MomentFormatPipe,
        MomentDurationPipe,
        MomentTimeAgoPipe,
        SafePipe,
    ],
    exports: [
        DefaultPipe,
        MomentFormatPipe,
        MomentDurationPipe,
        MomentTimeAgoPipe,
        SafePipe,
    ],
})
export class PipesModule {
}
