import {AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, ViewChild} from '@angular/core';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {DocumentOriginalContentDirective} from 'document/modules/directives/original-content/original-content.directive';
import {DocumentResourceLinkService} from 'document/services/resource/link.service';
import {DrillsterLinkDataInterface} from 'document/modules/interfaces/drillster-link-data.interface';
import {DocumentService} from 'document/services/document/document.service';
import {DocumentSourceFactory} from 'document/modules/factories/source.factory';
import {DocumentReferenceService} from 'document/services/reference/reference.service';
import {ModalService} from 'core/services/modal/modal.service';
import {ReferenceTypeEnum} from 'document/modules/enums/reference/type.enum';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ModalClassModifiersEnum} from 'core/components/modals/enums/class-modifiers.enum';
import {UserService} from 'security/services/user/user.service';
import {OneDriveService} from 'onedrive/services/onedrive.service';
import {UrlUtil} from 'shared/util/url.util';

@Component({
    selector: '.document-verwijzing, .document-drillster, .document-voorlezer',
    templateUrl: 'reference.component.html',
})
export class DocumentReferenceComponent implements AfterViewInit, OnDestroy {

    public readonly iconBox: IconBoxInterface = {
        type: IconBoxTypeEnum.Icon,
        value: IconEnum.Global,
        classModifiers: [
            IconBoxClassEnum.DocumentReference,
        ],
    };

    @HostBinding('class')
    public elementClass: string = 'document__reference';

    @ViewChild(DocumentOriginalContentDirective, {static: false})
    public originalContent: DocumentOriginalContentDirective;

    @ViewChild('documentReferenceLink', {static: true})
    public documentReferenceLink: ElementRef;

    public expandTitle: boolean = false;

    public subText: string;

    public value: string;

    public id: string;

    private referenceType: ReferenceTypeEnum = ReferenceTypeEnum.Reference;

    private linkDataDrillster: DrillsterLinkDataInterface;

    constructor(
        public element: ElementRef,
        private documentService: DocumentService,
        private linkService: DocumentResourceLinkService,
        private modalService: ModalService,
        private sanitizer: DomSanitizer,
        private referenceService: DocumentReferenceService,
        private referenceFactory: DocumentSourceFactory,
        private userService: UserService,
        private oneDriveService: OneDriveService,
    ) {
        this.id = this.element.nativeElement.id;
        this.setReferenceType((this.element.nativeElement as HTMLElement).classList);
    }

    public ngAfterViewInit(): void {
        this.loadDataset();
    }

    public ngOnDestroy(): void {
        this.documentService.removeResourceById(this.id);
    }

    private setReferenceType(domTokenList: DOMTokenList): void {
        if (domTokenList.contains('document-drillster')) {
            this.referenceType = ReferenceTypeEnum.Drillster;
        } else if (domTokenList.contains('document-voorlezer')) {
            this.referenceType = ReferenceTypeEnum.Reader;
        }
    }

    private loadDataset(): void {
        switch (this.referenceType) {
            case ReferenceTypeEnum.Drillster:
                this.loadForDrillster();
                break;

            case ReferenceTypeEnum.Reader:
                this.loadForReader();
                break;

            default:
                this.loadForLink();
        }
    }

    private loadForLink(): void {
        const paragraph: HTMLParagraphElement = this.retrieveParagraph(this.originalContent.children);
        const title: HTMLHeadingElement = this.retrieveTitle(this.originalContent.children);
        const hyperlink: HTMLLinkElement = this.retrieveHyperlink(paragraph.childNodes);

        if (hyperlink === null) {
            console.error('No hyperlink found!');

            return;
        }

        const dataset = <any>hyperlink.dataset;
        this.value = hyperlink.innerText;

        if (title !== null && title.innerText.length > 0) {
            this.expandTitle = true;
            this.subText = this.value;
            this.value = title.innerText;
        }

        this.handleDataset(dataset, hyperlink);
    }

    private loadForDrillster(): void {
        const dataSet = this.element.nativeElement.dataset;

        if (!dataSet.options) {
            console.error('Content error: dataset has no options for drillster');

            return;
        }

        try {
            this.linkDataDrillster = JSON.parse(dataSet.options);
            this.referenceType = ReferenceTypeEnum.Drillster;
            this.iconBox.value = IconEnum.Drillster;
            this.value = this.linkDataDrillster.title;
        } catch (e) {
            console.error('Failed to JSON parse the options:', e);
        }
    }

    private loadForReader(): void {
        this.referenceType = ReferenceTypeEnum.Reader;
        this.iconBox.value = IconEnum.Reading;
        this.value = 'Voorlezer';
    }

    private retrieveParagraph(nodes: Array<HTMLElement>): HTMLParagraphElement | null {
        for (const node of nodes) {
            if (node.nodeName === 'P') {
                return <HTMLParagraphElement>node;
            }
        }

        return null;
    }

    private retrieveTitle(nodes: Array<HTMLElement>): HTMLHeadingElement | null {
        for (const node of nodes) {
            if (node.nodeName.startsWith('H')) {
                return <HTMLHeadingElement>node;
            }
        }

        return null;
    }

    private retrieveHyperlink(nodes: NodeListOf<ChildNode>): HTMLLinkElement | null {
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes.item(i);

            if (node.nodeName === 'A') {
                return <HTMLLinkElement>node;
            }
        }

        return null;
    }

    public onReferenceClick(event: Event): void {
        switch (this.referenceType) {
            case ReferenceTypeEnum.Reader:
                this.openReader();
                break;

            case ReferenceTypeEnum.Drillster:
                this.openDrillster(event);
                break;

            case ReferenceTypeEnum.Reference:
                this.openDocument(event);
                break;
        }

        return;
    }

    private openReader(): void {
        const dataset = this.linkService.getLinkFromResourceLinkData(this.element.nativeElement.dataset);
        const linkData = this.linkService.getResourceURLFromLinkData(dataset);
        const modalUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(linkData);

        this.modalService.iFrame(modalUrl, 'Voorlezer', IconEnum.Reading, [ModalClassModifiersEnum.Reader]);
    }

    private openDrillster(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        if (!this.linkDataDrillster) {
            return;
        }

        let token = this.documentService.getDocument().drillsterToken;

        if (token) {
            token = atob(token);
        }

        this.modalService.drillster(token, this.linkDataDrillster['drl-code'], this.linkDataDrillster['drl-type']);
    }

    private openDocument(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        const href = this.documentReferenceLink.nativeElement.href;
        const target = this.documentReferenceLink.nativeElement.target;
        const dpsid: string | undefined = UrlUtil.getURLSearchParam(href, 'dpsid');

        if (undefined === dpsid) {
            throw new Error('Dpsid not found in query params');
        }

        this.oneDriveService.openDocument(this.documentService.getBookTitle(), dpsid, href, target);
    }

    private handleDataset(dataset: ApiResourceLinkDataInterface, originalHyperlink: HTMLLinkElement): void {
        if (dataset && dataset.link) {
            this.linkService.setLinkPropertiesByResourceLink(this.documentReferenceLink, dataset);
            this.iconBox.value = this.referenceService.getIconFromDataset(dataset);
        } else {
            this.documentReferenceLink.nativeElement.href = originalHyperlink.href;
            this.documentReferenceLink.nativeElement.title = originalHyperlink.title;
        }

        this.documentReferenceLink.nativeElement.target = originalHyperlink.target || '_blank';

        this.documentService.addResource(this.referenceFactory.fromDocumentReference(this));
    }
}
