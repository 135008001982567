import {HostBinding, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';

export abstract class GridRowClass implements ClassModifierInterface, OnChanges, OnInit {

    public readonly modifiersCollection = 'modifiers';

    private classHelperInstance: ClassHelper;

    @HostBinding('class')
    protected hostClasses: string;

    @Input()
    public classModifiers: Array<string> = [];

    public get classHelper(): ClassHelper {
        if (!(this.classHelperInstance instanceof ClassHelper)) {
            this.classHelperInstance = new ClassHelper(this.getBaseClass());
        }

        return this.classHelperInstance;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.classModifiers) {
            this.classHelper.clear(this.modifiersCollection);
            this.classHelper.addClasses(this.classModifiers, this.modifiersCollection);
        }

        this.setHostClasses();
    }

    public ngOnInit(): void {
        this.setHostClasses();
    }

    protected setHostClasses(): void {
        this.hostClasses = `${this.classHelper.toString()} ${this.classHelper.toString(this.modifiersCollection)}`;
    }

    protected abstract getBaseClass(): string;
}
