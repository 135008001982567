import {FilterDropdownFilterInterface} from 'shared/components/filter-dropdown/interfaces/filter.interface';
import {FilterDropdownFiltersEnum} from 'shared/enums/filter-dropdown/filters.enum';

export class FilterDropdownReadUnreadModel implements FilterDropdownFilterInterface<string> {

    public static readonly STATUS_ALL = 'all';

    public static readonly STATUS_UNREAD = 'unread';

    public static readonly STATUS_READ = 'read';

    public id: FilterDropdownFiltersEnum = FilterDropdownFiltersEnum.ReadUnread;

    public selectedValue: string = FilterDropdownReadUnreadModel.STATUS_ALL;

    public options: Map<string, Map<string, string>> = new Map([
        ['', new Map([
            [FilterDropdownReadUnreadModel.STATUS_ALL, 'Alle'],
            [FilterDropdownReadUnreadModel.STATUS_UNREAD, 'Ongelezen'],
            [FilterDropdownReadUnreadModel.STATUS_READ, 'Gelezen'],
        ])]
    ]);
}
