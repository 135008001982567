import {
    Component, ElementRef,
    Input,
    OnInit, ViewChild,
} from '@angular/core';
import {WindowSizeEnum} from 'shared/components/book-cover/enums/window-size-enum';
import {BookCoverStyleEnum} from 'shared/components/book-cover/enums/book-cover-style.enum';
import {WindowService} from 'services/window/window.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-book-cover',
    templateUrl: './book-cover.component.html',
})
export class BookCoverComponent implements OnInit {

    @ViewChild('bookCoverContainer', { static: true })
    private container: ElementRef;

    @Input()
    public bookCover: string;

    @Input()
    public eBook: boolean = false;

    @Input()
    public bookTitle: string;

    public coverWith: number;

    public coverMarginLeft: number;

    private onScrollSubscription: Subscription;

    private elementOriginalTop: number;

    constructor(
        private elementRef: ElementRef,
        private windowService: WindowService,
    ) {
        window.onresize = () => {
            this.resizeBookCover();
        };
    }

    public ngOnInit(): void {
        this.onScrollSubscription = this.windowService.onScroll.subscribe(() => this.handleScroll());
        this.elementOriginalTop = this.getElementOriginalTop();
        this.resizeBookCover();
    }

    private resizeBookCover(): void {
        if (window.innerWidth >= WindowSizeEnum.Minimal && window.innerWidth < WindowSizeEnum.Maximum) {
            const coverWidth = ((window.innerWidth - WindowSizeEnum.Start) * BookCoverStyleEnum.StepsWidth) + BookCoverStyleEnum.StartWidth;
            const coverMargin = ((window.innerWidth - WindowSizeEnum.Start) * BookCoverStyleEnum.StepsMarginLeft) + BookCoverStyleEnum.StartMarginLeft;
            this.coverWith = Number(coverWidth.toFixed(2));
            this.coverMarginLeft = -+Number(coverMargin.toFixed(2));
        } else {
            this.coverWith = BookCoverStyleEnum.DefaultCoverWidth;
            this.coverMarginLeft = BookCoverStyleEnum.DefaultMarginLeft;
        }
    }

    private getElementOriginalTop(): number {
        const parentElementTop: number = this.getNativeElement().parentElement.getBoundingClientRect().top;

        return this.getScrollTopDistance() + parentElementTop;
    }

    private getNativeElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    private getScrollTopDistance(): number {
        const documentElement: HTMLElement = document.documentElement;

        return (window.pageYOffset || documentElement.scrollTop)  - (documentElement.clientTop || 0);
    }

    private handleScroll(): void {
        const scrollOffset: number = this.getScrollTopDistance() - this.elementOriginalTop;

        if (scrollOffset > 0) {
            this.container.nativeElement.style.top = `${scrollOffset}px`;
        } else {
            this.container.nativeElement.style.top = null;
        }
    }
}
