import {ContentSelectModel} from 'shared/modules/content-select/models/content-select.model';

export class ContentSwitchStudentModel extends ContentSelectModel {

    constructor(id: string, title: string) {
        super(id, title, false, []);
    }

    public static fromApiStudent(apiStudent: ApiUserInterface): ContentSwitchStudentModel {
        return new ContentSwitchStudentModel(apiStudent.id.toString(), apiStudent.name);
    }
}
