import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from 'core/services/modal/modal.service';
import {IconEnum} from 'enums/icon.enum';
import {UserService} from 'security/services/user/user.service';
import {Subscription} from 'rxjs';
import {DocumentService} from 'document/services/document/document.service';
import {RoleEnum} from 'enums/role.enum';

@Component({
    selector: 'app-practice-supervisor',
    templateUrl: 'practice-supervisor.component.html',
})
export class DocumentPagePracticeSupervisorComponent implements OnInit, OnDestroy {

    public readonly roleEnum = RoleEnum;

    public readonly prefixIcon = IconEnum.Users;

    @Input()
    public bookId: number;

    @Input()
    public bookTitle: string;

    public userInternships: Array<ApiInternshipInterface> = [];

    public abilityToInvite: boolean = false;

    public isBpvDocument: boolean = false;

    private userChangeSubscription: Subscription;

    private documentChangeSubscription: Subscription;

    constructor(
        private modalService: ModalService,
        private userService: UserService,
        private documentService: DocumentService,
    ) {
    }

    public ngOnInit(): void {
        this.setUserInternships();

        this.userChangeSubscription = this.userService.onUserDataChange.subscribe(() => this.setUserInternships());
        this.documentChangeSubscription = this.documentService.documentChanged.subscribe(() => this.setUserInternships());
    }

    public ngOnDestroy(): void {
        if (this.userChangeSubscription instanceof Subscription) {
            this.userChangeSubscription.unsubscribe();
        }

        if (this.documentChangeSubscription instanceof Subscription) {
            this.documentChangeSubscription.unsubscribe();
        }
    }

    private setUserInternships(): void {
        this.userInternships = [];

        const document = this.documentService.getDocument();

        this.isBpvDocument = document && document.assignments.length > 0 && document.assignments[0].is_bpv;
        this.abilityToInvite = document && document.assignments.length > 0 && document.assignments[0].tasks.length > 0;

        const user = this.userService.getUserData();

        if (user.internships.length === 0) {
            return;
        }

        for (const internship of user.internships) {
            if (internship.book && internship.book.id === this.bookId) {
                this.userInternships.push(internship);
            }
        }
    }

    public handleAddPracticeSupervisor(): void {
        const modalInstance = this.modalService.practiceSupervisorAdd(this.bookId, this.bookTitle);

        modalInstance.closeEvent.subscribe((ship: ApiInternshipInterface | null) => {
            this.handlePracticeSupervisorSave(ship);
        });
    }

    public handleEditPracticeSupervisor(internship: ApiInternshipInterface): void {
        const modalInstance = this.modalService.practiceSupervisorEdit(this.bookId, this.bookTitle, internship);

        modalInstance.closeEvent.subscribe((ship: ApiInternshipInterface | null) => {
            this.handlePracticeSupervisorSave(ship, internship);
        });
    }

    private handlePracticeSupervisorSave(internship: ApiInternshipInterface | null, oldInternship: ApiInternshipInterface = null): void {
        const user = this.userService.getUserData();

        if (oldInternship === null && internship !== null) {
            user.internships.push(internship);
        }

        if (oldInternship !== null) {
            let index = -1;

            for (let i = 0; i < user.internships.length; i++) {
                if (user.internships[i].id === oldInternship.id) {
                    index = i;
                }
            }

            if (internship !== null) {
                user.internships[index] = internship;
            } else {
                user.internships.splice(index, 1);
            }
        }

        this.userService.reload();
    }
}
