import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {FeedbackService} from 'services/feedback/feedback.service';
import {ToastrService} from 'ngx-toastr';
import {finalize} from 'rxjs/operators';
import {AvatarClassesEnum} from 'shared/components/avatar/enums/avatar-classes.enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {UserService} from 'security/services/user/user.service';
import {Subscription} from 'rxjs';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {RedactorService} from 'services/redactor/redactor.service';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {HorizontalBarTextClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-text-classes.enum';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-tasks-feedback',
    templateUrl: 'feedback.component.html',
})
export class FeedbackComponent implements OnInit, OnDestroy {

    public readonly avatarClassesEnum = AvatarClassesEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly horizontalBarTextClasses = HorizontalBarTextClassesEnum;

    public readonly gridTableClasses = GridTableClassEnum;

    public readonly gridTableColClasses = GridTableColClassEnum;

    public readonly  sendFeedbackIconBox: IconBoxInterface = {
        value: IconEnum.Send,
        type: IconBoxTypeEnum.Icon,
        classModifiers: [
            IconBoxClassEnum.ColorOrange,
        ],
    };

    @ViewChild('feedbackTextArea', { static: true })
    protected feedbackTextArea: ElementRef;

    @Input()
    public task: ApiTaskDetailsInterface;

    @Input()
    public homework: ApiTaskDetailsHomeworkInterface;

    public archiveModeActive = environment.archiveModeActive;

    public loading: boolean = false;

    public userId: number;

    private userChangeSubscription: Subscription;

    private redactor: RedactorAppInstance;

    constructor(
        private feedbackService: FeedbackService,
        private toastService: ToastrService,
        private userService: UserService,
        private redactorService: RedactorService,
    ) {
    }

    public ngOnInit(): void {
        this.userId = this.userService.getCurrentUserId();

        this.userChangeSubscription = this.userService.onUserDataChange.subscribe(() => {
            this.userId = this.userService.getCurrentUserId();
        });

        this.redactorService.create(this.feedbackTextArea.nativeElement)
            .subscribe((redactor: RedactorAppInstance) => {
                this.redactor = redactor;
            });
    }

    public ngOnDestroy(): void {
        if (this.userChangeSubscription) {
            this.userChangeSubscription.unsubscribe();
        }
    }

    public handleSendFeedback(): void {
        this.loading = true;

        this.feedbackService.sendFeedback(this.homework.id, this.redactor.source.getCode())
            .pipe(finalize(() => this.loading = false))
            .subscribe((feedback: ApiFeedbackInterface) => {
                this.toastService.success('Feedback is verzonden.');

                this.redactor.source.setCode('');
                this.homework.feedback.push(feedback);
            }, () => {
                this.toastService.error('Kon feedback niet verzenden.');
            });
    }
}
