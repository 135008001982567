import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TabItemInterface} from 'shared/modules/tab/interfaces/tab-item.interface';
import {TabItemClassEnum} from 'shared/modules/tab/enums/tab-item.class-enum';

@Component({
    selector: 'app-tab-item',
    templateUrl: 'tab-item.component.html'
})
export class TabItemComponent {

    @Input()
    public tabItem: TabItemInterface;

    @Output()
    public tabItemClick: EventEmitter<TabItemInterface> = new EventEmitter();

    public activeClass: TabItemClassEnum = TabItemClassEnum.Active;

    public onClick(): void {
        this.tabItemClick.emit(this.tabItem);
    }
}
