import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {SharedModule} from 'shared/shared.module';
import {CommonModule} from '@angular/common';
import {NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import {GroupsAddGroupComponent} from 'pages/modules/groups/components/add-group/add-group.component';
import {GroupsGroupHeaderComponent} from 'pages/modules/groups/components/group-header/group-header.component';
import {GroupsPersonComponent} from 'pages/modules/groups/components/person/person.component';
import {GroupsGroupComponent} from 'pages/modules/groups/components/group/group.component';
import {GroupsGroupColumnHeaderComponent} from 'pages/modules/groups/components/group-column-header/group-column-header.component';
import {GroupsComponent} from 'pages/modules/groups/components/groups.component';
import {GroupsModuleGroupModelFactory} from 'pages/modules/groups/factories/group-model.factory';
import {GroupsModuleGroupModelPersonFactory} from 'pages/modules/groups/factories/group-model-person.factory';
import {GroupsAddGroupModalComponent} from 'pages/modules/groups/components/modals/add-group/add-group.component';
import {GroupsJoinGroupModalComponent} from 'pages/modules/groups/components/modals/join-group/join-group.component';
import {GroupsEditGroupModalComponent} from 'pages/modules/groups/components/modals/edit-group/edit-group.component';
import {GroupsActionsModalComponent} from 'pages/modules/groups/components/modals/actions/action.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {GroupsGroupNameComponent} from 'pages/modules/groups/components/group-name/group-name.component';
import {SecurityModule} from 'security/security.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        NgbModalModule,
        FormsModule,
        InfiniteScrollModule,
        SecurityModule,
    ],
    declarations: [
        GroupsAddGroupComponent,
        GroupsAddGroupModalComponent,
        GroupsJoinGroupModalComponent,
        GroupsEditGroupModalComponent,
        GroupsActionsModalComponent,
        GroupsGroupHeaderComponent,
        GroupsGroupColumnHeaderComponent,
        GroupsGroupComponent,
        GroupsPersonComponent,
        GroupsComponent,
        GroupsGroupNameComponent,
    ],
    exports: [
        GroupsComponent,
    ],
    providers: [
        GroupsModuleGroupModelFactory,
        GroupsModuleGroupModelPersonFactory,
    ],
    entryComponents: [
        GroupsAddGroupModalComponent,
        GroupsJoinGroupModalComponent,
        GroupsEditGroupModalComponent,
        GroupsActionsModalComponent,
    ],
})
export class GroupsModule {
}
