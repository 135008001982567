import {Injectable} from '@angular/core';

@Injectable()
export class DomParentsHelper {

    public findFirstParentWithClass(element: HTMLElement, className: string): HTMLElement | null {
        const parents: Array<HTMLElement> = this.findParentsWithClass(element, className, className);

        return parents.length === 1 ? parents[0] : null;
    }

    public findParentsWithClass(element: HTMLElement, className: string, untilClassName: string = null): Array<HTMLElement> {
        const parents: Array<HTMLElement> = [];
        let parentElement: HTMLElement = element.parentElement;

        while (parentElement) {
            const hasTargetClass = parentElement.classList.contains(className);
            const hasUntilClass = parentElement.classList.contains(untilClassName);

            if (hasTargetClass) {
                parents.push(parentElement);
            }

            parentElement = hasUntilClass ? null : parentElement.parentElement;
        }

        return parents;
    }

}
