export enum ExamineOptionsEnum {
    Teacher = 1,

    Student = 2,

    Differs = 3,

    None = 0,

    Auto = 4
}
