export enum  WorkTypeClassesEnum {

    InGridTableCol = 'work-type-label--in-grid-table-col',

    InAssignmentNumberGroup = 'work-type-label--in-assignment-number-group',

    InAssignmentNumberSelfStudy = 'work-type-label--in-assignment-number-self-study',

    InDataTableCell = 'work-type-label--in-data-table-cell',

    InLegend = 'work-type-label--in-legend',

}
