import {AfterViewInit, Component, ElementRef, HostBinding, HostListener, OnDestroy, QueryList, ViewChildren} from '@angular/core';
import {DocumentAnswerableComponent} from 'document/modules/abstract/answerable.component';
import {ClassHelper} from 'helpers/dom/class.helper';
import {DocumentMultiMatchingClassEnum} from './enums/class.enum';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {DocumentMultiMatchingOptionsInterface} from 'document/modules/components/multi-matching/interface/options.interface';
import {DocumentMultiMatchingChoiceInterface} from 'document/modules/components/multi-matching/interface/choice.interface';
import {DocumentService} from 'document/services/document/document.service';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {AutoCheckService} from 'document/services/autocheck/auto-check.service';

// KOPPELVRAGEN (MATRIX)

@Component({
  selector: '.document-multiplematch',
  templateUrl: './multi-matching.component.html',
  styleUrls: ['./multi-matching.component.scss']
})
export class DocumentMultiMatchingComponent extends DocumentAnswerableComponent implements AfterViewInit, OnDestroy {

    @HostBinding('class')
    public elementClass: string = 'document__multi-matching';

    @ViewChildren('givenanswersbody')
    private givenAnswersContainers: QueryList<ElementRef>;

    @ViewChildren('correctanswersbody')
    private correctAnswersContainers: QueryList<ElementRef>;


    public classes: ClassHelper = new ClassHelper(DocumentMultiMatchingClassEnum.Matching);

    public id: string;

    public hasTitles: boolean = false;

    public answers: any = {};

    public correctAnswers: any;

    public initialAnswers: any;

    public matches: Array<any>;

    public options: DocumentMultiMatchingOptionsInterface;

    public choices: Array<DocumentMultiMatchingChoiceInterface>;

    public mode: string;

    protected dataSet: DocumentDatasetOptionsInterface;

    public answerHidden: boolean;

    public autoScoreEnabled: boolean;

    public autoScoreAnswer: any;

    public allCorrectAnswers: Record<string, boolean> = {};

    private subscriptions: Array<Subscription> = [];

    constructor(
        protected elementRef: ElementRef,
        protected answersService: DocumentAnswersService,
        protected showAnswersService: DocumentAnswersStateService,
        protected document: DocumentService,
        private autocheckService: AutoCheckService
    ) {
        super(elementRef, answersService, showAnswersService);

        this.id = `matching-${this.dataSet.assignmentid}-${this.dataSet.fieldid}`;

        try {

            this.options = JSON.parse(this.dataSet.options);
            this.choices = this.options.sources;
            this.matches = this.options.targets;
            this.mode = this.options.mode;
            this.initializationFailed = false;

            this.matches.forEach( match => {
                this.answers[match.id] = [];
            });

            this.setHasTitles();

        } catch (e) {
            this.initializationFailed = true;
            console.error('Failed to JSON parse the options:', e);
        }
    }

    public handleDrop(event: CdkDragDrop<any>): void {

        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }

        this.saveAnswer(JSON.stringify(this.answers));
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.setContainerHeights();
        this.setupAutoScoring();
    }

    @HostListener('window:resize')
    private onWindowResize(): void {}

    private setHasTitles(): void {
        this.hasTitles =
            (this.options.choiceslabel && this.options.choiceslabel.length > 0)
            || (this.options.matcheslabel && this.options.matcheslabel.length > 0);

        if (this.hasTitles) {
            this.classes.addClass(DocumentMultiMatchingClassEnum.MatchingHasTitles);
        }
    }

    protected setAnswer(answer: string): void {
        this.answers = this.getDecodedAnswer(answer);
        const selectedChoices = [].concat.apply([], Object.values(this.answers)).map( (choice: any) => choice.id);

        // Filter the all choices list to exclude the already given answers
        this.choices = this.choices.filter( choice => {
            return !selectedChoices.includes(choice.id);
        });
    }

    private setupAutoScoring(): void {

        const assignmentId = this.dataSet.assignmentid;
        const fieldId = parseInt(this.dataSet.fieldid, 10);
        const assignment = this.answersService.getAssignment(assignmentId);
        this.autoScoreEnabled = assignment && assignment.auto_check;

        if (! this.autoScoreEnabled) { return; }

        this.subscriptions.push( this.autocheckService.getAutoCheckAnswerForFieldId(assignmentId, fieldId).pipe(take(1)).subscribe( autoCheckAnswer => {

            if (autoCheckAnswer) {
                try {
                    const lookupAnswers = {};
                    // TODO: Unfortunately, the .replace() here should not be needed, however the backend returns the correct answers double encoded.
                    const correctAnswers = JSON.parse(autoCheckAnswer.replace(/\\/g, '')) as Array<{source: string, target: string}> ;

                    correctAnswers.forEach( answer => {
                        if (!lookupAnswers[answer.target]) {
                            lookupAnswers[answer.target] = {};
                            lookupAnswers[answer.target].answers = {};
                        }
                        lookupAnswers[answer.target].answers[answer.source] = true;
                        this.allCorrectAnswers[answer.source] = true;
                    });
                    this.autoScoreAnswer = lookupAnswers;

                } catch (error) {
                    console.warn('Could not parse Autocheck answers');
                }
            }
        }));
    }

    protected hideCorrectAnswer(): void {
        this.classes.removeClass(DocumentMultiMatchingClassEnum.MatchingShowCorrectAnswer);
        this.answerHidden = true;
    }

    protected showCorrectAnswer(correctAnswer: string): void {
        this.correctAnswers = this.setupAnswers(correctAnswer);
        this.classes.addClass(DocumentMultiMatchingClassEnum.MatchingShowCorrectAnswer);
        this.answerHidden = false;

    }

    private setContainerHeights(): void {
        // TODO BD-1148: rewrite this code, timeout is not allowed.
        // We need to wait until the images are loaded before we can determine container heights. Probably not always 100% accurate, but this feature is a bonus anyway.
        setTimeout(() => {
            const correctAnswerContainers = this.correctAnswersContainers.toArray();
            this.givenAnswersContainers.forEach( (container, index) => {
                const currentGivenHeight = container.nativeElement.clientHeight;
                const currentCorrectHeight = correctAnswerContainers[index].nativeElement.clientHeight;
                const correction = 2; // (index === 0) ? 2 : 0;

                if (currentGivenHeight > currentCorrectHeight) {
                    correctAnswerContainers[index].nativeElement.style.minHeight = currentGivenHeight + correction + 'px';
                } else {
                    container.nativeElement.style.minHeight = currentCorrectHeight + correction + 'px';
                }

            });
        }, 3000);
    }

    public setupAnswers(correctAnswer: string): any {
        const options = JSON.parse(this.dataSet.options);
        const correctedAnswers = {};

        try {
            const answers = JSON.parse(correctAnswer.replace(/\\/g, '')) as Array<{source: string, target: string}>;
            answers.forEach( answer => {
               if (!correctedAnswers[answer.target]) {
                   correctedAnswers[answer.target] = [];
               }

               const choice = options.sources.find( existingChoice => {
                   if ( existingChoice.id === answer.source ) {
                       return existingChoice.label;
                   }
               });

               if (choice) {
                   correctedAnswers[answer.target].push(choice); // This needs to be the label of the choices
               }
            });
            this.setContainerHeights();

            return correctedAnswers;
        } catch (error) {
            console.warn('INVALID ANSWER JSON');
        }

    }

    protected answerValid(): boolean {
        return true;
    }

    protected showInitialAnswer(initialAnswer: string): void {
        this.initialAnswers = JSON.parse(initialAnswer);
    }

    protected hideInitialAnswer(): void {}

    protected disableAnswers(): void {
        this.classes.addClass(DocumentMultiMatchingClassEnum.MatchingDisabled);
    }

    protected enableAnswers(): void {
        this.classes.removeClass(DocumentMultiMatchingClassEnum.MatchingDisabled);
    }

    public ngOnDestroy(): void {
        this.autocheckService.resetAutoCheckAnswer();
        this.subscriptions.map(sub => sub.unsubscribe());
    }

}
