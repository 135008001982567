import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {CollapsibleComponent} from 'shared/components/collapsible/collapsible.component';
import * as moment from 'moment';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {CollapsibleClassEnum} from 'shared/components/collapsible/enums/class.enum';
import {TaskService} from 'services/task/task.service';

@Component({
    selector: 'app-tasks-week',
    templateUrl: 'week.component.html',
})
export class WeekComponent implements AfterViewInit {

    public readonly collapsibleClassEnum = CollapsibleClassEnum;

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly iconEnum = IconEnum;

    @ViewChild(CollapsibleComponent, {static: true})
    public collapsible: CollapsibleComponent;

    @Input()
    public week: ApiTaskWeekInterface;

    @Input()
    public studentSelected: ApiUserInterface;

    @Input()
    public initiallyExpanded: boolean = false;

    @Output()
    public loadTasks: EventEmitter<string> = new EventEmitter();

    private initialExpandOver: boolean = false;

    constructor(private taskService: TaskService) {
    }

    public ngAfterViewInit(): void {
        const selectedWeek = this.taskService.retrieveSelectedWeek();
        if (selectedWeek && this.week && this.week.week === Number(selectedWeek.replace('week-', ''))) {
            setTimeout(() => {
                const el = document.getElementById(selectedWeek);
                el.scrollIntoView();

                if (!this.collapsible.initiallyExpanded) {
                    this.collapsible.handleExpand();
                }

                this.taskService.clearSelectedWeek();
            }, 100);
        }
    }

    public getEndDate(): moment.Moment {
        const week = moment(this.week.date);

        return week.clone().add(6, 'd');
    }

    public onExpand(): void {
        if (this.initiallyExpanded && this.initialExpandOver === false) {
            this.initialExpandOver = true;

            return;
        }

        this.loadTasks.emit(this.week.id);
    }

    public handleCollapse(event?: MouseEvent): void {
        event.stopPropagation();

        this.collapsible.handleExpand();
    }
}
