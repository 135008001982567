import {Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import Timer = NodeJS.Timer;

@Directive({
    selector: '[appContentEditable]',
})
export class ContentEditableDirective implements OnChanges {

    @Input()
    public contentEditableTimeOut: number = 2;

    @Input('appContentEditable')
    public text: string;

    // tslint:disable-next-line:no-output-rename
    @Output('appContentEditableChange')
    public textChange: EventEmitter<string> = new EventEmitter();

    private lastText: string;

    private inputTimeout: Timer = null;

    constructor(
        private elementRef: ElementRef,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.text && changes.text.currentValue !== changes.text.previousValue && changes.text.currentValue !== this.lastText) {
            this.lastText = this.text;

            this.updateView(this.text);
        }
    }

    @HostListener('blur')
    private onBlur(): void {
        this.inputUpdated();
    }

    @HostListener('input')
    private onInput(): void {
        this.clearTimeout();

        this.inputTimeout = setTimeout(() => this.inputUpdated(), this.contentEditableTimeOut * 1000);
    }

    @HostListener('keypress', ['$event'])
    private onKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    private inputUpdated(): void {
        const value = this.elementRef.nativeElement.textContent;

        if (this.lastText === value) {
            this.clearTimeout();

            return;
        }

        this.lastText = value;
        this.textChange.emit(value);

        this.clearTimeout();
    }

    private updateView(text: string): void {
        this.elementRef.nativeElement.textContent = text;

        this.clearTimeout();
    }

    private clearTimeout(): void {
        if (this.inputTimeout !== null) {
            clearTimeout(this.inputTimeout);

            this.inputTimeout = null;
        }
    }
}
