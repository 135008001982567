export enum GridTableColClassEnum {

    Base = 'grid-table__col',

    TasksFixedWidth = 'grid-table__col--tasks-fixed-width',

    ExamInformationWidth = 'grid-table__col--exam-information-fixed-width',

    TasksDescription = 'grid-table__col--tasks-description',

    TasksDocumentPadding = 'grid-table__col--task-document-padding',

    ContentSpaceBetween = 'grid-table__col--content-space-between',

    ProgressHandedInWidth = 'grid-table__col--progress-handed-in-width',

    ProgressLogoWidth = 'grid-table__col--progress-collapsible-logo-width',

    ProgressGroupName = 'grid-table__col--progress-group-name',

    ProgressStudentName = 'grid-table__col--progress-student-name',

    ProgressAverage = 'grid-table__col--progress-average',

    ProgressResultsTaskDetails = 'grid-table__col--progress-results_task-details',

    ProgressResults = 'grid-table__col--progress-results',

    ProgressResultCol = 'grid-table__col--progress-result-col',

    ProgressStudentScroll = 'grid-table__col--progress-students-scroll',

    Attachments = 'grid-table__col--attachments',

    Padding = 'grid-table__col--padding',

    PaddingNone = 'grid-table__col--no-padding',

    BorderNone = 'grid-table__col--border-none',

    BorderBottomNone = 'grid-table__col--border-bottom-none',

    BorderRightNone = 'grid-table__col--border-right-none',

    BorderTop = 'grid-table__col--border-top',

    BorderLeft = 'grid-table__col--border-left',

    BorderRed = 'grid-table__col--border-24boost-red',

    BackgroundGrey = 'grid-table__col--background-24boost-grey',

    BackgroundGreyLighter = 'grid-table__col--background-24boost-grey-lighter',

    BackgroundWhite = 'grid-table__col--background-24boost-white',

    ContentEnd = 'grid-table__col--content-end',

    Square = 'grid-table__col--square',

    Preloader = 'grid-table__col--preloader',

    DirectionRow = 'grid-table__col--direction-row',

    CenterItems = 'grid-table__col--center-items',

    ContentCenter = 'grid-table__col--content-center',

    TextEllipsis = 'grid-table__col--text-ellipsis',

    TextColorPurple = 'grid-table__col--text-24boost-purple',

    TextColorOrange = 'grid-table__col--text-24boost-orange',

    StudentDetailMoreAssignments = 'grid-table__col--student-detail-more-assigment',

    LargeAndUp = 'grid-table__col--large-and-up',

    MediumAndUp = 'grid-table__col--medium-and-up',

    RatingScoreFixedWidth = 'grid-table__col--rating-score-fixed-width',

    Clickable = 'grid-table__col--clickable',

    ItemsFlexEnd = 'grid-table__col--items-flex-end',

    Trophy = 'grid-table__col--trophy',

    Floatable = 'grid-table__col--floatable',

    Floating = 'grid-table__col--floating',

    ScrollColColumn = 'grid-table__col--scroll-col-column',

    Icon = 'grid-table__col--icon',

    taskActive = 'grid-table__col--task-active',

}
