import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {TocViewBookItemInterface} from 'shared/modules/toc/interfaces/toc-view-book-item.interface';
import {TaskStatusEnum} from 'enums/task-status.enum';
import {AssignmentService} from 'services/assignment/assignment.service';
import {DocumentAssignmentStatusEnum} from 'pages/modules/document/enums/document-assignment-status.enum';
import {DocumentSelfScoreScoresEnum} from 'pages/modules/document/enums/self-scores.enum';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {ToastrService} from 'ngx-toastr';
import {RoleEnum} from 'enums/role.enum';
import {InviteStateEnum} from 'enums/invite-state.enum';
import {AnalyticsDimensionEnum} from 'services/analytics/enums/dimension.enum';
import {AnalyticsService} from 'services/analytics/analytics.service';
import {AnalyticsEventAssignmentHandInModel} from 'services/analytics/models/assignment-hand-in.model';
import {ModalService} from 'core/services/modal/modal.service';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {AutoCheckService} from 'document/services/autocheck/auto-check.service';
import {ApiService} from 'core/services/api/api.service';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-document-page-content',
    templateUrl: 'content.component.html',
})
export class DocumentPageContentComponent implements OnChanges {

    public readonly roleEnum = RoleEnum;

    public readonly statusEnum = DocumentAssignmentStatusEnum;

    @Input()
    public bookId: number;

    @Input()
    public bookTitle: string;

    @Input()
    public document: ApiDocumentDataInterface;

    @Input()
    public toc: Array<TocViewBookItemInterface>;

    @Output()
    public loadingChange: EventEmitter<boolean> = new EventEmitter();

    @Input()
    public selfStudy: boolean = false;

    @Input()
    public autoCorrect: boolean = false;

    @Input()
    public assignment: ApiDocumentAssignmentInterface = null;

    public score: DocumentSelfScoreScoresEnum;

    public showAttachmentsTypes: boolean = true;

    public status: DocumentAssignmentStatusEnum = DocumentAssignmentStatusEnum.None;

    public attachments: AttachmentDataInterface;

    public InviteCompletedOrRevoked: boolean = false;

    public constructor(
        private assignmentService: AssignmentService,
        private toastService: ToastrService,
        private answerStateService: DocumentAnswersStateService,
        private autocheckService: AutoCheckService,
        private analyticsService: AnalyticsService,
        private modalService: ModalService,
        private authorizationService: AuthorizationService,
        private apiService: ApiService
    ) {
        this.resetAttachments();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.document && changes.document.previousValue !== changes.document.currentValue) {
            this.handleDocumentChange(this.document);
        }

        if (changes.selfStudy || changes.assignment || changes.toc) {
            this.setStatus();
        }
    }

    private handleDocumentChange(document: ApiDocumentDataInterface): void {
        this.document = document;
        this.resetAttachments();

        this.setStatus();
    }

    private resetAttachments(): void {
        this.attachments = {
            files: this.assignment ? this.assignment.files : [],
            urls: this.assignment ? this.assignment.urls : [],
            description: this.assignment ? this.assignment.description : ''
        };
    }

    private setStatus(): void {
        this.answerStateService.reset();

        this.showAttachmentsTypes = this.status !== (this.statusEnum.None && this.statusEnum.Done);

        if (!this.assignment || this.assignment.tasks.length === 0) {
            this.status = DocumentAssignmentStatusEnum.None;

            return;
        }

        const task = this.assignment.tasks[this.assignment.tasks.length - 1];

        if (this.assignment.is_bpv) {
            this.showAttachmentsTypes = false;

            if (task.internshipinvites.length === 0) {
                this.status = DocumentAssignmentStatusEnum.BpvPreparation;
                this.showAttachmentsTypes = true;

                return;
            }

            const invite = task.internshipinvites[task.internshipinvites.length - 1];

            this.showAttachmentsTypes = invite.status === InviteStateEnum.Completed;

            this.InviteCompletedOrRevoked = false;

            if (invite.status === InviteStateEnum.Completed) {
                this.InviteCompletedOrRevoked = true;

                if (this.authorizationService.isGranted(RoleEnum.RoleReviewer)) {
                    this.modalService.reviewCompleted();
                }
            }

            if (invite.status === InviteStateEnum.Revoked) {
                this.InviteCompletedOrRevoked = true;

                if (this.authorizationService.isGranted(RoleEnum.RoleReviewer)) {
                    this.modalService.reviewRevoked();
                }
            }

            if (invite.status !== InviteStateEnum.Completed) {
                this.status = DocumentAssignmentStatusEnum.None;
                this.showAttachmentsTypes = true;

                return;
            }

            if (task.status === TaskStatusEnum.Closed) {
                this.status = DocumentAssignmentStatusEnum.Done;

                return;
            }

            this.status = DocumentAssignmentStatusEnum.Open;

            return;
        }

        if (task.status === TaskStatusEnum.Closed) {
            this.status = DocumentAssignmentStatusEnum.Done;
            this.answerStateService.disableAnswers();
        } else if (this.selfStudy && task.selfstudy_answers && task.selfstudy_answers.length > 0) {
            this.status = DocumentAssignmentStatusEnum.SelfScoring;
            this.answerStateService.showAnswers();
        } else if (this.selfStudy) {
            this.status = DocumentAssignmentStatusEnum.SelfScoreOpen;
        } else {
            this.status = DocumentAssignmentStatusEnum.Open;
        }
    }

    private findOpenTasks(assignment?: ApiDocumentAssignmentInterface): Array<ApiTaskDetailsInterface> {
        if (assignment === null) {
            return [];
        }

        return assignment.tasks.filter((task: ApiTaskDetailsInterface) => task.status === TaskStatusEnum.Open);
    }

    public handleScoreChange(score: DocumentSelfScoreScoresEnum): void {
        if (score !== DocumentSelfScoreScoresEnum.None) {
            this.status = DocumentAssignmentStatusEnum.SelfScoringDone;
        } else {
            this.status = DocumentAssignmentStatusEnum.SelfScoring;
        }
    }

    public handleStatusChange(status: DocumentAssignmentStatusEnum): void {
        if (status === DocumentAssignmentStatusEnum.SelfScoring) {
            const task = this.assignment.tasks[this.assignment.tasks.length - 1];

            task.selfstudy_answers.push(<any>{});

            this.setStatus();
        }
    }

    public onSubmit(): void {

        if (this.assignment === null) {
            return;
        }

        if (this.assignment.fields.length === 0 && this.attachments.files.length === 0 && this.attachments.urls.length === 0) {
            this.toastService.warning('Upload een bestand of geef een url op.');

            return;
        }

        const tasks = this.findOpenTasks(this.assignment);
        if (tasks.length === 0) {
            this.toastService.warning('Geen openstaande taken gevonden.');

            return;
        }

        const title = tasks[0].title;
        const files = [];

        for (const file of this.attachments.files) {
            files.push(file.id);
        }

        const params: AssignmentHandinInterface = {
            book: this.bookId,
            title: title,
            description: this.attachments.description,
            files: files,
            urls: this.attachments.urls,
            selfstudy_examination: this.score
        };

        this.analyticsService.dimension(AnalyticsDimensionEnum.AssignmentTitle, title);
        this.analyticsService.event(new AnalyticsEventAssignmentHandInModel());

        this.loadingChange.emit(true);

        setTimeout(() => {
            this.assignmentService.handIn(this.assignment.version_id, params)
                .subscribe((response) => {

                    let autocheck = false;

                    if (response.assignment && response.assignment.auto_check ) {
                        // Setting (possible) correct answers from autoCheck handedIn response
                        this.autocheckService.setAutoCheckAnswers(response.assignment);

                        // Check if we have an examination result and pass it to the service
                        if (response.examination_result && response.examined) {
                            this.autocheckService.setAutoCheckResult(response.examination_result);
                        }

                        autocheck = true;
                    }

                    this.loadingChange.emit(false);

                    if (!autocheck) {
                        this.toastService.success('Taak is ingeleverd.');
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        });
                    } else {
                        this.toastService.success('Taak is automatisch nagekeken en ingeleverd.');
                    }

                    for (const task of tasks) {
                        task.status = TaskStatusEnum.Closed;
                    }

                    this.setStatus();
                }, () => {
                    this.loadingChange.emit(false);
                    this.toastService.error('Taak kon niet worden ingeleverd. Controleer je antwoord(en).');
                });
        }, 1000);
    }

    public attachmentsChanged(data: AttachmentDataInterface): Subscription {

        const body = {
            assignment_id: this.assignment.version_id,
            attachments: data,
        };

        return this.apiService.post<null>(ApiEndpointEnum.SaveAttachments, body).subscribe(() => {
            // Just trigger the post...
        });
    }

}
