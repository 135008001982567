import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {GridColumnClass} from 'shared/classes/grid/grid-column.class';
import {PageColClassEnum} from 'shared/modules/page/enums/page-col/class.enum';

@Component({
    selector: 'app-page-col',
    templateUrl: 'page-col.component.html',
})
export class PageColComponent extends GridColumnClass implements OnInit {

    public static CLASS: string = 'col';

    @HostBinding('class')
    public hostClasses: string;

    @Input()
    public set marginBottom(value: boolean | string) {
        this.classHelper.toggleClassByBoolean(PageColClassEnum.MarginBottom, value.toString() === 'true');
    }

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classModifiers);
        this.hostClasses = this.classHelper.toString();

        super.ngOnInit();
    }

    protected getBaseClass(): string {
        return PageColComponent.CLASS;
    }

    /**
     * Override because bootstrap doesn't use BEM, changes infix to single dash
     */
    protected addColumnSizeClass(breakpoint: string, value: number | string, infix: string = '-'): void {
        return super.addColumnSizeClass(breakpoint, value, infix);
    }

    /**
     * Override because bootstrap doesn't use BEM, changes infix to single dash
     */
    protected addColumnOrderClass(breakpoint: string, value: number | string, infix: string = '-order'): void {
        return super.addColumnSizeClass(breakpoint, value, infix);
    }
}
