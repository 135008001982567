import {ContentSelectItemInterface} from 'shared/modules/content-select/interfaces/select-item.interface';

export class ContentSelectModel implements ContentSelectItemInterface {

    public parentId: string;

    public classModifiers: Array<string> = [];

    constructor(
        public id: string,
        public title: string,
        public active: boolean = false,
        public children: Array<ContentSelectModel> = [],
        public demo: boolean = false,
        public licenseRequested: boolean = false
    ) {
    }

    public setDemo(demo: boolean): void {
        this.demo = demo;
    }

    public setLicenseRequested(licenseRequested: boolean): void {
        this.licenseRequested = licenseRequested;
    }

    public setChildren(children: Array<ContentSelectModel>): void {
        this.children = children;
    }
}
