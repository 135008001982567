import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';
import {IconBoxBookModel} from 'shared/models/icon-box/book.model';
import {BookService} from 'services/book/book.service';
import {ContentSelectModelFactory} from 'shared/modules/content-select/factories/model.factory';
import {ContentSelectItemInterface} from 'shared/modules/content-select/interfaces/select-item.interface';
import {LocalStorageService} from 'angular-2-local-storage';
import {LocalStorageKeysEnum} from 'enums/local-storage-keys.enum';

@Component({
    selector: 'app-book-chapter-switch',
    templateUrl: 'book-chapter-switch.component.html',
})
export class BookChapterSwitchComponent implements OnInit {

    public readonly contentSwitchIconBox = new IconBoxBookModel();

    @Input()
    public classModifiers: Array<ContentSelectClassModifiersEnum> = [];

    @Output()
    public bookChapterChange: EventEmitter<Array<ContentSelectItemInterface>> = new EventEmitter();

    @Input()
    public disabled: boolean = false;

    public contentSwitchData: Array<ContentSelectItemInterface> = [];

    public initialActiveModel: ContentSelectItemInterface;

    constructor(
        private bookService: BookService,
        private contentSwitchModelFactory: ContentSelectModelFactory,
        private localStorageService: LocalStorageService,
    ) {
    }

    public ngOnInit(): void {
        this.bookService.retrieveActiveBooks()
            .subscribe((books: Array<ApiBookInterface>) => this.processBookData(books));
    }

    public onContentItemClick(): void {
        this.bookChapterChange.emit(this.contentSwitchData);

        this.localStorageService.set(LocalStorageKeysEnum.BookChapterSingleData, this.getActiveToPersisted(this.contentSwitchData));
    }

    private processBookData(books: Array<ApiBookInterface>): void {
        const models = this.contentSwitchModelFactory.fromApiBooksArray(books, true);
        const persisted = this.localStorageService.get<Array<BookChapterPersistedInterface>>(LocalStorageKeysEnum.BookChapterSingleData) || [];

        this.initialActiveModel = this.getInitialActiveState(models, persisted);
        this.contentSwitchData = models;

        this.bookChapterChange.emit(this.contentSwitchData);
    }

    private getInitialActiveState(models: Array<ContentSelectItemInterface>, persisted: Array<BookChapterPersistedInterface>): ContentSelectItemInterface {
        if (!(persisted instanceof Array) || persisted.length === 0) {
            return null;
        }

        if (!(models instanceof Array) || models.length === 0) {
            return null;
        }

        let initialActiveModel = null;

        for (const item of persisted) {
            for (const model of models) {
                if (model.id === item.id) {
                    model.active = item.active;

                    initialActiveModel = this.getInitialActiveState(model.children, item.children);

                    if (initialActiveModel === null) {
                        initialActiveModel = model;
                    }
                }
            }
        }

        return initialActiveModel;
    }

    private getActiveToPersisted(models: Array<ContentSelectItemInterface>): Array<BookChapterPersistedInterface> {
        const arr = [];

        if (!(models instanceof Array) || models.length === 0) {
            return arr;
        }

        for (const model of models) {
            const childPersisted = this.getActiveToPersisted(model.children);

            if (model.active || childPersisted.length > 0) {
                arr.push({
                    id: model.id,
                    active: model.active,
                    children: childPersisted
                });
            }
        }

        return arr;
    }
}
