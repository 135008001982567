import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {IconBoxClose} from 'models/iconbox/close.model';
import {SafeResourceUrl} from '@angular/platform-browser';
import {IconEnum} from 'enums/icon.enum';

@Component({
    selector: 'app-i-frame-modal',
    templateUrl: 'i-frame-modal.component.html',
})
export class IFrameModalComponent {

    public readonly iconBoxClose = new IconBoxClose();

    public url: SafeResourceUrl;

    public title: string;

    public showFooter: boolean = false;

    public icon: IconEnum;

    constructor(
        private activeModal: NgbActiveModal,
    ) {
    }

    public close(): void {
        this.activeModal.dismiss();
    }
}
