export enum DropdownClassEnum {

    Visible = 'dropdown-component--visible',

    Toc = 'dropdown-component--toc',

    ContentSwitch = 'dropdown-component--content-select',

    Profile = 'dropdown-component--profile',

    Caret = 'dropdown-component--caret',

    Filter = 'dropdown-component--filter',

}
