import {Injectable} from '@angular/core';
import 'isomorphic-fetch';
import {BroadcastService, MsalService} from '@azure/msal-angular';
import {ReplaySubject} from 'rxjs';
import {Router} from '@angular/router';
import {ClientAuthError, InteractionRequiredAuthError} from 'msal';
import {HttpErrorResponse} from '@angular/common/http';

declare const OneDrive: any;

@Injectable()
export class MsAlLoginService {

    public loginCompleted = new ReplaySubject<boolean>();

    public constructor(
        private msalService: MsalService,
        private broadcastService: BroadcastService,
        private router: Router
    ) {
        this.msalService.handleRedirectCallback(
            (authError, response) => {
                if (response.account && response.accountState === 'confirm') {
                    this.loginCompleted.next(true);
                    this.loginCompleted.complete();
                } else {
                    if (authError !== null) {
                        this.loginCompleted.next(false);
                        this.loginCompleted.complete();
                    }
                }
            }
        );
    }


    public authenticate(interactiveLogin: boolean, confirmation: boolean): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                const options = this.loginOptions(confirmation ? 'confirm' : 'silent');

                this.msalService.acquireTokenSilent(options)
                    .then(token => {
                        resolve(token);
                    }).catch(error => {
                        if (interactiveLogin) {
                            if (error instanceof ClientAuthError) {
                                this.msalService.loginRedirect(options);
                            }
                            if (error instanceof InteractionRequiredAuthError) {
                                this.msalService.acquireTokenRedirect(options);
                            }
                            if (error instanceof HttpErrorResponse && error.status === 401) {
                                this.msalService.acquireTokenRedirect(options);
                            }
                        }
                });
            }
        );
    }

    private loginOptions(myState: string): any {
        return (
            {
                scopes: this.msalService.getScopesForEndpoint('https://graph.microsoft.com/'),
                state: myState
            });
    }

    public getLoggedInUser(): string {
        const account = this.msalService.getAccount();

        if (account !== null) {
            return account.userName;
        }

        return null;
    }

    public isLoggedIn(): boolean {
        const account = this.msalService.getAccount();

        return account !== null;
    }

    public logout(): void {
        this.msalService.logout();
    }

    public clearLocalStorage(): void {
        Object.keys(localStorage).forEach(
            (key) => {
                if (key.indexOf('msal') > -1 || key.indexOf('microsoftonline') > -1 || key.indexOf('odpicker') > -1) {
                    localStorage.removeItem(key);
                }
            }
        );
    }

}
