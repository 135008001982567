import {Component, ElementRef, HostBinding, ViewChild} from '@angular/core';
import {ModalService} from 'core/services/modal/modal.service';
import {IconBoxClose} from 'models/iconbox/close.model';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';

@Component({
    selector: '.document-feedback-neutral',
    templateUrl: './neutral-feedback.component.html',
})
export class DocumentNeutralFeedbackComponent {

    @HostBinding('class')
    public elementClass: string = '';

    @ViewChild('neutralFeedbackContent', {read: ElementRef, static: true})
    public contents: ElementRef;

    constructor(
        private modalService: ModalService
    ) {
    }

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorBlue]);

    public showNeutralFeedback($event: any): void {
        this.modalService.showNeutralFeedback(this.contents.nativeElement.innerHTML);
    }


}
