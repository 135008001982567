import {Injectable} from '@angular/core';
import {ProgressStudentModel} from 'pages/modules/progress/models/student.model';
import {ProgressStudentInterface} from 'pages/modules/progress/interfaces/student.interface';
import {ProgressAssignmentStatusFactory} from 'pages/modules/progress/factories/assignment-status.factory';
import {ProgressModuleStatusFactory} from 'pages/modules/progress/factories/module-status.factory';
import {ProgressDrillsterStatusFactory} from 'pages/modules/progress/factories/drillster-status.factory';
import {ProgressExamsFactory} from 'pages/modules/progress/factories/exams.factory';
import {ApiUserDataInterface} from 'interfaces/api/user-data.interface';

@Injectable()
export class ProgressStudentFactory {

    constructor(
        private assignmentStatusFactory: ProgressAssignmentStatusFactory,
        private drillsterStatusFactory: ProgressDrillsterStatusFactory,
        private moduleStatusFactory: ProgressModuleStatusFactory,
        private examsFactory: ProgressExamsFactory,
    ) {
    }

    public fromApiUserData(userData: ApiUserDataInterface): ProgressStudentInterface {
        return new ProgressStudentModel(
            userData.id,
            userData.name,
        );
    }

    public fromApiProgressGroup(progressGroup: ApiProgressGroupInterface): Array<ProgressStudentInterface> {
        const users: Array<ProgressStudentInterface> = [];

        progressGroup.users.forEach((progressStudent: ApiProgressStudentInterface) => {
            users.push(new ProgressStudentModel(
                progressStudent.id,
                progressStudent.name,
                null,
                this.drillsterStatusFactory.fromApiProgressStudent(progressStudent),
                this.assignmentStatusFactory.fromApiProgressStudent(progressStudent),
                progressStudent.module_status ? this.moduleStatusFactory.fromApiProgressStudent(progressStudent) : null,
                this.examsFactory.fromApiProgressStudent(progressStudent),
            ));
        });

        return users;
    }

    public fromApiGroup(apiGroup: ApiGroupInterface): Array<ProgressStudentInterface> {
        const users: Array<ProgressStudentInterface> = [];

        apiGroup.students.forEach((apiStudent: ApiUserInterface) => {
            users.push(new ProgressStudentModel(
                apiStudent.id,
                apiStudent.name,
            ));
        });

        return users;
    }
}
