import {Injectable} from '@angular/core';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {IconEnum} from 'enums/icon.enum';
import {ActionButtonClassEnum} from 'shared/enums/action-button/class.enum';
import {ActionButtonDefaultModel} from 'shared/models/action-button/default.model';
import {ActionButtonToggleEnum} from 'shared/enums/action-button/toggle.enum';
import {ActionButtonToggleModel} from 'shared/models/action-button/toggle.model';
import {ActionButtonIconModel} from 'shared/models/action-button/icon.model';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {LinkTargetEnum} from 'enums/link-target.enum';

@Injectable()
export class ActionButtonFactory {

    public createButton(
        id: string,
        value: string,
        icon: IconEnum,
        classModifiers: Array<string> = [],
        routerLink: string = null,
        target: LinkTargetEnum = null,
    ): ActionButtonInterface {
        const button: ActionButtonInterface = new ActionButtonDefaultModel(id, value, icon, classModifiers);

        if (routerLink) {
            button.routerLink = routerLink;
        }

        if (target) {
            button.target = target;
        }

        return button;
    }

    public createPurpleButton(
        id: string,
        value: string,
        icon: IconEnum,
        classModifiers: Array<string> = [],
        routerLink: string = null,
        target: LinkTargetEnum = null,
    ): ActionButtonInterface {
        const buttonClassModifiers: Array<string> = [ActionButtonClassEnum.Purple, ...classModifiers];

        return this.createButton(id, value, icon, buttonClassModifiers, routerLink, target);
    }

    public createGreenButton(
        id: string,
        value: string,
        icon: IconEnum,
        classModifiers: Array<string> = [],
        routerLink: string = null,
        target: LinkTargetEnum = null,
    ): ActionButtonInterface {
        const buttonClassModifiers: Array<string> = [ActionButtonClassEnum.Green, ...classModifiers];

        return this.createButton(id, value, icon, buttonClassModifiers, routerLink, target);
    }

    public createIconButton(
        id: string,
        icon: IconEnum,
        classModifiers: Array<string> = [],
        iconClassModifiers: Array<string> = [],
    ): ActionButtonInterface {
        return new ActionButtonIconModel(id, icon, classModifiers, iconClassModifiers);
    }

    public createGreenIconButton(
        id: string,
        icon: IconEnum,
        classModifiers: Array<string> = [],
        iconClassModifiers: Array<string> = [],
    ): ActionButtonInterface {
        const buttonClassModifiers: Array<string> = [ActionButtonClassEnum.Green, ...classModifiers];
        const iconBoxClassModifiers: Array<string> = [IconBoxClassEnum.ColorGreen, ...iconClassModifiers];

        return this.createIconButton(id, icon, buttonClassModifiers, iconBoxClassModifiers);
    }

    public createToggleButton(
        id: string,
        state: boolean,
        active: string = ActionButtonToggleEnum.Active,
        inactive: string = ActionButtonToggleEnum.InActive,
        classModifiers: Array<string> = []
    ): ActionButtonInterface {
        const toggleBox: ToggleBoxInterface = {active, inactive, state};

        return new ActionButtonToggleModel(id, toggleBox, classModifiers);
    }
}
