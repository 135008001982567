export enum DocumentSelfScoreScoresEnum {

    None = null,

    Right = 10,

    Wrong = 20,

    Retry = 30,

    Pass = 40,

}
