import {Injectable} from '@angular/core';
import {ProgressExamScoreInterface} from 'pages/modules/progress/interfaces/exam-score.interface';
import {ProgressExamScoreModel} from 'pages/modules/progress/models/exam-score.model';
import {ProgressAverageInterface} from 'pages/modules/progress/interfaces/average.interface';
import {ProgressAverageModel} from 'pages/modules/progress/models/average.model';

@Injectable()
export class ProgressExamScoreFactory {

    //
    // Group
    //

    public fromApiProgressStudentScore(score: ApiProgressStudentScoreInterface): ProgressExamScoreInterface {
        return new ProgressExamScoreModel(
            score.avg_exam > 0.0 ? score.avg_exam : null,
            score.avg_practice_exam > 0.0 ? score.avg_practice_exam : null,
        );
    }

    public fromApiProgressAverage(average: ApiProgressAverageInterface): ProgressExamScoreInterface {
        return new ProgressExamScoreModel(
            average.avg_exam > 0.0 ? average.avg_exam : null,
            average.avg_practice_exam > 0.0 ? average.avg_practice_exam : null,
        );
    }

    //
    // Students
    //

    public fromApiProgressStudent(progressStudent: ApiProgressStudentInterface): ProgressExamScoreInterface {
        return new ProgressExamScoreModel(
            progressStudent.score.avg_exam > 0.0 ? progressStudent.score.avg_exam : null,
            progressStudent.score.avg_practice_exam > 0.0 ? progressStudent.score.avg_practice_exam : null,
        );
    }

    public fromApiProgressForStudentStudyMaterial(studyMaterial: ApiProgressForStudentStudyMaterialsInterface): ProgressExamScoreInterface {
        return new ProgressExamScoreModel(
            studyMaterial.scores[0].avg_exam > 0.0 ? studyMaterial.scores[0].avg_exam : null,
            studyMaterial.scores[0].avg_practice_exam > 0.0 ? studyMaterial.scores[0].avg_practice_exam : null,
        );
    }

}
