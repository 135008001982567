import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';

export class IconBoxClose implements IconBoxInterface {

    public value = IconEnum.Cross;

    public type = IconBoxTypeEnum.Icon;

    public classModifiers: Array<IconBoxClassEnum> = [
        IconBoxClassEnum.ModalClose,
    ];

    constructor(classModifiers: Array<IconBoxClassEnum> = []) {
        this.classModifiers = this.classModifiers.concat([
            IconBoxClassEnum.ModalClose
        ], classModifiers);
    }

}
