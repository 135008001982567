import {StudyMaterialTypeEnum} from 'enums/study-material-type.enum';

export class TocViewBookItemModel {

    constructor(
        public id: number,
        public dpsid: string,
        public title: string,
        public target: string,
        public type: StudyMaterialTypeEnum,
        public selfStudyCompatible: boolean,
        public doctype?: string,
        public disabled?: boolean,
    ) {}

    public static fromApiViewBook(tocViewBook: ApiTocViewBooksInterface): TocViewBookItemModel {
        return new TocViewBookItemModel(
            tocViewBook.id,
            tocViewBook.dpsid,
            tocViewBook.title,
            tocViewBook.target,
            tocViewBook.type,
            tocViewBook.self_study_compatible,
            tocViewBook.doctype,
            tocViewBook.disabled,
        );
    }
}
