import {
    ApplicationRef,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    EmbeddedViewRef,
    HostListener,
    Injector,
    OnDestroy
} from '@angular/core';
import {DocumentSummaryButtonComponent} from 'document/directives/summary/button/button.component';
import {Subscription} from 'rxjs';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {RoleEnum} from 'enums/role.enum';

@Directive({
    selector: '[appDocumentSummary]',
})
export class DocumentSummaryDirective implements OnDestroy {

    private currentSelection: Selection = null;

    private buttonElement: ComponentRef<DocumentSummaryButtonComponent> = null;

    private buttonClickSubscription: Subscription = null;

    public enabled: boolean = true;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector,
        private authorizationService: AuthorizationService,
    ) {}

    @HostListener('mouseup')
    public onMouseup(): void {
        if (!this.enabled) {
            return;
        }
        this.handleShowSummary();
    }

    @HostListener('document:mousedown', ['$event'])
    public onDocumentClick(event: MouseEvent): void {
        if (!this.enabled) {
            return;
        }
        const target: HTMLElement = <HTMLElement>event.target;

        if (this.elementRef.nativeElement && this.elementRef.nativeElement.contains(target)) {
            return;
        }

        if (target.classList && target.classList.contains('document-summary-button__button')) {
            return;
        } else if (target.className && target.className.indexOf('document-summary-button__button') >= 0) {
            return;
        }

        this.hideButton();
    }

    public ngOnDestroy(): void {
        this.hideButton();
    }

    private handleShowSummary(): void {
        this.hideButton();

        if (!this.authorizationService.isGranted(RoleEnum.RoleStudent)) {
            return;
        }

        this.currentSelection = this.getTextSelection();

        if (this.currentSelection === null) {
            return;
        }

        this.showButton();
    }

    private getTextSelection(): Selection|null {
        const selection = window.getSelection();

        if (selection.isCollapsed) {
            return null;
        }

        return selection;
    }

    private hideButton(): void {
        if (this.buttonClickSubscription !== null) {
            this.buttonClickSubscription.unsubscribe();
        }

        if (this.buttonElement === null) {
            return;
        }

        this.appRef.detachView(this.buttonElement.hostView);
        this.buttonElement.destroy();
        this.buttonElement = null;
    }

    private showButton(): void {
        this.buttonElement = this.componentFactoryResolver
            .resolveComponentFactory(DocumentSummaryButtonComponent)
            .create(this.injector);

        this.buttonElement.instance.selection = this.currentSelection;
        this.buttonClickSubscription = this.buttonElement.instance.clickEvent.subscribe(() => {
            this.hideButton();
        });

        this.appRef.attachView(this.buttonElement.hostView);

        const domElem = (this.buttonElement.hostView as EmbeddedViewRef<DocumentSummaryButtonComponent>)
            .rootNodes[0] as HTMLElement;

        document.body.appendChild(domElem);
    }
}
