import {ResultStateEnum} from 'shared/components/result-cell/enums/result-state.enum';
import {ResultCodeEnum} from 'shared/components/result-cell/enums/result-code.enum';
import {ProgressResultStateInterface} from 'pages/modules/progress/interfaces/result-state.interface';

export class ProgressResultStateMapping extends Array<ProgressResultStateInterface> {

    constructor(scoreModel: string) {
        let states: Array<ProgressResultStateInterface> = [];

        if (scoreModel === 'GVO') {
           states = [
                {
                    state: ResultStateEnum.Graded,
                    result: ResultCodeEnum.ResultRight,
                },
                {
                    state: ResultStateEnum.Graded,
                    result: ResultCodeEnum.ResultPass,
                },
                {
                    state: ResultStateEnum.Graded,
                    result: ResultCodeEnum.ResultWrong,
                },
                {
                    state: ResultStateEnum.Graded,
                    result: ResultCodeEnum.ResultRetry,
                },
                {
                    state: ResultStateEnum.HandedIn,
                    text: 'Ingeleverd',
                },
                {
                    state: ResultStateEnum.TooLate,
                    text: 'Te laat',
                }
            ];
        }

        if (scoreModel === 'GF') {
            states = [
                {
                    state: ResultStateEnum.Graded,
                    result: ResultCodeEnum.ResultRightWrongRight,
                },
                {
                    state: ResultStateEnum.Graded,
                    result: ResultCodeEnum.ResultRightWrongWrong,
                },
                {
                    state: ResultStateEnum.Graded,
                    result: ResultCodeEnum.ResultRetry,
                },
                {
                    state: ResultStateEnum.HandedIn,
                    text: 'Ingeleverd',
                },
                {
                    state: ResultStateEnum.TooLate,
                    text: 'Te laat',
                }
            ];
        }

        super(...states);

    }
}
