import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DocumentService} from 'document/services/document/document.service';
import {ReadSpeakerButtonComponent} from 'shared/components/read-speaker-button/read-speaker-button.component';
import {HeadingClassesEnum} from 'enums/heading-classes.enum';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {ExamService} from 'services/exam/exam.service';

@Component({
    selector: 'h1',
    templateUrl: 'heading.component.html',
})
export class DocumentHeadingComponent implements OnInit, AfterViewInit, OnDestroy {

    protected static readonly CLASS: string = 'heading';

    @Input()
    public readSpeakerEnabled: boolean = true;

    @ViewChild(ReadSpeakerButtonComponent, {static: false})
    public readSpeakerButtonComponent: ReadSpeakerButtonComponent;

    public isBpv: boolean = false;

    public studyMaterialTitle: string;

    public bookTitle: string;

    public disableBookTitle: boolean = false;

    private bookChangedSubscription: Subscription;

    private disableBookTitleSubscription: Subscription;

    constructor(
        protected elementRef: ElementRef,
        private documentService: DocumentService,
        private renderer: Renderer2,
        private route: Router,
        private examService: ExamService
    ) {
        this.bookChangedSubscription = this.documentService.bookChanged.subscribe(() => {
            this.setBookTitle();
        });
        this.disableBookTitleSubscription = this.examService.disableBookTitleSubject.subscribe(
            (disableBookTitle) => {
                this.disableBookTitle = disableBookTitle;
            }
        );
    }

    public ngOnInit(): void {
        const nativeElement: HTMLElement = this.getNativeElement();

        this.renderer.addClass(nativeElement, DocumentHeadingComponent.CLASS);
        this.renderer.addClass(nativeElement, HeadingClassesEnum.H1);
        this.renderer.addClass(nativeElement, HeadingClassesEnum.Document);
        this.renderer.addClass(nativeElement, HeadingClassesEnum.BootstrapGutter);
        this.renderer.addClass(nativeElement, HeadingClassesEnum.ReadSpeakerPreserve);

        if (!this.hasNestedSubHeading(nativeElement.nextSibling)) {
            this.renderer.addClass(nativeElement, HeadingClassesEnum.MarginBottom);
        }

        this.setBookTitle();
    }

    public ngAfterViewInit(): void {
        const document = this.documentService.getDocument();

        if (!document) {
            return;
        }

        this.studyMaterialTitle = document.studyMaterialTitle;

        if (this.studyMaterialTitle && this.studyMaterialTitle.length > 0 && !this.disableBookTitle) {
            this.getNativeElement().classList.add(HeadingClassesEnum.DocumentHasBookTitle);
        }

        if (document.assignments.length === 0) {
            return;
        }

        this.isBpv = document.assignments[0].is_bpv;
    }

    public ngOnDestroy(): void {
        if (this.bookChangedSubscription) {
            this.bookChangedSubscription.unsubscribe();
        }
        if (this.disableBookTitleSubscription) {
            this.disableBookTitleSubscription.unsubscribe();
        }

        this.readSpeakerEnabled = false;
    }

    private setBookTitle(): void {
        this.bookTitle = this.documentService.getBookTitle();
    }

    public onReadSpeakerShown(shown: boolean): void {
        if (shown === true) {
            this.renderer.addClass(this.getNativeElement(), HeadingClassesEnum.ReadSpeakerActive);
        } else {
            this.renderer.removeClass(this.getNativeElement(), HeadingClassesEnum.ReadSpeakerActive);
        }
    }

    private hasNestedSubHeading(element: Node): boolean {
        if (element instanceof HTMLElement) {
            if (element.tagName === 'H2') {
                // Ensure no top margin for this H2 element
                this.renderer.addClass(element, HeadingClassesEnum.MarginTopNone);

                return true;
            }

            return this.hasNestedSubHeading(element.firstChild);
        }

        return false;
    }

    private getNativeElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }
}
