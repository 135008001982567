import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';

export class ActionButtonToggleModel implements ActionButtonInterface {

    constructor(
        public id: string,
        public toggleBox: ToggleBoxInterface,
        public classModifiers: Array<string> = [],
    ) {
    }

}
