import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {ResourceTypeEnum} from 'enums/resource-type.enum';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {RouteService} from 'routing/services/route/route.service';
import {StringService} from 'services/string/string.service';
import {ApiService} from 'core/services/api/api.service';
import {TeacherMaterialService} from 'services/teacher-material/teacher-material.service';
import {environment} from 'environments/environment';
import {MaterialItemClass} from 'pages/modules/teacher-material/enums/material-item-class.enum';
import {OneDriveService} from 'onedrive/services/onedrive.service';
import {DocumentService} from 'document/services/document/document.service';
import {UrlUtil} from 'shared/util/url.util';


@Component({
    selector: 'app-teacher-material-item',
    templateUrl: 'item.component.html',
})
export class TeacherMaterialItemComponent implements OnInit, OnChanges {

    @Input()
    public item: ApiResourceInterface;

    @ViewChild('itemLink', {static: false})
    public itemLink: ElementRef;

    public iconBox: IconBoxInterface;

    public path: string = '';

    public teacherMaterialsEnabled: boolean;

    public class: MaterialItemClass;

    constructor(
        private stringService: StringService,
        private routeService: RouteService,
        private apiService: ApiService,
        private teacherMaterialService: TeacherMaterialService,
        private oneDriveService: OneDriveService,
        private documentService: DocumentService
    ) {
    }

    public ngOnInit(): void {
        this.loadIconBox();
        this.teacherMaterialsEnabled = environment.features.teacherMaterialsEnabled;

        if ( this.isUploadedSource()) {
            this.class = MaterialItemClass.UploadedSource;
            this.iconBox.classModifiers.push(IconBoxClassEnum.MaterialUploadedSource);
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.getElementHref();
        this.path = this.teacherMaterialService.getElementPath(this.item.path);
    }

    private loadIconBox(): void {
        this.iconBox = {
            type: IconBoxTypeEnum.Icon,
            value: this.getResourceIcon(),
            classModifiers: [
                IconBoxClassEnum.Material,
            ],
        };
    }

    private getResourceIcon(): IconEnum {
        return this.item.type === ResourceTypeEnum.Html
            ? IconEnum.Global
            : this.item.document_type === ResourceTypeEnum.Ebook ? IconEnum.Ebook : this.item.type === ResourceTypeEnum.Link ? IconEnum.Global : IconEnum.Document;
    }

    private getElementHref(): string {
        const dpsId: string = encodeURIComponent(this.item.dpsid);

        if (this.item.document_type === ResourceTypeEnum.Ebook) {
            return this.routeService.getRouteWithPublisher(RoutesEnum.PdfViewer, new Map([
                ['dpsid', dpsId],
            ]));
        }

        if (this.item.document_type === ResourceTypeEnum.TeacherSource && this.item.mime_type.indexOf('application/pdf') >= 0) {
            return this.routeService.getRouteWithPublisher(RoutesEnum.PdfViewerSource, new Map([
                ['sourceDpsid', dpsId],
            ]));
        }

        if (!this.item.dpsid) {
            return this.apiService.buildUrl(ApiEndpointEnum.SourceGet, new Map([['sourceId', this.item.id.toString()]]));
        }

        return this.routeService.getApiRoute(ApiEndpointEnum.ResourceExternal, new Map([
            ['dpsid', dpsId],
            ['book', this.documentService.getBookId().toString()],
        ]));
    }

    private isUploadedSource(): boolean {
        return !this.item.document_type;
    }

    public openDocument(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        const href = this.getElementHref();
        const target = '_blank';

        if ((this.item.type === 'document')) {
            this.oneDriveService.openDocument(
                this.documentService.getBookTitle(),
                UrlUtil.getURLSearchParamOrReturnUrl(href, 'dpsid'),
                href,
                target
            );
        } else {
            window.open(href, target);
        }
    }
}
