import {
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import { AutoCheckService } from 'document/services/autocheck/auto-check.service';
import { ResultCodeEnum } from 'shared/components/result-cell/enums/result-code.enum';

@Component({
    selector: 'app-document-page-auto-score',
    templateUrl: 'auto-score.component.html'
})
export class DocumentPageAutoScoreComponent implements OnInit, OnDestroy {

    public readonly scoresEnum = ResultCodeEnum;

    constructor(public autocheckService: AutoCheckService) {
    }

    public ngOnInit(): void {
    }

    public ngOnDestroy(): void {

    }

}
