import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-tasks-weeks',
    templateUrl: 'weeks.component.html',
})
export class WeeksComponent {

    @Input()
    public weeks: ApiTaskWeeksInterface;

    @Input()
    public studentSelected: ApiUserInterface;

    @Output()
    public loadTasks: EventEmitter<string> = new EventEmitter();

    public onLoadTasks(weekId: string): void {
        this.loadTasks.emit(weekId);
    }
}
