import {Injectable} from '@angular/core';
import {KeyValue} from '@angular/common';
import {ProgressStudentInterface} from 'pages/modules/progress/interfaces/student.interface';
import {GroupsService} from 'services/groups/groups.service';

@Injectable()
export class FilterDropdownOptionsFactory {

    public fromApiGroups(groups: Array<ApiGroupInterface>): Map<string, string> {
        const keyValues: Array<KeyValue<string, string>> = [];

        groups.forEach((group: ApiGroupInterface) => {
            keyValues.push({key: group.id.toString(), value: GroupsService.buildGroupName(group.name, group.year)});
        });

        return this.getKeyValuesAsMap(keyValues);
    }

    public fromProgressStudents(users: Array<ProgressStudentInterface>): Map<string, string> {
        const keyValues: Array<KeyValue<string, string>> = [];

        users.forEach((user: ApiUserInterface) => {
            keyValues.push({key: user.id.toString(), value: user.name});
        });

        return this.getKeyValuesAsMap(keyValues);
    }

    protected getKeyValuesAsMap(keyValues: Array<KeyValue<string, string>>): Map<string, string> {
        const mapped = <Array<[string, string]>>keyValues.map(keyValue => [keyValue.key, keyValue.value]);

        return new Map<string, string>(mapped);
    }
}
