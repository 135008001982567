import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {AuthenticationService} from 'security/services/authentication/authentication.service';
import {RoleEnum} from 'enums/role.enum';

@Injectable({
    providedIn: 'root'
})
export class IsStudentGuard implements CanActivate {
    public constructor(private authenticationService: AuthenticationService, private authorizationService: AuthorizationService) {
    }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.authorizationService.isGranted(RoleEnum.RoleStudent);
    }
}
