import {AfterViewInit, Component, ElementRef, OnDestroy} from '@angular/core';
import {DocumentAnswerableComponent} from 'document/modules/abstract/answerable.component';
import {DocumentGraphicGapMatchClassEnum} from './enums/class.enum';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {ClassHelper} from 'helpers/dom/class.helper';
import {CdkDragDrop, transferArrayItem} from '@angular/cdk/drag-drop';
import {Subject, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {AutoCheckService} from 'document/services/autocheck/auto-check.service';
import {RoleEnum} from 'enums/role.enum';
import {DocumentService} from 'document/services/document/document.service';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';

@Component({
    selector: '.document-graphicgapmatch',
    templateUrl: './graphic-gap-match.component.html',
    styleUrls: ['./graphic-gap-match.component.scss']
})
export class DocumentGraphicGapMatchComponent extends DocumentAnswerableComponent implements AfterViewInit, OnDestroy {
    public readonly roleEnum = RoleEnum;

    public readonly examinationNeededEnum = ExamineOptionsEnum;

    public classes: ClassHelper = new ClassHelper(DocumentGraphicGapMatchClassEnum.Matching);

    public id: string;

    public image: string;

    public imagepath: string;

    public hasTitles: boolean = false;

    public examinationByStudent: boolean = false;

    public options: any;

    public correctAnswers: Array<any>;

    public initialAnswers: Array<any>;

    public choices: Array<any>;

    public givenChoicesLeft: Array<any> = [];

    public initialChoicesLeft: Array<any> = [];

    public zones: {}  = {};

    public initialZones: {}  = {};

    public gaps: Array<any> = [];

    public mode: string;

    public answersUpdated$: Subject<any> = new Subject();

    public isMobile: boolean = false; // window.innerWidth < 786;

    protected dataSet: DocumentDatasetOptionsInterface;

    public answerHidden: boolean;

    public autoScoreEnabled: boolean;

    public autoScoreAnswer: any;

    private subscriptions: Array<Subscription> = [];


    constructor( protected elementRef: ElementRef,
                 protected answersService: DocumentAnswersService,
                 protected showAnswersService: DocumentAnswersStateService,
                 private autocheckService: AutoCheckService,
                 private documentService: DocumentService,
    ) {

        super(elementRef, answersService, showAnswersService);
        this.id = `graphicGapMatch-${this.dataSet.assignmentid}-${this.dataSet.fieldid}`;
        this.setup();
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.setupAutoScoring();
        this.handleExaminationNeeded();
    }

    private setup(): void {
        try {

            this.options = JSON.parse(this.dataSet.options);
            this.image = this.options.image;
            this.imagepath = this.options.imagepath;
            this.choices = this.options.options.map( (opt, index) => {
                return {
                    ...opt,
                    index,
                    removed: false
                };
            });

            this.givenChoicesLeft = [...this.choices];
            this.initialChoicesLeft = [...this.choices];

            this.gaps = this.options.gaps;

            this.gaps.forEach( gap => {
                this.zones[gap.id] = {...gap, answer: [], placement: this.calcPlacement(gap)};
                this.initialZones[gap.id] = {...gap, answer: [], placement: this.calcPlacement(gap)};
            });

            this.initializationFailed = false;

            this.setHasTitles();

            this.answersUpdated$.subscribe( () => {
                this.saveAnswer(JSON.stringify(this.createSaveAnswer()));
            });

        } catch (e) {
            this.initializationFailed = true;
            console.error('Failed to JSON parse the options:', e);
        }
    }

    private handleExaminationNeeded(): void {
        const task = this.documentService.getActiveTask();

        // examination by Student
        if (task && task.examination_needed === this.examinationNeededEnum.Student) {
            this.examinationByStudent = true;
        }
    }

    private setupAutoScoring(): void {

        const assignmentId = this.dataSet.assignmentid;
        const fieldId = parseInt(this.dataSet.fieldid, 10);
        const assignment = this.answersService.getAssignment(assignmentId);
        this.autoScoreEnabled = assignment && assignment.auto_check;

        if (! this.autoScoreEnabled) { return; }

        this.subscriptions.push( this.autocheckService.getAutoCheckAnswerForFieldId(assignmentId, fieldId).pipe(take(1)).subscribe( autoCheckAnswer => {

            if (autoCheckAnswer) {

                try {
                    const correctAnswers = JSON.parse(autoCheckAnswer) as Array<{gap: string, option: string}> ;
                    this.autoScoreAnswer = {};

                    correctAnswers.forEach( (answer: any) => {
                        this.autoScoreAnswer[answer.gap] = answer.option;
                    });

                } catch (error) {
                    console.warn('Could not parse Autocheck answers');
                }

            }
        }));
    }

    private setHasTitles(): void {
        this.hasTitles =
            (this.options.choiceslabel && this.options.choiceslabel.length > 0)
            || (this.options.matcheslabel && this.options.matcheslabel.length > 0);

        if (this.hasTitles) {
            this.classes.addClass(DocumentGraphicGapMatchClassEnum.MatchingHasTitles);
        }
    }

    public createSaveAnswer(): any {
        const gapZonesArray = Object.keys(this.zones);
        const answersToSave = [];

        gapZonesArray.forEach( zoneId => {
            const currentAnswer = this.zones[zoneId].answer;
            if ( currentAnswer.length ) {
                answersToSave.push({gap: zoneId, option: currentAnswer[0]});
            }
        });

        return answersToSave;
    }

    private calcPlacement(gap: any): any {
        const shape = gap.shape;
        if (shape === 'rect' ) {
            const coords = gap.coordinates.split(',');
            const [left, top, bottom, right] = coords;

            return {
                top,
                left,
                width: parseInt(bottom, 10) - parseInt(left, 10) + '%',
                height: parseInt(right, 10) - parseInt(top, 10) + '%',
            };
        }

        if (shape === 'circle' ) {
            const coords = gap.coordinates.split(',');
            const [x, y, radius] = coords;
            const diameterPerc = parseInt(radius, 10) * 2;
            const diameterPixels = diameterPerc / 2;

            return {
                top: `calc(${y} - ${diameterPixels / 2}px)`,
                left: `calc(${x} - ${diameterPixels / 2}px)`,
                width: diameterPerc + 3 + '%',
                height: diameterPerc / 2 + '%',
            };
        }
    }

    public handleDropToAnswer(event: CdkDragDrop<any>): void {

        // Handle a move inside answers. This moves it to the correct answer container.
        if ( event.container.data.length === 0 && event.previousContainer.data.length === 1 ) {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
            this.answersUpdated$.next();

            return;
        }

        // If we already have an answer in this dropzone, ignore it.
        if ( event.container.data.length > 0) { return; }

        // else move the item to answers and set removed flag on choices
        if (event.previousContainer !== event.container) {
            this.givenChoicesLeft = event.previousContainer.data.map( cur =>  {
                if (cur.id === event.item.data.id) {
                    return {
                        ...cur,
                        removed: true
                    };
                } else {
                    return cur;
                }
            });
            event.container.data.splice(event.item.data.index, 0, event.item.data);
        }
        this.answersUpdated$.next();
    }

    public handleDropToChoices(event: CdkDragDrop<any>): void {

        // This is a move inside the container - should be ignored
        if ( event.container.data.length === event.previousContainer.data.length ) { return; }

        // Drop back from answer to choices
        if (event.previousContainer !== event.container) {
            event.previousContainer.data.splice(0, 1);
            this.givenChoicesLeft = event.container.data.map( cur =>  {
                if (cur.id === event.item.data.id) {
                    return {
                        ...cur,
                        removed: false
                    };
                } else {
                    return cur;
                }
            });
            this.answersUpdated$.next();
        }
    }

    protected setAnswer(answer: string): void {
        const answersObj = this.getDecodedAnswer<{gap: string, option: any}[]>(answer);
        const givenAnswerIds = [];

        answersObj.forEach( savedAnswer =>  {
            const currentZone = this.zones[savedAnswer.gap];
            if ( currentZone ) {
                if (currentZone.answer.length === 0) {
                    currentZone.answer.push(savedAnswer.option);
                    givenAnswerIds.push(savedAnswer.option.id);
                }
            }
        });

        if ( givenAnswerIds.length ) { // This check is needed as sometimes the framework calls setAnswer twice.
            this.givenChoicesLeft = this.choices.map(choice => {
                return {
                    ...choice,
                    removed: givenAnswerIds.includes(choice.id)
                };
            });
        }
    }

    protected hideCorrectAnswer(): void {
        this.classes.removeClass(DocumentGraphicGapMatchClassEnum.MatchingShowCorrectAnswer);
    }

    protected showCorrectAnswer(correctAnswer: string): void {
        this.correctAnswers = this.setupAnswers(correctAnswer);
        this.classes.addClass(DocumentGraphicGapMatchClassEnum.MatchingShowCorrectAnswer);
    }

    public setupAnswers(correctAnswer: string): any {

        let correctedAnswers: Array<any> = [];

        try {
            const answers = JSON.parse(correctAnswer) as Array<{gap: string, option: string}>;
            correctedAnswers = answers.map( answer => {
                const zone = this.zones[answer.gap];
                const choiceFound = this.choices.find( choice => choice.id === answer.option );

                return {choice: choiceFound, placement: zone.placement};
            });

        } catch (error) {
            console.warn('INVALID ANSWER JSON');
        }

        return correctedAnswers;

    }

    protected answerValid(): boolean {
        return true;
    }

    protected showInitialAnswer(initialAnswer: string): void {
        try {
            const answersObj = JSON.parse(initialAnswer) as Array<{gap: string, option: any}>;
            const givenAnswerIds = [];
            this.initialAnswers = answersObj;

            answersObj.forEach( savedAnswer =>  {
                const currentZone = this.initialZones[savedAnswer.gap];
                if ( currentZone ) {
                    if (currentZone.answer.length === 0) {
                        currentZone.answer.push(savedAnswer.option);
                        givenAnswerIds.push(savedAnswer.option.id);
                    }
                }
            });

            if ( givenAnswerIds.length ) { // This check is needed as sometimes the framework calls initialAnswer twice.
                this.initialChoicesLeft = this.choices.map(choice => {
                    return {
                        ...choice,
                        removed: givenAnswerIds.includes(choice.id)
                    };
                });
            }

        } catch (error) {
            console.warn('INVALID ANSWER JSON');
        }
    }

    protected hideInitialAnswer(): void {
        // this.classes.addClass(DocumentMultiMatchingClassEnum.MatchingHideInitialAnswer);
    }

    protected disableAnswers(): void {
        this.classes.addClass(DocumentGraphicGapMatchClassEnum.MatchingDisabled);
    }

    protected enableAnswers(): void {
        this.classes.removeClass(DocumentGraphicGapMatchClassEnum.MatchingDisabled);
    }

    public ngOnDestroy(): void {
        this.autocheckService.resetAutoCheckAnswer();
        this.subscriptions.map(sub => sub.unsubscribe());
    }

}
