import {Component, Input} from '@angular/core';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {ActionButtonClassEnum} from 'shared/enums/action-button/class.enum';
import {DemoBarService} from 'services/demo-bar/demo-bar.service';
import {ToastrService} from 'ngx-toastr';
import {ModalService} from 'core/services/modal/modal.service';
import {PublisherService} from 'services/publisher/publisher.service';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-demo-bar',
    templateUrl: './demo-bar.component.html',
})
export class DemoBarComponent {

    @Input()
    public bookId: string = '';

    @Input()
    public demo: boolean = false;

    @Input()
    public licenseRequested: boolean = false;

    public isLoading: boolean = false;

    public slug?: string;

    public action: ActionButtonInterface = {
        id: '1',
        value: 'Neem contact op',
        iconBox: {
            value: IconEnum.Send,
            type: IconBoxTypeEnum.Icon,
        },
        classModifiers: [
            ActionButtonClassEnum.Demo,
        ],
    };

    constructor(
        private demoBarService: DemoBarService,
        private toastService: ToastrService,
        private modalService: ModalService,
        private publisherService: PublisherService,
    ) {
        this.slug = this.publisherService.getActivePublisher().slug;
    }

    public openLink(): void {
        const url = this.slug === 'bbo' ? environment.bboUrl : environment.bvoUrl;

        window.open(url, '_blank');
    }

    public setRequest(): void {
        if (this.licenseRequested) {
            return;
        }

        const modalInstance = this.modalService.demoRequest();
        modalInstance.setRequestEvent.subscribe((request) => {

            if (!request) {
                return;
            }

            this.isLoading = true;
            this.demoBarService.requestTeacherLicense(this.bookId)
                .subscribe(() => {
                    this.licenseRequested = true;
                    this.isLoading = false;
                    modalInstance.close();
                }, (error) => {
                    this.toastService.error(error.error.msg);
                    this.isLoading = false;
                });
        });
    }
}
