import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ButtonComponent} from 'shared/components/button/button.component';
import {ColorBarComponent} from 'shared/components/color-bar/color-bar.component';
import {FormsModule} from '@angular/forms';
import {MineFilterComponent} from 'shared/components/mine-filter/mine-filter.component';
import {StatusFilterComponent} from 'shared/components/status-filter/status-filter.component';
import {IconBoxComponent} from 'shared/modules/icon-box/components/icon-box.component';
import {ContentSelectModelFactory} from 'shared/modules/content-select/factories/model.factory';
import {PreloaderComponent} from 'shared/components/preloader/preloader.component';
import {FilterDropdownComponent} from 'shared/components/filter-dropdown/filter-dropdown.component';
import {RemoveToggleComponent} from 'shared/components/remove-toggle/remove-toggle.component';
import {CollapsibleComponent} from 'shared/components/collapsible/collapsible.component';
import {DrillsterComponent} from 'shared/components/drillster/drillster.component';
import {ToggleBoxComponent} from 'shared/components/toggle-box/toggle-box.component';
import {DrillsterModalComponent} from 'shared/components/drillster/drillster-modal/drillster-modal.component';
import {CheckBoxComponent} from 'shared/components/check-box/check-box.component';
import {FilterButtonGroupComponent} from 'shared/components/filter-button-group/filter-button-group.component';
import {RadioBoxComponent} from 'shared/components/radio-box/radio-box.component';
import {FormElementsModule} from 'shared/modules/form-elements/form-elements.module';
import {
    NgbCarouselConfig,
    NgbCarouselModule,
    NgbDateParserFormatter,
    NgbDatepickerI18n,
    NgbDatepickerModule,
    NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import {DatePickerComponent} from 'shared/components/date-picker/date-picker.component';
import {TimePickerComponent} from 'shared/components/time-picker/time-picker.component';
import {AttachmentsComponent} from 'shared/components/attachments/attachments.component';
import {AvatarComponent} from 'shared/components/avatar/avatar.component';
import {ResultCellComponent} from 'shared/components/result-cell/result-cell.component';
import {TaskStatePipe} from 'shared/pipes/task-state.pipe';
import {HomeworkStatePipe} from 'shared/pipes/homework-state.pipe';
import {HorizontalBarModule} from 'shared/modules/horizontal-bar/horizontal-bar.module';
import {ScoringComponent} from 'shared/components/scoring/scoring.component';
import {TabModule} from 'shared/modules/tab/tab.module';
import {ActionButtonComponent} from 'shared/components/action-button/action-button.component';
import {ActionButtonFactory} from 'shared/factories/action-button.factory';
import {FilterDropdownOptionsFactory} from 'shared/factories/filter-dropdown-options.factory';
import {GridTableModule} from 'shared/modules/grid-table/grid-table.module';
import {PageModule} from 'shared/modules/page/page.module';
import {AssignmentStatusStatePipe} from 'shared/pipes/assignment-status-state.pipe';
import {ContentSelectModule} from 'shared/modules/content-select/content-select.module';
import {IconBoxModule} from 'shared/modules/icon-box/icon-box.module';
import {PipesModule} from 'shared/modules/pipes/pipes.module';
import {DropdownModule} from 'shared/modules/dropdown/dropdown.module';
import {SearchModule} from 'shared/modules/search/search.module';
import {DirectivesModule} from 'shared/modules/directives/directives.module';
import {WorkTypeLabelComponent} from 'shared/components/work-type-label/work-type-label.component';
import {SecurityModule} from 'security/security.module';
import {InviteExternalReviewerModalComponent} from 'shared/modals/invite-external-reviewer/invite-external-reviewer-modal.component';
import {NotFoundComponent} from 'shared/components/not-found/not-found.component';
import {PublisherRoutePipe} from 'shared/pipes/publisher-route.pipe';
import {PopoverTitleComponent} from 'shared/components/popover-title/popover-title.component';
import {TaskTitleComponent} from 'shared/components/task-title/task-title.component';
import {TaskResultPipe} from 'shared/pipes/task-result.pipe';
import {DatePickerI18n} from 'shared/date-picker/date-picker-i18n';
import {DatePickerFormatter} from 'shared/date-picker/date-picker-formatter';
import {DateTimePickerComponent} from 'shared/components/date-time-picker/date-time.component';
import {UserSelectionPersonalComponent} from 'shared/components/user-selection-personal/personal.component';
import {TermsConditionsModalComponent} from 'shared/modals/terms-conditions/terms-conditions.component';
import {CardComponent} from 'shared/components/card/card.component';
import {ReadSpeakerButtonComponent} from 'shared/components/read-speaker-button/read-speaker-button.component';
import {OrganizationModalComponent} from 'shared/modals/organizaton/organization-modal.component';
import {SummaryModalComponent} from 'shared/modals/summary/summary-modal.component';
import {NoteModalComponent} from 'shared/modals/note/note-modal.component';
import {IFrameModalComponent} from 'shared/modals/i-frame/i-frame-modal.component';
import {SourceModalComponent} from 'shared/modals/source/source-modal.component';
import {BookChapterSwitchComponent} from 'shared/components/book-chapter-switch/book-chapter-switch.component';
import {GroupFilterComponent} from 'shared/components/group-filter/group-filter.component';
import {StudentFilterComponent} from 'shared/components/student-filter/student-filter.component';
import {ContentEditableDirective} from 'shared/directives/content-editable/content-editable.directive';
import {ProgressPopoverHandedInComponent} from 'shared/components/popover-handed-in/popover-handed-in.component';
import {BookCoverComponent} from './components/book-cover/book-cover.component';
import {HelpPageModule} from 'shared/modals/help-page/help-page.module';
import { DemoBarComponent } from './components/demo-bar/demo-bar.component';
import { DemoMarkerComponent } from './components/demo-marker/demo-marker.component';
import { AlertMessageComponent } from 'shared/components/alert-message/alert-message.component';
import {TypeofPipe} from 'shared/pipes/typeof.pipe';
import {ProgressBarComponent} from 'shared/components/progress-bar/progress-bar.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatButtonModule} from '@angular/material/button';
import {AddAttachmentsModalComponent} from 'shared/modals/add-attachments/add-attachments.component';
import {AddAttachmentPreferencesComponent} from 'shared/modals/add-attachments/add-attachment-preferences/add-attachment-preferences.component';
import {AddUrlModalComponent} from 'shared/modals/add-url/add-url.component';
import {AddUrlPreferencesComponent} from 'shared/modals/add-url/add-url-preferences/add-url-preferences.component';
import {UrlHttpsPipe} from 'shared/pipes/url-https.pipe';
import {NeutralFeedbackModalComponent} from 'shared/modals/neutral-feedback-modal/neutral-feedback-modal.component';
import {PopoverDrillsterComponent} from 'shared/components/popover-drillster/popover-drillster.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        FormElementsModule,
        NgbDatepickerModule,
        HorizontalBarModule,
        TabModule,
        PageModule,
        GridTableModule,
        NgbTooltipModule,
        ContentSelectModule,
        IconBoxModule,
        PipesModule,
        DropdownModule,
        SearchModule,
        DirectivesModule,
        SecurityModule,
        NgbCarouselModule,
        HelpPageModule,
        MatProgressBarModule,
        MatButtonModule,
    ],
    declarations: [
        ActionButtonComponent,
        ButtonComponent,
        ColorBarComponent,
        FilterDropdownComponent,
        RemoveToggleComponent,
        CollapsibleComponent,
        ToggleBoxComponent,
        DrillsterComponent,
        DrillsterModalComponent,
        CheckBoxComponent,
        FilterButtonGroupComponent,
        RadioBoxComponent,
        DatePickerComponent,
        TimePickerComponent,
        AttachmentsComponent,
        AvatarComponent,
        ResultCellComponent,
        ScoringComponent,
        TaskStatePipe,
        TypeofPipe,
        HomeworkStatePipe,
        AssignmentStatusStatePipe,
        UrlHttpsPipe,
        WorkTypeLabelComponent,
        InviteExternalReviewerModalComponent,
        NotFoundComponent,
        PublisherRoutePipe,
        PopoverTitleComponent,
        TaskTitleComponent,
        TaskResultPipe,
        DateTimePickerComponent,
        UserSelectionPersonalComponent,
        TermsConditionsModalComponent,
        CardComponent,
        ReadSpeakerButtonComponent,
        OrganizationModalComponent,
        SummaryModalComponent,
        NoteModalComponent,
        IFrameModalComponent,
        SourceModalComponent,
        ContentEditableDirective,
        BookChapterSwitchComponent,
        GroupFilterComponent,
        StudentFilterComponent,
        ProgressPopoverHandedInComponent,
        PopoverDrillsterComponent,
        MineFilterComponent,
        StatusFilterComponent,
        BookCoverComponent,
        DemoMarkerComponent,
        DemoBarComponent,
        AlertMessageComponent,
        ProgressBarComponent,
        AddAttachmentsModalComponent,
        AddAttachmentPreferencesComponent,
        AddUrlModalComponent,
        AddUrlPreferencesComponent,
        NeutralFeedbackModalComponent
    ],
    exports: [
        ActionButtonComponent,
        IconBoxComponent,
        ButtonComponent,
        ColorBarComponent,
        PreloaderComponent,
        FilterDropdownComponent,
        RemoveToggleComponent,
        CollapsibleComponent,
        ToggleBoxComponent,
        DrillsterComponent,
        DrillsterModalComponent,
        CheckBoxComponent,
        FilterButtonGroupComponent,
        RadioBoxComponent,
        DatePickerComponent,
        TimePickerComponent,
        AttachmentsComponent,
        AvatarComponent,
        ResultCellComponent,
        ScoringComponent,
        FormElementsModule,
        HorizontalBarModule,
        TabModule,
        PageModule,
        GridTableModule,
        ContentSelectModule,
        IconBoxModule,
        DropdownModule,
        SearchModule,
        DirectivesModule,
        PipesModule,
        TaskStatePipe,
        TypeofPipe,
        HomeworkStatePipe,
        AssignmentStatusStatePipe,
        WorkTypeLabelComponent,
        InviteExternalReviewerModalComponent,
        NotFoundComponent,
        PublisherRoutePipe,
        PopoverTitleComponent,
        TaskTitleComponent,
        PopoverDrillsterComponent,
        TaskResultPipe,
        UrlHttpsPipe,
        DateTimePickerComponent,
        UserSelectionPersonalComponent,
        TermsConditionsModalComponent,
        CardComponent,
        ReadSpeakerButtonComponent,
        OrganizationModalComponent,
        SummaryModalComponent,
        NoteModalComponent,
        BookChapterSwitchComponent,
        GroupFilterComponent,
        StudentFilterComponent,
        ContentEditableDirective,
        ProgressPopoverHandedInComponent,
        MineFilterComponent,
        StatusFilterComponent,
        BookCoverComponent,
        DemoMarkerComponent,
        DemoBarComponent,
        AlertMessageComponent,
        ProgressBarComponent,
        AddAttachmentsModalComponent,
        AddAttachmentPreferencesComponent,
        AddUrlModalComponent,
        AddUrlPreferencesComponent,
        NeutralFeedbackModalComponent
    ],
    providers: [
        ContentSelectModelFactory,
        FilterDropdownOptionsFactory,
        ActionButtonFactory,
        NgbCarouselConfig,
        {provide: NgbDatepickerI18n, useClass: DatePickerI18n},
        {provide: NgbDateParserFormatter, useClass: DatePickerFormatter},
    ],
    entryComponents: [
        DrillsterModalComponent,
        InviteExternalReviewerModalComponent,
        TermsConditionsModalComponent,
        OrganizationModalComponent,
        SummaryModalComponent,
        NoteModalComponent,
        IFrameModalComponent,
        SourceModalComponent,
        AddAttachmentsModalComponent,
        AddUrlModalComponent,
        NeutralFeedbackModalComponent
    ]
})
export class SharedModule {
}
