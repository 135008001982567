export enum PreloaderClassModifiersEnum {

    Inline = 'preloader--inline',

    Visible = 'preloader--visible',

    Background = 'preloader--background',

    Groups = 'preloader--groups',

    Tasks = 'preloader--tasks',

    Exams = 'preloader--exams',

    Small = 'preloader--small',

    Progress = 'preloader--progress',

}
