import {GroupYearFilterEnum} from 'pages/modules/groups/enums/group-year-filter.enum';
import {FilterDropdownFilterInterface} from 'shared/components/filter-dropdown/interfaces/filter.interface';
import * as moment from 'moment';

export class GroupsModuleGroupsYearModel implements FilterDropdownFilterInterface<string> {

    protected static readonly YEAR_PREVIOUS = moment().subtract(1, 'year').year().toString();
    protected static readonly YEAR_CURRENT = moment().add(0, 'years').year().toString();
    protected static readonly YEAR_NEXT = moment().add(1, 'year').year().toString();
    protected static readonly YEAR_NEXT_TWO = moment().add(2, 'year').year().toString();

    public id: string = 'add-group-year-filter';

    public selectedValue: string = GroupYearFilterEnum.All;

    public options: Map<string, Map<string, string>>;

    constructor(
        public editMode: boolean = false,
    ) {
        const options: Array<[string, string]> = [
            [GroupsModuleGroupsYearModel.YEAR_PREVIOUS, GroupsModuleGroupsYearModel.YEAR_PREVIOUS],
            [GroupsModuleGroupsYearModel.YEAR_CURRENT, GroupsModuleGroupsYearModel.YEAR_CURRENT],
            [GroupsModuleGroupsYearModel.YEAR_NEXT, GroupsModuleGroupsYearModel.YEAR_NEXT],
            [GroupsModuleGroupsYearModel.YEAR_NEXT_TWO, GroupsModuleGroupsYearModel.YEAR_NEXT_TWO],
        ];

        if (!editMode) {
            options.unshift([GroupYearFilterEnum.All, GroupYearFilterEnum.All]);
        } else {
            this.selectedValue = GroupsModuleGroupsYearModel.YEAR_CURRENT;
        }

        this.options = new Map([['', new Map(options)]]);
    }
}
