import {Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TocComponentInterface} from 'shared/modules/toc/interfaces/toc-component.interface';
import {Subscription} from 'rxjs';
import {ClassHelper} from 'helpers/dom/class.helper';
import {TocClassEnum} from 'shared/modules/toc/enums/toc-class.enum';
import {TocRolesLogic} from 'shared/modules/toc/authorization/roles.logic';
import {RoleService} from 'security/services/role/role.service';
import {AnalyticsService} from 'services/analytics/analytics.service';
import {AnalyticsDimensionEnum} from 'services/analytics/enums/dimension.enum';
import {AnalyticsEventBookTableOfContentsModel} from 'services/analytics/models/book-table-of-contents.model';
import {TocViewBookItemInterface} from 'shared/modules/toc/interfaces/toc-view-book-item.interface';
import {ToastrService} from 'ngx-toastr';
import {WindowService} from 'services/window/window.service';

@Component({
    selector: 'app-toc-component',
    templateUrl: 'toc.component.html',
})
export class TocComponent implements OnInit, OnChanges, OnDestroy {

    public static readonly CLASS: string = 'toc-component';

    @HostBinding('class')
    public elementClasses: string;

    @HostBinding('style.width.px')
    protected elementWidth?: number = null;

    @Input()
    public toc: TocComponentInterface;

    @Input()
    public bookId: number;

    @Input()
    public currentBook: ApiBookInterface;

    public isFixed: boolean = false;

    public isLoading: boolean = true;

    public bookItem: TocViewBookItemInterface;

    private classHelper: ClassHelper = new ClassHelper(TocComponent.CLASS);

    private parentElement: HTMLElement;

    private readonly windowScrollSubscription: Subscription;

    constructor(
        private elementRef: ElementRef,
        private roleService: RoleService,
        private tocRolesLogic: TocRolesLogic,
        private analyticsService: AnalyticsService,
        private toastService: ToastrService,
        private windowService: WindowService,
    ) {
        this.roleService.registerRoleLogic(this.tocRolesLogic);
        this.windowScrollSubscription = this.windowService.onScroll.subscribe(() => this.updateElement());
    }

    public ngOnInit(): void {
        if (this.toc.viewBooks.length > 0) {
            this.analyticsService.dimension(AnalyticsDimensionEnum.BookTitle, this.toc.viewBooks[0].viewBook.title);
            this.analyticsService.dimension(AnalyticsDimensionEnum.MethodTitle, this.toc.viewBooks[0].title);
        }

        this.analyticsService.event(new AnalyticsEventBookTableOfContentsModel());
        this.parentElement = this.elementRef.nativeElement.parentElement;
        this.classHelper.addClasses(this.toc.classModifiers || []);

        this.updateElement();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.bookId) {
            this.isLoading = true;
        }

        if (changes.toc) {
            this.bookItem = {
                type: null,
                title: 'root',
                viewBook: null,
                children: this.toc.viewBooks
            };

            this.isLoading = false;
        }
    }

    public ngOnDestroy(): void {
        this.windowScrollSubscription.unsubscribe();
        this.roleService.unregisterLogic(this.tocRolesLogic);
    }

    public hideToc(): void {
        this.toc.visible = false;
    }

    public handleItemActiveEvent(bookItem: TocViewBookItemInterface): void {
        this.setItemActiveStates(false, this.bookItem.children, bookItem);
    }

    private updateElement(): void {
        // Check parent's parent element rather than having a fixed offset top value
        if (this.parentElement.parentElement.getBoundingClientRect().top <= 0) { // Toggle fixed class
            this.toggleFixed(true);
        } else {
            this.toggleFixed(false);
        }
    }

    private toggleFixed(value: boolean): void {
        this.elementWidth = value ? this.parentElement.offsetWidth : null;
        this.classHelper.toggleClassByBoolean(TocClassEnum.Fixed, value);
        this.updateElementClasses();
    }

    private setItemActiveStates(
        value: boolean,
        bookItems: Array<TocViewBookItemInterface>,
        filterBookItem: TocViewBookItemInterface,
    ): void {
        bookItems.forEach((childBookItem: TocViewBookItemInterface) => {
            childBookItem.active = (childBookItem !== filterBookItem) ? value : childBookItem.active;

            if (childBookItem.children.length > 0) {
                this.setItemActiveStates(value, childBookItem.children, filterBookItem);
            }
        });
    }

    private updateElementClasses(): void {
        this.elementClasses = this.classHelper.toString();
    }
}
