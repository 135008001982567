import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {Observable} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';

@Injectable()
export class LicenseService {
    constructor(private apiRequestService: ApiService) {
    }

    public activateMultiple(licences: Array<string>): Observable<Array<ApiLicenseActivationInterface>> {
        return this.apiRequestService
            .post<Array<ApiLicenseActivationInterface>>(
                ApiEndpointEnum.LicensesActivateMultiple,
                {
                    licences: licences
                },
            );
    }
}
