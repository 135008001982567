import {Pipe, PipeTransform} from '@angular/core';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {RoleEnum} from 'enums/role.enum';
import {RoleService} from 'security/services/role/role.service';

@Pipe({
    name: 'isGranted',
    pure: false,
})
export class AuthorizationIsGrantedPipe implements PipeTransform {

    protected isGranted: boolean;

    protected rolesSnapshot: string;

    constructor(
        protected roleService: RoleService,
        protected authorizationService: AuthorizationService,
    ) {
    }

    /**
     * Check if input variables change because this is not a pure pipe and return the cached
     * state to prevent unnecessary code execution.
     */
    public transform(role: RoleEnum | Array<RoleEnum>): boolean {
        if (this.isGranted !== undefined && !this.hasRoleChanges()) {
            return this.isGranted;
        }

        return this.getIsGranted(role);
    }

    protected getIsGranted(role: RoleEnum | Array<RoleEnum>): boolean {
        const checkRoles = role instanceof Array ? role : [role];

        // All roles should return granted, here we'll return true when one role fails
        this.isGranted = !checkRoles.some((checkRole: RoleEnum) => {
            return !this.authorizationService.isGranted(checkRole);
        });

        return this.isGranted;
    }

    protected hasRoleChanges(): boolean {
        const userRolesSnapshot: string = this.roleService.getRolesSnapshot();
        const hasRoleChanges: boolean = userRolesSnapshot !== this.rolesSnapshot;

        if (hasRoleChanges) {
            this.rolesSnapshot = userRolesSnapshot;
        }

        return hasRoleChanges;
    }
}
