import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {ProgressAverageDetailsColColumnInterface} from 'pages/modules/progress/components/average-details/interfaces/col-column.interface';
import {ProgressAverageDetailsColHeaderInterface} from 'pages/modules/progress/components/average-details/interfaces/col-header.interface';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {CollapsibleClassEnum} from 'shared/components/collapsible/enums/class.enum';
import {ResultStateEnum} from 'shared/components/result-cell/enums/result-state.enum';
import {ResultCodeEnum} from 'shared/components/result-cell/enums/result-code.enum';
import {ResultClassEnum} from 'shared/components/result-cell/enums/result-class.enum';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {ProgressResultStateMapping} from 'pages/modules/progress/mapping/result-state.mapping';
import {ProgressGroupInterface} from 'pages/modules/progress/interfaces/group.interface';
import {ProgressStatusFilterEnum} from 'shared/components/status-filter/enum/progress-status-filter.enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import {ProgressResultIsNumericStateMapping} from 'pages/modules/progress/mapping/result-numeric-state.mapping';
import {AutoCheckService} from 'document/services/autocheck/auto-check.service';
import {GridTableScrollColComponent} from 'shared/modules/grid-table/components/scroll-col/scroll-col.component';
import {GridTableScrollControlComponent} from 'shared/modules/grid-table/components/scroll-control/scroll-control.component';

@Component({
    selector: 'app-progress-average-details',
    templateUrl: 'average-details.component.html',
})
export class ProgressAverageDetailsComponent implements OnInit, OnChanges {
    public readonly iconEnum = IconEnum;

    public readonly tableClasses = GridTableClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly rowClasses = GridTableRowClassEnum;

    public readonly collapsibleClasses = CollapsibleClassEnum;

    public readonly resultCellClasses = ResultClassEnum;

    public resultsOrder: Array<ResultCodeEnum | ResultStateEnum> = [];

    public containsAverages: boolean = false;

    public readonly resultsOrderGVO: Array<ResultCodeEnum | ResultStateEnum> = [
        ResultCodeEnum.ResultRight,
        ResultCodeEnum.ResultPass,
        ResultCodeEnum.ResultWrong,
        ResultCodeEnum.ResultRetry,
        ResultStateEnum.HandedIn,
        ResultStateEnum.TooLate,
    ];

    public readonly resultsOrderGF: Array<ResultCodeEnum | ResultStateEnum> = [
        ResultCodeEnum.ResultRightWrongRight,
        ResultCodeEnum.ResultRightWrongWrong,
        ResultCodeEnum.ResultRetry,
        ResultStateEnum.HandedIn,
        ResultStateEnum.TooLate,
    ];

    public readonly resultsOrderIsNumeric: Array<ResultCodeEnum | ResultStateEnum> = [
        ResultStateEnum.IsNumeric,
        ResultCodeEnum.ResultRetry,
        ResultStateEnum.HandedIn,
        ResultStateEnum.TooLate,
    ];

    @Input()
    public classHelper: ClassHelper;

    @Input()
    public group: ProgressGroupInterface = null;

    @Input()
    public assignmentsStatus: Array<ApiProgressAverageAssignmentStatusInterface>;

    @Input()
    public bookId: number;

    @Input()
    public filter: ProgressStatusFilterEnum;

    @Input()
    public isNumeric: boolean = false;

    public results: Array<any> = [];

    public resultStatuses: ProgressResultStateMapping;

    public resultIsNumericStatuses: ProgressResultIsNumericStateMapping = new ProgressResultIsNumericStateMapping();

    public headers: Array<ProgressAverageDetailsColHeaderInterface> = [];

    public columns: Array<ProgressAverageDetailsColColumnInterface> = [];

    public rows: Array<Array<any>> = [];

    private scoreModel = 'GVO';

    @ViewChild(GridTableScrollColComponent, { static: true })
    private gridScrollComponent: GridTableScrollColComponent;

    @ViewChild(GridTableScrollControlComponent, { static: true })
    private tableScrollControlComponent: GridTableScrollControlComponent;

    constructor(private autoCheckService: AutoCheckService) {
        this.autoCheckService.getScoreModel().subscribe(
            (scoreModel) => {
                this.scoreModel = scoreModel;
                this.resultStatuses = new ProgressResultStateMapping(scoreModel);
                this.resultsOrder = scoreModel === 'GF' ? this.resultsOrderGF : this.resultsOrderGVO;
            }
        );
    }

    public ngOnInit(): void {
        this.gridScrollComponent.addScrollControl(this.tableScrollControlComponent);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.group || changes.filter) {
            this.setupResults();
        }
    }

    private setupResults(): void {
        if (this.group.average.statuses.length > 0) {
            this.containsAverages = true;
        }

        if (this.isNumeric === true) {
            this.scoreModel = 'Numeric';
        }

        const headers: Array<ProgressAverageDetailsColHeaderInterface> = [];
        const columns: Array<ProgressAverageDetailsColColumnInterface> = [];
        const rows: Array<Array<any>> = [];

        switch (this.scoreModel) {
            case 'Numeric':
                this.resultsOrder = this.resultsOrderIsNumeric;
                break;
            case 'GVO':
                this.resultsOrder = this.resultsOrderGVO;
                break;
            case 'GF':
                this.resultsOrder = this.resultsOrderGF;
                break;
        }

        for (const resultState of this.resultsOrder) {
            rows.push([]);
        }

        for (let i = 0; i < this.group.average.statuses.length; i++) {
            const assignmentStatus = this.group.average.statuses[i];

            if (this.filter !== ProgressStatusFilterEnum.All && assignmentStatus.taskAssignedCount === 0) {
                continue;
            }

            headers.push({
                width: 72,
                status: assignmentStatus,
            });
            columns.push({
                width: 72,
            });

            const row: any = {};


            switch (this.scoreModel) {
                case 'Numeric':
                    if (this.assignmentsStatus && this.assignmentsStatus[i].numeric_total) {
                        row[ResultStateEnum.IsNumeric] = Math.round(this.assignmentsStatus[i].numeric_total / this.assignmentsStatus[i].handedin_count);
                    } else {
                        row[ResultStateEnum.IsNumeric] = 0;
                    }

                    break;
                case 'GF':
                    row[ResultCodeEnum.ResultRightWrongRight] = Math.round((100.0 / assignmentStatus.taskAssignedCount) * assignmentStatus.rightWrong_rightCount);
                    row[ResultCodeEnum.ResultRightWrongWrong] = Math.round((100.0 / assignmentStatus.taskAssignedCount) * assignmentStatus.rightWrong_wrongCount);

                    break;
                case 'GVO':
                    row[ResultCodeEnum.ResultPass] = Math.round((100.0 / assignmentStatus.taskAssignedCount) * assignmentStatus.passCount);
                    row[ResultCodeEnum.ResultRight] = Math.round((100.0 / assignmentStatus.taskAssignedCount) * assignmentStatus.rightCount);
                    row[ResultCodeEnum.ResultWrong] = Math.round((100.0 / assignmentStatus.taskAssignedCount) * assignmentStatus.wrongCount);

                    break;
            }

            row[ResultCodeEnum.ResultRetry] = Math.round((100.0 / assignmentStatus.taskAssignedCount) * assignmentStatus.retryCount);
            row[ResultStateEnum.Doing] = Math.round((100.0 / assignmentStatus.taskAssignedCount) * assignmentStatus.inProgressCount);
            row[ResultStateEnum.HandedIn] = Math.round((100.0 / assignmentStatus.taskAssignedCount) * assignmentStatus.handedInCount);
            row[ResultStateEnum.TooLate] = Math.round((100.0 / assignmentStatus.taskAssignedCount) * assignmentStatus.lateCount);


            for (let j = 0; j < this.resultsOrder.length; j++) {
                rows[j].push(row[this.resultsOrder[j]]);
            }
        }

        this.headers = headers;
        this.columns = columns;
        this.rows = rows;
    }
}
