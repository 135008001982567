import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'shared/shared.module';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ExamsAddModalComponent} from 'pages/modules/exams/components/modals/add-modal/add-modal.component';
import {ExamsComponent} from 'pages/modules/exams/components/exams/exams.component';
import {ExamsMonthComponent} from 'pages/modules/exams/components/exams/month/month.component';
import {ExamsMonthsComponent} from 'pages/modules/exams/components/exams/months/months.component';
import {ExamsExamPlansTableComponent} from 'pages/modules/exams/components/exams/exam-plans-table/exam-plans-table.component';
import {ExamsDetailComponent} from 'pages/modules/exams/components/exams-detail/exams-detail.component';
import {ExamsMakeComponent} from 'pages/modules/exams/components/exams-make/exams-make.component';
import {ExamsExamInformationComponent} from 'pages/modules/exams/components/exam-information/exam-information.component';
import {ExamsExamStudentsComponent} from 'pages/modules/exams/components/exam-students/exam-students.component';
import {ExamsExamCreatedByComponent} from 'pages/modules/exams/components/exam-created-by/exam-created-by.component';
import {ExamsExamExaminationResultComponent} from 'pages/modules/exams/components/exam-examination-result/exam-examination-result.component';
import {ExamsExamSwitchStudentComponent} from 'pages/modules/exams/components/exam-switch-student/exam-switch-student.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ExamsDeleteModalComponent} from 'pages/modules/exams/components/modals/delete-modal/delete-modal.component';
import {ExamsResultComponent} from 'pages/modules/exams/components/exams-result/exams-result.component';
import {SecurityModule} from 'security/security.module';
import {ExamsExamCheckComponent} from 'pages/modules/exams/components/exam-check/exam-check.component';
import {ExamsExamCheckQuestionModule} from 'pages/modules/exams/components/exam-check/question/question.module';
import {ExamsExamScoreComponent} from 'pages/modules/exams/components/exam-score/exam-score.component';
import {DocumentPageModule} from 'pages/modules/document/document.module';
import {DocumentModule} from 'document/document.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        NgxDatatableModule,
        NgbTooltipModule,
        RouterModule,
        InfiniteScrollModule,
        SecurityModule,
        ExamsExamCheckQuestionModule,
        DocumentPageModule,
        DocumentModule,
    ],
    declarations: [
        ExamsComponent,
        ExamsDetailComponent,
        ExamsMakeComponent,
        ExamsResultComponent,
        ExamsAddModalComponent,
        ExamsDeleteModalComponent,
        ExamsMonthsComponent,
        ExamsMonthComponent,
        ExamsExamPlansTableComponent,
        ExamsExamInformationComponent,
        ExamsExamStudentsComponent,
        ExamsExamCreatedByComponent,
        ExamsExamExaminationResultComponent,
        ExamsExamSwitchStudentComponent,
        ExamsExamCheckComponent,
        ExamsExamScoreComponent,
    ],
    exports: [
        ExamsComponent,
        ExamsDetailComponent,
        ExamsMakeComponent,
        ExamsResultComponent,
        ExamsAddModalComponent,
    ],
    entryComponents: [
        ExamsAddModalComponent,
        ExamsDeleteModalComponent,
    ]
})
export class ExamsModule {
}
