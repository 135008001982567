import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {Observable} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {RouteService} from 'routing/services/route/route.service';
import {Router} from '@angular/router';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {SearchParametersEnum} from 'pages/modules/search/enums/search-parameters.enum';

@Injectable()
export class SearchService {
    constructor(
        private api: ApiService,
        private routeService: RouteService,
        private router: Router,
    ) {
    }

    public globalSearch(query: string): void {
        const queryParams = {};
        queryParams[SearchParametersEnum.Query] = query;

        this.router.navigate([this.routeService.getRouteWithPublisher(RoutesEnum.Search)], {
            queryParams: queryParams,
        });


    }

    public search(query: string, offset: number, limit: number, type: string|null = null): Observable<ApiSearchInterface> {
        const searchParams = new URLSearchParams();

        searchParams.append('q', query);
        searchParams.append('offset', offset.toString());
        searchParams.append('limit', limit.toString());

        if (type !== null) {
            searchParams.append('entity', type);
        }

        return this.api.get(ApiEndpointEnum.Search, searchParams);
    }
}
