import {AnalyticsEventInterface} from 'services/analytics/interfaces/event.interface';
import {AnalyticsEventCategoryEnum} from 'services/analytics/enums/event-category.enum';
import {AnalyticsEventActionEnum} from 'services/analytics/enums/event-action.enum';

export class AnalyticsEventModel implements AnalyticsEventInterface {

    public constructor(
        public eventCategory: AnalyticsEventCategoryEnum,
        public eventAction: AnalyticsEventActionEnum,
        public eventValue?: any,
        public eventLabel?: any,
    ) {}

}
