import {ProgressChapterInterface} from 'pages/modules/progress/interfaces/chapter.interface';
import {ProgressBookInterface} from 'pages/modules/progress/interfaces/book.interface';

export class ProgressChapterModel implements ProgressChapterInterface {

    constructor(
        public id: number,
        public title: string,
        public book: ProgressBookInterface,
        public dpsid?: string,
    ) {
    }

}
