import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from 'shared/shared.module';
import {DocumentModule} from 'document/document.module';
import {ContentExamNavigationComponent} from 'pages/modules/content-exam/components/navigation/navigation.component';
import {ContentExamNavigationAttemptComponent} from 'pages/modules/content-exam/components/navigation-attempt/navigation-attempt.component';
import {ContentExamResultAssignmentComponent} from 'pages/modules/content-exam/components/result-assignment/result-assignment.component';
import {ContentExamResultComponent} from 'pages/modules/content-exam/components/result/result.component';
import {ContentExamModalStartComponent} from 'pages/modules/content-exam/components/modal-start/modal-start.component';
import {ContentExamModalResultsComponent} from 'pages/modules/content-exam/components/modal-results/modal-results.component';
import {ContentExamPageComponent} from 'pages/modules/content-exam/components/page/page.component';
import {ExamStateService} from 'pages/modules/content-exam/service/exam-state.service';
import {ContentExamPageDocumentComponent} from 'pages/modules/content-exam/components/page-document/page-document.component';
import {SecurityModule} from 'security/security.module';

@NgModule({
    imports: [
        SharedModule,
        DocumentModule,
        CommonModule,
        SecurityModule
    ],
    declarations: [
        ContentExamNavigationComponent,
        ContentExamNavigationAttemptComponent,
        ContentExamResultAssignmentComponent,
        ContentExamResultComponent,
        ContentExamModalStartComponent,
        ContentExamModalResultsComponent,
        ContentExamPageComponent,
        ContentExamPageDocumentComponent,
    ],
    exports: [
        ContentExamModalStartComponent,
        ContentExamModalResultsComponent,
        ContentExamPageComponent,
    ],
    providers: [
        ExamStateService,
    ],
    entryComponents: [
        ContentExamModalStartComponent,
        ContentExamModalResultsComponent,
    ],
})
export class ContentExamModule {
}
