import {RoleEnum} from 'enums/role.enum';
import {GroupsModulePersonInterface} from 'pages/modules/groups/interfaces/person.interface';

export class GroupsModulePersonModel implements GroupsModulePersonInterface {
    constructor(
        public id: number,
        public name: string,
        public firstName?: string,
        public lastName?: string,
        public middleName?: string,
        public role?: RoleEnum,
    ) {
    }
}
