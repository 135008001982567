import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';
import {SortEnum} from 'enums/sort.enum';
import {IconEnum} from 'enums/icon.enum';

@Component({
    selector: 'app-groups-group-column-header-component',
    templateUrl: 'group-column-header.component.html',
})
export class GroupsGroupColumnHeaderComponent implements OnInit {

    @Input()
    public title: string;

    @Output()
    public sorting: EventEmitter<SortEnum> = new EventEmitter();

    @Input()
    public allowedSorting: boolean;

    public sortToggle: boolean;

    public classHelper: ClassHelper = new ClassHelper();

    get sortDirection(): SortEnum {
        return this.sortToggle ? SortEnum.Asc : SortEnum.Desc;
    }

    get icon(): string {
        switch (this.sortDirection) {
            case SortEnum.Asc:
                return IconEnum.SortAz;
            case SortEnum.Desc:
                return IconEnum.SortZa;
        }
    }

    public ngOnInit(): void {
        if (!this.allowedSorting) {
            this.classHelper.addClass('group__column-header--no-sorting');
        }
    }

    public handleSort(): void {
        if (!this.allowedSorting) {
            return;
        }

        this.sortToggle = !this.sortToggle;
        this.sorting.emit(this.sortDirection);
    }
}
