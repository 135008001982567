import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {Observable} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {DocumentTypeEnum} from 'enums/document-type.enum';
import {ProgressStatusFilterEnum} from 'shared/components/status-filter/enum/progress-status-filter.enum';

@Injectable()
export class ProgressService {

    constructor(
        private apiRequestService: ApiService,
    ) {
    }

    public retrieveOverview(
        bookId: number,
        chapterId?: number,
        groupId?: number,
        studentId?: number,
        search?: string,
    ): Observable<ApiProgressOverviewInterface> {
        const params = new URLSearchParams();

        params.append('book', bookId ? bookId.toString() : '0');

        if (chapterId) {
            params.append('chapter', chapterId.toString());
        }

        if (search) {
            params.append('search', search);
        }

        if (groupId) {
            params.append('group', groupId.toString());

            if (studentId) {
                params.append('student', studentId.toString());
            }
        }

        return this.apiRequestService.get<ApiProgressOverviewInterface>(ApiEndpointEnum.ProgressOverview, params);
    }

    public retrieveForTeacher(
        bookId: number,
        chapterId: number,
        groupId?: number,
        studentId?: number,
        search?: string,
        includeTasks?: number,
    ): Observable<Array<ApiProgressInterface>> {
        const params = new URLSearchParams();

        params.append('book', bookId.toString());
        params.append('chapter', chapterId.toString());

        if (search && search.trim().length > 0) {
            params.append('search', search.trim());
        }

        if (groupId && studentId) {
            const userGroup = {
                groupId: groupId,
                userId: studentId,
            };

            params.append('user_groups', JSON.stringify([userGroup]));
        } else if (groupId) {
            params.append('groups', JSON.stringify([groupId]));
        }

        if (includeTasks) {
            params.append('includeTasks', includeTasks.toString());
        }

        return this.apiRequestService.get<Array<ApiProgressInterface>>(ApiEndpointEnum.ProgressTeacher, params);
    }

    public retrieveForStudent(
        bookId: number,
        groupId?: number,
        chapterId?: number,
        search?: string,
        includeTasks?: number,
    ): Observable<Array<ApiProgressForStudentInterface>> {
        const params = new URLSearchParams();

        if (bookId) {
            params.append('book', bookId.toString());
        }

        if (groupId) {
            params.append('group', groupId.toString());
        }

        if (chapterId) {
            params.append('chapter', chapterId.toString());
        }

        if (search) {
            params.append('search', search);
        }

        if (includeTasks) {
            params.append('includeTasks', includeTasks.toString());
        }

        return this.apiRequestService.get<Array<ApiProgressForStudentInterface>>(ApiEndpointEnum.ProgressStudent, params);
    }

    public getProgressDownloadLink(type: DocumentTypeEnum, isForTeacher: boolean, groupId: number, studentId: number, bookId: number, selectedStatus: string | ProgressStatusFilterEnum): string {
        const params: URLSearchParams = new URLSearchParams([['interface', 'new'], ['status', selectedStatus.toString()]]);

        switch (type) {
            case DocumentTypeEnum.Pdf:
                return isForTeacher
                    ? this.getGroupDocumentLink(ApiEndpointEnum.ProgressDownloadGroupPdf, groupId, bookId, params)
                    : this.getStudentDocumentLink(ApiEndpointEnum.ProgressDownloadStudentPdf, groupId, studentId, bookId, params);

            case DocumentTypeEnum.Excel:
                return isForTeacher
                    ? this.getGroupDocumentLink(ApiEndpointEnum.ProgressDownloadGroupExcel, groupId, bookId, params)
                    : this.getStudentDocumentLink(ApiEndpointEnum.ProgressDownloadStudentExcel, groupId, studentId, bookId, params);
        }
    }

    protected getGroupDocumentLink(apiRoute: ApiEndpointEnum, groupId: number, bookId: number, queryParams?: URLSearchParams): string {
        return this.apiRequestService.buildUrlWithParams(apiRoute, new Map([
            ['groupId', groupId.toString()],
            ['bookId', bookId.toString()],
        ]), queryParams);
    }

    protected getStudentDocumentLink(apiRoute: ApiEndpointEnum, groupId: number, studentId: number, bookId: number, queryParams?: URLSearchParams): string {
        return this.apiRequestService.buildUrlWithParams(apiRoute, new Map([
            ['studentId', studentId.toString()],
            ['bookId', bookId.toString()],
            ['groupId', groupId ? groupId.toString() : null]
        ]), queryParams);
    }
}
