import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {HorizontalBarIconClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-icon-classes.enum';

@Component({
    selector: 'app-exams-exam-check',
    templateUrl: 'exam-check.component.html',
})
export class ExamsExamCheckComponent implements OnChanges {

    public readonly rowClasses = GridTableRowClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly horizontalBarIconClasses = HorizontalBarIconClassesEnum;

    public readonly iconEnum = IconEnum;

    @Input()
    public examPlan: ApiExamPlanDetailsInterface;

    @Input()
    public examResult: ApiExamUserResultInterface;

    @Input()
    public examCheck: ApiExamCheckInterface;

    @Input()
    public examCheckCurrentAnswer: ApiExamOpenAnswerInterface;

    @Output()
    public examCheckCurrentAnswerChange: EventEmitter<ApiExamOpenAnswerInterface> = new EventEmitter();

    public questionIndex: number = 0;

    public questionTotal: number = 0;

    public ngOnChanges(changes: SimpleChanges): void {
        if (!this.examCheck || !this.examCheckCurrentAnswer) {
            return;
        }

        this.questionIndex = 0;
        this.questionTotal = this.examCheck.items.length;

        for (let i = 0; i < this.questionTotal; i++) {
            if (this.examCheck.items[i].id === this.examCheckCurrentAnswer.id) {
                this.questionIndex = i;
            }
        }
    }

    public navigatePrevious(): void {
        if (this.questionIndex === 0) {
            return;
        }

        this.questionIndex--;

        this.setCurrentAnswer();
    }

    public navigateNext(): void {
        if (this.questionIndex === (this.questionTotal - 1)) {
            return;
        }

        this.questionIndex++;

        this.setCurrentAnswer();
    }

    private setCurrentAnswer(): void {
        this.examCheckCurrentAnswer = this.examCheck.items[this.questionIndex];
        this.examCheckCurrentAnswerChange.emit(this.examCheckCurrentAnswer);
    }
}
