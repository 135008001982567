import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ResultCodeEnum} from 'shared/components/result-cell/enums/result-code.enum';

@Injectable()
export class AutoCheckService {

    private autoCheckAnswers$: BehaviorSubject<ApiDocumentAssignmentInterface> = new BehaviorSubject<ApiDocumentAssignmentInterface>(null);
    private autoCheckResult$: BehaviorSubject<ResultCodeEnum> = new BehaviorSubject<ResultCodeEnum>(null);
    private scoreModel$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public setAutoCheckAnswers(assignment: ApiDocumentAssignmentInterface): void {
        this.autoCheckAnswers$.next(assignment);
    }

    public setAutoCheckResult(examinationResult: ResultCodeEnum): void {
        if (examinationResult === ResultCodeEnum.ResultRightWrongRight || examinationResult === ResultCodeEnum.ResultRightWrongWrong) {
            this.autoCheckResult$.next(examinationResult);
        }
    }

    public setScoreModel(scoreModel: string): void {
        this.scoreModel$.next(scoreModel);
    }

    public getAutoCheckAnswerForFieldId(assignmentId: string, fieldId: number): Observable<any> {
        return this.autoCheckAnswers$.pipe(
            filter(assignment => !!assignment),
            filter((assignment) => assignment.assignment_id === assignmentId),
            map(assignment => {
                return assignment && assignment.fields.find((field) => field.field_id === fieldId);
            }),
            filter(field => !!field.correct_answer),
            map(field => field.correct_answer)
        );
    }

    public getAutoCheckResult(): Observable<ResultCodeEnum> {
        return this.autoCheckResult$.asObservable();
    }

    public getScoreModel(): Observable<string> {
        return this.scoreModel$.asObservable();
    }

    public resetAutoCheckAnswer(): void {
        this.autoCheckAnswers$.next(null);
        this.autoCheckResult$.next(null);
    }

}
