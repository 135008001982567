import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ModalService} from 'core/services/modal/modal.service';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';
import {TasksGoToAssignmentsModel} from 'pages/modules/tasks/models/go-to-assignments.model';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {RouteService} from 'routing/services/route/route.service';
import {UserService} from 'security/services/user/user.service';
import {AnalyticsService} from 'services/analytics/analytics.service';
import {AnalyticsDimensionEnum} from 'services/analytics/enums/dimension.enum';
import {AnalyticsEventAssignmentGradeModel} from 'services/analytics/models/assignment-grade.model';
import {HomeworkService} from 'services/homework/homework.service';
import {ResultCodeEnum} from 'shared/components/result-cell/enums/result-code.enum';
import {ScoringStatusEnum} from 'shared/components/scoring/enums/status.enum';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {TaskStateService} from 'pages/modules/tasks/service/task-state.service';
import {TaskService} from 'services/task/task.service';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-tasks-score',
    templateUrl: 'score.component.html',
})
export class TasksScoreComponent implements OnChanges {

    public readonly tableClasses = GridTableClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly examineOptionsEnum = ExamineOptionsEnum;

    public readonly statusEnum = ScoringStatusEnum;

    public readonly resultCodeEnum = ResultCodeEnum;

    public readonly actionGoToAssignment: ActionButtonInterface = new TasksGoToAssignmentsModel(false);

    @Input()
    public isGFAnswerModel: boolean;

    @Input()
    public isAutoCheck: boolean;

    @Input()
    public activeTask: ApiTaskDetailsInterface;

    @Input()
    public homework: ApiTaskDetailsHomeworkInterface;

    @Input()
    public isAlgebrakit: boolean;

    public changingScore: boolean = false;

    public archiveModeActive = environment.archiveModeActive;

    public constructor(
        private tasksService: TaskStateService,
        private toastService: ToastrService,
        private analyticsService: AnalyticsService,
        private homeworkService: HomeworkService,
        private modalService: ModalService,
        private routeService: RouteService,
        private userService: UserService,
        private taskService: TaskService
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.actionGoToAssignment.routerLink = this.activeTask.assignment
            ? this.routeService.getRouteWithPublisher(RoutesEnum.Document, new Map([
                ['bookId', String(this.activeTask.book)],
                ['documentDpsid', this.activeTask.assignment.dpsid],
            ]))
            : null;
    }

    public handleToggleChangeScore(): void {
        this.changingScore = !this.changingScore;
    }

    public onExamineScoreChange(score: ResultCodeEnum): void {
        if (score === ResultCodeEnum.ResultRetry) {
            this.handleResitTaskAction();

            return;
        }

        this.analyticsService.dimension(AnalyticsDimensionEnum.AssignmentTitle, this.homework.title);
        this.analyticsService.event(new AnalyticsEventAssignmentGradeModel());

        this.homeworkService.handInHomework(this.homework.id, score)
            .subscribe(() => {
                this.toastService.success('Beoordeling is verzonden.');

                this.changingScore = false;
                this.homework.examination_result = score;
                this.homework.examined = moment().unix();

                for (const homework of this.activeTask.homework) {
                    if (homework.id !== this.homework.id) {
                        continue;
                    }

                    homework.examination_result = score;
                    homework.examined = moment().unix();
                }

                this.tasksService.setActiveTaskScoreChange(true);
            }, () => {
                this.toastService.error('Kon beoordeling niet verzenden.');
            });
    }

    public teacherCanHandInOverride(): boolean {
        return this.userService.getCurrentUserId() !== this.activeTask.assigned_to_id || this.activeTask.is_taskgrouptask;
    }

    public teacherHandInOverride(): void {
        this.homeworkService.handInOverride(this.activeTask.id)
            .subscribe(() => {
                this.toastService.success('Taak is anders ingeleverd.');

                this.tasksService.setActiveTask(this.activeTask, false);
            }, () => {
                this.toastService.error('Kon niet anders inleveren.');
            });
    }

    private handleResitTaskAction(): void {
        const modalInstance = this.modalService.taskResit(this.activeTask);

        modalInstance.taskAddEvent.subscribe(() => this.tasksService.setActiveTask(this.activeTask, false));
    }

    public navigateToTask(taskId: number): void {
        this.taskService.navigateToDetails(taskId);
    }
}
