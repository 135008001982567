import {Injectable} from '@angular/core';
import {PublisherService} from 'services/publisher/publisher.service';

@Injectable()
export class FaviconService {

    private readonly defaultFavicon = 'favicon.ico';

    constructor(
        private publisherService: PublisherService,
    ) {
        this.publisherService.onPublisherChange.subscribe(() => this.handlePublisherChanged());
    }

    private handlePublisherChanged(): void {
        let favicon = this.getFavicon();

        if (!favicon) {
            favicon = this.defaultFavicon;
        }

        this.setFavicon(favicon);
    }

    private getFavicon(): string|null {
        const publisher = this.publisherService.getActivePublisher();

        if (!publisher) {
            return null;
        }

        return publisher.favicon;
    }

    private setFavicon(favicon: string): void {
        const head = document.head || document.getElementsByTagName('head')[0];

        let iconElement: HTMLLinkElement = head.querySelector('[rel="icon"]');

        if (!iconElement) {
            iconElement = document.createElement('link');
            iconElement.rel = 'icon';
            iconElement.type = 'image/x-icon';

            head.appendChild(iconElement);
        }

        if (iconElement.href === favicon) {
            return;
        }

        iconElement.href = favicon;
    }
}
