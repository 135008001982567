export enum MessageCenterFilterEnum {

    Homework = 'homework',

    Resource = 'resource',

    Exam = 'exam',

    Goal = 'goal',

    Message = 'message',

}
