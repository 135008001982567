export enum CollapsibleClassEnum {

    Bordered = 'collapsible--bordered',

    GroupComponent = 'collapsible--group-component',

    Expand = 'collapsible--expand',

    HasLogo = 'collapsible--logo',

    MarginBottom = 'collapsible--margin-bottom',

    ProgressRow = 'collapsible--progress-row',

    TasksTask = 'collapsible--tasks-task',

    HeaderBorderNone = 'collapsible__header--border-none',

    TextColorPurple = 'collapsible--text-24boost-purple',

    TitlePaddingLeft = 'collapsible__title--padding-left',

    Progress = 'collapsible--progress',
}
