import {Component, Input} from '@angular/core';
import {AlertMessageInterface} from 'shared/components/alert-message/interfaces/alertMessageInterface';

@Component({
    selector: 'app-alert-message',
    templateUrl: './alert-message.component.html',
})
export class AlertMessageComponent {

    @Input()
    public alertMessage: AlertMessageInterface;

}
