import {Injectable} from '@angular/core';
import {ContentSelectModel} from 'shared/modules/content-select/models/content-select.model';
import {ContentSelectOrElseModel} from 'shared/modules/content-select/models/or-else.model';

@Injectable()
export class ContentSelectModelFactory {

    public fromApiBook(apiBook: ApiBookInterface, subItems: boolean = false): ContentSelectModel {
        const contentSelectModel = new ContentSelectModel(apiBook.id.toString(), apiBook.title);

        contentSelectModel.setDemo(apiBook.demo);
        contentSelectModel.setLicenseRequested(apiBook.licenseRequested);

        if (subItems && apiBook.chapters.length) {
            contentSelectModel.setChildren(this.fromApiBooksChapters(apiBook.chapters, apiBook.id));
        }

        return contentSelectModel;
    }

    public fromApiBooksArray(apiBooks: Array<ApiBookInterface>, subItems: boolean = false): Array<ContentSelectModel> {
        const models: Array<ContentSelectModel> = [];

        apiBooks.forEach((apiBook: ApiBookInterface) => {
            models.push(this.fromApiBook(apiBook, subItems));
        });

        return models;
    }

    public fromApiBooks(apiBooks: ApiBooksInterface, subItems: boolean = false): Array<ContentSelectModel> {
        const models: Array<ContentSelectModel> = [];

        apiBooks.data.forEach((apiBook: ApiBookInterface) => {
            models.push(this.fromApiBook(apiBook, subItems));
        });

        return models;
    }

    public fromApiBooksChapters(apiBookChapters: Array<ApiBookChapterInterface>, bookId: number): Array<ContentSelectModel> {
        const chapters: Array<ContentSelectModel> = [];

        apiBookChapters.forEach((apiBookChapter: ApiBookChapterInterface) => {
            const contentSwitchModel = new ContentSelectModel(apiBookChapter.id.toString(), apiBookChapter.title);
            contentSwitchModel.parentId = bookId.toString();

            chapters.push(contentSwitchModel);
        });

        return chapters;
    }

    public fromApiAssignment(apiAssignment: ApiAssignmentInterface): ContentSelectModel {
        return new ContentSelectModel(apiAssignment.id.toString(), apiAssignment.title);
    }

    public fromApiAssignments(apiAssignments: Array<ApiAssignmentInterface>): Array<ContentSelectModel> {
        const models: Array<ContentSelectModel> = [];

        apiAssignments.forEach((apiAssignment: ApiAssignmentInterface) => {
            models.push(this.fromApiAssignment(apiAssignment));
        });

        return models;
    }

    public fromApiUser(apiUser: ApiUserInterface): ContentSelectModel {
        return new ContentSelectModel(apiUser.id.toString(), apiUser.name);
    }

    public fromApiUserArray(apiUsers: Array<ApiUserInterface>): Array<ContentSelectModel> {
        const models: Array<ContentSelectModel> = [];

        apiUsers.forEach((apiUser: ApiUserInterface) => {
            models.push(this.fromApiUser(apiUser));
        });

        return models;
    }

    public fromApiOrganization(apiOrganisation: ApiOrganisationInterface): ContentSelectModel {
        return new ContentSelectModel(apiOrganisation.id.toString(), apiOrganisation.name);
    }

    public fromApiOrganizationsArray(apiOrganizations: Array<ApiOrganisationInterface>): Array<ContentSelectOrElseModel> {
        const models: Array<ContentSelectOrElseModel> = [];

        apiOrganizations.forEach((apiOrganization: ApiOrganisationInterface) => {
            models.push(this.fromApiOrganization(apiOrganization));
        });

        return models;
    }
}
