import {Directive, OnInit} from '@angular/core';

declare var MathJax: any;

@Directive({
    selector: 'math',
})
export class DocumentMathDirective implements OnInit {
    public ngOnInit(): void {
        MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
    }
}
