import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {DropdownClassEnum} from 'shared/modules/dropdown/enums/dropdown-class.enum';
import {DropdownComponent} from 'shared/modules/dropdown/components/dropdown.component';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {ContentSelectItemInterface} from 'shared/modules/content-select/interfaces/select-item.interface';
import {ContentSelectChevronClassModifiersEnum} from 'app/modules/shared/modules/content-select/enums/class-modifiers-chevron.enum';
import {ContentSelectClassModifiersEnum} from 'app/modules/shared/modules/content-select/enums/class-modifiers.enum';
import {ContentSelectItemComponent} from 'shared/modules/content-select/components/item/item.component';
import {SearchInterface} from 'shared/modules/search/interfaces/search.interface';
import {FormClassModifiersEnum} from 'enums/form-class-modifiers.enum';
import {SearchPrefixedDelayModel} from 'shared/models/search/prefixed-delayed.model';
import * as lodash from 'lodash';
import {ContentSelectItemEventInterface} from 'shared/modules/content-select/interfaces/item-event.interface';
import {ClassHelper} from 'helpers/dom/class.helper';

@Component({
    selector: 'app-content-select-component',
    templateUrl: 'content-select.component.html',
})
export class ContentSelectComponent implements OnInit, OnChanges {

    public readonly search: SearchInterface = new SearchPrefixedDelayModel('Zoeken...', [FormClassModifiersEnum.NoBorderRadius]);

    @ViewChild(DropdownComponent, {static: true})
    public dropdownComponent: DropdownComponent;

    @ViewChildren(ContentSelectItemComponent)
    public itemComponents: Array<ContentSelectItemComponent>;

    @Output()
    public contentSelectEvent: EventEmitter<Array<ContentSelectItemInterface>> = new EventEmitter();

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public clickOutSide: boolean = false;

    @Input()
    public items: Array<ContentSelectItemInterface> = [];

    @Input()
    public placeholder: string = 'Maak een selectie...';

    @Input()
    public iconBox: IconBoxInterface;

    @Input()
    public multiSelect: boolean = false;

    @Input()
    public activeItem: ContentSelectItemInterface;

    @Input()
    public showFooter: boolean = false;

    @Input()
    public max: number = null;

    @Input()
    public footerButtons: Array<ActionButtonInterface> = [];

    @Input()
    public cancelButtonText: string = 'Annuleren';

    @Input()
    public applyButtonText: string = 'Toepassen';

    @Input()
    public disabled: boolean = false;

    @Input()
    public displaySearch: boolean = true;

    public title?: string;

    public classHelper: ClassHelper = new ClassHelper();

    public maxSelected: boolean = false;

    public itemsDisplayed: Array<ContentSelectItemInterface> = [];

    private applied: boolean = false;

    private searchValue: string;

    private activeItemsSnapshot: string;

    public ngOnInit(): void {
        const className: ContentSelectChevronClassModifiersEnum = this.dropdownComponent.isVisible()
            ? ContentSelectChevronClassModifiersEnum.Rotate270
            : ContentSelectChevronClassModifiersEnum.Rotate90;

        this.classHelper.addClass(className, 'chevron');
        this.classHelper.addClass(DropdownClassEnum.ContentSwitch, 'dropdown');
        this.classHelper.addClasses(this.classModifiers);
        if (this.showFooter) {
            this.classHelper.addClass(ContentSelectClassModifiersEnum.HasFooter);
        }

        this.dropdownComponent.toggleEvent.subscribe((toggle: boolean) => this.handleDropdownToggleEvent(toggle));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.items) { // Create items clone when items changed
            this.updateDisplayedItems();
        }

        if (changes.activeItem) {
            const activeItem: ContentSelectItemInterface = changes.activeItem.currentValue;

            this.title = activeItem ? activeItem.title : undefined;
            this.createSelectedItemsSnapshot();
        }

        this.toggleDisabled();
    }

    public toggleDropdown(event?: MouseEvent): void {
        if (event instanceof MouseEvent) {
            event.stopPropagation();
        }

        if (this.isDisabled()) {
            return;
        }

        this.dropdownComponent.toggle(!this.dropdownComponent.isVisible());
    }

    public handleApplyButtonClick(event: MouseEvent): void {
        this.applied = true;
        this.toggleDropdown(event);
    }

    /**
     * Handles events triggered by items, when selecting child items the parent item is the first to pass here
     */
    public handleItemEvent(itemEvent: ContentSelectItemEventInterface): void {
        if (this.isDisabled()) {
            return;
        }

        const displayItem: ContentSelectItemInterface = itemEvent.item;
        const activeState: boolean = displayItem.active;
        const originalItem: ContentSelectItemInterface = this.findOriginalItem(displayItem, this.items);

        if (!this.multiSelect) {
            this.handleSingleSelectMode(originalItem, activeState);
        } else {
            this.handleMultiSelectMode(originalItem, itemEvent.childEvent);
        }

        originalItem.active = activeState; // Update active state

        if (!this.multiSelect && !itemEvent.childEvent) {
            this.toggleDropdown();
        }
    }

    public setSearchValue(value: string): void {
        this.searchValue = value.toLowerCase();
        this.updateDisplayItems();
    }

    public updateDisplayedItems(): void {
        this.itemsDisplayed = this.getItemsClone();
    }

    /**
     * Handles single select mode, this mode ensures only one item is emitted
     */
    private handleSingleSelectMode(originalItem: ContentSelectItemInterface, activeState: boolean): void {
        this.updateCollectionActiveStates(false, this.items); // Toggle all items to false due to single select mode

        this.activeItem = activeState ? originalItem : null;
        this.title = activeState ? this.activeItem.title : undefined;
    }

    /**
     * Handles multi select mode, this mode ensures that only parent items are emitted
     */
    private handleMultiSelectMode(originalItem: ContentSelectItemInterface, childClickEvent: boolean): void {
        // When it's not a child click event, ensure item's children active states are false
        if (!childClickEvent) {
            this.updateCollectionActiveStates(false, originalItem.children);
        }


        this.maxSelected = this.max !== null && this.max <= this.getActiveItems(this.itemsDisplayed).length;
    }

    /**
     * Updates displayed items with according checked states and if filtered by search or not
     *
     * TODO: make recursive to support multiple levels, for now goes max 2 levels deep
     */
    private updateDisplayItems(): void {
        this.updateDisplayedItems();

        this.itemsDisplayed = this.itemsDisplayed.filter((item: ContentSelectItemInterface) => {
            if (!this.searchValue) {
                return true;
            }

            const matchingTitle = item.title.toLowerCase().includes(this.searchValue);
            let hasMatchingChildren = false;

            item.children.forEach(child => {
                child.visible = child.title.toLowerCase().includes(this.searchValue);

                if (child.visible) {
                    hasMatchingChildren = true;
                    item.expanded = true;
                }
            });

            return matchingTitle || hasMatchingChildren;
        });
    }

    private updateCollectionActiveStates(
        state: boolean,
        collection: Array<ContentSelectItemInterface>,
        filter?: ContentSelectItemInterface,
    ): void {
        const filtered: Array<ContentSelectItemInterface> = filter !== undefined
            ? collection.filter(originalItem => originalItem.id !== filter.id)
            : collection;

        filtered.forEach((filteredItem: ContentSelectItemInterface) => {
            filteredItem.active = state;

            if (filteredItem.children.length > 0) {
                this.updateCollectionActiveStates(state, filteredItem.children, filter);
            }
        });
    }

    private findOriginalItem(searchedItem: ContentSelectItemInterface, collection?: Array<ContentSelectItemInterface>): ContentSelectItemInterface | null {
        let foundItem: ContentSelectItemInterface = null;

        collection.some((item: ContentSelectItemInterface) => {
            if (item.id === searchedItem.id && item.parentId === searchedItem.parentId) {
                foundItem = item;
            } else if (item.children.length > 0) {
                foundItem = this.findOriginalItem(searchedItem, item.children);
            }

            return foundItem !== null;
        });

        return foundItem;
    }

    private handleDropdownToggleEvent(toggle: boolean): void {
        this.classHelper.toggleClass(ContentSelectChevronClassModifiersEnum.Rotate90, 'chevron');
        this.classHelper.toggleClass(ContentSelectChevronClassModifiersEnum.Rotate270, 'chevron');
        this.classHelper.toggleClass(ContentSelectClassModifiersEnum.Expanded);

        // Prevent any actions when footer is displayed and/or nothing was applied or changed
        if (this.showFooter && !this.applied && !toggle) {
            return;
        }

        if (!toggle) {
            this.emitActiveItems(); // TODO: Move...
            this.applied = false;
        } else {
            this.updateDisplayItems();
        }
    }

    private getItemsClone(): Array<ContentSelectItemInterface> {
        return lodash.cloneDeep(this.items);
    }

    private emitActiveItems(): void {
        const activeItems: Array<ContentSelectItemInterface> = this.getActiveItems(this.items);
        const stringifiedItems: string = JSON.stringify(activeItems);

        // No need to emit when results are the same
        if (this.activeItemsSnapshot === stringifiedItems) {
            return;
        }

        this.activeItemsSnapshot = stringifiedItems;
        this.contentSelectEvent.emit(activeItems);
    }

    private getActiveItems(items: Array<ContentSelectItemInterface>): Array<ContentSelectItemInterface> {
        let activeItems: Array<ContentSelectItemInterface> = [];

        items.forEach((item: ContentSelectItemInterface) => {
            if (!item.parentActive && item.active) {
                activeItems.push(item); // Add current item
            }

            if (item.children !== undefined && item.children.length > 0) {
                activeItems = [...activeItems, ...this.getActiveItems(item.children)];
            }
        });

        return activeItems;
    }

    private toggleDisabled(): void {
        this.classHelper.toggleClassByBoolean(ContentSelectClassModifiersEnum.Disabled, this.isDisabled());
    }

    private isDisabled(): boolean {
        return this.disabled || this.items.length <= 0;
    }

    private createSelectedItemsSnapshot(): void {
        this.activeItemsSnapshot = JSON.stringify(this.getActiveItems(this.items));
    }
}
