export enum HelpPageEnum {

    Welcome = 'welcome',

    Books = 'books',

    Tasks = 'tasks',

    Exams = 'exams',

    Progress = 'progress',

    Groups = 'groups',

    Question = 'help',
}
