import {Component, EventEmitter, OnInit} from '@angular/core';
import {GroupsModuleGroupInterface} from 'pages/modules/groups/interfaces/group.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GroupsModuleActionsEnum} from 'pages/modules/groups/enums/group-actions.enum';
import {IconEnum} from 'enums/icon.enum';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';

@Component({
    selector: 'app-groups-actions-modal-component',
    templateUrl: 'action.component.html',
})
export class GroupsActionsModalComponent implements OnInit {

    public groupActionEvent: EventEmitter<string> = new EventEmitter();

    public group: GroupsModuleGroupInterface;

    public actions: Array<ActionButtonInterface> = [];

    constructor(private activeModal: NgbActiveModal) {
    }

    public handleAction(action: ActionButtonInterface): void {
        this.groupActionEvent.emit(action.id);
        this.close();
    }

    public ngOnInit(): void {
        this.actions.forEach((action: ActionButtonInterface) => {
            this.updateActionButton(action);
        });
    }

    private updateActionButton(action: ActionButtonInterface): void {
        switch (action.id) {
            case GroupsModuleActionsEnum.Edit:
                action.value = 'Groepsnaam wijzigen';
                break;
            case GroupsModuleActionsEnum.RenewCode:
                action.value = 'Nieuwe groepscode genereren';
                break;
            case GroupsModuleActionsEnum.Active:
                action.value = this.group.active ? 'Groep deactiveren' : 'Groep activeren';
                break;
        }
    }

    private close(): void {
        this.activeModal.dismiss();
    }
}
