import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxClose} from 'models/iconbox/close.model';

@Component({
    selector: 'app-content-exam-modal-results',
    templateUrl: 'modal-results.component.html'
})
export class ContentExamModalResultsComponent {

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorBlue]);

    constructor(private activeModal: NgbActiveModal) {
    }

    public close(): void {
        this.activeModal.dismiss();
    }

    public results(): void {
        this.activeModal.close();
    }
}
