import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {ApiService} from 'core/services/api/api.service';
import {ToastrService} from 'ngx-toastr';
import {share} from 'rxjs/operators';

@Injectable()
export class UploadService {
    public constructor(private apiService: ApiService, private toastrService: ToastrService) {
    }

    public upload(file: File, url?: string, queryParams?: URLSearchParams, urlProperties?: Map<string, string>, options?: any): Observable<ApiFileInterface> {
        const obs = new Observable((observer: Observer<ApiFileInterface>) => {
            this.apiService.uploadFile<ApiFileInterface>(file, url, queryParams, urlProperties, options)
                .subscribe((data: any) => {
                    this.handleError(data) ? observer.next(data) : observer.error(null);

                    observer.complete();
                }, (error: any) => {
                    this.handleError(error);

                    observer.error(null);
                    observer.complete();
                }, () => {
                    observer.complete();
                });
        });

        return obs.pipe(share());
    }

    private handleError(data: any): boolean {
        if (data['errors']) {
            if (data['errors'][0]['message'] === 'VALIDATION_NOT_A_VALID_MIMETYPE') {
                this.toastrService.error('Dit type bestand mag niet worden geupload.');

                return;
            }

            this.toastrService.error('Kon het bestand niet uploaden.');

            return false;
        } else {
            if (data.error && (data.error.msg === 'FILE_SIZE_TO_LARGE' || data.status === 413)) {
                this.toastrService.error('Bestand is te groot. Kies een bestand kleiner dan 10MB.');

                return;
            }
        }

        return true;
    }
}
