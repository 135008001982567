import {AfterViewInit, Component, ElementRef, HostBinding, HostListener, OnDestroy, ViewChild} from '@angular/core';
import {DocumentAnswerableComponent} from 'document/modules/abstract/answerable.component';
import {ClassHelper} from 'helpers/dom/class.helper';
import {DocumentHotspotClassEnum} from './enums/class.enum';
import {DocumentValueInterface} from 'document/modules/components/matching/interface/value.interface';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {Subject, Subscription} from 'rxjs';
import {Marker} from 'document/modules/components/hotspot/models/Marker.model';
import {take} from 'rxjs/operators';
import {AutoCheckService} from 'document/services/autocheck/auto-check.service';

@Component({
  selector: '.document-hotspot',
  templateUrl: 'hotspot.component.html',
})
export class DocumentHotspotComponent extends DocumentAnswerableComponent implements AfterViewInit, OnDestroy {

    @HostBinding('class')
    public elementClass: string = 'document__hotspot';

    @ViewChild('markercontainer', {static: true}) public markerContainer: ElementRef;

    @ViewChild('markercontainerinitial', {static: true}) public markerContainerInitial: ElementRef;

    @ViewChild('markercontainerhandedin', {static: true}) public markerContainerHandedIn: ElementRef;

    public classes: ClassHelper = new ClassHelper(DocumentHotspotClassEnum.HotSpotBlock);

    public id: string;

    public correctAnswers: Array<any> = [];

    public initialAnswers: Array<any>;

    public imageSrc: string;

    public savedMarkers: Array<Marker> = [];

    public markerOffset: number = 0;

    public markersChanged$: Subject<any> = new Subject();

    public hasAnswers: boolean = false;

    public options: any;

    protected matches: Array<DocumentValueInterface>;

    protected dataSet: DocumentDatasetOptionsInterface;

    private subscriptions: Array<Subscription> = [];

    public answerHidden: boolean;

    public autoScoreEnabled: boolean;

    public autoScoreAnswer: any;


    constructor(
        protected elementRef: ElementRef,
        protected answersService: DocumentAnswersService,
        protected showAnswersService: DocumentAnswersStateService,
        private autocheckService: AutoCheckService
    ) {
        super(elementRef, answersService, showAnswersService);

        this.id = `hotspot-${this.dataSet.assignmentid}-${this.dataSet.fieldid}`;
    }

    public ngAfterViewInit(): void {

        super.ngAfterViewInit();

        try {
            this.options = JSON.parse(this.dataSet.options);
            this.imageSrc = this.options.imagepath + this.options.image;
            this.autoScoreEnabled = true;
        } catch (e) {
            this.initializationFailed = true;
            console.error('Failed to JSON parse the options:', e);
        }

        const markerContainer = this.markerContainer.nativeElement;
        const markerContainerHandedIn = this.markerContainerHandedIn.nativeElement;

        // Possibly set by setAnswer - this renders existing hotspots, also for handed in.
        this.savedMarkers.forEach( (marker, index) => {
            const newMarker = this.createMarker(marker);
            markerContainer.appendChild(newMarker);

            const newMarker2 = this.createMarker(marker, false);
            markerContainerHandedIn.appendChild(newMarker2);
        });

        // Possibly set by initialAnswer - this renders existing hotspots
        if ( this.initialAnswerShown && this.initialAnswers ) {
            setTimeout(() => { // Wait one tick to make sure the nested container is rendered
                const markerContainerInitial = this.markerContainerInitial.nativeElement;
                this.initialAnswers.forEach((marker, index) => {
                    const newMarker = this.createMarker(marker, false);
                    markerContainerInitial.appendChild(newMarker);
                });
            }, 0);
        }

        this.markersChanged$.subscribe( () => {
            const payload = [];
            const currentMarkers = markerContainer.querySelectorAll('.' + DocumentHotspotClassEnum.HotSpotMarker);
            // Clear handed in container
            this.clearHandedInMarkers();
            currentMarkers.forEach( (marker: HTMLElement) => {
                const [top, left] = [parseFloat(marker.style.top), parseFloat(marker.style.left)];
                payload.push({top, left});

                // Also add marker to Handed-in container
                const newMarker = this.createMarker({top, left}, false);
                markerContainerHandedIn.appendChild(newMarker);

            });
            // Save
            this.saveAnswer(JSON.stringify(payload));

            // Update 'Antwoord model'
            this.savedMarkers = payload;

        });

        markerContainer.addEventListener('click', ({offsetX, offsetY, target}) => {

            const hitTarget = target as HTMLElement;
            if (hitTarget.classList.contains(DocumentHotspotClassEnum.HotSpotMarker)) {
                return;
            }

            // Convert pixel offsets to percentages
            const top = ((offsetY - this.markerOffset) / markerContainer.offsetHeight) * 100;
            const left = ((offsetX - this.markerOffset) / markerContainer.offsetWidth) * 100;

            // Create a new marker
            const newMarker = this.createMarker({top, left});

            // Append the new marker
            markerContainer.appendChild(newMarker);

            // Trigger a change
            this.markersChanged$.next();
        });
        this.setupAutoScoring();
    }

    private setupAutoScoring(): void {

        const assignmentId = this.dataSet.assignmentid;
        const fieldId = parseInt(this.dataSet.fieldid, 10);
        const assignment = this.answersService.getAssignment(assignmentId);
        this.autoScoreEnabled = assignment && assignment.auto_check;

        if (! this.autoScoreEnabled) { return; }

        this.subscriptions.push( this.autocheckService.getAutoCheckAnswerForFieldId(assignmentId, fieldId).pipe(take(1)).subscribe( autoCheckAnswer => {
            if (autoCheckAnswer) {
                try {
                    this.autoScoreAnswer = JSON.parse(autoCheckAnswer.replace(/\\/g, '')) as Array<{shape: string, coordinates: string}> ;
                } catch (error) {
                    console.warn('Could not parse Autocheck answers');
                }
            }
        }));

    }

    /* With the newer data format it would also be possible to not use a native DOM element for this but an Angular component with cdkDragDrop  */
    public createMarker({top, left}: Marker, draggable: boolean = true): HTMLElement {
        const newMarker = document.createElement('span');
        const numExistingMarkers = this.markerContainer.nativeElement.getElementsByClassName(DocumentHotspotClassEnum.HotSpotMarker).length;

        newMarker.classList.add(DocumentHotspotClassEnum.HotSpotMarker);
        newMarker.style.top = `${top}%`;
        newMarker.style.left = `${left}%`;
        newMarker.draggable = draggable;
        newMarker.ondragstart = this.dragStart;
        newMarker.ondragend = this.dragEnd;
        newMarker.id = `hotspot-marker-id-${this.id}-${numExistingMarkers}` ;

        return newMarker;
    }

    public deleteMarkers(): void {
        const container = this.markerContainer.nativeElement;
        while (container.firstChild) {
            container.firstChild.remove();
        }
        this.markersChanged$.next();
    }

    private clearHandedInMarkers(): void {
        const container = this.markerContainerHandedIn.nativeElement;
        if (!container) { return; }

        while (container.firstChild) {
            container.firstChild.remove();
        }
    }

    public dragStart(ev: DragEvent): void {
        ev.dataTransfer.effectAllowed = 'move';
        ev.dataTransfer.setData('id', ev.target['id']);
        const el = ev.target as HTMLElement;
        el.classList.add('drag-hide');

    }

    public dragEnd(ev: DragEvent): void {
        const el = ev.target as HTMLElement;
        el.classList.remove('drag-hide');

    }

    public allowDrop(ev: Event): void {
        ev.preventDefault();
    }

    public handleDrop(e: any): boolean {
        e.stopPropagation(); // stops the browser from redirecting.
        const markerId = e.dataTransfer.getData('id');
        const marker = document.getElementById(markerId);
        const container = e.target;

        const top = ((e.offsetY - this.markerOffset) / container.offsetHeight) * 100 + '%';
        const left = ((e.offsetX - this.markerOffset) / container.offsetWidth) * 100 + '%';
        marker.style.top = top;
        marker.style.left = left;

        // Trigger a change
        this.markersChanged$.next();

        return false;
    }

    protected setAnswer(answer: string): void {
        if (answer === undefined || !answer.length) {
            return;
        }

        const markers = this.getDecodedAnswer(answer);
        if (markers && markers.length) {
            this.savedMarkers = markers;
        }
    }

    @HostListener('window:resize')
    private onWindowResize(): void {}


    protected hideCorrectAnswer(): void {
        this.classes.removeClass(DocumentHotspotClassEnum.HotspotShowCorrectAnswer);
        this.answerHidden = true;
    }

    protected showCorrectAnswer(correctAnswer: string): void {

        let answerObj = null;

        try {
            answerObj = JSON.parse(correctAnswer.replace(/\\/g, ''));
        } catch (e) {
            console.warn('COULD NOT PARSE CORRECT ANSWER');
        }

        if (!answerObj) { return; }

        this.correctAnswers = answerObj;
        this.hasAnswers = true;
        this.classes.addClass(DocumentHotspotClassEnum.HotspotShowCorrectAnswer);
        this.answerHidden = false;

    }
    protected showInitialAnswer(initialAnswer: string): void {
        this.initialAnswers = JSON.parse(initialAnswer);
    }

    protected answerValid(): boolean {
        return true;
    }

    public ngOnDestroy(): void {
        this.autocheckService.resetAutoCheckAnswer();
        this.subscriptions.map(sub => sub.unsubscribe());
    }

}
