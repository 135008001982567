import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RadioBoxInterface} from 'shared/components/radio-box/interfaces/radio-box.interface';
import {RadioBoxEnum} from 'shared/components/radio-box/enum/radio-box.enum';
import {AddAttachmentPreferencesEnum} from 'shared/modals/add-attachments/add-attachment-preferences/enum/add-attachment-preferences.enum';

@Component({
    selector: 'app-add-attachment-preferences',
    templateUrl: 'add-attachment-preferences.component.html',
})
export class AddAttachmentPreferencesComponent {

    public readonly preferencesOptions: Array<RadioBoxInterface> = [
        {
            id: AddAttachmentPreferencesEnum.Local,
            value: 'Van deze computer',
        },
        {
            id: AddAttachmentPreferencesEnum.OneDrive,
            value: 'Vanuit OneDrive',
        }
    ];

    public classModifier = RadioBoxEnum ;

    @Input()
    public addAttachmentPreferences: AddAttachmentPreferencesEnum;

    @Output()
    public addAttachmentPreferencesChange: EventEmitter<AddAttachmentPreferencesEnum> = new EventEmitter();

    @Input()
    public disabled: boolean = false;

    public addAttachmentPreferencesUpdated(): void {
        this.addAttachmentPreferencesChange.emit(this.addAttachmentPreferences);
    }
}
