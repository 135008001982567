import {Component} from '@angular/core';
import {IconBoxClose} from 'models/iconbox/close.model';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-task-not-exist-modal',
    templateUrl: 'task-not-exist-modal.component.html',
})
export class TaskNotExistModalComponent {

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorTask]);

    constructor(private activeModal: NgbActiveModal) {
    }

    public close(): void {
        this.activeModal.dismiss();
    }

    public results(): void {
        this.activeModal.close();
    }
}
