export enum ScoringStatusEnum {

    None = 0,

    Open = 1,

    Scoring = 3,

    SelfScoreOpen = 2,

    SelfScoring = 3,

    SelfScoringDone = 4,

    Done = 5,

}
