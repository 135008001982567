import {Component, Input} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {AvatarClassesEnum} from 'shared/components/avatar/enums/avatar-classes.enum';

@Component({
    selector: 'app-exams-exam-created-by',
    templateUrl: 'exam-created-by.component.html',
})
export class ExamsExamCreatedByComponent {

    public readonly rowClasses = GridTableRowClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly avatarClasses = AvatarClassesEnum;

    public readonly iconEnum = IconEnum;

    @Input()
    public examPlan: ApiExamPlanDetailsInterface;
}
