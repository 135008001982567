export enum SearchDocumentTypeFilterEnum {

    All = 'all',

    Resources = 'resource',

    StudyMaterials = 'studymaterial',

    Tasks = 'task',

    Notes = 'note',

    Users = 'person',

}
