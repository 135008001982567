export enum NavbarItemsEnum {

    Groups = 'navbar-groups',

    Material = 'navbar-material',

    Progress = 'navbar-progress',

    Tasks = 'navbar-tasks',

    Tests = 'navbar-tests',

}
