import { Component, OnDestroy, OnInit } from '@angular/core';
import {ResultStateEnum} from 'shared/components/result-cell/enums/result-state.enum';
import {ResultCodeEnum} from 'shared/components/result-cell/enums/result-code.enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {WorkTypeEnum} from 'shared/components/work-type-label/enums/work-type.enum';
import {WorkTypeClassesEnum} from 'shared/components/work-type-label/enums/work-type-classes.enum';
import { AutoCheckService } from 'document/services/autocheck/auto-check.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-legend-modal',
    templateUrl: 'legend-modal.component.html'
})
export class LegendModalComponent implements OnInit, OnDestroy {


    public readonly iconBoxClassEnum = IconBoxClassEnum;

    public readonly workTypeEnum = WorkTypeEnum;

    public readonly workTypeClasses = WorkTypeClassesEnum;

    public scoremodel: string = 'GVO';

    private subs: Array<Subscription> = [];

    public closeIconBox: IconBoxInterface = {
        type: IconBoxTypeEnum.Icon,
        value: IconEnum.Cross
    };

    public legendItems: Array<Array<any>> = [
        [
            {
                value: 'Groepsopdracht',
                state: ResultStateEnum.Other,
                group: true,
            },
            {
                value: 'Zelfstudie opdracht',
                state: ResultStateEnum.Other,
                selfStudy: true,
            },
        ],
        [
            {
                value: 'Opgegeven',
                state: ResultStateEnum.Created,
            },
            {
                value: 'Niet opgegeven',
                state: ResultStateEnum.None,
            },
        ],
        [
            {
                value: 'Te laat',
                state: ResultStateEnum.TooLate,
            },
            {
                value: 'Herkansen',
                state: ResultStateEnum.Resit,
                result: ResultCodeEnum.ResultRetry,
            },
        ],
        [
            {
                value: 'Goed',
                state: ResultStateEnum.Graded,
                result: ResultCodeEnum.ResultRight,
                scoremodel: 'GVO',
            },
            {
                value: 'Voldoende',
                state: ResultStateEnum.Graded,
                result: ResultCodeEnum.ResultPass,
                scoremodel: 'GVO',
            },
            {
                value: 'Onvoldoende',
                state: ResultStateEnum.Graded,
                result: ResultCodeEnum.ResultWrong,
                scoremodel: 'GVO'
            },
            {
                value: 'Goed',
                state: ResultStateEnum.Graded,
                result: ResultCodeEnum.ResultRightWrongRight,
                scoremodel: 'GF'
            },
            {
                value: 'Fout',
                state: ResultStateEnum.Graded,
                result: ResultCodeEnum.ResultRightWrongWrong,
                scoremodel: 'GF'
            },
        ]
    ];

    constructor(
        private activeModal: NgbActiveModal,
        private autocheckService: AutoCheckService
    ) {}

    public ngOnInit(): void {
        this.subs.push( this.autocheckService.getScoreModel().pipe(filter(Boolean)).subscribe( (scoremodel: string) => {
            this.scoremodel = scoremodel;
        }));
    }


    public close(): void {
        this.activeModal.close();
    }

    public ngOnDestroy(): void {
        this.subs.map(sub => sub.unsubscribe());
    }

}
