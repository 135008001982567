import {Component, Input} from '@angular/core';
import {GroupsModuleGroupInterface} from 'pages/modules/groups/interfaces/group.interface';

@Component({
    selector: 'app-group-name',
    templateUrl: 'group-name.component.html',
})
export class GroupsGroupNameComponent {

    @Input()
    public group: ApiGroupInterface|GroupsModuleGroupInterface;

}
