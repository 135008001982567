import {
    AfterViewInit,
    Component,
    ContentChild, ElementRef,
    HostBinding,
    Input
} from '@angular/core';
import {DocumentRatingScoreComponent} from 'document/modules/components/rating-score/rating-score.component';
import {DocumentTextareaComponent} from 'document/modules/components/textarea/textarea.component';
import {DomParentsHelper} from 'helpers/dom/parents.helper';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';
import {DocumentSectionDirective} from 'document/modules/directives/section/section.directive';
import {
    DocumentUserInputInlineComponent
} from 'document/modules/components/user-input-inline/user-input-inline.component';
import {DocumentService} from 'document/services/document/document.service';

@Component({
    selector: '.document-review',
    templateUrl: 'review.component.html',
})
export class DocumentReviewComponent implements AfterViewInit {

    @HostBinding('class')
    public elementClass: string = 'document__review';

    @Input()
    public showExternalReviewer: boolean = false;

    @ContentChild(DocumentTextareaComponent, {static: true})
    public textAreaComponent: DocumentTextareaComponent;

    @ContentChild(DocumentRatingScoreComponent, {static: true})
    public ratingScoreComponent: DocumentRatingScoreComponent;

    @ContentChild(DocumentUserInputInlineComponent, {static: true})
    public InputInlineComponent: DocumentUserInputInlineComponent;

    constructor(
        private domParentsHelper: DomParentsHelper,
        private elementRef: ElementRef,
    ) {
    }

    public ngAfterViewInit(): void {
        this.handleReviewComponents();

        const sectionElement: HTMLElement = this.domParentsHelper.findFirstParentWithClass(this.elementRef.nativeElement, 'document__section');

        if (parseInt(sectionElement.dataset.level, 0) > 3) {
            sectionElement.classList.add(DocumentHostClassesEnum.SectionReview);
        }

        if (parseInt(sectionElement.dataset.level, 0) === 4) {
            sectionElement.classList.add(DocumentHostClassesEnum.SectionReviewContainer);
        }

        DocumentSectionDirective.DEPTH = 0;
    }

    private handleReviewComponents(): void {
        this.textAreaComponent.inReviewMode();
        this.ratingScoreComponent.connectWithTextArea(this.textAreaComponent);
    }
}
