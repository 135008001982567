import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, Observer} from 'rxjs';
import {Injectable} from '@angular/core';
import {AnalyticsService} from 'services/analytics/analytics.service';
import {AnalyticsEventAuthenticatedAccessDeniedModel} from 'services/analytics/models/authenticated-access-denied.model';
import {LocalStorageService} from 'angular-2-local-storage';

@Injectable()
export class AuthenticationHttpInterceptor implements HttpInterceptor {

    constructor(
        private analyticsService: AnalyticsService,
        private localStorageService: LocalStorageService
    ) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return new Observable((observer: Observer<HttpEvent<any>>) => {
            next.handle(req).subscribe((response: HttpEvent<any>) => {
                observer.next(response);
            }, (httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse.status === 401) {
                    this.analyticsService.event(new AnalyticsEventAuthenticatedAccessDeniedModel());

                    // todo this should be moved to a general logout handler
                    this.localStorageService.clearAll();

                    location.href = httpErrorResponse.error.redirectUrl;

                    return;
                }

                observer.error(httpErrorResponse);
            }, () => {
                observer.complete();
            });
        });
    }
}
