import {Component, OnDestroy, OnInit} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {ToastrService} from 'ngx-toastr';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {ModalService} from 'core/services/modal/modal.service';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {Subscription} from 'rxjs';
import {ActionButtonClassEnum} from 'shared/enums/action-button/class.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {RouteService} from 'routing/services/route/route.service';
import {ExamService} from 'services/exam/exam.service';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {RoleEnum} from 'enums/role.enum';
import {AlertMessageInterface} from 'shared/components/alert-message/interfaces/alertMessageInterface';
import {ExamDeleteAlertMessage} from 'shared/components/alert-message/models/exam-deleted.model';
import {ExamUserResultService} from 'pages/modules/exams/service/exam-user-result.service';
import {ExamSystemEnum} from 'enums/exam-system.enum';

@Component({
    selector: 'app-exams-detail',
    templateUrl: 'exams-detail.component.html',
})
export class ExamsDetailComponent implements OnInit, OnDestroy {

    public static readonly BUTTON_GOTO_EXAM: string = 'button-goto-exam';

    public static readonly BUTTON_REMOVE_EXAM: string = 'button-remove-exam';

    public readonly rowClasses = GridTableRowClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly iconEnum = IconEnum;

    public readonly routesEnum = RoutesEnum;

    public alertMessage: AlertMessageInterface = new ExamDeleteAlertMessage();

    public ExamDeleted: boolean = false;

    public isLoading: boolean = false;

    public examCheck: ApiExamCheckInterface;

    public examCheckCurrentAnswer: ApiExamOpenAnswerInterface;

    public actionGoToExam: ActionButtonInterface = {
        id: ExamsDetailComponent.BUTTON_GOTO_EXAM,
        value: 'Toon antwoorden',
        disabled: false,
        iconBox: {
            value: IconEnum.Trophy,
            type: IconBoxTypeEnum.Icon,
        },
        classModifiers: [
            ActionButtonClassEnum.Exam,
            ActionButtonClassEnum.Mobile,
        ],
    };

    public actionButtons: Array<ActionButtonInterface> = [
        {
            id: ExamsDetailComponent.BUTTON_REMOVE_EXAM,
            iconBox: {
                value: IconEnum.Trash,
                type: IconBoxTypeEnum.Icon,
            },
            classModifiers: [
                ActionButtonClassEnum.Exam,
                ActionButtonClassEnum.IconOnlyInverted,
            ],
        }
    ];

    public examPlan: ApiExamPlanDetailsInterface;

    public examResult: ApiExamUserResultInterface;

    private routeParamSubscription: Subscription;

    private routeQueryParamSubscription: Subscription;

    private examUserResultSubscription: Subscription;

    public constructor(
        private authService: AuthorizationService,
        private examService: ExamService,
        private toastService: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private routeService: RouteService,
        private examUserResultService: ExamUserResultService
    ) {
        this.examUserResultSubscription = this.examUserResultService.subscribe(
            result => {
                if (result !== null) {
                    setTimeout(() => {
                        this.setActionGoToExamButtonState(result);
                        this.setExamUserResult(result);
                        this.loadExamResultCheck();
                    });
                }
            }
        );
    }

    public ngOnInit(): void {
        this.routeParamSubscription = this.route.params.subscribe(() => {
            this.loadData();
        });

        this.routeQueryParamSubscription = this.route.queryParams.subscribe(() => {
            this.loadData();
        });
    }

    public ngOnDestroy(): void {
        if (this.routeParamSubscription instanceof Subscription) {
            this.routeParamSubscription.unsubscribe();
        }

        if (this.routeQueryParamSubscription instanceof Subscription) {
            this.routeQueryParamSubscription.unsubscribe();
        }

        this.actionGoToExam.routerLink = undefined;
    }

    public handleActionButton(id: string): void {
        switch (id) {
            case ExamsDetailComponent.BUTTON_REMOVE_EXAM:
                this.handleRemoveExamPlan();
                break;
        }
    }

    public mayStartExam(): boolean {
        return this.examService.mayStartExam(this.examPlan);
    }

    private loadData(planId?: number): void {
        if (!this.route.snapshot.params.planId || this.isLoading === true) {
            return;
        }

        this.isLoading = true;

        if (!planId) {
            planId = this.route.snapshot.params.planId;
        }

        this.examService.retrieveExamPlan(planId).toPromise()
            .then((examPlan: ApiExamPlanDetailsInterface) => {
                this.examPlan = examPlan;
                this.examUserResultService.setResult(this.examPlan.results[0]);

                if (this.examPlan.results.length > 0 && this.examPlan.results[0].examResult && !(this.examPlan.results[0].examResult instanceof Array)) {
                    this.actionButtons = [];
                }
                this.ExamDeleted = false;

                return Promise.resolve([]);
            })
            .catch((error: any) => {
                if (error.error.code === 404) {
                    this.alertMessage.title = `Deze toets is niet beschikbaar.`;
                    this.ExamDeleted = true;
                }

                this.toastService.error('Kon opgegeven examen niet ophalen.');
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    private setActionGoToExamButtonState(examResult: ApiExamUserResultInterface): void {
        if (undefined === this.examPlan) {
            return;
        }

        const isTeacher: boolean = this.authService.isGranted(RoleEnum.RoleTeacher);
        const isContentExam: boolean = this.examPlan.exam.system === ExamSystemEnum.Content;

        let route: RoutesEnum = isContentExam ? RoutesEnum.ContentExamPlanPage : RoutesEnum.ExamPlanResult;
        if (isTeacher) {
            route = isContentExam ? RoutesEnum.ContentExamPageTeacher : RoutesEnum.ExamPlanResultTeacher;
        }

        if (isTeacher && examResult && examResult.examResult) {
            const routeParams = new Map([
                ['bookId', String(this.examPlan.book.id)],
                ['examId', String(this.examPlan.exam.documentDpsId)],
                ['planId', String(this.examPlan.id)],
                ['resultId', String(examResult.examResult.id)],
                ['studentId', isTeacher ? String(examResult.planUser.user.id) : String(examResult.user.id)],
            ]);

            this.actionGoToExam.value = 'Toon antwoorden';
            this.actionGoToExam.routerLink = null === examResult.examResult ? undefined : this.routeService.getRouteWithPublisher(route, routeParams);

            return;
        }

        if (!isTeacher && !(examResult && examResult.examResult)) {
            const routeParams = new Map([
                ['bookId', String(this.examPlan.book.id)],
                ['examId', String(isContentExam ? this.examPlan.exam.documentDpsId : this.examPlan.exam.id)],
                ['planId', String(this.examPlan.id)],
            ]);


            this.actionGoToExam.value = 'Toets starten';
            this.actionGoToExam.disabled = !this.mayStartExam();

            this.actionGoToExam.routerLink = this.routeService.getRouteWithPublisher(isContentExam ? RoutesEnum.ContentExamPlanPage : RoutesEnum.ExamPlanMake, routeParams);

            return;
        }
    }

    private handleRemoveExamPlan(): void {
        const modalInstance = this.modalService.examPlanRemove(this.examPlan);

        modalInstance.examPlanDeletedEvent.subscribe(() => {
            this.router.navigateByUrl(this.routeService.getRouteWithPublisher(RoutesEnum.Exams));
        });
    }

    public examAnswerGraded(): void {
        let allReviewed = true;

        for (const answer of this.examCheck.items) {
            if (answer.reviewed !== true) {
                allReviewed = false;
            }
        }

        if (allReviewed !== true) {
            return;
        }

        setTimeout(() => {
            this.loadData();
        }, 2000);
    }

    private setExamUserResult(result: ApiExamUserResultInterface | null): void {
        if (null === result) {
            return;
        }

        this.setActionGoToExamButtonState(result);

        this.examResult = result;
        this.loadExamResultCheck();
    }

    private loadExamResultCheck(): void {
        this.examCheck = this.examCheckCurrentAnswer = null;

        if (!this.examResult) {
            return;
        }

        if (!this.examResult.examResult) {
            return;
        }

        if (this.examResult.examResult.needs_review !== true) {
            return;
        }

        this.examService.retrieveResultForReview(this.examResult.examResult.id).subscribe((result: ApiExamCheckInterface) => {
            this.examCheck = result;

            if (this.examCheck.items.length === 0) {
                return;
            }

            this.examCheckCurrentAnswer = this.examCheck.items[0];
        });
    }
}
