import {AfterViewInit, Directive, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {WindowService} from 'services/window/window.service';
import {Subscription} from 'rxjs';

@Directive({
    selector: '.document-toc-container',
})
export class DocumentPageTocContainerDirective implements OnInit, AfterViewInit, OnDestroy {

    protected isFixed: boolean = false;

    protected onResizeSubscription: Subscription;

    protected nativeElement: HTMLElement;

    protected parentElement: HTMLElement;

    protected initialized: boolean = false;

    constructor(private elementRef: ElementRef, private windowService: WindowService) {
        this.onResizeSubscription = this.windowService.onResize.subscribe(() => this.onWindowServiceEvent());
    }

    public ngOnInit(): void {
        this.nativeElement = this.elementRef.nativeElement;
        this.parentElement = this.elementRef.nativeElement.parentElement;
        this.updateElementPosition();
    }

    public ngAfterViewInit(): void {
        this.initialized = true;
    }

    public ngOnDestroy(): void {
        this.onResizeSubscription.unsubscribe();
    }

    protected onWindowServiceEvent(): void {
        if (!this.initialized) {
            return;
        }

        this.updateElementPosition();
    }

    protected updateElementPosition(): void {
        this.updateElementStyling();
    }

    protected updateElementStyling(): void {
        this.nativeElement.style.left = `${this.parentElement.getBoundingClientRect().right}px`;
    }
}
