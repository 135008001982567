import {ButtonClassEnum} from 'shared/components/button/enums/button-class.enum';
import {ButtonTypeEnum} from 'shared/components/button/enums/button-type.enum';
import {NavbarItemsEnum} from 'core/components/navbar/enums/navbar-items.enum';
import {ColorEnum} from 'enums/color.enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {ButtonRolesInterface} from 'shared/components/button/interfaces/button-roles.interface';
import {RoleEnum} from 'enums/role.enum';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {HelpPageEnum} from 'services/help-page/enums/help-page.enum';

export class NavbarButton implements ButtonRolesInterface {

    public publisherLink: boolean = true;

    public iconBox: IconBoxInterface;

    public type: string = ButtonTypeEnum.Navbar;

    public roles: Array<RoleEnum> = [
        RoleEnum.RoleHasPublisher,
    ];

    public classModifiers: Array<string> = [
        ButtonClassEnum.Navbar,
    ];

    constructor(
        public id: NavbarItemsEnum,
        public routerLink: RoutesEnum,
        public text: string,
        public color: ColorEnum,
        roles: Array<RoleEnum>,
        icon: IconEnum,
    ) {
        this.classModifiers.push(`${ButtonClassEnum.Navbar}-${color}`);
        this.roles = [...this.roles, ...roles];
        this.iconBox = {
            type: IconBoxTypeEnum.Icon,
            value: icon,
            classModifiers: [
                IconBoxClassEnum.Navbar,
                `${IconBoxClassEnum.Icon}-${color}`,
            ],
        };
    }

    public setNotificationCount(value: number): void {
        this.iconBox.notificationCount = value;
    }
}
