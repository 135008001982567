import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {DocumentAssignmentStatusEnum} from 'pages/modules/document/enums/document-assignment-status.enum';
import {AssignmentService} from 'services/assignment/assignment.service';
import {ToastrService} from 'ngx-toastr';
import {DocumentSelfScoreScoresEnum} from 'pages/modules/document/enums/self-scores.enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import {WindowBreakPointsEnum} from 'enums/window-break-points.enum';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';

@Component({
    selector: 'app-document-page-self-score',
    templateUrl: 'self-score.component.html',
})
export class DocumentPageSelfScoreComponent implements OnInit, OnChanges {

    public readonly statusEnum = DocumentAssignmentStatusEnum;

    public readonly scoresEnum = DocumentSelfScoreScoresEnum;

    @Input()
    public assignment: ApiDocumentAssignmentInterface;

    @Input()
    public status: DocumentAssignmentStatusEnum;

    @Output()
    public statusChange: EventEmitter<DocumentAssignmentStatusEnum> = new EventEmitter();

    @Input()
    public score: DocumentSelfScoreScoresEnum;

    @Output()
    public scoreChange: EventEmitter<DocumentSelfScoreScoresEnum> = new EventEmitter();

    public classModifier = new ClassHelper();

    public loading: boolean = false;

    public constructor(
        private element: ElementRef,
        private assignmentService: AssignmentService,
        private toastrService: ToastrService,
        private answersStateService: DocumentAnswersStateService
    ) {
    }

    public ngOnInit(): void {
        this.answersStateService.subscribeToSelfAnswerChanges(() => {
            if (this.status === this.statusEnum.SelfScoreOpen) {
                this.handleSelfScore();
            }
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.handleFixedState();
    }

    public handleSelfScore(): void {
        this.loading = true;

        this.assignmentService.handInSelfScore(this.assignment.tasks[this.assignment.tasks.length - 1].id)
            .subscribe(() => {
                this.loading = false;

                this.status = DocumentAssignmentStatusEnum.SelfScoring;
                this.statusChange.emit(this.status);
                this.handleFixedState();
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });

            }, () => {
                this.loading = false;
                this.toastrService.error('Kon zelf nakijken niet starten.');
            });
    }

    public handleSubmitScore(score: DocumentSelfScoreScoresEnum): void {
        this.score = score;
        this.scoreChange.emit(this.score);

        this.handleFixedState();
    }

    public getScoreAsClass(): string {
        switch (this.score) {
            case DocumentSelfScoreScoresEnum.Right:
                return 'right';

            case DocumentSelfScoreScoresEnum.Pass:
                return 'pass';

            case DocumentSelfScoreScoresEnum.Wrong:
                return 'wrong';
        }

        return 'none';
    }

    public getScoreAsText(): string {
        switch (this.score) {
            case DocumentSelfScoreScoresEnum.Right:
                return 'goed';

            case DocumentSelfScoreScoresEnum.Pass:
                return 'voldoende';

            case DocumentSelfScoreScoresEnum.Wrong:
                return 'onvoldoende';
        }

        return 'niets';
    }

    @HostListener('window:scroll')
    @HostListener('window:resize')
    private handleScrollEvent(): void {
        this.handleFixedState();
    }

    private handleFixedState(): void {
        if (this.status !== DocumentAssignmentStatusEnum.SelfScoring
            && this.status !== DocumentAssignmentStatusEnum.SelfScoringDone) {
            this.classModifier.removeClass('self-score--fixed');

            return;
        }

        if (window.innerWidth < WindowBreakPointsEnum.Small) {
            this.classModifier.removeClass('self-score--fixed');

            return;
        }

        const nativeElement: HTMLElement = this.element.nativeElement;
        const bounding = nativeElement.getBoundingClientRect();

        if (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        ) {
            this.classModifier.removeClass('self-score--fixed');
        } else {
            this.classModifier.addClass('self-score--fixed');
        }
    }
}
