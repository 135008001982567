import {NgModule} from '@angular/core';
import {SharedModule} from 'shared/shared.module';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TocComponent} from 'shared/modules/toc/components/toc.component';
import {TocHeaderComponent} from 'shared/modules/toc/components/toc-header/toc-header.component';
import {TocViewBooksComponent} from 'shared/modules/toc/components/toc-view-books/toc-view-books.component';
import {TocViewBookItemComponent} from 'shared/modules/toc/components/toc-view-book-item/toc-view-book-item.component';
import {TocRolesLogic} from 'shared/modules/toc/authorization/roles.logic';
import {SecurityModule} from 'security/security.module';
import {RoutingModule} from 'routing/routing.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        SecurityModule,
        RoutingModule,
    ],
    declarations: [
        TocComponent,
        TocHeaderComponent,
        TocViewBooksComponent,
        TocViewBookItemComponent,
    ],
    exports: [
        TocComponent,
    ],
    providers: [
        TocRolesLogic,
    ],
})
export class TocModule {
}
