import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';
import {ClassHelper} from 'helpers/dom/class.helper';

@Component({
    selector: '.document-initial-answer',
    templateUrl: 'document-initial-answer.component.html',
})
export class DocumentInitialAnswerComponent implements OnInit {

    @HostBinding('class')
    public elementClass: string = '';

    @Input()
    public classModifiers: Array<string> = [];

    public ngOnInit(): void {
        const classHelper = new ClassHelper();

        classHelper.addClasses(this.classModifiers);
        classHelper.addClass(DocumentHostClassesEnum.QuestionInitialAnswer);

        this.elementClass = classHelper.toString();
    }
}
