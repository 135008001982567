import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FilterDropdownFilterInterface} from 'shared/components/filter-dropdown/interfaces/filter.interface';
import {ClassHelper} from 'helpers/dom/class.helper';
import {FilterDropdownClassEnum} from 'shared/enums/filter-dropdown/class.enum';
import {DropdownComponent} from 'shared/modules/dropdown/components/dropdown.component';
import {DropdownClassEnum} from 'shared/modules/dropdown/enums/dropdown-class.enum';

@Component({
    selector: 'app-filter-dropdown-component',
    templateUrl: 'filter-dropdown.component.html',
})
export class FilterDropdownComponent implements OnInit, OnChanges {

    protected static readonly CLASS = 'filter-dropdown';

    public readonly dropdownClasses = DropdownClassEnum;

    public readonly id: string;

    @HostBinding('class')
    public classes: string;

    @Output()
    public filterChange: EventEmitter<FilterDropdownFilterInterface<any>> = new EventEmitter();

    @Input()
    public filter: FilterDropdownFilterInterface<any>;

    @Input()
    public disabled: boolean = false;

    @Input()
    public visible: Map<string, boolean> = new Map();

    @Input()
    public classModifiers: Array<string> = [];

    private classHelper: ClassHelper = new ClassHelper(FilterDropdownComponent.CLASS);

    public constructor() {
        this.id = Math.random().toString(36).substring(9);
    }

    public ngOnInit(): void {
        this.loadFiltersClasses();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            this.classHelper.toggleClassByBoolean(FilterDropdownClassEnum.Disabled, changes.disabled.currentValue);
            this.updateClasses();
        }
    }

    public handleChange(filter: FilterDropdownFilterInterface<any>, dropdown: DropdownComponent): void {
        dropdown.toggle();

        if (this.disabled) {
            return;
        }

        this.filterChange.emit(filter);
    }

    public toggleDropdown(event: Event, dropdown: DropdownComponent): void {
        event[DropdownComponent.EVENT_FIELD] = dropdown.id;

        dropdown.toggle(!dropdown.isVisible());
    }

    public getFilterText(): string {
        for (const optionGroup of this.filter.options.values()) {
            for (const option of optionGroup.entries()) {
                if (option[0] === this.filter.selectedValue) {
                    return option[1];
                }
            }
        }

        if (this.filter.placeHolder) {
            return String(this.filter.placeHolder);
        }

        return '';
    }

    public keyValueCompare(a: any, b: any): number {
        return 0;
    }

    private loadFiltersClasses(): void {
        this.classHelper.addClasses(this.classModifiers);
        if (this.filter.icon !== undefined) {
            this.classHelper.addClass(FilterDropdownClassEnum.Icon);
        }

        this.updateClasses();
    }

    private updateClasses(): void {
        this.classes = this.classHelper.toString();
    }
}
