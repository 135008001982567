import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from 'shared/shared.module';
import {DocumentPageModule} from 'pages/modules/document/document.module';
import {ToastrModule} from 'ngx-toastr';
import {TeacherMaterialModule} from 'pages/modules/teacher-material/teacher-material.module';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {PdfViewerComponent} from 'pages/components/pdf-viewer/pdf-viewer.component';
import {GroupsModule} from 'pages/modules/groups/groups.module';
import {TasksModule} from 'pages/modules/tasks/tasks.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ActivateLicenseModalComponent} from 'pages/modules/bookshelf/components/modals/activate-license/activate-license-modal.component';
import {ManageMaterialsModalComponent} from 'pages/modules/bookshelf/components/modals/manage-materials/manage-materials-modal.component';
import {ProgressModule} from 'pages/modules/progress/progress.module';
import {BookshelfModule} from 'pages/modules/bookshelf/bookshelf.module';
import {ExamsModule} from 'pages/modules/exams/exams.module';
import {MessageCenterModule} from 'pages/modules/message-center/message-center.module';
import {DeeplinkModule} from 'pages/modules/deeplink/deeplink.module';
import {SearchModule} from 'pages/modules/search/search.module';
import {UserModule} from 'pages/modules/user/user.module';
import {DemoRequestModalComponent} from 'shared/components/demo-bar/modal/demo-request/demo-request-modal.component';
import {ContentExamModule} from 'pages/modules/content-exam/content-exam.module';
import {
    SaveFavoritesSortModalComponent
} from 'pages/modules/bookshelf/components/modals/save-favorites-sort/save-favorites-sort-modal.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        InfiniteScrollModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        SharedModule,
        DocumentPageModule,
        TeacherMaterialModule,
        NgxExtendedPdfViewerModule,
        TasksModule,
        GroupsModule,
        FormsModule,
        ReactiveFormsModule,
        ProgressModule,
        BookshelfModule,
        ExamsModule,
        ContentExamModule,
        MessageCenterModule,
        DeeplinkModule,
        SearchModule,
        UserModule,
    ],
    declarations: [
        PdfViewerComponent,
        ActivateLicenseModalComponent,
        ManageMaterialsModalComponent,
        DemoRequestModalComponent,
        SaveFavoritesSortModalComponent
    ],
    entryComponents: [
        ActivateLicenseModalComponent,
        ManageMaterialsModalComponent,
        DemoRequestModalComponent,
        SaveFavoritesSortModalComponent
    ],
})
export class PagesModule {
}
