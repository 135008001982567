import {IconEnum} from 'enums/icon.enum';
import {ActionButtonClassEnum} from 'shared/enums/action-button/class.enum';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';

export class TasksGoToAssignmentsModel implements ActionButtonInterface {
    public id = 'button-goto-assignment';

    public value = 'Bekijk opdracht';

    public disabled: boolean;

    public iconBox = {
        value: IconEnum.Check,
        type: IconBoxTypeEnum.Icon,
    };

    public classModifiers = [
        ActionButtonClassEnum.Mobile,
    ];

    public constructor(disabled: boolean = true) {
        this.disabled = disabled;
    }
}
