import {Component, Input} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {RoutesEnum} from 'routing/enums/routes.enum';

@Component({
    selector: 'app-progress-popover-not-assigned',
    templateUrl: 'popover-not-assigned.component.html',
})
export class ProgressPopoverNotAssignedComponent {

    public readonly iconEnum = IconEnum;

    public readonly tableClasses = GridTableClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly routeEnum = RoutesEnum;

    @Input()
    public bookId: number = null;

    @Input()
    public dpsid: string = null;
}
