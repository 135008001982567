import {AfterViewInit, Component, ElementRef, HostBinding} from '@angular/core';
import {DocumentAnswerableComponent} from 'document/modules/abstract/answerable.component';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {ClassHelper} from 'helpers/dom/class.helper';
import {DocumentUserInputInlineClassEnum} from 'document/modules/components/user-input-inline/enums/class.enum';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {RoleEnum} from 'enums/role.enum';
import {DocumentService} from 'document/services/document/document.service';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';

@Component({
    selector: '.document-userinputinline',
    templateUrl: 'user-input-inline.component.html',
})
export class DocumentUserInputInlineComponent extends DocumentAnswerableComponent implements AfterViewInit {

    @HostBinding('class')
    public elementClass: string;

    public readonly roleEnum = RoleEnum;

    public readonly examinationNeededEnum = ExamineOptionsEnum;

    public classHelper: ClassHelper = new ClassHelper(DocumentUserInputInlineClassEnum.Input);

    public id: string;

    public value: string = '';

    public correctAnswer: string = '';

    public initialAnswer: string;

    public reviewMode: boolean = false;

    public examinationByStudent: boolean = false;

    constructor(
        protected elementRef: ElementRef,
        protected answersService: DocumentAnswersService,
        protected showAnswersService: DocumentAnswersStateService,
        private documentService: DocumentService,
    ) {
        super(elementRef, answersService, showAnswersService);
        this.id = `input-inline-${this.dataSet.assignmentid}-${this.dataSet.fieldid}`;
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.setClass();
        this.handleExaminationNeeded();
    }

    public onModelChange(): void {
        this.saveAnswer(this.value);
        this.setClass();
    }

    public setClass(): void {
        this.classHelper.toggleClassByBoolean(DocumentUserInputInlineClassEnum.HasValue, this.value.length > 0);
        this.updateClasses();
    }

    public updateClasses(): void {
        this.elementClass = this.classHelper.toString();
    }

    protected setAnswer(answer: string): void {
        if (!answer) {
            return;
        }

        this.value = answer;
    }

    public inReviewMode(): void {
        this.reviewMode = true;
    }

    protected showCorrectAnswer(correctAnswer: string): void {
        this.correctAnswer = correctAnswer;
    }

    protected showInitialAnswer(initialAnswer: string): void {
        if (initialAnswer === this.value) {
            return;
        }

        this.initialAnswer = initialAnswer;
    }

    protected answerValid(): boolean {
        return true;
    }

    private handleExaminationNeeded(): void {
        const task = this.documentService.getActiveTask();

        // examination by Student
        if (task && task.examination_needed === this.examinationNeededEnum.Student) {
            this.examinationByStudent = this.documentService.subscribeSelfStudyCompatible();
        }
    }
}
