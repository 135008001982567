import {AfterContentInit, Component, ContentChild, DoCheck, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {GridTableColComponent} from 'shared/modules/grid-table/components/col/grid-table-col.component';
import {GridTableScrollColCellDirective} from 'shared/modules/grid-table/components/scroll-col/column/cell/cell.directive';
import {GridTableScrollColHeaderDirective} from 'shared/modules/grid-table/components/scroll-col/column/header/header.directive';
import {ScrollColCellInterface} from 'shared/modules/grid-table/components/scroll-col/interfaces/scroll-col-cell.interface';
import {ScrollColColumnInterface} from 'shared/modules/grid-table/components/scroll-col/interfaces/scroll-col-column.interface';
import {ScrollColHeaderInterface} from 'shared/modules/grid-table/components/scroll-col/interfaces/scroll-col-header.interface';
import {GridTableScrollControlComponent} from 'shared/modules/grid-table/components/scroll-control/scroll-control.component';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridTableScrollEventInterface} from 'shared/modules/grid-table/interfaces/scroll-event.interface';
import {WindowService} from 'services/window/window.service';
import {TableGridScrollControlDirectionEnum} from 'shared/modules/grid-table/enums/scroll-control/direction.enum';
import * as smoothscroll from 'smoothscroll-polyfill';
import {ActiveTaskEventInterface, TaskStateService} from 'pages/modules/tasks/service/task-state.service';
import {StudentProgressInterface} from 'pages/modules/progress/components/student-details/student-results/student-results.component';
import {ProgressStatusFilterEnum} from 'shared/components/status-filter/enum/progress-status-filter.enum';

@Component({
    selector: 'app-grid-table-scroll-col',
    templateUrl: 'scroll-col.component.html',
})
export class GridTableScrollColComponent extends GridTableColComponent implements OnInit, OnChanges, DoCheck, OnDestroy, AfterContentInit {

    private _internalColumns: ScrollColColumnInterface[];

    private controlComponents: GridTableScrollControlComponent[] = [];

    private visibility: boolean = false;

    private visibilityChanged: boolean = false;

    public readonly columnClassEnum = GridTableColClassEnum;

    public _headers: ScrollColHeaderInterface[];

    public _columns: ScrollColColumnInterface[];

    public _visibleColumns: ScrollColColumnInterface[];

    public _rows: ScrollColCellInterface[][];

    @ContentChild(GridTableScrollColHeaderDirective, {read: TemplateRef, static: true})
    public headerTemplateDirective: TemplateRef<any> | null;

    @ContentChild(GridTableScrollColCellDirective, {read: TemplateRef, static: true})
    public cellTemplateDirective: TemplateRef<any> | null;

    public _scrollableWidth: number = 0;

    public progressAllTasks: StudentProgressInterface | null;

    @Input()
    public hidePopover: boolean = false;

    public activeTaskIndex: number | null;

    public activeTask: ActiveTaskEventInterface;

    constructor(
        private taskStateService: TaskStateService,
        protected elementRef: ElementRef,
        private windowService: WindowService
    ) {
        super();
        smoothscroll.polyfill();
        this.subscriptions.push(this.windowService.onResize.subscribe(() => this.handleWindowResize()));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.updateControls();

        if (this.hidePopover) {
            this.classHelper.addClass(this.columnClassEnum.ProgressResultsTaskDetails);
        }

        this.subscriptions.push(this.taskStateService.subscribeToTasks(tasks => {
            this.progressAllTasks = tasks;
            setTimeout(() => {
                this.subscriptions.push(this.taskStateService.subscribeToProgressStatusFilter(selectedProgressFilter => this.scrollToActiveColumn(this.activeTask, selectedProgressFilter)));
             });
        }));

    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /**
     * It seems that when toggling views ngAfterView is triggered before the view is
     * actually visible which results in having no scrollWidth and clientWidth so we'll
     * have to monitor the visibility
     */
    public ngDoCheck(): void {
        this.visibilityChanged = this.isVisible() !== this.visibility;
        if (this.visibilityChanged) {
            this.visibility = this.isVisible();
            this.updateControls();
            this.subscriptions.push(this.taskStateService.subscribeToActiveTask(task => {this.scrollToActiveColumn(task, this.taskStateService.getProgressStatusFilter()); this.activeTask = task; }));
        }
    }

    @Input()
    public set headers(val: ScrollColHeaderInterface[]) {
        this._headers = val;
        this.recalculate();
    }

    public get headers(): ScrollColHeaderInterface[] {
        return this._headers;
    }

    @Input()
    public set rows(val: ScrollColCellInterface[][]) {
        this._rows = val;

        this.recalculate();
    }

    public get rows(): ScrollColCellInterface[][] {
        return this._rows;
    }

    @Input()
    public set columns(val: ScrollColColumnInterface[]) {
        this._columns = val;

        if (val) {
            const internalColumns = [];

            for (let i = 0; i < val.length; i++) {
                val[i].index = i;

                internalColumns.push(val[i]);
            }

            this._internalColumns = internalColumns;
        }

        this.recalculate();
    }

    public get columns(): ScrollColColumnInterface[] {
        return this._columns;
    }

    public addScrollControl(scrollControl: GridTableScrollControlComponent): void {
        if (this.controlComponents.includes(scrollControl)) {
            return;
        }

        this.controlComponents.push(scrollControl);
        this.subscriptions.push(scrollControl.scrollClickEvent.subscribe((event: GridTableScrollEventInterface) => {
            this.handleScrollClick(event);
        }));
    }

    @HostListener('scroll')
    public updateControls(): void {
        const leftOffset = this.getScrollLeft();
        const clientWidth = this.getClientWidth();

        this.controlComponents.forEach(controlComponent => {
            controlComponent.canScrollPrev = leftOffset > 0;
            controlComponent.canScrollNext = (clientWidth + leftOffset) < this.getScrollWidth();
        });

        this.calculateVisibleColumns();
    }

    private recalculate(): void {
        this.recalculateColumns();
        this.calculateVisibleColumns();
    }

    private handleWindowResize(): void {
        if (!this.isVisible()) {
            return;
        }

        // Prevent spamming updating controls
        clearTimeout(this.windowResizeTimeout);
        this.windowResizeTimeout = setTimeout(() => {
            this.updateControls();
        }, 500);
    }

    private getScrollWidth(): number {
        return this.getNativeElement().scrollWidth;
    }

    private getClientWidth(): number {
        return this.getNativeElement().clientWidth;
    }

    private getScrollLeft(): number {
        return this.getNativeElement().scrollLeft;
    }

    private getNativeElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    private isVisible(): boolean {
        return this.getNativeElement().offsetWidth > 0 && this.getNativeElement().offsetHeight > 0;
    }

    private handleScrollClick(event: GridTableScrollEventInterface): void {
        const scrollSize: number = event.scrollSize || this.getClientWidth();
        const scrollValue: number = event.direction === TableGridScrollControlDirectionEnum.Next ? scrollSize : -scrollSize;
        const scrollTo: number = this.getScrollLeft() + scrollValue;

        this.getNativeElement().scrollTo({top: 0, left: scrollTo, behavior: 'smooth'});
    }

    public scrollToActiveColumn(activeTask: ActiveTaskEventInterface, activeProgressFilter: number = 1): void {
        if (!this._columns) {
            return;
        }

        if (undefined === this.progressAllTasks || undefined === activeTask) {
            return;
        }

        const drillsterStatussesLength = undefined !== this.progressAllTasks.studyMaterial
            ? this.progressAllTasks.studyMaterial.drillsterStatusses.length : this.progressAllTasks.group.students[0].drillsterStatusses.length;

        let activeTaskIndex = undefined !== this.progressAllTasks.studyMaterial ?
            this.progressAllTasks.studyMaterial.assignmentStatuses.findIndex(taskItem => taskItem.actualAssignmentId === activeTask.task.assignment.id)
            : this.progressAllTasks.group.students[0].assignmentStatuses.findIndex(taskItem => taskItem.actualAssignmentId === activeTask.task.assignment.id);

        if (activeProgressFilter !== ProgressStatusFilterEnum.All) {
            activeTaskIndex = undefined !== this.progressAllTasks.studyMaterial ?
                this.progressAllTasks.studyMaterial.assignmentStatuses.filter(filteredTaskItems => filteredTaskItems.progressStatus).findIndex(taskItem => taskItem.actualAssignmentId === activeTask.task.assignment.id)
                : this.progressAllTasks.group.students[0].assignmentStatuses.filter(filteredTaskItems => filteredTaskItems.progressStatus).findIndex(taskItem => taskItem.actualAssignmentId === activeTask.task.assignment.id);
        }

        activeTaskIndex += drillsterStatussesLength;
        this.activeTaskIndex = activeTaskIndex;

        const defaultWith = 72;
        const columnWidth: number | undefined = (this.columns[0] && this.columns[0].width) ? this.columns[0].width : defaultWith;
        const index: number = activeTaskIndex;
        const width: number = index * columnWidth;
        const scrollTo: number = width - (this.getClientWidth() / 2);

        this.getNativeElement().scrollTo({top: 0, left: scrollTo});
    }

    private recalculateColumns(): Array<any> | undefined {
        if (!this._columns) {
            return;
        }

        let width = 0;

        for (const column of this._columns) {
            width += column.width;
        }

        this._scrollableWidth = width;
    }

    private calculateVisibleColumns(): void {
        if (!this._internalColumns) {
            return;
        }

        const visibleColumns = [];

        const leftOffset = this.getScrollLeft();
        const rightOffset = leftOffset + this.getClientWidth();

        for (let i = 0; i < this._internalColumns.length; i++) {
            const column = this._internalColumns[i];

            if ((column.width * i) < (leftOffset - column.width)) {
                continue;
            }

            if ((column.width * i) > rightOffset) {
                continue;
            }

            visibleColumns.push(column);
        }

        this._visibleColumns = visibleColumns;
    }
}
