import {EventEmitter, Injectable} from '@angular/core';
import {forkJoin, Observable, Observer} from 'rxjs';
import {ApiService} from 'core/services/api/api.service';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {MessageCenterMessageInterface} from 'pages/modules/message-center/interfaces/message.interface';
import {MessageCenterMessageTypeEnum} from 'pages/modules/message-center/enums/type.enum';
import {MessageCenterFilterEnum} from 'pages/modules/message-center/enums/filter.enum';
import {UserService} from 'security/services/user/user.service';
import {ModalService} from 'core/services/modal/modal.service';
import {MessageCenterMessageFactory} from 'pages/modules/message-center/factories/message.factory';
import {MercureMessageType, MercureService} from 'services/mercure/mercure.service';

@Injectable()
export class MessageService {

    public unreadCountChange: EventEmitter<number> = new EventEmitter();

    private unreadCount: number = 0;

    constructor(
        private apiService: ApiService,
        private userService: UserService,
        private modalService: ModalService,
        private messageFactory: MessageCenterMessageFactory,
        private mercureService: MercureService
    ) {
        userService.onUserDataChange.subscribe(() => this.handleUserChange());
    }

    public initialize(): void {
        this.mercureService.subscribe<ApiMessageInterface>(MercureMessageType.Push, data => this.handleReceivedMessage(data));
    }

    public retrieveMessages(
        offset: number,
        limit: number,
        search: string,
        read?: number,
        onlyNewPushMessages?: boolean,
    ): Observable<ApiMessagesInterface> {
        return this.apiService.get<any>(ApiEndpointEnum.MessageAll, new URLSearchParams([
            ['offset', offset.toString()],
            ['limit', limit.toString()],
            ['search', search],
            ['read', read.toString()],
            ['onlyNewPushMessages', onlyNewPushMessages ? '1' : ''],
        ]));
    }

    public retrieveNotifications(
        offset: number,
        limit: number,
        search: string,
        read: number,
        filter?: MessageCenterFilterEnum,
    ): Observable<ApiNotificationsInterface> {
        return this.apiService.get<any>(ApiEndpointEnum.MessageNotificationAll, new URLSearchParams([
            ['offset', offset.toString()],
            ['limit', limit.toString()],
            ['search', search],
            ['read', read.toString()],
            ['filter', filter ? filter.toString() : ''],
        ]));
    }

    public markAllAsRead(): Observable<any> {
        const fork = forkJoin([
            this.markAllMessagesAsRead(),
            this.markAllNotificationsAsRead(),
        ]);

        fork.subscribe(() => {
            this.decreaseUnreadCount(this.getUnreadCount());
        });

        return fork;
    }

    public markAsRead(
        message: MessageCenterMessageInterface
    ): Observable<ApiNotificationInterface|ApiMessageInterface> {
        return new Observable((observer: Observer<ApiNotificationInterface|ApiMessageInterface>) => {
            const isMessage: boolean = (message.messageType.type === MessageCenterMessageTypeEnum.Message);
            const observable: Observable<ApiNotificationInterface|ApiMessageInterface> = isMessage
                ? this.markMessageAsRead(message.id)
                : this.markNotificationAsRead(message.id);

            observable.subscribe(
                (response: ApiNotificationInterface|ApiMessageInterface) => {
                    this.decreaseUnreadCount(1);
                    observer.next(response);
                },
                (error: any) => observer.error(error),
                () => observer.complete(),
            );
        });
    }

    public getUnreadCount(): number {
        return this.unreadCount;
    }

    public increaseUnreadCount(count: number): void {
        this.unreadCount += count;
        this.unreadCountChange.emit(this.unreadCount);
    }

    public decreaseUnreadCount(count: number): void {
        this.unreadCount -= count;

        if (this.unreadCount < 0) {
            this.unreadCount = 0;
        }

        this.unreadCountChange.emit(this.unreadCount);
    }

    public showMessage(message: MessageCenterMessageInterface): void {
        if (!message.read) {
            this.markAsRead(message).subscribe(); // Trigger mark as read
        }

        this.modalService.messageCenterMessageDetails(message);
    }

    protected markMessageAsRead(messageId: number): Observable<ApiMessageInterface> {
        return this.apiService.post<ApiMessageInterface>(
            ApiEndpointEnum.MessageMarkAsRead,
            null,
            null,
            new Map([['messageId', messageId.toString()]]),
        );
    }

    protected markAllMessagesAsRead(): Observable<any> {
        return this.apiService.post<ApiNotificationInterface>(ApiEndpointEnum.MessageMarkAllAsRead);
    }

    protected markAllNotificationsAsRead(): Observable<any> {
        return this.apiService.post<ApiNotificationInterface>(ApiEndpointEnum.MessageNotificationMarkAllAsRead);
    }

    protected markNotificationAsRead(notificationId: number): Observable<ApiNotificationInterface> {
        return this.apiService.post<ApiNotificationInterface>(
            ApiEndpointEnum.MessageNotificationMarkAsRead,
            null,
            null,
            new Map([['notificationId', notificationId.toString()]]),
        );
    }

    private handleUserChange(): void {
        const user = this.userService.getUserData();

        const messages = [];

        if (user.messages_pushed_unread) {
            for (const message of user.messages_pushed_unread) {
                messages.push(this.messageFactory.fromApiMessage(message));
            }
        }

        this.showPushMessages(messages);

        this.unreadCount = user.notifications_unread_count;
        this.unreadCountChange.emit(this.unreadCount);
    }

    private handleReceivedMessage(payload: ApiMessageInterface): void {
        const message = this.messageFactory.fromApiMessage(payload);

        this.showPushMessages([message]);
    }

    private showPushMessages(messages: Array<MessageCenterMessageInterface>): void {
        for (const message of messages) {
            if (!message || !message.push) {
                continue;
            }

            this.showMessage(message);
        }
    }
}
