import {AfterViewInit, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {DocumentAnswerableComponentInterface} from 'document/modules/interfaces/answerable.interface';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {Subscription} from 'rxjs';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';

export abstract class DocumentAnswerableComponent implements DocumentAnswerableComponentInterface, OnInit, AfterViewInit, OnDestroy {

    protected dataSet: DocumentDatasetInterface;

    protected field: ApiDocumentAssignmentFieldInterface;

    public correctAnswerShown: boolean = false;

    public initialAnswerShown: boolean = false;

    public answersDisabled: boolean = false;

    public initializationFailed: boolean = false;

    private previousAnswer: any;

    private answerSavingSubscription: Subscription;

    protected constructor(
        protected elementRef: ElementRef,
        protected answersService: DocumentAnswersService,
        protected showAnswersService: DocumentAnswersStateService,
    ) {
        this.dataSet = this.elementRef.nativeElement.dataset;
    }

    public ngOnInit(): void {
        this.updateAnswersState();

        this.showAnswersService.register(this);
    }

    public ngAfterViewInit(): void {
        this.field = this.answersService.currentAnswer(this.dataSet.assignmentid, Number(this.dataSet.fieldid));
        this.updateGivenAnswer();
    }

    public ngOnDestroy(): void {
        this.showAnswersService.deregister(this);

        if (this.answerSavingSubscription) {
            this.answerSavingSubscription.unsubscribe();
        }
    }

    public saveAnswer(answer: string): void {
        if (this.previousAnswer === answer || this.answersDisabled === true) {
            return;
        }

        this.previousAnswer = answer;
        this.field.answer = answer;

        if (this.answerSavingSubscription) {
            this.answerSavingSubscription.unsubscribe();
            this.answerSavingSubscription = null;
        }

        this.answerSavingSubscription = this.answersService.saveAnswer(
            this.dataSet.assignmentid,
            Number(this.dataSet.fieldid),
            answer
        );
    }

    protected getCorrectAnswer(): string {
        if (!this.field || !this.field.correct_answer) {
            return '';
        }

        return this.field.correct_answer;
    }

    protected getInitialAnswer(): string {
        if (!this.field || !this.field.initial_answer) {
            return '';
        }

        return this.field.initial_answer;
    }

    protected abstract setAnswer(answer: string): void;

    protected getDecodedAnswer<AnswerType = any>(answer: any): AnswerType {
        try {
            if (typeof answer === 'string') {
                return JSON.parse(answer) as AnswerType;
            }

            return answer;
        } catch (error) {
            console.error(`Failed to parse json string: ${answer}`);
        }
    }

    public updateGivenAnswer(): void {
        if (!this.initializationFailed && this.field !== null && this.field.answer) {
            this.previousAnswer = this.field.answer;
            this.dataSet.assignmentFieldId = this.field.id.toString();
            this.setAnswer(this.field.answer);
        }
    }

    private updateAnswersState(): void {
        if (this.initializationFailed || this.answersDisabled) {
            return;
        }

        this.field = this.answersService.currentAnswer(this.dataSet.assignmentid, Number(this.dataSet.fieldid));

        if (!this.field || !this.field.answer) {
            return;
        }

        this.setAnswer(this.field.answer);
    }

    // Per component events

    protected abstract showCorrectAnswer(correctAnswer: string): void;

    protected hideCorrectAnswer(): void {
        // To be overriden when needed
    }

    protected abstract showInitialAnswer(initialAnswer: string): void;

    protected hideInitialAnswer(): void {
        // To be overriden when needed
    }

    protected enableAnswers(): void {
        // To be overriden when needed
    }

    protected disableAnswers(): void {
        // To be overriden when needed
    }

    protected abstract answerValid(): boolean;

    // Service callbacks

    public onShowCorrectAnswer(): void {
        if (this.initializationFailed) {
            return;
        }

        this.correctAnswerShown = true;

        this.showCorrectAnswer(this.getCorrectAnswer());
    }

    public onHideCorrectAnswer(): void {
        this.correctAnswerShown = false;

        this.hideCorrectAnswer();
    }

    public onShowInitialAnswer(): void {
        if (this.initializationFailed) {
            return;
        }

        this.initialAnswerShown = true;

        const initialAnswer = this.getInitialAnswer();

        if (initialAnswer) {
            this.showInitialAnswer(this.getInitialAnswer());
        }
    }

    public onHideInitialAnswer(): void {
        this.initialAnswerShown = false;

        this.hideInitialAnswer();
    }

    public onEnableAnswers(): void {
        this.answersDisabled = false;

        this.enableAnswers();
    }

    public onDisableAnswers(): void {
        this.answersDisabled = true;

        this.disableAnswers();
    }

    public onValidate(): boolean {
        return this.answerValid();
    }
}
