import {DOCUMENT} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {AfterViewInit, Component, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {ModalService} from 'core/services/modal/modal.service';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {IconEnum} from 'enums/icon.enum';
import {StudyMaterialTypeEnum} from 'enums/study-material-type.enum';
import {ToastrService} from 'ngx-toastr';
import {DocumentRolesLogic} from 'pages/modules/document/authorization/roles.logic';
import {DocumentErrorEnum} from 'pages/modules/document/enums/document-error.enum';
import {DocumentActionsButtonTocModel} from 'pages/modules/document/models/actions-button-toc.model';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {RouteService} from 'routing/services/route/route.service';
import {RoleService} from 'security/services/role/role.service';
import {AnalyticsService} from 'services/analytics/analytics.service';
import {AnalyticsDimensionEnum} from 'services/analytics/enums/dimension.enum';
import {AnalyticsEventStudyMaterialOpenModel} from 'services/analytics/models/study-material-open.model';
import {BookService} from 'services/book/book.service';
import {DocumentService} from 'services/document/document.service';
import {DocumentService as DocumentBookService} from 'document/services/document/document.service';
import {SearchInterface} from 'shared/modules/search/interfaces/search.interface';
import {TocComponentInterface} from 'shared/modules/toc/interfaces/toc-component.interface';
import {TocViewBookItemInterface} from 'shared/modules/toc/interfaces/toc-view-book-item.interface';
import {TocViewBookItemModel} from 'shared/modules/toc/models/toc-view-book-item.model';
import {FeedRssService} from 'document/services/feed-rss/feed-rss.service';

@Component({
    selector: 'app-document-page',
    templateUrl: 'document.component.html',
})
export class DocumentPageComponent implements OnInit, OnDestroy, AfterViewInit {

    public readonly documentErrorEnum = DocumentErrorEnum;

    public bodyHeight: string;

    public childHeight: string;

    public bookId: number;

    public demoNoAccess: boolean = false;

    public bookTitle: string;

    public demo: boolean;

    public licenseRequested: boolean;

    public bookCover: string;

    public currentStudyMaterialMethodId: string;

    public dpsid: string;

    public currentBook: ApiBookInterface;

    public search: SearchInterface = {
        affixIcon: IconEnum.Search,
        placeHolder: 'Zoek binnen content',
    };

    public documentToc: TocComponentInterface = {
        visible: false,
        viewBooks: [],
        books: null,
    };

    public versions: Array<ContentVersionsInterface> = [];

    public documents: Array<ApiDocumentDataInterface> = null;

    public document?: ApiDocumentDataInterface = null;

    public buttonModel: DocumentActionsButtonTocModel = new DocumentActionsButtonTocModel(['btn--no-margin']);

    public tocDisabled: boolean = true;

    public loading: boolean = true;

    public assignment: ApiDocumentAssignmentInterface = null;

    public selfStudy: boolean = false;

    public isAssignment: boolean = false;

    public isBpv: boolean = false;

    public isAssignable: boolean = true;

    public eBook?: string;

    public documentError: DocumentErrorEnum = DocumentErrorEnum.Other;

    public constructor(
        @Inject(DOCUMENT) private _document: Document,
        private documentService: DocumentService,
        private documentBookService: DocumentBookService,
        private activatedRoute: ActivatedRoute,
        private routeService: RouteService,
        private ngZone: NgZone,
        private toastService: ToastrService,
        private modalService: ModalService,
        private answerService: DocumentAnswersStateService,
        private roleService: RoleService,
        private documentRolesLogic: DocumentRolesLogic,
        private analyticsService: AnalyticsService,
        private bookService: BookService,
        private feedRssService: FeedRssService,
    ) {
        this.roleService.registerRoleLogic(this.documentRolesLogic);
        window.onresize = () => {
            this.ngZone.run(() => {
                this.resizeTocContainer();
            });
        };
    }

    public ngOnInit(): void {
        this.answerService.reset();
        this.documentBookService.setBook();

        this.activatedRoute.params.subscribe((params: Params) => {
            if (params.documentDpsid && params.bookId) {
                this.dpsid = params.documentDpsid;
                this.bookId = Number(params.bookId);
                this.documentBookService.setBookId(this.bookId);
                this.loadDocumentData();
            }
        });
    }

    public ngAfterViewInit(): void {
        this.resizeTocContainer();
    }

    public ngOnDestroy(): void {
        this.roleService.unregisterLogic(this.documentRolesLogic);
    }

    public doShowToc(): void {
        this.resizeTocContainer();

        if (this.documentToc.viewBooks.length) {
            this.documentToc.visible = true;
        }
    }

    public handleCoverClick(): void {
        if (!this.eBook) {
            return;
        }

        window.open(this.eBook, '_blank');
    }

    public setActiveDocument(version: number): void {
        this.versions = [];
        for (let i = this.documents.length - 1; i >= 0; i--) {
            this.versions.push({
                value: i,
                state: version === i
            });
        }

        this.document = this.documents[version];
        if (this.documents.length > 1 && !this.document.visited) {
            for (const doc of this.documents) {
                doc.visited = true;
            }
            this.modalService.documentVersions();
        }

        this.handleDocumentLoaded();

        this.analyticsService.event(new AnalyticsEventStudyMaterialOpenModel());
    }

    private loadDocumentData(): void {
        this.loading = true;

        this.getDemoStates();

        Promise.all([
            this.loadDocument(),
            this.loadDocumentToc(),
        ]).catch((error: HttpErrorResponse) => {
            this.documents = null;
            this.document = null;

            if (error && error.error && error.error.msg === 'NO_LICENCE_FOR_DOCUMENT') {
                this.toastService.error('U heeft niet de juiste licentie voor dit boek.');
                this.documentError = DocumentErrorEnum.NoValidLicense;
            } else {
                if (error.error.msg !== 'NO_DEMO_ACCESS') {
                    this.toastService.error('Kon document niet laden.');
                    this.documentError = DocumentErrorEnum.Other;
                }
            }

            throw error; // Re-throw error for Sentry
        }).finally(() => {
            this.loading = false;
        });
    }

    private loadDocument(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.documentService
                .retrieveDocumentVersions(this.dpsid, this.bookId.toString())
                .subscribe((documents: Array<ApiDocumentDataInterface>) => {
                    this.documents = documents;
                    this.documentService.setActiveDocument(this.documents);
                    this.setActiveDocument(this.documents.length - 1);
                    this.demoNoAccess = false;
                    this.isAssignable = this.document.assignments.length > 0 ? this.document.assignments[0].is_assignable : false;
                    resolve(); // Resolve promise
                }, (error: any) => {
                    if (error.error.msg === 'NO_DEMO_ACCESS') {
                        this.demoNoAccess = true;
                    }
                    reject(error);
                });
        });
    }

    private loadDocumentToc(): Promise<void> {
        return this.retrieveDocumentToc().then(documentToc => {
            return Promise.all([
                documentToc,
                this.retrieveDocumentTocBooks(),
                documentToc.book.has_rss_feed ? this.feedRssService.loadRssFeedByStudyMaterialMethodId(documentToc.module.studymaterialmethod.id) : Promise.resolve(),
            ]);
        }).then((response: [ApiDocumentDataTocInterface, ApiBooksInterface, void]) => {
            const documentToc: ApiDocumentDataTocInterface = response[0];
            const book = this.currentBook = documentToc.book;
            const viewBooks = this.getViewBooksItems(documentToc.body);
            const books: ApiBooksInterface = response[1];

            this.currentStudyMaterialMethodId = documentToc.module.studymaterialmethod.id;
            this.analyticsService.dimension(AnalyticsDimensionEnum.BookTitle, this.bookTitle);
            this.tocDisabled = viewBooks.length === 0;
            this.bookCover = documentToc.book.cover;
            this.bookTitle = documentToc.book.title;
            this.eBook = book.ebook;
            this.documentBookService.setBook(book);

            if (!this.tocDisabled) {
                this.setExpandedStates(viewBooks);

                this.documentToc = {
                    visible: this.documentToc.visible,
                    books,
                    viewBooks,
                };
            }
        });
    }

    private getDemoStates(): void {
        this.bookService.getBook(String(this.bookId)).subscribe((book: ApiBookInterface) => {
            this.demo = book.demo;
            this.licenseRequested = book.licenseRequested;
        }, (error: any) => {
            console.error(error);
        });
    }

    private retrieveDocumentTocBooks(): Promise<ApiBooksInterface> {
        return new Promise((resolve, reject) => {
            this.bookService.retrieveAllBooks().subscribe(
                (apiBooks: ApiBooksInterface) => resolve(apiBooks),
                (error: any) => reject(error),
            );
        });
    }

    private retrieveDocumentToc(): Promise<ApiDocumentDataTocInterface> {
        return new Promise((resolve, reject) => {
            this.documentService.retrieveDocumentToc(this.dpsid, this.bookId.toString())
                .subscribe(
                    (documentToc: ApiDocumentDataTocInterface) => resolve(documentToc),
                    (error: any) => reject(error),
                );
        });
    }

    private handleDocumentLoaded(): void {
        this.isAssignment = this.document.assignments.length > 0;

        this.analyticsService.dimension(AnalyticsDimensionEnum.AssignmentResourceTitle, this.document.title);
        this.analyticsService.dimension(AnalyticsDimensionEnum.MethodTitle, this.document.studyMaterialTitle);

        const openAssignments = this.findAssignmentsWithTasks();
        if (openAssignments.length === 0) {
            this.assignment = null;
            this.selfStudy = false;
            this.isBpv = false;

            return;
        }

        this.assignment = openAssignments[0];
        this.isBpv = this.assignment.is_bpv === true;
        const task = this.assignment.tasks[this.assignment.tasks.length - 1];
        this.selfStudy = task.examination_needed === ExamineOptionsEnum.Student;
    }

    private findAssignmentsWithTasks(): Array<ApiDocumentAssignmentInterface> {
        return this.document.assignments.filter((assignment: ApiDocumentAssignmentInterface) => assignment.tasks.length > 0);
    }

    private getActiveState(viewBook: TocViewBookItemModel): boolean {
        if (viewBook.dpsid === undefined) {
            return false;
        }

        const params: Map<string, string> = new Map([['bookId', this.bookId.toString()], ['documentDpsid', viewBook.dpsid]]);

        return this.routeService.isActive(RoutesEnum.Document, params);
    }

    private setExpandedStates(viewBooks: Array<TocViewBookItemInterface>): void {
        viewBooks.forEach((viewBook: TocViewBookItemInterface) => {
            if (viewBook.children.length !== 0) {
                this.setExpandedStates(viewBook.children);
            }

            viewBook.expanded = viewBook.children.find(child => child.active || child.expanded) !== undefined;
        });
    }

    /**
     * Convert API structure to item component structure which contains states such as expanded
     */
    private getViewBooksItems(books: Array<ApiTocViewBooksInterface>): Array<TocViewBookItemInterface> {
        const viewBookItems: Array<TocViewBookItemInterface> = [];

        books.forEach((apiViewBook: ApiTocViewBooksInterface) => {
            const viewBook = TocViewBookItemModel.fromApiViewBook(apiViewBook);
            const children: Array<TocViewBookItemInterface> = apiViewBook.children !== undefined ? this.getViewBooksItems(apiViewBook.children) : [];

            viewBookItems.push({
                id: apiViewBook.id || null,
                title: apiViewBook.title,
                type: apiViewBook.type !== undefined ? apiViewBook.type : StudyMaterialTypeEnum.ModuleHtml5,
                doctype: apiViewBook.doctype,
                result: apiViewBook.result,
                disabled: this.demo && apiViewBook.index > 15 ? true : apiViewBook.disabled,
                index: apiViewBook.index,
                active: this.getActiveState(viewBook),
                demo: this.demo ? this.demo : false,
                assignable: apiViewBook.assignmenttype !== 'exercise',
                children,
                viewBook,
            })
            ;
        });

        return viewBookItems;
    }

    private resizeTocContainer(): void {
        this.bodyHeight = `${this._document.body.clientHeight}px`;
        // minus 300 from top
        this.childHeight = `${this._document.body.clientHeight - 300}px`;
    }
}
