import {Component, OnInit} from '@angular/core';
import {FullscreenService} from 'services/fullscreen/fullscreen.service';
import {AuthenticationService} from 'security/services/authentication/authentication.service';
import {AnalyticsService} from 'services/analytics/analytics.service';
import {FaviconService} from 'services/favicon/favicon.service';
import {PublisherService} from 'services/publisher/publisher.service';
import {NotificationService} from 'services/notification/notification.service';
import {MessageService} from 'services/message/message.service';
import {SystemService} from 'services/system/system.service';
import {RoutingRolesLogic} from 'routing/authorization/roles.logic';
import {MercureService} from 'services/mercure/mercure.service';

@Component({
    selector: 'app-component',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {

    public userAuthenticated: boolean = false;

    public fullscreen: boolean = false;

    constructor(
        private authenticationService: AuthenticationService,
        private fullScreenService: FullscreenService,
        private publisherService: PublisherService,
        private pollService: NotificationService,
        private messageService: MessageService,
        private systemService: SystemService,
        private mercureService: MercureService,
        private analyticsService: AnalyticsService,
        private faviconService: FaviconService,
    ) {
        this.publisherService.initialize(new RoutingRolesLogic(this.publisherService));

        this.pollService.initialize();
        this.messageService.initialize();
        this.systemService.initialize();

        this.fullScreenService.fullscreenObservable.subscribe((fullscreen: boolean) => {
            this.fullscreen = fullscreen;
        });
    }

    public ngOnInit(): void {
        this.authenticationService.isAuthenticated.subscribe((authenticated: boolean) => {
            this.userAuthenticated = authenticated;
        });
    }
}
