import {DocumentPageActionsButtonsEnum} from 'pages/modules/document/enums/actions-buttons.enum';
import {DocumentActionButtonInterface} from 'pages/modules/document/interfaces/action-button.interface';
import {DocumentPageAshowAnswersButtonTranslationsEnum} from 'pages/modules/document/enums/show-answers-button-translations.enum';
import {RoleEnum} from 'enums/role.enum';
import {IconEnum} from 'enums/icon.enum';

export class DocumentActionsButtonShowAnswerModel implements DocumentActionButtonInterface {

    public id: DocumentPageActionsButtonsEnum = DocumentPageActionsButtonsEnum.ShowAnswer;

    public value: string = DocumentPageAshowAnswersButtonTranslationsEnum.AnswersHidden;

    public icon: IconEnum = IconEnum.BookAnswers;

    public roles: Array<RoleEnum> = [
        RoleEnum.RoleTeacher,
    ];

    public visible: boolean = true;

    constructor(isBpv: boolean, isSelfStudy: boolean, isAssignment: boolean) {
        if (isBpv || !isAssignment) {
            this.visible = false;
        }

        if (isSelfStudy) {
            this.roles = [RoleEnum.RoleStudentOrTeacher];
        }
    }

}
