export enum LocalStorageKeysEnum {

    UserData = 'user-data',

    RolesData = 'roles-data',

    BookChapterSingleData = 'book-chapter-single-data',

    GroupUserSingleData = 'group-user-single-data',

    MineFilterData = 'mine-filter-data',

    StatusFilterData = 'status-filter-data',

    Publisher = 'publisher-data',

    Group = 'group-filter',
}
