export enum DocumentUserInputInlineClassEnum {

    Input = 'document__input-inline',

    HasValue = 'document__input-inline--has-value',

    Table = 'document__input-inline--table',

    ListStyling = 'document__input-inline--list-styling',

}
