import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {TeacherMaterialFolderModel} from 'pages/modules/teacher-material/models/folder-item.model';
import {TeacherMaterialSourceModel} from 'pages/modules/teacher-material/models/source.model';
import {TeacherMaterialService} from 'services/teacher-material/teacher-material.service';
import {RouteService} from 'routing/services/route/route.service';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {FolderChangeEventInterface} from 'pages/modules/teacher-material/interfaces/folder-change-event.interface';
import {Router} from '@angular/router';

@Component({
    selector: 'app-shelf',
    templateUrl: './shelf.component.html',
})
export class ShelfComponent implements OnChanges {

    @Input()
    public bookId: number;

    @Input()
    public di: boolean;

    @Input()
    public folderDpsid: string | null;

    @Input()
    public loading: boolean;

    @Output()
    public loadingChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input()
    public itemTotal: number;

    @Output()
    public itemTotalChange: EventEmitter<number> = new EventEmitter<number>();

    public displayTotal = 0;

    public readonly routesEnum = RoutesEnum;

    public folders: Array<TeacherMaterialFolderModel> = [];

    public returnFolder: ApiPathComponentInterface;

    public sources: Array<TeacherMaterialSourceModel> = [];

    public path: Array<ApiPathComponentInterface> = [];

    public pathComponentLink: any = [];

    public returnRoute: string;

    public teacherMaterialRoute: string;

    public paginating: boolean = false;

    protected offset: number = 0;

    protected limit: number = 10;

    public constructor(
        private teacherMaterialService: TeacherMaterialService,
        protected routeService: RouteService,
        protected router: Router,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.folderDpsid || changes.bookId) {
            if (this.bookId) {
                this.reset();
            }
            this.loadItems();
        }
    }

    public next(): void {
        if ((this.offset + 1) * this.limit >= this.itemTotal) {
            return;
        }

        this.offset++;
        this.paginating = true;

        this.isLoading(true);
        this.loadItems();
    }

    public handleFolderChange(event: FolderChangeEventInterface, folder: TeacherMaterialFolderModel): void {
        if (folder && folder.disabled) {
            return;
        }

        let route: string;

        this.sources = []; // Clear sources
        this.isLoading(true);

        const params: Map<string, string> = new Map<string, string>([
            ['bookId', event.bookId],
        ]);

        if (event.folderDpsid !== null) {
            params.set('folderDpsid', event.folderDpsid);
            route = this.routeService.getRouteWithPublisher(this.routesEnum.TeacherMaterialBookChapter, params);
        } else {
            route = this.routeService.getRouteWithPublisher(this.routesEnum.TeacherMaterialBook, params);
        }


        this.router.navigate([route]);
    }

    private reset(): void {
        this.sources = [];
        this.folders = [];
    }

    private loadItems(): void {
        if (!this.bookId) {
            return;
        }

        this.teacherMaterialService
            .retrieveForBook(
                this.bookId,
                this.folderDpsid,
                new URLSearchParams([['offset', (this.offset * this.limit).toString()]])
            )
            .subscribe((data: ApiTeacherMaterialsInterface) => {
                this.handleData(data);
            }, () => {
                this.isLoading(false);
            });
    }

    private isLoading(loading: boolean): void {
        this.loading = loading;
        this.loadingChange.emit(loading);
    }

    private setItemTotal(itemTotal: number): void {
        this.itemTotal = itemTotal;
        this.itemTotalChange.emit(itemTotal);
    }

    private handleData(data: ApiTeacherMaterialsInterface): void {
        const folders = [];
        const sources = [];

        for (const folder of data.folders) {
            folders.push(new TeacherMaterialFolderModel(folder.dpsid, folder.title, folder.document_type, folder.total ? folder.total : 0, folder.disabled));
        }

        for (const document of data.documents.data) {
            sources.push(new TeacherMaterialSourceModel(document.id, document.title, document.dpsid, document.studymaterial, document.type, document.disabled, document.document_type, document.sort_title, document.mimetype));
        }

        this.folders = folders;
        this.sources = [...this.sources, ...sources];

        this.path = data.path;

        if (this.path) {
            this.setFolderLinks();
        }

        this.returnFolder = data.previous_folder;
        this.displayTotal = data.total;

        this.setItemTotal(data.folder_total);
        this.setReturnRoute();
        this.setTeacherMaterialRoute();

        this.isLoading(false);
    }

    private setReturnRoute(): void {
        const routeName: RoutesEnum = this.returnFolder ? RoutesEnum.TeacherMaterialBookChapter : RoutesEnum.TeacherMaterialBook;
        const routeParams: Map<string, string> = new Map([['bookId', this.bookId.toString()]]);

        if (this.returnFolder) {
            routeParams.set('folderDpsid', this.returnFolder.dpsid);
        }

        this.returnRoute = this.routeService.getRouteWithPublisher(routeName, routeParams);
    }

    private setTeacherMaterialRoute(): void {
        const routeName: RoutesEnum = RoutesEnum.TeacherMaterialBook;
        const routeParams: Map<string, string> = new Map([['bookId', this.bookId.toString()]]);

        this.teacherMaterialRoute = this.routeService.getRouteWithPublisher(routeName, routeParams);
    }

    private setFolderLinks(): void {
        for (const pathComponent of this.path) {
            this.pathComponentLink[pathComponent.dpsid] = this.routeService.getRouteWithPublisher(this.routesEnum.TeacherMaterialBookChapter, new Map([
                ['bookId', this.bookId.toString()],
                ['folderDpsid', pathComponent.dpsid],
            ]));
        }
    }
}
