import {Inject, Injectable} from '@angular/core';
import {OneDrivePreferencesEnum} from 'pages/modules/user/components/one-drive-preferences/enum/one-drive-preferences.enum';
import {UserService} from 'security/services/user/user.service';
import {OneDriveService} from 'onedrive/services/onedrive.service';
import { DOCUMENT } from '@angular/common';
import {MsAlLoginService} from 'onedrive/services/msallogin.service';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    public constructor(
        private userService: UserService,
        private oneDriveService: OneDriveService,
        private mssallogin: MsAlLoginService,
        @Inject(DOCUMENT) private document: Document
    ) {}

    public openFile(url: string, file: ApiFileInterface, target: string): void {

        if (this.userService.getUserData().use_one_drive &&
                this.mssallogin.getLoggedInUser() === this.userService.getUserData().one_drive_username) {
            this.oneDriveService.saveFile(
                'bestanden',
                url,
                String(file.id + '.' + file.extension),
                this.userService.getUserData().one_drive_open_preference === OneDrivePreferencesEnum.OfficeLocal,
                this.userService.getUserData().one_drive_root
            ).then(
                (weburl) => {
                    this.openUrl(weburl, target);
                },
                () => {
                    this.openUrl(url, target);
                });
        } else {
            this.openUrl(url, target);
        }
    }

    private openUrl(url: string, target: string): void {
        const a = document.createElement('a');
        a.target = target;
        a.href = url;
        a.click();
    }
}
