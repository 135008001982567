import {Component, EventEmitter, Output} from '@angular/core';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GroupsService} from 'services/groups/groups.service';
import {finalize} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {IconBoxClose} from 'models/iconbox/close.model';

@Component({
    selector: 'app-groups-join-group-modal-component',
    templateUrl: 'join-group.component.html',
})
export class GroupsJoinGroupModalComponent {

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorGreen]);

    public readonly codePartLength = '4';

    @Output()
    public groupEvent: EventEmitter<Array<string>> = new EventEmitter();

    public codes: Array<GroupsModuleGroupCodeInterface> = [];

    public isLoading: boolean = false;

    constructor(
        private activeModal: NgbActiveModal,
        private groupsService: GroupsService,
        private toastService: ToastrService,
    ) {
        this.addCode();
    }

    public close(): void {
        this.activeModal.dismiss();
    }

    public addCode(): void {
        this.codes.push({first: '', second: ''});
    }

    public removeCode(index: number): void {
        if (this.codes.length <= 1) {
            this.codes[index].first = '';
            this.codes[index].second = '';

            return;
        }

        this.codes.splice(index, 1);
    }

    public sendCodes(): void {
        const groupCodes: Array<string> = this.getGroupCodes();

        if (!this.validateInput(groupCodes)) {
            return;
        }

        this.isLoading = true;

        this.groupsService.joinGroups(groupCodes)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((groups: Array<ApiGroupInterface>) => {
                if (groups.length > 1) {
                    this.toastService.success(`Je bent toegevoegd aan de ${groups.length} groepen.`);
                } else if (groups.length > 0) {
                    this.toastService.success('Je bent toegevoegd aan de groep.');
                } else {
                    this.toastService.error('1 of meerdere groepscodes zijn onjuist.');
                }

                this.groupEvent.emit(groupCodes);
                this.close();
            }, (errorResponse: HttpErrorResponse) => {
                this.toastService.error('Kon niet aan groep(en) worden toegevoegd.');
            });
    }

    private validateInput(groupCodes: Array<string> ): boolean {
        let isValid = true;
        const codesLength = Object.values(groupCodes).map(c => c.length);
        codesLength.forEach((number: number) => {
            if (number !== 9) {
                isValid = false;
            }
        });

        if (!isValid) {
            this.toastService.warning('1 of meerdere groepscodes zijn onjuist.');
        }

        return isValid;
    }

    public pasteCodeEvent(event: ClipboardEvent, index: number): void {
        let pastedText = event.clipboardData.getData('text');

        if (pastedText.length === 9) {
            pastedText = pastedText.split('-').join('');
        }

        this.codes[index].first = pastedText.slice(0, 4);
        this.codes[index].second = pastedText.slice(4, 8);
    }

    public boxInputChange(content: string, secondBox: HTMLInputElement): void {
        if (content.length < Number(this.codePartLength)) {
            return;
        }

        secondBox.focus();
    }

    private getGroupCodes(): Array<string> {
        const groupCodes: Array<string> = [];

        this.codes.forEach((code: GroupsModuleGroupCodeInterface) => {
            groupCodes.push(`${code.first}-${code.second}`);
        });

        return groupCodes;
    }
}
