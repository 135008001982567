import {Component, Input} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {UserService} from 'security/services/user/user.service';
import {UserIdentityModelFactory} from 'core/factories/user-identity-model.factory';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {finalize} from 'rxjs/operators';
import {UserIdentityModel} from 'core/models/user-identity.model';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-user-details',
    templateUrl: 'user-details.component.html',
})
export class UserDetailsComponent {

    public readonly iconEnum = IconEnum;

    @Input()
    public user: UserIdentityModel;

    @Input()
    public editable: boolean = false;

    public readonly: boolean = true;

    public isLoading: boolean = false;

    private userBackup: UserIdentityModel;

    private selectedFile: File = null;

    public archiveModeActive = environment.archiveModeActive;

    constructor(
        private userService: UserService,
        private toastService: ToastrService,
        private userIdentityModelFactory: UserIdentityModelFactory,
        private sanitizer: DomSanitizer,
    ) {
    }

    public getUserMiddleLastName(): string {
        return ((this.user.middleName || '') + ' ' + (this.user.lastName || '')).trim();
    }

    public toggleEditable(): void {
        this.readonly = !this.readonly;

        if (this.readonly) {
            this.selectedFile = null;
            this.user = UserIdentityModel.fromUser(this.userBackup);
        } else {
            this.userBackup = JSON.parse(JSON.stringify(this.user));
        }
    }

    public uploadAvatar(event: FileList): void {
        if (event.length === 0) {
            return;
        }

        this.selectedFile = event.item(0);
        this.user.avatar = <string>this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.selectedFile));
    }

    public saveProfile(): void {
        this.isLoading = true;

        let promise = null;

        if (this.selectedFile) {
            promise = this.userService.updateAvatar(this.selectedFile).toPromise();
        } else {
            promise = Promise.resolve();
        }

        promise
            .catch(() => {
                this.toastService.error('Kon avatar niet bijwerken.');
                this.isLoading = false;
            })
            .then(() => {
                this.userService.editProfile(this.user.toApiBody())
                    .pipe(finalize(() => this.isLoading = false))
                    .subscribe(() => {
                        this.toastService.success('Profiel is bijgewerkt.');

                        this.toggleEditable();
                    }, (error: HttpErrorResponse) => {
                        this.toastService.error('Kon profiel niet bijwerken.');
                    });
            });

    }
}
