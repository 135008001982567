export enum ResultClassEnum {
    ResultRight = 'result-cell--right',
    ResultWrong = 'result-cell--wrong',
    ResultWrongWrong = 'result-cell--wrongwrong',
    ResultRetry = 'result-cell--retry',
    ResultPass = 'result-cell--pass',

    ResultHandedIn = 'result-cell--handed-in',
    ResultResit = 'result-cell--resit',
    ResultCreated = 'result-cell--created',
    ResultDoing = 'result-cell--doing',
    ResultTooLate = 'result-cell--too-late',
    ResultDeadLine = 'result-cell--dead-line',
    ResultNumeric = 'result-cell--numeric',

    Progress = 'result-cell--progress',
    Other = 'result-cell--other',
}
