import {ProgressStudentInterface} from 'pages/modules/progress/interfaces/student.interface';
import {ProgressScoreInterface} from 'pages/modules/progress/interfaces/score.interface';
import {ProgressAssignmentStatusInterface} from 'pages/modules/progress/interfaces/assignment-status.interface';
import {ProgressModuleStatusInterface} from 'pages/modules/progress/interfaces/module-status.interface';
import {ProgressDrillsterStatusInterface} from 'pages/modules/progress/interfaces/drillster-status.interface';
import {ProgressExamsInterface} from 'pages/modules/progress/interfaces/exams.interface';

export class ProgressStudentModel implements ProgressStudentInterface {

    constructor(
        public id: number,
        public name: string,
        public score?: ProgressScoreInterface,
        public drillsterStatusses?: Array<ProgressDrillsterStatusInterface>,
        public assignmentStatuses?: Array<ProgressAssignmentStatusInterface>,
        public moduleStatus?: ProgressModuleStatusInterface,
        public exams?: Array<ProgressExamsInterface>,
    ) {
    }

}
