import {ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RouteService} from 'routing/services/route/route.service';
import {PageWrapperClassEnum} from 'shared/modules/page/enums/page-wrapper/class.enum';
import {InfiniteScroll} from 'shared/classes/infinite-scroll.abstract';
import {Observable, Subscription} from 'rxjs';
import {SearchService} from 'services/search/search.service';
import {SearchParametersEnum} from 'pages/modules/search/enums/search-parameters.enum';
import {SearchDocumentTypeFilterEnum} from 'pages/modules/search/enums/document-type-filter.enum';
import {SearchResultsFactory} from 'pages/modules/search/factories/results.factory';
import {SearchResultInterface} from 'pages/modules/search/interfaces/search-result.interface';
import {TabItemInterface} from 'shared/modules/tab/interfaces/tab-item.interface';
import {IconEnum} from 'enums/icon.enum';
import {SearchFilterTabModel} from 'pages/modules/search/models/tab.model';
import {SearchInterface} from 'shared/modules/search/interfaces/search.interface';
import {PageRowClassEnum} from 'shared/modules/page/enums/page-row/class.enum';

@Component({
    selector: 'app-search-page',
    templateUrl: 'search.component.html',
})
export class SearchPageComponent extends InfiniteScroll<ApiSearchInterface> implements OnInit, OnDestroy {

    @HostBinding('class')
    protected classes = 'container-fluid container-fluid--width';

    public readonly pageWrapperClasses = PageWrapperClassEnum;

    public readonly pageRowClasses = PageRowClassEnum;

    public items: Array<SearchResultInterface> = [];

    public documentTypeFilter: Array<TabItemInterface> = [
        new SearchFilterTabModel(SearchDocumentTypeFilterEnum.All, 'Alle', true),
        new SearchFilterTabModel(SearchDocumentTypeFilterEnum.Resources, 'Lesmaterialen', false, IconEnum.Book),
        new SearchFilterTabModel(SearchDocumentTypeFilterEnum.Tasks, 'Taken', false, IconEnum.Check),
        new SearchFilterTabModel(SearchDocumentTypeFilterEnum.Notes, 'Notities', false, IconEnum.Note),
    ];

    public search: SearchInterface = {
        affixIcon: IconEnum.Search,
        placeHolder: 'Zoeken', // TODO: translate me
        classModifiers: [],
    };

    public query: string;

    public itemTotal: number = 0;

    protected limit: number = 24;

    private activeDocumentTypeFilter: SearchDocumentTypeFilterEnum = SearchDocumentTypeFilterEnum.All;

    private routeQueryParamSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private routeService: RouteService,
        private searchService: SearchService,
        private searchFactory: SearchResultsFactory,
        protected change: ChangeDetectorRef,
    ) {
        super(change);
    }

    public ngOnInit(): void {
        this.routeQueryParamSubscription = this.route.queryParams.subscribe((params: Params) => {
            this.query = params[SearchParametersEnum.Query];

            this.loadData(true);
        });

        // super.ngOnInit();
    }

    public ngOnDestroy(): void {
        if (this.routeQueryParamSubscription) {
            this.routeQueryParamSubscription.unsubscribe();
        }

        super.ngOnDestroy();
    }

    public onFilterButtonChange(tabModel: TabItemInterface): void {
        this.activeDocumentTypeFilter = (tabModel as SearchFilterTabModel).key;

        this.loadData(true);
    }

    public onSearchChange(search: string): void {
        this.query = search;

        this.loadData(true);
    }

    protected loadData(reset: boolean = false): void {
        if (!this.dataValid()) {
            return;
        }

        super.loadData(reset);
    }

    protected getDataLength(): number {
        return this.itemTotal;
    }

    protected getLoadDataObservable(): Observable<ApiSearchInterface> {
        let documentType = this.activeDocumentTypeFilter;

        if (documentType === SearchDocumentTypeFilterEnum.All) {
            documentType = null;
        }

        return this.searchService.search(
            this.query,
            this.getRequestOffset(),
            this.limit,
            documentType,
        );
    }

    protected subscribe(response: ApiSearchInterface): void {
        this.itemTotal = response.total;
        this.items = [...this.items, ...this.searchFactory.fromSearchResults(response.result)];
    }

    protected reset(): void {
        super.reset();

        this.items = [];
        this.itemTotal = 0;
    }

    private dataValid(): boolean {
        if (!this.query || this.query.length === 0) {
            return false;
        }

        return true;
    }
}
