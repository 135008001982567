import {NgModule} from '@angular/core';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {AuthenticationService} from 'security/services/authentication/authentication.service';
import {RoleService} from 'security/services/role/role.service';
import {AuthorizationIsGrantedPipe} from 'security/pipes/authorization-is-granted.pipe';
import {UserService} from 'security/services/user/user.service';
import {CommonModule} from '@angular/common';
import {AuthorizationNotGrantedPipe} from 'security/pipes/authorization-not-granted.pipe';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        AuthorizationIsGrantedPipe,
        AuthorizationNotGrantedPipe,
    ],
    exports: [
        AuthorizationIsGrantedPipe,
        AuthorizationNotGrantedPipe,
    ],
    providers: [
        AuthenticationService,
        AuthorizationService,
        RoleService,
        UserService,
    ],
})
export class SecurityModule {
}
