import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ExamUserResultService {

    private resultSubject: BehaviorSubject<ApiExamUserResultInterface | null> = new BehaviorSubject(null);

    private result: ApiExamUserResultInterface;

    public subscribe(fn: (result: ApiExamUserResultInterface | null) => void): Subscription {
        return this.resultSubject.subscribe(fn);
    }

    public setResult(result?: ApiExamUserResultInterface): void {
        this.result = result;
        this.resultSubject.next(result);
    }
}
