import {Pipe, PipeTransform} from '@angular/core';
import {TaskService} from 'services/task/task.service';
import {ResultStateEnum} from 'shared/components/result-cell/enums/result-state.enum';
import {ProgressAssignmentStatusInterface} from 'pages/modules/progress/interfaces/assignment-status.interface';

@Pipe({
    name: 'assignmentStatusState'
})
export class AssignmentStatusStatePipe implements PipeTransform {
    constructor(private taskService: TaskService) {
    }

    public transform(assignmentStatus: ProgressAssignmentStatusInterface = null): Array<ResultStateEnum> {
        return this.taskService.getAssignmentStatusStates(assignmentStatus);
    }
}
