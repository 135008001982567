import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HorizontalBarComponent} from 'shared/modules/horizontal-bar/components/horizontal-bar.component';
import {HorizontalBarIconComponent} from 'shared/modules/horizontal-bar/components/icon/icon.component';
import {HorizontalBarTextComponent} from 'shared/modules/horizontal-bar/components/text/text.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        HorizontalBarComponent,
        HorizontalBarIconComponent,
        HorizontalBarTextComponent,
    ],
    exports: [
        HorizontalBarComponent,
        HorizontalBarIconComponent,
        HorizontalBarTextComponent,
    ],
})
export class HorizontalBarModule {
}
