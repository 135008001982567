import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MessageCenterComponent} from 'pages/modules/message-center/components/message-center.component';
import {SharedModule} from 'shared/shared.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {MessageCenterMessageFactory} from 'pages/modules/message-center/factories/message.factory';
import {MessageCenterMessageTypeFactory} from 'pages/modules/message-center/factories/message-type.factory';
import {MessageCenterMessageDetailsModalComponent} from 'pages/modules/message-center/modals/message-details/message-details.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        InfiniteScrollModule,
    ],
    declarations: [
        MessageCenterComponent,
        MessageCenterMessageDetailsModalComponent,
    ],
    providers: [
        MessageCenterMessageFactory,
        MessageCenterMessageTypeFactory,
    ],
    entryComponents: [
        MessageCenterMessageDetailsModalComponent,
    ],
})
export class MessageCenterModule {
}
