import {BookshelfItemInterface} from 'app/modules/pages/modules/bookshelf/interfaces/item.interface';
import {BookshelfItemTypeEnum} from 'app/modules/pages/modules/bookshelf/components/item/enums/item-type.enum';
import {BookshelfItemClassEnum} from 'pages/modules/bookshelf/components/item/enums/item-class.enum';
import {IconEnum} from 'enums/icon.enum';

export class BookshelfItemModel implements BookshelfItemInterface {

    public type: BookshelfItemTypeEnum = BookshelfItemTypeEnum.Book;

    public classModifiers: Array<string> = [];

    public icon: IconEnum;

    constructor(
        public id: number,
        public title: string,
        public dummy: boolean,
        public demo: boolean,
        public isFavorite: boolean,
        public dpsid: string,
        public cover: string|null,
        public studymaterialmethod: ApiStudyMaterialMethodInterface = null
    ) {
        if (this.dummy) {
            this.classModifiers.push(BookshelfItemClassEnum.Dummy);
            this.icon = IconEnum.HourGlass;
        }
    }
}
