import {Component, ComponentRef, Input, OnChanges, SimpleChanges, ViewChild, ViewContainerRef} from '@angular/core';
import {DocumentJitAnchorDirective} from 'document/modules/components/jit-content/jit-anchor.directive';
import {DynamicComponentInterface, JitComponentFactory} from 'document/factories/jit-component.factory';

@Component({
    selector: 'document-jit-content',
    templateUrl: 'jit.component.html'
})
export class DocumentJitComponent implements OnChanges {
    @Input()
    public content: string;

    @Input()
    public attributes: {[key: string]: any};

    @ViewChild(DocumentJitAnchorDirective, {read: ViewContainerRef, static: true})
    public htmlAnchor: ViewContainerRef;

    private jitComponent: ComponentRef<DynamicComponentInterface>;

    constructor(
        private componentFactoryFactory: JitComponentFactory
    ) {}

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        await this.setContent();
    }

    private async setContent(): Promise<void> {
        if (!this.content || !this.htmlAnchor) {
            return;
        }

        this.deleteExistingJitComponent();

        this.jitComponent = await this.componentFactoryFactory.create(this.htmlAnchor, this.content, this.attributes);
        this.htmlAnchor.insert(this.jitComponent.hostView);
    }

    private deleteExistingJitComponent(): void {
        if (this.jitComponent instanceof ComponentRef) {
            this.htmlAnchor.clear();
            delete this.jitComponent;
        }
    }
}
