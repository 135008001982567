import {AfterViewInit, ContentChildren, QueryList} from '@angular/core';
import {DocumentContentMediaComponent} from 'document/modules/components/content-media/content-media.component';
import {DocumentUserInputInlineComponent} from 'document/modules/components/user-input-inline/user-input-inline.component';
import {DocumentContentMediaClassEnum} from 'document/modules/components/content-media/enums/class.enum';
import {DocumentUserInputInlineClassEnum} from 'document/modules/components/user-input-inline/enums/class.enum';
import {DocumentSingleSelectInlineComponent} from 'document/modules/components/single-select-inline/single-select-inline.component';
import {DocumenSingleSelectInlineClassEnum} from 'document/modules/components/single-select-inline/enums/class.enum';

export abstract class TableComponent implements AfterViewInit {
    @ContentChildren(DocumentContentMediaComponent, {descendants: true})
    public mediaComponents: QueryList<DocumentContentMediaComponent>;

    @ContentChildren(DocumentUserInputInlineComponent, {descendants: true})
    public inputInlineComponents: QueryList<DocumentUserInputInlineComponent>;

    @ContentChildren(DocumentSingleSelectInlineComponent, {descendants: true})
    public selectInlineComponents: QueryList<DocumentSingleSelectInlineComponent>;

    public ngAfterViewInit(): void {
        this.mediaComponents.forEach((mediaComponent: DocumentContentMediaComponent) => {
            mediaComponent.classModifiers.push(DocumentContentMediaClassEnum.Table);
        });

        this.inputInlineComponents.forEach((inputComponent: DocumentUserInputInlineComponent) => {
            inputComponent.classHelper.addClass(DocumentUserInputInlineClassEnum.Table);
            inputComponent.setClass();
        });

        this.selectInlineComponents.forEach((selectComponent: DocumentSingleSelectInlineComponent) => {
            selectComponent.classModifiers.addClass(DocumenSingleSelectInlineClassEnum.Table);
            selectComponent.setClass();
        });
    }
}
