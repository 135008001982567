import {EventEmitter, Injectable} from '@angular/core';
import {RoleService} from 'security/services/role/role.service';
import {PublisherDataInterface} from 'services/publisher/interfaces/data.interface';
import {UserService} from 'security/services/user/user.service';
import {AnalyticsService} from 'services/analytics/analytics.service';
import {AnalyticsDimensionEnum} from 'services/analytics/enums/dimension.enum';
import {PublisherFactory} from 'services/publisher/factories/publisher.factory';
import {AnalyticsEventPublisherUsedModel} from 'services/analytics/models/publisher-used.model';
import {RouterStateSnapshot} from '@angular/router';
import {LocalStorageService} from 'angular-2-local-storage';
import {LocalStorageKeysEnum} from 'enums/local-storage-keys.enum';
import {RolesLogicInterface} from 'security/interfaces/roles-logic.interface';

@Injectable()
export class PublisherService {

    protected activePublisher: PublisherDataInterface;

    private publisherCache: {[name: string]: PublisherDataInterface} = {};

    public onPublisherChange: EventEmitter<PublisherDataInterface> = new EventEmitter();

    constructor(
        private roleService: RoleService,
        private userService: UserService,
        private analyticsService: AnalyticsService,
        private publisherFactory: PublisherFactory,
        private localStorageService: LocalStorageService,
    ) {}

    public initialize(defaultRoleLogic: RolesLogicInterface): void {
        this.roleService.registerRoleLogic(defaultRoleLogic);

        this.activePublisher = this.localStorageService.get(LocalStorageKeysEnum.Publisher);

        if (!this.activePublisher) {
            return;
        }

        this.onPublisherChange.emit(this.activePublisher);
    }

    public getFromState(state: RouterStateSnapshot): string|null {
        return state.url.split('/')[1] || null;
    }

    public setPublisher(name: string): void {
        if (this.activePublisher && this.activePublisher.name === name) {
            return;
        }

        this.activePublisher = this.getPublisherByName(name);

        this.analyticsService.dimension(AnalyticsDimensionEnum.PublisherSlug, name);
        this.analyticsService.event(new AnalyticsEventPublisherUsedModel());

        this.localStorageService.set(LocalStorageKeysEnum.Publisher, this.activePublisher);

        this.onPublisherChange.emit(this.activePublisher);
        this.roleService.reloadRoles();
    }

    public getActivePublisher(): PublisherDataInterface | undefined {
        return this.activePublisher;
    }

    public isValidPublisher(): boolean {
        return this.getActivePublisher() !== undefined;
    }

    protected getPublisherByName(name: string): PublisherDataInterface | undefined {
        const user = this.userService.getUserData();

        if (!user.publisher || user.publisher.slug !== name) {
            return undefined;
        }

        return this.getFromCache(name);
    }

    private getFromCache(name: string): PublisherDataInterface {
        const user = this.userService.getUserData();

        if (this.publisherCache[name]) {
            return this.publisherCache[name];
        }

        const publisher = this.publisherFactory.fromApiPublisherInterface(user.publisher);

        this.publisherCache[name] = publisher;

        return publisher;
    }
}
