import {Component, OnInit} from '@angular/core';
import {DocumentService} from 'document/services/document/document.service';
import {RoleEnum} from 'enums/role.enum';
import {ModalService} from 'core/services/modal/modal.service';
import {ToastrService} from 'ngx-toastr';
import {DocumentSourceFactory} from 'document/modules/factories/source.factory';
import {DocumentSourceInterface} from 'document/modules/interfaces/source.interface';
import {OneDriveService} from 'onedrive/services/onedrive.service';
import {UserIdentityModelFactory} from 'core/factories/user-identity-model.factory';
import {UserService} from 'security/services/user/user.service';
import {UserIdentityModel} from 'core/models/user-identity.model';
import {IconEnum} from 'enums/icon.enum';
import mime from 'mime-types';
import {UrlUtil} from 'shared/util/url.util';
import {ApiUserDataInterface} from 'interfaces/api/user-data.interface';
import {environment} from 'environments/environment';


@Component({
    selector: 'app-document-sources',
    templateUrl: 'sources.component.html',
})
export class DocumentPageActionsSourcesComponent implements OnInit {

    public readonly roleEnum = RoleEnum;

    public readonly iconEnum = IconEnum;

    public userIdentity: UserIdentityModel;

    public archiveModeActive: boolean = environment.archiveModeActive;

    constructor(
        private toastService: ToastrService,
        private referenceFactory: DocumentSourceFactory,
        private modalService: ModalService,
        public documentService: DocumentService,
        private oneDriveService: OneDriveService,
        private userIdentityModelFactory: UserIdentityModelFactory,
        private userService: UserService,
    ) {
    }

    public ngOnInit(): void {
        const userData = this.userService.getUserData();
        if (userData) {
            this.retrieveUser(userData);
        }
    }

    public create(event: MouseEvent): void {
        event.preventDefault();

        this.modalService.source()
            .subscribe((response: ApiSourceInterface) => {
                this.documentService.addResource(this.referenceFactory.fromApiSource(response));
            }, (reason?: undefined | string) => {
                if (reason !== undefined) {
                    this.toastService.error(reason);
                }
            });
    }

    public edit(resource: DocumentSourceInterface): void {
        this.modalService.source(resource)
            .subscribe((response: ApiSourceInterface) => {
                if (response === null) { // Removed resource
                    this.documentService.removeResourceById(resource.id);
                } else {
                    this.documentService.addOrReplaceResource(this.referenceFactory.fromApiSource(response));
                }
            }, (reason?: undefined | string) => {
                if (reason !== undefined) {
                    this.toastService.error(reason);
                }
            });
    }

    public openLink(resource: DocumentSourceInterface): void {
        const url: string = resource.link;
        const isFile: boolean = resource.type === 'file';
        const target: string = resource.target ? resource.target : '_self';

        if ((isFile || resource.icon === this.iconEnum.Attachment)) {
            const fileName = isFile ? 'uploaded_' + resource.id + '.' + mime.extension(resource.mimeType) : UrlUtil.getURLSearchParamOrReturnUrl(url, 'dpsid');

            this.oneDriveService.openDocument(this.documentService.getBookTitle(), fileName, url, target);
        } else {
            window.open(url, target);
        }
    }

    private retrieveUser(userData: ApiUserDataInterface): void {
        this.userIdentity = this.userIdentityModelFactory.fromUserData(userData);
    }
}
