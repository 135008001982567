import {
    Component,
    ComponentRef,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import {HtmlAnchorDirective} from 'document/directives/html-anchor.directive';
import {DocumentService} from 'document/services/document/document.service';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';
import {DynamicComponentInterface, JitComponentFactory} from 'document/factories/jit-component.factory';
import {DocumentNotesTriggerDirective} from 'document/components/notes/trigger/trigger.directive';
import {DocumentSummaryDirective} from 'document/directives/summary/summary.directive';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-document',
    templateUrl: 'document.component.html'
})

export class DocumentComponent implements OnInit, OnChanges {

    private static jitComponent: ComponentRef<DynamicComponentInterface>;

    @ViewChild(DocumentNotesTriggerDirective, {static: true})
    private noteTrigger: DocumentNotesTriggerDirective;

    @ViewChild(DocumentSummaryDirective, {static: true})
    private showSummary: DocumentSummaryDirective;

    @Input()
    public bookId: number;

    @Input()
    public documentNotesEnabled: boolean = true;

    @Input()
    public documentSummaryEnabled: boolean = true;

    @Input()
    public document: ApiDocumentDataInterface;

    @Input()
    public classModifiers: Array<string> = [];

    @ViewChild(HtmlAnchorDirective, {read: ViewContainerRef, static: true})
    public htmlAnchor: ViewContainerRef;

    @HostBinding('class')
    public elementClass: string = DocumentHostClassesEnum.Document;

    public archiveModeActive = environment.archiveModeActive;

    constructor(private documentService: DocumentService, private componentFactory: JitComponentFactory) {
    }

    public ngOnInit(): void {
        if (this.classModifiers.length > 0) {
            this.classModifiers.push(this.elementClass);
            this.elementClass = this.classModifiers.join(' ');
        }
        this.noteTrigger.enabled = this.documentNotesEnabled;
        this.showSummary.enabled = this.documentSummaryEnabled;
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.bookId && null !== changes.bookId.currentValue) {
            this.documentService.setBookId(this.bookId);
        }

        if (changes.document && null !== changes.document.currentValue) {
            this.documentService.setDocument(this.document);
            await this.loadDynamicHtml();
        }
    }

    private async loadDynamicHtml(): Promise<void> {
        if (DocumentComponent.jitComponent instanceof ComponentRef) {
            this.htmlAnchor.clear(); // Clear previous contents
            delete DocumentComponent.jitComponent;
        }

        DocumentComponent.jitComponent = await this.componentFactory.create(this.htmlAnchor, this.document.body);
        this.htmlAnchor.insert(DocumentComponent.jitComponent.hostView);
    }
}
