import {Pipe, PipeTransform} from '@angular/core';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {RouteService} from 'routing/services/route/route.service';

@Pipe({
    name: 'publisherRoute',
})
export class PublisherRoutePipe implements PipeTransform {

    constructor(
        private routeService: RouteService,
    ) {
    }

    public transform(route: RoutesEnum, params?: Array<[string, string]>|{[key: string]: string|number}): string {
        let paramsMap: Map<string, string> = null;

        if (params instanceof Array) {
            paramsMap = new Map(params);
        } else {
            paramsMap = new Map();

            // tslint:disable-next-line:forin
            for (const key in params) {
                paramsMap.set(key, String(params[key]));
            }
        }

        return this.routeService.getRouteWithPublisher(route, paramsMap);
    }
}
