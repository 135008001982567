import {Directive, ElementRef, HostBinding, HostListener} from '@angular/core';
import {Router} from '@angular/router';
import {DocumentSourceFactory} from 'document/modules/factories/source.factory';
import {DocumentService} from 'document/services/document/document.service';
import {DocumentKeyEventService} from 'document/services/key-event/key-event.service';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';
import {DocumentResourceLinkService} from 'document/services/resource/link.service';

@Directive({
    selector: '.document-resourcelink',
})
export class DocumentResourceLinkDirective {

    @HostBinding('class')
    protected elementClass: string = DocumentHostClassesEnum.ResourceLink;

    protected nativeElement: HTMLAnchorElement = null;

    protected dataSet: ApiResourceLinkDataInterface;

    protected linkData?: ApiResourceLinkDataLinkInterface;

    constructor(
        private element: ElementRef,
        private router: Router,
        private keyEventService: DocumentKeyEventService,
        private linkService: DocumentResourceLinkService,
        private documentService: DocumentService,
        private sourceFactory: DocumentSourceFactory,
    ) {
        this.nativeElement = this.element.nativeElement;
        this.dataSet = this.element.nativeElement.dataset;
        this.linkData = this.linkService.getLinkFromResourceLinkData(this.dataSet);

        this.handleDataset();
        this.handleBodyResourceLink();
    }

    private handleDataset(): void {
        if (this.dataSet.link) {
            this.linkService.setLinkPropertiesByResourceLink(this.element, this.dataSet);
        } else if (this.dataSet.anchor) {
            this.element.nativeElement.href = window.location + this.dataSet.anchor;
        } else {
            this.element.nativeElement.target = '_blank';
        }
    }

    private handleBodyResourceLink(): void {
        if (!this.nativeElement.parentElement || this.nativeElement.parentElement.tagName !== 'NG-COMPONENT') {
            return;
        }

        this.documentService.addResource(this.sourceFactory.fromBody(
            this.nativeElement.title,
            this.nativeElement.href,
            this.nativeElement.target,
        ));
    }

    @HostListener('click', ['$event'])
    public goToRoute(event: MouseEvent): void {

        if (this.linkData || this.dataSet.anchor ) {
            event.preventDefault();

            if (this.linkData && this.linkData.ext !== 'other' && !this.keyEventService.ctrlClick) {
                this.router.navigateByUrl(this.linkService.getResourceURLFromLinkData(this.linkData));
            } else {
                this.nativeElement.hash = this.dataSet.anchor;

                const elementSource = window.document.querySelector(this.dataSet.anchor);

                if (elementSource) {
                    elementSource.scrollIntoView({behavior: 'smooth'});
                }
            }
        }
    }
}
