export enum ActionButtonClassEnum {

    Green = 'action-button--color-24boost-green',

    Purple = 'action-button--color-24boost-purple',

    White = 'action-button--color-24boost-white',

    Orange = 'action-button--color-24boost-orange',

    Demo= 'action-button--demo-bar action-button--color-24boost-demo',

    Exam = 'action-button--color-24boost-red',

    ShowAnswer = 'action-button--color-24boost-blue-darker',

    MessageCenter = 'action-button--color-24boost-blue-lighter',

    OrangeInversed = 'action-button--color-24boost-orange-inversed',

    Disabled = 'action-button--disabled',

    IconOnly = 'action-button--icon-only',

    IconOnlyInverted = 'action-button--icon-only-inversed',

    DropDownVisible = 'action-button--dropdown-visible',

    DropDownItem = 'action-button--dropdown-item',

    Mobile = 'action-button--mobile',

    Heading = 'action-button--heading',

    MediaLargeOnly = 'action-button--large-only',

    MediaSmallAndDown = 'action-button--small-and-down',

    MobileIconOnly = 'action-button--mobile-icon-only',

    ModalHeader = 'action-button--modal-header',

}
