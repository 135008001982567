import {Component, Input} from '@angular/core';
import {WorkTypeEnum} from 'shared/components/work-type-label/enums/work-type.enum';
import {WorkTypeLetterEnum} from 'shared/components/work-type-label/enums/work-type-letter.enum';

@Component({
    selector: 'app-work-type-label',
    templateUrl: 'work-type-label.component.html',
})
export class WorkTypeLabelComponent {

    public readonly workTypeLetterEnum = WorkTypeLetterEnum;

    @Input()
    public workType: WorkTypeEnum;

    @Input()
    public classModifiers: Array<string> = [];

}
