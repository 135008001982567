import {IconEnum} from 'enums/icon.enum';
import {Injectable} from '@angular/core';
import {DocumentLinkDataEnum} from 'document/enums/document-link-data.enum';

@Injectable()
export class DocumentReferenceService {

    public getIconFromDataset(dataset: ApiResourceLinkDataInterface): IconEnum {
        let linkData: any = null;

        try {
            linkData = JSON.parse(dataset.link);
        } catch (e) {
            // Just catch
        }

        const value: DocumentLinkDataEnum = linkData && linkData.ext ? linkData.ext : DocumentLinkDataEnum.Global;

        switch (value) {
            case DocumentLinkDataEnum.Other:
                return IconEnum.Attachment;

            case DocumentLinkDataEnum.Html:
                return IconEnum.Other;

            default:
                return IconEnum.Global;
        }
    }
}
