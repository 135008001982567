import {Pipe, PipeTransform} from '@angular/core';
import {TaskService} from 'services/task/task.service';
import {ResultStateEnum} from 'shared/components/result-cell/enums/result-state.enum';

@Pipe({
    name: 'taskState'
})
export class TaskStatePipe implements PipeTransform {
    constructor(private taskService: TaskService) {}

    public transform(task: ApiTaskDetailsInterface): Array<ResultStateEnum> {
        if (task === undefined || task === null) {
            return [];
        }

        const taskState = task.children && task.children.length > 0 ? task.children[0] : task;

        return this.taskService.getTaskStates(taskState);
    }
}
