import {NgModule} from '@angular/core';
import {DeeplinkBookIdComponent} from 'pages/modules/deeplink/bookId/bookId.component';
import {SharedModule} from 'shared/shared.module';
import {CommonModule} from '@angular/common';
import {DeeplinkEanComponent} from 'pages/modules/deeplink/ean/ean.component';
import {RouterModule} from '@angular/router';
import {DeeplinkDpsidComponent} from 'pages/modules/deeplink/dpsid/dpsid.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
    ],
    declarations: [
        DeeplinkEanComponent,
        DeeplinkDpsidComponent,
        DeeplinkBookIdComponent,
    ],
    exports: [
        DeeplinkEanComponent,
        DeeplinkDpsidComponent,
        DeeplinkBookIdComponent,
    ],
})
export class DeeplinkModule {
}
