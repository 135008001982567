import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {HomeworkStatusEnum} from 'enums/homework-status.enum';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {StringService} from 'services/string/string.service';
import {ResultCodeEnum} from 'shared/components/result-cell/enums/result-code.enum';

@Injectable()
export class HomeworkService {

    private homeWorkLoadedEventSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private api: ApiService,
        private stringService: StringService,
    ) {
    }

    public retrieveHomework(homeworkId: number): Observable<ApiHomeworkCurrentInterface> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.Homework, new Map([
            ['homeworkId', homeworkId.toString()],
        ]));

        return this.api.get<ApiHomeworkCurrentInterface>(apiRoute);
    }

    public handInHomework(homeworkId: number, result: ResultCodeEnum): Observable<any> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.HomeworkHandIn, new Map([
            ['homeworkId', homeworkId.toString()],
        ]));

        return this.api.post<any>(apiRoute, {
            examination_result: result,
            status: HomeworkStatusEnum.StatusExamined
        });
    }

    public handInOwnTask(taskId: number, params: any): Observable<ApiHomeworkOwnTaskHandedInInterface> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.HomeworkHandInOwnTask, new Map([
            ['taskId', taskId.toString()],
        ]));

        return this.api.post<ApiHomeworkOwnTaskHandedInInterface>(apiRoute, params);
    }

    public handInOverride(taskId: number): Observable<any> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.HomeworkHandInOverride, new Map([
            ['taskId', taskId.toString()],
        ]));

        return this.api.post<any>(apiRoute);
    }

    public emit(value: boolean): void {
        this.homeWorkLoadedEventSubject.next(value);
    }

    public subscribeHomeworkLoaded(fn: (loaded: boolean) => void): Subscription {
        return this.homeWorkLoadedEventSubject.subscribe(fn);
    }
}
