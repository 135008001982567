import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {UserService} from 'security/services/user/user.service';
import {SearchInterface} from 'shared/modules/search/interfaces/search.interface';
import {InfiniteScroll} from 'shared/classes/infinite-scroll.abstract';
import {Observable, Subscription} from 'rxjs';
import {ContentSelectItemInterface} from 'shared/modules/content-select/interfaces/select-item.interface';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {SearchPrefixedDefaultModel} from 'shared/models/search/prefixed.model';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';
import {ExamService} from 'services/exam/exam.service';
import {ExamsStatusFilterEnum} from 'pages/modules/exams/enums/exams-status-filter.enum';
import {ExamsGroupsFilterEnum} from 'pages/modules/exams/enums/exams-groups-filter.enum';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {RoleEnum} from 'enums/role.enum';

@Component({
    selector: 'app-exams',
    templateUrl: 'exams.component.html'
})
export class ExamsComponent extends InfiniteScroll<ApiExamMonthsInterface> implements OnInit, OnDestroy {

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly searchConfig: SearchInterface = new SearchPrefixedDefaultModel('Zoek een toets', null);

    public readonly examsClassModifiers: Array<ContentSelectClassModifiersEnum> = [
        ContentSelectClassModifiersEnum.Exams,
        ContentSelectClassModifiersEnum.Grey,
    ];

    public readonly statusFilterOptions = new Map([
        [ExamsStatusFilterEnum.All, 'Alle'],
        [ExamsStatusFilterEnum.ReviewNeeded, 'Na te kijken'],
        [ExamsStatusFilterEnum.Specified, 'Opgegeven'],
        [ExamsStatusFilterEnum.Done, 'Nagekeken'],
    ]);

    public readonly statusFilterInitial = ExamsStatusFilterEnum.All;

    public months: ApiExamMonthsInterface = null;

    public future: boolean = true;

    public showFilters: boolean = true;

    public contentSwitchData: Array<ContentSelectItemInterface> = [];

    public showGroupsFilter: boolean = true;

    public bookId: number;

    public demo: boolean;

    public licenseRequested: boolean;

    protected limit: number = 8;

    private search: string = '';

    private selectedGroup: ApiGroupInterface;

    private selectedStudent: ApiUserInterface;

    private userChangedSubscription: Subscription;

    private selectedStatus: string | ExamsStatusFilterEnum;

    public constructor(
        private authorizationService: AuthorizationService,
        private examService: ExamService,
        private userService: UserService,
        protected change: ChangeDetectorRef,
    ) {
        super(change);
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.demo = false;

        this.setStudentFilters();

        super.ngOnInit();

        this.userChangedSubscription = this.userService.onUserDataChange.subscribe(() => this.setStudentFilters());
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this.userChangedSubscription) {
            this.userChangedSubscription.unsubscribe();
        }
    }

    public onStatusFilterChange(filter: ExamsStatusFilterEnum | string): void {
            this.selectedStatus = filter;

            this.loadData(true);
    }

    public onGroupsFilterChange(group: ApiGroupInterface): void {
        if (this.selectedGroup !== group) {
            this.selectedGroup = group;

            this.loadData(true);
        }
    }

    public onStudentsFilterChange(student: ApiUserInterface): void {
        if (this.selectedStudent !== student) {
            this.selectedStudent = student;

            this.loadData(true);
        }
    }

    public onSearchChange(search: string): void {
        this.search = search;
        this.loadData(true);
    }

    public toggleFuture(): void {
        this.future = !this.future;
        this.loadData(true);
    }

    public toggleFilters(): void {
        this.showFilters = !this.showFilters;
    }

    public onContentItemClick(event: Array<ContentSelectItemInterface>): void {
        this.contentSwitchData = event;

        this.loadData(true);
    }

    public onLoadExamPlans(weekId: string): void {
        const activeGroup = this.getActiveGroupOrStudent();
        const activeBook = this.getActiveBookOrChapter();

        this.examService.retrieveExamPlans(
            weekId,
            this.search,
            this.selectedStatus,
            activeGroup.groupId,
            activeGroup.studentId,
            activeBook.bookId,
            activeBook.chapterId
        ).subscribe((examPlans: ApiExamPlansInterface) => {
            for (const month of this.months.data) {
                if (month.id === weekId) {
                    month.examPlans = examPlans;
                    break;
                }
            }
        });
    }

    protected loadData(reset: boolean = false): void {
        if (!this.isFilterSelectionReady()) {
            return;
        }

        super.loadData(reset);
    }

    protected isFilterSelectionReady(): boolean {
        const isGrantedTeacher: boolean = this.authorizationService.isGranted(RoleEnum.RoleTeacher);
        const teacherHasUndefinedSelection: boolean = isGrantedTeacher && this.selectedStudent === undefined;
        const hasGroupSelection: boolean = this.showGroupsFilter ? this.selectedGroup !== undefined : true;
        const hasContentSwitchData: boolean = this.contentSwitchData.length !== 0;
        const hasSelectedStatus: boolean = this.selectedStatus !== undefined;

        return (hasGroupSelection && hasContentSwitchData && hasSelectedStatus && !teacherHasUndefinedSelection);
    }

    protected getDataLength(): number {
        return this.months ? this.months.total : 0;
    }

    protected getLoadDataObservable(): Observable<ApiExamMonthsInterface> {
        const activeGroup = this.getActiveGroupOrStudent();
        const activeBook = this.getActiveBookOrChapter();

        return this.examService.retrieveMonths(
            this.future,
            this.getRequestOffset(),
            this.limit.toString(),
            this.search,
            this.selectedStatus,
            activeGroup.groupId,
            activeGroup.studentId,
            activeBook.bookId,
            activeBook.chapterId
        );
    }

    protected subscribe(response: ApiExamMonthsInterface): void {
        if (this.months !== null) {
            this.months.data = [...this.months.data, ...response.data];
            this.months.total = response.total;
        } else {
            this.months = response;
        }
    }

    protected reset(): void {
        super.reset();

        this.months = null;
    }

    private setStudentFilters(): void {
        const user = this.userService.getUserData();

        if (user.role !== RoleEnum.RoleStudent) {
            return;
        }

        this.showGroupsFilter = user.group_count >= 1;
    }

    private getDefaultOptionsForStudentsFilter(): Map<string | number, string> {
        return new Map<string | number, string>([
            [ExamsGroupsFilterEnum.All, 'Alle studenten'],
        ]);
    }

    private getActiveGroupOrStudent(): any {
        const ret = {groupId: null, studentId: null};

        if (this.selectedGroup) {
            ret.groupId = this.selectedGroup.id;

            if (this.selectedStudent) {
                ret.studentId = this.selectedStudent.id;
            }
        }

        return ret;
    }

    private getActiveBookOrChapter(): any {
        const ret = {bookId: null, chapterId: null, demo: false, licenseRequested: false};

        booksLoop:
            for (const book of this.contentSwitchData) {
                if (book.active === true) {
                    ret.bookId = book.id;
                    ret.demo = book.demo ? book.demo : false;
                    ret.licenseRequested = book.licenseRequested ? book.licenseRequested : false;
                    break;
                }

                for (const chapter of book.children) {
                    if (chapter.active === true) {
                        ret.bookId = book.id;
                        ret.chapterId = chapter.id;
                        ret.demo = book.demo ? book.demo : false;
                        ret.licenseRequested = book.licenseRequested ? book.licenseRequested : false;
                        break booksLoop;
                    }
                }
            }

        if (ret && ret.bookId) {
            this.bookId = ret.bookId;
            this.demo = ret.demo;
            this.licenseRequested = ret.licenseRequested;
        }

        return ret;
    }
}
