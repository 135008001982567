import {BookshelfItemTypeEnum} from 'app/modules/pages/modules/bookshelf/components/item/enums/item-type.enum';
import {BookshelfItemClassEnum} from 'app/modules/pages/modules/bookshelf/components/item/enums/item-class.enum';
import {IconEnum} from 'enums/icon.enum';
import {BookshelfItemInterface} from 'app/modules/pages/modules/bookshelf/interfaces/item.interface';

export class BookshelfTeachingMaterialsModel implements BookshelfItemInterface {

    public id: number = -1;

    public isFavorite: boolean = false;

    public title: string = 'Lesmateriaal beheren'; // TODO: 1251 translations

    public type: BookshelfItemTypeEnum = BookshelfItemTypeEnum.TeachingMaterials;

    public icon: string = IconEnum.TeachingMaterial;

    public classModifiers: Array<string> = [
        BookshelfItemClassEnum.Icon,
    ];

    public studymaterialmethod: ApiStudyMaterialMethodInterface = null;

}
