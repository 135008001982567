import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-remove-toggle-component',
    templateUrl: 'remove-toggle.component.html',
})
export class RemoveToggleComponent {

    @Output()
    public removeClick: EventEmitter<void> = new EventEmitter();

    @Output()
    public cancelRemove: EventEmitter<boolean> = new EventEmitter();

    public removing: boolean = false;

    public handleRemove(event: MouseEvent): void {
        event.stopPropagation();

        this.removeClick.emit();
    }

    public toggleRemoving(event: MouseEvent): void {
        event.stopPropagation();

        this.removing = !this.removing;
        this.cancelRemove.emit(this.removing);
    }

}
