import {Component, OnDestroy} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {AvatarClassesEnum} from 'shared/components/avatar/enums/avatar-classes.enum';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {ExamStateInterface, ExamStateService} from 'pages/modules/content-exam/service/exam-state.service';
import {Subscription} from 'rxjs';
import {ExamMetadataService} from 'services/exam/exam-metadata.service';
import {HorizontalBarTextClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-text-classes.enum';
import {RoleEnum} from 'enums/role.enum';

@Component({
    selector: 'app-content-exam-result',
    templateUrl: 'result.component.html',
})
export class ContentExamResultComponent implements OnDestroy {

    private subscriptions: Subscription[] = [];

    public readonly tableClasses = GridTableClassEnum;

    public readonly rowClasses = GridTableRowClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly horizontalBarTextClasses = HorizontalBarTextClassesEnum;

    public readonly avatarClasses = AvatarClassesEnum;

    public readonly iconEnum = IconEnum;

    public readonly roleEnum = RoleEnum;

    public examResult: ApiContentExamResultInterface;

    public attempt: number;

    public showResultsTable: boolean;

    public showResultsHeader: boolean;

    public assignmentResults: Array<ApiPracticeExamAssignmentResultsInterface>;

    public totalScore: number = 0;

    public ExamFinishDate: string;

    public showPracticeExamText: boolean;

    public student: ApiExamUserInterface;

    constructor(examStateService: ExamStateService, private examMetadataService: ExamMetadataService) {
        this.subscriptions.push(examStateService.subscribe(examState => this.onExamState(examState)));
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    private onExamState(examState: ExamStateInterface): void {
        const examResult = this.examMetadataService.getResultByAttempt(examState.attempt);
        if (undefined === examResult) {
            return;
        }

        const student = this.examMetadataService.getStudentDataForTeacher();

        this.examResult = examResult;
        this.showResultsTable = examState.showResults;
        this.showResultsHeader = examState.hasResults;
        this.attempt = examState.attempt;
        this.totalScore = examResult.totalScore;
        this.assignmentResults = examResult.assignmentResults;
        this.ExamFinishDate = examResult.completed;
        this.showPracticeExamText = this.examMetadataService.isPractiseExam();
        this.student = student;
    }
}
