export enum TocViewBooksBookClassEnum {

    IndentSmall = 'toc-component__book--indent-small',

    IndentLarge = 'toc-component__book--indent-large',

    Expanded = 'toc-component__book--expanded',

    Root = 'toc-component__book--root',

}
