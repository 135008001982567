import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import {DocumentSortableBlockOptionsInterface} from 'document/modules/components/sortable-block/interface/options.interface';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';
import {DocumentAnswerableComponent} from 'document/modules/abstract/answerable.component';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {DocumentSortableBlockOptionInterface} from 'document/modules/components/sortable-block/interface/option.interface';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AutoCheckService } from 'document/services/autocheck/auto-check.service';
import {RoleEnum} from 'enums/role.enum';
import {DocumentService} from 'document/services/document/document.service';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';

// VOLGORDE VRAGEN

@Component({
    selector: '.document-sortableblock',
    templateUrl: 'sortable-block.component.html',
})
export class DocumentSortableBlockComponent extends DocumentAnswerableComponent implements AfterViewInit, OnDestroy {

    @HostBinding('class')
    public elementClass: string = DocumentHostClassesEnum.SortableBlock;

    public readonly roleEnum = RoleEnum;

    public readonly examinationNeededEnum = ExamineOptionsEnum;

    public examinationByStudent: boolean = false;

    public id: string;

    public options: DocumentSortableBlockOptionsInterface;

    public isImageSortableBlock: boolean = false;

    public correctAnswers: Array<DocumentSortableBlockOptionInterface> = [];

    public initialAnswers: Array<DocumentSortableBlockOptionInterface>;

    protected dataSet: DocumentDatasetOptionsInterface;

    public answerHidden: boolean;

    public autoScoreEnabled: boolean;

    public autoScoreAnswers: any;

    private subscriptions: Array<Subscription> = [];

    constructor(
        protected elementRef: ElementRef,
        protected answersService: DocumentAnswersService,
        protected showAnswersService: DocumentAnswersStateService,
        private autocheckService: AutoCheckService,
        private documentService: DocumentService,
    ) {
        super(elementRef, answersService, showAnswersService);

        this.id = `sortable-block-${this.dataSet.assignmentid}-${this.dataSet.fieldid}`;

        try {
            this.options = JSON.parse(this.dataSet.options);
            for (const choice of this.options.choices) {
                if (choice.image) {
                    this.isImageSortableBlock = true;
                    break;
                }
            }
        } catch (e) {
            this.initializationFailed = true;

            console.error('Failed to JSON parse the options:', e);
        }
    }

    public ngAfterViewInit(): void {
        this.setupAutoScoring();
        this.handleExaminationNeeded();
    }

    public handleDrop(event: CdkDragDrop<Array<string>>): void {
        if (this.answersDisabled) {
            return;
        }

        moveItemInArray(this.options.choices, event.previousIndex, event.currentIndex);

        this.saveAnswer(this.options.choices.map(choice => choice.value).join(','));
    }

    private setupAutoScoring(): void {

        const assignmentId = this.dataSet.assignmentid;
        const fieldId = parseInt(this.dataSet.fieldid, 10);
        const assignment = this.answersService.getAssignment(assignmentId);
        this.autoScoreEnabled = assignment && assignment.auto_check;

        if (! this.autoScoreEnabled) { return; }

        this.subscriptions.push( this.autocheckService.getAutoCheckAnswerForFieldId(assignmentId, fieldId).pipe(take(1)).subscribe( autoCheckAnswer => {

            if (autoCheckAnswer) {
                this.autoScoreAnswers = this.handleAnswers(autoCheckAnswer);
            }
        }));

    }

    private handleExaminationNeeded(): void {
        const task = this.documentService.getActiveTask();

        // examination by Student
        if (task && task.examination_needed === this.examinationNeededEnum.Student) {
            this.examinationByStudent = this.documentService.subscribeSelfStudyCompatible();
        }
    }


    protected setAnswer(answer: string): void {
        if (!answer) {
            return;
        }

        this.options.choices = this.handleAnswers(answer);
    }

    protected hideCorrectAnswer(): void {
        this.elementClass = this.deleteClass(this.elementClass, `${DocumentHostClassesEnum.SortableBlock}--has-correct-answer`);
        this.answerHidden = true;
    }

    protected showCorrectAnswer(correctAnswer: string): void {

        this.correctAnswers = this.handleAnswers(correctAnswer);

        this.answerHidden = false;

        this.elementClass = `${this.elementClass} ${DocumentHostClassesEnum.SortableBlock}--has-correct-answer`;
    }


    protected showInitialAnswer(initialAnswer: string): void {
        if (!initialAnswer) {
            return;
        }

        this.initialAnswers = this.handleAnswers(initialAnswer);

        this.elementClass = `${this.elementClass} ${DocumentHostClassesEnum.SortableBlock}--has-initial-answer`;
    }

    protected hideInitialAnswer(): void {
        this.elementClass = this.deleteClass(this.elementClass, `${DocumentHostClassesEnum.SortableBlock}--has-initial-answer`);
    }

    protected answerValid(): boolean {
        return true;
    }

    private handleAnswers(answer: string): Array<DocumentSortableBlockOptionInterface> {
        const answers = [];

        if (!answer) {
            return answers;
        }

        const splittedAnswer = answer.split(',');

        for (const answerValue of splittedAnswer) {
            for (const choice of this.options.choices) {
                if (choice.value === answerValue) {
                    answers.push(choice);
                    break;
                }
            }
        }

        return answers;
    }

    private deleteClass(classes: string, classToRemove: string): string {
        const classList = classes.split(' ');

        classList.splice(classes.indexOf(classToRemove), 1);

        return classList.join(' ');
    }

    public ngOnDestroy(): void {
        this.autocheckService.resetAutoCheckAnswer();
        this.subscriptions.map(sub => sub.unsubscribe());
    }
}
