export enum ActivationStatusEnum {

    Success = 1,

    ErrorCodeUnknown = 100,

    ErrorUserAlreadyHasLicense = 110,

    ErrorCodeAlreadyActivated = 120,

    ErrorCodeInvalid = 130,

    ErrorCodeExpired = 140,

    ErrorSoap = 300,

    ErrorSoapActivation = 310,

    ErrorUnknown = 320,

}
