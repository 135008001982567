import {ProgressDrillsterStatusInterface} from 'pages/modules/progress/interfaces/drillster-status.interface';

export class ProgressDrillsterStatusModel implements ProgressDrillsterStatusInterface {

    constructor(
        public id: number|null,
        public dpsid: string,
        public result: number,
        public drillster_id: number,
        public title?: string,
    ) {
    }

    public static fromApiProgressStudentScoreDrillsterStatus(
        drillsterStatus: ApiProgressStudentScoreDrillsterStatusInterface,
    ): ProgressDrillsterStatusModel {
        return new this(
            drillsterStatus.id,
            drillsterStatus.dpsid,
            drillsterStatus.result,
            drillsterStatus.drillster_id,
            drillsterStatus.title,
        );
    }
}
