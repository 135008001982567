import {Pipe, PipeTransform} from '@angular/core';
import {ResultCodeEnum} from 'shared/components/result-cell/enums/result-code.enum';

@Pipe({
    name: 'taskResult'
})
export class TaskResultPipe implements PipeTransform {
    public transform(task: ApiTaskDetailsInterface): ResultCodeEnum {
        if (task === undefined || task === null || !task.homework || task.homework.length === 0) {
            return null;
        }

        return task.homework[0].examination_result;
    }
}
