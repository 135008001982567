import {TabItemInterface} from 'shared/modules/tab/interfaces/tab-item.interface';
import {IconEnum} from 'enums/icon.enum';
import {SearchDocumentTypeFilterEnum} from 'pages/modules/search/enums/document-type-filter.enum';

export class SearchFilterTabModel implements TabItemInterface {

    public classModifiers: Array<string> = [];

    constructor(public key: SearchDocumentTypeFilterEnum, public title: string, public active: boolean = false, public icon: IconEnum = null) {
    }
}
