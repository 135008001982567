import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-exams-exam-check-question',
    templateUrl: 'question.component.html',
})
export class ExamsExamCheckQuestionComponent {

    @Input()
    public examPlan: ApiExamPlanDetailsInterface;

    @Input()
    public answer: ApiExamOpenAnswerInterface;

}
