import {Component} from '@angular/core';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DrillsterInterface} from 'document/modules/interfaces/drillster.interface';
import {IconBoxClose} from 'models/iconbox/close.model';

@Component({
    selector: 'app-drillster-modal',
    templateUrl: 'drillster-modal.component.html',
})
export class DrillsterModalComponent {

    public readonly iconBox = new IconBoxClose([IconBoxClassEnum.Drillster]);

    public drillster: DrillsterInterface;

    constructor(
        private activeModal: NgbActiveModal,
    ) {
    }

    public close(): void {
        this.activeModal.dismiss('Cross click');
    }
}
