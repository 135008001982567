import {Pipe, PipeTransform} from '@angular/core';
import {TaskService} from 'services/task/task.service';
import {ResultStateEnum} from 'shared/components/result-cell/enums/result-state.enum';

@Pipe({
    name: 'homeworkState'
})
export class HomeworkStatePipe implements PipeTransform {
    constructor(private taskService: TaskService) {}

    public transform(homework: ApiTaskDetailsHomeworkInterface = null): Array<ResultStateEnum> {
        return this.taskService.getHomeworkStates(homework);
    }
}
