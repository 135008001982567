import {Injectable} from '@angular/core';
import {ProgressDrillsterStatusModel} from 'pages/modules/progress/models/drillster-status.model';

@Injectable()
export class ProgressDrillsterStatusFactory {

    public fromApiProgressStudent(progressStudent: ApiProgressStudentInterface): Array<ProgressDrillsterStatusModel> {
        const statuses: Array<ProgressDrillsterStatusModel> = [];

        progressStudent.score.drillster_status.forEach((drillsterStatus: ApiProgressStudentScoreDrillsterStatusInterface) => {
            statuses.push(ProgressDrillsterStatusModel.fromApiProgressStudentScoreDrillsterStatus(drillsterStatus));
        });

        return statuses;
    }

    public fromApiProgressForStudent(progressForStudent: ApiProgressForStudentInterface): Array<ProgressDrillsterStatusModel> {
        const studentScore: ApiProgressStudentScoreInterface = progressForStudent.studymaterials[0].scores[0];

        const statuses: Array<ProgressDrillsterStatusModel> = [];

        if (studentScore.drillster_status !== undefined) {
            studentScore.drillster_status.forEach((drillsterStatus: ApiProgressStudentScoreDrillsterStatusInterface) => {
                statuses.push(ProgressDrillsterStatusModel.fromApiProgressStudentScoreDrillsterStatus(drillsterStatus));
            });
        }

        return statuses;
    }
}
