import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IsTeacherDirective} from 'shared/modules/directives/components/is-teacher.directive';
import {IsStudentDirective} from 'shared/modules/directives/components/is-student.directive';
import {RepeatElementDirective} from 'shared/modules/directives/components/repeat-element.directive';
import {PreventDoubleClickDirective} from 'shared/modules/directives/components/prevent-double-click.directive';
import {IsReviewerDirective} from 'shared/modules/directives/components/is-reviewer.directive';
import {IsNotReviewerDirective} from 'shared/modules/directives/components/is-not-reviewer.directive';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        IsTeacherDirective,
        IsStudentDirective,
        IsReviewerDirective,
        IsNotReviewerDirective,
        RepeatElementDirective,
        PreventDoubleClickDirective,
    ],
    exports: [
        IsTeacherDirective,
        IsStudentDirective,
        IsReviewerDirective,
        IsNotReviewerDirective,
        RepeatElementDirective,
        PreventDoubleClickDirective,
    ],
})
export class DirectivesModule {
}
