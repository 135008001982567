import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BookshelfItemInterface} from 'app/modules/pages/modules/bookshelf/interfaces/item.interface';
import {BookshelfItemTypeEnum} from 'pages/modules/bookshelf/components/item/enums/item-type.enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {environment} from 'environments/environment';
import {RouteService} from 'routing/services/route/route.service';

@Component({
    selector: 'app-bookshelf-item',
    templateUrl: 'item.component.html',
})
export class BookshelfItemComponent implements OnInit, OnChanges {

    @Input()
    public item: BookshelfItemInterface;

    @Output()
    public itemClick: EventEmitter<BookshelfItemInterface> = new EventEmitter();

    @Output()
    public toggleFavourite: EventEmitter<BookshelfItemInterface> = new EventEmitter();

    public type: BookshelfItemTypeEnum;

    public isFavorite: boolean = false;

    public isDemo: boolean = false;

    public showActions: boolean = false;

    public classHelper: ClassHelper = new ClassHelper();

    public routerLink: string = null;

    public archiveModeActive: boolean = environment.archiveModeActive;

    public constructor(
        private routeService: RouteService,
    ) {}

    public ngOnInit(): void {
        if (this.item.classModifiers && this.item.classModifiers.length > 0) {
            this.classHelper.addClasses(this.item.classModifiers);
        }

        this.item.type = this.item.type || BookshelfItemTypeEnum.Book;
        this.isFavorite = this.item.isFavorite;
        this.isDemo = this.item.demo;
        this.showActions = this.item.type === BookshelfItemTypeEnum.Book;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.setRoute();
    }

    public onClick(): void {
        if (this.item.dummy) {
            return;
        }

        this.itemClick.emit(this.item);
    }

    public toggleFavorite($event: Event): void {
        $event.preventDefault();
        $event.stopPropagation();

        this.toggleFavourite.emit(this.item);
    }

    public toggleIsFavorite(): void {
        this.isFavorite = !this.isFavorite;
    }

    private setRoute(): void {
        if (!this.item) {
            return;
        }

        switch (this.item.type) {
            case BookshelfItemTypeEnum.Book:
                if (this.item.dummy !== true) {
                    this.routerLink = this.routeService.getRouteWithPublisher(RoutesEnum.Document, new Map([
                        ['bookId', String(this.item.id)],
                        ['documentDpsid', this.item.dpsid],
                    ]));
                }

                return;

            case BookshelfItemTypeEnum.TeacherMaterials:
                this.routerLink = this.routeService.getRouteWithPublisher(RoutesEnum.TeacherMaterial);

                return;
        }

        this.routerLink = null;
    }
}
