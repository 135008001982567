import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {Observable} from 'rxjs';
import {StringService} from 'services/string/string.service';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';

@Injectable({
    providedIn: 'root'
})
export class FeedbackService {

    public constructor(
        private api: ApiService,
        private stringService: StringService,
    ) {}

    public sendFeedback(homeworkId: number, remark: string): Observable<ApiFeedbackInterface> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.FeedbackSubmit, new Map([
            ['homeworkId', homeworkId.toString()],
        ]));

        return this.api.post<ApiFeedbackInterface>(apiRoute, {
            message: remark
        });
    }
}
