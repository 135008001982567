import {Pipe, PipeTransform} from '@angular/core';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {RoleEnum} from 'enums/role.enum';
import {RoleService} from 'security/services/role/role.service';
import {AuthorizationIsGrantedPipe} from 'security/pipes/authorization-is-granted.pipe';

@Pipe({
    name: 'isNotGranted',
    pure: false,
})
export class AuthorizationNotGrantedPipe extends AuthorizationIsGrantedPipe implements PipeTransform {

    constructor(
        protected roleService: RoleService,
        protected authorizationService: AuthorizationService,
    ) {
        super(roleService, authorizationService);
    }

    /**
     * Check if input variables change because this is not a pure pipe and return the cached
     * state to prevent unnecessary code execution.
     */
    public transform(role: RoleEnum | Array<RoleEnum>): boolean {
        return !super.transform(role);
    }
}
