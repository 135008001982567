import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {Observable} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {StringService} from 'services/string/string.service';

@Injectable()
export class HelpPageContentService {

    private firstTime: boolean;

    constructor(
        private api: ApiService,
        private stringService: StringService,
    ) {
    }

    public retrieveContentByPage(pageId: string): Observable<Array<HelpPageInterface>> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.HelpPage, new Map([
            ['pageId', pageId.toString()],
        ]));

        return this.api.get<Array<HelpPageInterface>>(apiRoute);
    }

    public skipHelpers(): Observable<any> {
        return this.api.post(ApiEndpointEnum.SkipHelpers);
    }

    public setFirstTime(firstTime: boolean): void {
        this.firstTime = firstTime;
    }

    public getFirstTime(): boolean {
        return this.firstTime;
    }
}
