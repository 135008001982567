import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CheckBoxInterface} from 'shared/components/check-box/interfaces/check-box.interface';
import {CheckBoxModelInterface} from 'shared/components/check-box/interfaces/check-box-model.interface';

@Component({
    selector: 'app-check-box',
    templateUrl: 'check-box.component.html',
})
export class CheckBoxComponent implements ClassModifierInterface {

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public options: Array<CheckBoxInterface>;

    @Input()
    public model: CheckBoxModelInterface;

    @Output()
    public modelChange: EventEmitter<CheckBoxModelInterface> = new EventEmitter();

    @Input()
    public disabled: boolean = false;

    public id: string;

    constructor() {
        this.id = Math.random().toString(36).substring(9);
    }

    public onModelChange(optionId: number, checked: boolean): void {
        if (this.disabled) {
            return;
        }

        this.model[optionId] = checked;
        this.modelChange.emit(this.model);
    }
}
