import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';
import {IconEnum} from 'enums/icon.enum';
import {CollapsibleClassEnum} from 'shared/components/collapsible/enums/class.enum';

@Component({
    selector: 'app-collapsible-component',
    templateUrl: 'collapsible.component.html',
})
export class CollapsibleComponent implements OnInit {

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public title: string;

    @Input()
    public subTitle?: string;

    @Input()
    public icon: IconEnum = IconEnum.Chevron;

    @Input()
    public logo?: IconEnum;

    @Input()
    public initiallyExpanded: boolean = false;

    @Output()
    public expanded: EventEmitter<string> = new EventEmitter();

    @Output()
    public collapsed: EventEmitter<string> = new EventEmitter();

    public classHelper: ClassHelper = new ClassHelper();

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classModifiers);

        if (this.initiallyExpanded === true) {
            this.handleExpand();
        }

        if (this.logo) {
            this.classModifiers.push(CollapsibleClassEnum.HasLogo);
        }
    }

    public handleExpand(): void {
        const classModifier: string = CollapsibleClassEnum.Expand;

        this.classHelper.toggleClass(classModifier);
        if (this.classHelper.hasClass(classModifier)) {
            this.expanded.emit(this.title);
        } else {
            this.collapsed.emit(this.title);
        }
    }
}
