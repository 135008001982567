import {Component, Input, OnInit} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';

@Component({
    selector: 'app-progress-bar-component',
    templateUrl: 'progress-bar.component.html',
})
export class ProgressBarComponent implements OnInit {

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public progressValue: number;

    @Input()
    public progressTitle: string = 'Taken worden opgegeven..';

    @Input()
    public isFinished: boolean;

    private classHelper: ClassHelper = new ClassHelper();

    public ngOnInit(): void {
        if (this.classModifiers) {
            this.classHelper.addClasses(this.classModifiers);
        }
    }
}
