import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DocumentActionButtonInterface} from 'pages/modules/document/interfaces/action-button.interface';

@Component({
    selector: 'app-document-page-actions-button',
    templateUrl: 'button.component.html',
})
export class DocumentPageActionsButtonComponent {

    @Input()
    public model: DocumentActionButtonInterface;

    @Output()
    public buttonClick: EventEmitter<DocumentActionButtonInterface> = new EventEmitter();

    @Input()
    public isloading: boolean;

    public doClick(): void {
        this.buttonClick.emit(this.model);
    }
}
