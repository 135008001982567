import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ResultCodeEnum} from 'shared/components/result-cell/enums/result-code.enum';
import {ResultStateEnum} from 'shared/components/result-cell/enums/result-state.enum';
import {ScoringStatusEnum} from 'shared/components/scoring/enums/status.enum';
import {environment} from 'environments/environment';

@Component({
    selector: 'app-scoring',
    templateUrl: 'scoring.component.html',
})
export class ScoringComponent {

    public readonly statusEnum = ScoringStatusEnum;

    public readonly scoresEnum = ResultCodeEnum;

    public readonly resultStateEnum = ResultStateEnum;

    @Input()
    public isGFAnswerModel: boolean;

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public status: ScoringStatusEnum;

    @Output()
    public statusChange: EventEmitter<ScoringStatusEnum> = new EventEmitter();

    @Input()
    public score: ResultCodeEnum;

    @Output()
    public scoreChange: EventEmitter<ResultCodeEnum> = new EventEmitter();

    @Input()
    public allowResit: boolean = false;

    @Input()
    public onlyResit: boolean = false;

    public archiveModeActive = environment.archiveModeActive;

    public handleSubmitScore(score: ResultCodeEnum): void {
        this.score = score;
        this.scoreChange.emit(this.score);
    }
}
