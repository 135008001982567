import {Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren} from '@angular/core';
import {ContentSwitchGroupModel} from 'pages/modules/tasks/components/modals/add-modal/models/group-model';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {ContentSwitchStudentModel} from 'pages/modules/tasks/components/modals/add-modal/models/student-model';
import {IconEnum} from 'enums/icon.enum';
import {AddTaskTaskGroupInterface} from 'pages/modules/tasks/components/modals/add-modal/interfaces/add-task-task-group.interface';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';
import {ContentSelectComponent} from 'shared/modules/content-select/components/content-select.component';

@Component({
    selector: 'app-tasks-add-modal-user-selection-groups',
    templateUrl: 'groups.component.html',
})
export class TasksAddTaskModalUserSelectionGroupsComponent implements OnChanges {

    public readonly iconBoxEnum = IconBoxClassEnum;

    @Input()
    public groups: Array<ContentSwitchGroupModel> = [];

    @Input()
    public taskGroups: Array<AddTaskTaskGroupInterface> = [];

    @Output()
    public taskGroupsChange: EventEmitter<Array<AddTaskTaskGroupInterface>> = new EventEmitter();

    @Input()
    public disabled: boolean = false;

    @ViewChildren('studentSelector')
    public studentSelectors: QueryList<ContentSelectComponent>;

    public groupsClassModifiers: Array<ContentSelectClassModifiersEnum> = [
        ContentSelectClassModifiersEnum.GroupStudents,
        ContentSelectClassModifiersEnum.Grey,
    ];

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.taskGroups && changes.taskGroups.currentValue) {
            for (const taskGroup of changes.taskGroups.currentValue) {
                this.onTaskGroupStudentsChange(taskGroup);
            }
        }

        if (this.taskGroups.length === 0) {
            this.addNewTaskGroup();
        }
    }

    public onTaskGroupStudentsChange(taskGroup: AddTaskTaskGroupInterface): void {
        if (this.disabled) { return; }

        taskGroup.students = [];
        const addedStudentsCache: any = {};

        // Set the active list of students
        for (const group of taskGroup.groups) {
            for (const student of group.children) {
                if (!group.active && !student.active) {
                    continue;
                }

                if (addedStudentsCache[student.parentId + '-' + student.id]) {
                    continue;
                }

                taskGroup.students.push(student);
                addedStudentsCache[student.parentId + '-' + student.id] = student;
            }
        }

        this.deduplicateSelectedStudents();

        this.taskGroupsChange.emit(this.taskGroups);
    }

    public getGroupStudentIcon(item: ContentSwitchGroupModel | ContentSwitchStudentModel): string {
        return item instanceof ContentSwitchGroupModel ? IconEnum.Users : IconEnum.User;
    }

    public addNewTaskGroup(): void {
        this.taskGroups.push({
            title: '',
            students: [],
            groups: JSON.parse(JSON.stringify(this.groups))
        });

        this.deduplicateSelectedStudents();
    }

    public removeTaskGroup(taskGroup: AddTaskTaskGroupInterface): void {
        this.taskGroups.splice(this.taskGroups.indexOf(taskGroup), 1);

        if (this.taskGroups.length === 0) {
            this.addNewTaskGroup();
        }
    }

    private deduplicateSelectedStudents(): void {
        const activeStudentCache: any = this.getListOfActiveStudents();
        const activeStudentCacheKeys = Object.keys(activeStudentCache);

        for (let i = 0; i < this.taskGroups.length; i++) {
            const taskGroup = this.taskGroups[i];

            const groupsCopy = JSON.parse(JSON.stringify(this.groups));

            for (const group of groupsCopy) {
                const studentsToRemove = [];

                for (const student of group.children) {
                    const studentId = student.id;
                    const parentId = student.parentId;

                    if (activeStudentCacheKeys.indexOf(parentId + '-' + studentId) < 0) {
                        continue;
                    }

                    if (activeStudentCache[parentId + '-' + studentId] === i) {
                        student.active = true;
                        continue;
                    }

                    studentsToRemove.push(student);
                }

                for (const student of studentsToRemove) {
                    group.children.splice(group.children.indexOf(student), 1);
                }
            }

            taskGroup.groups = groupsCopy;
        }

        if (this.studentSelectors) {
            this.studentSelectors.forEach((component: ContentSelectComponent) => {
                component.updateDisplayedItems();
            });
        }
    }

    private getListOfActiveStudents(): any {
        const activeStudentCache: any = {};

        for (let i = 0; i < this.taskGroups.length; i++) {
            const taskGroup = this.taskGroups[i];

            for (const group of taskGroup.groups) {
                for (const student of group.children) {
                    if (!!activeStudentCache[student.parentId + '-' + student.id]) {
                        continue;
                    }

                    if (group.active || student.active) {
                        activeStudentCache[student.parentId + '-' + student.id] = i;
                    }
                }
            }

            for (const student of taskGroup.students) {
                if (!student.active) {
                    continue;
                }

                activeStudentCache[student.parentId + '-' + student.id] = i;
            }
        }

        return activeStudentCache;
    }
}
