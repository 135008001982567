import {Injectable} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {RoleEnum} from 'enums/role.enum';
import {AnalyticsEventInterface} from 'services/analytics/interfaces/event.interface';
import {AnalyticsDimensionEnum} from 'services/analytics/enums/dimension.enum';
import {AnalyticsEventAuthenticatedUserLoadModel} from 'services/analytics/models/authenticated-user-load.model';
import {ApiUserDataInterface} from 'interfaces/api/user-data.interface';

declare var ga: any;

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    private readonly roles = new Map<RoleEnum, string>([
        [RoleEnum.RoleStudent, 'student'],
        [RoleEnum.RoleTeacher, 'teacher'],
        [RoleEnum.RoleReviewer, 'reviewer'],
    ]);

    constructor(
        private router: Router,
    ) {
        this.initialize();
    }

    private initialize(): void {
        this.router.events.subscribe((event: RouterEvent) => this.onNavigationEndEvent(event));
    }

    private onNavigationEndEvent(event: RouterEvent): void {
        if (!(event instanceof NavigationEnd)) {
            return;
        }

        try {
            this.pageView(event.urlAfterRedirects);
        } catch (e) {
            console.error('Failed pageView ga:', e.message);
        }
    }

    public pageView(url: string): void {
        if (undefined !== ga) {
            ga('set', 'page', url);
        }

        if (undefined !== ga) {
            ga('send', 'pageview');
        }
    }

    public setUser(user: ApiUserDataInterface): void {
        try {
            if (undefined !== ga) {
                ga('set', 'userId', user.id);
            }

            this.dimension(AnalyticsDimensionEnum.UserRole, this.roles.get(user.role));

            for (let i = (user.school_years || []).length - 1; i >= 0; i--) {
                if (user.school_years[i].organization && user.school_years[i].organization.name) {
                    this.dimension(AnalyticsDimensionEnum.UserOrganisation, user.school_years[i].organization.name);
                    break;
                }
            }
        } catch (e) {
            console.error('Failed setUser ga:', e.message);
        }


        this.event(new AnalyticsEventAuthenticatedUserLoadModel());
    }

    public event(event: AnalyticsEventInterface): void {
        try {
            if (undefined !== ga) {
                ga('send', 'event', event);
            }
        } catch (e) {
            console.error('Failed Analytics event :', e.message);
        }

    }

    public dimension(dimension: AnalyticsDimensionEnum, value: string): void {
        try {
            if (undefined !== ga) {
            ga('set', `dimension${dimension}`, value);
        }
        } catch (e) {
            console.error('Failed Analytics dimension:', e.message);
        }

    }
}
