import {GroupsModulePersonInterface} from 'pages/modules/groups/interfaces/person.interface';
import {GroupsModuleGroupInterface} from 'pages/modules/groups/interfaces/group.interface';

export class GroupsModuleGroupModel implements GroupsModuleGroupInterface {
    constructor(
        public id: number,
        public name: string,
        public code: string,
        public active: boolean,
        public teachers: Array<GroupsModulePersonInterface>,
        public students: Array<GroupsModulePersonInterface>,
        public year: string,
    ) {
    }
}
