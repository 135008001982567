import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {DeeplinkService} from 'services/deeplink/deeplink.service';
import {RouteService} from 'routing/services/route/route.service';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-deeplink-book-id',
    templateUrl: 'bookId.component.html',
})
export class DeeplinkBookIdComponent implements OnInit, OnDestroy {

    private routeParamSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private deeplinkService: DeeplinkService,
        private router: Router,
        private routeService: RouteService,
        private toastService: ToastrService,
    ) {}

    public ngOnInit(): void {
        this.routeParamSubscription = this.route.params.subscribe((params: Params) => {
            if (params.bookId) {
                this.loadDocument(Number(params.bookId));
            } else {
                this.onFailure();
            }
        }, () => this.onFailure());
    }

    public ngOnDestroy(): void {
        if (this.routeParamSubscription) {
            this.routeParamSubscription.unsubscribe();
        }
    }

    private loadDocument(bookId: number): void {
        this.deeplinkService.bookId(bookId)
            .subscribe((response: ApiBookInterface) => {
                this.router.navigate([
                    this.routeService.getRouteWithPublisher(RoutesEnum.Document, new Map([
                        ['bookId', response.id.toString()],
                        ['documentDpsid', response.dpsid.toString()],
                    ])),
                ]);
            }, () => this.onFailure());
    }

    private onFailure(): void {
        this.toastService.error('Er ging iets mis bij het laden van het boek.');

        this.router.navigate([
            this.routeService.getRouteWithPublisher(RoutesEnum.Books),
        ]);
    }
}
