export enum ResultStateEnum {

    None = 'none',

    Created = 'created',

    TooLate = 'tooLate',

    HandedIn = 'handedIn',

    Doing = 'doing',

    Resit = 'resit',

    Graded = 'graded',

    DeadLine = 'deadLine',

    Other = 'other',

    IsNumeric = 'isNumeric',

}
