import {AfterViewInit, Component, ElementRef, HostBinding, HostListener, ViewChild} from '@angular/core';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';
import {DocumentContentMediaTagEnum} from 'document/modules/components/content-media/enums/tag.enum';
import {DocumentContentMediaClassEnum} from 'document/modules/components/content-media/enums/class.enum';

@Component({
    selector: 'figure',
    templateUrl: 'content-media.component.html',
})
export class DocumentContentMediaComponent implements AfterViewInit {

    @ViewChild('mediaContainer', {static: false})
    public mediaContainer: ElementRef;

    @HostBinding('class')
    public elementClass: string = DocumentHostClassesEnum.Media;

    public showEnlarge: boolean;

    public classModifiers: Array<string> = [
        DocumentContentMediaClassEnum.Loading,
    ];

    public enlargeElement: HTMLElement;

    public mediaLoading: boolean = true;

    public mediaError: boolean = false;

    private mediaElement: HTMLElement;

    private figCaption: HTMLElement;

    private supportedMediaTags: Array<string> = [
        DocumentContentMediaTagEnum.Img,
        DocumentContentMediaTagEnum.IFrame,
    ];

    constructor(
        private elementRef: ElementRef
    ) {
        this.handleMedia();
    }

    public ngAfterViewInit(): void {
        const children: Array<HTMLElement> = Array.from(this.mediaContainer.nativeElement.childNodes);

        this.figCaption = children.find(childElement => childElement.tagName === DocumentContentMediaTagEnum.FigCaption);
        this.mediaElement = children.find(childElement => this.supportedMediaTags.includes(childElement.tagName));
        this.handleMedia();
    }

    public getModalTitle(): string | null {
        return this.figCaption ? this.figCaption.innerText : null;
    }

    @HostListener('window:resize')
    private onWindowSizeChange(): void {
        this.calculateFixCaptionWidth();
    }

    private handleMedia(): void {
        let tagName = null;

        if (this.mediaElement) {
            this.enlargeElement = <HTMLElement>this.mediaElement.cloneNode(true);
            tagName = this.mediaElement.tagName;
        }

        switch (tagName) {
            case DocumentContentMediaTagEnum.Img:
                this.handleImage();
                break;
            case DocumentContentMediaTagEnum.IFrame:
                this.handleIFrame();
                break;
            default:
                this.showMedia();
                break;
        }
    }

    private handleIFrame(): void {
        const iFrameElement: HTMLIFrameElement = <HTMLIFrameElement>this.mediaElement;
        const hasSrcTag: boolean = this.mediaElement.getAttribute('src').length !== 0;

        this.classModifiers.push(DocumentContentMediaClassEnum.Iframe);

        if (!hasSrcTag) {
            this.handleError('Media iFrame missing src tag value');

            return;
        }

        iFrameElement.onload = () => this.showMedia();
    }

    private handleImage(): void {
        const imageElement: HTMLImageElement = <HTMLImageElement>this.mediaElement;

        this.imageSetMaxResolutionForEnlarge();

        imageElement.onload = () => this.showMedia();
    }

    private imageSetMaxResolutionForEnlarge(): void {
        const enlargeElement: HTMLImageElement = <HTMLImageElement>this.enlargeElement;

        const src = enlargeElement.src;

        const mimeType = src.substr(src.lastIndexOf('.'));
        const srcWithoutSizeAndMimetype = src.substr(0, src.lastIndexOf('/'));

        enlargeElement.src = `${srcWithoutSizeAndMimetype}/0x0${mimeType}`;
    }

    private showMedia(): void {
        this.mediaLoading = false;
        this.removeClass(DocumentContentMediaClassEnum.Loading);

        const hasMath = this.hasMath();

        if (hasMath || !this.mediaElement || this.mediaElement.tagName === DocumentContentMediaTagEnum.IFrame) {
            return;
        }

        this.showEnlarge = true;

        this.calculateFixCaptionWidth();
    }

    private calculateFixCaptionWidth(): void {
        if (!this.figCaption) {
            // Fig caption might not be available?
            return;
        }

        const imageElement: HTMLImageElement = <HTMLImageElement>this.mediaElement;
        const figCaptionWidth = imageElement.naturalWidth;

        this.figCaption.style.maxWidth = figCaptionWidth + 'px';
    }

    private handleError(message: string): void {
        this.removeClass(DocumentContentMediaClassEnum.Loading);
        this.addClass(DocumentContentMediaClassEnum.Error);

        this.mediaLoading = false;
        this.mediaError = true;

        // Add modifier to host element
        this.elementRef.nativeElement.classList.add(DocumentHostClassesEnum.MediaError);

        console.error('Media error:', message);
    }

    private hasClass(className: DocumentContentMediaClassEnum): boolean {
        return this.classModifiers.includes(className);
    }

    private addClass(className: DocumentContentMediaClassEnum): void {
        if (!this.hasClass(className)) {
            this.classModifiers.push(className);
        }
    }

    private removeClass(className: DocumentContentMediaClassEnum): void {
        if (this.hasClass(className)) {
            const index: number = this.classModifiers.indexOf(className);
            this.classModifiers.splice(index, 1);
        }
    }

    private hasMath(): boolean {
        if (!this.mediaContainer) {
            return false;
        }

        const children: Array<HTMLElement> = Array.from(this.mediaContainer.nativeElement.childNodes);
        const divElement = children.find(childElement => childElement.tagName === DocumentContentMediaTagEnum.Div);

        if (!divElement) {
            return false;
        }

        const divChildren: Array<Element> = Array.from(divElement.children);

        return !!divChildren.find(childElement => {
            return childElement.tagName === 'MATH' || (childElement.tagName === 'SCRIPT' && childElement['type'] === 'math/mml');
        });
    }
}
