import {IconEnum} from 'enums/icon.enum';
import {MaterialshelfItemClassEnum} from 'pages/modules/teacher-material/enums/item-class.enum';
import {MaterialShelfItemTypeEnum} from 'pages/modules/teacher-material/enums/item-type.enum';
import {MaterialShelfItemInterface} from 'pages/modules/teacher-material/interfaces/item.interface';
import {ResourceTypeEnum} from 'enums/resource-type.enum';

export class ReturnToTeacherMaterialsModel {

    public id: number;

    public type: MaterialShelfItemTypeEnum = MaterialShelfItemTypeEnum.TeacherMaterial;

    public title: string;

    public icon: string = IconEnum.ArrowLeft;

    public items?: Array<MaterialShelfItemInterface> = [];

    public dpsid: string = '';

    public documentType: string = ResourceTypeEnum.ReturnFolder;

    public total: number = null;

    public disabled?: boolean;

    public classModifiers: Array<string> = [
        MaterialshelfItemClassEnum.Return,
    ];

    constructor(pathComponent: ApiPathComponentInterface) {
        this.title = pathComponent ? pathComponent.title : 'Docentmateriaal';
        this.dpsid = pathComponent ? pathComponent.dpsid : null;
    }

}
