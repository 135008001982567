import {
    Compiler,
    Component, ComponentFactory,
    ComponentRef,
    HostBinding,
    Injectable,
    NgModule,
    NO_ERRORS_SCHEMA,
    ViewContainerRef
} from '@angular/core';

// tslint:disable-next-line no-empty-interface
export interface DynamicComponentInterface {}

@Injectable()
export class JitComponentFactory {
    constructor(
        private compiler: Compiler,
    ) {}

    private static escapeBody(template: string): string {
        return template
            .replace(/&#123;&#123;/g, '{{ \'{\' }}{{ \'{\' }}')
            ;
    }

    private static async importRequiredModules(): Promise<Array<any>> {
        return [
            (await import('../modules/components/components.module')).DocumentComponentsModule,
            (await import('../modules/directives/directives.module')).DocumentDirectivesModule,
        ];
    }

    private static createComponentAndSetAttributes(containerRef: ViewContainerRef, componentFactory: ComponentFactory<DynamicComponentInterface>, attributes: {[key: string]: any}): ComponentRef<DynamicComponentInterface> {
        const componentRef = containerRef.createComponent(componentFactory);

        if (!attributes || Object.keys(attributes).length === 0) {
            return componentRef;
        }

        for (const key of Object.keys(attributes)) {
            componentRef.instance[key] = attributes[key];
        }

        return componentRef;
    }

    /**
     * This hack is required to compile documents on runtime, this allows us to compile directives/components
     * supplied by the API. This is not something that should be used elsewhere and it violates Angular's principles.
     */
    public async create(containerRef: ViewContainerRef, template: string, attributes: {[key: string]: any} = {}): Promise<ComponentRef<DynamicComponentInterface>> {
        const escapedTemplate = JitComponentFactory.escapeBody(template);
        const modules = await JitComponentFactory.importRequiredModules();

        const componentFactory = this.compileAndCreateComponentFactory(escapedTemplate, modules);
        if (undefined === componentFactory) {
            throw new Error('Factory not found');
        }

        return JitComponentFactory.createComponentAndSetAttributes(containerRef, componentFactory, attributes);
    }

    private compileAndCreateComponentFactory(template: string, modules: Array<any>): ComponentFactory<DynamicComponentInterface>|undefined  {
        @Component({template, preserveWhitespaces: true})
        class JitTemplateComponent implements DynamicComponentInterface {
            @HostBinding('id')
            public elementId: string = 'read-speaker-content';
        }

        @NgModule({
            imports: modules,
            declarations: [
                JitTemplateComponent,
            ],
            schemas: [
                NO_ERRORS_SCHEMA,
            ],
        })
        class JitTemplateModule {}

        const moduleFactory = this.compiler.compileModuleAndAllComponentsSync(JitTemplateModule);

        return moduleFactory.componentFactories.find(factory => factory.componentType === JitTemplateComponent);
    }
}
