import {MaterialShelfItemTypeEnum} from 'pages/modules/teacher-material/enums/item-type.enum';

export enum BookshelfItemClassEnum {

    Icon = 'book-shelf-item--icon',

    Dummy = 'book-shelf-item--dummy',

    FolderUploadedSources = 'book-shelf-item--folder-uploaded-sources',

    FolderReturn = 'book-shelf-item--folder-return',

    FolderDisabled = 'book-shelf-item--folder-disabled'
}
