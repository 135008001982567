import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DocumentPageActionsButtonsEnum} from 'pages/modules/document/enums/actions-buttons.enum';
import {WindowService} from 'services/window/window.service';
import {Subscription} from 'rxjs';
import {DocumentActionButtonInterface} from 'pages/modules/document/interfaces/action-button.interface';
import {PageWindowSizeEnum} from 'pages/modules/document/components/page-actions/enums/page-window-size-enum';
import {DocumentActionsButtonEBookModel} from 'pages/modules/document/models/actions-button-ebook.model';
import {WindowSizeEnum} from 'pages/modules/document/components/extern-page-actions/enums/window-size-enum';
import {ExternPageActionStyleEnum} from 'pages/modules/document/components/extern-page-actions/enums/extern-page-action-style.enum';

@Component({
    selector: 'app-document-extern-page-actions',
    templateUrl: 'extern-page-actions.component.html',
})
export class DocumentExternPageActionsComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('pageActionsContainer', {static: true})
    private container: ElementRef;

    @Input()
    public isloading: boolean;

    @Input()
    public eBook: string;

    public buttons: Array<DocumentActionButtonInterface> = [];

    private onScrollSubscription: Subscription;

    private elementOriginalTop: number;

    public externPageActionContainerWidth: number;

    public externPageActionContainerMarginLeft: number;

    constructor(
        private elementRef: ElementRef,
        private windowService: WindowService,
    ) {
        window.onresize = () => {
            this.resizeExternPageActionContainer();
        };
    }

    public ngOnInit(): void {
        this.onScrollSubscription = this.windowService.onScroll.subscribe(() => this.handleScroll());
        this.elementOriginalTop = this.getElementOriginalTop();


        this.setupButtons();
        this.resizeExternPageActionContainer();
    }

    public ngOnDestroy(): void {
        if (this.onScrollSubscription) {
            this.onScrollSubscription.unsubscribe();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.eBook) {
            this.setupButtons();
        }
    }

    public handleButtonClick(button: DocumentActionButtonInterface): void {
        switch (button.id) {
            case DocumentPageActionsButtonsEnum.EBook:
                this.handleEBook();
                break;
        }
    }

    private handleEBook(): void {
        window.open(this.eBook, '_blank');
    }

    private getElementOriginalTop(): number {
        const parentElementTop: number = this.getNativeElement().parentElement.getBoundingClientRect().top;

        return this.getScrollTopDistance() + parentElementTop;
    }

    private getNativeElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    private resizeExternPageActionContainer(): void {
        if (window.innerWidth >= WindowSizeEnum.Minimal && window.innerWidth < WindowSizeEnum.Maximum) {
            const coverWidth = ((window.innerWidth - WindowSizeEnum.Start) * ExternPageActionStyleEnum.StepsWidth) + ExternPageActionStyleEnum.StartWidth;
            const coverMargin = ((window.innerWidth - WindowSizeEnum.Start) * ExternPageActionStyleEnum.StepsMarginLeft) + ExternPageActionStyleEnum.StartMarginLeft;
            this.externPageActionContainerWidth = Number(coverWidth.toFixed(2));
            this.externPageActionContainerMarginLeft = -+Number(coverMargin.toFixed(2));
        } else {
            this.externPageActionContainerWidth = ExternPageActionStyleEnum.DefaultExternPageActionWidth;
            this.externPageActionContainerMarginLeft = ExternPageActionStyleEnum.DefaultMarginLeft;
        }
    }

    private handleScroll(): void {
        const scrollOffset: number = this.getScrollTopDistance() - this.elementOriginalTop;

        if (scrollOffset > 0 && window.innerWidth > PageWindowSizeEnum.SmallDevice) {
            this.container.nativeElement.style.top = `${scrollOffset}px`;
        } else {
            this.container.nativeElement.style.top = null;
        }
    }

    private getScrollTopDistance(): number {
        const documentElement: HTMLElement = document.documentElement;

        return (window.pageYOffset || documentElement.scrollTop) - (documentElement.clientTop || 0);
    }

    private setupButtons(): void {
        this.buttons = [
            new DocumentActionsButtonEBookModel(!!this.eBook),
        ];
    }
}
