import {Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FilterButtonGroupInterface} from 'shared/components/filter-button-group/interfaces/filter.interface';
import {ClassHelper} from 'helpers/dom/class.helper';
import {FilterButtonGroupClassEnum} from 'shared/enums/filter-button-group/class.enum';

@Component({
    selector: 'app-filter-button-group',
    templateUrl: 'filter-button-group.component.html'
})
export class FilterButtonGroupComponent<T> implements OnInit, OnChanges {

    protected static readonly CLASS = 'filter-button-group';

    @HostBinding('class')
    public classes: string;

    @Input()
    public filter: FilterButtonGroupInterface<T>;

    @Output()
    public filterChange: EventEmitter<FilterButtonGroupInterface<T>> = new EventEmitter();

    @Input()
    public disabled: boolean = false;

    @Input()
    public classModifiers: Array<string> = [];

    private classHelper: ClassHelper = new ClassHelper(FilterButtonGroupComponent.CLASS);

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classModifiers);
        this.updateClasses();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            this.classHelper.toggleClassByBoolean(FilterButtonGroupClassEnum.Disabled, changes.disabled.currentValue);
            this.updateClasses();
        }
    }

    public handleChange(filter: T): void {
        if (this.disabled) {
            return;
        }

        this.filter.selected = filter;
        this.filterChange.emit(this.filter);
    }

    public keyValueCompare(a: any, b: any): number {
        return 0;
    }

    private updateClasses(): void {
        this.classes = this.classHelper.toString();
    }
}
