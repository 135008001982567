import {Component, Input, OnDestroy} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {ModalService} from 'core/services/modal/modal.service';
import {Observable, Subscription} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-document-enlarge',
    templateUrl: 'enlarge.component.html',
})
export class DocumentEnlargeComponent implements OnDestroy {

    public readonly icon: IconEnum = IconEnum.Enlarge;

    @Input()
    public useElementClone: boolean = true;

    @Input()
    public modalTitle: string = null;

    @Input()
    public contentElement: HTMLElement;

    protected subscription: Subscription;

    constructor(
        private toastService: ToastrService,
        private modalService: ModalService,
    ) {
    }

    public enlarge(): void {
        if (this.useElementClone) {
            this.showModalWithClone();
        } else {
            this.showModal();
        }
    }

    public ngOnDestroy(): void {
        if (this.subscription instanceof Subscription) {
            this.subscription.unsubscribe();
        }
    }

    private showModalWithClone(): void {
        const elementParent: HTMLElement = this.contentElement.parentElement;
        const elementClone: HTMLElement = <HTMLElement>this.contentElement.cloneNode(true);

        this.subscription = this.showModal().subscribe((result: HTMLElement | string) => {
            if (this.useElementClone && result instanceof HTMLElement) {
                elementClone.remove();
                elementParent.appendChild(result);
            }
        }, (error: any) => {
            console.error(error);
            this.toastService.error('Oeps! Er ging iets mis tijdens het vergroten.');
        });

        elementParent.appendChild(elementClone);
    }

    private showModal(): Observable<string | HTMLElement> {
        return this.modalService.enlarge(this.modalTitle, this.contentElement);
    }
}
