import {ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {environment} from 'environments/environment';
import {SearchInterface} from 'shared/modules/search/interfaces/search.interface';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {TabItemInterface} from 'shared/modules/tab/interfaces/tab-item.interface';
import {TocViewBookItemInterface} from 'shared/modules/toc/interfaces/toc-view-book-item.interface';
import {TeacherMaterialService} from 'services/teacher-material/teacher-material.service';
import {Observable} from 'rxjs';
import {SchoolService} from 'services/school/school.service';
import {TeacherMaterialSearchModel} from 'pages/modules/teacher-material/models/search.model';
import {TeacherMaterialTabModel} from 'pages/modules/teacher-material/models/tab.model';
import {ResourceTypeEnum} from 'enums/resource-type.enum';
import {TeacherMaterialTabTitlesEnum} from 'pages/modules/teacher-material/enums/tab-titles.enum';
import {InfiniteScroll} from 'shared/classes/infinite-scroll.abstract';
import {BookService} from 'services/book/book.service';
import {ContentSelectModelFactory} from 'shared/modules/content-select/factories/model.factory';
import {ContentSelectItemInterface} from 'shared/modules/content-select/interfaces/select-item.interface';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {RouteService} from 'routing/services/route/route.service';
import {PageWrapperClassEnum} from 'shared/modules/page/enums/page-wrapper/class.enum';
import {ShelfComponent} from 'pages/modules/teacher-material/components/shelf/shelf.component';
import {DocumentService as DocumentBookService} from 'document/services/document/document.service';

@Component({
    selector: 'app-teacher-material',
    templateUrl: 'teacher-material.component.html'
})
export class TeacherMaterialComponent extends InfiniteScroll<ApiResourcesInterface> implements OnInit, OnDestroy {

    public readonly pageWrapperClassEnum = PageWrapperClassEnum;

    @ViewChild(ShelfComponent, {static: false})
    protected appShelf: ShelfComponent;

    @HostBinding('class')
    protected classes = 'container-fluid container-fluid--width';

    @Input()
    public bookItems: Array<TocViewBookItemInterface> = [];

    public resources: Array<ApiResourceInterface> = [];

    public visibleResources: Array<ApiResourceInterface> = [];

    public contentSwitchItems: Array<ContentSelectItemInterface> = [];

    public contentSwitchIconBox: IconBoxInterface = {
        type: IconBoxTypeEnum.Icon,
        value: IconEnum.BookClose,
        classModifiers: [
            IconBoxClassEnum.TocBook,
        ],
    };

    public contentSwitchClassModifiers: Array<string> = [
        ContentSelectClassModifiersEnum.TeacherMaterials,
        ContentSelectClassModifiersEnum.Grey,
    ];

    public search: SearchInterface;

    public content: boolean = false;

    public contentUrl: string;

    public iconBox: IconBoxInterface = {
        type: IconBoxTypeEnum.Icon,
        value: IconEnum.BookClose,
        classModifiers: [
            IconBoxClassEnum.Icon,
            IconBoxClassEnum.TocBook,
        ],
    };

    public tabItems: Array<TabItemInterface> = [
        new TeacherMaterialTabModel(TeacherMaterialTabTitlesEnum.All, true),
        new TeacherMaterialTabModel(TeacherMaterialTabTitlesEnum.InternetLink, false, IconEnum.Global),
        new TeacherMaterialTabModel(TeacherMaterialTabTitlesEnum.Document, false, IconEnum.Book),
        new TeacherMaterialTabModel(TeacherMaterialTabTitlesEnum.Ebook, false, IconEnum.Ebook),
    ];

    public activeBook: ContentSelectItemInterface;

    protected itemTotal: number = 0;

    private searchValue: string = '';

    private filter?: ResourceTypeEnum;

    private filters: Map<string, ResourceTypeEnum> = new Map([
        [TeacherMaterialTabTitlesEnum.All, null],
        [TeacherMaterialTabTitlesEnum.InternetLink, ResourceTypeEnum.Html],
        [TeacherMaterialTabTitlesEnum.Document, ResourceTypeEnum.TeacherSource],
        [TeacherMaterialTabTitlesEnum.Ebook, ResourceTypeEnum.Ebook],
    ]);

    private chapterId: string;

    public bookId: number;

    public demo: boolean;

    public licenseRequested: boolean;

    public folderDpsid: string;

    public bookCover: string;

    public bookTitle: string;

    public paginating: boolean = false;

    protected offset: number = 0;

    public newMaterialsViewActive: boolean = environment.features.teacherMaterialsEnabled === true;

    constructor(
        private resourcesService: TeacherMaterialService,
        private schoolService: SchoolService,
        private bookService: BookService,
        private documentBookService: DocumentBookService,
        private contentSwitchModelFactory: ContentSelectModelFactory,
        private route: ActivatedRoute,
        private routeService: RouteService,
        private router: Router,
        protected change: ChangeDetectorRef,
    ) {
        super(change);

        this.search = new TeacherMaterialSearchModel('Zoeken');

        if (this.newMaterialsViewActive) {
            this.watchRouteParams();
        } else {
            this.watchQueryParams();
        }
    }

    public ngOnInit(): void {
        this.setFilter(TeacherMaterialTabTitlesEnum.All);
        this.loadContentSwitchData();

        super.ngOnInit();
    }

    /**
     * Route params are used in the new situation
     */
    private watchRouteParams(): void {
        this.route.params.subscribe(params => {
            if (!params['bookId']) {
                return;
            }

            this.bookId = Number(params['bookId']);
            this.folderDpsid = params['folderDpsid'];
            this.retrieveDocumentBook();

            this.contentUrl = this.routeService.getRouteWithPublisher(RoutesEnum.Book, new Map([
                ['bookId', String(this.bookId)],
            ]));
        });
    }

    /**
     * @deprecated Will be removed when switching to new situation
     * Query params are used in the old situation
     */
    private watchQueryParams(): void {
        this.route.queryParams.subscribe(params => {
            if (this.bookId) {
                return;
            }

            this.bookId = Number(params['bookId']);
            this.retrieveDocumentBook();

            if (params['bookId'] && params['dpsid']) {
                this.contentUrl = this.routeService.getRouteWithPublisher(RoutesEnum.Document, new Map([
                    ['bookId', String(params['bookId'])],
                    ['documentDpsid', params['dpsid']],
                ]));
            } else {
                this.contentUrl = this.routeService.getRouteWithPublisher(RoutesEnum.Books);
            }
        });
    }

    public onTabChange(tabItem: TabItemInterface): void {
        this.setFilter(tabItem.title);
        this.loadData(true);
    }

    public handleSearch(searchValue: string): void {
        this.searchValue = searchValue;
        this.loadData(true);
    }

    public onContentSwitchClick(items: Array<ContentSelectItemInterface>): void {
        const filteredItem: ContentSelectItemInterface = items[0];
        const hasItem: boolean = filteredItem !== undefined;
        const hasParent = hasItem && filteredItem.parentId !== undefined;

        this.bookId = hasItem ? Number(hasParent ? filteredItem.parentId : filteredItem.id) : undefined;
        this.chapterId = hasParent ? filteredItem.id : undefined;

        // Clear cover and title
        this.bookCover = null;
        this.bookTitle = null;

        if (!this.bookId) {
            return;
        }

        const route: string = this.routeService.getRouteWithPublisher(RoutesEnum.TeacherMaterialBook, new Map([
            ['bookId', this.bookId.toString()],
        ]));

        this.router.navigate([route]);
        this.loadData(true);
    }

    public setFilter(tabTitle: string): void {
        this.filter = this.filters.get(tabTitle);
    }

    protected loadData(reset: boolean = false): void {
        if (this.isNewMaterialsActive()) {
            this.isLoading = reset;

            return;
        }

        super.loadData(reset);
    }

    public handleScrolled(): void {
        if (this.isNewMaterialsActive()) {
            this.appShelf.next();

            return;
        }

        if (this.searchValue) {
            this.next();

            return;
        }

        this.handleScrolled();
    }

    protected isNewMaterialsActive(): boolean {
        return this.newMaterialsViewActive && !this.searchValue;
    }

    public next(): void {
        if ((this.offset + 1) * this.limit >= this.itemTotal) {
            return;
        }

        this.offset++;
        this.paginating = true;

        this.isLoading = true;
        this.loadItems();
    }

    protected reset(): void {
        this.visibleResources = [];
        this.resources = [];

        super.reset();
    }

    private loadItems(): void {
        if (!this.bookId) {
            return;
        }

        this.resourcesService.retrieveForSchoolYear(
            this.schoolService.getSelectedSchoolYear().id.toString(),
            new URLSearchParams([
                ['offset', (this.offset * this.limit).toString()],
                ['limit', this.limit.toString()],
                ['book', this.bookId ? this.bookId.toString() : ''],
                ['chapter', this.chapterId ? this.chapterId.toString() : ''],
                ['type', this.filter ? this.filter.toString() : ''],
                ['search', this.searchValue],
            ])
        )
            .subscribe((data: ApiResourcesInterface) => {
                this.subscribe(data);
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            });
    }

    protected getLoadDataObservable(): Observable<ApiResourcesInterface> {
        return this.resourcesService.retrieveForSchoolYear(
            this.schoolService.getSelectedSchoolYear().id.toString(),
            new URLSearchParams([
                ['offset', this.getRequestOffset().toString()],
                ['limit', this.limit.toString()],
                ['book', this.bookId ? this.bookId.toString() : ''],
                ['chapter', this.chapterId ? this.chapterId.toString() : ''],
                ['type', this.filter ? this.filter.toString() : ''],
                ['search', this.searchValue],
            ]),
        );
    }

    protected subscribe(resources: ApiResourcesInterface): void {
        this.itemTotal = resources.total;
        this.resources = [...this.resources, ...resources.data]; // Merge resources
        this.visibleResources = this.resources;
    }

    protected getDataLength(): number {
        return this.itemTotal;
    }

    private loadContentSwitchData(): void {
        this.bookService.retrieveBooks(
            '0',
            '-1',
            '',
            true,
        ).subscribe((apiBooks: ApiBooksInterface) => {
            const items = this.contentSwitchModelFactory.fromApiBooks(apiBooks, !environment.features.teacherMaterialsEnabled);

            if (this.bookId) {
                items.forEach((item: ContentSelectItemInterface) => {
                    if (Number(item.id) === Number(this.bookId)) {
                        item.active = true;
                        this.activeBook = item;
                        this.content = true;
                    }
                });
            } else {
                this.content = false;
            }

            this.contentSwitchItems = items;
        });
    }

    private retrieveDocumentBook(): void {
        if (!this.bookId) {
            return;
        }

        this.bookService.getBook(String(this.bookId)).subscribe((book: ApiBookInterface) => {
            this.bookCover = book.cover;
            this.bookTitle = book.title;
            this.demo = book.demo;
            this.licenseRequested = book.licenseRequested;
            this.documentBookService.setBook(book);
        }, (error: any) => {
            console.error(error);
        });
    }
}
