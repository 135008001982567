import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {ApiService} from 'core/services/api/api.service';
import {StringService} from 'services/string/string.service';
import {ApiAssignmentHandInInterface} from 'interfaces/api/assignment-handin.interface';

@Injectable()
export class AssignmentService {

    public constructor(
        private api: ApiService,
        private stringService: StringService,
    ) {}

    public retrieveAssignments(bookId: number, chapterId: number): Observable<Array<ApiAssignmentInterface>> {
        return this.api.get<Array<ApiAssignmentInterface>>(
            ApiEndpointEnum.Assignments,
            null,
            new Map([
                ['bookId', bookId.toString()],
                ['chapterId', chapterId.toString()],
            ])
        );
    }

    public handIn(assignmentId: number, params: AssignmentHandinInterface): Observable<ApiAssignmentHandInInterface> {
        return this.api.post<ApiAssignmentHandInInterface>(
            ApiEndpointEnum.AssignmentHandin,
            params,
            null,
            new Map([
                ['assignmentId', assignmentId.toString()],
            ])
        );
    }

    public handInSelfScore(taskId: number): Observable<[]> {
        return this.api.post<[]>(
            ApiEndpointEnum.AssignmentHandinSelfScore,
            {},
            null,
            new Map([
                ['taskId', taskId.toString()],
            ])
        );
    }

    public retrieveTasks(bookId: number, assignmentId: number, groupId: number = null, user: number = null): Observable<Array<ApiTaskInterface>> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.AssignmentGetTasks, new Map([['assignmentId', assignmentId.toString()]]));

        const searchParams = new URLSearchParams();
        searchParams.set('book', bookId.toString());

        if (groupId !== null) {
            searchParams.set('group', groupId.toString());
        }

        if (user !== null) {
            searchParams.set('user', user.toString());
        }

        return this.api.get(apiRoute, searchParams);
    }
}
