import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageComponent} from 'shared/modules/page/components/page.component';
import {PageRowComponent} from 'shared/modules/page/components/row/page-row.component';
import {PageColComponent} from 'shared/modules/page/components/col/page-col.component';
import {PageWrapperComponent} from 'shared/modules/page/components/wrapper/wrapper.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        PageComponent,
        PageRowComponent,
        PageColComponent,
        PageWrapperComponent,
    ],
    exports: [
        PageComponent,
        PageRowComponent,
        PageColComponent,
        PageWrapperComponent,
    ],
})
export class PageModule {
}
