import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {GridTableBorderEnum} from 'shared/modules/grid-table/enums/table/border.enum';

@Component({
    selector: 'app-grid-table',
    templateUrl: 'grid-table.component.html',
})
export class GridTableComponent implements OnInit {

    public static CLASS = 'grid-table';

    @HostBinding('class')
    public hostClasses: string;

    @Input()
    public borderRadius: boolean = true;

    @Input()
    public marginBottom: boolean = true;

    @Input()
    public border: GridTableBorderEnum = GridTableBorderEnum.GreyDark;

    @Input()
    public classModifiers: Array<string> = [];

    private classHelper = new ClassHelper(GridTableComponent.CLASS);

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classModifiers);
        this.classHelper.addClass(this.border);
        this.classHelper.toggleClassByBoolean(GridTableClassEnum.BorderRadius, this.borderRadius);
        this.classHelper.toggleClassByBoolean(GridTableClassEnum.MarginBottom, this.marginBottom);
        this.hostClasses = this.classHelper.toString();
    }
}
