import {ProgressAssignmentStatusInterface} from 'pages/modules/progress/interfaces/assignment-status.interface';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';

export class ProgressAssignmentStatusModel implements ProgressAssignmentStatusInterface {

    constructor(
        public assignmentId: number,
        public actualAssignmentId: number,
        public dpsid: string,
        public title: string,
        public groupTask: boolean,
        public examinationNeeded: ExamineOptionsEnum,
        public modifiedByTeacher: number,
        public progressStatus?: number,
        public dueDate?: string,
        public passCount?: number,
        public rightCount?: number,
        public wrongCount?: number,
        public rightWrong_rightCount?: number,
        public rightWrong_wrongCount?: number,
        public retryCount?: number,
        public inProgressCount?: number,
        public handedInCount?: number,
        public lateCount?: number,
        public result?: number,
        public taskAssignedCount?: number,
        public handedin?: string,
        public resultNumeric?: number,
        public assignmentTaskId?: number,
        public assignmentTaskMasterTaskId?: number,
    ) {
    }

    public static fromApiProgressStudentScoreAssignmentStatus(
        status: ApiProgressStudentScoreAssignmentStatusInterface
    ): ProgressAssignmentStatusModel {
        return new this(
            status.assignment_id,
            status.actual_assignment_id,
            status.dpsid,
            status.title,
            status.group_task || false,
            status.examination_needed,
            status.modifiedByTeacher,
            status.progress_status,
            status.duedate,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            status.result,
            null,
            status.handedin,
            status.result_numeric,
            status.assignment_task_id || null,
            status.assignment_task_master_task_id || null,
        );
    }

    public static fromApiProgressAverageAssignmentStatus(
        status: ApiProgressAverageAssignmentStatusInterface,
    ): ProgressAssignmentStatusModel {
        return new this(
            status.assignment_id,
            status.actual_assignment_id,
            status.dpsid,
            status.title,
            status.group_task || false,
            status.examination_needed,
            status.modifiedByTeacher,
            null,
            null,
            status.pass_count,
            status.right_count,
            status.wrong_count,
            status.rightwrong_right_count,
            status.rightwrong_wrong_count,
            status.retry_count,
            status.inprogress_count,
            status.handedin_count,
            status.late_count,
            status.result,
            status.taskassigned_count,
            null,
            status.result_numeric,
            status.assignment_task_id || null,
            status.assignment_task_master_task_id || null,
        );
    }

}
