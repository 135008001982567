import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {IconEnum} from 'enums/icon.enum';

export class IconBoxInfo implements IconBoxInterface {

    public value: string = IconEnum.InfoHelpers;

    public type: string = IconBoxTypeEnum.Icon;

    constructor(public classModifiers: Array<string> = []) {
    }
}
