import {RolesLogicInterface} from 'security/interfaces/roles-logic.interface';
import {RoleEnum} from 'enums/role.enum';
import {PublisherService} from 'services/publisher/publisher.service';

export class RoutingRolesLogic implements RolesLogicInterface {

    protected publisher: string;

    constructor(
        private publisherService: PublisherService,
    ) {
    }

    public getRoles(): Array<RoleEnum> {
        const roles: Array<RoleEnum> = [];

        if (this.publisherService.isValidPublisher()) {
            roles.push(RoleEnum.RoleHasPublisher);
        }

        return roles;
    }

    public getName(): string {
        return 'RoutingRolesLogic';
    }
}
