import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-demo-marker',
    templateUrl: './demo-marker.component.html',
})
export class DemoMarkerComponent {

    @Input()
    public setMarker: boolean = false;

}
