import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChildren} from '@angular/core';
import {ContentSelectItemInterface} from 'shared/modules/content-select/interfaces/select-item.interface';
import {ContentSelectItemSwitchClassModifiersEnum} from 'app/modules/shared/modules/content-select/enums/class-modifiers-item-switch.enum';
import {ContentSelectItemEventInterface} from 'shared/modules/content-select/interfaces/item-event.interface';
import {ClassHelper} from 'helpers/dom/class.helper';

@Component({
    selector: 'app-content-select-item-component',
    templateUrl: 'item.component.html',
})
export class ContentSelectItemComponent implements OnInit, OnChanges {

    protected readonly classModifiersEnum = ContentSelectItemSwitchClassModifiersEnum;

    @ViewChildren(ContentSelectItemComponent)
    public childItemComponents: Array<ContentSelectItemComponent>;

    @Output()
    public itemEvent: EventEmitter<ContentSelectItemEventInterface> = new EventEmitter();

    @Input()
    public item: ContentSelectItemInterface;

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public disabled: boolean = false;

    @Input()
    public active: boolean = false;

    @Input()
    public parentActive: boolean = false;

    @Input()
    public depth: number = 0;

    @Input()
    public multiSelect: boolean;

    @Input()
    public search: string;

    public expanded: boolean;

    public itemTitleId: string;

    public classHelper: ClassHelper = new ClassHelper();

    public ngOnInit(): void {
        const isActiveAndMultiMode: boolean = this.active && this.multiSelect;

        this.classHelper.addClasses(this.classModifiers);

        // Set children parentActive states when this item is active and in multi mode
        if (isActiveAndMultiMode) {
            this.updateChildParentActiveStates(true, this.item.children);
        }

        // Set expanded state when active, expanded or when one or more children are active
        if ((isActiveAndMultiMode && this.hasChildren()) || this.item.expanded || this.hasActiveChildren()) {
            this.expanded = true;
        }

        this.itemTitleId = this.item.title.replace(new RegExp(' ', 'g'), '-');
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            if (this.disabled === true) {
                this.classHelper.addClass(this.classModifiersEnum.Disabled, 'item-switch');
            } else {
                this.classHelper.removeClass(this.classModifiersEnum.Disabled, 'item-switch');
            }
        }

        if (changes.active || changes.parentActive) {
            this.toggleItemSwitchClass();
        }
    }

    public handleClick(item: ContentSelectItemInterface, event?: MouseEvent): void {
        if (event !== undefined) {
            event.stopPropagation();
        }

        if (this.disabled) {
            return;
        }

        if (item === this.item) {
            this.handleItemClick();
        } else {
            this.handleChildClick(item); // Some child was clicked
        }

        this.emitItemEvent(item);
    }

    public getNextDepth(): number {
        return this.depth + 1;
    }

    public handleExpand(event: MouseEvent): void {
        event.stopPropagation();

        this.expanded = !this.expanded;
    }

    public highlighted(title: string): string {
        if (!this.search) {
            return title;
        }

        const startIndex = title.toLowerCase().indexOf(this.search);

        if (startIndex < 0) {
            return title;
        }

        const matchingTitle = title.substr(startIndex, this.search.length);

        return title.replace(matchingTitle, `<mark>${matchingTitle}</mark>`);
    }

    /**
     * Sets parentActive which makes children appear as active in the view
     */
    private updateChildParentActiveStates(state: boolean, childItemComponents: Array<ContentSelectItemInterface>): void {
        if (childItemComponents.length <= 0) {
            return;
        }

        childItemComponents.forEach((item: ContentSelectItemInterface) => {
            item.parentActive = state;
            item.active = false;
            this.updateChildParentActiveStates(state, item.children);
        });
    }

    private toggleItemSwitchClass(): void {
        const displayAsActive: boolean = this.active || this.parentActive;

        this.classHelper.toggleClassByBoolean(this.classModifiersEnum.Active, displayAsActive, 'item-switch');
    }

    private handleItemClick(): void {
        const itemNewActiveState: boolean = !this.item.active;

        this.item.active = itemNewActiveState;

        // When there are child items: update child parentActive states and expand this item when not expanded but active
        if (this.hasChildren() && this.multiSelect) {
            this.updateChildParentActiveStates(itemNewActiveState, this.item.children);

            if (!this.expanded && itemNewActiveState) {
                this.expanded = true;
            }
        }
    }

    private handleChildClick(item: ContentSelectItemInterface): void {
        const childCount: number = this.item.children.length;
        const allChildrenActive: boolean = this.getActiveChildren().length === this.item.children.length;
        const allChildrenActiveByParent: boolean = this.item.children.filter(child => child.parentActive).length === childCount;
        const children: Array<ContentSelectItemInterface> = (allChildrenActive
                ? this.item.children
                : this.item.children.filter(child => child.id !== item.id)
        );

        this.item.active = allChildrenActive;

        // When all items have parentActive = true, set this item active to false
        if (allChildrenActiveByParent) {
            item.active = false;
            item.parentActive = false;
        }

        children.forEach((child: ContentSelectItemInterface) => {
            child.active = allChildrenActive ? false : allChildrenActiveByParent ? true : child.active;
            child.parentActive = allChildrenActive;

            this.emitItemEvent(child, true);
        });

        this.emitItemEvent(this.item, true); // Emit parent
    }

    private getActiveChildren(): Array<ContentSelectItemInterface> {
        return this.item.children.filter(child => child.active);
    }

    private hasActiveChildren(): boolean {
        return this.getActiveChildren().length > 0;
    }

    private hasChildren(): boolean {
        return this.item.children.length > 0;
    }

    private emitItemEvent(item: ContentSelectItemInterface, childClick: boolean = false): void {
        this.itemEvent.emit({item, childEvent: childClick});
    }
}
