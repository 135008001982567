import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconBoxModule} from 'shared/modules/icon-box/icon-box.module';
import {ContentSelectComponent} from 'shared/modules/content-select/components/content-select.component';
import {ContentSelectItemComponent} from 'shared/modules/content-select/components/item/item.component';
import {PipesModule} from 'shared/modules/pipes/pipes.module';
import {DropdownModule} from 'shared/modules/dropdown/dropdown.module';
import {SearchModule} from 'shared/modules/search/search.module';
import {DirectivesModule} from 'shared/modules/directives/directives.module';

@NgModule({
    imports: [
        CommonModule,
        IconBoxModule,
        PipesModule,
        DropdownModule,
        SearchModule,
        DirectivesModule,
    ],
    declarations: [
        ContentSelectComponent,
        ContentSelectItemComponent,
    ],
    exports: [
        ContentSelectComponent,
        ContentSelectItemComponent,
    ],
})
export class ContentSelectModule {
}
