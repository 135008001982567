import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {TocViewBookItemInterface} from 'shared/modules/toc/interfaces/toc-view-book-item.interface';
import {TocViewBookItemComponent} from 'shared/modules/toc/components/toc-view-book-item/toc-view-book-item.component';
import {TocViewBooksBookClassEnum} from 'shared/modules/toc/enums/book.class-enum';
import {ClassHelper} from 'helpers/dom/class.helper';

@Component({
    selector: 'app-toc-view-books',
    templateUrl: 'toc-view-books.component.html',
})
export class TocViewBooksComponent implements OnInit, AfterViewInit {

    @ViewChildren(TocViewBookItemComponent)
    public bookItemComponents: QueryList<TocViewBookItemComponent> = new QueryList();

    @ViewChildren(TocViewBooksComponent)
    public bookComponents: QueryList<TocViewBooksComponent> = new QueryList();

    @Output()
    public activeEvent: EventEmitter<TocViewBookItemInterface> = new EventEmitter();

    @Output()
    public selectBookItem: EventEmitter<TocViewBookItemInterface> = new EventEmitter();

    @Input()
    public bookId: number;

    @Input()
    public chapterId: number;

    @Input()
    public depth: number = 0;

    @Input()
    public bookItem: TocViewBookItemInterface;

    public classes: Array<string> = [];

    public classHelper: ClassHelper = new ClassHelper();

    constructor(
        public elementRef: ElementRef,
    ) {
    }

    public getNextDepth(): number {
        return this.depth + 1;
    }

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classes);

        if (this.depth === 0) {
            this.classHelper.addClass(TocViewBooksBookClassEnum.Root);
        } else {
            const indentClass: TocViewBooksBookClassEnum = this.depth === 1
                ? TocViewBooksBookClassEnum.IndentSmall
                : TocViewBooksBookClassEnum.IndentLarge;

            this.classHelper.addClass(indentClass);
        }

        if (this.depth === 1 && !this.bookItem.viewBook.dpsid) {
            this.chapterId = this.bookItem.id;
        }
    }

    public ngAfterViewInit(): void {
        if (this.bookItem.children.length > 0 && this.bookItem.expanded) {
            this.toggleExpandClass(true);
        }
    }

    public handleActiveEvent(bookItem: TocViewBookItemInterface): void {
        this.activeEvent.emit(bookItem); // Pass up to parents
    }

    public handleExpand(bookItem: TocViewBookItemInterface): void {
        const viewBookComponent: TocViewBooksComponent = this.bookComponents.find(book => book.bookItem === bookItem);

        if (!viewBookComponent) {
            // throw new Error(`Book item #${bookItem.title} is not something we can expand`);

            return;
        }

        viewBookComponent.toggleExpandClass(bookItem.expanded);
    }

    public toggleExpandClass(value: boolean): void {
        this.classHelper.toggleClassByBoolean(TocViewBooksBookClassEnum.Expanded, value);
    }
}
