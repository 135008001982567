import {EventEmitter, Injectable} from '@angular/core';
import {DocumentAnswerableComponentInterface} from 'document/modules/interfaces/answerable.interface';
import {Subscription} from 'rxjs';

@Injectable()
export class DocumentAnswersStateService {

    private selfAnswerUpdated: EventEmitter<number> = new EventEmitter();

    private answersShownState: boolean = false;

    private answersDisabledState: boolean = false;

    private stateChangedEventEmitter: EventEmitter<null> = new EventEmitter();

    private components: Array<DocumentAnswerableComponentInterface> = [];

    constructor() {
        this.reset();
    }

    public register(component: DocumentAnswerableComponentInterface): void {
        this.components.push(component);

        this.setComponentState(component);
    }

    public deregister(component: DocumentAnswerableComponentInterface): void {
        this.components.splice(this.components.indexOf(component));
    }

    public registerChangeListener(generatorOrNext?: Function, error?: Function, complete?: Function): Subscription {
        return this.stateChangedEventEmitter.subscribe(generatorOrNext, error, complete);
    }

    public emit(): void {
        this.stateChangedEventEmitter.emit(null);

        for (const component of this.components) {
            this.setComponentState(component);
        }
    }

    public reset(): void {
        this.answersShownState = false;
        this.answersDisabledState = false;

        this.emit();
    }

    public answersValid(): boolean {
        let allValid = true;

        for (const component of this.components) {
            if (!component.onValidate()) {
                allValid = false;
            }
        }

        return allValid;
    }

    private setComponentState(component: DocumentAnswerableComponentInterface): void {
        component.updateGivenAnswer();

        if (this.answersShown()) {
            component.onShowCorrectAnswer();
            component.onShowInitialAnswer();
        } else {
            component.onHideCorrectAnswer();
            component.onHideInitialAnswer();
        }

        if (this.answersDisabled()) {
            component.onDisableAnswers();
        } else {
            component.onEnableAnswers();
        }
    }

    // Show answers

    public answersShown(): boolean {
        return this.answersShownState;
    }

    public showAnswers(): void {
        this.answersShownState = true;
        this.emit();
    }

    public hideAnswers(): void {
        this.answersShownState = false;
        this.emit();
    }

    // Disable answers

    public answersDisabled(): boolean {
        return this.answersDisabledState;
    }

    public toggleAnswers(value: boolean): void {
        if (value) {
            this.disableAnswers();
        } else {
            this.enableAnswers();
        }
    }

    public disableAnswers(): void {
        this.answersDisabledState = true;
        this.emit();
    }

    public enableAnswers(): void {
        this.answersDisabledState = false;
        this.emit();
    }

    public selfAnswerChanged(): void {
        this.selfAnswerUpdated.emit(null);
    }

    public subscribeToSelfAnswerChanges(next: (value: null) => void): Subscription {
        return this.selfAnswerUpdated.subscribe(next);
    }

}
