import {Compiler, COMPILER_OPTIONS, CompilerFactory, NgModule} from '@angular/core';
import {HtmlAnchorDirective} from 'document/directives/html-anchor.directive';
import {DocumentService} from 'document/services/document/document.service';
import {DocumentKeyEventService} from 'document/services/key-event/key-event.service';
import {DocumentResourceLinkService} from 'document/services/resource/link.service';
import {HelpersModule} from 'helpers/helpers.module';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {DocumentComponent} from 'document/components/document/document.component';
import {DocumentSourceFactory} from 'document/modules/factories/source.factory';
import {DocumentReferenceService} from 'document/services/reference/reference.service';
import {DocumentSummaryButtonComponent} from 'document/directives/summary/button/button.component';
import {DocumentSummaryDirective} from 'document/directives/summary/summary.directive';
import {DocumentNotesComponent} from 'document/components/notes/notes.component';
import {DocumentNotesAddButtonComponent} from 'document/components/notes/add-button/add-button.component';
import {CommonModule} from '@angular/common';
import {DocumentNotesTriggerDirective} from 'document/components/notes/trigger/trigger.directive';
import {DocumentNotesEditButtonComponent} from 'document/components/notes/edit-button/edit-button.component';
import {JitComponentFactory} from 'document/factories/jit-component.factory';
import {AutoCheckService} from 'document/services/autocheck/auto-check.service';
import {FeedRssService} from 'document/services/feed-rss/feed-rss.service';

@NgModule({
    imports: [
        CommonModule,
        HelpersModule,
    ],
    declarations: [
        HtmlAnchorDirective,
        DocumentComponent,
        DocumentSummaryDirective,
        DocumentSummaryButtonComponent,
        DocumentNotesComponent,
        DocumentNotesAddButtonComponent,
        DocumentNotesTriggerDirective,
        DocumentNotesEditButtonComponent,
    ],
    exports: [
        DocumentComponent,
    ],
    providers: [
        {provide: COMPILER_OPTIONS, useValue: {}, multi: true},

        DocumentService,
        DocumentKeyEventService,
        DocumentResourceLinkService,
        DocumentAnswersStateService,
        DocumentAnswersService,
        DocumentSourceFactory,
        DocumentReferenceService,
        JitComponentFactory,
        AutoCheckService,
        FeedRssService,
    ],
    entryComponents: [
        DocumentSummaryButtonComponent,
    ],
})
export class DocumentModule {
}
