import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssignmentService } from 'services/assignment/assignment.service';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { GroupsService } from 'services/groups/groups.service';
import { DocumentService } from 'services/document/document.service';
import { ContentSelectModelFactory } from 'shared/modules/content-select/factories/model.factory';
import { TaskService } from 'services/task/task.service';
import { BookService } from 'services/book/book.service';
import { TaskTypeEnum } from 'pages/modules/tasks/components/modals/add-modal/enums/task-type.enum';
import { ExamineOptionsEnum } from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';
import { ContentSwitchGroupModel } from 'pages/modules/tasks/components/modals/add-modal/models/group-model';
import { ContentSwitchStudentModel } from 'pages/modules/tasks/components/modals/add-modal/models/student-model';
import { PreloaderClassModifiersEnum } from 'shared/components/preloader/enums/class-modifiers.enum';
import { AddTaskTaskGroupInterface } from 'pages/modules/tasks/components/modals/add-modal/interfaces/add-task-task-group.interface';
import { TaskModalModeEnum } from 'pages/modules/tasks/components/modals/add-modal/enums/modal-mode.enum';
import { TasksApiTaskBuilderFactory } from 'pages/modules/tasks/components/modals/add-modal/factories/api-task-builder.factory';
import { IconBoxClose } from 'models/iconbox/close.model';
import { IconBoxClassEnum } from 'shared/modules/icon-box/enums/icon-box.class-enum';
import { AnalyticsService } from 'services/analytics/analytics.service';
import { AnalyticsDimensionEnum } from 'services/analytics/enums/dimension.enum';
import { AnalyticsEventTaskNewModel } from 'services/analytics/models/task-new.model';
import { AnalyticsEventTaskEditModel } from 'services/analytics/models/task-edit.model';
import { AnalyticsEventTaskResitModel } from 'services/analytics/models/task-resit.model';
import { AttachmentsComponentClassEnum } from 'shared/components/attachments/enums/class.enum';
import * as moment from 'moment';
import { ProgressBarClassModifiersEnum } from 'shared/components/progress-bar/enums/progress-bar-class-modifiers.enum';
import { AnalyticsEventTaskNewSavedModel } from 'services/analytics/models/task-new-saved.model';
import { AnalyticsEventTaskResitSavedModel } from 'services/analytics/models/task-resit-saved.model';
import { AnalyticsEventTaskEditSavedModel } from 'services/analytics/models/task-edit-saved.model';
import { AddTaskFormInterface } from 'pages/modules/tasks/components/modals/add-modal/interfaces/add-task-form.interface';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'app-add-task',
    templateUrl: 'add-modal.component.html'
})
export class TasksAddModalComponent implements OnInit, OnDestroy {

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorTask]);

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly typeTypeEnum = TaskTypeEnum;

    public readonly modalModeEnum = TaskModalModeEnum;

    public readonly attachmentsClassEnum = AttachmentsComponentClassEnum;

    public readonly progressBarClassEnum = ProgressBarClassModifiersEnum;

    @Input()
    public task: ApiTaskDetailsInterface = null;

    @Input()
    public bookId: number = null;

    @Input()
    public chapterId: number = null;

    @Input()
    public ownTaskMode: boolean = false;

    @Input()
    public preSelectedAssignments: Array<String> = [];

    @Input()
    public resit: boolean = false;

    @Output()
    public taskAddEvent: EventEmitter<Array<ApiTaskDetailsInterface>> = new EventEmitter();

    @Output()
    public taskUpdateEvent: EventEmitter<ApiTaskDetailsInterface> = new EventEmitter();

    // Other options

    public modalTitle: string = 'Opdracht opgeven';

    public modalMode: TaskModalModeEnum = TaskModalModeEnum.Create;

    public isLoading: boolean = false;

    public setProgressBar: boolean = false;

    public assignments: Array<ApiAssignmentInterface> = [];

    public originalGroups: Array<ContentSwitchGroupModel> = [];

    public books: Array<ApiBookInterface> = [];

    //
    // Fields
    //

    public title: string = '';

    public groups: Array<ContentSwitchGroupModel> = [];

    public taskGroups: Array<AddTaskTaskGroupInterface> = [];

    public startDateTime: number;

    public dueDateTime: number;

    public maxTasks: number = 25;

    public workType: TaskTypeEnum = TaskTypeEnum.Personal;

    public examineOption: ExamineOptionsEnum = ExamineOptionsEnum.Teacher;

    public addTaskFinished: boolean = false;

    public attachments: AttachmentDataInterface = {
        description: '',
        urls: [],
        files: [],
    };

    public selectedTasks: Array<ApiAssignmentInterface> = [];

    public taskProgress: number = 0;

    public countAddTask: number = 0;

    public isAutoCheck: boolean;

    public taskChanged$: Subject<any> = new Subject();

    private subscriptions: Array<Subscription> = [];

    constructor(
        private activeModal: NgbActiveModal,
        private documentService: DocumentService,
        private assignmentService: AssignmentService,
        private toastService: ToastrService,
        private groupService: GroupsService,
        private contentSwitchModelFactory: ContentSelectModelFactory,
        private taskService: TaskService,
        private bookService: BookService,
        private apiTaskBuilderFactory: TasksApiTaskBuilderFactory,
        private analyticsService: AnalyticsService,
    ) {
    }

    public ngOnInit(): void {
        const currentDocument = this.documentService.getCurrentDocument();
        this.isAutoCheck = (!this.task && currentDocument && currentDocument.book && currentDocument.book.auto_check) || (this.task && this.task.auto_check);

        if (currentDocument && (this.bookId === undefined || this.bookId === null)) {
            this.bookId = currentDocument.book.id;
        }

        if (currentDocument && (this.chapterId === undefined || this.chapterId === null)) {
            this.chapterId = currentDocument.chapter.id;
        }

        this.handleLoadData();

        this.taskChanged$.subscribe( () => {
            this.isAutoCheck = this.checkAllAutocheckAssignments();
            this.handleAutoCheckMode();
        });

    }

    public close(): void {
        this.activeModal.dismiss();
    }

    public validateInput(): boolean {
        if (!this.startDateTime) {
            this.toastService.error('Taken konden niet worden opgegeven. Kies een start datum/tijd.');

            return false;
        }

        if (moment.unix(this.startDateTime).isBefore(moment(), 'd')) {
            this.toastService.warning('Taken konden niet worden opgegeven. De start datum/tijd mag niet in het verleden liggen.');

            return false;
        }

        if (!this.dueDateTime) {
            this.toastService.warning('Taken konden niet worden opgegeven. Kies een eind datum/tijd.');

            return false;
        }

        if (this.startDateTime > this.dueDateTime) {
            this.toastService.warning('Taken konden niet worden opgegeven. De start datum/tijd mag niet voor de eind datum/tijd liggen.');

            return false;
        }

        const params = this.getTaskParams(this.selectedTasks);
        if (params.taskgroups.length > 0) {
            for (const taskGroup of params.taskgroups) {
                if (taskGroup.user_groups.length < 1) {
                    this.toastService.warning('Taken konden niet worden opgegeven. Maak de taak groepen aan.');

                    return false;
                }

                if (taskGroup.name === '') {
                    this.toastService.warning('Taken konden niet worden opgegeven. Geef de taakgroep een naam.');

                    return false;
                }
            }
        } else if (params.groups.length < 1 && params.users.length < 1 && params.user_groups.length < 1) {
            this.toastService.warning('Taken konden niet worden opgegeven. Selecteer studenten en/of groepen.');

            return false;
        }

        return true;
    }

    public handleSave(): Promise<void> {
        if (!this.validateInput()) {
            return;
        }

        this.saveTaskList(this.selectedTasks, this.selectedTasks.length);

    }

    private saveTaskList(list: Array<ApiAssignmentInterface>, total: number): void {
        let promise: Promise<void>;

        if (!this.ownTaskMode) {
            if (list.length === 0) {
                return;
            }

            const selectedTask = list.pop();

            promise = this.setTaskParams([selectedTask]);

            if (promise !== undefined) {
                promise.then(
                    () => {
                        this.increaseProgress(100 / total, total);
                        this.saveTaskList(list, total);
                    }
                );
            }
        } else {
            promise = this.setTaskParams(this.selectedTasks);

            if (promise !== undefined) {
                promise.then(
                    () => {
                        this.increaseProgress(100 / total, total);
                    }
                );
            }
        }
    }

    private checkAllAutocheckAssignments(): boolean {
        if (this.selectedTasks.length === 0) {
            return false;
        }

        let allAutocheck = true;

        this.selectedTasks.forEach( task => {
            if (!task.auto_check) {
                allAutocheck = false;
            }
        });

        return allAutocheck;

    }

    private setTaskParams(selectedTasks: Array<ApiAssignmentInterface>): Promise<void> {
        const params = this.getTaskParams(selectedTasks);
        if (params === null) {
            return;
        }

        this.isLoading = true;
        this.setProgressBar = true;

        // autocheck check, correct examination needed enum
        if ( selectedTasks && selectedTasks[0] && selectedTasks[0].auto_check ) {
           params.examination_needed = ExamineOptionsEnum.Auto;
        }

        return this.saveTask(params);
    }

    private getTaskParams(selectedTasks: Array<ApiAssignmentInterface>): AddTaskFormInterface {
        return this.apiTaskBuilderFactory.forModalType(
            this.modalMode,
            this.ownTaskMode,
            this.bookId,
            this.startDateTime,
            this.dueDateTime,
            this.examineOption,
            this.workType,
            this.groups,
            this.taskGroups,
            this.attachments,
            selectedTasks,
            this.title
        );
    }

    private saveTask(params: AddTaskFormInterface): Promise<void> {
        return new Promise(
            (resolve, reject) => {
                this.countAddTask++;
                const isFinished = (this.selectedTasks.length === this.countAddTask) || this.ownTaskMode;

                if (this.modalMode === TaskModalModeEnum.Create) {

                    this.taskService
                        .saveNewTask(params, isFinished)
                        .pipe(finalize(() => this.isLoading = false))
                        .subscribe((task: ApiTaskAddInterface) => {

                            this.analyticsService.event(new AnalyticsEventTaskNewSavedModel());
                            this.taskAddEvent.emit(task.tasks);

                            // this.increaseProgress(100 / this.selectedTasks.length);

                            this.toastService.success(`${task.newTaskCount} van ${task.taskCount} taken opgegeven.`);
                            resolve();
                        }, (error: HttpErrorResponse) => {
                            if (error.error.msg === 'TASK_GROUP_USER_HAS_TASK') {
                                this.toastService.error('Één of meerdere van deze opdrachten is al opgegeven aan één of meerdere van de geselecteerde studenten.');
                            } else {
                                this.toastService.error('Kon taken niet opgeven.');
                            }
                            // this.increaseProgress(100 / this.selectedTasks.length);
                            resolve();
                        });
                } else if (this.modalMode === TaskModalModeEnum.Resit) {
                    this.taskService
                        .resitTask(this.task.id, params, isFinished)
                        .pipe(finalize(() => this.isLoading = false))
                        .subscribe((task: ApiTaskAddInterface) => {
                            this.analyticsService.event(new AnalyticsEventTaskResitSavedModel());
                            this.taskAddEvent.emit(task.tasks);

                            // this.increaseProgress(100 / this.selectedTasks.length);

                            this.toastService.success(`${task.newTaskCount} van ${task.taskCount} taken opgegeven.`);
                            resolve();
                        }, (error: any) => {
                            this.toastService.error('Kon taken niet opgeven.');
                            // this.increaseProgress(100 / this.selectedTasks.length);
                            resolve();
                        });
                } else {
                    this.taskService
                        .updateTask(this.task.id, params)
                        .pipe(finalize(() => this.isLoading = false))
                        .subscribe((task: ApiTaskDetailsInterface) => {

                            if ((this.selectedTasks.length === this.countAddTask) || this.ownTaskMode) {
                                this.analyticsService.event(new AnalyticsEventTaskEditSavedModel());
                                this.taskUpdateEvent.emit(task);
                            }

                            // this.increaseProgress(100 / this.selectedTasks.length);

                            this.toastService.success('Taak is gewijzigd.');
                            resolve();
                        }, () => {
                            this.toastService.error('Kon taak niet wijzigen.');
                            // this.increaseProgress(100 / this.selectedTasks.length);
                            resolve();
                        });
                }
            }
        );
    }

    private increaseProgress(taskPercentage: number, total: number): void {
        if (!isFinite(taskPercentage)) {
            taskPercentage = 100;
        }

        if (this.taskProgress !== 100) {
            this.taskProgress += taskPercentage;
        }

        if ((total === this.countAddTask) || this.ownTaskMode) {
            this.addTaskFinished = true;
            setTimeout(() => {
                this.close();
            }, 1000);
        }
    }

    private handleLoadData(): void {
        if (this.resit === true) {
            this.handleResitTaskMode();

            return;
        }

        if (this.task !== null) {
            this.handleEditTaskMode();

            return;
        }

        this.analyticsService.event(new AnalyticsEventTaskNewModel());

        this.isLoading = true;

        this.loadGroupsAndStudents();

        if (this.ownTaskMode) {
            this.loadBooks();
        } else {
            this.loadAssignments();
        }
    }

    private handleAutoCheckMode(): void {

        if (this.isAutoCheck) {
            this.examineOption = ExamineOptionsEnum.Auto;
        } else if (this.task) {
            this.examineOption = this.task.examination_needed;
        }
    }

    private loadAssignments(): void {
        if (this.ownTaskMode === true) {
            return;
        }

        this.assignmentService.retrieveAssignments(this.bookId, this.chapterId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((assignments: Array<ApiAssignmentInterface>) => {
                this.assignments = assignments;
                this.setPreSelectedTasks();
            }, (errorResponse: HttpErrorResponse) => {
                this.toastService.error('Kon opdrachten niet ophalen.');
            });
    }

    private setPreSelectedTasks(): void {
        if (!this.preSelectedAssignments || this.preSelectedAssignments.length === 0) {
            return;
        }

        const preSelectedTasks = [];

        for (const assignment of this.assignments) {
            if (this.preSelectedAssignments.indexOf(assignment.dpsid) >= 0) {
                preSelectedTasks.push(assignment);
            }
        }

        this.selectedTasks = preSelectedTasks;
        this.taskChanged$.next();
    }

    private loadGroupsAndStudents(): void {
        this.groupService.retrieveAllGroups()
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((apiGroups: ApiGroupsInterface) => {
                const groups = [];

                for (const group of apiGroups.data) {
                    groups.push(ContentSwitchGroupModel.fromApiGroup(group));
                }

                this.originalGroups = groups;
                this.groups = JSON.parse(JSON.stringify(this.originalGroups));
                this.taskGroups = [];
            }, (errorResponse: HttpErrorResponse) => {
                this.toastService.error('Kon groepen en studenten niet ophalen.');
            });
    }

    private loadBooks(): void {
        if (this.ownTaskMode !== true) {
            return;
        }

        this.bookService.retrieveAllBooks('', !this.ownTaskMode)
            .subscribe((apiBooks: ApiBooksInterface) => {
                this.books = apiBooks.data;
            }, (errorResponse: HttpErrorResponse) => {
                this.toastService.error('Kon boeken niet ophalen.');
            });
    }

    //
    // Load task modal from API data
    //

    private handleEditTaskMode(): void {
        this.modalMode = TaskModalModeEnum.Edit;
        this.modalTitle = 'Opdracht bewerken';

        this.setCommonData();

        if (this.task.is_taskgrouptask) {
            this.workType = TaskTypeEnum.Group;

            for (const apiTaskGroup of this.task.taskgroups) {
                const taskGroup: AddTaskTaskGroupInterface = {
                    id: apiTaskGroup.id,
                    title: apiTaskGroup.name,
                    students: [],
                    groups: []
                };

                const groupCache = {};

                for (const userGroup of apiTaskGroup.users) {
                    if (!groupCache[userGroup.group.id]) {
                        groupCache[userGroup.group.id] = new ContentSwitchGroupModel(String(userGroup.group.id), userGroup.group.name);
                    }

                    const student = new ContentSwitchStudentModel(String(userGroup.user.id), userGroup.user.name);
                    student.active = true;

                    groupCache[userGroup.group.id].addStudent(student);
                    taskGroup.students.push(student);
                }

                taskGroup.groups = Object.values(groupCache);

                this.taskGroups.push(taskGroup);
            }
        } else if (this.task.group_task) {
            const group = new ContentSwitchGroupModel(String(this.task.group), this.task.assign_label);
            group.active = true;

            this.groups.push(group);
        } else {
            const groupCache = {};

            for (const childTask of this.task.children) {
                if (!groupCache[childTask.group]) {
                    groupCache[childTask.group] = new ContentSwitchGroupModel(String(childTask.group), '-');
                }

                const student = new ContentSwitchStudentModel(String(childTask.assigned_to.id), childTask.assigned_to.name);
                student.active = true;

                groupCache[childTask.group].addStudent(student);
            }

            this.groups = Object.values(groupCache);
        }

        this.analyticsService.event(new AnalyticsEventTaskEditModel());
    }

    private handleResitTaskMode(): void {
        this.modalMode = TaskModalModeEnum.Resit;
        this.modalTitle = 'Opdracht herkansen';

        this.task.examination_needed = (this.isAutoCheck) ?  ExamineOptionsEnum.Auto : ExamineOptionsEnum.Teacher;

        this.setCommonData();

        const now = Math.round(Date.now() / 1000);

        if (this.startDateTime < now) {
            this.startDateTime = now;
        }

        if (this.dueDateTime < this.startDateTime) {
            this.dueDateTime = this.startDateTime;
        }

        if (this.task.is_taskgrouptask) {
            this.workType = TaskTypeEnum.Group;

            const apiTaskGroup = this.task.taskgroup;

            const taskGroup: AddTaskTaskGroupInterface = {
                id: apiTaskGroup.id,
                title: apiTaskGroup.name,
                students: [],
                groups: []
            };

            const groupCache = {};

            for (const userGroup of apiTaskGroup.users) {
                if (!groupCache[userGroup.group.id]) {
                    groupCache[userGroup.group.id] = new ContentSwitchGroupModel(String(userGroup.group.id), userGroup.group.name);
                }

                const student = new ContentSwitchStudentModel(String(userGroup.user.id), userGroup.user.name);
                student.active = true;

                groupCache[userGroup.group.id].addStudent(student);
                taskGroup.students.push(student);
            }

            taskGroup.groups = Object.values(groupCache);

            this.taskGroups.push(taskGroup);
        } else {
            const group = new ContentSwitchGroupModel(String(this.task.group), '');

            const student = new ContentSwitchStudentModel(String(this.task.assigned_to.id), this.task.assigned_to.name);
            student.active = true;

            group.addStudent(student);

            this.groups.push(group);
        }

        this.analyticsService.event(new AnalyticsEventTaskResitModel());
    }

    private setCommonData(): void {
        this.bookId = this.task.book;
        this.examineOption = this.task.examination_needed;
        this.startDateTime = this.task.start_date;
        this.dueDateTime = this.task.duedate;
        this.attachments.description = this.task.description;

        for (const apiFile of this.task.files) {
            this.attachments.files.push(apiFile);
        }

        for (const apiUrl of this.task.urls) {
            this.attachments.urls.push(apiUrl);
        }

        if (this.task.assignment) {
            this.selectedTasks.push(this.task.assignment);
            this.taskChanged$.next();

            this.analyticsService.dimension(AnalyticsDimensionEnum.AssignmentResourceTitle, this.task.assignment.resource.title);
            this.analyticsService.dimension(AnalyticsDimensionEnum.AssignmentTitle, this.task.assignment.title);
        } else {
            this.ownTaskMode = true;
            this.title = this.task.title;

            this.analyticsService.dimension(AnalyticsDimensionEnum.AssignmentResourceTitle, null);
            this.analyticsService.dimension(AnalyticsDimensionEnum.AssignmentTitle, null);

            this.loadBooks();
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.map(sub => sub.unsubscribe());
    }
}
