import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NoteService} from 'services/note/note.service';
import {Subscription} from 'rxjs';
import {UserService} from 'security/services/user/user.service';

@Component({
    selector: 'app-document-notes]',
    templateUrl: 'notes.component.html',
})
export class DocumentNotesComponent implements OnInit, OnDestroy {

    @Input()
    public document: ApiDocumentDataInterface;

    public addButtonElement: HTMLElement = null;

    public currentUserId: number = null;

    private blackList: Array<string> = [
        'document-notes-button__button',
        'document__input-inline',
        'read-speaker',
    ];

    private userChangedSubscription: Subscription;

    private notesChangedSubscription: Subscription;

    constructor(
        private noteService: NoteService,
        private userService: UserService,
    ) {}

    public ngOnInit(): void {
        this.notesChangedSubscription = this.noteService.notesChange.subscribe((notes: Array<ApiNoteInterface>) => {
            if (notes.length > 0) {
                this.document.notes = notes;
            }
        });

        this.userChangedSubscription = this.userService.onUserDataChange.subscribe(() => {
            this.currentUserId = this.userService.getCurrentUserId();
        });

        this.currentUserId = this.userService.getCurrentUserId();
    }

    public ngOnDestroy(): void {
        if (this.notesChangedSubscription instanceof Subscription) {
            this.notesChangedSubscription.unsubscribe();
        }

        if (this.userChangedSubscription instanceof Subscription) {
            this.userChangedSubscription.unsubscribe();
        }
    }

    public hideButton(): void {
        this.addButtonElement = null;
    }

    public showButton(event: MouseEvent): void {
        if (this.isNotesButtonElement(event)) {
            return;
        }

        const closestElement = this.getBestAndClosestElement(event);

        if (closestElement !== null) {
            setTimeout(() => this.addButtonElement = closestElement);

            return;
        }

        this.hideButton();
    }

    private isNotesButtonElement(event: MouseEvent): boolean {
        let path = null;

        if (event.composedPath) {
            path = event.composedPath();
        } else {
            path = event['path'];
        }

        for (const target of path) {
            const targetElement = <HTMLElement>target;

            if (targetElement.classList && targetElement.classList.contains('document-notes-button')) {
                return true;
            }
        }

        return false;
    }

    private getBestAndClosestElement(event: MouseEvent): HTMLElement {
        let bestElement = null;

        for (const target of event.composedPath()) {
            const targetElement = <HTMLElement>target;

            if (targetElement.localName === 'app-document') {
                break;
            }

            if (targetElement.classList) {
                for (let i = 0; i < targetElement.classList.length; i++) {
                    if (this.blackList.includes(targetElement.classList.item(i))) {
                        return null;
                    }
                }
            }

            const targetId = targetElement.id;

            if (bestElement !== null || targetId === undefined || targetId === null || targetId.length === 0) {
                continue;
            }

            bestElement = targetElement;
        }

        return bestElement;
    }

    public handleNoteRemoved(note: ApiNoteInterface): void {
        this.document.notes.splice(this.document.notes.indexOf(note), 1);
    }
}
