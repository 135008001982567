import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ContentSelectItemInterface} from 'shared/modules/content-select/interfaces/select-item.interface';
import {ContentSelectComponent} from 'shared/modules/content-select/components/content-select.component';
import {ContentSelectModelFactory} from 'shared/modules/content-select/factories/model.factory';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';
import {IconBoxTrashCan} from 'models/iconbox/trash-can.model';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconEnum} from 'enums/icon.enum';
import {FormClassModifiersEnum} from 'enums/form-class-modifiers.enum';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';
import {SearchPrefixedInstantModel} from 'shared/models/search/prefixed-instant.model';
import {ClassHelper} from 'helpers/dom/class.helper';
import naturalCompare from 'string-natural-compare';

@Component({
    selector: 'app-tasks-add-modal-assignments',
    templateUrl: 'assignments.component.html',
})
export class TasksAddTaskModalAssignmentsComponent implements OnChanges {

    public readonly iconEnum = IconEnum;

    public readonly iconBoxTrashCan = new IconBoxTrashCan([IconBoxClassEnum.ColorBlue]);

    public readonly search = new SearchPrefixedInstantModel('Zoek een opdracht...', [FormClassModifiersEnum.NoBorderRadius]);

    @ViewChild('tasksContentSwitch', { static: false })
    protected tasksContentSwitch: ContentSelectComponent;

    @Input()
    public tasks: Array<ApiAssignmentInterface> = [];

    @Output()
    public tasksChange: EventEmitter<Array<ApiAssignmentInterface>> = new EventEmitter();

    @Input()
    public examine: ExamineOptionsEnum;

    @Input()
    public assignments: Array<ApiAssignmentInterface> = [];

    @Input()
    public disabled: boolean = false;

    @Input()
    public max: number = 25;

    public differs = ExamineOptionsEnum.Differs;

    public examineOption: ExamineOptionsEnum;

    public examinOptionEnums = ExamineOptionsEnum;

    public classHelper: ClassHelper = new ClassHelper();

    public contentSwitchTasks: Array<ContentSelectItemInterface> = [];

    constructor(
        private contentSwitchModelFactory: ContentSelectModelFactory,
    ) {
        this.classHelper.addClass(ContentSelectClassModifiersEnum.TasksAddModal);
        this.examineOption = ExamineOptionsEnum.Teacher;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.assignments && changes.assignments.currentValue) {
            const switchItems = [];

            for (const assignment of this.assignments) {
                if (Object.keys(assignment).length === 0) {
                    continue;
                }

                const switchItem = this.contentSwitchModelFactory.fromApiAssignment(assignment);

                switchItems.push(switchItem);
            }

            this.contentSwitchTasks = switchItems;
        }

        if (changes.tasks && changes.tasks.currentValue) {
            for (const task of this.tasks) {
                const switchItem = this.contentSwitchTasks.find(child => child.id === task.id.toString());

                if (!switchItem) {
                    continue;
                }

                switchItem.active = true;
            }
        }

        if (changes.examine && changes.examine.currentValue) {

            if (changes.examine.currentValue === ExamineOptionsEnum.Differs) {
                this.tasks.map((task) => task.examination_needed = ExamineOptionsEnum.Teacher);
            } else {
                this.tasks.map((task) => task.examination_needed = undefined);
            }
        }
    }

    public handleTasksSelection(selection: Array<ContentSelectItemInterface>): void {
        this.tasks = this.assignments.filter(task => selection.map(({id}) => Number(id)).includes(task.id));
        this.tasksChange.emit(this.tasks);
    }

    public removeSelectedTask(task: ApiAssignmentInterface): void {
        const taskIndex = this.tasks.indexOf(task);

        this.tasks.splice(taskIndex, 1);

        const switchItem = this.contentSwitchTasks.find(child => child.id === task.id.toString());
        switchItem.active = false;

        this.tasksContentSwitch.handleItemEvent({ item: switchItem, childEvent: false });
        this.tasksChange.emit(this.tasks);
    }

    public examineChange(examine: ExamineOptionsEnum, task: ApiAssignmentInterface): void {

        if (this.examine !== ExamineOptionsEnum.Differs) {
            task.examination_needed = this.examine;
        } else {
            task.examination_needed = examine;
        }

    }
}
