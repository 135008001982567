import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {PublisherService} from 'services/publisher/publisher.service';

@Injectable()
export class RouterPublisherResolveService implements Resolve<void> {

    constructor(
        private router: Router,
        private publisherService: PublisherService,
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
        let publisher = route.params.publisher;
        if ('eduactief' === route.params.publisher) {
            publisher = 'bbo';
        }

        this.publisherService.setPublisher(publisher);

        if (!this.publisherService.isValidPublisher()) {
            this.router.navigate(['/']);
        }
    }
}
