import {NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';

const I18N_VALUES = {
    'nl': {
        weekdays: ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
    },
};

@Injectable()
export class DatePickerI18n extends NgbDatepickerI18n {

    private readonly language = 'nl';

    public getWeekdayShortName(weekday: number): string {
        return I18N_VALUES[this.language].weekdays[weekday - 1];
    }

    public getMonthShortName(month: number): string {
        return I18N_VALUES[this.language].months[month - 1];
    }

    public getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    public getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}
