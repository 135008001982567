import {Injectable} from '@angular/core';
import {MessageService} from 'services/message/message.service';
import {UserService} from 'security/services/user/user.service';
import {MercureMessageType, MercureService} from 'services/mercure/mercure.service';

enum NotificationTypeEnum {
    HomeworkNew = 10,
    HomeworkEdit = 11,
    HomeworkDelete = 12,
    HomeworkHandIn = 13,
    TaskNew = 20,
    TaskEdit = 21,
    TaskDelete = 22,
    TaskDeadlineApproaching = 23,
    TaskDeadlineExpired = 24,
    TaskResit = 25,
    NoteShare = 30,
    FeedbackNew = 40,
    ExamPlanNew = 50,
    ExamNeedsReview = 51,
    ExamReviewed = 52,
    ExamExamPlanStart = 53,
    ExamPlanDelete = 54,
    AssessmentExamResult = 60,
    AssessmentReady = 61,
    NewSource = 70,
    InternshipReviewCompleted = 80,
    Message = 90,
    GroupJoined = 100,
    GroupLeft = 101,
}

interface PollMessageContentInterface {
    id: number;
    title: string;
    description: string;
    type: number;
    created: number;
    viewed?: number;
    object_id?: number;
    info: any;
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(
        private mercureService: MercureService,
        private messageService: MessageService,
        private userService: UserService,
    ) {
    }

    public initialize(): void {
        this.mercureService.subscribe<PollMessageContentInterface>(MercureMessageType.Notification, data => this.handleNotification(data));
    }

    private handleNotification(notification: PollMessageContentInterface): void {
        switch (notification.type) {
            case NotificationTypeEnum.HomeworkNew:
            case NotificationTypeEnum.HomeworkDelete:
            case NotificationTypeEnum.TaskNew:
            case NotificationTypeEnum.TaskDelete:
            case NotificationTypeEnum.ExamPlanNew:
            case NotificationTypeEnum.ExamPlanDelete:
            case NotificationTypeEnum.GroupJoined:
            case NotificationTypeEnum.GroupLeft:
                this.handleReloadLogin();
                break;
        }
    }

    private handleReloadLogin(): void {
        this.userService.reload();
    }
}
