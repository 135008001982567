import {Component, HostBinding} from '@angular/core';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';

@Component({
    selector: `.document-steplist`,
    templateUrl: 'step-list.component.html',
})
export class DocumentStepListComponent {

    @HostBinding('class')
    public elementClass: string = DocumentHostClassesEnum.StepList;

}
