import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import {DocumentSingleSelectBlockOptionsInterface} from 'document/modules/components/single-select-block/interface/options.interface';
import {DocumentAnswerableComponent} from 'document/modules/abstract/answerable.component';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {DocumentMultiSelectBlockOptionInterface} from 'document/modules/components/multi-select-block/interface/option.interface';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AutoCheckService } from 'document/services/autocheck/auto-check.service';
import {RoleEnum} from 'enums/role.enum';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';
import {DocumentService} from 'document/services/document/document.service';

// OPDRACHT 14 - Meerkeuzevragen

@Component({
    selector: '.document-singleselectblock',
    templateUrl: 'single-select-block.component.html',
})
export class DocumentSingleSelectBlockComponent extends DocumentAnswerableComponent implements AfterViewInit, OnDestroy {

    private baseElementClass: string = 'document__single-select-block';

    @HostBinding('class')
    public elementClass: string = this.baseElementClass;

    public readonly roleEnum = RoleEnum;

    public readonly examinationNeededEnum = ExamineOptionsEnum;

    public examinationByStudent: boolean = false;

    public id: string;

    public options: DocumentSingleSelectBlockOptionsInterface;

    public value: string = '';

    public correctAnswer: string = '';

    public initialAnswer: string;

    public answerHidden: boolean;

    protected dataSet: DocumentDatasetOptionsInterface;

    public autoScoreEnabled: boolean;

    public autoScoreAnswer: any;

    private subscriptions: Array<Subscription> = [];

    constructor(
        protected elementRef: ElementRef,
        protected answersService: DocumentAnswersService,
        protected showAnswersService: DocumentAnswersStateService,
        private autocheckService: AutoCheckService,
        private documentService: DocumentService,
    ) {
        super(elementRef, answersService, showAnswersService);

        this.id = `single-select-block-${this.dataSet.assignmentid}-${this.dataSet.fieldid}`;

        try {
            this.options = JSON.parse(this.dataSet.options);
            this.autoScoreEnabled = false;


        } catch (e) {
            this.initializationFailed = true;

            console.error('Failed to JSON parse the options:', e);
        }
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.setupAutoScoring();
        this.handleExaminationNeeded();
    }

    public onChange(): void {
        this.saveAnswer(this.value);
    }

    protected setAnswer(answer: string): void {
        if (!answer) {
            return;
        }
        this.value = answer;
    }

    private handleExaminationNeeded(): void {
        const task = this.documentService.getActiveTask();

        // examination by Student
        if (task && task.examination_needed === this.examinationNeededEnum.Student) {
            this.examinationByStudent = this.documentService.subscribeSelfStudyCompatible();
        }
    }

    private setupAutoScoring(): void {

        const assignmentId = this.dataSet.assignmentid;
        const fieldId = parseInt(this.dataSet.fieldid, 10);
        const assignment = this.answersService.getAssignment(assignmentId);
        this.autoScoreEnabled = assignment && assignment.auto_check;

        if (! this.autoScoreEnabled) { return; }

        this.subscriptions.push( this.autocheckService.getAutoCheckAnswerForFieldId(assignmentId, fieldId).pipe(take(1)).subscribe( autoCheckAnswer => {
            if (autoCheckAnswer) {
                this.autoScoreAnswer = autoCheckAnswer;
            }
        }));
    }

    protected showCorrectAnswer(correctAnswer: string): void {
        if (!correctAnswer || !this.options) {
            return;
        }

        for (const option of this.options.choices) {
            if (option.label === correctAnswer || option.value === correctAnswer) {
                this.correctAnswer = option.value;
            }
        }

        this.answerHidden = false;
    }

    protected hideCorrectAnswer(): void {
        this.answerHidden = true;
    }

    protected showInitialAnswer(initialAnswer: string): void {
        if (!initialAnswer || !this.options) {
            return;
        }

        if (initialAnswer === this.value) {
            return;
        }

        for (const option of this.options.choices) {
            if (option.value === initialAnswer) {
                this.initialAnswer = option.value;
            }
        }
    }

    protected answerValid(): boolean {
        return true;
    }

    public selectOption(choice: DocumentMultiSelectBlockOptionInterface): void {
        this.value = choice.value;
        this.saveAnswer(this.value);
    }

    public ngOnDestroy(): void {
        this.autocheckService.resetAutoCheckAnswer();
        this.subscriptions.map(sub => sub.unsubscribe());
    }
}
