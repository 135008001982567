export enum HelpPageClassEnum {

    One = 'modal--help-page-set-position-one',

    Two = 'modal--help-page-set-position-two',

    Three = 'modal--help-page-set-position-three',

    Four = 'modal--help-page-set-position-four',

    Five = 'modal--help-page-set-position-five',

}
