import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {ToastrService} from 'ngx-toastr';
import {combineLatest, of, Subscription} from 'rxjs';
import {ApiExamStepInterface, ExamService} from 'services/exam/exam.service';
import {IconEnum} from 'enums/icon.enum';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {ActionButtonClassEnum} from 'shared/enums/action-button/class.enum';
import {ModalService} from 'core/services/modal/modal.service';
import {BookService} from 'services/book/book.service';
import {RouteService} from 'routing/services/route/route.service';
import {ExamMetadataService} from 'services/exam/exam-metadata.service';
import {ExamStateInterface, ExamStateService} from 'pages/modules/content-exam/service/exam-state.service';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {RoleEnum} from 'enums/role.enum';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {switchMap, take} from 'rxjs/operators';

@Component({
    selector: 'app-content-exam-page',
    templateUrl: 'page.component.html',
})
export class ContentExamPageComponent implements OnInit, OnDestroy {
    public readonly roleEnum = RoleEnum;

    private subscriptions: Subscription[] = [];

    private modalNotShown: boolean = true;

    private book!: ApiBookInterface;

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public toggleAnswers: boolean;

    public actionCloseExam: ActionButtonInterface = {
        id: 'button-close-exam',
        value: 'Afsluiten',
        disabled: false,
        iconBox: {
            value: IconEnum.TrophyBasic,
            type: IconBoxTypeEnum.Icon,
        },
        classModifiers: [
            ActionButtonClassEnum.Exam,
            ActionButtonClassEnum.Mobile,
        ],
    };


    public actionShowAnswersExamShow: ActionButtonInterface = {
        id: 'button-toggle-answer-exam',
        value: 'Antwoord tonen',
        disabled: false,
        iconBox: {
            value: IconEnum.BookClose,
            type: IconBoxTypeEnum.Icon,
        },
        classModifiers: [
            ActionButtonClassEnum.ShowAnswer,
            ActionButtonClassEnum.Mobile,
        ],
    };

    public actionShowAnswersExamHide: ActionButtonInterface = {
        id: 'button-toggle-answer-exam',
        value: 'Antwoord verbergen',
        disabled: false,
        iconBox: {
            value: IconEnum.BookClose,
            type: IconBoxTypeEnum.Icon,
        },
        classModifiers: [
            ActionButtonClassEnum.ShowAnswer,
            ActionButtonClassEnum.Mobile,
        ],
    };

    public isLoading: boolean = true;

    public bookTitle: string;

    public title: string;

    public document: ApiDocumentDataInterface = null;

    public bookId: number;

    public examDpsId: string;

    public planId: string;

    public studentId: string;

    public bookRoute: string;

    public showResults: boolean = false;

    public constructor(
        private bookService: BookService,
        private examService: ExamService,
        private examStateService: ExamStateService,
        private examMetadataService: ExamMetadataService,
        private toastService: ToastrService,
        private routeService: RouteService,
        private route: ActivatedRoute,
        private router: Router,
        private modalService: ModalService,
        private answerStateService: DocumentAnswersStateService,
        private authService: AuthorizationService
    ) {
    }

    public ngOnInit(): void {
        this.examService.setToDisableBookTitle(true);
        this.subscriptions.push(this.route.params.subscribe(params => this.handleRouteLoad(params)));
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public onExamState(examState?: ExamStateInterface): void {
        if (!examState.hasMetadata) {
            return;
        }

        this.isLoading = true;
        this.handleExamState(examState).then((loading: boolean | undefined) => this.isLoading = loading !== undefined || loading || false);
    }

    private async handleExamState(examState: ExamStateInterface): Promise<void | boolean> {
        this.showResults = examState.showResults;

        if (undefined !== examState.assignment) {
            try {
                return this.loadAssignment(examState.assignment, examState.attempt);
            } catch (error) {
                return this.handleError('Het is niet gelukt de opdracht te laden.', error);
            }
        }

        if (examState.hasReachedMaxAttempts || examState.showResults) {
            const showModal: boolean = this.modalNotShown && examState.hasReachedMaxAttempts && examState.isAtFinalAttempt && examState.isPractiseExam && !examState.viewUserMode;

            const result = (showModal ? this.modalService.practiceExamResults().catch(() => this.navigateToBook()) : Promise.resolve());
            this.modalNotShown = false;

            return result;
        }

        if (!examState.inProgress && !examState.hasResults) {
            try {
                await this.startExamWithModal();

                return await this.startExam();
            } catch (error_1) {
                return this.handleError('Het is niet gelukt om het examen te starten.', error_1);
            }
        }

        if (examState.inProgress) {
            try {
                return this.finish();
            } catch (error_2) {
                return this.handleError('Het is niet gelukt om het examen af te ronden.', error_2);
            }
        }

        return Promise.reject('Invalid state');
    }

    private handleRouteLoad(params: Params): void {
        const bookId = this.bookId = params.bookId;
        const examDpsId = this.examDpsId = params.examId;
        const planId: string | undefined = this.planId = params.planId;
        const studentId: string | undefined = this.studentId = params.studentId;

        this.bookService.getBook(bookId.toString()).pipe(
            switchMap(book => combineLatest([
                of(book),
                this.examMetadataService.retrieve(book.uuid, examDpsId, planId, studentId)
            ]))
        ).pipe(take(1)).subscribe((data: [ApiBookInterface, ApiExamMetadataInterface]) => {
            const book = this.book = data[0];

            this.bookTitle = book.title;
            this.title = data[1].title;
            this.planId = String(data[1].examPlanId);
            this.modalNotShown = true;

            this.subscriptions.push(this.examStateService.subscribe(examState => this.onExamState(examState)));
            this.updateBookRoute();
        });
    }

    private async finish(): Promise<void> {
        const metadata = await this.examService.retrieveExamResults(this.book.uuid, this.examDpsId, this.planId).toPromise();

        return this.examMetadataService.addResult(metadata.results.pop());
    }

    private async loadAssignment(assignment: ApiExamAssignmentInterface, attempt: number): Promise<void> {
        try {
            const examStep = await this.examService.retrieveExamAssignment(this.book.uuid, this.examDpsId, assignment.dpsId, attempt, this.planId, this.studentId).toPromise();

            return this.setDocument(examStep);
        } catch (response) {
            return this.handleError('Kon het document niet ophalen.', response.error);
        }
    }

    private navigateToBook(): Promise<any> {
        return this.router.navigate([this.bookRoute]);
    }

    private startExamWithModal(): Promise<void> {
        const isPractiseExam: boolean = this.examMetadataService.isPractiseExam();
        if (this.authService.isGranted(RoleEnum.RoleStudent) && isPractiseExam) {
            return this.modalService.practiceExamStart();
        }

        return Promise.resolve();
    }

    private async startExam(): Promise<void> {
        await this.examService.startExam(this.book.uuid, this.examDpsId, this.planId).toPromise();

        return this.examStateService.setInProgress(true);
    }

    private setDocument(examStep: ApiExamStepInterface): void {
        this.document = examStep.document.legacyDocument;
        this.toggleAnswers = false;
    }

    private updateBookRoute(): void {
        const bookRoute = this.bookRoute = this.routeService.getRouteWithPublisher(RoutesEnum.Book, new Map([['bookId', String(this.bookId)]]));

        this.actionCloseExam.routerLink = this.bookRoute = bookRoute;
    }

    private handleError(message: string, error: any): void {
        if (this.isServerError(error) && error.msg === 'No license for exam') {
            this.toastService.error('Je hebt geen geldige licentie voor deze toets.');
        } else {
            this.toastService.error(message);
        }

        throw error; // Rethrow original error for Sentry
    }

    private isServerError(error: ApiResponseInterface<string> | string): error is ApiResponseInterface<string> {
        return undefined !== (error as ApiResponseInterface<string>).msg;
    }

    public toggleAnswer(): void {
        this.toggleAnswers = !this.toggleAnswers;

        if (this.toggleAnswers) {
            return this.answerStateService.showAnswers();
        }

        return this.answerStateService.hideAnswers();
    }
}
