export enum HorizontalBarTextClassesEnum {

    Base = 'horizontal-bar__text',

    NoFlex = 'horizontal-bar__text--no-flex',

    FlexEnd = 'horizontal-bar__text--flex-end',

    NoBorderRight = 'horizontal-bar__text--no-border-right',

    Clickable = 'horizontal-bar__text--clickable',

    LineHeightNormal = 'horizontal-bar__text--line-height-normal',
}
