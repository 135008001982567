import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'default'
})
export class DefaultPipe implements PipeTransform {

    public transform(value: any, fallBack: any): any {
        const fallBackValue = fallBack !== undefined ? fallBack : null;

        return value === undefined ? fallBackValue : value;
    }

}
