import {Component, ElementRef, HostBinding, OnInit, ViewChild} from '@angular/core';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import {IconEnum} from 'enums/icon.enum';
import {ColorEnum} from 'enums/color.enum';
import {TypographyListClassesEnum} from 'enums/typography-list-classes.enum';
import {DocumentSubSectionSelectorsEnum} from 'document/modules/components/sub-section/enums/selectors.enum';
import {AngularEnumSelectorHelper} from 'helpers/static/angular-enum-selector.helper';
import {BootstrapSpacingClassesEnum} from 'enums/bootstrap-spacing-classes.enum';

@Component({
    selector: `${AngularEnumSelectorHelper.getAsSelector(DocumentSubSectionSelectorsEnum)}`,
    templateUrl: 'sub-section.component.html',
})
export class DocumentSubSectionComponent implements OnInit {

    public readonly colors = ColorEnum;

    @ViewChild('subSectionContent', { read: ElementRef, static: true })
    public contents: ElementRef;

    @HostBinding('class')
    public elementClass: string = DocumentHostClassesEnum.SubSection;

    public icon: IconEnum = IconEnum.Bullseye;

    public title: string;

    public classHelper: ClassHelper = new ClassHelper();

    protected selector: string;

    constructor(private elementRef: ElementRef) {
    }

    public ngOnInit(): void {
        const classList: DOMTokenList = this.elementRef.nativeElement.classList;
        const contentsElement: HTMLElement = this.contents.nativeElement;
        const firstChild = contentsElement.firstChild as HTMLElement;

        this.selector = AngularEnumSelectorHelper.findValueByDOMTokenList(DocumentSubSectionSelectorsEnum, classList);
        this.icon = this.getHeaderIcon();

        if (firstChild instanceof HTMLHeadingElement) {
            this.setTitle(firstChild);
        }

        this.setListClasses(contentsElement.childNodes);
    }

    protected setTitle(element: HTMLHeadingElement): void {
        this.title = element.innerText;
        element.remove(); // Remove heading
    }

    private setListClasses(children: NodeListOf<ChildNode>): void {
        children.forEach((child: ChildNode) => {
            if (child instanceof HTMLUListElement) {
                child.classList.add(TypographyListClassesEnum.StyleColorBlue, this.getListStyle());

                this.updateListClasses(child);
            }
        });
    }

    private getListStyle(): TypographyListClassesEnum {
        switch (this.selector) {
            case DocumentSubSectionSelectorsEnum.Summary:
                return TypographyListClassesEnum.StyleCheck;
            default:
                return TypographyListClassesEnum.StyleCircle;
        }
    }

    private updateListClasses(list: HTMLUListElement): void {
        const previousSibling: HTMLElement = <HTMLElement>list.previousSibling;

        if (previousSibling && previousSibling instanceof HTMLParagraphElement) {
            previousSibling.classList.add(BootstrapSpacingClassesEnum.NoMarginBottom);
        }
    }

    private getHeaderIcon(): IconEnum {
        switch (this.selector) {
            case DocumentSubSectionSelectorsEnum.Goals:
                return IconEnum.Bullseye;
            case DocumentSubSectionSelectorsEnum.Example:
                return IconEnum.Search;
            case DocumentSubSectionSelectorsEnum.Reading:
                return IconEnum.Reading;
            case DocumentSubSectionSelectorsEnum.Level:
                return IconEnum.Binoculars;
            case DocumentSubSectionSelectorsEnum.Summary:
                return IconEnum.HomeWork;
            case DocumentSubSectionSelectorsEnum.TaskPlan:
                return IconEnum.RoadMap;
            default:
                return IconEnum.Question;
        }
    }
}
