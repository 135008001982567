import {Injectable} from '@angular/core';
import {ProgressModuleStatusInterface} from 'pages/modules/progress/interfaces/module-status.interface';

@Injectable()
export class ProgressModuleStatusFactory {

    public fromApiProgressStudent(progressStudent: ApiProgressStudentInterface): ProgressModuleStatusInterface {
        const status = progressStudent.module_status;

        return {
            id: status.id,
            bpv_status: status.bpv_status,
            files: status.files
        };
    }

}
