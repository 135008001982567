import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {IconBoxClose} from 'models/iconbox/close.model';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AddAttachmentPreferencesEnum} from 'shared/modals/add-attachments/add-attachment-preferences/enum/add-attachment-preferences.enum';

@Component({
    selector: 'app-add-attachments',
    templateUrl: 'add-attachments.component.html',
})
export class AddAttachmentsModalComponent implements OnInit {

    @Output()
    public preferencesEvent: EventEmitter<string> = new EventEmitter<string>();

    public modalTitle: string = 'Bijlage toevoegen';

    public addAttachmentOpenPreference: AddAttachmentPreferencesEnum;

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorBlue]);

    constructor(
        private activeModal: NgbActiveModal,
    ) {
    }

    public ngOnInit(): void {
        this.addAttachmentOpenPreference = AddAttachmentPreferencesEnum.Local;
    }

    public close(): void {
        this.activeModal.dismiss();
    }

    public handlePreferences(): void {
        this.preferencesEvent.emit(this.addAttachmentOpenPreference);
    }
}
