import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {Observable, Observer} from 'rxjs';
import {StringService} from 'services/string/string.service';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {ProgressModuleStatusInterface} from 'pages/modules/progress/interfaces/module-status.interface';

@Injectable()
export class InternshipService {
    constructor(
        private api: ApiService,
        private stringService: StringService,
    ) {
    }

    public getPossiblePracticeSupervisors(): Observable<Array<ApiUserInterface>> {
        return this.api.get(ApiEndpointEnum.TeachersOrganisation);
    }

    public invitePracticeSupervisor(bookId: number, teacherId: number): Observable<ApiInternshipInterface> {
        const data = {
            book: bookId,
            mentor: teacherId,
        };

        return this.api.post(ApiEndpointEnum.InternshipAdd, data);
    }

    public updatePracticeSupervisor(internshipId: number, bookId: number, teacherId: number): Observable<ApiInternshipInterface> {
        return new Observable((observer: Observer<ApiInternshipInterface>) => {
            this.removePracticeSupervisor(internshipId).subscribe(() => {
                this.invitePracticeSupervisor(bookId, teacherId).subscribe((data: ApiInternshipInterface) => {
                    observer.next(data);
                    observer.complete();
                }, (err) => {
                    observer.error(err);
                    observer.complete();
                });
            }, (err) => {
                observer.error(err);
                observer.complete();
            });
        });
    }

    public removePracticeSupervisor(internshipId: number): Observable<null> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.InternshipRemove, new Map([
            ['internShipId', internshipId.toString()],
        ]));

        return this.api.delete(apiRoute);
    }

    public inviteExternalReviewer(taskId: number, name: string, email: string, remark: string): Observable<ApiInternshipInviteInterface> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.InternshipInviteExternalReviewer, new Map([
            ['taskId', taskId.toString()],
        ]));

        const mail_link = `${window.location.href}?hash=:hash`;

        return this.api.post(apiRoute, {
            name: name,
            email: email,
            message: remark,
            mail_link: mail_link,
        });
    }

    public handInReview(inviteId: number): Observable<any> {
        return this.api.post(ApiEndpointEnum.InternshipComplete, {
            internshipinvite: inviteId,
        });
    }

    public attachFile(userModuleStatusId: number, file: File): Observable<ProgressModuleStatusInterface> {
        return new Observable((observer: Observer<ProgressModuleStatusInterface>) => {
            this.api.uploadFile(file).subscribe((uploadedFile: ApiFileInterface) => {
                const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.InternshipAttachFile, new Map([
                    ['moduleStatusId', userModuleStatusId.toString()],
                    ['fileId', uploadedFile.id.toString()],
                ]));

                this.api.post(apiRoute).subscribe((data: ProgressModuleStatusInterface) => {
                    observer.next(data);
                    observer.complete();
                }, (err) => {
                    observer.error(err);
                    observer.complete();
                });
            }, (err) => {
                observer.error(err);
                observer.complete();
            });
        });
    }

    public deleteFile(userModuleStatusId: number, fileId: number): Observable<null> {
        const apiRoute = this.stringService.getMappedString(ApiEndpointEnum.InternshipDeleteFile, new Map([
            ['moduleStatusId', userModuleStatusId.toString()],
            ['fileId', fileId.toString()],
        ]));

        return this.api.post(apiRoute);
    }
}
