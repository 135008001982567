export enum CardClassEnum {

    HeightMd = 'card--height-md',

    MarginBottomNone = 'card--margin-bottom-none',

    ColorBlue = 'card--color-24boost-blue',

    ColorBlueDark = 'card--color-24boost-blue-dark',

    ColorRed = 'card--color-24boost-red',

    ColorOrange = 'card--color-24boost-orange',

    ColorGreyDark = 'card--color-24boost-grey-dark',

    CursorPointer = 'card--cursor-pointer',

    HoverNone = 'card--hover-none',

}
