import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-content-versions-modal',
    templateUrl: 'content-versions-modal.component.html',
})
export class DocumentPageVersionsModalComponent {

    constructor(
        private activeModal: NgbActiveModal,
    ) {
    }

    public close(): void {
        this.activeModal.dismiss();
    }

}
