import {Component, HostBinding, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from 'security/services/authentication/authentication.service';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {UserService} from 'security/services/user/user.service';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {DropdownComponent} from 'shared/modules/dropdown/components/dropdown.component';
import {DropdownClassEnum} from 'shared/modules/dropdown/enums/dropdown-class.enum';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import {AvatarClassesEnum} from 'shared/components/avatar/enums/avatar-classes.enum';
import {DeviceDetectorService} from 'ngx-device-detector';
import {RoleEnum} from 'enums/role.enum';
import {Subscription} from 'rxjs';
import {environment} from 'environments/environment';
import {Router} from '@angular/router';
import {RouteService} from 'routing/services/route/route.service';
import {ApiUserDataInterface} from 'interfaces/api/user-data.interface';

@Component({
    templateUrl: 'avatar.component.html',
    selector: 'app-avatar-component',
})
export class AvatarComponent implements OnInit, OnDestroy {

    public static readonly CLASS = 'avatar-component';

    public readonly roleEnum = RoleEnum;

    public readonly routesEnum = RoutesEnum;

    public onedriveEnabled = environment.features.onedriveEnabled;

    public archiveModeActive = environment.archiveModeActive;

    @HostBinding('class')
    protected elementClasses: string;

    @Input()
    public enableProfileDropdown: boolean = false;

    @Input()
    public currentUser: boolean = false;

    @Input()
    public user: ApiUserDataInterface;

    @Input()
    public avatar: string;

    @Input()
    public isClickable: boolean = true;

    @Input()
    public classModifiers: Array<string> = [];

    @ViewChild('profileDropdown', { static: false })
    protected profileDropdown: DropdownComponent;

    public classHelper: ClassHelper = new ClassHelper(AvatarComponent.CLASS);

    public iconBox: IconBoxInterface = {
        type: IconBoxTypeEnum.Image,
        value: 'https://www.gravatar.com/avatar/00000000000000000000000000000000',
        alt: 'Avatar',
        classModifiers: [],
    };

    private userChangeSubscription: Subscription;

    constructor(
        private userService: UserService,
        private deviceService: DeviceDetectorService,
        private authenticationService: AuthenticationService,
        private routeService: RouteService,
        private router: Router
    ) {
    }

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classModifiers);

        if (this.classHelper.hasClass(AvatarClassesEnum.BorderNoRadius)) {
            this.iconBox.classModifiers.push(IconBoxClassEnum.BorderNoRadius, IconBoxClassEnum.NoBorder);
        }

        if (this.profileDropdown) {
            this.profileDropdown.classHelper.addClass(DropdownClassEnum.Profile);
        }

        this.elementClasses = this.classHelper.toString();

        this.setCurrentUser();
        this.loadImage();
    }

    public ngOnDestroy(): void {
        if (this.userChangeSubscription instanceof Subscription) {
            this.userChangeSubscription.unsubscribe();
        }
    }

    private setCurrentUser(): void {
        if (!this.currentUser) {
            return;
        }

        this.user = this.userService.getUserData();
        this.userChangeSubscription = this.userService.onUserDataChange.subscribe(() => {
            this.setCurrentUser();
            this.loadImage();
        });
    }

    public handleClick(isUserAvatarClick: boolean = false): void {
        if (!this.profileDropdown) {
            return;
        }

        if (this.deviceService.isDesktop() && this.currentUser && isUserAvatarClick) {
            this.router.navigate([
                this.routeService.getRouteWithPublisher(RoutesEnum.Profile),
            ]);
        }

        this.profileDropdown.toggle();
    }

    private loadImage(): void {
        let avatar = this.avatar;

        if (this.user) {
            avatar = this.user.avatar;
        }

        if (!avatar) {
            throw new Error('Missing user data, set [currentUser] true or supply either the [user] or [avatar] input property');
        }

        const avatarImage = new Image();

        avatarImage.src = avatar;
        avatarImage.onload = () => {
            this.iconBox.value = avatar;
        };
    }

    @HostListener('mouseover', ['$event.type'])
    @HostListener('mouseout', ['$event.type'])
    protected toggleProfileDropdown(eventType: string): void {
        if (!this.deviceService.isDesktop() || !this.profileDropdown || !this.enableProfileDropdown || this.profileDropdown.isVisible() && eventType === 'mouseover') {
            return;
        }

        this.profileDropdown.toggle();
    }

    public getLogoutUrl(): string {
        return this.authenticationService.getLogoutUrl();
    }

}
