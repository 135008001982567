import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';

export class ActionButtonIconModel implements ActionButtonInterface {

    public iconBox: IconBoxInterface;

    constructor(
        public id: string,
        public icon: IconEnum,
        public classModifiers: Array<string> = [],
        iconClassModifiers: Array<string> = [],
    ) {
        this.iconBox = {
            value: icon,
            type: IconBoxTypeEnum.Icon,
            classModifiers: iconClassModifiers,
        };
    }

}
