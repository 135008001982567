import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';
import {ResultStateEnum} from 'shared/components/result-cell/enums/result-state.enum';
import {ResultCodeEnum} from 'shared/components/result-cell/enums/result-code.enum';
import {ResultClassEnum} from 'shared/components/result-cell/enums/result-class.enum';
import {ResultLetterEnum} from 'shared/components/result-cell/enums/result-letter.enum';
import {ResultSentenceEnum} from 'shared/components/result-cell/enums/result-sentence.enum';
import {ResultIconEnum} from 'shared/components/result-cell/enums/result-icon.enum';
import {isNumber} from 'util';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';

@Component({
    selector: 'app-result-cell',
    templateUrl: 'result-cell.component.html',
})
export class ResultCellComponent implements OnInit, OnChanges {

    public readonly resultClassMap: Map<ResultCodeEnum, ResultClassEnum> = new Map([
        [ResultCodeEnum.ResultRight, ResultClassEnum.ResultRight],
        [ResultCodeEnum.ResultPass, ResultClassEnum.ResultPass],
        [ResultCodeEnum.ResultRightWrongRight, ResultClassEnum.ResultRight],
        [ResultCodeEnum.ResultWrong, ResultClassEnum.ResultWrong],
        [ResultCodeEnum.ResultRightWrongWrong, ResultClassEnum.ResultWrongWrong],
        [ResultCodeEnum.ResultRetry, ResultClassEnum.ResultRetry],
    ]);

    public readonly resultLetterMap: Map<ResultCodeEnum, ResultLetterEnum> = new Map([
        [ResultCodeEnum.ResultRight, ResultLetterEnum.ResultRightLetter],
        [ResultCodeEnum.ResultPass, ResultLetterEnum.ResultPassLetter],
        [ResultCodeEnum.ResultRightWrongRight, ResultLetterEnum.ResultRightLetter],
        [ResultCodeEnum.ResultWrong, ResultLetterEnum.ResultWrongLetter],
        [ResultCodeEnum.ResultRightWrongWrong, ResultLetterEnum.ResultRightWrongWrongLetter],
        [ResultCodeEnum.ResultRetry, ResultLetterEnum.ResultRetryLetter],
    ]);

    public readonly resultSentenceMap: Map<ResultCodeEnum, ResultSentenceEnum> = new Map([
        [ResultCodeEnum.ResultRight, ResultSentenceEnum.ResultRightSentence],
        [ResultCodeEnum.ResultPass, ResultSentenceEnum.ResultPassSentence],
        [ResultCodeEnum.ResultRightWrongRight, ResultSentenceEnum.ResultRightSentence],
        [ResultCodeEnum.ResultWrong, ResultSentenceEnum.ResultWrongSentence],
        [ResultCodeEnum.ResultRightWrongWrong, ResultSentenceEnum.ResultWrongWrongSentence],
        [ResultCodeEnum.ResultRetry, ResultSentenceEnum.ResultRetrySentence],
    ]);

    public readonly examinationNeededEnum = ExamineOptionsEnum;

    @Input()
    public examinationNeeded: number;

    @Input()
    public modifiedByTeacher: number;

    @Input()
    public state: Array<ResultStateEnum> = [];

    @Input()
    public result: number;

    @Input()
    public resultNumeric: number;

    @Input()
    public isAutoCheck: boolean;

    @Input()
    public extended: boolean = false;

    @Input()
    public isTaskDetail: boolean = false;

    @Input()
    public classModifiers: Array<string> = [];

    public isResit: number = ResultCodeEnum.ResultRetry;

    public letter: string;

    public classHelper: ClassHelper = new ClassHelper();

    public isIconResult: boolean;

    public iconClass: string;

    public ngOnInit(): void {
        this.loadClasses();
        this.loadLetter();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.classModifiers) {
            this.classHelper.clear('classModifiers');
            this.classHelper.addClasses(this.classModifiers, 'classModifiers');
        }

        if (changes.state || changes.result || changes.extended) {
            this.classHelper.clear('state');

            this.loadClasses();
            this.loadLetter();
        }
    }

    private loadClasses(): void {
        this.state.forEach((state: ResultStateEnum) => {
            const classModifier: string = this.getClassModifierByState(state);
            if (classModifier) {
                this.classHelper.addClass(classModifier, 'state');
            }
        });
    }

    private getClassModifierByState(state: ResultStateEnum): string {
        if (isNumber(this.resultNumeric) && this.result !== ResultCodeEnum.ResultRetry) {
            return ResultClassEnum.ResultNumeric;
        }

        switch (state) {
            case ResultStateEnum.Graded:
                return this.getClassForResult();

            case ResultStateEnum.HandedIn:
                return ResultClassEnum.ResultHandedIn;

            case ResultStateEnum.Resit:
                return ResultClassEnum.ResultResit;

            case ResultStateEnum.Created:
                return ResultClassEnum.ResultCreated;

            case ResultStateEnum.Doing:
                return ResultClassEnum.ResultDoing;

            case ResultStateEnum.TooLate:
                return ResultClassEnum.ResultTooLate;

            case ResultStateEnum.DeadLine:
                return ResultClassEnum.ResultDeadLine;

            case ResultStateEnum.IsNumeric:
                return ResultClassEnum.ResultNumeric;

            case ResultStateEnum.Other:
                return ResultClassEnum.Other;
        }

        return null;
    }

    private getClassForResult(): string | null {
        if (!this.result) {
            return null;
        }

        return this.resultClassMap.get(this.result);
    }

    private loadLetter(): void {
        if (!this.result) {
            this.letter = null;
        } else if (this.extended === true) {
            this.letter = this.resultSentenceMap.get(this.result);

            if (this.isAutoCheck) {
                this.iconClass = ResultIconEnum[this.resultLetterMap.get(this.result)];
            }
        } else {
            // Check for icon
            if (this.result === ResultCodeEnum.ResultRightWrongRight || this.result === ResultCodeEnum.ResultRightWrongWrong) {
                this.isIconResult = true;
                this.iconClass = ResultIconEnum[this.resultLetterMap.get(this.result)];
            }
            this.letter = this.resultLetterMap.get(this.result);
        }
    }
}
