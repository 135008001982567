import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {GridRowClass} from 'shared/classes/grid/grid-row.class';

@Component({
    selector: 'app-grid-table-row',
    templateUrl: 'grid-table-row.component.html',
})
export class GridTableRowComponent extends GridRowClass implements OnChanges, OnInit {

    public static readonly CLASS: string = GridTableRowClassEnum.Base;

    public ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    protected getBaseClass(): string {
        return GridTableRowComponent.CLASS;
    }
}
