import {EventEmitter, Injectable, NgZone, Output} from '@angular/core';
import {environment} from 'environments/environment';
import 'isomorphic-fetch';
import {Client} from '@microsoft/microsoft-graph-client';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {MsAlLoginService} from 'onedrive/services/msallogin.service';
import {UserService} from 'security/services/user/user.service';
import {OneDrivePreferencesEnum} from 'pages/modules/user/components/one-drive-preferences/enum/one-drive-preferences.enum';
import {ToastrService} from 'ngx-toastr';
import {ApiUserDataInterface} from 'interfaces/api/user-data.interface';

declare const OneDrive: any;

@Injectable()
export class OneDriveService {

    @Output()
    public filesSelected: EventEmitter<any> = new EventEmitter();
    public filesUploaded: EventEmitter<Array<File>> = new EventEmitter();
    public linksUploaded: EventEmitter<Array<string>> = new EventEmitter();
    public uploadCanceled: EventEmitter<void> = new EventEmitter();
    public uploadError: EventEmitter<void> = new EventEmitter();

    private client: Client;

    private mode: string = 'files';

    public constructor(
        private http: HttpClient,
        private mssallogin: MsAlLoginService,
        private userService: UserService,
        private ngZone: NgZone,
        private toastrService: ToastrService
    ) {

    }

    private async onSuccess(files: any): Promise<void> {
        this.ngZone.run(
            () => {
                this.filesSelected.emit(files);
            }
        );
    }

    private onCancel(cancel: any): void {
        this.ngZone.run(
            () => {
                this.uploadCanceled.emit();
            }
        );
    }

    private onError(error: any): void {
        this.ngZone.run(
            () => {
                this.uploadError.emit();
            }
        );
    }

    public launchOneDrivePicker(mode: string): void {
        this.mode = mode;

        this.mssallogin.authenticate(false, false).then(
            (token) => {
                const pickerOptions = {
                    clientId: environment.microsoftAppId,
                    action: this.mode === 'files' ? 'download' : 'query',
                    multiSelect: false, // or true
                    openInNewWindow: true, // or false
                    sourceInputElementId: 'attachment-file',
                    advanced: {
                        loginHint: token.account.userName,
                        //    endpointHint: this.userService.getUserData().one_drive_root,
                        accessToken: token.accessToken,
                        isConsumerAccount: token.account.idToken.tid === '',
                        redirectUri: environment.microsoftRedirectUrl + '/onedrive/filepicker',
                        createLinkParameters: {type: 'view', scope: 'anonymous'}
                    },
                    success: (files) => this.onSuccess(files),
                    cancel: (cancel) => this.onCancel(cancel),
                    error: (error) => this.onError(error)
                };

                OneDrive.open(pickerOptions);
            }
        );
    }


    public shareFile(itemId: string, share: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.mssallogin.authenticate(false, false).then(
                (token) => {
                    const graphUrl = `https://graph.microsoft.com/v1.0/me/drive/items/${itemId}/createLink`;
                    this.http.post(graphUrl, {type: share, scope: 'anonymous'}).subscribe(
                        (response: any) => {
                            resolve(response.link.webUrl);
                        },
                        (error) => {
                            reject(error);
                        });

                });
        });
    }


    public saveFile(folder: string, url: string, fileName: string, localApp: boolean, root: string): Promise<string> {
        return new Promise((resolve, reject) => {
            this.mssallogin.authenticate(false, false).then(
                (token) => {
                    const graphUrl = `https://graph.microsoft.com/v1.0/me/drive/special/approot:/${folder}/${fileName}`;
                    this.http.get(graphUrl).subscribe(
                        (response: any) => {
                            resolve(this.getUrl(response, localApp, root));
                        },
                        (error) => {

                            this.getDataFromUrl(url, (data: any) => {
                                if ((data.size / 1024 / 1024) > 4) {
                                    this.toastrService.warning('Documenten groter dan 4Mb kunnen niet direct in Office 365 getoond worden');
                                    reject();
                                } else {
                                    this.http.put(graphUrl + ':/content?@microsoft.graph.conflictBehavior=fail', data, {headers: new HttpHeaders({'content-type': 'text/plain'})}).subscribe(
                                        (response: any) => {
                                            resolve(this.getUrl(response, localApp, root));
                                        },
                                        () => {
                                            reject();
                                        }
                                    );
                                }
                            });
                        });
                });
        });
    }


    public getUrl(response: any, localApp: boolean, root: string): string {
        if (!localApp) {
            return response.webUrl;
        }

        const accessUrl = response.parentReference.path.replace('/drive/root:', root) + '/' + response.name;
        const mimeType = response.file.mimeType;

        if (mimeType.indexOf('application/msword') > -1 || mimeType.indexOf('application/vnd.openxmlformats-officedocument.wordprocessingml.document') > -1) {
            return 'ms-word:ofe|u|' + accessUrl;
        }
        if (mimeType.indexOf('application/vnd.ms-powerpoint') > -1 || mimeType.indexOf('application/vnd.openxmlformats-officedocument.presentationml.presentation') > -1) {
            return 'ms-powerpoint:ofe|u|' + accessUrl;
        }
        if (mimeType.indexOf('application/vnd.ms-excel') > -1 || mimeType.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') > -1) {
            return 'ms-excel:ofe|u|' + accessUrl;
        }

        return accessUrl;
    }


    public getDataFromUrl(url: string, callback: any): void {
        const xhr = new XMLHttpRequest();
        xhr.onload = function (): any {
            callback(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    public openDocument(bookTitle: string, fileName: string, href: string, target: string): void {
        const userData: ApiUserDataInterface = this.userService.getUserData();

        if (userData.use_one_drive && this.mssallogin.getLoggedInUser() === userData.one_drive_username) {
            this.saveFile(
                bookTitle,
                href,
                fileName,
                this.userService.getUserData().one_drive_open_preference === OneDrivePreferencesEnum.OfficeLocal,
                this.userService.getUserData().one_drive_root
            ).then(url => this.openUrl(url.indexOf('pdf-viewer') > -1 ? href : url, target), () => this.openUrl(href, target));
        } else {
            this.openUrl(href, target);
        }
    }

    private openUrl(url: string, target: string): void {
        const a = document.createElement('a');
        a.target = target;
        a.href = url;
        a.click();
    }
}
