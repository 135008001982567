import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {DatePickerFormatter} from 'shared/date-picker/date-picker-formatter';

@Pipe({
    name: 'momentFormat'
})
export class MomentFormatPipe implements PipeTransform {

    public transform(value: string | number | moment.Moment, format: string = DatePickerFormatter.DATE_FORMAT): any {
        const momentDate: moment.Moment = (typeof value === 'number') ? moment.unix(value) : moment(value);

        if (!value || !momentDate.isValid()) {
            return '-';
        }

        return momentDate.format(format);
    }

}
