export enum TeacherMaterialTabTitlesEnum {

    All = 'Alle',

    InternetLink = 'Internetlink',

    Document = 'Document',

    Ebook = 'Ebook',

}
