import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {PublisherService} from 'services/publisher/publisher.service';

@Injectable()
export class PublisherHttpInterceptor implements HttpInterceptor {

    constructor(
        private publisherService: PublisherService,
    ) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const setHeaders = {};

        const publisher = this.publisherService.getActivePublisher();

        if (publisher) {
            setHeaders['X-Publisher'] = publisher.slug;
        }

        return next.handle(req.clone({
            setHeaders: setHeaders
        }));
    }
}
