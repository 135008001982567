import {NgModule} from '@angular/core';
import {DomParentsHelper} from 'helpers/dom/parents.helper';

@NgModule({
    providers: [
        DomParentsHelper,
    ],
})
export class HelpersModule {
}
