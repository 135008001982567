import {NgModule} from '@angular/core';
import {SharedModule} from 'shared/shared.module';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {UserProfilePageComponent} from 'pages/modules/user/components/profile-page/profile-page.component';
import {UserUserPageComponent} from 'pages/modules/user/components/user-page/user-page.component';
import {UserDetailsComponent} from 'pages/modules/user/components/user-details/user-details.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {SettingsComponent} from 'pages/modules/user/components/settings/settings.component';
import {OneDriveComponent} from 'pages/modules/user/components/one-drive/one-drive.component';
import {OneDrivePreferencesComponent} from 'pages/modules/user/components/one-drive-preferences/one-drive-preferences.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        NgbTooltipModule,
    ],
    declarations: [
        UserProfilePageComponent,
        UserUserPageComponent,
        UserDetailsComponent,
        SettingsComponent,
        OneDriveComponent,
        OneDrivePreferencesComponent,
    ],
    exports: [
        UserProfilePageComponent,
        UserUserPageComponent,
    ],
})
export class UserModule {
}
