import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-time-picker',
    templateUrl: 'time-picker.component.html',
})
export class TimePickerComponent {

    @Input()
    public model: NgbTimeStruct;

    @Output()
    public modelChange: EventEmitter<NgbTimeStruct> = new EventEmitter();

    public hours: Array<number> = [];

    public minutes: Array<number> = [];

    constructor() {
        for (let i = 0; i < 24; i++) {
            this.hours.push(i);
        }

        for (let i = 0; i < 60; i += 5) {
            this.minutes.push(i);
        }
    }

    public onModelChange(): void {
        this.modelChange.emit(this.model);
    }

    public paddedTime(time: number): string {
        return String(time).padStart(2, '0');
    }
}
