import {Injectable} from '@angular/core';
import {UserService} from 'security/services/user/user.service';

@Injectable()
export class SchoolService {
    constructor(private userService: UserService) {
    }

    public getSchoolYears(): Array<ApiSchoolYearInterface> {
        const schoolYears: Array<ApiSchoolYearInterface> = this.userService.getUserData().school_years;

        return schoolYears.filter(schoolYear => schoolYear.active === 1);
    }

    public getSelectedSchoolYear(): ApiSchoolYearInterface {
        return this.getSchoolYears().find(schoolYear => schoolYear.default === 1);
    }
}
