export enum AnalyticsEventActionEnum {

    AuthenticatedUserLoad = 'load user profile',

    AuthenticatedAccessDenied = 'access_denied',

    StudyMaterialOpen = 'open',

    AssignmentHandIn = 'handin',

    AssignmentGrade = 'grade',

    TaskHandIn = 'handin',

    TaskNew = 'new',

    TaskNewSaved = 'new_saved',

    TaskResit = 'resit',

    TaskResitSaved = 'resit_saved',

    TaskEdit = 'edit',

    TaskEditSaved = 'edit_saved',

    TaskView = 'view',

    BookTableOfContents = 'load_table_of_contents',

    PublisherUsed = 'used',

    SummaryNew = 'new',

    SummaryEdit = 'edit',

    MessageShow = 'show',

    NotificationClick = 'click',

    NoteNew = 'new',

    DeeplinkEan = 'ean',

    DeeplinkDpsid = 'dpsid',

    DeeplinkBookId = 'book_id',

}
