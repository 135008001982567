export enum SystemTypeEnum {

    MaintenanceOn = 503,

    MaintenanceOff = 200,

    PageRefresh = 100,

    ReloadGroups = 101,

    ReloadBooks = 102,

    ReloadLogin = 103,

}
