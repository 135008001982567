import {Injectable} from '@angular/core';
import {AddTaskFormInterface} from 'pages/modules/tasks/components/modals/add-modal/interfaces/add-task-form.interface';
import {TaskStatusEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/task-status.enum';
import {TaskTypeEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/task-type.enum';
import {AddTaskFormTaskGroupInterface} from 'pages/modules/tasks/components/modals/add-modal/interfaces/add-task-form-task-group.interface';
import {TaskModalModeEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/modal-mode.enum';
import {ToastrService} from 'ngx-toastr';
import {ContentSwitchGroupModel} from 'pages/modules/tasks/components/modals/add-modal/models/group-model';
import {AddTaskTaskGroupInterface} from 'pages/modules/tasks/components/modals/add-modal/interfaces/add-task-task-group.interface';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';

@Injectable()
export class TasksApiTaskBuilderFactory {

    constructor(private toastService: ToastrService) {
    }

    public forModalType(
        modalMode: TaskModalModeEnum,
        ownTaskMode: boolean,
        bookId: number | null,
        startDateTime: number,
        dueDateTime: number,
        examineOption: ExamineOptionsEnum,
        workType: TaskTypeEnum,
        groups: Array<ContentSwitchGroupModel> | null,
        taskGroups: Array<AddTaskTaskGroupInterface> | null,
        attachments: AttachmentDataInterface,
        selectedTasks: Array<ApiAssignmentInterface>,
        title: string,
    ): AddTaskFormInterface | null {
        const params: AddTaskFormInterface = {
            book: null,
            description: null,
            status: TaskStatusEnum.Open, // TODO: not from frontend
            resit: modalMode === TaskModalModeEnum.Resit ? 1 : 0,
            type: 2, // TODO: remove from API
            users: [], // TODO: remove from API
            groups: [],
            user_groups: [],
            taskgroups: [],
            start_date: startDateTime,
            duedate: dueDateTime,
            urls: [],
            files: [],
            self_study_compatible: 1, // TODO: remove from API
        };

        if (examineOption !== ExamineOptionsEnum.Differs) {
            params['examination_needed'] = examineOption;
        }

        this.addAttachments(attachments, params);

        if (!this.addBook(ownTaskMode, bookId, params)) {
            this.toastService.warning('Taken konden niet worden opgegeven. Selecteer een boek.');

            return null;
        }

        if (!this.addAssignments(ownTaskMode, selectedTasks, params)) {
            this.toastService.warning('Taken konden niet worden opgegeven. Selecteer 1 of meerdere opdrachten.');

            return null;
        }

        if (!this.addTitle(ownTaskMode, title, params)) {
            this.toastService.warning('Taken konden niet worden opgegeven. Geef een titel op.');

            return null;
        }

        if (workType === TaskTypeEnum.Personal && !this.addGroups(groups, params)) {
            this.toastService.warning('Taken konden niet worden opgegeven. Selecteer studenten en/of groepen.');

            return null;
        }

        if (workType === TaskTypeEnum.Group && !this.addTaskGroups(taskGroups, params)) {
            this.toastService.warning('Taken konden niet worden opgegeven. Maak de taak groepen aan.');

            return null;
        }

        return params;
    }

    // Add all fields

    private addBook(ownTaskMode: boolean, bookId: number | null, params: AddTaskFormInterface): boolean {
        if (ownTaskMode && bookId === null) {
            return false;
        }

        params.book = bookId;

        return true;
    }

    private addGroups(groups: Array<ContentSwitchGroupModel>, params: AddTaskFormInterface): boolean {
        for (const group of groups) {
            if (group.active === true) {
                params.groups.push(Number(group.id));
                continue;
            }

            for (const student of group.children) {
                if (student.active) {
                    params.user_groups.push({
                        group_id: Number(group.id),
                        user_id: Number(student.id)
                    });
                }
            }
        }

        return true;
    }

    private addTaskGroups(taskGroups: Array<AddTaskTaskGroupInterface>, params: AddTaskFormInterface): boolean {
        for (const taskGroup of taskGroups) {
            const apiTaskGroup: AddTaskFormTaskGroupInterface = {
                name: taskGroup.title,
                user_groups: [],
            };

            for (const group of taskGroup.groups) {
                for (const student of group.children) {
                    if (group.active || student.active) {
                        apiTaskGroup.user_groups.push({
                            group_id: Number(group.id),
                            user_id: Number(student.id),
                        });
                    }
                }
            }

            params.taskgroups.push(apiTaskGroup);
        }

        return true;
    }

    private addAttachments(attachments: AttachmentDataInterface, params: AddTaskFormInterface): void {
        params.description = attachments.description;

        for (const url of attachments.urls) {
            params.urls.push(url);
        }

        for (const file of attachments.files) {
            params.files.push({
                id: file.id
            });
        }
    }

    private addAssignments(ownTaskMode: boolean, selectedTasks: Array<ApiAssignmentInterface>, params: AddTaskFormInterface): boolean {
        if (ownTaskMode === true) {
            return true;
        }

        if (selectedTasks.length === 0) {
            return false;
        }

        params.assignments = [];

        selectedTasks.forEach((assignment: ApiAssignmentInterface) => {
            params.assignments.push({
                id: Number(assignment.id),
                title: assignment.title,
                examination_needed: assignment.examination_needed,
            });
        });

        return true;
    }

    private addTitle(ownTaskMode: boolean, title: string, params: AddTaskFormInterface): boolean {
        if (ownTaskMode !== true) {
            return true;
        }

        if (!title || title.trim().length === 0) {
            return false;
        }

        params.title = title.trim();

        return true;
    }
}
