import {Injectable} from '@angular/core';
import {TaskAttachmentInterface} from 'pages/modules/tasks/interfaces/attachment.interface';
import {TasksAttachmentFileModel} from 'pages/modules/tasks/models/attachment-file.model';
import {TasksAttachmentUrlModel} from 'pages/modules/tasks/models/attachment-url.model';

@Injectable()
export class TasksAttachmentFactory {

    /**
     * TODO: Add path to files
     */
    public fromApiFile(file: ApiFileInterface): TaskAttachmentInterface {
        return new TasksAttachmentFileModel(file.name, file.id);
    }

    public fromApiFiles(files: Array<ApiFileInterface>): Array<TaskAttachmentInterface> {
        const attachments: Array<TaskAttachmentInterface> = [];

        files.forEach((file: any) => {
            attachments.push(this.fromApiFile(file));
        });

        return attachments;
    }

    /**
     * TODO: Get url values
     */
    public fromApiUrl(url: ApiUrlInterface): TaskAttachmentInterface {
        return new TasksAttachmentUrlModel(url.url);
    }

    public fromApiUrls(urls: Array<ApiUrlInterface>): Array<TaskAttachmentInterface> {
        const attachments: Array<TaskAttachmentInterface> = [];

        urls.forEach((url: any) => {
            attachments.push(this.fromApiUrl(url));
        });

        return attachments;
    }

    public fromApiHomework(homework: ApiTaskDetailsHomeworkInterface|ApiHomeworkDetailsInterface): Array<TaskAttachmentInterface> {
        return [
            ...this.fromApiFiles(homework.files),
            ...this.fromApiUrls(homework.urls),
        ];
    }

}
