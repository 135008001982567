import {Component, EventEmitter, Output, Input} from '@angular/core';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {TaskService} from 'services/task/task.service';
import {finalize} from 'rxjs/operators';
import {IconBoxClose} from 'models/iconbox/close.model';
import {AlertMessageInterface} from 'shared/components/alert-message/interfaces/alertMessageInterface';
import {TaskDeleteAlertMessage} from 'shared/components/alert-message/models/task-delete.model';

@Component({
    selector: 'app-tasks-delete-modal-component',
    templateUrl: 'delete-modal.component.html',
})
export class TasksDeleteModalComponent {

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorTask]);

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    @Input()
    public taskId: number;

    @Input()
    public assignedByCurrentTeacher: boolean;

    @Output()
    public taskDeletedEvent: EventEmitter<null> = new EventEmitter();

    public isLoading: boolean = false;

    public alertMessage: AlertMessageInterface = new TaskDeleteAlertMessage();

    constructor(
        private activeModal: NgbActiveModal,
        private taskService: TaskService,
        private toastService: ToastrService,
    ) {
    }

    public close(): void {
        this.activeModal.dismiss();
    }

    public deleteTask(): void {
        this.isLoading = true;

        this.taskService.deleteTask(this.taskId)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe(() => {
                this.toastService.success('Taak is verwijderd.');

                this.close();
                this.taskDeletedEvent.emit();
            }, (err: any) => {
                console.error(err);

                this.toastService.error('Kon taak niet verwijderen.');
            });
    }
}
