import {
    AfterViewInit,
    Component,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {IconEnum} from 'enums/icon.enum';
import {GridTableScrollControlComponent} from 'shared/modules/grid-table/components/scroll-control/scroll-control.component';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {ActiveTaskEventInterface, TaskStateService} from 'pages/modules/tasks/service/task-state.service';
import {Subscription} from 'rxjs';
import {ProgressGroupFactory} from 'pages/modules/progress/factories/group.factory';
import {ProgressGroupInterface} from 'pages/modules/progress/interfaces/group.interface';
import {ProgressStudyMaterialInterface} from 'pages/modules/progress/interfaces/study-material.interface';
import {ProgressStudyMaterialFactory} from 'pages/modules/progress/factories/study-material.factory';
import {
    NavigateToTaskEvent,
    StudentResultsComponent
} from 'pages/modules/progress/components/student-details/student-results/student-results.component';
import {RoleEnum} from 'enums/role.enum';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {ProgressStatusFilterEnum} from 'shared/components/status-filter/enum/progress-status-filter.enum';
import {ModalService} from 'core/services/modal/modal.service';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {RouteService} from 'routing/services/route/route.service';
import {Router} from '@angular/router';
import {TaskService} from 'services/task/task.service';

@Component({
    selector: 'app-tasks-task-selector',
    templateUrl: 'task-selector.component.html',
})
export class TaskSelectorComponent implements AfterViewInit, OnChanges, OnDestroy {
    private subscriptions: Subscription[] = [];

    @HostBinding('class')
    private classes: string[] = ['tasks-task-selector'];

    @ViewChildren(GridTableScrollControlComponent)
    public readonly gridTableScrollControlComponents: QueryList<GridTableScrollControlComponent>;

    @ViewChild(StudentResultsComponent, {static: true})
    public readonly studentResultsComponent: StudentResultsComponent;

    @Input()
    public progress: ApiProgressInterface[] = [];

    public readonly gridTableRowClassEnum = GridTableRowClassEnum;

    public readonly gridTableColClassEnum = GridTableColClassEnum;

    public readonly gridTableClassEnum = GridTableClassEnum;

    public readonly iconEnum = IconEnum;

    public progressGroup: ProgressGroupInterface;

    public progressStudyMaterial: ProgressStudyMaterialInterface;

    public bookId: number;

    public activeTask: ActiveTaskEventInterface;

    constructor(
        private taskStateService: TaskStateService,
        private progressGroupFactory: ProgressGroupFactory,
        private studyMaterialFactory: ProgressStudyMaterialFactory,
        private authorizationService: AuthorizationService,
        private modalService: ModalService,
        private router: Router,
        private routeService: RouteService,
        private taskService: TaskService,
    ) {
        this.subscriptions.push(...[
            this.taskStateService.subscribeToActiveTask(task => this.onActiveTaskEvent(task)),
            this.taskStateService.subscribeToProgressStatusFilter(progressStatusFilter => this.onProgressStatusFilter(progressStatusFilter)),
        ]);
    }

    public ngAfterViewInit(): void {
        this.studentResultsComponent.addScrollControls(this.gridTableScrollControlComponents);
    }

    public ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges.progress && 0 !== simpleChanges.progress.currentValue.length) {
            this.loadProgress(simpleChanges.progress.currentValue[0]);
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public onNavigateToTaskEvent(navigateToTaskEvent: NavigateToTaskEvent): Promise<void | boolean> {
        if (null !== navigateToTaskEvent.assignmentStatus.assignmentTaskId) {
            this.taskService.navigateToDetails(navigateToTaskEvent.assignmentStatus.assignmentTaskId);

            return Promise.resolve();
        }

        if (this.authorizationService.isGranted(RoleEnum.RoleTeacher)) {
            return this.modalService.taskNotExist().then(() => this.navigateToTask(String(navigateToTaskEvent.assignmentStatus.dpsid))).catch(error => error);
        }
    }

    public navigateToTask(dpsId: string): void {
        this.router.navigateByUrl(this.routeService.getRouteWithPublisher(RoutesEnum.Document, new Map([
            ['bookId', String(this.bookId)],
            ['documentDpsid', dpsId]
        ])));
    }

    protected onActiveTaskEvent(activeTaskEvent: ActiveTaskEventInterface | undefined): void {
        if (undefined === activeTaskEvent) {
            return;
        }

        this.bookId = activeTaskEvent.task.book;
    }

    protected onProgressStatusFilter(progressStatusFilter: ProgressStatusFilterEnum): void {
        if (undefined === this.studentResultsComponent) {
            return;
        }

        this.studentResultsComponent.filter(progressStatusFilter);
    }

    protected loadProgress(apiProgress: ApiProgressInterface): void {
        const isTeacher: boolean = this.authorizationService.isGranted(RoleEnum.RoleTeacher);
        const group = this.progressGroup = isTeacher ? this.progressGroupFactory.fromApiProgress(apiProgress)[0] : undefined;
        const studyMaterial = this.progressStudyMaterial = this.isProgressForStudent(apiProgress) ? this.studyMaterialFactory.fromApiProgressForStudent(apiProgress) : undefined;
        const average = isTeacher ? this.progressGroup.average : studyMaterial.average;

        this.subscriptions.push(this.taskStateService.subscribeToActiveTask(task => this.activeTask = task));

        this.studentResultsComponent.update({
            studyMaterial,
            group,
            bookId: this.bookId,
            average,
            filter: this.taskStateService.getProgressStatusFilter()
        }, this.activeTask);
    }

    protected isProgressForStudent(progress: ApiProgressInterface | ApiProgressForStudentInterface): progress is ApiProgressForStudentInterface {
        return progress.hasOwnProperty('studymaterials');
    }
}
