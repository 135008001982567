import {ProgressAverageInterface} from 'pages/modules/progress/interfaces/average.interface';
import {ProgressAssignmentStatusModel} from 'pages/modules/progress/models/assignmen-status.model';
import {ProgressExamScoreModel} from 'pages/modules/progress/models/exam-score.model';

export class ProgressAverageModel implements ProgressAverageInterface {

    constructor(
        public statuses: Array<ProgressAssignmentStatusModel>,
        public exams: ProgressExamScoreModel,
    ) {
    }
}
