import {EventEmitter, Input} from '@angular/core';
import {ExamMetadataInterface, ExamMetadataService} from 'services/exam/exam-metadata.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ExamTypeEnum} from 'enums/exam-type.enum';

export interface ExamStateInterface {
    attempt?: number;
    isAtFinalAttempt?: boolean;
    attemptHasResult?: boolean;
    attemptEnabled?: boolean;
    assignment?: ApiExamAssignmentInterface;
    isAtFinalAssignment: boolean;
    hasReachedMaxAttempts: boolean;
    hasResults: boolean;
    showResults: boolean;
    inProgress: boolean;
    hasMetadata: boolean;
    viewUserMode: boolean;
    isPractiseExam: boolean;
}

@Input()
export class ExamStateService {
    private examState: ExamStateInterface = {
        isAtFinalAssignment: false,
        hasReachedMaxAttempts: false,
        hasResults: false,
        showResults: false,
        inProgress: false,
        hasMetadata: false,
        viewUserMode: false,
        isPractiseExam: true,
        isAtFinalAttempt: false,
        attemptHasResult: false,
        attemptEnabled: false,
    };

    private examStateSubject: BehaviorSubject<ExamStateInterface> = new BehaviorSubject(this.examState);

    private attemptChangeEventEmitter: EventEmitter<number> = new EventEmitter();

    constructor(private examMetadataService: ExamMetadataService) {
        examMetadataService.subscribe(metadata => this.handleMetadata(metadata));
    }

    public subscribe(fn: (examState: ExamStateInterface) => void): Subscription {
        return this.examStateSubject.subscribe(fn);
    }

    public startNextAttempt(): void {
        this.examState.attempt = this.examState.attempt + 1;
        this.examState.hasResults = false;
        this.examState.showResults = false;

        this.examStateSubject.next(this.examState);
    }

    public setAttempt(attempt: number, isAtFinalAttempt?: boolean, attemptHasResult?: boolean, attemptEnabled?: boolean): void {
        this.examState.attempt = attempt;
        this.examState.isAtFinalAttempt = isAtFinalAttempt;
        this.examState.attemptHasResult = attemptHasResult;
        this.examState.attemptEnabled = attemptEnabled;
        this.examState.assignment = undefined;
        this.examState.showResults = true;

        this.examStateSubject.next(this.examState);
    }

    public getAttempt(): number {
        return this.examState.attempt;
    }

    public emitAttemptChange(attempt: number): void {
        this.attemptChangeEventEmitter.emit(attempt);
    }

    public attemptChangeListener(): EventEmitter<number> {
        return this.attemptChangeEventEmitter;
    }

    public setAssignment(assignment: ApiExamAssignmentInterface | undefined): void {
        this.examState.assignment = assignment;
        this.examState.showResults = undefined === assignment && !this.examState.inProgress;
        this.examState.isAtFinalAssignment = this.examMetadataService.isLastAssignment(assignment);

        this.examStateSubject.next(this.examState);
    }

    public setInProgress(value: boolean): void {
        this.examState.inProgress = value;
        this.examState.assignment = this.examMetadataService.getAssignmentAt(0);

        this.examStateSubject.next(this.examState);
    }

    private handleMetadata(examMetadata: ExamMetadataInterface): void {
        if (undefined === examMetadata.apiExamMetadata) {
            return;
        }

        const hasReachedMaxAttempts = examMetadata.hasReachedMaxAttempts;
        const apiMetadata: ApiExamMetadataInterface = examMetadata.apiExamMetadata;
        const inProgress = apiMetadata.inProgress;
        const attempt: number = hasReachedMaxAttempts ? examMetadata.maxAttempts : examMetadata.results.length + (inProgress ? 1 : 0);
        const attemptHasResult = this.examMetadataService.hasResult(attempt);
        const viewUserMode: boolean = undefined !== apiMetadata.user;

        this.examState.attempt = attempt || 1; // In case of no previous attempts
        this.examState.hasMetadata = true;
        this.examState.inProgress = inProgress;
        this.examState.hasReachedMaxAttempts = hasReachedMaxAttempts;
        this.examState.hasResults = attemptHasResult;
        this.examState.showResults = attemptHasResult && !inProgress || viewUserMode;
        this.examState.assignment = inProgress ? apiMetadata.assignments[0] : undefined;
        this.examState.viewUserMode = viewUserMode;
        this.examState.isPractiseExam = ExamTypeEnum.PracticeExam === apiMetadata.type;
        this.examState.isAtFinalAssignment = 1 === examMetadata.apiExamMetadata.assignments.length;

        this.examStateSubject.next(this.examState);
    }
}
