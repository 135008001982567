import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {SearchInterface} from 'shared/modules/search/interfaces/search.interface';
import {Observable} from 'rxjs';
import {BookshelfTeachingMaterialsModel} from 'app/modules/pages/modules/bookshelf/models/teaching-materials.model';
import {BookshelfTeacherMaterialsModel} from 'app/modules/pages/modules/bookshelf/models/teacher-materials.model';
import {BookService} from 'services/book/book.service';
import {Router} from '@angular/router';
import {BookshelfItemInterface} from 'app/modules/pages/modules/bookshelf/interfaces/item.interface';
import {BookshelfSearchModel} from 'app/modules/pages/modules/bookshelf/models/bookshelf.search';
import {UserService} from 'security/services/user/user.service';
import {AuthenticationService} from 'security/services/authentication/authentication.service';
import {BookshelfItemModelFactory} from 'app/modules/pages/modules/bookshelf/factories/item-model.factory';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {BookshelfActivateLicenseModel} from 'app/modules/pages/modules/bookshelf/models/activate-license.model';
import {InfiniteScroll} from 'shared/classes/infinite-scroll.abstract';
import {BookshelfItemModel} from 'app/modules/pages/modules/bookshelf/models/item.model';
import {StringService} from 'services/string/string.service';
import {environment} from 'environments/environment';
import {ModalService} from 'core/services/modal/modal.service';
import {BookshelfItemTypeEnum} from 'pages/modules/bookshelf/components/item/enums/item-type.enum';
import {RoleEnum} from 'enums/role.enum';
import {RouteService} from 'routing/services/route/route.service';

@Component({
    selector: 'app-bookshelf-component',
    templateUrl: 'bookshelf.component.html',
})
export class BookshelfComponent extends InfiniteScroll<ApiBooksInterface> implements OnInit {

    public itemTotal: number = 0;

    public favouritesTotal: number = 0;

    public search: SearchInterface;

    public items: Array<BookshelfItemInterface> = [];

    public favourites: Array<BookshelfItemInterface> = [];

    public staticItems: Array<BookshelfItemInterface> = [];

    private searchValue: string = '';

    private itemCount: number = 0;

    private firstTime: boolean = true;

    constructor(
        private bookService: BookService,
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private authorizationService: AuthorizationService,
        private bookShelfItemModelFactory: BookshelfItemModelFactory,
        private stringService: StringService,
        private routeService: RouteService,
        private router: Router,
        private modalService: ModalService,
        protected change: ChangeDetectorRef,
    ) {
        super(change);

        this.search = new BookshelfSearchModel('Zoeken');
    }

    public ngOnInit(): void {
        this.authenticationService.isAuthenticated.subscribe(() => {
            this.loadStaticItems();
        });

        super.ngOnInit();
    }

    public handleItemClick(item: BookshelfItemInterface): void {
        switch (item.type) {
            case BookshelfItemTypeEnum.TeachingMaterials:
                this.handleTeachingMaterials();
                break;

            case BookshelfItemTypeEnum.ActivateLicense:
                this.handleActivateLicense();
                break;
        }
    }

    private handleTeachingMaterials(): void {
        this.modalService.teachingMaterials();
    }

    private handleActivateLicense(): void {
        this.modalService.activateLicense();
    }

    public doSearch(searchValue: string): void {
        this.searchValue = searchValue;
        this.loadData(true);
    }

    public toggleFavourite(item: ApiBookInterface): void {
        this.bookService.toggleFavorite(item.id.toString()).subscribe(() => this.loadData(true));
    }

    private loadStaticItems(): void {
        this.staticItems = [];

        if (this.authorizationService.isGranted(RoleEnum.RoleTeacher)) {
            this.staticItems.push(new BookshelfTeachingMaterialsModel());

            if (environment.features.teacherMaterialsEnabled === false) {
                this.staticItems.push(new BookshelfTeacherMaterialsModel());
            }
        }

        if (this.authorizationService.isGranted(RoleEnum.RoleStudent)) {
            this.staticItems.push(new BookshelfActivateLicenseModel());
        }
    }

    protected reset(): void {
        this.items = [];
        this.favourites = [];
        this.firstTime = true;

        super.reset();
    }

    protected getDataLength(): number {
        return this.itemTotal;
    }

    protected getLoadDataObservable(): Observable<ApiBooksInterface> {
        const limit: number = this.firstTime && this.favouritesTotal > this.limit ? this.favouritesTotal : this.limit;

        return this.bookService.retrieveBooks(
            this.getRequestOffset().toString(),
            limit.toString(),
            this.searchValue,
        );
    }

    protected subscribe(bookshelfItems: ApiBooksInterface): void {
        this.itemCount = bookshelfItems.data.length; // Required for infinite scroll
        this.itemTotal = bookshelfItems.total;
        this.favouritesTotal = bookshelfItems.favorites;
        this.items = [...this.items, ...this.getModelsFromBookshelfItems(bookshelfItems)];
        this.favourites = [...this.favourites, ...this.getModelsFromBookshelfItems(bookshelfItems, true)];
    }

    private getModelsFromBookshelfItems(bookshelfItems: ApiBooksInterface, favourites: boolean = false): Array<BookshelfItemModel> {
        return this.bookShelfItemModelFactory.fromApiBooks(bookshelfItems.data.filter(bookshelfItem => bookshelfItem.isFavorite === favourites));
    }
}
