import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ContentSelectItemInterface} from 'shared/modules/content-select/interfaces/select-item.interface';
import {ContentSelectModelFactory} from 'shared/modules/content-select/factories/model.factory';
import {ContentSelectModel} from 'shared/modules/content-select/models/content-select.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';
import {InternshipService} from 'services/internship/internship.service';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxClose} from 'models/iconbox/close.model';

@Component({
    selector: 'app-add-practice-supervisor',
    templateUrl: 'add-practice-supervisor-modal.component.html',
})
export class DocumentPageAddPracticeSupervisorModalComponent implements OnInit {

    public readonly iconEnum = IconEnum;

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorBlue]);

    public readonly contentSwitchIconBox: IconBoxInterface = {
        type: IconBoxTypeEnum.Icon,
        value: IconEnum.TeacherTie,
    };

    @Input()
    public bookTitle: string;

    @Input()
    public bookId: number;

    @Input()
    public internship: ApiInternshipInterface = null;

    @Output()
    public closeEvent: EventEmitter<ApiInternshipInterface | null> = new EventEmitter();

    public currentSupervisor: ContentSelectItemInterface = null;

    public contentSwitchData: Array<ContentSelectItemInterface> = [];

    public supervisorClassModifiers: Array<ContentSelectClassModifiersEnum> = [
        ContentSelectClassModifiersEnum.PracticeSupervisor,
        ContentSelectClassModifiersEnum.Grey,
    ];

    private teacherId: string;

    constructor(
        private activeModal: NgbActiveModal,
        private internshipService: InternshipService,
        private contentSwitchModelFactory: ContentSelectModelFactory,
        private toastService: ToastrService,
    ) {
    }

    public ngOnInit(): void {
        this.internshipService.getPossiblePracticeSupervisors().subscribe((teachers: Array<ApiUserInterface>) => {
            this.contentSwitchData = this.contentSwitchModelFactory.fromApiUserArray(teachers);
            this.getPracticeSupervisor();
        });
    }

    public close(internship: ApiInternshipInterface = null): void {
        this.closeEvent.emit(internship);
        this.activeModal.dismiss();
    }
    public onContentItemClick(teachers: Array<ContentSelectModel>): void {
        teachers.forEach((teacher) => {
            this.teacherId = teacher.id;
        });
    }

    public savePracticeSupervisor(): void {
        if (this.internship !== null) {
            this.updatePracticeSupervisor();
        } else {
            this.addPracticeSupervisor();
        }
    }

    public removePracticeSupervisor(): void {
        this.internshipService.removePracticeSupervisor(this.internship.id).subscribe(() => {
            this.toastService.success('Praktijkbegeleider is verwijderd.');
            this.close();
        }, (errorResponse: HttpErrorResponse) => {
            this.toastService.error('Kon praktijkbegeleider niet verwijderen.');
        });
    }

    private getPracticeSupervisor(): void {
        if (this.internship === null) {
            return;
        }

        for (const contentSwitchItem of this.contentSwitchData) {
            if (Number(contentSwitchItem.id) === this.internship.mentor.id) {
                contentSwitchItem.active = true;
                this.currentSupervisor = contentSwitchItem;
                this.teacherId = this.currentSupervisor.id;
            }
        }
    }


    private addPracticeSupervisor(): void {
        this.internshipService.invitePracticeSupervisor(this.bookId, Number(this.teacherId)).subscribe((data: ApiInternshipInterface) => {
            this.toastService.success('Praktijkbegeleider is toegevoegd.');
            this.close(data);
        }, (errorResponse: HttpErrorResponse) => {
            this.toastService.error('Kon praktijkbegeleider niet toevoegen.');
        });
    }

    private updatePracticeSupervisor(): void {
        this.internshipService.updatePracticeSupervisor(this.internship.id, this.bookId, Number(this.teacherId)).subscribe((data: ApiInternshipInterface) => {
            this.toastService.success('Praktijkbegeleider is gewijzigd.');
            this.close(data);
        }, (errorResponse: HttpErrorResponse) => {
            this.toastService.error('Kon praktijkbegeleider niet wijzigen.');
        });
    }
}
