import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PublisherService} from 'services/publisher/publisher.service';
import {Subscription} from 'rxjs';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxClose} from 'models/iconbox/close.model';

@Component({
    selector: 'app-save-favorites-sort',
    templateUrl: 'save-favorites-sort-modal.component.html',
})
export class SaveFavoritesSortModalComponent implements OnInit, OnDestroy {

    public readonly iconBoxClose = new IconBoxClose([IconBoxClassEnum.ColorBlue]);

    public url: string;

    private publisherChangeSubscription: Subscription;

    public publisherUrlDisabled = false;

    constructor(
        private activeModal: NgbActiveModal,
        private publisherService: PublisherService,
    ) {
    }

    public ngOnInit(): void {
        this.publisherChangeSubscription = this.publisherService.onPublisherChange.subscribe(() => this.setUrl());

        this.setUrl();
    }

    public ngOnDestroy(): void {
        if (this.publisherChangeSubscription instanceof Subscription) {
            this.publisherChangeSubscription.unsubscribe();
        }
    }

    public close(): void {
        this.activeModal.dismiss();
    }

    public save(): void {
        this.activeModal.close();
    }

    private setUrl(): void {
        const publisher = this.publisherService.getActivePublisher();

        if (!publisher || !publisher.teacher_materials_url) {
            this.publisherUrlDisabled = true;

            return;
        }

        this.url = publisher.teacher_materials_url;
    }
}
