import {SearchResultInterface} from 'pages/modules/search/interfaces/search-result.interface';
import {Injectable} from '@angular/core';
import {SearchEntityTypesEnum} from 'pages/modules/search/enums/entity-types.enum';
import {SearchResultTaskInterface} from 'pages/modules/search/interfaces/result-task.interface';
import {SearchResultNoteInterface} from 'pages/modules/search/interfaces/result-note.interface';
import {SearchResultUserInterface} from 'pages/modules/search/interfaces/result-user.interface';
import {SearchResultDocumentInterface} from 'pages/modules/search/interfaces/result-document.interface';
import {SearchResultStudyMaterialInterface} from 'pages/modules/search/interfaces/result-study-material.interface';

@Injectable()
export class SearchResultsFactory {

    public fromSearchResults(apiResults: Array<ApiSearchItemInterface>): Array<SearchResultInterface> {
        const results = [];

        for (const apiResult of apiResults) {
            const res = this.handleApiResult(apiResult);

            if (res !== null) {
                results.push(this.handleApiResult(apiResult));
            }
        }

        return results;
    }

    private handleApiResult(apiResult: ApiSearchItemInterface): SearchResultInterface|null {
        switch (apiResult.entity) {
            case SearchEntityTypesEnum.Document:
                return this.handleApiResultToDocument(apiResult);

            case SearchEntityTypesEnum.StudyMaterial:
                return this.handleApiResultToStudyMaterial(apiResult);

            case SearchEntityTypesEnum.User:
                return this.handleApiResultToUser(apiResult);

            case SearchEntityTypesEnum.Note:
                return this.handleApiResultToNote(apiResult);

            case SearchEntityTypesEnum.Task:
                return this.handleApiResultToTask(apiResult);
        }

        console.error('Unknown entity type found:', apiResult.entity);

        return null;
    }

    private handleApiResultToDocument(apiResult: ApiSearchItemInterface): SearchResultDocumentInterface {
        return {
            id: apiResult.id,
            entity: apiResult.entity,
            title: apiResult.name,
            dpsid: apiResult.dpsid,
            isEduHtml: apiResult.isEduHtml,
            teacherOnly: apiResult.teacher_only,
            type: apiResult.type,
        };
    }

    private handleApiResultToStudyMaterial(apiResult: ApiSearchItemInterface): SearchResultStudyMaterialInterface {
        return {
            id: apiResult.id,
            entity: apiResult.entity,
            title: apiResult.title,
            type: apiResult.type,
            typeId: apiResult.type_id,
            method: apiResult.method,
            edition: apiResult.edition,
            publisher: apiResult.publisher,
            ean: apiResult.ean,
            cpi: apiResult.cpi,
            url: apiResult.url,
            licenseFree: apiResult.licence_free,
            dpsid: apiResult.dpsid || null,
            bookId: apiResult.related_book_id || null,
        };
    }

    private handleApiResultToUser(apiResult: ApiSearchItemInterface): SearchResultUserInterface {
        return {
            id: apiResult.id,
            entity: apiResult.entity,
            title: apiResult.name,
            avatar: apiResult.avatar,
        };
    }

    private handleApiResultToNote(apiResult: ApiSearchItemInterface): SearchResultNoteInterface {
        return {
            id: apiResult.id,
            entity: apiResult.entity,
            title: apiResult.message,
            dpsid: apiResult.dpsid || null,
        };
    }

    private handleApiResultToTask(apiResult: ApiSearchItemInterface): SearchResultTaskInterface {
        return {
            id: apiResult.id,
            entity: apiResult.entity,
            title: apiResult.title,
        };
    }

}
