import {Directive, ElementRef, HostBinding, OnInit} from '@angular/core';

@Directive({
    selector: 'document-original-content',
})
export class DocumentOriginalContentDirective implements OnInit {

    @HostBinding('style.display')
    public display: string = 'none';

    public children: Array<HTMLElement> = [];

    constructor(public elementRef: ElementRef) {
    }

    public ngOnInit(): void {
        this.elementRef.nativeElement.childNodes.forEach((childNode: HTMLElement) => {
            this.children.push(childNode);
        });
    }
}
