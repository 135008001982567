import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';
import {LocalStorageService} from 'angular-2-local-storage';
import {LocalStorageKeysEnum} from 'enums/local-storage-keys.enum';
import {FilterDropdownFilterInterface} from 'shared/components/filter-dropdown/interfaces/filter.interface';
import {IconEnum} from 'enums/icon.enum';
import {TaskGroupsFilterEnum} from 'pages/modules/tasks/enums/groups-filter.enum';

@Component({
    selector: 'app-student-filter',
    templateUrl: 'student-filter.component.html',
})
export class StudentFilterComponent implements OnChanges {

    @Input()
    public classModifiers: Array<ContentSelectClassModifiersEnum> = [];

    @Input()
    public group: ApiGroupInterface;

    @Input()
    public student: ApiUserInterface;

    @Output()
    public studentChange: EventEmitter<ApiUserInterface> = new EventEmitter();

    @Input()
    public disabled: boolean = false;

    public studentsFilter: FilterDropdownFilterInterface<string | number> = {
        id: 'students-filter',
        icon: IconEnum.User,
        selectedValue: null,
        placeHolder: 'Selecteer student',
        options: new Map([['', this.getDefaultOptionsForStudentsFilter()]]),
    };

    constructor(
        private localStorageService: LocalStorageService,
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.group) {
            this.updateSelectedStudent();
        }

        if (changes.student && changes.student.currentValue !== changes.student.previousValue) {
            if (changes.student.currentValue && changes.student.currentValue.id) {
                this.studentsFilter.selectedValue = changes.student.currentValue.id;
            } else {
                this.studentsFilter.selectedValue = TaskGroupsFilterEnum.All;
            }
        }
    }

    public onStudentsFilterChange(filterChange: FilterDropdownFilterInterface<string | number>): void {
        this.studentsFilter = filterChange;

        this.updateSelectedStudent();

        this.studentChange.emit(this.student);
    }

    private getDefaultOptionsForStudentsFilter(): Map<string | number, string> {
        return new Map<string | number, string>([
            [TaskGroupsFilterEnum.All, 'Alle studenten'],
        ]);
    }

    private updateSelectedStudent(): void {
        if (this.group === undefined) {
            this.studentsFilter.selectedValue = null;

            return;
        }

        if (this.student === undefined) {
            this.setInitialActiveState();
        }

        this.studentsFilter.options.set('', this.getDefaultOptionsForStudentsFilter());

        if (this.group === null) {
            this.studentsFilter.selectedValue = TaskGroupsFilterEnum.All;

            this.student = null;
            this.studentChange.emit(this.student);

            return;
        }

        let selectedStudent = null;

        for (const student of this.group.students) {
            this.studentsFilter.options.get('').set(String(student.id), student.name);

            if (student.id === Number(this.studentsFilter.selectedValue)) {
                selectedStudent = student;
            }
        }

        this.student = selectedStudent;
        this.studentChange.emit(this.student);

        if (this.student === null) {
            this.studentsFilter.selectedValue = TaskGroupsFilterEnum.All;
        }

        const persisted = this.loadPersistedObject();

        if (persisted === null) {
            return;
        }

        persisted.userId = this.student ? this.student.id : null;
        this.localStorageService.set(LocalStorageKeysEnum.GroupUserSingleData, persisted);
    }

    private setInitialActiveState(): void {
        const persisted = this.loadPersistedObject();

        if (!this.group || persisted === null || !persisted.userId) {
            return;
        }

        for (const student of this.group.students) {
            if (student.id === persisted.userId) {
                this.studentsFilter.selectedValue = student.id.toString();

                break;
            }
        }
    }

    private loadPersistedObject(): GroupPersistedInterface|null {
        const persisted = this.localStorageService.get<GroupPersistedInterface>(LocalStorageKeysEnum.GroupUserSingleData) || null;

        if (!(persisted instanceof Object) || Object.keys(persisted).length === 0) {
            return null;
        }

        return persisted;
    }
}
