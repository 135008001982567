export class RedactorToggleToolbarPlugin implements RedactorPluginInterface {

    public static pluginName: string = 'toggle-toolbar-plugin';

    public static buttonName: string = 'toggle-toolbar';

    public static containerToolbarClass: string = 'redactor-box--toolbar-collapsed';

    /**
     * @see https://imperavi.com/redactor/examples/creating-plugins/sample-plugin-with-button/
     */
    public getInstance(): RedactorPluginInstanceInterface {
        return {
            appInstance: null,
            getClassList: function (): DOMTokenList {
                return this.appInstance.container.$container.nodes[0].classList;
            },
            addClass(className: string): void {
                this.getClassList().add(className);
            },
            removeClass(className: string): void {
                this.getClassList().remove(className);
            },
            init: function (app: RedactorAppInstance): void {
                this.appInstance = app;
            },
            start: function (): void {
                this.toggle(true);
                this.appInstance.toolbar.addButton(RedactorToggleToolbarPlugin.buttonName, {
                    title: this.appInstance.lang.get('options'),
                    icon: '<i class="icon icon--options"></i>',
                    api: `plugin.${RedactorToggleToolbarPlugin.pluginName}.toggle`,
                });
            },
            toggle: function (toggleCollapsed?: boolean): void {
                const classList = this.getClassList();
                const toggle = (toggleCollapsed === undefined)
                    ? !classList.contains(RedactorToggleToolbarPlugin.containerToolbarClass)
                    : toggleCollapsed;

                if (!toggle) {
                    this.removeClass(RedactorToggleToolbarPlugin.containerToolbarClass);
                } else {
                    this.addClass(RedactorToggleToolbarPlugin.containerToolbarClass);
                }
            },
        };
    }

}
