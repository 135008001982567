import {EventEmitter, Injectable} from '@angular/core';
import {WindowScrollDirectionEnum} from 'services/window/enums/scroll-direction.enum';
import {WindowServiceScrollEventInterface} from 'services/window/interfaces/window-scroll-event.interface';

@Injectable()
export class WindowService {

    public onScroll: EventEmitter<WindowServiceScrollEventInterface> = new EventEmitter();

    public onResize: EventEmitter<Event> = new EventEmitter();

    private lastScrollPosition: number = 0;

    private scrollDirection: WindowScrollDirectionEnum;

    constructor() {
        window.addEventListener('scroll', (event) => this.handleScroll(event), true);
        window.addEventListener('resize', (event) => this.handleResize(event), true);
    }

    protected handleResize(event: Event): void {
        if (this.onResize.observers.length) {
            this.onResize.emit(event);
        }
    }

    protected handleScroll(event: Event): void {
        if (this.onScroll.observers.length) {
            this.setScrollDirection();
            this.onScroll.emit({scrollDirection: this.scrollDirection, originalEvent: event});
        }
    }

    protected setScrollDirection(): void {
        const scrollDirection: WindowScrollDirectionEnum = this.lastScrollPosition < window.pageYOffset
            ? WindowScrollDirectionEnum.Down
            : WindowScrollDirectionEnum.Up;

        this.lastScrollPosition = window.pageYOffset;
        this.scrollDirection = scrollDirection;
    }
}
