export enum HorizontalBarClassesEnum {

    Base = 'horizontal-bar',

    BorderOuterNone = 'horizontal-bar--border-outer-none',

    NoWidth = 'horizontal-bar--no-width',

    Progress = 'horizontal-bar--progress',

    CursorPointer = 'horizontal-bar--cursor-pointer',

}
