import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {ExamService} from 'services/exam/exam.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'app-exams-make',
    templateUrl: 'exams-make.component.html',
})
export class ExamsMakeComponent implements OnInit, OnDestroy {

    public readonly preloaderEnum = PreloaderClassModifiersEnum;

    public readonly routesEnum = RoutesEnum;

    public isLoading: boolean = false;

    public examPlanId: number;

    public exam: ApiExamDetailsInterface;

    public examUrl: SafeResourceUrl;

    private routeParamSubscription: Subscription;

    public constructor(
        private examService: ExamService,
        private toastService: ToastrService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
    ) {
    }

    private loadData(): void {
        const planId = this.route.snapshot.params.planId || 0;
        const examId = this.route.snapshot.params.examId;
        const bookId = this.route.snapshot.params.bookId;

        if (planId !== 0) {
            this.examPlanId = planId;
        }

        if (!examId || !bookId || this.isLoading === true) {
            return;
        }

        this.isLoading = true;

        this.examService.retrieveExam(planId, examId, bookId).toPromise()
            .then((exam: ApiExamDetailsInterface) => {
                this.setExam(exam);

                return Promise.resolve([]);
            })
            .catch((response: any) => {
                console.error(response.error.msg);

                if (response.error.msg === 'No license for exam') {
                    this.toastService.error('Je hebt geen geldige licentie voor deze toets.');
                } else {
                    this.toastService.error('Kon examen niet ophalen.');
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    private setExam(exam: ApiExamDetailsInterface): void {
        this.exam = exam;
        if (exam.examination_info.exam_url) {
            this.examUrl = this.sanitizer.bypassSecurityTrustResourceUrl(<string>exam.examination_info.exam_url);
        }
    }

    public ngOnInit(): void {
        this.routeParamSubscription = this.route.params.subscribe(() => {
            this.loadData();
        });
    }

    public ngOnDestroy(): void {
        if (this.routeParamSubscription instanceof Subscription) {
            this.routeParamSubscription.unsubscribe();
        }
    }

}
