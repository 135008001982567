import {ProgressExamScoreInterface} from 'pages/modules/progress/interfaces/exam-score.interface';

export class ProgressExamScoreModel implements ProgressExamScoreInterface {

    constructor(
        public exam?: number,
        public practiceExam?: number,
    ) {
    }
}
