import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, Observer} from 'rxjs';
import {environment} from 'environments/environment';
import {Injectable, inject} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceHttpInterceptor implements HttpInterceptor {
    private toastService = inject(ToastrService);

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return new Observable((observer: Observer<HttpEvent<any>>) => {
            next.handle(req).subscribe((response: HttpEvent<any>) => {
                observer.next(response);
            }, (err: HttpErrorResponse) => {
                if (err.status >= 500 && err.status <= 599) {

                    if (err.status === 503) {
                        location.href = environment.maintenanceUrl;

                        return;
                    }

                    if (err.status === 504) {
                        this.toastService.error('We zijn even niet bereikbaar, we lossen het voor je op!', '',  { timeOut: 9000 });
                        console.error(err);

                        return;
                    }

                    this.toastService.error('Oeps er gaat iets mis, we gaan voor je aan de slag!', '',  { timeOut: 9000 });

                    console.error(err);
                }

                if (err.status === 403) {
                    this.toastService.error('Je hebt onvoldoende rechten.', '',  { timeOut: 9000 });
                    console.error(err);

                    return;
                }

                observer.error(err);
            }, () => {
                observer.complete();
            });
        });
    }
}
