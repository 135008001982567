import {Component, Input, OnInit} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {AvatarClassesEnum} from 'shared/components/avatar/enums/avatar-classes.enum';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';

@Component({
    selector: 'app-content-exam-result-assignment',
    templateUrl: 'result-assignment.component.html',
})
export class ContentExamResultAssignmentComponent implements OnInit {

    public readonly tableClasses = GridTableClassEnum;

    public readonly rowClasses = GridTableRowClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly avatarClasses = AvatarClassesEnum;

    public readonly iconEnum = IconEnum;

    @Input()
    public assignmentResults: Array<ApiPracticeExamAssignmentResultsInterface>;

    @Input()
    public attempt: number;

    @Input()
    public showPracticeExamText: boolean;

    private updateExamResult(): void {
    }

    public ngOnInit(): void {
        this.updateExamResult();
    }
}
