import {Injectable} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {Observable} from 'rxjs';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {UserService} from 'security/services/user/user.service';
import {first} from 'rxjs/operators';

@Injectable()
export class GroupsService {

    protected groupCount: number = 0;

    constructor(
        private userService: UserService,
        private apiRequestService: ApiService,
    ) {
        this.groupCount = this.userService.getUserData().group_count;
    }

    public static buildGroupName(name: string, year: string | null): string {
        return `${name}${year !== null && year.length > 0 ? ' ' : ''}${year !== null ? year : ''}`;
    }

    public getGroupCount(): number {
        return this.groupCount;
    }

    public getGroup(groupId: number): Observable<ApiGroupInterface> {

        return this.apiRequestService
            .get<ApiGroupInterface>(ApiEndpointEnum.Group, null, new Map([
                ['group', groupId.toString()],
            ]));
    }

    public retrieveGroups(offset?: number, limit?: number, search?: string, active?: boolean): Observable<ApiGroupsInterface> {
        const params = new URLSearchParams();

        if (offset) {
            params.append('offset', offset.toString());
        }

        if (limit) {
            params.append('limit', limit.toString());
        }

        if (search) {
            params.append('search', search);
        }

        if (active !== undefined && active !== null) {
            params.append('active', active ? '1' : '0');
        } else {
            params.append('active', '-1');
        }

        return this.apiRequestService.get<ApiGroupsInterface>(ApiEndpointEnum.Groups, params);
    }

    public retrieveAllGroups(search?: string | null): Observable<ApiGroupsInterface> {
        const params = new URLSearchParams();

        if (search) {
            params.append('search', search);
        }

        return this.apiRequestService
            .get<ApiGroupsInterface>(ApiEndpointEnum.Groups, params);
    }

    public retrieveAllForFilter(): Observable<ApiGroupsInterface> {
        return this.apiRequestService.get<ApiGroupsInterface>(ApiEndpointEnum.GroupsFilter);
    }

    public retrieveOrganisationGroups(schoolYearId: number, search?: string): Observable<Array<ApiGroupInterface>> {
        return this.apiRequestService
            .get<Array<ApiGroupInterface>>(
                ApiEndpointEnum.GroupsOrganisation,
                null,
                new Map([
                    ['schoolYear', schoolYearId.toString()],
                    ['search', search],
                ]),
            );
    }

    public createGroup(name: string, schoolYearId: number, year: string): Observable<ApiGroupInterface> {
        const body: any = {
            name: name,
            year: year,
            school_year_id: schoolYearId
        };

        const observable: Observable<ApiGroupInterface> = this.apiRequestService.post<ApiGroupInterface>(ApiEndpointEnum.GroupsCreate, body);

        observable.subscribe(() => this.updateGroupCountByAmount(1, null, true));

        return observable;
    }

    public addTeacher(userId: number, groupId: number, isFinished?: boolean): Observable<ApiGroupInterface> {
        const observable: Observable<ApiGroupInterface> = this.apiRequestService
            .post<ApiGroupInterface>(
                ApiEndpointEnum.GroupsAddTeacher,
                null,
                null,
                new Map([
                    ['groupId', groupId.toString()],
                    ['userId', userId.toString()],
                ]),
            );

        observable.subscribe(() => this.updateGroupCountByAmount(1, userId, isFinished));

        return observable;
    }

    public updateGroupName(groupId: number, groupName: string, groupYear: string): Observable<ApiGroupInterface> {
        return this.apiRequestService
            .post<ApiGroupInterface>(
                ApiEndpointEnum.GroupsUpdate,
                {
                    group: groupId,
                    name: groupName,
                    year: groupYear,
                },
            );
    }

    public joinGroups(codes: Array<string>): Observable<Array<ApiGroupInterface>> {
        const observable: Observable<Array<ApiGroupInterface>> = this.apiRequestService.post(ApiEndpointEnum.GroupsJoin, {codes});

        let count = 0;
        observable.subscribe((groups) => {
            if (count < 1) {
                this.updateGroupCountByAmount(groups.length, null, true);
                count++;
            }
        });

        return observable;
    }

    public removeTeacher(userId: number, groupId: number): Observable<any> {
        const observable: Observable<any> = this.apiRequestService
            .delete<any>(
                ApiEndpointEnum.GroupsAddTeacher,
                null,
                new Map([
                    ['groupId', groupId.toString()],
                    ['userId', userId.toString()]
                ]));

        observable.pipe(first()).subscribe(() => this.updateGroupCountByAmount(-1, userId, true));

        return observable;
    }

    public removeStudent(userId: number, groupId: number): Observable<ApiGroupInterface> {
        return this.apiRequestService
            .post<ApiGroupInterface>(
                ApiEndpointEnum.GroupsRemoveStudent,
                null,
                null,
                new Map([
                    ['groupId', groupId.toString()],
                    ['userId', userId.toString()],
                ]),
            );
    }

    public renewCode(groupId: number): Observable<ApiGroupInterface> {
        return this.apiRequestService
            .post<ApiGroupInterface>(
                ApiEndpointEnum.GroupsRenewCode,
                null,
                null,
                new Map([
                    ['groupId', groupId.toString()],
                ]),
            );
    }

    public toggleActive(groupId: number, active: boolean): Observable<ApiGroupInterface> {
        return this.apiRequestService
            .post<ApiGroupInterface>(
                ApiEndpointEnum.GroupsUpdate,
                {
                    group: groupId,
                    active: active ? 1 : 0,
                },
            );
    }

    /**
     * TODO: BO-1588 It would have been better if API would return user's group count with each request
     */
    protected updateGroupCountByAmount(amount: number, userId?: number, isFinished?: boolean): void {
        if (userId !== undefined && !this.userService.isCurrentUserId(userId)) {
            return;
        }

        const user = this.userService.getUserData();

        if (isFinished) {
            this.userService.reload();
        }


        this.groupCount = user.group_count + 1;
    }
}
