import {DocumentPageActionsButtonsEnum} from 'pages/modules/document/enums/actions-buttons.enum';
import {DocumentActionButtonInterface} from 'pages/modules/document/interfaces/action-button.interface';
import {IconEnum} from 'enums/icon.enum';
import {RoleEnum} from 'enums/role.enum';

export class DocumentActionsButtonAssignmentModel implements DocumentActionButtonInterface {

    public id: DocumentPageActionsButtonsEnum = DocumentPageActionsButtonsEnum.SubmitAssignment;

    public value: string = 'Opdracht opgeven';

    public icon: IconEnum = IconEnum.Redo;

    public roles: Array<RoleEnum> = [
        RoleEnum.RoleDocumentAssignTask,
    ];

    constructor(public classModifiers: Array<string>, public visible: boolean = true) {
    }

}
