import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy } from '@angular/core';
import {DocumentSingleSelectInlineOptionsInterface} from 'document/modules/components/single-select-inline/interface/options.interface';
import {DocumentAnswerableComponent} from 'document/modules/abstract/answerable.component';
import {DocumentAnswersService} from 'document/services/answers/answers.service';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {DocumenSingleSelectInlineClassEnum} from 'document/modules/components/single-select-inline/enums/class.enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AutoCheckService } from 'document/services/autocheck/auto-check.service';
import {RoleEnum} from 'enums/role.enum';
import {ExamineOptionsEnum} from 'pages/modules/tasks/components/modals/add-modal/enums/examine-options.enum';
import {DocumentService} from 'document/services/document/document.service';

// KEUZE VRAGEN

@Component({
    selector: '.document-singleselectinline',
    templateUrl: 'single-select-inline.component.html',
})
export class DocumentSingleSelectInlineComponent extends DocumentAnswerableComponent implements AfterViewInit, OnDestroy {

    @HostBinding('class')
    public elementClass: string;

    public readonly roleEnum = RoleEnum;

    public readonly examinationNeededEnum = ExamineOptionsEnum;

    public classModifiers: ClassHelper = new ClassHelper(DocumenSingleSelectInlineClassEnum.Input);

    public id: string;

    public examinationByStudent: boolean = false;

    public options: DocumentSingleSelectInlineOptionsInterface;

    public value: string = '';

    public handedInAnswer: string = '';

    public correctAnswer: string = '';

    public initialAnswer: string;

    public answerHidden: boolean;

    protected dataSet: DocumentDatasetOptionsInterface;

    public autoScoreEnabled: boolean;

    public autoScoreAnswer: string;

    private subscriptions: Array<Subscription> = [];

    constructor(
        protected elementRef: ElementRef,
        protected answersService: DocumentAnswersService,
        protected showAnswersService: DocumentAnswersStateService,
        private autocheckService: AutoCheckService,
        private documentService: DocumentService,
    ) {
        super(elementRef, answersService, showAnswersService);

        this.id = `input-inline-${this.dataSet.assignmentid}-${this.dataSet.fieldid}`;

        try {
            this.options = JSON.parse(this.dataSet.options);
            this.autoScoreEnabled = false;
        } catch (e) {
            this.initializationFailed = true;

            console.error('Failed to JSON parse the options:', e);
        }
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();

        this.setClass();
        this.setupAutoScoring();
        this.handleExaminationNeeded();

    }

    public onModelChange(ev: string): void {
        this.setClass();

        this.saveAnswer(this.value);

        this.handedInAnswer = this.getOptionValue(this.value);
    }

    public setClass(): void {
        if (this.value && this.value.length > 0) {
            this.classModifiers.addClass(DocumenSingleSelectInlineClassEnum.HasValue);
        } else {
            this.classModifiers.removeClass(DocumenSingleSelectInlineClassEnum.HasValue);
        }

        this.elementClass = this.classModifiers.toString();
    }

    protected setAnswer(answer: string): void {
        if (!answer) {
            return;
        }

        this.value = answer;

        this.handedInAnswer = this.getOptionValue(this.value);

    }

    private handleExaminationNeeded(): void {
        const task = this.documentService.getActiveTask();

        // examination by Student
        if (task && task.examination_needed === this.examinationNeededEnum.Student) {
            this.examinationByStudent = this.documentService.subscribeSelfStudyCompatible();
        }
    }

    private setupAutoScoring(): void {

        const assignmentId = this.dataSet.assignmentid;
        const fieldId = parseInt(this.dataSet.fieldid, 10);
        const assignment = this.answersService.getAssignment(assignmentId);
        this.autoScoreEnabled = assignment && assignment.auto_check;

        if (! this.autoScoreEnabled) { return; }

        this.subscriptions.push( this.autocheckService.getAutoCheckAnswerForFieldId(assignmentId, fieldId).pipe(take(1)).subscribe( autoCheckAnswer => {
            if (autoCheckAnswer) {
                this.autoScoreAnswer =  this.getOptionValue(autoCheckAnswer);
            }
        }));
    }

    protected showCorrectAnswer(correctAnswer: string): void {
        if (!correctAnswer || !this.options) {
            return;
        }

        for (const option of this.options.choices) {
            if (option.label === correctAnswer || option.value === correctAnswer) {
                this.correctAnswer = option.label;
            }
        }

        this.answerHidden = false;
    }

    protected hideCorrectAnswer(): void {
        this.answerHidden = true;
    }

    protected showInitialAnswer(initialAnswer: string): void {
        this.initialAnswer = this.getOptionValue(initialAnswer);
    }

    protected answerValid(): boolean {
        return true;
    }

    private getOptionValue(key: string): string {
        if (!key || !this.options) {
            return '';
        }

        for (const option of this.options.choices) {
            if (option.value === key || option.label === key) {
                return option.label;
            }
        }

        return null;
    }

    public ngOnDestroy(): void {
        this.autocheckService.resetAutoCheckAnswer();
        this.subscriptions.map(sub => sub.unsubscribe());
    }
}
