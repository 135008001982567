import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {ClassHelper} from 'helpers/dom/class.helper';
import {PageRowClassEnum} from 'shared/modules/page/enums/page-row/class.enum';

@Component({
    selector: 'app-page-row',
    templateUrl: 'page-row.component.html',
})
export class PageRowComponent implements OnInit {

    public static CLASS = 'row';

    @HostBinding('class')
    public hostClasses: string;

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public set marginBottom(value: boolean | string) {
        this.classHelper.toggleClassByBoolean(PageRowClassEnum.MarginBottom, value.toString() === 'true');
    }

    private classHelper = new ClassHelper(PageRowComponent.CLASS);

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classModifiers);
        this.hostClasses = this.classHelper.toString();
    }
}
