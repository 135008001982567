export enum RoutesEnum {

    Books = 'books',

    Book = 'books/:bookId',

    Document = 'books/:bookId/:documentDpsid',

    TeacherMaterialBook = 'books/:bookId/teacher-material',

    TeacherMaterialBookChapter = 'books/:bookId/teacher-material/:folderDpsid',

    Search = 'search',

    Tasks = 'tasks',

    TaskDetails = 'tasks/:taskId',

    TaskHomework = 'tasks/homework/:homeworkId',

    Exams = 'exams',

    ExamDetails = 'exams/:planId',

    ExamStudentDetails = 'exams/:planId/student/:studentId',

    ExamMake = ':bookId/exams/:examId/make',

    ContentExamPage = ':bookId/exams/content/:examId',

    ContentExamPlanPage = ':bookId/exams/content/:examId/plan/:planId',

    ContentExamPageTeacher = ':bookId/exams/content/:examId/plan/:planId/student/:studentId',

    ExamPlanMake = 'exams/:planId/make/:examId/:bookId',

    ExamPlanResult = 'exams/:planId/result/:resultId',

    ExamPlanResultTeacher = 'exams/:planId/result/:resultId/:studentId',

    Progress = 'progress',

    Groups = 'groups',

    TeacherMaterial = 'teacher-material',

    PdfViewer = 'pdf-viewer/:dpsid',

    PdfViewerSource = 'pdf-viewer/:sourceDpsid',

    Profile = 'profile',

    Settings = 'settings',

    User = 'user/:userId',

    MessageCenter = 'berichtencentrum',

    //
    // Deeplinks
    //

    Deeplink = 'deeplink',

    DeeplinkEan = 'ean/:ean',

    DeeplinkDpsid = 'dpsid/:dpsid',

    DeeplinkBookId = 'book-id/:bookId',

}
