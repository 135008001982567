import {ProgressStudyMaterialInterface} from 'pages/modules/progress/interfaces/study-material.interface';
import {ProgressAverageInterface} from 'pages/modules/progress/interfaces/average.interface';
import {ProgressAssignmentStatusInterface} from 'pages/modules/progress/interfaces/assignment-status.interface';
import {ProgressDrillsterStatusInterface} from 'pages/modules/progress/interfaces/drillster-status.interface';
import {ProgressExamsInterface} from 'pages/modules/progress/interfaces/exams.interface';

export class ProgressStudyMaterialModel implements ProgressStudyMaterialInterface {

    constructor(
        public assignmentStatuses: Array<ProgressAssignmentStatusInterface>,
        public drillsterStatusses: Array<ProgressDrillsterStatusInterface>,
        public average: ProgressAverageInterface,
        public exams?: Array<ProgressExamsInterface>,
    ) {
    }
}
