export enum DocumentContentMediaTagEnum {

    Img = 'IMG',

    IFrame = 'IFRAME',

    FigCaption = 'FIGCAPTION',

    Div = 'DIV',

}
