import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TaskMineFilterEnum} from 'shared/components/mine-filter/enum/mine-filter.enum';
import {MinePersistedInterface} from 'shared/components/mine-filter/interface/persisted.interface';
import {ContentSelectClassModifiersEnum} from 'shared/modules/content-select/enums/class-modifiers.enum';
import {LocalStorageService} from 'angular-2-local-storage';
import {LocalStorageKeysEnum} from 'enums/local-storage-keys.enum';
import {FilterDropdownFilterInterface} from 'shared/components/filter-dropdown/interfaces/filter.interface';

@Component({
    selector: 'app-mine-filter',
    templateUrl: 'mine-filter.component.html',
})
export class MineFilterComponent implements OnInit {

    @Input()
    public classModifiers: Array<ContentSelectClassModifiersEnum> = [];

    @Output()
    public mineChange: EventEmitter<string|TaskMineFilterEnum> = new EventEmitter();

    @Input()
    public disabled: boolean = false;

    public mineFilter: FilterDropdownFilterInterface<TaskMineFilterEnum> = {
        id: 'mine-filter',
        selectedValue: TaskMineFilterEnum.Mine,
        options: new Map([
            ['', new Map<TaskMineFilterEnum, string>([
                [TaskMineFilterEnum.Mine, 'Mijn opdrachten'],
                [TaskMineFilterEnum.All, 'Alle opdrachten'],
            ])]
        ]),
    };

    constructor(
        private localStorageService: LocalStorageService,
    ) {}

    public ngOnInit(): void {
        this.setInitialActiveState();

        this.updateSelectedGroup();
    }

    public onMineFilterChange(filterChange: FilterDropdownFilterInterface<TaskMineFilterEnum>): void {
        this.mineFilter = filterChange;
        this.mineChange.emit(this.mineFilter.selectedValue);

        const val: MinePersistedInterface = {
            mine: this.mineFilter.selectedValue
        };

        this.localStorageService.set(LocalStorageKeysEnum.MineFilterData, val);
    }

    private updateSelectedGroup(): void {
        this.mineChange.emit(this.mineFilter.selectedValue);
    }

    private setInitialActiveState(): void {
        const persisted = this.localStorageService.get<MinePersistedInterface>(LocalStorageKeysEnum.MineFilterData) || null;

        if (!persisted || !persisted.mine) {
            return;
        }

        this.mineFilter.selectedValue = persisted.mine;
    }
}
