export enum ExamsStatusFilterEnum {

    All = 'all',

    Specified = 'specified',

    ReviewNeeded = 'review_needed',

    Done = 'done',
}
