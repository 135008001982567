export class TeacherMaterialSourceModel {

    constructor(
        public id: number,
        public title: string,
        public dpsid: string,
        public studyMaterial: string,
        public type: string,
        public disabled: boolean,
        public documentType?: string,
        public sortTitle?: string,
        public mimeType?: string
    ) {
    }

}
