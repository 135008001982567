import {RoleEnum} from 'enums/role.enum';

export class UserRolesModel {

    private data: Array<RoleEnum>;

    private snapShot: string;

    constructor(private apiRole: RoleEnum) {
        this.reset();
    }

    public getData(): Array<RoleEnum> {
        return this.data;
    }

    public getSnapshot(): string {
        return this.snapShot;
    }

    public push(...items: Array<RoleEnum>): number {
        const result: number = this.data.push(...items);

        this.createSnapShot();

        return result;
    }

    public splice(start: number, deleteCount?: number): Array<RoleEnum> {
        const results = this.data.splice(start, deleteCount);

        this.createSnapShot();

        return results;
    }

    public reset(): void {
        this.data = [];
        this.push(this.apiRole);
        this.createSnapShot();
    }

    public includes(role: RoleEnum): boolean {
        return this.data.includes(role);
    }

    public some(callbackfn: (value: RoleEnum, index: number, array: Array<RoleEnum>) => boolean, thisArg?: any): boolean {
        return this.data.some(callbackfn, thisArg);
    }

    protected createSnapShot(): void {
        this.data.sort();
        this.snapShot = JSON.stringify(this.data);
    }
}
