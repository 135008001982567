import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ToggleBoxClassEnum} from 'shared/components/toggle-box/enums/toggle-box.class-enum';

@Component({
    selector: 'app-toggle-box',
    templateUrl: 'toggle-box.component.html',
})
export class ToggleBoxComponent {

    @Output()
    public toggleStateEvent: EventEmitter<boolean> = new EventEmitter();

    @Input()
    public toggleBox: ToggleBoxInterface;

    public getActive(): string {
        return this.toggleBox.state ? ToggleBoxClassEnum.Active : ToggleBoxClassEnum.Inactive;
    }

    public getInactive(): string {
        return this.toggleBox.state ? ToggleBoxClassEnum.Inactive : ToggleBoxClassEnum.Active;
    }

    public changeToggleState(event: MouseEvent): void {
        event.stopPropagation();
        event.preventDefault();

        this.toggleBox.state = !this.toggleBox.state;
        this.toggleStateEvent.emit(this.toggleBox.state);
    }
}
