import {ProgressBookInterface} from 'pages/modules/progress/interfaces/book.interface';

export class ProgressBookModel implements ProgressBookInterface {

    constructor(
        public id: number,
        public title: string,
    ) {
    }

}
