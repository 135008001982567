import {AfterContentInit, Component, ContentChildren, Input, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges} from '@angular/core';
import {GridTableRowComponent} from 'shared/modules/grid-table/components/row/grid-table-row.component';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridColumnClass} from 'shared/classes/grid/grid-column.class';
import {Subscription} from 'rxjs';
import Timer = NodeJS.Timer;

@Component({
    selector: 'app-grid-table-col',
    templateUrl: 'grid-table-col.component.html',
})
export class GridTableColComponent extends GridColumnClass implements OnInit, OnChanges, AfterContentInit, OnDestroy {

    public static readonly CLASS: string = GridTableColClassEnum.Base;

    protected subscriptions: Subscription[] = [];

    public readonly paddingCollection = 'padding';

    public readonly rightAnswerState = 'right';

    public readonly wrongAnswerState = 'wrong';

    public readonly activeTaskState = GridTableColClassEnum.taskActive;

    public taskActiveSelected: boolean = false;

    protected windowResizeTimeout: Timer;

    @Input()
    public set padding(value: string | boolean) {
        if (String(value) === 'false') {
            this.classHelper.addClass(GridTableColClassEnum.PaddingNone, this.paddingCollection);
        } else {
            this.classHelper.removeClass(GridTableColClassEnum.PaddingNone, this.paddingCollection);
        }

        this.setHostClasses();
    }

    @Input()
    public set rightwrong(value: string | boolean) {
        if (value === 'right') {
            this.classHelper.addClass(this.rightAnswerState);
        } else {
            this.classHelper.addClass(this.wrongAnswerState);
        }

        this.setHostClasses();
    }

    // TODO BO-2240-TASK SELECTOR even kijken hoe ik dit beste kan opvangen.
    @Input()
    public set taskactive(value: boolean) {

        if (value) {
            this.classHelper.addClass(this.activeTaskState);
        } else {
            this.classHelper.removeClass(this.activeTaskState);
        }

        this.setHostClasses();
    }

    @ContentChildren(GridTableRowComponent, {descendants: true})
    protected childRows: QueryList<any>;

    public ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }

    public ngAfterContentInit(): void {
        this.subscriptions.push(this.childRows.changes.subscribe(() => this.updateBasedOnChildRows()));

        this.updateBasedOnChildRows();
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    protected isBootstrapMode(): boolean {
        return false;
    }

    protected getBaseClass(): string {
        return GridTableColComponent.CLASS;
    }

    protected getBreakpointValues(): Array<number> {
        return [2, 3, 4, 6, 9, 12];
    }

    private updateBasedOnChildRows(): void {
        if (this.childRows.length > 0) {
            this.classHelper.addClass(GridTableColClassEnum.BorderNone);
            this.classHelper.addClass(GridTableColClassEnum.PaddingNone);
        } else {
            this.classHelper.removeClass(GridTableColClassEnum.BorderNone);
            this.classHelper.removeClass(GridTableColClassEnum.PaddingNone);
        }

        this.setHostClasses();
    }
}
