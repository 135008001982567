import {Component, OnDestroy, OnInit} from '@angular/core';
import {FullscreenService} from 'services/fullscreen/fullscreen.service';
import {ActivatedRoute} from '@angular/router';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {ApiService} from 'core/services/api/api.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-pdf-viewer',
    templateUrl: 'pdf-viewer.component.html',
})
export class PdfViewerComponent implements OnInit, OnDestroy {

    public currentPdf: string = '';

    public pdfHeight: string = '100vh';

    public spread: string = 'off';

    public zoom: number = 100;

    private paramsSubscription: Subscription;

    private dpsid: string;

    constructor(
        private fullscreenService: FullscreenService,
        private route: ActivatedRoute,
        private apiService: ApiService
    ) {
    }

    public ngOnInit(): void {
        this.paramsSubscription = this.route.params.subscribe(params => {
            if (params['dpsid']) {
                this.dpsid = params['dpsid'];
            }

            if (params['sourceDpsid']) {
                this.dpsid = params['sourceDpsid'];
            }
        });

        const resourceUrl: string = ApiEndpointEnum.Resource.replace(':dpsid', this.dpsid);
        this.apiService.download<any>(resourceUrl, null, null, {responseType: 'blob'}).subscribe((data: any) => this.currentPdf = window.URL.createObjectURL(data));

        setTimeout(() => this.setFullScreen());
    }

    public ngOnDestroy(): void {
        if (this.paramsSubscription) {
            this.paramsSubscription.unsubscribe();
        }
    }

    public setFullScreen(): void {
        this.fullscreenService.setFullscreen(true);
    }
}
