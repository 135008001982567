import {ElementRef, Injectable} from '@angular/core';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {DocumentService} from 'document/services/document/document.service';
import {StringService} from 'services/string/string.service';
import {RouteService} from 'routing/services/route/route.service';

@Injectable()
export class DocumentResourceLinkService {

    constructor(
        private documentService: DocumentService,
        private stringService: StringService,
        private routeService: RouteService,
    ) {
    }

    public setLinkPropertiesByResourceLink(element: ElementRef, resourceLinkData: ApiResourceLinkDataInterface): void {
        const linkData: ApiResourceLinkDataLinkInterface = this.getLinkFromResourceLinkData(resourceLinkData);

        this.setLinkTitle(element, linkData);
        this.setLinkHref(element, linkData);
    }

    public getLinkFromResourceLinkData(linkData: ApiResourceLinkDataInterface): ApiResourceLinkDataLinkInterface | null {
        let link: any = null;

        if (linkData.link) {
            try {
                link = JSON.parse(linkData.link);
            } catch (e) {
                // Just catch
            }
        }

        return link;
    }

    public setLinkTitle(element: ElementRef, linkData: ApiResourceLinkDataLinkInterface): void {
        const htmlElement: HTMLLinkElement = element.nativeElement;

        htmlElement.title = linkData.title;
    }

    public setLinkHref(element: ElementRef, linkData: ApiResourceLinkDataLinkInterface): void {
        const htmlElement: HTMLLinkElement = element.nativeElement;

        htmlElement.href = this.getResourceURLFromLinkData(linkData);
    }

    public getResourceURLFromLinkData(linkData: ApiResourceLinkDataLinkInterface): string {
        switch (linkData.ext) {
            case 'other':
                return this.stringService.getMappedString(ApiEndpointEnum.ResourceExternal, new Map([
                    ['dpsid', linkData.dpsid],
                    ['book', this.documentService.getBookId().toString()],
                ]));
            default:
                return this.getDocumentRouteWithDpsid(linkData.dpsid);
        }
    }

    protected getDocumentRouteWithDpsid(dpsid: string): string {
        return this.routeService.getRouteWithPublisher(RoutesEnum.Document, new Map([
            ['bookId', this.documentService.getBookId().toString()],
            ['documentDpsid', dpsid],
        ]));
    }
}
