import {Injectable} from '@angular/core';
import {ProgressGroupInterface} from 'pages/modules/progress/interfaces/group.interface';
import {ProgressGroupModel} from 'pages/modules/progress/models/group.model';
import {ProgressStudentFactory} from 'pages/modules/progress/factories/student.factory';
import {ProgressAverageFactory} from 'pages/modules/progress/factories/average.factory';

@Injectable()
export class ProgressGroupFactory {

    constructor(
        private studentFactory: ProgressStudentFactory,
        private averageFactory: ProgressAverageFactory,
    ) {
    }

    public fromApiProgress(progress: ApiProgressInterface): Array<ProgressGroupInterface> {
        const groups: Array<ProgressGroupInterface> = [];

        progress.groups.forEach((progressGroup: ApiProgressGroupInterface) => {
            groups.push(new ProgressGroupModel(
                progressGroup.info.id,
                progressGroup.info.name,
                this.studentFactory.fromApiProgressGroup(progressGroup),
                this.averageFactory.fromApiProgressGroup(progressGroup),
            ));
        });

        return groups;
    }

    public fromApiGroup(apiGroup: ApiGroupInterface): ProgressGroupInterface {
        return new ProgressGroupModel(
            apiGroup.id,
            apiGroup.name,
            this.studentFactory.fromApiGroup(apiGroup),
        );
    }
}
