import {Injectable} from '@angular/core';
import {ModalService} from 'core/services/modal/modal.service';
import {Router} from '@angular/router';
import {UserService} from 'security/services/user/user.service';
import {RoutesEnum} from 'routing/enums/routes.enum';

@Injectable()
export class HelpPageService {

    private positionClass: string;

    constructor(
        private router: Router,
        private modalService: ModalService,
        private userService: UserService
    ) {
    }

    public openHelpPage(tabName: string, forceOpen: boolean = false): void {
        if (!forceOpen && this.userService.isTabAlreadyVisited(tabName)) {
            return;
        }

        const isFirstTime = !this.userService.isTabAlreadyVisited(tabName);

        switch (tabName) {
            case RoutesEnum.Books:
                this.modalService.openBooksHelpPage(isFirstTime, this.positionClass);
                break;
            case RoutesEnum.Tasks:
                this.modalService.openTasksHelpPage(isFirstTime, this.positionClass);
                break;
            case RoutesEnum.Exams:
                this.modalService.openExamsHelpPage(isFirstTime, this.positionClass);
                break;
            case RoutesEnum.Progress:
                this.modalService.openProgressHelpPage(isFirstTime, this.positionClass);
                break;
            case RoutesEnum.Groups:
                this.modalService.openGroupsHelpPage(isFirstTime, this.positionClass);
                break;
        }
    }

    public setModalPosition(positionClass: string): void {
        this.positionClass = positionClass;
    }
}
