import {Component, Input} from '@angular/core';
import {IconEnum} from 'enums/icon.enum';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {ProgressDrillsterStatusInterface} from 'pages/modules/progress/interfaces/drillster-status.interface';

@Component({
    selector: 'app-popover-drillster',
    templateUrl: 'popover-drillster.component.html',
})
export class PopoverDrillsterComponent {

    public readonly iconEnum = IconEnum;

    public readonly tableClasses = GridTableClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly routesEnum = RoutesEnum;

    @Input()
    public drillster: ProgressDrillsterStatusInterface = null;

    @Input()
    public bookId: number;
}
