
export class NoHelpPageModel implements HelpPageInterface {

    public id = 1;

    public title = 'Excuses';

    public text = 'Er is nog geen helper voor deze pagina beschikbaar.';

}
