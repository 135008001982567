import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {ToastrService} from 'ngx-toastr';
import {InternshipService} from 'services/internship/internship.service';
import {ModalService} from 'core/services/modal/modal.service';
import {InviteStateEnum} from 'enums/invite-state.enum';

@Component({
    selector: 'app-document-send-review',
    templateUrl: 'send-review.component.html',
})
export class DocumentPageSendReviewComponent implements OnChanges, OnInit {

    @Input()
    public assignment: ApiDocumentAssignmentInterface;

    public invite: ApiInternshipInviteInterface = null;

    public constructor(
        private internshipService: InternshipService,
        private modalService: ModalService,
        private answersStateService: DocumentAnswersStateService,
        private toastService: ToastrService,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.setInvite();
    }

    public ngOnInit(): void {
        this.setInvite();
    }

    private setInvite(): void {
        this.invite = null;

        if (!this.assignment || this.assignment.tasks.length === 0) {
            return;
        }

        const task = this.assignment.tasks[this.assignment.tasks.length - 1];

        for (const invite of task.internshipinvites) {
            if (invite.status !== InviteStateEnum.Revoked) {
                this.invite = invite;
            }
        }
    }

    public handInReview(): void {
        if (!this.answersStateService.answersValid()) {
            this.toastService.warning('De opdracht is nog niet beoordeeld en kan daarom nog niet ingeleverd worden.');

            return;
        }

        this.internshipService.handInReview(this.invite.id).subscribe((data) => {
            this.modalService.reviewHandedIn();
        }, (error) => {
            this.toastService.error('Deze opdracht is reeds beoordeeld.');
        });
    }
}
