import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DomParentsHelper} from 'helpers/dom/parents.helper';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxClose} from 'models/iconbox/close.model';

@Component({
    selector: 'app-simple-modal-component',
    templateUrl: 'simple.component.html',
})
export class SimpleModalComponent implements OnInit {

    public readonly iconBox = new IconBoxClose([IconBoxClassEnum.ColorBlue]);

    @ViewChild('modalContent', { static: true })
    public modalContent: ElementRef;

    public title: string;

    public innerHTML: string;

    public htmlElement: HTMLElement;

    constructor(
        private elementRef: ElementRef,
        private activeModal: NgbActiveModal,
        private domHelper: DomParentsHelper,
    ) {
    }

    public ngOnInit(): void {
        const parentElement: HTMLElement = this.domHelper.findFirstParentWithClass(this.elementRef.nativeElement, 'modal-content');

        if (!parentElement) {
            throw new Error('Parent element not found');
        }

        if (this.innerHTML !== undefined && this.htmlElement !== undefined) {
            throw new Error('You cannot set both innerHTML and htmlElement, only one can be displayed in the body');
        }

        if (this.innerHTML) {
            this.modalContent.nativeElement.innerHTML = this.innerHTML;
        } else if (this.htmlElement instanceof HTMLElement) {
            this.modalContent.nativeElement.appendChild(this.htmlElement);
        }
    }

    public close(): void {
        this.activeModal.close(this.innerHTML || this.htmlElement);
    }
}
