import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthenticationHttpInterceptor} from 'http-interceptors/authentication/authentication.http-interceptor';
import {MaintenanceHttpInterceptor} from 'http-interceptors/maintenance/maintenance.http-interceptor';
import {ThrowExceptionsHttpInterceptor} from 'http-interceptors/throw-exceptions/throw-exceptions.http-interceptor';
import {PublisherHttpInterceptor} from 'http-interceptors/publisher/publisher.http-interceptor';
import {MsalInterceptor} from '@azure/msal-angular';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MaintenanceHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ThrowExceptionsHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: PublisherHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        }
    ],
})
export class HttpInterceptorsModule {
}
