import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ButtonInterface} from 'shared/components/button/interfaces/button.interface';
import {ButtonTypeEnum} from 'shared/components/button/enums/button-type.enum';
import {RouteService} from 'routing/services/route/route.service';

@Component({
    templateUrl: 'button.component.html',
    selector: 'app-button-component',
})
export class ButtonComponent implements OnInit {

    @Input()
    public button: ButtonInterface;

    @Input()
    public disabled: boolean = false;

    @Output()
    public mouseEnter: EventEmitter<string> = new EventEmitter();

    @Output()
    public mouseLeave: EventEmitter<string> = new EventEmitter();

    public route: string;

    constructor(
        private routeService: RouteService,
    ) {
    }

    public ngOnInit(): void {
        this.route = this.button.publisherLink
            ? this.routeService.getRouteWithPublisher(this.button.routerLink)
            : this.button.routerLink;
    }

    public isNavbarType(): boolean {
        return this.button.type === ButtonTypeEnum.Navbar;
    }

    public onMouseEnter(): void {
        this.mouseEnter.emit(this.button.id);
    }

    public onMouseLeave(): void {
        this.mouseLeave.emit(this.button.id);
    }
}
