import 'assets/vendors/redactor/redactor';
import 'assets/vendors/redactor/_plugins/table/table';
import 'services/redactor/languages/nl';
import 'services/redactor/languages/toolbar-plugin.nl';
import {Injectable, NgZone} from '@angular/core';
import {RedactorToggleToolbarPlugin} from 'services/redactor/plugins/toggle-toolbar.plugin';
import {Observable, Observer} from 'rxjs';
import {RedactorNativePluginsEnum} from 'services/redactor/enums/native-plugins.enum';

@Injectable()
export class RedactorService {

    protected app: RedactorApp;

    protected buttons: Array<string> = ['format', 'bold', 'italic', 'underline', 'lists', 'table'];

    protected plugins: Map<string, RedactorPluginInterface> = new Map([
        [RedactorToggleToolbarPlugin.pluginName, new RedactorToggleToolbarPlugin()],
    ]);

    constructor(private ngZone: NgZone) {
        this.plugins.forEach((redactorPlugin: RedactorPluginInterface, name: string) => {
            window.Redactor.add('plugin', name, redactorPlugin.getInstance());
        });
    }

    public create(element: HTMLElement, options?: any, plugins: Array<string> = []): Observable<RedactorAppInstance> {
        return new Observable((observer: Observer<RedactorAppInstance>) => {
            try {
                this.ngZone.runOutsideAngular(() => {
                    plugins.push(RedactorToggleToolbarPlugin.pluginName);
                    plugins.push(RedactorNativePluginsEnum.Table);

                    const mergedOptions = Object.assign({}, {
                        buttons: this.buttons,
                        plugins,
                        lang: 'nl',
                    }, options || {});

                    observer.next(window.Redactor(<Node>element, mergedOptions));
                    observer.complete();
                });
            } catch (error) {
                observer.error(error);
            }
        });
    }

    public delete(element: HTMLElement): void {
        this.ngZone.runOutsideAngular(() => {
            window.Redactor(element, 'destroy');
        });
    }
}
