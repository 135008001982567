import {BookshelfItemTypeEnum} from 'pages/modules/bookshelf/components/item/enums/item-type.enum';
import {BookshelfItemClassEnum} from 'pages/modules/bookshelf/components/item/enums/item-class.enum';
import {IconEnum} from 'enums/icon.enum';
import {BookshelfItemInterface} from 'pages/modules/bookshelf/interfaces/item.interface';

export class BookshelfActivateLicenseModel implements BookshelfItemInterface {

    public id: number = -1;

    public isFavorite: boolean = false;

    public title: string = 'Licentie activeren'; // TODO: 1251 translations

    public type: BookshelfItemTypeEnum = BookshelfItemTypeEnum.ActivateLicense;

    public icon: string = IconEnum.ActivateLicense;

    public classModifiers: Array<string> = [
        BookshelfItemClassEnum.Icon,
    ];

    public studymaterialmethod: ApiStudyMaterialMethodInterface = null;

}
