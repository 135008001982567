import {Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TaskTitleClassesEnum} from 'shared/components/task-title/enums/classes.enum';

@Component({
    selector: 'app-task-title',
    templateUrl: 'task-title.component.html',
})
export class TaskTitleComponent implements OnChanges {

    @HostBinding('class')
    public class: string = TaskTitleClassesEnum.TaskTitle;

    @Input()
    public task: ApiTaskInterface;

    @Input()
    public classModifiers: Array<string> = [];

    public ngOnChanges(changes: SimpleChanges): void {
        this.class = `${TaskTitleClassesEnum.TaskTitle} ${this.classModifiers.join(' ')}`;
    }

}
