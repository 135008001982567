import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from 'core/services/api/api.service';
import {ApiEndpointEnum} from 'enums/api-endpoint.enum';
import {IconEnum} from 'enums/icon.enum';
import {ResourceTypeEnum} from 'enums/resource-type.enum';
import {TeacherMaterialSourceModel} from 'pages/modules/teacher-material/models/source.model';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {RouteService} from 'routing/services/route/route.service';
import {IconBoxClassEnum} from 'shared/modules/icon-box/enums/icon-box.class-enum';
import {IconBoxTypeEnum} from 'shared/modules/icon-box/enums/icon-box.type-enum';
import {IconBoxInterface} from 'shared/modules/icon-box/interfaces/icon-box.interface';
import {MaterialItemClass} from 'pages/modules/teacher-material/enums/material-item-class.enum';
import {ClassHelper} from 'helpers/dom/class.helper';
import {OneDriveService} from 'onedrive/services/onedrive.service';
import {DocumentService} from 'document/services/document/document.service';
import mime from 'mime-types';
import {UrlUtil} from 'shared/util/url.util';

@Component({
    selector: 'app-shelf-source[source]',
    templateUrl: './source.component.html',
})
export class ShelfSourceComponent implements OnInit {

    @Input()
    public source!: TeacherMaterialSourceModel;

    @Input()
    public teacherOnly: boolean;

    @Input()
    public disabled: boolean;

    public iconBox: IconBoxInterface;

    public href: string = null;

    public classHelper: ClassHelper = new ClassHelper();

    public constructor(
        private routeService: RouteService,
        private apiService: ApiService,
        private documentService: DocumentService,
        private oneDriveService: OneDriveService
    ) {
    }

    public ngOnInit(): void {
        this.loadIconBox();
        this.getElementHref();

        if (this.isUploadedSource()) {
            this.classHelper.addClass(MaterialItemClass.UploadedSource);
            this.iconBox.classModifiers.push(IconBoxClassEnum.MaterialUploadedSource);
        }

        if (this.disabled) {
            this.classHelper.addClass(MaterialItemClass.DisabledSource);
            this.iconBox.classModifiers.push(IconBoxClassEnum.MaterialUploadedSourceDisabled);
        }
    }

    private loadIconBox(): void {
        this.iconBox = {
            type: IconBoxTypeEnum.Icon,
            value: this.getResourceIcon(),
            classModifiers: [
                IconBoxClassEnum.Material,
            ],
        };
    }

    private isUploadedSource(): boolean {
        return !this.source.documentType;
    }

    private getResourceIcon(): IconEnum {
        return this.source.type === ResourceTypeEnum.Html
            ? IconEnum.Global
            : this.source.documentType === ResourceTypeEnum.Ebook ? IconEnum.Ebook : this.source.type === ResourceTypeEnum.Link ? IconEnum.Global : IconEnum.Document;
    }

    private getElementHref(): string {
        const dpsId: string = encodeURIComponent(this.source.dpsid);

        if (this.source.documentType === ResourceTypeEnum.Ebook) {
            return this.routeService.getRouteWithPublisher(RoutesEnum.PdfViewer, new Map([
                ['dpsid', dpsId],
            ]));
        }

        if (!this.source.dpsid) {
            return this.apiService.buildUrl(ApiEndpointEnum.SourceGet, new Map([['sourceId', this.source.id.toString()]]));
        }

        return this.routeService.getApiRoute(ApiEndpointEnum.ResourceExternal, new Map([
            ['dpsid', dpsId],
            ['book', this.documentService.getBookId().toString()],
        ]));
    }

    public isDisabled(event: Event): void {
        if (this.disabled) {
            event.preventDefault();
        }
    }

    public openDocument(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        if (this.disabled) {
            return;
        }

        const href = this.getElementHref();
        const target = '_blank';
        const source = this.source;
        const isFile: boolean = source.type === 'file';

        if ((isFile || source.type === 'document' || this.getResourceIcon() === IconEnum.Attachment)) {
            if (isFile && undefined === source.mimeType) {
                throw new Error('Mimetype cannot be undefined when source type is "file"');
            }

            const fileName: string = isFile ? 'uploaded_' + source.id + '.' + mime.extension(source.mimeType) : UrlUtil.getURLSearchParamOrReturnUrl(href, 'dpsid');

            this.oneDriveService.openDocument(this.documentService.getBookTitle(), fileName, href, target);
        } else {
            window.open(href, target);
        }
    }
}
