import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {ResultClassEnum} from 'shared/components/result-cell/enums/result-class.enum';
import {ProgressExamsInterface} from 'pages/modules/progress/interfaces/exams.interface';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {ExamTypeEnum} from 'enums/exam-type.enum';
import {ProgressStudentInterface} from 'pages/modules/progress/interfaces/student.interface';
import {RoleEnum} from 'enums/role.enum';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {RouteService} from 'routing/services/route/route.service';
import {ProgressGroupInterface} from 'pages/modules/progress/interfaces/group.interface';
import {ExamSystemEnum} from 'enums/exam-system.enum';

@Component({
    selector: 'app-progress-exam-result-detail',
    templateUrl: 'exam-result-detail.component.html'
})
export class ProgressExamResultDetailComponent implements OnChanges {

    public readonly tableClasses = GridTableClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly rowClasses = GridTableRowClassEnum;

    public readonly resultCellClasses = ResultClassEnum;

    public readonly routeEnum = RoutesEnum;

    @Input()
    public exam: ProgressExamsInterface = null;

    @Input()
    public examNumber: number;

    @Input()
    public group: ProgressGroupInterface;

    @Input()
    public student: ProgressStudentInterface;

    @Input()
    public isResult: boolean = false;

    public popoverEnabled: boolean = false;

    public typePracticeExam: number = ExamTypeEnum.PracticeExam;

    public typeExam: number = ExamTypeEnum.Exam;

    public examDetailsUrl: string;

    constructor(
        private authorizationService: AuthorizationService,
        private routeService: RouteService,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.exam.previousValue !== changes.exam.currentValue && this.exam.results !== null) {
            this.popoverEnabled = this.exam.results.length > 0;
            this.setExamRouterLink();
        }
    }

    private setExamRouterLink(): void {
        if (this.exam.results.length === 0) {
            return;
        }

        if (this.group === undefined || this.student === undefined) {
            return;
        }

        const isTeacher: boolean = this.authorizationService.isGranted(RoleEnum.RoleTeacher);
        const isContentExam: boolean = this.exam.system === ExamSystemEnum.Content;
        const isExam: boolean = this.exam.type === ExamTypeEnum.Exam;

        let route: RoutesEnum = isContentExam ? isExam ? RoutesEnum.ExamDetails : RoutesEnum.ContentExamPlanPage : RoutesEnum.ExamDetails;
        if (isTeacher) {
            route = isContentExam ? isExam ? RoutesEnum.ExamStudentDetails : RoutesEnum.ContentExamPageTeacher : RoutesEnum.ExamStudentDetails;
        }

        const routeMap: Map<string, string> = new Map([
            ['bookId', String(this.exam.bookId)],
            ['examId', String(isContentExam ? this.exam.dpsId : this.exam.id)],
            ['planId', String(this.exam.results.length > 0 ? this.exam.results[this.exam.results.length - 1].planId : 0)],
            ['resultId', String(this.exam.results.length > 0 ? this.exam.results[this.exam.results.length - 1].id : 0)],
            ['studentId', String(this.student.id)]
        ]);

        this.examDetailsUrl = this.routeService.getRouteWithPublisher(route, routeMap);
    }
}
