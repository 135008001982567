import {InfiniteScroll} from 'shared/classes/infinite-scroll.abstract';
import {ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {ActionButtonInterface} from 'shared/interfaces/action-button.interface';
import {IconEnum} from 'enums/icon.enum';
import {ActionButtonFactory} from 'shared/factories/action-button.factory';
import {PageWrapperClassEnum} from 'shared/modules/page/enums/page-wrapper/class.enum';
import {MessageService} from 'services/message/message.service';
import {ActionButtonClassEnum} from 'shared/enums/action-button/class.enum';
import {FilterDropdownFilterInterface} from 'shared/components/filter-dropdown/interfaces/filter.interface';
import {FilterDropdownReadUnreadModel} from 'shared/models/filter-dropdown/read-unread.model';
import {SearchInterface} from 'shared/modules/search/interfaces/search.interface';
import {SearchPrefixedDelayModel} from 'shared/models/search/prefixed-delayed.model';
import {MessageCenterMessageInterface} from 'pages/modules/message-center/interfaces/message.interface';
import {Observable} from 'rxjs';
import {FilterButtonGroupInterface} from 'shared/components/filter-button-group/interfaces/filter.interface';
import {MessageCenterMessageTypeEnum} from 'pages/modules/message-center/enums/type.enum';
import {CardClassEnum} from 'shared/enums/card/class.enum';
import {MessageCenterFilterEnum} from 'pages/modules/message-center/enums/filter.enum';
import {MessageCenterMessageFactory} from 'pages/modules/message-center/factories/message.factory';
import {ModalService} from 'core/services/modal/modal.service';
import {FilterButtonGroupClassEnum} from 'shared/enums/filter-button-group/class.enum';
import {AnalyticsService} from 'services/analytics/analytics.service';
import {AnalyticsEventNotificationClickModel} from 'services/analytics/models/notification-click.model';

@Component({
    selector: 'app-message-center',
    templateUrl: 'message-center.component.html'
})
export class MessageCenterComponent extends InfiniteScroll<any> implements OnInit, OnDestroy {

    @HostBinding('class')
    protected classes = 'container-fluid container-fluid--width';

    public readonly actionButtonClassEnum = ActionButtonClassEnum;

    public readonly pageWrapperClassEnum = PageWrapperClassEnum;

    public readonly messageCenterMessageTypeEnum = MessageCenterMessageTypeEnum;

    public readonly filterButtonGroupClassEnum = FilterButtonGroupClassEnum;

    public readonly searchModel: SearchInterface = new SearchPrefixedDelayModel('Zoek een bericht', null);

    public canMarkAllAsRead: boolean = true;

    public actionButton: ActionButtonInterface;

    public readUnreadFilter: FilterDropdownFilterInterface<string> = new FilterDropdownReadUnreadModel();

    public typeFilterOptions: Map<MessageCenterMessageTypeEnum, string> = new Map([
        [MessageCenterMessageTypeEnum.All, 'Alle'],
        [MessageCenterMessageTypeEnum.Message, 'Berichten'],
        [MessageCenterMessageTypeEnum.Material, 'Lesmateriaal'],
        [MessageCenterMessageTypeEnum.Tasks, 'Taken'],
        [MessageCenterMessageTypeEnum.Tests, 'Toetsen'],
    ]);

    public typeFilterDropdown: FilterDropdownFilterInterface<MessageCenterMessageTypeEnum>;

    public typeFilterButtonGroup: FilterButtonGroupInterface<MessageCenterMessageTypeEnum>;

    public messages: Array<MessageCenterMessageInterface> = [];

    public filter: MessageCenterFilterEnum;

    private searchValue: string = '';

    private totalNotifications: number = 0;

    constructor(
        private actionButtonFactory: ActionButtonFactory,
        private messageCenterMessageFactory: MessageCenterMessageFactory,
        private messageService: MessageService,
        private modalService: ModalService,
        private analyticsService: AnalyticsService,
        protected change: ChangeDetectorRef,
    ) {
        super(change);

        this.actionButton = this.actionButtonFactory.createButton(
            'heading-action-root',
            'Markeer alles als gelezen',
            IconEnum.MailOpen,
        );

        // TODO: Make filter button group display dropdown on mobile devices, this makes me a sad panda
        this.typeFilterButtonGroup = {
            id: 'type-button-group-filter',
            selected: MessageCenterMessageTypeEnum.All,
            options: this.typeFilterOptions,
        };

        this.typeFilterDropdown = {
            id: 'type-dropdown-filter',
            options: new Map([['', this.typeFilterOptions]]),
            selectedValue: MessageCenterMessageTypeEnum.All.toString(),
        };
    }

    public onReadUnreadChange(): void {
        this.loadData(true);
    }

    public onFilterDropdownChange(): void {
        const selectedValue: MessageCenterMessageTypeEnum = Number(this.typeFilterDropdown.selectedValue);

        this.typeFilterButtonGroup.selected = selectedValue;
        this.handleFilterChange(selectedValue);
        this.loadData(true);
    }

    public onFilterButtonChange(filter: FilterButtonGroupInterface<MessageCenterMessageTypeEnum>): void {
        this.typeFilterDropdown.selectedValue = filter.selected.toString();
        this.handleFilterChange(filter.selected);
        this.loadData(true);
    }

    public handleFilterChange(filter: MessageCenterMessageTypeEnum|string): void {
        switch (filter) {
            case MessageCenterMessageTypeEnum.Tasks:
                this.filter = MessageCenterFilterEnum.Homework;
                break;

            case MessageCenterMessageTypeEnum.Tests:
                this.filter = MessageCenterFilterEnum.Exam;
                break;

            case MessageCenterMessageTypeEnum.Material:
                this.filter = MessageCenterFilterEnum.Resource;
                break;

            case MessageCenterMessageTypeEnum.Message:
                this.filter = MessageCenterFilterEnum.Message;
                break;

            default:
                this.filter = null;
        }

        this.loadData(true);
    }

    public onSearch(value: string): void {
        this.searchValue = value;
        this.loadData(true);
    }

    public markAllAsRead(): void {
        this.messageService.markAllAsRead().subscribe(() => {
            const messages: Array<MessageCenterMessageInterface> = this.messages;

            messages.forEach((message: MessageCenterMessageInterface) => {
                const clone: MessageCenterMessageInterface = this.getMessageClone(message);

                clone.read = true;

                this.replaceMessage(message, clone);
            });
        });
    }

    public getMessageDescription(message: MessageCenterMessageInterface): string {
        const isMessage: boolean = message.messageType.type === MessageCenterMessageTypeEnum.Message;

        if (!isMessage) {
            return message.description;
        }

        return '<span>Open bericht om het te lezen</span>';
    }

    public markMessageAsRead(message: MessageCenterMessageInterface): void {
        if (message.messageType.type !== MessageCenterMessageTypeEnum.Message) {
            this.analyticsService.event(new AnalyticsEventNotificationClickModel(message.id, message.messageType.type));
        }

        if (message.messageType.type === MessageCenterMessageTypeEnum.Message) {
            this.messageService.showMessage(message);
        } else if (!message.read) {
            this.messageService.markAsRead(message).subscribe(); // Trigger call
        }
    }

    public getMessageCardClasses(message: MessageCenterMessageInterface): Array<string> {
        const classes: Array<string> = [CardClassEnum.HeightMd, CardClassEnum.CursorPointer];

        if (message.read) {
            classes.push(CardClassEnum.ColorGreyDark, CardClassEnum.HoverNone);
        } else {
            switch (message.messageType.type) {
                case MessageCenterMessageTypeEnum.Tasks:
                    classes.push(CardClassEnum.ColorOrange);
                    break;

                case MessageCenterMessageTypeEnum.Tests:
                    classes.push(CardClassEnum.ColorRed);
                    break;

                case MessageCenterMessageTypeEnum.Message:
                    classes.push(CardClassEnum.ColorBlueDark);
                    break;

                default:
                    classes.push(CardClassEnum.ColorBlueDark);
                    break;
            }
        }

        return classes;
    }

    protected getDataLength(): number {
        return this.totalNotifications;
    }

    protected getLoadDataObservable(): Observable<ApiNotificationsInterface> {
        return this.messageService.retrieveNotifications(
            this.getRequestOffset(),
            this.limit,
            this.searchValue,
            this.getNotificationReadValue(),
            this.filter,
        );
    }

    protected subscribe(response: ApiNotificationsInterface): void {
        this.totalNotifications = response.total;

        this.messages.push(...this.messageCenterMessageFactory.fromApiMessagesAndApiNotifications(response));
    }

    protected reset(): void {
        super.reset();

        this.messages = [];
        this.totalNotifications = 0;
    }

    private getMessageClone(message: MessageCenterMessageInterface): MessageCenterMessageInterface {
        return JSON.parse(JSON.stringify(message));
    }

    private replaceMessage(
        source: MessageCenterMessageInterface,
        target: MessageCenterMessageInterface,
    ): void {
        const index: number = this.messages.indexOf(source);

        this.messages[index] = target;
    }

    private getNotificationReadValue(): number {
        const selectedValue: string = this.readUnreadFilter.selectedValue;

        return selectedValue === FilterDropdownReadUnreadModel.STATUS_ALL
            ? 0 : selectedValue === FilterDropdownReadUnreadModel.STATUS_READ ? 1 : 2;
    }
}
