import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-tasks-add-modal-task-title',
    templateUrl: 'title.component.html',
})
export class TasksAddTaskModalTitleComponent {

    @Input()
    public title: string = '';

    @Output()
    public titleChange: EventEmitter<string> = new EventEmitter();

    @Input()
    public disabled: boolean = false;

    public onTitleChange(): void {
        this.titleChange.emit(this.title);
    }
}
