import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {UserService} from 'security/services/user/user.service';

@Injectable({
    providedIn: 'root'
})
export class HasGroupsGuard implements CanActivate {
    public constructor(
        private userService: UserService
    ) {}

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const user = this.userService.getUserData();

        return user && user.group_count > 0;
    }
}
