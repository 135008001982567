import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InputInterface} from 'shared/modules/form-elements/components/interfaces/input.interface';
import {IconEnum} from 'enums/icon.enum';
import {InputFormControlClassModifiersEnum} from 'shared/modules/form-elements/components/input/enum/class-modifiers.enum';
import {ClassHelper} from 'helpers/dom/class.helper';

@Component({
    selector: 'app-input-component',
    templateUrl: 'input.component.html'
})
export class InputElementComponent implements InputInterface, OnInit {

    @Input()
    public id: string;

    @Input()
    public name: string;

    @Input()
    public value: string = '';

    @Input()
    public type: string = 'text';

    @Output()
    public valueChange: EventEmitter<string> = new EventEmitter();

    @Output()
    public prefixClick: EventEmitter<null> = new EventEmitter();

    @Output()
    public affixClick: EventEmitter<null> = new EventEmitter();

    @Input()
    public placeHolder: string = '';

    @Input()
    public affixIcon?: IconEnum;

    @Input()
    public prefixIcon?: IconEnum;

    @Input()
    public prefixImage?: string;

    @Input()
    public readonly: boolean = false;

    @Input()
    public prefix: boolean = false;

    @Input()
    public affix: boolean = false;

    @Input()
    public classModifiers: Array<string> = [];

    public classHelper: ClassHelper = new ClassHelper();

    public ngOnInit(): void {
        this.classHelper.addClasses(this.classModifiers);

        if (this.prefix) {
            this.classHelper.addClass(InputFormControlClassModifiersEnum.BorderNoRadiusRight, 'form-control');
        }

        if (this.affix) {
            this.classHelper.addClass(InputFormControlClassModifiersEnum.BorderNoRadiusLeft, 'form-control');
        }
    }

    public onModelChange(): void {
        this.valueChange.emit(this.value);
    }

    public getElementIdentifier(): string {
        return this.id || this.name;
    }
}
