import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {environment} from 'environments/environment';
import {DocumentPageActionsButtonsEnum} from 'pages/modules/document/enums/actions-buttons.enum';
import {DocumentActionsButtonTocModel} from 'pages/modules/document/models/actions-button-toc.model';
import {WindowService} from 'services/window/window.service';
import {Subscription} from 'rxjs';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {AuthorizationService} from 'security/services/authorization/authorization.service';
import {DocumentPageAshowAnswersButtonTranslationsEnum} from 'pages/modules/document/enums/show-answers-button-translations.enum';
import {ModalService} from 'core/services/modal/modal.service';
import {NavigationExtras, Router} from '@angular/router';
import {RoutesEnum} from 'routing/enums/routes.enum';
import {DocumentActionsButtonAssignmentModel} from 'pages/modules/document/models/actions-button-assignment.model';
import {DocumentActionsButtonMaterialModel} from 'pages/modules/document/models/actions-button-material.model';
import {DocumentActionsButtonShowAnswerModel} from 'pages/modules/document/models/actions-button-show-answer.model';
import {DocumentActionButtonInterface} from 'pages/modules/document/interfaces/action-button.interface';
import {RouteService} from 'routing/services/route/route.service';
import {PageWindowSizeEnum} from 'pages/modules/document/components/page-actions/enums/page-window-size-enum';
import {DocumentActionsButtonEBookModel} from 'pages/modules/document/models/actions-button-ebook.model';

@Component({
    selector: 'app-document-page-actions',
    templateUrl: 'page-actions.component.html',
})
export class DocumentPageActionsComponent implements OnInit, OnChanges, OnDestroy {

    @ViewChild('pageActionsContainer', {static: true})
    private container: ElementRef;

    @Output()
    public showToc: EventEmitter<void> = new EventEmitter();

    @Input()
    public isTocDisabled: boolean;

    @Input()
    public bookId: number;

    @Input()
    public dpsid: string;

    @Input()
    public bookTitle: string;

    @Input()
    public isAssignment: boolean = false;

    @Input()
    public isBpv: boolean = false;

    @Input()
    public selfStudy: boolean = false;

    @Input()
    public demo: boolean = false;

    @Input()
    public isloading: boolean;

    @Input()
    public isAssignable: boolean = true;

    @Input()
    public eBook: string;

    public archiveModeActive = environment.archiveModeActive;

    public buttons: Array<DocumentActionButtonInterface> = [];

    private onScrollSubscription: Subscription;

    private onShowAnswersSubscription: Subscription;

    private elementOriginalTop: number;

    constructor(
        private elementRef: ElementRef,
        private windowService: WindowService,
        private authorizationService: AuthorizationService,
        private answersService: DocumentAnswersStateService,
        private modalService: ModalService,
        private routeService: RouteService,
        private router: Router,
    ) {
    }

    public ngOnInit(): void {
        this.onScrollSubscription = this.windowService.onScroll.subscribe(() => this.handleScroll());
        this.onShowAnswersSubscription = this.answersService.registerChangeListener(() => this.setShowAnswersButtonTranslation());
        this.elementOriginalTop = this.getElementOriginalTop();

        this.setupButtons();
    }

    public ngOnDestroy(): void {
        if (this.onScrollSubscription) {
            this.onScrollSubscription.unsubscribe();
        }

        if (this.onShowAnswersSubscription) {
            this.onShowAnswersSubscription.unsubscribe();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.isTocDisabled) {
            this.isTocDisabled = !changes.isTocDisabled.currentValue;

            const actionButton: DocumentActionButtonInterface = this.buttons.find(button => button.id === DocumentPageActionsButtonsEnum.TableOfContents);
            if (actionButton !== undefined && this.isTocDisabled) {
                actionButton.classModifiers.push('');
            }
        }

        if (changes.selfStudy || changes.isAssignment || changes.isAssignable || changes.eBook) {
            this.setupButtons();
        }
    }

    public handleButtonClick(button: DocumentActionButtonInterface): void {
        if ((this.demo && button.id === 3) || !this.isAssignable && button.id === 3) {
            return;
        }

        switch (button.id) {
            case DocumentPageActionsButtonsEnum.TableOfContents:
                this.showToc.emit();
                break;
            case DocumentPageActionsButtonsEnum.ShowAnswer:
                this.onShowAnswersClick();
                break;
            case DocumentPageActionsButtonsEnum.SubmitAssignment:
                this.handleAddTask();
                break;
            case DocumentPageActionsButtonsEnum.TeacherMaterial:
                this.handleTeacherMaterial();
                break;
            case DocumentPageActionsButtonsEnum.EBook:
                this.handleEBook();
                break;
        }
    }

    private handleEBook(): void {
        window.open(this.eBook, '_blank');
    }

    private handleTeacherMaterial(): void {
        if (environment.features.teacherMaterialsEnabled === true) {
            this.handleTeacherMaterialNewView();
        } else {
            this.handleTeacherMaterialOldView();
        }
    }

    private handleTeacherMaterialNewView(): void {
        const params = new Map<string, string>([
            ['bookId', String(this.bookId)],
        ]);

        this.router.navigate([this.routeService.getRouteWithPublisher(RoutesEnum.TeacherMaterialBook, params)]);
    }

    private handleTeacherMaterialOldView(): void {
        const navigationExtras: NavigationExtras = {
            queryParams: {
                'bookId': this.bookId,
                'dpsid': this.dpsid,
            }
        };

        this.router.navigate([this.routeService.getRouteWithPublisher(RoutesEnum.TeacherMaterial)], navigationExtras);
    }

    public handleAddTask(): void {
        this.modalService.taskAdd(this.bookId, null, this.dpsid);
    }

    private getElementOriginalTop(): number {
        const parentElementTop: number = this.getNativeElement().parentElement.getBoundingClientRect().top;

        return this.getScrollTopDistance() + parentElementTop;
    }

    private getNativeElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    private handleScroll(): void {
        const scrollOffset: number = this.getScrollTopDistance() - this.elementOriginalTop;

        if (scrollOffset > 0 && window.innerWidth > PageWindowSizeEnum.SmallDevice) {
            this.container.nativeElement.style.top = `${scrollOffset}px`;
        } else {
            this.container.nativeElement.style.top = null;
        }
    }

    private getScrollTopDistance(): number {
        const documentElement: HTMLElement = document.documentElement;

        return (window.pageYOffset || documentElement.scrollTop) - (documentElement.clientTop || 0);
    }

    private onShowAnswersClick(): void {
        if (this.answersService.answersShown()) {
            this.answersService.hideAnswers();
        } else {
            this.answersService.showAnswers();
            this.answersService.selfAnswerChanged();
        }

        this.setShowAnswersButtonTranslation();
    }

    private setShowAnswersButtonTranslation(): void {
        const showAnswersButton = this.buttons.find((button: DocumentActionButtonInterface) => button.id === DocumentPageActionsButtonsEnum.ShowAnswer);

        if (!showAnswersButton) {
            return;
        }

        if (this.answersService.answersShown()) {
            showAnswersButton.value = DocumentPageAshowAnswersButtonTranslationsEnum.AnswersShown;
        } else {
            showAnswersButton.value = DocumentPageAshowAnswersButtonTranslationsEnum.AnswersHidden;
        }
    }

    private setupButtons(): void {
        const buttons: Array<DocumentActionButtonInterface> = [
            new DocumentActionsButtonTocModel(['d-none', 'd-md-flex']),
        ];

        const assignmentButton = this.demo
            ? new DocumentActionsButtonAssignmentModel(['btn--disabled', 'btn--demo'], this.isAssignment)
            : !this.isAssignable
                ? new DocumentActionsButtonAssignmentModel(['btn--disabled', 'btn--is-not-assignable'], this.isAssignment)
                : new DocumentActionsButtonAssignmentModel(null, this.isAssignment);

        if (!this.archiveModeActive) {
            buttons.push(assignmentButton);
        }

        buttons.push(
            new DocumentActionsButtonMaterialModel(),
            new DocumentActionsButtonEBookModel(!!this.eBook),
        );

        if (!this.archiveModeActive) {
            buttons.push(
                new DocumentActionsButtonShowAnswerModel(this.isBpv, this.selfStudy, this.isAssignment)
            );
        }



        this.setShowAnswersButtonTranslation();

        this.buttons = buttons;
    }
}
