import {
    Component,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {DocumentHostClassesEnum} from 'document/enums/document-host-classes.enum';
import {Subscription} from 'rxjs';
import {DocumentAnswersStateService} from 'document/services/show-answers/answers-state.service';
import {ClassHelper} from 'helpers/dom/class.helper';

@Component({
    selector: '.document-antwoord, .document-answer',
    templateUrl: 'document-answer.component.html',
})
export class DocumentAnswerComponent implements OnInit, OnDestroy {

    @HostBinding('class')
    public elementClass: string = '';

    @Input()
    public classModifiers: Array<string> = [];

    @Input()
    public overrideShowAnswers: boolean = false;

    private eventEmitterSubscription: Subscription;

    public showAnswers: boolean = false;

    public constructor(
        private answersService: DocumentAnswersStateService,
    ) {
    }

    public ngOnInit(): void {
        this.eventEmitterSubscription = this.answersService.registerChangeListener(() => this.onShowAnswersEvent());

        this.onShowAnswersEvent();
    }

    public ngOnDestroy(): void {
        if (this.eventEmitterSubscription) {
            this.eventEmitterSubscription.unsubscribe();
        }
    }

    private onShowAnswersEvent(): void {
        this.showAnswers = this.answersService.answersShown();

        const classHelper = new ClassHelper();
        classHelper.addClasses(this.classModifiers);

        if (this.showAnswers || this.overrideShowAnswers) {
            classHelper.addClass(DocumentHostClassesEnum.QuestionAnswer);
        }

        this.elementClass = classHelper.toString();
    }
}
