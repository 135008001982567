import {Component, Input, OnDestroy} from '@angular/core';
import {TasksAttachmentFactory} from 'pages/modules/tasks/factories/attachment.factory';
import {TaskAttachmentInterface} from 'pages/modules/tasks/interfaces/attachment.interface';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {GridTableClassEnum} from 'shared/modules/grid-table/enums/table/class.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {HomeworkService} from 'services/homework/homework.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-tasks-document',
    templateUrl: 'document.component.html',
})
export class TasksDocumentComponent implements OnDestroy {

    public readonly tableClasses = GridTableClassEnum;

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly rowClasses = GridTableRowClassEnum;

    @Input()
    public activeTask: ApiTaskDetailsInterface;

    @Input()
    public document: ApiDocumentDataInterface;

    @Input()
    public homework: ApiHomeworkCurrentInterface;

    public homeworkAttachments: Array<TaskAttachmentInterface> = [];

    private subscriptions: Array<Subscription> = [];

    public constructor(
        private attachmentFactory: TasksAttachmentFactory,
        private homeworkService: HomeworkService
    ) {
        this.subscriptions.push(this.homeworkService.subscribeHomeworkLoaded(
            (value) => setTimeout(() =>
                this.updateAttachments(value)))
        );
    }

    private updateAttachments(value: boolean): void {
        if (!value || this.homework === null || this.homework === undefined) {
            this.homeworkAttachments = [];

            return;
        }

        this.homeworkAttachments = this.attachmentFactory.fromApiHomework(this.homework.current);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
