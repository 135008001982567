import {Component, Input} from '@angular/core';
import {environment} from 'environments/environment';
import {GridTableRowClassEnum} from 'shared/modules/grid-table/enums/row/class.enum';
import {IconEnum} from 'enums/icon.enum';
import {HorizontalBarClassesEnum} from 'shared/modules/horizontal-bar/enums/horizontal-bar-classes.enum';
import {GridTableColClassEnum} from 'shared/modules/grid-table/enums/col/class.enum';
import {FileService} from 'services/file/file.service';

@Component({
    selector: 'app-tasks-attachments',
    templateUrl: 'attachments.component.html',
})
export class TasksAttachmentsComponent {

    public readonly columnClasses = GridTableColClassEnum;

    public readonly horizontalBarClasses = HorizontalBarClassesEnum;

    public readonly iconEnum = IconEnum;

    public readonly rowClasses = GridTableRowClassEnum;

    public readonly downloadFileUrl = environment.fileDownloadUrl;

    @Input()
    public activeTask: ApiTaskDetailsInterface;

    public expanded: boolean = false;


    public constructor(
        private fileService: FileService
    ) {
    }

    public handleExpand(): void {
        this.expanded = !this.expanded;
    }

    public openFile(url: string, file: ApiFileInterface, target: string): void {
        this.fileService.openFile(url, file, target);
    }

}
