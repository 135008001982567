import {IconEnum} from 'enums/icon.enum';
import {MaterialshelfItemClassEnum} from 'pages/modules/teacher-material/enums/item-class.enum';
import {MaterialShelfItemTypeEnum} from 'pages/modules/teacher-material/enums/item-type.enum';

export class TeacherMaterialFolderModel {

    public type: MaterialShelfItemTypeEnum = MaterialShelfItemTypeEnum.Folder;

    public icon: string = IconEnum.Map;

    public classModifiers: Array<string> = [
        MaterialshelfItemClassEnum.Default,
    ];

    constructor(
        public dpsid: string,
        public title: string,
        public documentType: string,
        public total: number,
        public disabled: boolean,
    ) {
    }

}
