export class UrlUtil {
    /**
     * Try and get param from URL, when not found return URL
     */
    public static getURLSearchParamOrReturnUrl(url: string, param: string): string {
        try {
            const result: string | undefined = this.getURLSearchParam(url, param);

            return undefined !== result ? result : url;
        } catch (error) {
            return url;
        }
    }

    public static getURLSearchParam(url: string, param: string): string | undefined {
        try {
            const urlParams: URLSearchParams = new URL(url).searchParams;
            const result: string | undefined = urlParams.has(param) ? urlParams.get(param) : undefined;

            return undefined !== result ? result : url;
        } catch (error) {
            return undefined;
        }
    }
}
