import {AfterViewInit, Component} from '@angular/core';
import {HelpPageModalComponent} from 'shared/modals/help-page/help-page-modal.component';
import {IconEnum} from 'enums/icon.enum';
import {HelpPageEnum} from 'services/help-page/enums/help-page.enum';
import {PreloaderClassModifiersEnum} from 'shared/components/preloader/enums/class-modifiers.enum';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HelpPageContentService} from 'services/help-page/help-page-content.service';
import {UserService} from 'security/services/user/user.service';
import {NoHelpPageModel} from 'shared/modals/help-page/models/no-help-page.model';

@Component({
    selector: 'app-tasks-help-page-modal',
    templateUrl: '../help-page-modal.component.html',
})
export class TasksHelpPageModalComponent extends HelpPageModalComponent implements AfterViewInit {

    public icon: IconEnum = IconEnum.Check;

    public triangle = true;

    public preloaderColor = PreloaderClassModifiersEnum.Tasks;

    private noHelPage = new NoHelpPageModel();

    constructor(
        public activeModal: NgbActiveModal,
        public popupPageService: HelpPageContentService,
        public userService: UserService
    ) {
        super(activeModal, popupPageService, userService);
    }

    public ngAfterViewInit(): void {
        this.loadTasksData();
        this.carousel.pause();
    }

    public loadTasksData(): void {
        this.isLoading = true;
        this.popupPageService.retrieveContentByPage(HelpPageEnum.Tasks).toPromise()
            .then((pages: Array<HelpPageInterface>) => {
                this.pages = pages;

                if (this.pages.length < 1) {
                    this.pages.push(this.noHelPage);
                }

                this.isLoading = false;

                return Promise.resolve([]);
            }, () => {
                this.isLoading = false;
            });
    }
}
